// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    GET_EVENT, GET_EVENT_FAILED, GET_EVENT_SUCCESS,
    EDIT_EVENT_ATTENDANCY, EDIT_EVENT_ATTENDANCY_FAILED, EDIT_EVENT_ATTENDANCY_SUCCESS,
    CREATE_EVENT_ATTENDANCY, CREATE_EVENT_ATTENDANCY_FAILED, CREATE_EVENT_ATTENDANCY_SUCCESS,
    DELETE_EVENT_ATTENDANCY, DELETE_EVENT_ATTENDANCY_FAILED, DELETE_EVENT_ATTENDANCY_SUCCESS,
    GET_EVENT_BY_TYPE_ID, GET_EVENT_BY_TYPE_ID_FAILED, GET_EVENT_BY_TYPE_ID_SUCCESS,
    GET_EVENT_BY_ID, GET_EVENT_BY_ID_FAILED, GET_EVENT_BY_ID_SUCCESS
} from './constants';

const INIT_STATE = {
    event: {},
    eventsAttendancy: null,
    eventsAttendancyByType: null,
    loading: false,
    error: null
};

const Event = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EVENT:
            return { ...state, loading: true };
        case GET_EVENT_SUCCESS:
            return { ...state, eventsAttendancy: action.payload, loading: false, error: null };
        case GET_EVENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EVENT_BY_TYPE_ID:
            return { ...state, loading: true };
        case GET_EVENT_BY_TYPE_ID_SUCCESS:
            return { ...state, eventsAttendancyByType: action.payload, loading: false, error: null };
        case GET_EVENT_BY_TYPE_ID_FAILED:
            return { ...state, eventsAttendancyByType: null, error: action.payload, loading: false };

        case GET_EVENT_BY_ID:
            return { ...state, loading: true };
        case GET_EVENT_BY_ID_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_EVENT_BY_TYPE_ID_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_EVENT_ATTENDANCY:
            return { ...state, loading: true };
        case EDIT_EVENT_ATTENDANCY_SUCCESS:
            {
                let current = state.eventsAttendancy;
                let index = current?.findIndex((element) => { return element.id == action.payload.id });
                if (index >= 0) {
                    current[index] = action.payload;
                }
                return { ...state, eventsAttendancy: current, loading: false, error: null };
            }
        case EDIT_EVENT_ATTENDANCY_FAILED:
            return { ...state, event: null, error: action.payload, loading: false };

        case DELETE_EVENT_ATTENDANCY:
            return { ...state, loading: true };
        case DELETE_EVENT_ATTENDANCY_SUCCESS:
            {
                let current = state.eventsAttendancy;
                let index = current?.findIndex((element) => { return element.id == action.payload });
                if (index >= 0) {
                    current.splice(index, 1);
                }
                return { ...state, eventsAttendancy: current, loading: false, error: null };
            }
        case DELETE_EVENT_ATTENDANCY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_EVENT_ATTENDANCY:
            return { ...state, loading: true };
        case CREATE_EVENT_ATTENDANCY_SUCCESS:
            {
                let current = state.eventsAttendancy;
                if (current) {
                    current.push(action.payload);
                }
                else {
                    current = [action.payload];
                }
                return { ...state, eventsAttendancy: current, loading: false, error: null };
            }
        case CREATE_EVENT_ATTENDANCY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Event;
