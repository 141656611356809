// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {BASE_URL} from './../constants';
import {
    getRepoProductsFailed,getRepoProductsSuccess
} from './actions';
import {
    GET_REPOPRODUCTS
} from './constants';

function* getRepoProductsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/localize/repo-products', options);
        yield put(getRepoProductsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getRepoProductsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetRepoProducts() {
    yield takeEvery(GET_REPOPRODUCTS, getRepoProductsInternal);
}

function* repoproductsSaga() {
    yield all([fork(watchGetRepoProducts)]);
}

export default repoproductsSaga;
