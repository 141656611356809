/* AUTH */
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED';

export const GET_EVENT_BY_TYPE_ID = 'GET_EVENT_BY_TYPE_ID';
export const GET_EVENT_BY_TYPE_ID_SUCCESS = 'GET_EVENT_BY_TYPE_ID_SUCCESS';
export const GET_EVENT_BY_TYPE_ID_FAILED = 'GET_EVENT_BY_TYPE_ID_FAILED';

export const EDIT_EVENT_ATTENDANCY = 'EDIT_EVENT_ATTENDANCY';
export const EDIT_EVENT_ATTENDANCY_SUCCESS = 'EDIT_EVENT_ATTENDANCY_SUCCESS';
export const EDIT_EVENT_ATTENDANCY_FAILED = 'EDIT_EVENT_ATTENDANCY_FAILED';

export const DELETE_EVENT_ATTENDANCY = 'DELETE_EVENT_ATTENDANCY';
export const DELETE_EVENT_ATTENDANCY_SUCCESS = 'DELETE_EVENT_ATTENDANCY_SUCCESS';
export const DELETE_EVENT_ATTENDANCY_FAILED = 'DELETE_EVENT_ATTENDANCY_FAILED';

export const CREATE_EVENT_ATTENDANCY = 'CREATE_EVENT_ATTENDANCY';
export const CREATE_EVENT_ATTENDANCY_SUCCESS = 'CREATE_EVENT_ATTENDANCY_SUCCESS';
export const CREATE_EVENT_ATTENDANCY_FAILED = 'CREATE_EVENT_ATTENDANCY_FAILED';

export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const GET_EVENT_BY_ID_SUCCESS = 'GET_EVENT_BY_ID_SUCCESS';
export const GET_EVENT_BY_ID_FAILED = 'GET_EVENT_BY_ID_FAILED';
