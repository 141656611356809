// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Mail,
    Trash,
    User,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Label,
    Row,
    UncontrolledTooltip,
} from 'reactstrap';
import { countryCodeToString, dateStringFromTimeInterval, durationBetweenDates, facultyWithId, formatDateString, getLocalizedString, isAdmin, locationString } from '../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    changeTheme,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAcademicDoc,
    getNetworkAcademics,
    deleteNetworkAcademy
} from '../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, getLoggedInUser } from '../../../../helpers/authUtils';
import UserInfo from '../../Settings/Profile/UserInfo';
import * as layoutConstants from '../../../../constants/layout';
import SVG from 'react-inlinesvg';
import { format } from '../../../../helpers/stringFormat';
import Select from 'react-select';

import addAcademyIcon from '../../../../assets/images/menu/educationplus.svg';
import AddAcademyModal from './AddAcademyModal';
import { AcademicsIcon } from '../../../../components/Icons';
import { MENU_TYPE_HOME } from '../../../../redux/appMenu/constants';
import AlertPopupView, { ALERT_TYPE_DANGER } from '../../Supports/AlertPopupView';
import RemoveNetworkModal from './RemoveNetworkModal';
import ReactPaginate from 'react-paginate';

export const NETWORK_ACADEMIC_TYPE_COUNTRY = 0;
export const NETWORK_ACADEMIC_TYPE_SCHOOL = 1;

class NetworkAcademics extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            displayWidth: 800,
            selectedNode: null,
            showAddCompanyDialog: 0,
            showInviteProfileDialog: 0,
            idType: null,
            editingCompetency: null,
            editingAcademy: null,
            inviteContact: null,
            selectedCountry: 'All Countries',
            selectedState: 'All States',
            selectedFaculty: -1,
            showDetails: false,
            groupByCountry: true,
            showAlertPopup: false,
            currentPage: 0,
            keyword: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
        this.props.getAcademicDoc({});
        this.getAcademics();

        if (this.props.subscriptionProfile == null) {
            this.getProfileAndCompanies();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        // this.props.history.push({ pathname: '/network' });
    }

    getProfileAndCompanies = () => {
        this.props.getSubscriptionProfile({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
            },
        });
    };

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }

    onSelectedNode = (node) => {
        // alert('onSelectedNode: ' + node.labels[0]);
        this.setState({ selectedNode: node });
    };

    showAddAcademyModal = (editingAcademy) => {
        this.setState({
            editingAcademy: editingAcademy,
            showAddCompanyDialog: 1
        });
    };

    closeAddAcademyModal = (response) => {
        this.setState({ showAddCompanyDialog: 0, editingAcademy: null }, () => {
            if (response) {
                this.props.getNetworkAcademics(this.state.groupByCountry ? NETWORK_ACADEMIC_TYPE_COUNTRY : NETWORK_ACADEMIC_TYPE_SCHOOL, 0, false, this.state.keyword, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
        
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
        
                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                });
            }
        });
    };

    academicsNetworkByCountry = () => {
        let network = [];

        let academics = this.props.networkAcademics?.data ?? [];

        academics.forEach(acad => {
            let academy = acad.academy;

            let users = {};
            if (acad.externalUsers?.length > 0) {
                users.external = acad.externalUsers;
            }
            if (acad.invitedUsers?.length > 0) {
                users.invited = acad.invitedUsers;
            }
            if (acad.underSignupUsers?.length > 0) {
                users.underSignup = acad.underSignupUsers;
            }
            if (acad.registeredUsers?.length > 0) {
                users.user = acad.registeredUsers;
            }
            academy.network = users;

            if (acad.user) {
                academy.admins = [acad.user];
            }

            if (academy?.cities?.length > 0) {
                academy.cities.forEach(city => {
                    let countryCode = city.country ?? '';

                    let index = network.findIndex((group) => { return group.country == countryCode });
                    if (index >= 0) {
                        let acads = network[index].academics ?? [];
                        if (!acads.some((a) => { return a.id == academy.id })) {
                            acads.push(academy);
                            network[index].academics = acads;
                        }
                    }
                    else {
                        network.push({ country: countryCode, academics: [academy] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.country?.length == 0 });
                if (index >= 0) {
                    let acads = network[index].academics ?? [];
                    acads.push(academy);
                    network[index].academics = acads;
                }
                else {
                    network.push({ country: '', academics: [academy] });
                }
            }
        }, this);

        network.forEach(element => {
            if (element.academics?.length > 1) {
                element.academics.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    academicsNetworkBySchool = () => {
        let network = [];

        let academics = this.props.networkAcademics?.data ?? [];

        academics.forEach(acad => {
            let academy = acad.academy;

            let users = {};
            if (acad.externalUsers?.length > 0) {
                users.external = acad.externalUsers;
            }
            if (acad.invitedUsers?.length > 0) {
                users.invited = acad.invitedUsers;
            }
            if (acad.underSignupUsers?.length > 0) {
                users.underSignup = acad.underSignupUsers;
            }
            if (acad.registeredUsers?.length > 0) {
                users.user = acad.registeredUsers;
            }
            academy.network = users;

            if (acad.user) {
                academy.admins = [acad.user];
            }

            if (academy?.faculties?.length > 0) {
                academy.faculties.forEach(faculty => {
                    let index = network.findIndex((group) => { return group.faculty.facultyId == faculty.facultyId });
                    if (index >= 0) {
                        let acads = network[index].academics ?? [];
                        if (!acads.some((a) => { return a.id == academy.id })) {
                            acads.push(academy);
                            network[index].academics = acads;
                        }
                    }
                    else {
                        network.push({ faculty: faculty, academics: [academy] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.faculty.facultyId == 1000 });
                if (index >= 0) {
                    let acads = network[index].academics ?? [];
                    acads.push(academy);
                    network[index].academics = acads;
                }
                else {
                    network.push({ faculty: { facultyId: 1000 }, academics: [academy] });
                }
            }
        });

        network.forEach(element => {
            if (element.academics?.length > 1) {
                element.academics.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    networkCities = (academy) => {
        let cities = (academy.cities ?? []).map(city => {
            let faculties = academy.faculties?.filter((f) => { return (f.cities ?? []).some((c) => { return c == city.id }) }) ?? [];
            return { city: city, faculties: faculties };
        });

        let network = academy.network;
        if ((Object.keys(network) ?? []).length > 0) {
            cities.forEach(city => {
                city.faculties.forEach(faculty => {
                    let networkByFaculty = {};
                    (Object.keys(network) ?? []).forEach(key => {
                        let users = (network[key] ?? []).filter((user) => { return (user.academyPlaces ?? []).some((p) => { return p.facultyId == faculty.facultyId }) });
                        if (users.length > 0) {
                            networkByFaculty[key] = users;
                        }
                    });
                    if (Object.keys(networkByFaculty).length > 0) {
                        faculty.network = networkByFaculty;
                    }
                });
            });
        };

        cities = cities.filter((city) => {
            city.faculties = city.faculties.filter((faculty) => { return faculty.network != null });
            return city.faculties.length > 0
        });
        return cities;
    }


    academyType = (id, informationMissing, managed) => {
        let color = informationMissing ? (managed ? 'orange' : 'gray') : (managed ? 'green' : 'red');
        let hover = informationMissing ? (managed ? 'informationStatus.managedMissingInfo' : 'informationStatus.notManagedMissingInfo') : (managed ? 'informationStatus.managedFullInfo' : 'informationStatus.notManagedFullInfo');
        return (
            <div id={id} className={'box-center size-px-20'}>
                <SVG src={AcademicsIcon} className="size-px-20 " color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    networkPerson = (id, type, value) => {
        let t = this.props.t;
        let color = 'bg-color-black';
        switch (type) {
            case 'external':
                color = 'bg-color-black';
                break;
            case 'invited':
                color = 'bg-color-red';
                break;
            case 'underSignup':
                color = 'bg-color-orange';
                break;
            case 'user':
                color = 'bg-color-green';
                break;
            default:
                break;
        }

        return (
            <div id={type + id} className={'box-center size-px-15 rounded-8 mr-2' + ' ' + color}>
                <Label className='font-size-10 text-color-white'>{value}</Label>
                <UncontrolledTooltip placement="bottom" target={type + id}>{type}</UncontrolledTooltip>
            </div>
        )
    }

    networkPersonModule = (id, branch) => {
        let network = branch.network;
        return (
            <div className='align-horizontal center-vertical'>
                {Object.keys(network).length > 0 &&
                    <div className='align-horizontal'>
                        {network.external?.length > 0 &&
                            this.networkPerson(id, 'external', network.external.length)
                        }
                        {network.invited?.length > 0 &&
                            this.networkPerson(id, 'invited', network.invited.length)
                        }
                        {network.underSignup?.length > 0 &&
                            this.networkPerson(id, 'underSignup', network.underSignup.length)
                        }
                        {network.user?.length > 0 &&
                            this.networkPerson(id, 'user', network.user.length)
                        }
                        <div>
                            <User className="icon-dual size-px-20" />
                        </div>
                    </div>}
            </div>
        )
    }

    invitationModule = (id, inviteable, invitationDate) => {
        let t = this.props.t;
        let color = '';
        if (invitationDate) {
            let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
            let weeks = durationBetweenDates(invitationDate, "DD/MM/YYYY", now, "DD/MM/YYYY", ['weeks']);
            if (weeks < 2) {
                color = 'bg-color-green';
            }
            else if (weeks >= 2 && weeks < 4) {
                color = 'bg-color-orange';
            }
            else {
                color = 'bg-color-red';
            }
        }

        return (
            <div className='box-center mt-1'>
                <Mail
                    className="icon-dual ml-2 icon-size-20"
                    color={color.length > 0 ? 'gray' : (inviteable ? 'green' : 'red')}
                    id={id}
                    onClick={() => { }}
                />
                {color.length > 0 &&
                    <div className={'size-px-10 rounded-5' + ' ' + color} style={{ 'transform': 'translate(-8px, 5px)' }}></div>
                }
                <UncontrolledTooltip placement="bottom" target={id}>
                    {t('network.invite')}
                </UncontrolledTooltip>
            </div>
        )
    }

    childrenFromObject = (object) => {
        if (object == null) {
            return [];
        }

        let child = Object.assign({}, object);
        child.children = null;
        let children = [];
        children.push(child);

        let grandChildren = object.children ?? [];
        if (grandChildren.length > 0) {
            for (let i = 0; i < grandChildren.length; i++) {
                let grand = this.childrenFromObject(grandChildren[i])
                children = children.concat(grand)
            }
        }
        return children
    }

    adminString = (academy, countries) => {
        let admins = '';
        (academy?.admins ?? []).forEach(admin => {
            let name = admin.firstName ?? '';
            if (admin.lastName) {
                name += (name.length > 0 ? ' ' : '') + admin.lastName;
            }

            let location = locationString(academy.country, admin.state, admin.city, countries);

            let adminInfo = name;
            if (location) {
                adminInfo += (adminInfo.length > 0 ? ', ' : '') + location;
            }
            // if (admin.branch) {
            //     adminInfo += (adminInfo.length > 0 ? ', ' : '') + admin.branch;
            // }
            if (adminInfo.length > 0) {
                admins += (admins.length > 0 ? ' | ' : '') + adminInfo;
            }
        });

        if (admins.length > 0) {
            admins = this.props.t('company.admin') + ' : ' + admins;
        }

        return admins;
    }

    createdByString = (academy, countries) => {
        let t = this.props.t;

        let createdBy = academy?.createdByUserDetails?.firstName ?? '';
        if (academy?.createdByUserDetails?.lastName?.length > 0) {
            createdBy += (createdBy.length > 0 ? ' ' : '') + academy?.createdByUserDetails?.lastName;
        }

        let userCountry = academy?.createdByUserDetails?.country ? countryCodeToString(academy?.createdByUserDetails?.country, countries) : '';
        if (createdBy.length > 0 && userCountry?.length > 0) {
            createdBy += (createdBy.length > 0 ? ', ' : '') + userCountry;
        }

        if (createdBy.length == 0) {
            createdBy = t('company.admin');
        }

        let createdDate = academy.dateCreate ? formatDateString(academy.dateCreate, 'MMM. DD, YYYY') : '';

        return (createdDate?.length > 0 ? format(t('general.createdByOn'), createdBy, createdDate) : format(t('general.createdBy'), createdBy));
    }

    getAcademics = () => {
        if (this.props.networkAcademicKeyword != this.state.keyword || this.props.networkAcademics == null) {
            this.props.getNetworkAcademics(this.state.groupByCountry ? NETWORK_ACADEMIC_TYPE_COUNTRY : NETWORK_ACADEMIC_TYPE_SCHOOL, 0, false, this.state.keyword, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    if(this._isMounted)
                        this.setState({ loading: true, error: null });
                },
    
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
    
                callbackOnFinish: () => {
                    // Update loading state?
                    if(this._isMounted)
                        this.setState({ loading: false });
                },
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({ currentPage: page }, () => {
            this.props.getNetworkAcademics(this.state.groupByCountry ? NETWORK_ACADEMIC_TYPE_COUNTRY : NETWORK_ACADEMIC_TYPE_SCHOOL, page, false, this.state.keyword, {
                callbackOnBegin: () => {
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
            });
        });
    }

    viewNetworkAcademicsDetails = (academics) => {
        if (this.props.history) {
            this.props.history.push({
                pathname: '/network-academics-details/' + academics.id,
                state: {
                    networkAcademics: academics
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let groupByCountry = this.state.groupByCountry;

        let network = groupByCountry ? this.academicsNetworkByCountry() : this.academicsNetworkBySchool();
        // if (this.state.keyword?.length >= 3) {
        //     network = network.map((group) => {
        //         let academics = group.academics?.filter((academy) => {
        //             const isValidName = (academy?.name ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());
        //             const isValidDomain = (academy.domains ?? []).some((domain) => { return domain.toLowerCase().includes(this.state.keyword.toLowerCase()) });

        //             let country = academy.country?.length == 0 ? '' : countryCodeToString(academy.country, countries);
        //             const isValidCountry = country?.length == 0 ? true : (country ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());

        //             let isValidCity = false;
        //             if (academy?.cities != null) {
        //                 for (let i = 0; i < academy.cities.length; i++) {
        //                     let city = academy.cities[i];
        //                     let cityName = city.city;
        //                     if (cityName?.length > 0) {
        //                         isValidCity = cityName?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                         if (isValidCity) {
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }

        //             let isValidFaculty = false;
        //             if (academy?.faculties != null) {
        //                 for (let i = 0; i < academy.faculties.length; i++) {
        //                     let faculty = academy.faculties[i];
        //                     let facultyName = faculty.facultyId == 1000 ? (faculty.facultyName ?? t('sub.Others')) : (facultyWithId(faculty.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '');
        //                     if (facultyName?.length > 0) {
        //                         isValidFaculty = facultyName?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                         if (isValidFaculty) {
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }

        //             let isValidAdmin = false;
        //             let adminString = this.adminString(academy, countries);
        //             if (adminString.length > 0) {
        //                 isValidAdmin = adminString?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //             }

        //             let isValidCreatedBy = false;
        //             let createdByString = this.createdByString(academy, countries);
        //             if (createdByString?.length > 0) {
        //                 isValidCreatedBy = createdByString.toLowerCase().includes(this.state.keyword.toLowerCase());
        //             }

        //             let isValidState = false;
        //             if (academy?.cities != null) {
        //                 for (let i = 0; i < academy.cities.length; i++) {
        //                     let city = academy.cities[i];

        //                     let states = city.country?.length == 0 ? null : countries?.find((country) => { return country.value == city.country })?.states;
        //                     if (states == null) {
        //                         return isValidName || isValidDomain || isValidCountry || isValidCity || isValidFaculty || isValidCreatedBy || isValidAdmin;
        //                     }

        //                     let state = states?.find((state) => { return state.id == city.state ?? '' });
        //                     let stateName = state?.name ? getLocalizedString(state?.name) : '';
        //                     isValidState = stateName.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                     if (isValidState) {
        //                         break;
        //                     }
        //                 }
        //             }

        //             return isValidName || isValidCountry || isValidFaculty || isValidDomain || isValidCity || isValidState || isValidCreatedBy || isValidAdmin;
        //         }, this);
        //         group.academics = academics;
        //         return group;
        //     }, this);
        // }
        let filteredNetwork = [];
        if (groupByCountry) {
            filteredNetwork = this.state.selectedCountry == 'All Countries' ? network : network.filter((group) => { return group.country == this.state.selectedCountry });
        }
        else {
            filteredNetwork = this.state.selectedFaculty == -1 ? network : network.filter((group) => { return group.faculty.facultyId == this.state.selectedFaculty });
        }

        let availableCountries = [];
        let networkCountries = [];
        let availableFaculties = [];
        let networkFaculties = [];
        if (groupByCountry) {
            availableCountries = [
                {
                    value: 'All Countries',
                    label: t('country.allCountries'),
                    states: null,
                }
            ];

            networkCountries = network.map((group) => { return group.country });
            networkCountries = networkCountries?.map((countryCode) => {
                if (countryCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return countries.find((c) => { return c.value == countryCode });
            });
            networkCountries.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkCountries.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableCountries = availableCountries.concat(networkCountries);
        }
        else {
            availableFaculties = [
                {
                    value: -1,
                    label: t('academic.allSchools'),
                }
            ];

            networkFaculties = network.map((group) => { return group.faculty });
            networkFaculties = networkFaculties?.map((faculty) => {
                if (faculty.facultyId == 1000) {
                    return {
                        value: faculty.facultyId,
                        label: t('sub.Others'),

                    }
                }

                let facultyName = faculty.facultyId ? (facultyWithId(faculty.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '') : '';
                return {
                    value: faculty.facultyId,
                    label: facultyName
                };
            }, this);
            networkFaculties.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkFaculties.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableFaculties = availableFaculties.concat(networkFaculties);
        }

        let selectedCountry = (this.state.selectedCountry && availableCountries.length > 0) ? availableCountries.find((element) => { return element.value == this.state.selectedCountry }) : null;
        let states = selectedCountry?.states?.map((state, i) => {
            return {
                value: state.id,
                label: getLocalizedString(state.name),
            };
        }) ?? [];

        let availableStates = [];
        if (filteredNetwork.length == 1 && states.length > 0) {
            let academics = filteredNetwork[0].academics ?? [];
            let cityStates = [];
            academics.forEach(academy => {
                let cities = (academy.cities ?? []).filter((c) => { return c.country == this.state.selectedCountry });
                cities.forEach(city => {
                    let state = city.state ?? '';
                    const exist = cityStates.some((stateCode) => { return stateCode == state });
                    if (!exist) {
                        cityStates.push(state);
                    }
                });
            });
            cityStates = cityStates.map((stateCode) => {
                if (stateCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return states.find((c) => { return c.value == stateCode });
            });
            cityStates.sort(function (a, b) { return b.label.localeCompare(a.label) });
            cityStates.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });

            availableStates = [
                {
                    value: 'All States',
                    label: t('country.allStates'),
                }
            ];
            availableStates = availableStates.concat(cityStates);
        }

        filteredNetwork = filteredNetwork.map(group => {
            if (this.state.selectedState != 'All States') {
                group.academics = group.academics?.filter((academy) => { return (academy.cities ?? []).some((city) => { return city.state == (this.state.selectedState == 'Other' ? '' : this.state.selectedState) }) });
            }
            return group;
        }, this);

        filteredNetwork = filteredNetwork.filter((group) => { return group.academics?.length > 0 });


        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                let countryA = a.country?.length == 0 ? '' : countryCodeToString(a.country, countries)
                let countryB = b.country?.length == 0 ? '' : countryCodeToString(b.country, countries)
                return a.country.length == 0 ? 1 : countryB.localeCompare(countryA)
            }
            else {
                let facultyA = a.faculty?.facultyId == 1000 ? 'Others' : (facultyWithId(a.faculty?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '');
                let facultyB = b.faculty?.facultyId == 1000 ? 'Others' : (facultyWithId(b.faculty?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '');
                return facultyA == 'Others' ? 1 : facultyB.localeCompare(facultyA)
            }
        }, this);
        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                return a.country.length == 0 ? 1 : -1
            }
            else {
                return a.faculty?.facultyId == 1000 ? 1 : -1
            }
        });

        return (
            <React.Fragment>
                {(this.state.showAlertPopup ?? false) &&
                    <RemoveNetworkModal
                        modal={this.state.showAlertPopup}
                        networkAcademy={this.state.editingAcademy}
                        onCancel={() => { this.setState({ showAlertPopup: false, editingContact: null }) }}
                        onSubmit={() => {
                            if (this.state.editingAcademy?.id) {
                                this.props.deleteNetworkAcademy(this.state.editingAcademy.id, {
                                    callbackOnBegin: () => {
                                        // Update loading state?
                                        // console.log('callbackOnBegin');
                                        this.setState({ loading: true });
                                    },
                                    callbackOnFailure: (error) => {
                                        // show error to your users or stay quiet
                                        this.setState({ loading: false, error: error });
                                    },
                                    callbackOnFinish: () => {
                                        // Update loading state?
                                        this.setState({ loading: false });
                                    },
                                    callbackOnSuccess: (response) => {
                                        this.setState({ showAlertPopup: false, editingAcademy: null })
                                    },
                                });
                            }
                        }}
                    />
                }

                {this.state.showAddCompanyDialog == 1 &&
                    <AddAcademyModal
                        id="add-contact"
                        title={t('profile.updatePersonalInformation')}
                        academicDoc={this.props.academicDoc}
                        editingAcademy={this.state.editingAcademy ? JSON.parse(JSON.stringify(this.state.editingAcademy)) : null}
                        modal={this.state.showAddCompanyDialog == 1}
                        close={this.closeAddAcademyModal}
                    />
                }
                <Row className="step-company">
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        <div className="path-component align-horizontal">
                            <Label className="link" onClick={this.onBack}>{t('Network')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link-selected">{t('education.academics')} </Label>
                            <div id="add-contact" className='align-horizontal'>
                                <SVG src={addAcademyIcon} className="ml-3 size-px-30" onClick={() => { this.showAddAcademyModal() }} />
                                <UncontrolledTooltip placement="bottom" target="add-contact">
                                    {t('network.addAcademy')}
                                </UncontrolledTooltip>
                                {/* <div className='box-center size-px-10 rounded-5 bg-color-test1' style={{ 'transform': 'translate(-5px, 0px)' }}>
                                    <Label className='mt-0 mb-0 font-bold'>+</Label>
                                </div> */}
                            </div>
                        </div>
                    </CardBody>
                    <CardBody className="text-box">
                        <Row className='ml-4'>
                            <Col xl={3}>
                                {groupByCountry ?
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableCountries}
                                        placeholder={t('placeholder.Select')}
                                        value={selectedCountry}
                                        onChange={(value) => {
                                            if (this.state.selectedCountry != value.value) {
                                                this.setState({ selectedCountry: value.value == 'Other' ? '' : value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                    :
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableFaculties}
                                        placeholder={t('placeholder.Select')}
                                        value={(this.state.selectedFaculty && availableFaculties.length > 0) ? availableFaculties.find((element) => { return element.value == this.state.selectedFaculty }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedFaculty != value.value) {
                                                this.setState({ selectedFaculty: value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                }
                            </Col>
                            {states.length > 0 &&
                                <Col xl={3}>

                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableStates}
                                        placeholder={t('placeholder.Select')}
                                        value={this.state.selectedState ? availableStates.find((element) => { return element.value == this.state.selectedState }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedState != value.value) {
                                                this.setState({ selectedState: value.value })
                                            }
                                        }} />

                                </Col>
                            }

                            <Col xl={3} className='box-side'>
                                <div className="width-percent-100 float-sm-right mt-3 mt-sm-0 mb-3">
                                    <div className="width-percent-100 task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control search-input rounded-5"
                                                    placeholder="Search..."
                                                    value={this.state.keyword ?? ''}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({ keyword: e.target.value ?? '' }, () => {
                                                            if (!(this.props.networkAcademicKeyword.length < 3 && this.state.keyword.length < 3)) {
                                                                this.getAcademics();
                                                            }
                                                        })
                                                    }}
                                                />
                                                <span className="uil uil-search icon-search"></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>

                            <Col xl={3} className='box-side'>
                                <div className='align-horizontal mb-2'>
                                    <div>
                                        <label className="cursor-pointer mr-2 mb-3" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            {t('company.byCountry')}
                                        </label>
                                    </div>
                                    {/* <div className='hard-custom-switch'> */}
                                    <div className="custom-control custom-switch mb-3 cursor-pointer" onClick={() => {
                                        this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                            this.handlePageClick(0);
                                        })
                                    }}>
                                        <input
                                            type="radio"
                                            className="custom-control-input cursor-pointer"
                                            checked={!this.state.groupByCountry}
                                            onChange={() => {}}
                                        />
                                        <label className="custom-control-label cursor-pointer">
                                            {t('academic.bySchools')}
                                        </label>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </Col>

                            <Col className='box-side'>
                                {groupByCountry && states.length > 0 &&
                                    <div></div>
                                }
                                <div className={"custom-control custom-switch mb-3 cursor-pointer" + (groupByCountry ? ' mr-2' : '')} onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}>
                                    <input
                                        type="radio"
                                        className="custom-control-input cursor-pointer"
                                        checked={this.state.showDetails}
                                        onChange={() => {}}
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                                        {t('general.details')}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <br />

                        {filteredNetwork.length > 0 && filteredNetwork.map((item, index) => {
                            let country = item.country?.length == 0 ? t('sub.Others') : countryCodeToString(item.country, countries);
                            let faculty = item.faculty?.facultyId == 1000 ? t('sub.Others') : (facultyWithId(item.faculty?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '');

                            return (
                                <div key={'network' + index} >
                                    <div className={'mb-3'}>
                                        <Row className="path-component ml-4">
                                            <Col>
                                                <Label className='link-selected'>{groupByCountry ? (country ?? '') : (faculty ?? '')}</Label>
                                            </Col>
                                        </Row>
                                        {item.academics.length > 0 && item.academics.map((academy, academyIndex) => {
                                            let removingInfo = {};
                                            removingInfo.id = academy.id;

                                            let info = academy?.name ?? '';
                                            removingInfo.name = academy?.name;

                                            let faculties = '';
                                            let faculs = [];
                                            (academy?.faculties ?? []).forEach(faculty => {
                                                let facultyName = '';
                                                if (faculty.facultyId == 1000) {
                                                    facultyName = faculty.facultyName ?? t('sub.Others');
                                                }
                                                else {
                                                    facultyName = facultyWithId(faculty.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '';
                                                }
                                                if (facultyName?.length > 0) {
                                                    faculties += (faculties.length > 0 ? ', ' : '') + facultyName;
                                                    faculs.push(facultyName);
                                                }
                                            });
                                            removingInfo.faculties = faculs;

                                            if (faculties.length > 0) {
                                                info += (info.length > 0 ? ' | ' : '') + faculties;
                                            }

                                            let domains = '';
                                            (academy?.domains ?? []).forEach(domain => {
                                                domains += (domains.length > 0 ? ', ' : '') + domain;
                                            });
                                            removingInfo.domains = academy?.domains;

                                            let admins = this.adminString(academy, countries);
                                            removingInfo.admins = academy?.admins;

                                            let createdByString = this.createdByString(academy, countries);

                                            let cities = this.networkCities(academy);
                                            removingInfo.cities = academy?.cities;

                                            const hasDetails = cities.length > 0 || admins.length > 0 || createdByString.length > 0;

                                            let companyBranches = [
                                                { location: 'Vietnam', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: true },
                                                { location: 'AMERICAS', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: false },
                                                { location: 'APAC', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: false }
                                            ]

                                            let branches = companyBranches.filter((br) => { return (br.headQuater ?? false) == false })

                                            const informationMissing = academy?.name == null || academy?.cities?.length == 0 || academy?.faculties?.length == 0;

                                            let loggedInUser = getLoggedInUser();
                                            const managed = academy.admins?.length > 0;

                                            return (
                                                <div key={academyIndex} className='ml-4'>
                                                    <div className='align-horizontal path-component ml-2'>
                                                        {this.academyType('academyType' + index + academyIndex, informationMissing, managed)}
                                                        <Label className='ml-2 link-horver font-size-15' onClick={() => { this.viewNetworkAcademicsDetails(academy) }}>{info}</Label>

                                                        {Object.keys(academy.network).length >= 1 &&
                                                            <div className='align-horizontal'>
                                                                {info?.length > 0 &&
                                                                    <div className='ml-3 mr-3'>|</div>
                                                                }
                                                                {this.networkPersonModule('' + index + academyIndex + 'hq', academy)}
                                                            </div>
                                                        }

                                                        {(academy?.createdByUserId == getLoggedInUser()?.id || isAdmin() || managed) &&
                                                            <div onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.showAddAcademyModal(academy)
                                                            }}>
                                                                <Edit className="icon-size-20 cursor-pointer ml-2" color='gray' id={'edit' + index + academyIndex} />
                                                                <UncontrolledTooltip placement="bottom" target={'edit' + index + academyIndex}>
                                                                    {t('network.editNetworkAcademy')}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        }

                                                        {!managed &&
                                                            <div className='float-right size-px-20 box-center ml-2'>
                                                                <Trash className="icon-dual size-px-20 cursor-pointer" id={'delete' + index + academyIndex}
                                                                    onClick={() => {
                                                                        this.setState({ editingAcademy: removingInfo, showAlertPopup: 1 });
                                                                    }} />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    id='tooltip-1'
                                                                    target={'delete' + index + academyIndex}>
                                                                    {t("experience.hover.removePerson")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        }

                                                    </div>
                                                    {this.props.didLoadAcademicsDetails && this.state.showDetails && hasDetails &&
                                                        <div className='bg-color-details align-vertical ml-2'>
                                                            {cities.length > 0 && cities.map((city, cityIndex) => {
                                                                return (
                                                                    <Row className='ml-4 align-horizontal'>
                                                                        <Label className='mb-0 mr-4 ml-1 mt-1'>{city.city?.city ?? ''}</Label>
                                                                        {city.faculties?.length > 0 && city.faculties?.map((f) => {
                                                                            let facultyName = f.facultyId == 1000 ? (f.facultyName ?? t('sub.Others')) : (facultyWithId(f.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '');
                                                                            return (
                                                                                <div className='align-horizontal mr-4'>
                                                                                    {f.network && Object.keys(f.network).length > 0 &&
                                                                                        <div>
                                                                                            {this.networkPersonModule('' + index + academyIndex + cityIndex, f)}
                                                                                        </div>
                                                                                    }
                                                                                    <Label className='ml-2 mr-2'>{'|'}</Label>
                                                                                    <Label>{facultyName}</Label>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                )
                                                            }, this)
                                                            }

                                                            {domains.length > 0 &&
                                                                <Row className='ml-3 mb-1 mt-1'>
                                                                    <Col className='align-horizontal'>
                                                                        <Label className='mt-0 mb-1'>{t('company.domains')} :</Label>
                                                                        <Label className='ml-1 mt-0 mb-1'>{domains}</Label>
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {admins.length > 0 &&
                                                                <Row className='ml-3 mb-1 mt-1'>
                                                                    <Col className='align-horizontal'>
                                                                        <Label className='mb-0 mt-0 mb-1'>{admins}</Label>
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {createdByString.length > 0 &&
                                                                <Row className='ml-3 mb-1 mt-1'>
                                                                    <Col>
                                                                        <Label className='mb-0 mt-1 font-size-10 text-color-system-notice'>{createdByString}</Label>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }, this)}
                                    </div>
                                    {(index < filteredNetwork.length - 1) &&
                                        <Row className="path-component ml-3">
                                            <Col>
                                                <div className='separator-line mb-3'></div>
                                            </Col>
                                        </Row>

                                    }
                                </div>
                            )
                        }, this)}

                        {this.props.networkAcademics?.total_pages > 1 &&
                            <div className='float-right mt-2'>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.props.networkAcademics?.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    forcePage={this.state.currentPage}
                                    onPageChange={(e) => { this.handlePageClick(e.selected) }}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, menuHierarchy, loading, error } = state.Profile;
    const { countries } = state.Auth;
    const { academicDoc } = state.Academics;
    const { networkAcademicKeyword, networkAcademics, didLoadAcademicsDetails } = state.Network;

    return { menuHierarchy, subscriptionProfile, countries, academicDoc, networkAcademicKeyword, networkAcademics, didLoadAcademicsDetails, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAcademicDoc,
    getNetworkAcademics,
    deleteNetworkAcademy
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademics));
