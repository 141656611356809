// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    GET_ACADEMICS, GET_ACADEMICS_FAILED, GET_ACADEMICS_SUCCESS,
    GET_ACADEMIC_BY_ID, GET_ACADEMIC_BY_ID_SUCCESS, GET_ACADEMIC_BY_ID_FAILED,
    GET_ACADEMIC_DOC, GET_ACADEMIC_DOC_FAILED, GET_ACADEMIC_DOC_SUCCESS,
    CREATE_ACADEMIC, CREATE_ACADEMIC_FAILED, CREATE_ACADEMIC_SUCCESS,
    GET_ACADEMICS_IN_COUNTRY, GET_ACADEMICS_IN_COUNTRY_FAILED, GET_ACADEMICS_IN_COUNTRY_SUCCESS,
    DELETE_ACADEMIC, DELETE_ACADEMIC_FAILED, DELETE_ACADEMIC_SUCCESS,
    GET_PUBLIC_ACADEMY, GET_PUBLIC_ACADEMY_SUCCESS, GET_PUBLIC_ACADEMY_FAILED,
    GET_EMAILS_ACADEMICS, GET_EMAILS_ACADEMICS_SUCCESS, GET_EMAILS_ACADEMICS_FAILED,
    GET_ACADEMIC_GOALS, GET_ACADEMIC_GOALS_SUCCESS, GET_ACADEMIC_GOALS_FAILED,
    GET_ACADEMIC_FELLOWS, GET_ACADEMIC_FELLOWS_SUCCESS, GET_ACADEMIC_FELLOWS_FAILED

} from './constants';

import { DELETE_NETWORK_COMPANY_GOAL, DELETE_NETWORK_COMPANY_GOAL_SUCCESS, DELETE_NETWORK_COMPANY_GOAL_FAILED } from '../network/constants';


const INIT_STATE = {
    academicsOwnderId: null,
    academics: {},
    academy: null,
    academicsInCountry: null,
    academicDoc: null,
    loading: false,
    error: null,
    publicAcademy: null,
    emailsAcademics: null,
    academicGoals: null
};

const Academics = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ACADEMICS:
            return { ...state, academicsOwnderId: action.payload.userId, loading: true };
        case GET_ACADEMICS_SUCCESS:
            if (state.academicsOwnderId == action.payload.userId) {
                return { ...state, academics: action.payload.data, loading: false, error: null };
            }
            else {
                return { ...state, loading: false, error: null };
            }
        case GET_ACADEMICS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACADEMIC_BY_ID:
            return { ...state, loading: true };
        case GET_ACADEMIC_BY_ID_SUCCESS:
            return { ...state, academy: action.payload, loading: false, error: null };
        case GET_ACADEMIC_BY_ID_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACADEMICS_IN_COUNTRY:
            return { ...state, loading: true };
        case GET_ACADEMICS_IN_COUNTRY_SUCCESS:
            return { ...state, academicsInCountry: action.payload, loading: false, error: null };
        case GET_ACADEMICS_IN_COUNTRY_FAILED:
            return { ...state, academicsInCountry: null, error: action.payload, loading: false };

        case GET_ACADEMIC_DOC:
            return { ...state, loading: true };
        case GET_ACADEMIC_DOC_SUCCESS:
            return { ...state, academicDoc: action.payload, loading: false, error: null };
        case GET_ACADEMIC_DOC_FAILED:
            return { ...state, academicDoc: null, error: action.payload, loading: false };

        case CREATE_ACADEMIC:
            return { ...state, loading: true };
        case CREATE_ACADEMIC_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_ACADEMIC:
            return { ...state, loading: true };
        case DELETE_ACADEMIC_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PUBLIC_ACADEMY:
            return { ...state, loading: true };
        case GET_PUBLIC_ACADEMY_SUCCESS:
            return { ...state, publicAcademy: action.payload, loading: false, error: null };
        case GET_PUBLIC_ACADEMY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EMAILS_ACADEMICS:
            return { ...state, loading: true };
        case GET_EMAILS_ACADEMICS_SUCCESS:
            return { ...state, emailsAcademics: action.payload, loading: false, error: null };
        case GET_EMAILS_ACADEMICS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACADEMIC_GOALS:
            return { ...state, loading: true };
        case GET_ACADEMIC_GOALS_SUCCESS:
            return { ...state, academicGoals: action.payload, loading: false, error: null };
        case GET_ACADEMIC_GOALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACADEMIC_FELLOWS:
            return { ...state, loading: true };
        case GET_ACADEMIC_FELLOWS_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_ACADEMIC_FELLOWS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case DELETE_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                let currentGoals = state.academicGoals ?? [];
                let index = currentGoals.findIndex((element) => { return element.id == action.payload.response });
                if (index >= 0) {
                    currentGoals.splice(index, 1);
                }
                return { ...state, academicGoals: currentGoals, loading: false, error: null };
            }
        case DELETE_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Academics;
