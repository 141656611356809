// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { allLanguages, childrenOfObject, getLocalizedString } from '../../../../helpers/utils';
import {
    getAPICountryInfo,
    getGoalsByUser,
    createNetworkCompanyGoal,
    editNetworkCompanyGoal,
    getAllNetworkPeople
} from '../../../../redux/actions';
import { PlusSquare, Trash } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import { getLoggedInUserProfile } from '../../../../helpers/authUtils';
import { NETWORK_PERSON_TYPE_COUNTRY } from './NetworkPerson';

class AddGoalModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'lg',
            selectedGoal: { id: 1000, label: this.props.t('experience.New') },
            goalName: '',
            goalLanguage: null,
            goalDescription: '',

            isSelectingIssue: false,
            selectedIssue: null,
            issueDescription: '',
            issues: [],
            issueHieachy: [],

            isSelectingContributor: false,
            selectedContributor: null,
            contributors: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.goalsByUser == null) {
            this.props.getGoalsByUser({});
        }
        // if (this.props.allNetworkPeople == null) {
        //     this.props.getAllNetworkPeople({});
        // }

        this.defaultIssuesHieachy();

        this.prefillData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.menuHierarchy != prevProps.menuHierarchy) {
            this.defaultIssuesHieachy();
        }

        if (this.props.allNetworkPeople != prevProps.allNetworkPeople) {
            this.prefillData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    prefillData = () => {
        let editingGoal = this.props.editingGoal;
        if (editingGoal) {
            let languages = allLanguages();

            let state = {};
            state.goalName = editingGoal?.name ?? '';
            state.goalDescription = editingGoal?.description ?? '';
            state.selectedLanguage = editingGoal?.language ? languages?.find((element) => { return element.code == editingGoal?.language }) : null;
            state.issues = editingGoal?.issues?.map((element) => { return { issue: element.issue?.id, description: element.description } });

            let allContributiors = this.contributors();
            let contributors = [];
            (editingGoal?.contributors ?? []).forEach(nodeId => {
                let contributor = allContributiors.find((element) => { return element.nodeId == nodeId });
                if (contributor) {
                    contributors.push(contributor);
                }
            });

            let loggedInUser = getLoggedInUserProfile()?.user;
            if (loggedInUser && contributors.some((element) => { return element.nodeId == loggedInUser.nodeId })) {
                contributors = contributors.filter((element) => { return element.nodeId != loggedInUser.nodeId });
                loggedInUser.label = '- ' + this.props.t('profile.me') + ' -';
                contributors.unshift(loggedInUser);
            }

            state.contributors = contributors;

            this.setState(state);
        }
    }

    onAddGoal = () => {
        let params = {};
        params.name = this.state.goalName ?? '';
        params.language = this.state.selectedLanguage?.code;
        params.description = this.state.goalDescription ?? '';
        params.issues = this.state.issues.map((element) => { return { issueId: element.issue, description: element.description } });
        params.contributors = this.state.contributors.map((element) => { return element.nodeId });

        let string = JSON.stringify(params);

        if (this.props.editingGoal) {
            if (this.props.editingGoal?.id) {
                this.props.editNetworkCompanyGoal(this.props.editingGoal?.id, params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                })
            }
        }
        else {
            if (this.props.companyNodeId && this.props.companyNodeId >= 0) {
                this.props.createNetworkCompanyGoal(this.props.companyNodeId, params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                })
            }
        }

    }

    goals = () => {
        let goals = [];//this.props.goalsByUser?.map((goal) => { goal.label = goal.name ?? ''; return goal }) ?? [];
        goals.push({ id: 1000, label: this.props.t('experience.New') })

        return goals;
    }

    selectGoal = (value) => {
        let state = {};
        state.selectedGoal = value;
        if (value != this.state.selectedGoal) {
            state.goalLanguage = null;
            state.goalDescription = '';
        }

        this.setState(state);
    }

    defaultIssuesHieachy = () => {
        if (this.props.menuHierarchy?.issueGroup) {
            this.addIssueGroupToIssueHieachy([], this.props.menuHierarchy?.issueGroup);
        }
    }

    addIssueGroupToIssueHieachy = (issueHieachy, issueGroup) => {
        if (issueGroup == null) {
            return
        }
        let hieachy = issueHieachy;
        let children = issueGroup.children?.map((element) => { return { value: element.id, label: element.name, entity: element } }) ?? [];
        let group = { value: issueGroup.id, label: issueGroup.name, children: children, selectedChild: null, entity: issueGroup };
        hieachy.push(group);
        this.setState({ issueHieachy: hieachy });
    }

    selectIssueGroup = (issueGroup) => {
        let hieachy = this.state.issueHieachy;
        let index = hieachy.findIndex((element) => { return element.children.find((e) => { return e.value == issueGroup.id }) != null })
        if (index >= 0) {
            hieachy[index].selectedChild = issueGroup;
            if (hieachy.length > index + 1) {
                hieachy = hieachy.slice(0, index + 1);
            }
        }
        this.addIssueGroupToIssueHieachy(hieachy, issueGroup);
        this.setState({ selectedIssue: issueGroup }, () => { })
    }

    cancelAddingIssue = () => {
        this.setState({ isSelectingIssue: false, selectedIssue: null, issueDescription: '' }, () => {
            this.defaultIssuesHieachy();
        })
    }

    finishAddingIssue = () => {
        var issues = this.state.issues;
        if (this.state.selectedIssue?.id && !issues.some((element) => { return element == this.state.selectedIssue?.id })) {
            issues.push({
                issue: this.state.selectedIssue?.id,
                description: this.state.issueDescription
            })
        }

        this.setState({ isSelectingIssue: false, issues: issues, selectedIssue: null, issueDescription: '' }, () => {
            this.defaultIssuesHieachy();
        })
    }

    contributors = () => {
        let contributors = this.props.allNetworkPeople?.map((element) => {
            let name = element.firstName ?? '';
            if (element.lastName?.length > 0) {
                name += (name.length > 0 ? ' ' : '') + element.lastName;
            }
            element.label = name;
            return element;
        }) ?? [];

        contributors = contributors.filter((element) => { return element.nodeId != null });

        if (contributors.length > 1) {
            contributors.sort((a, b) => a.label > b.label ? 1 : -1);
        }

        let loggedInUser = getLoggedInUserProfile()?.user;
        if (loggedInUser) {
            contributors = contributors.filter((element) => { return element.nodeId != loggedInUser.nodeId });
            loggedInUser.label = '- ' + this.props.t('profile.me') + ' -';
            contributors.unshift(loggedInUser);
        }

        return contributors;
    }

    cancelAddingContributor = () => {
        this.setState({ isSelectingContributor: false, selectedContributor: null })
    }

    finishAddingContributor = () => {
        var contributors = this.state.contributors;
        if (this.state.selectedContributor?.nodeId && !contributors.some((element) => { return element.nodeId == this.state.selectedContributor?.nodeId })) {
            contributors.push(this.state.selectedContributor);
        }

        this.setState({ isSelectingContributor: false, contributors: contributors, selectedContributor: null })
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t, menuHierarchy } = this.props;

        let languages = allLanguages();

        let issueHieachy = this.state.issueHieachy;
        let issueGroup = childrenOfObject(menuHierarchy?.issueGroup);
        if (issueGroup.length > 0) {
            issueGroup.shift();
            issueGroup = issueGroup.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        let contributors = this.contributors();

        const saveEnable = this.state.selectedLanguage != null && this.state.goalName?.length > 0;

        let headerTitle = format(t(this.props.editingGoal ? t('general.modify0') : t('general.add0')), t('experience.Goal').toLowerCase());

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}
                    style={{ maxWidth: '600px', width: '100%' }}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}
                                            <Row>
                                                <Col xl={12}>
                                                    {/* <Row className='mt-2 mb-4'>
                                                        <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                            <Label className='link-selected font-size-15'>{format(t('general.select0'), t('experience.Goal').toLowerCase())}</Label>
                                                        </Col>
                                                        <Col>
                                                            <Select className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="states" id="states"
                                                                placeholder={t('placeholder.Select')}
                                                                options={goals}
                                                                value={this.state.selectedGoal}
                                                                onChange={(value) => { this.selectGoal(value) }}
                                                            />
                                                        </Col>
                                                    </Row> */}

                                                    {this.state.selectedGoal?.id == 1000 &&
                                                        <div>
                                                            <Row className='mb-4'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{format(t('general.select0'), t('experience.Language').toLowerCase())}</Label>
                                                                </Col>
                                                                <Col>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        name="career" id="career"
                                                                        options={languages ?? []}
                                                                        placeholder={t('placeholder.Select')}
                                                                        value={this.state.selectedLanguage}
                                                                        onChange={(value) => { this.setState({ selectedLanguage: value }) }} />
                                                                </Col>
                                                            </Row>

                                                            <Row className='mt-2 mb-4'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                    <Label className='link-selected font-size-15' for="firstName">{t("experience.GoalName")}</Label>
                                                                </Col>
                                                                <Col md={4} xl={8}>
                                                                    <InputGroup className='input-container'>
                                                                        <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={format(t('general.enter0'), t("experience.GoalName").toLowerCase())}
                                                                            autoComplete="off"
                                                                            value={this.state.goalName ?? ""}
                                                                            onChange={(e) => { this.setState({ goalName: e.target?.value }) }} />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row className='mt-2'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                    <Label className='link-selected font-size-15' for="firstName">{t("nexions.description")}</Label>
                                                                </Col>
                                                                <Col md={4} xl={8}>
                                                                    <InputGroup className='input-container'>
                                                                        <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t('nexions.description')}
                                                                            autoComplete="off"
                                                                            value={this.state.goalDescription ?? ""}
                                                                            onChange={(e) => { this.setState({ goalDescription: e.target?.value }) }} />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }

                                                    <Row className='mt-2 mb-0'>
                                                        <Col className='path-component font-size-15 text-left mt-2'>
                                                            <Label className='link-selected font-size-15'>{t('topBar.issues')}</Label>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mb-4 border-1 rounded-3'>
                                                        <div className='align-vertical width-percent-100'>
                                                            {this.state.issues?.length > 0 && this.state.issues.map((element, index) => {
                                                                let issue = issueGroup?.find((e) => { return e.value == element.issue });

                                                                return <div key={index}>
                                                                    <div className='align-vertical'>
                                                                        <div className='align-horizontal ml-3'>
                                                                            <Label>{issue?.label ?? ''}</Label>
                                                                            <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                var issues = this.state.issues;
                                                                                issues.splice(index, 1);
                                                                                this.setState({ issues: issues });
                                                                            }}>
                                                                                <Trash className="icon-dual ml-2 icon-size-20" id="remove-nexion-issue" />
                                                                                <UncontrolledTooltip
                                                                                    placement="top"
                                                                                    id='tooltip-1'
                                                                                    target='remove-nexion-issue'>
                                                                                    {t("experience.hover.removeIssue")}
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        </div>
                                                                        <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element.description}</Label>
                                                                    </div>

                                                                    {(index == this.state.issues.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                </div>
                                                            })}

                                                            <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                {!this.state.isSelectingIssue &&
                                                                    <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                        <div className='align-horizontal'>
                                                                            <PlusSquare className="icon-dual icon-size-20" id="icon-add-issue"
                                                                                onClick={() => {
                                                                                    if (this.state.isSelectingIssue) {
                                                                                        this.cancelAddingIssue()
                                                                                    }
                                                                                    else {
                                                                                        this.setState({ isSelectingIssue: true })
                                                                                    }
                                                                                }} />
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                id='tooltip-1'
                                                                                target='icon-add-issue'>
                                                                                {t("experience.hover.addIssue")}
                                                                            </UncontrolledTooltip>
                                                                        </div>

                                                                        <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                            onClick={() => {
                                                                                if (this.state.isSelectingIssue) {
                                                                                    this.cancelAddingIssue()
                                                                                }
                                                                                else {
                                                                                    this.setState({ isSelectingIssue: true })
                                                                                }
                                                                            }}
                                                                        >{t('nexions.issue')}</Label>
                                                                    </div>
                                                                }

                                                                {(this.state.isSelectingIssue || this.state.issues.length > 0) &&
                                                                    <div className='width-percent-100'>
                                                                        {this.state.isSelectingIssue &&
                                                                            <div className='input-container'>
                                                                                {issueHieachy.length > 0 &&
                                                                                    issueHieachy.map((element, index) => {
                                                                                        let issues = element.children;
                                                                                        let selectedIssue = element.selectedChild;
                                                                                        return (
                                                                                            issues.length > 0 ?
                                                                                                <Select className="react-select mb-3"
                                                                                                    classNamePrefix="react-select"
                                                                                                    name="career" id="career"
                                                                                                    options={issues}
                                                                                                    placeholder={t('placeholder.Select')}
                                                                                                    value={(selectedIssue != null && issues?.length > 0) ? issues.find((element) => { return element.value == selectedIssue.value }) : null}
                                                                                                    onChange={(value) => { this.selectIssueGroup(value.entity) }} />
                                                                                                : <div></div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                <Input
                                                                                    className="react-select mt-3"
                                                                                    classNamePrefix="react-select"
                                                                                    type="text"
                                                                                    name="goalDescription"
                                                                                    id="goalDescription"
                                                                                    autoComplete="off"
                                                                                    placeholder={t('nexions.description')}
                                                                                    value={this.state.issueDescription}
                                                                                    onChange={(e) => { this.setState({ issueDescription: e.target.value }) }}></Input>

                                                                                <ul className="list-inline wizard mt-3">
                                                                                    <li className="previous list-inline-item">
                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingIssue() }} color="info">{t("personalInfo.cancel")}</Button>
                                                                                        <Button onClick={() => { this.finishAddingIssue() }} color="success">{t('general.Add')}</Button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                            </Col>
                                                        </div>
                                                    </Row>

                                                    <Row className='mt-2 mb-0'>
                                                        <Col className='path-component font-size-15 text-left mt-2'>
                                                            <Label className='link-selected font-size-15'>{t('general.contributors')}</Label>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mb-4 border-1 rounded-3'>
                                                        <div className='align-vertical width-percent-100'>
                                                            {this.state.contributors?.length > 0 && this.state.contributors.map((element, index) => {
                                                                return <div key={index}>
                                                                    <div className='align-vertical'>
                                                                        <div className='align-horizontal ml-3'>
                                                                            <Label>{element.label}</Label>
                                                                            <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                var contributors = this.state.contributors;
                                                                                contributors.splice(index, 1);
                                                                                this.setState({ contributors: contributors });
                                                                            }}>
                                                                                <Trash className="icon-dual ml-2 icon-size-20" id="remove-contributor" />
                                                                                <UncontrolledTooltip
                                                                                    placement="top"
                                                                                    id='tooltip-1'
                                                                                    target='remove-contributor'>
                                                                                    {t("experience.hover.removeContributor")}
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {(index == this.state.contributors.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                </div>
                                                            })}

                                                            <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                {!this.state.isSelectingContributor &&
                                                                    <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                        <div className='align-horizontal'>
                                                                            <PlusSquare className="icon-dual icon-size-20" id="icon-add-issue"
                                                                                onClick={() => {
                                                                                    if (this.state.isSelectingContributor) {
                                                                                        this.cancelAddingContributor()
                                                                                    }
                                                                                    else {
                                                                                        this.setState({ isSelectingContributor: true })
                                                                                    }
                                                                                }} />
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                id='tooltip-1'
                                                                                target='icon-add-issue'>
                                                                                {t("experience.hover.addIssue")}
                                                                            </UncontrolledTooltip>
                                                                        </div>

                                                                        <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                            onClick={() => {
                                                                                if (this.state.isSelectingContributor) {
                                                                                    this.cancelAddingContributor()
                                                                                }
                                                                                else {
                                                                                    this.setState({ isSelectingContributor: true })
                                                                                }
                                                                            }}
                                                                        >{t('general.contributor')}</Label>
                                                                    </div>
                                                                }

                                                                {(this.state.isSelectingContributor || this.state.contributors.length > 0) &&
                                                                    <div className='width-percent-100'>
                                                                        {this.state.isSelectingContributor &&
                                                                            <div className='input-container'>
                                                                                <Select className="react-select mb-3"
                                                                                    classNamePrefix="react-select"
                                                                                    name="career" id="career"
                                                                                    options={contributors}
                                                                                    placeholder={t('placeholder.Select')}
                                                                                    value={this.state.selectedContributor}
                                                                                    onChange={(value) => { this.setState({ selectedContributor: value }) }} />

                                                                                <ul className="list-inline wizard mt-3">
                                                                                    <li className="previous list-inline-item">
                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingContributor() }} color="info">{t("personalInfo.cancel")}</Button>
                                                                                        <Button onClick={() => { this.finishAddingContributor() }} color="success">{t('general.Add')}</Button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                            </Col>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>


                                            <br />

                                            <Row>
                                                <Col md={1} xl={1}></Col>
                                                <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                    <div cla>
                                                        <Button color="white" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.props.close(null);
                                                        }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                    </div>
                                                    <div>
                                                        <Button className='ml-2' disabled={!saveEnable} color="primary" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.onAddGoal();
                                                        }}><i className='uil'></i>{t(this.props.editingGoal ? 'personalInfo.save' : 'general.Add')}</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { menuHierarchy, goalsByUser } = state.Profile;
    const { network, existUsers, allNetworkPeople } = state.Network;
    const { companies, companySpecialties } = state.Company;
    return { country, countries, menuHierarchy, goalsByUser, network, existUsers, allNetworkPeople, companies, companySpecialties, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    getGoalsByUser,
    createNetworkCompanyGoal,
    editNetworkCompanyGoal,
    getAllNetworkPeople
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddGoalModal));