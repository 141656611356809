// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { allLanguages, childrenOfObject, countryCodeToString, facultyWithId, getLocalizedString, locationString } from '../../../../helpers/utils';
import { getAPICountryInfo, checkExistAcademy, checkExistNetworkDomain, checkExistAcademyDomains, addExistAcademicToNetwork, getAllAcademics, createNetworkAcademy, editNetworkAcademy, getAllNetworkAcademics } from '../../../../redux/actions';
import { PlusSquare, Trash, User } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import information from '../../../../assets/images/information.png';
import SVG from 'react-inlinesvg';
import isValidDomain from 'is-valid-domain';
import SimpleCheckbox from '../../Supports/SimpleCheckbox';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export const ADD_ACADEMY_IDENTIFICATION = 0;
export const ADD_ACADEMY_FACULTY = 1;
export const ADD_ACADEMY_EXIST_ACADEMY = 3;
export const ADD_ACADEMY_EXIST_DOMAIN = 4;
export const ADD_ACADEMY_NEW = 5;
export const ADD_ACADEMY_NEW_DOMAIN = 6;
export const ADD_ACADEMY_NETWORKED = 7;
export const ADD_ACADEMY_COUNTRIES = 8;

class AddAcademyModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            selectedCountry: null,
            size: 'xx',
            addAcademyStep: ADD_ACADEMY_IDENTIFICATION,
            academyName: '',
            selectedLanguage: null,
            selectedCountry: null,
            selectedAcademyCountry: null,
            selectedState: null,
            cityName: '',
            selectedCity: null,
            isAddingCountry: false,
            isAddingCity: false,
            academicCountries: [],
            isAddingDomain: false,
            domains: [],
            domain: '',
            isDomain: true,
            domainOwner: null,
            displayingFaculty: 0,
            isAddingFaculty: false,
            selectedFaculty: null,
            faculties: [],
            isAddingSpecialties: false,
            selectedSpecialties: null,
            isAddingGraduateDegree: false,
            selectedGraduateDegree: null,
            showExistDomains: false,
            didCheckedAcademy: false,
            useSameName: false,
            didCheckedDomains: false,
            existDomains: [],
            selectingFacultyIndex: null,
            allCountries: []
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        this.props.getAllAcademics(0, 0, false, '', {
            callbackOnSuccess: (response) => {
                this.setState({allAcademics: response})
            },
        });

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({
                callbackOnSuccess: (response) => {
                    this.filterCountry();
                },
            });
        }
        else {
            this.filterCountry();
        }

        if (this.props.allNetworkAcademics == null) {
            this.props.getAllNetworkAcademics({});
        }

        if (this.props.editingAcademy) {
            this.populateAcademy(this.props.editingAcademy);
        }

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        this.setState({allCountries: countries});
    }

    populateAcademy = (editingAcademy) => {
        console.log('selecged', editingAcademy);
        let academyName = editingAcademy?.name ?? '';

        let languages = allLanguages();
        let selectedLanguage = editingAcademy?.language ? languages.find((element) => { return element.code == editingAcademy.language }) : null;

        let domains = editingAcademy?.domains ?? [];

        let academicCountries = [];
        (editingAcademy?.cities ?? []).forEach(city => {
            let countryIndex = academicCountries.findIndex((c) => { return c.country == city.country });
            if (countryIndex >= 0) {
                if (!academicCountries[countryIndex].cities.some((c) => { return c.state == city.state && c.city == city.city })) {
                    academicCountries[countryIndex].cities.push({ state: city.state, city: city.city, selected: false, faculties: [] });
                }
            }
            else {
                academicCountries.push({ country: city.country, cities: [{ state: city.state, city: city.city, selected: false, faculties: [] }] });
            }
        });

        let faculties = [];
        if (editingAcademy?.faculties?.length > 0) {
            (editingAcademy.faculties ?? []).forEach(faculty => {
                let selectedFaculty = facultyWithId(faculty.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties);
                faculties.push({ faculty: selectedFaculty, cities: [] });
                (faculty.cities ?? []).forEach(id => {
                    let city = editingAcademy.cities.find((c) => { return c.id == id });
                    if (city) {
                        let countryIndex = academicCountries.findIndex((c) => { return c.country == city.country });
                        if (countryIndex >= 0) {
                            let cityIndex = academicCountries[countryIndex].cities.findIndex((c) => { return c.state == city.state && c.city == city.city });
                            if (cityIndex >= 0) {
                                academicCountries[countryIndex].cities[cityIndex].selected = true;
                                if (!academicCountries[countryIndex].cities[cityIndex].faculties.some((f) => { return f == faculty.facultyId })) {
                                    academicCountries[countryIndex].cities[cityIndex].faculties.push(faculty.facultyId);
                                }
                            }
                        }
                    }
                });
            });
        }

        this.setState({
            academyName: academyName,
            selectedLanguage: selectedLanguage,
            domains: domains,
            faculties: faculties,
            academicCountries: academicCountries
        });
    }

    filterCountry = () => {
        if (this.props.editingAcademy?.country) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    // value: getLocalizedString(element)
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let selectedCountry = countries?.find((country) => { return country.value == this.props.editingAcademy.country });
            this.setState({ selectedCountry: selectedCountry });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    checkExistAcademy = () => {
        if (this.props.editingAcademy?.name == this.state.academyName) {
            this.setState({ didCheckedAcademy: true, useSameName: true, addAcademyStep: ADD_ACADEMY_COUNTRIES });
        }
        else {
            this.props.checkExistAcademy({ 'name': this.state.academyName }, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },

                callbackOnSuccess: (response) => {
                    this.setState({ didCheckedAcademy: true }, () => {
                        this.setState({ addAcademyStep: response.existing == true ? ADD_ACADEMY_EXIST_ACADEMY : ADD_ACADEMY_COUNTRIES })
                    })
                },
            });
        }
    }

    checkExistAcademyDomains = () => {
        let needCheck = false;
        if (this.state.domains.length > 0) {
            let defaultDomains = this.props.editingAcademy?.domains ?? [];
            needCheck = this.state.domains.filter(x => !defaultDomains.includes(x)).length > 0;
        }

        if (needCheck) {
            this.props.checkExistAcademyDomains(this.state.domains, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },

                callbackOnSuccess: (response) => {
                    this.setState({ didCheckedDomains: true }, () => {
                        let notMine = response;
                        if (this.props.editingAcademy?.domains?.length > 0) {
                            notMine = response.filter(x => !this.props.editingAcademy?.domains.includes(x))
                        }

                        if (notMine.length > 0) {
                            this.setState({ existDomains: notMine, addAcademyStep: ADD_ACADEMY_EXIST_DOMAIN })
                        }
                        else {
                            this.checkExistAcademy();
                        }
                    })
                },
            })
        }
        else {
            this.setState({ didCheckedDomains: true }, () => {
                this.checkExistAcademy();
            })
        }
    }

    didSelectExistAcademic = (index, checked) => {
        this.setState({ selectedExistAcademicIndex: checked ? index : null });
    }

    addExistAcademicToNetwork = () => {
        let existAcademic = this.state.selectedExistAcademicIndex && this.state.existDomains[this.state.selectedExistAcademicIndex].data?.id;

        if (existAcademic || this.state.selectedInternalAcademyId) {
            let allNetworkAcademics = this.props.allNetworkAcademics ?? [];
            if (allNetworkAcademics.some((c) => { return c.id == existAcademic })) {
                this.setState({ addAcademyStep: ADD_ACADEMY_NETWORKED });
            }
            else {
                this.props.addExistAcademicToNetwork(existAcademic || this.state.selectedInternalAcademyId, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.getAllNetworkAcademics({});
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                });
            }
        }
    }

    onAcademyAction = () => {
        let cities = [];
        this.state.academicCountries.forEach(country => {
            country.cities.forEach(city => {
                city.country = country.country
                cities.push(city)
            });
        });
        // cities = cities.filter((c) => { return c.selected == true });

        let faculties = this.state.faculties.map((f) => { return { facultyId: f.faculty.id, cities: [] } });
        cities.forEach((city, index) => {
            city.faculties.forEach(faculty => {
                let idx = faculties.findIndex((f) => { return f.facultyId == faculty });
                if (idx >= 0) {
                    if (!faculties[idx].cities.some((c) => { return c == (index + 1) })) {
                        faculties[idx].cities.push(index + 1);
                    }
                }
            });
        });

        cities = cities.map((c, index) => { return { id: index + 1, country: c.country, state: c.state, city: c.city } });

        let params = {};
        params.name = this.state.academyName;
        params.useSameName = this.state.useSameName;
        params.language = this.state.selectedLanguage.code;
        params.cities = cities;
        params.faculties = faculties;
        if (this.state.domains.length > 0) {
            params.domains = this.state.domains;
        }

        if (this.props.editingAcademy) {
            if (this.props.editingAcademy?.id) {
                this.props.editNetworkAcademy(this.props.editingAcademy?.id, params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                })
            }
        }
        else {
            this.props.createNetworkAcademy(params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnSuccess: (response) => {
                    this.props.getAllNetworkAcademics({});
                    this.props.close(response);
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                }
            })
        }
    }

    addCountry = () => {
        let academicCountries = this.state.academicCountries;
        if (!academicCountries.some((country) => { return country.country == this.state.selectedCountry.value })) {
            academicCountries.push({ country: this.state.selectedCountry.value, cities: [] });
        }
        this.setState({ academicCountries: academicCountries, isAddingCountry: false, selectedCountry: null });
    }

    addCity = () => {
        let academicCountries = this.state.academicCountries;
        let index = academicCountries.findIndex((c) => { return c.country == this.state.selectedAcademyCountry.value });
        if (index >= 0) {
            if (!academicCountries[index].cities.some((city) => { return city.state == this.state.selectedState?.value && city.city == this.state.cityName })) {
                academicCountries[index].cities.push({ state: this.state.selectedState?.value, city: this.state.cityName, selected: false, faculties: [] });
            }
        }
        this.setState({ academicCountries: academicCountries, isAddingCity: false, selectedAcademyCountry: null, selectedState: null, cityName: '' });
    }

    removeCity = (city) => {
        let academicCountries = this.state.academicCountries;
        let index = academicCountries.findIndex((country) => { return country.country == city.country });
        if (index >= 0) {
            let cityIndex = academicCountries[index].cities.findIndex((c) => { return c.state == city.state && c.city == city.city });
            if (cityIndex >= 0) {
                academicCountries[index].cities.splice(cityIndex, 1);
            }
        }
        this.setState({ academicCountries: academicCountries });
    }

    selectCity = (city) => {
        let academicCountries = this.state.academicCountries;
        let index = academicCountries.findIndex((country) => { return country.country == city.country });
        if (index >= 0) {
            let cityIndex = academicCountries[index].cities.findIndex((c) => { return c.state == city.state && c.city == city.city });
            if (cityIndex >= 0) {
                academicCountries[index].cities[cityIndex].selected = !academicCountries[index].cities[cityIndex].selected;
                academicCountries[index].cities[cityIndex].faculties = [];
            }
        }
        this.setState({ academicCountries: academicCountries, isAddingCity: false, selectedAcademyCountry: null, selectedState: null, selectedCity: null });
    }

    selectFaculty = (city, faculty) => {
        let academicCountries = this.state.academicCountries;
        let index = academicCountries.findIndex((country) => { return country.country == city.country });
        if (index >= 0) {
            let cityIndex = academicCountries[index].cities.findIndex((c) => { return c.state == city.state && c.city == city.city });
            if (cityIndex >= 0) {
                let facultyIndex = academicCountries[index].cities[cityIndex].faculties.findIndex((f) => { return f == faculty.id });
                if (facultyIndex >= 0) {
                    academicCountries[index].cities[cityIndex].faculties.splice(facultyIndex, 1);
                }
                else {
                    academicCountries[index].cities[cityIndex].faculties.push(faculty.id);
                }
            }
        }
        this.setState({ academicCountries: academicCountries, isAddingFaculty: false, selectedFaculty: null, selectingFacultyIndex: null });
    }

    checkDomain = () => {
        if (this.state.domain == null || this.state.domain?.length == 0) {
            return;
        }
        let domains = this.state.domains;
        if (!domains.some((element) => { return element == this.state.domain })) {
            let isDomain = isValidDomain(this.state.domain);
            if (isDomain) {
                this.props.checkExistNetworkDomain(this.state.domain, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        if (response?.prefix == 'Node::Academy::' && this.props.editingAcademy != null) {
                            this.setState({ domainOwner: response });
                        }
                        else if (response?.prefix == 'Node::Company::') {
                            this.setState({ domainOwner: response });
                        }
                        else {
                            this.addDomain();
                        }
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                });
            }
            else {
                this.setState({ isDomain: false });
            }
        }
    }

    domainOwnerToString = () => {
        let response = this.state.domainOwner;
        if (response?.prefix == 'Node::Academy::') {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let domainOwner = response.name ?? '';
            if (response.cities?.length > 0) {
                let location = locationString(response.cities[0].country, response.cities[0].state, response.cities[0].city, countries);
                if (location?.length > 0) {
                    domainOwner += (domainOwner.length > 0 ? ', ' : '') + location;
                }
            }
            return domainOwner;
        }
        else if (response?.prefix == 'Node::Company::') {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let domainOwner = response.name ?? '';
            let location = locationString(response.countryHQ, response.stateHQ, response.cityHQ, countries);
            if (location?.length > 0) {
                domainOwner += (domainOwner.length > 0 ? ', ' : '') + location;
            }

            return domainOwner;
        }

        return '';
    }

    addDomain = () => {
        let domains = this.state.domains;
        if (!domains.some((element) => { return element == this.state.domain })) {
            domains.push(this.state.domain);
        }
        this.setState({ domains: domains, isAddingDomain: false, domain: '', didCheckedDomains: false, existDomains: [], domainOwner: null })
    }

    addFaculty = () => {
        let faculties = this.state.faculties;
        if (!faculties.some((element) => { return element.faculty.id == this.state.selectedFaculty.id })) {
            faculties.push({ faculty: this.state.selectedFaculty, graduateDegrees: [], specialties: [] });
        }
        this.setState({ faculties: faculties, selectedFaculty: null, isAddingFaculty: false })
    }

    onBack = () => {
        let didCheckedDomains = this.state.didCheckedDomains;
        if (this.state.displayingFaculty == 0) {
            didCheckedDomains = false;
        }

        let previousStep = ADD_ACADEMY_IDENTIFICATION;
        switch (this.state.addAcademyStep) {
            case ADD_ACADEMY_NEW_DOMAIN:
                previousStep = ADD_ACADEMY_FACULTY;
                break;
            case ADD_ACADEMY_NETWORKED:
                previousStep = ADD_ACADEMY_EXIST_DOMAIN;
                break;
            default:
                break;
        }

        this.setState({
            addAcademyStep: previousStep,
            displayingFaculty: this.state.displayingFaculty > 0 ? (this.state.displayingFaculty - 1) : 0,
            didCheckedDomains: didCheckedDomains,
            isAddingCity: false,
            selectedAcademyCountry: null,
            selectedState: null,
            selectedCity: null,
            isAddingFaculty: false,
            selectedFaculty: null,
            selectingFacultyIndex: null,
            didCheckedDomains: false,
            selectedExistAcademicIndex: null
        });
    }

    checkNextHidden = () => {
        return this.state.addAcademyStep == ADD_ACADEMY_EXIST_DOMAIN || this.state.addAcademyStep == ADD_ACADEMY_EXIST_ACADEMY || this.state.addAcademyStep == ADD_ACADEMY_FACULTY || this.state.addAcademyStep == ADD_ACADEMY_NETWORKED;
    }

    checkNextable = () => {
        let nextable = false;
        if (this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION) {
            nextable = this.state.academyName.length > 0 && this.state.selectedLanguage != null;
        }
        else if (this.state.addAcademyStep == ADD_ACADEMY_COUNTRIES) {
            let validCity = this.state.academicCountries.some((country) => { return country.cities.length > 0 });
            nextable = this.state.academyName.length > 0 && this.state.selectedLanguage != null && this.state.faculties.length > 0 && validCity;
            let states = this.state.selectedCountry?.states ?? [];
            if (nextable == true && states.length > 0) {
                nextable = this.state.selectedState != null
            }
        }
        else {
            let isEmpty = false;
            for (let idx = 0; idx < this.state.academicCountries.length; idx++) {
                let selectedCities = this.state.academicCountries[idx].cities.filter((city) => { return city.selected == true });
                if (selectedCities.length == 0) {
                    isEmpty = true;
                }
                else {
                    isEmpty = selectedCities.some((city) => { return city.faculties.length == 0 });
                }
                if (isEmpty) {
                    break;
                }
            }
            nextable = !isEmpty;
        }

        return nextable;
    }

    checkSaveHidden = () => {
        console.log(this.state.selectedInternalAcademyId);
        if(this.state.selectedInternalAcademyId)
            return false;

        return this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION || this.state.addAcademyStep == ADD_ACADEMY_NETWORKED;
    }

    checkSaveable = () => {
        if(this.state.selectedInternalAcademyId)
            return true;
        // let saveEnable = true;
        // if (this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION) {
        //     saveEnable = this.state.academyName.length > 0 && this.state.selectedLanguage != null && this.state.faculties.length > 0 && this.state.selectedCountry != null && this.state.cityName.length > 0;
        //     let states = this.state.selectedCountry?.states ?? [];
        //     if (saveEnable == true && states.length > 0) {
        //         saveEnable = this.state.selectedState != null
        //     }
        // }
        // else if (this.state.addAcademyStep == ADD_ACADEMY_FACULTY) {
        //     let currentFaculty = this.state.faculties[this.state.displayingFaculty];
        //     saveEnable = currentFaculty.specialties.length > 0 && currentFaculty.graduateDegrees.length > 0;
        // }

        let saveable = this.state.academyName.length > 0 && this.state.selectedLanguage != null && !this.state.isAddingCountry && !this.state.isAddingCity && !this.state.isAddingDomain && !this.state.isAddingGraduateDegree && !this.state.isAddingSpecialties && !this.state.isAddingFaculty;
        if (saveable && this.state.addAcademyStep == ADD_ACADEMY_EXIST_DOMAIN) {
            saveable = this.state.selectedExistAcademicIndex != null;
        }

        return saveable;
    }

    onNext = () => {
        if(this.state.selectedInternalAcademyId) {
            this.addExistAcademicToNetwork();
        }
        if (this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION) {
            this.checkExistAcademyDomains();
        }
        else if (this.state.addAcademyStep == ADD_ACADEMY_COUNTRIES) {
            this.setState({ addAcademyStep: ADD_ACADEMY_FACULTY });
        }
    }

    onValidate = () => {
        if ((this.state.selectedExistAcademicIndex != null) || (this.state.selectedInternalAcademyId != null)) {
            this.addExistAcademicToNetwork();
        }
        else if (this.state.addAcademyStep == ADD_ACADEMY_EXIST_ACADEMY) {
            this.setState({ useSameName: true }, () => {
                this.setState({ addAcademyStep: ADD_ACADEMY_COUNTRIES });
            })
        }
        // else if (this.state.addAcademyStep == ADD_ACADEMY_COUNTRIES) {
        //     this.setState({ addAcademyStep: ADD_ACADEMY_FACULTY });
        // }
        else {
            this.onAcademyAction();
        }
    }

    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log('search', string);
    }
    
    handleOnHover = (result) => {
        // the item hovered
      }
    
    handleOnSelect = (item) => {
        console.log('select', item);

        this.populateAcademy(item);

        this.setState({
           selectedInternalAcademyId: item.id
        });
    }
    
    handleOnClear = () => {
        this.populateAcademy(null);

        this.setState({
            selectedInternalAcademyId: null
         });
    }

    handleOnFocus = () => {
    }
    
    formatResult = (item) => {
        const name = item.name;
        const city = item.cities?.[0]?.city;
        const countryCode = item.cities?.[0]?.country;
        const domain = item.domains?.[0];
        const country = countryCodeToString(countryCode, this.state.allCountries);

        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>{name}</span>
            <span style={{ display: 'block', textAlign: 'left', fontSize: '0.875rem'}}>{city} {country} {domain}</span>
          </>
        )
      }


    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        let languages = allLanguages();
        let facultyDoc = this.props.academicDoc?.facultyDoc?.academyFaculties?.map((f) => { return { id: f.order, label: f.value ? getLocalizedString(f.value) : '', entity: f } });
        const graduateDegreeDoc = this.props.academicDoc?.graduateDegreesDoc?.academyGraduateDegrees?.map((gd) => { return { id: gd.order, label: gd.value ? getLocalizedString(gd.value) : '', entity: gd } });

        const countries = this.state.allCountries;
        let availableCountries = countries?.filter((country) => { return !this.state.academicCountries.some((c) => { return c.country == country.value }) })
        let academicCountries = this.state.academicCountries.map((country) => { return countries.find((c) => { return c.value == country.country }) });
        let states = this.state.selectedAcademyCountry?.states ?? [];
        states = states.map((element) => { return { value: element.id, label: getLocalizedString(element.name) } });
        let cities = [];
        this.state.academicCountries.forEach(country => {
            country.cities.forEach(city => {
                city.country = country.country;
                city.label = city.city;
                cities.push(city)
            });
        });

        let selectedCities = cities.filter((city) => { return city.selected == true });
        let remainCities = cities.filter((city) => { return city.selected == false });

        let availableFaculties = facultyDoc ? facultyDoc.filter((faculty) => { return faculty.id != 1000 && !this.state.faculties.some((e) => { return e.faculty?.id == faculty.id }) }) : null;

        const nextHidden = this.checkNextHidden();
        const nextable = this.checkNextable();
        const saveHidden = this.checkSaveHidden();
        const saveEnable = this.checkSaveable();

        let headerTitle = (this.props.editingAcademy ? t('network.modifyAcademy') : t('network.addAcademy')) + ' - ';
        headerTitle += this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION ? t('network.identification') : this.state.academyName;

        let validateButton = t(this.state.addAcademyStep == ADD_ACADEMY_FACULTY ? 'updateCV.validate' : 'personalInfo.save');
        if (this.state.addAcademyStep == ADD_ACADEMY_EXIST_ACADEMY) {
            validateButton = t('subscriptions.YES');
        }
        else if (this.state.addAcademyStep == ADD_ACADEMY_EXIST_DOMAIN) {
            validateButton = t('general.Add');
        }

        let domainOwnerName = this.domainOwnerToString();

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}
                                            {this.state.addAcademyStep == ADD_ACADEMY_IDENTIFICATION &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-3'>
                                                            <Col md={12} style={{ zIndex: 11}}>
                                                                <div style={{ width: '100%' }} className='mb-3 autocomplete'>
                                                                    <ReactSearchAutocomplete
                                                                        items={this.state.allAcademics}
                                                                        fuseOptions={{ 
                                                                            keys: ["name"],
                                                                            minMatchCharLength: 3,
                                                                            threshold: 0.6
                                                                        }}
                                                                        resultStringKeyName="name"
                                                                        onSearch={this.handleOnSearch}
                                                                        onHover={this.handleOnHover}
                                                                        onSelect={this.handleOnSelect}
                                                                        onFocus={this.handleOnFocus}
                                                                        onClear={this.handleOnClear}
                                                                        autoFocus
                                                                        placeholder='Search existing'
                                                                        formatResult={this.formatResult}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='country'>{t("experience.Language")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="country"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={languages}
                                                                    value={this.state.selectedLanguage ? languages?.filter((c) => { return c.value == this.state.selectedLanguage.value }) : null}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedLanguage: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-2'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("Academy")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <InputGroup className='input-container'>
                                                                    <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("Academy")}
                                                                        autoComplete="off"
                                                                        value={this.state.academyName ?? ""}
                                                                        onChange={(value, data, event, formattedValue) => {
                                                                            this.setState({ academyName: data, didCheckedAcademy: false, useSameName: false })
                                                                        }} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <div className='width-percent-100 mt-3'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left'>
                                                                    <Label className='link-selected font-size-15'>{t('company.domains')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="add-domain"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingDomain: !this.state.isAddingDomain });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="add-domain">
                                                                        {t('network.addDomain')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                                {/* <Col>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingDomain || this.state.domains.length > 0) &&
                                                            <div className='border-1'>
                                                                <Col xl={12}>
                                                                    {this.state.domains.length > 0 && this.state.domains.map((domain, mailIndex) => {
                                                                        return (
                                                                            <Row key={mailIndex}>
                                                                                <Col className='align-horizontal'>
                                                                                    <Label className='mt-2'>{domain}</Label>
                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-domain' + mailIndex}
                                                                                            onClick={() => {
                                                                                                let domains = this.state.domains;
                                                                                                domains.splice(mailIndex, 1);
                                                                                                this.setState({ domains: domains })
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target={'remove-domain' + mailIndex}>
                                                                                            {t("techGroup.hover.removeDomain")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })
                                                                    }

                                                                    {this.state.isAddingDomain &&
                                                                        <div>
                                                                            {this.state.domains.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <AvGroup>
                                                                                <Row className='mt-2 mb-1'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label>{t('company.domain')} :</Label>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <InputGroup className='input-container'>
                                                                                            <AvInput className="react-select" type="domain" name="domain" id="domain" placeholder={null}
                                                                                                autoComplete="off"
                                                                                                value={this.state.domain}
                                                                                                onChange={(e) => { this.setState({ domain: e.target.value }) }}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </AvGroup>
                                                                            {!this.state.isDomain &&
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col className='path-component font-size-15 text-left'>
                                                                                        <Label className='font-size-15 text-color-alert'>{t('network.invalidDomain')}</Label>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            {this.state.domainOwner != null &&
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col className='path-component font-size-15 text-left'>
                                                                                        <Label className='font-size-15 text-color-alert'>{format(t(this.state.domainOwner?.prefix == 'Node::Academy::' ? 'network.domainBelongToAcademics' : 'network.domainBelongToCompany'), domainOwnerName)}</Label>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => {
                                                                                                let state = { domain: '', isDomain: true, domainOwner: null };
                                                                                                if (this.state.domainOwner == null && this.state.isDomain) {
                                                                                                    state.isAddingDomain = false;
                                                                                                }
                                                                                                this.setState(state);
                                                                                            }}>
                                                                                            <i className='uil'></i>{t((this.state.domainOwner == null && this.state.isDomain) ? "modal.close" : 'academic.erase')}
                                                                                        </Button>
                                                                                        {this.state.domainOwner == null && this.state.isDomain &&
                                                                                            <Button color="primary"
                                                                                                disabled={this.state.domain.length == 0}
                                                                                                onClick={() => { this.checkDomain() }}>
                                                                                                <i className='uil'></i>{t("general.Add")}
                                                                                            </Button>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>

                                                </Row>
                                            }

                                            {this.state.addAcademyStep == ADD_ACADEMY_COUNTRIES &&
                                                <Row>
                                                    <div className='width-percent-100'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{t('general.Countries')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="add-country"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingCountry: !this.state.isAddingCountry });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="add-country">
                                                                        {t('network.addCountry')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                                {/* <Col>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingCountry || this.state.academicCountries.length > 0) &&
                                                            <div className='border-1'>
                                                                <Col xl={12}>
                                                                    {this.state.academicCountries.map((country, countryIndex) => {
                                                                        let countryName = countryCodeToString(country.country, countries);
                                                                        return (
                                                                            <Row key={countryIndex}>
                                                                                <Col className='align-horizontal'>
                                                                                    <Label className='mt-2'>{countryName ?? ''}</Label>
                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-country' + countryIndex}
                                                                                            onClick={() => {
                                                                                                let academicCountries = this.state.academicCountries;
                                                                                                academicCountries.splice(countryIndex, 1);
                                                                                                this.setState({ academicCountries: academicCountries })
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target={'remove-country' + countryIndex}>
                                                                                            {t("hover.removeCountry")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }, this)}

                                                                    {this.state.isAddingCountry &&
                                                                        <div>
                                                                            {this.state.academicCountries.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            <Row className='mt-2 mb-1'>
                                                                                <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                    <Label>{t('personalInfo.country')} :</Label>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Select className="react-select"
                                                                                        classNamePrefix="react-select"
                                                                                        name="country" id="country"
                                                                                        placeholder={t('placeholder.Select')}
                                                                                        options={availableCountries}
                                                                                        value={this.state.selectedCountry ? availableCountries?.filter((c) => { return c.value == this.state.selectedCountry.value }) : null}
                                                                                        onChange={(value) => {
                                                                                            this.setState({ selectedCountry: value });
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => { this.setState({ isAddingCountry: false, selectedCountry: null }) }}>
                                                                                            <i className='uil'></i>{t("modal.close")}
                                                                                        </Button>
                                                                                        <Button color="primary"
                                                                                            disabled={this.state.selectedCountry == null}
                                                                                            onClick={() => { this.addCountry() }}>
                                                                                            <i className='uil'></i>{t("general.Add")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>

                                                    {this.state.academicCountries.length > 0 &&
                                                        <div className='width-percent-100 mt-3'>
                                                            <Col xl={12}>
                                                                <Row>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('general.Cities')}</Label>
                                                                        <PlusSquare
                                                                            className="icon-dual icon-size-20 ml-3"
                                                                            id="add-city"
                                                                            onClick={(e) => {
                                                                                e.currentTarget.blur();
                                                                                this.setState({ isAddingCity: !this.state.isAddingCity });
                                                                            }}
                                                                        />
                                                                        <UncontrolledTooltip placement="bottom" target="add-city">
                                                                            {t('network.addCity')}
                                                                        </UncontrolledTooltip>
                                                                    </Col>
                                                                    {/* <Col>
                                                                    </Col> */}
                                                                </Row>
                                                            </Col>
                                                            {(this.state.isAddingCity || cities.length > 0) &&
                                                                <div className='border-1'>
                                                                    <Col xl={12}>
                                                                        {cities.map((city, cityIndex) => {
                                                                            let location = '';
                                                                            let states = countries?.find((e) => e.value === city.country)?.states;
                                                                            if (states, city.state) {
                                                                                let state = states.find((s) => { return s.id == city.state });
                                                                                if (state?.name && getLocalizedString(state.name)) {
                                                                                    location += getLocalizedString(state.name);
                                                                                }
                                                                            }
                                                                            if (city.city?.length > 0) {
                                                                                location += (location.length > 0 ? ', ' : '') + city.city;
                                                                            }

                                                                            return (
                                                                                <Row key={cityIndex}>
                                                                                    <Col className='align-horizontal'>
                                                                                        <Label className='mt-2'>{location ?? ''}</Label>
                                                                                        <div className='float-right width-px-40 box-center'>
                                                                                            <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-city' + cityIndex}
                                                                                                onClick={() => {
                                                                                                    this.removeCity(city);
                                                                                                }} />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target={'remove-city' + cityIndex}>
                                                                                                {t("hover.removeCity")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        }, this)}

                                                                        {this.state.isAddingCity &&
                                                                            <div>
                                                                                {cities.length > 0 &&
                                                                                    <Row className='mt-1'>
                                                                                        <Col>
                                                                                            <div className='footer-box-text-grey'></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                                <Row className='mt-2 mb-1'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label for='country'>{t("personalInfo.country")} :</Label>
                                                                                    </Col>
                                                                                    <Col md={8} xl={8}>
                                                                                        <Select className="react-select"
                                                                                            classNamePrefix="react-select"
                                                                                            name="country" id="country"
                                                                                            placeholder={t('placeholder.Select')}
                                                                                            options={academicCountries}
                                                                                            value={this.state.selectedAcademyCountry ? academicCountries?.filter((c) => { return c.value == this.state.selectedAcademyCountry.value }) : null}
                                                                                            onChange={(value) => {
                                                                                                this.setState({ selectedAcademyCountry: value });
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                {states.length > 0 &&
                                                                                    <Row className='mt-2 mb-1'>
                                                                                        <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                            <Label for='country'>{t("personalInfo.state")} :</Label>
                                                                                        </Col>
                                                                                        <Col md={8} xl={8}>
                                                                                            <Select className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                name="states" id="states"
                                                                                                placeholder={t('placeholder.Select')}
                                                                                                options={states}
                                                                                                value={this.state.selectedState ? states?.filter((c) => { return c.value == this.state.selectedState.value }) : null}
                                                                                                onChange={(value) => {
                                                                                                    this.setState({ selectedState: value });
                                                                                                }}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                                <Row className='mt-2 mb-1'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2' >
                                                                                        <Label for="firstName">{t("personalInfo.city")} :</Label>
                                                                                    </Col>
                                                                                    <Col md={4} xl={8}>
                                                                                        <InputGroup className='input-container'>
                                                                                            <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("personalInfo.enterYourCity")}
                                                                                                autoComplete="off"
                                                                                                value={this.state.cityName ?? ""}
                                                                                                onChange={(value, data, event, formattedValue) => {
                                                                                                    this.setState({ cityName: data })
                                                                                                }} />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={12} xl={12} className='box-side'>
                                                                                        <div></div>
                                                                                        <div className='align-horizontal'>
                                                                                            <Button color="white mr-2"
                                                                                                onClick={() => { this.setState({ isAddingCity: false, selectedAcademyCountry: null, selectedState: null, cityName: '' }) }}>
                                                                                                <i className='uil'></i>{t("modal.close")}
                                                                                            </Button>
                                                                                            <Button color="primary"
                                                                                                disabled={this.state.selectedAcademyCountry == null || this.state.cityName.length == 0 || (states.length > 0 ? this.state.selectedState == null : false)}
                                                                                                onClick={() => { this.addCity() }}>
                                                                                                <i className='uil'></i>{t("general.Add")}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    <div className='width-percent-100 mt-3'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{t('academic.schools')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="add-school"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingFaculty: !this.state.isAddingFaculty });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="add-school">
                                                                        {t('network.addSchool')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                                {/* <Col>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingFaculty || this.state.faculties.length > 0) &&
                                                            <div className='border-1'>
                                                                <Col xl={12}>
                                                                    {this.state.faculties.length > 0 && this.state.faculties.map((faculty, facultyIndex) => {
                                                                        return (
                                                                            <Row key={facultyIndex}>
                                                                                <Col className='align-horizontal'>
                                                                                    <Label className='mt-2'>{faculty.faculty?.label ?? ''}</Label>
                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-faculty' + facultyIndex}
                                                                                            onClick={() => {
                                                                                                let faculties = this.state.faculties;
                                                                                                faculties.splice(facultyIndex, 1);
                                                                                                this.setState({ faculties: faculties })
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target={'remove-faculty' + facultyIndex}>
                                                                                            {t("hover.removeFaculty")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }, this)}

                                                                    {this.state.isAddingFaculty &&
                                                                        <div>
                                                                            {this.state.faculties.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            <Row className='mt-2 mb-1'>
                                                                                <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                    <Label>{t('academic.school')} :</Label>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Select className="react-select"
                                                                                        classNamePrefix="react-select"
                                                                                        name="states" id="states"
                                                                                        placeholder={t('placeholder.Select')}
                                                                                        options={availableFaculties}
                                                                                        value={this.state.selectedFaculty ? availableFaculties.find((f) => { return f.id == this.state.selectedFaculty.id }) : null}
                                                                                        onChange={(value) => {
                                                                                            this.setState({ selectedFaculty: value });
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => { this.setState({ isAddingFaculty: false, selectedFaculty: null }) }}>
                                                                                            <i className='uil'></i>{t("modal.close")}
                                                                                        </Button>
                                                                                        <Button color="primary"
                                                                                            disabled={this.state.selectedFaculty == null}
                                                                                            onClick={() => { this.addFaculty() }}>
                                                                                            <i className='uil'></i>{t("general.Add")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>
                                                </Row>
                                            }

                                            {this.state.addAcademyStep == ADD_ACADEMY_FACULTY &&
                                                <Row>
                                                    <div className='width-percent-100 mt-3'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left'>
                                                                    <Label className='link-selected font-size-15'>{t('general.Cities')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="select-city"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingCity: !this.state.isAddingCity });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="select-city">
                                                                        {t('network.selectCity')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                                {/* <Col>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingCity || selectedCities.length > 0) &&
                                                            <div>
                                                                <Col xl={12}>
                                                                    {selectedCities.map((city, selectedCityIndex) => {
                                                                        let cityFaculties = this.state.faculties.map((f) => { return f.faculty });
                                                                        let selectedFaculties = cityFaculties.filter((faculty) => { return city.faculties.some((f) => { return f == faculty.id }) });
                                                                        let remainFaculties = cityFaculties.filter((faculty) => { return !city.faculties.some((f) => { return f == faculty.id }) });

                                                                        return (
                                                                            <Row className='border-1 mb-2' key={selectedCityIndex}>
                                                                                <Col className='align-vertical'>
                                                                                    <div className='align-horizontal'>
                                                                                        <Label className='mt-2'>{city?.label ?? ''}</Label>
                                                                                        <div className='float-right width-px-40 box-center'>
                                                                                            <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-selected-city' + selectedCityIndex}
                                                                                                onClick={() => {
                                                                                                    this.selectCity(city);
                                                                                                }} />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target={'remove-selected-city' + selectedCityIndex}>
                                                                                                {t("hover.removeFaculty")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Row className=''>
                                                                                        <Col>
                                                                                            <div className='footer-box-text-grey'></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className='width-percent-100'>
                                                                                        <Col xl={12}>
                                                                                            <Row>
                                                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                    <Label className='link-selected font-size-15'>{t('academic.schools')}</Label>
                                                                                                    <PlusSquare
                                                                                                        className="icon-dual icon-size-20 ml-3"
                                                                                                        id="select-school"
                                                                                                        onClick={(e) => {
                                                                                                            e.currentTarget.blur();
                                                                                                            this.setState({ isAddingFaculty: !this.state.isAddingFaculty, selectingFacultyIndex: selectedCityIndex });
                                                                                                        }}
                                                                                                    />
                                                                                                    <UncontrolledTooltip placement="bottom" target="select-school">
                                                                                                        {t('network.selectSchool')}
                                                                                                    </UncontrolledTooltip>
                                                                                                </Col>
                                                                                                {/* <Col>
                                                                                                </Col> */}
                                                                                            </Row>
                                                                                        </Col>
                                                                                        {((this.state.isAddingFaculty && this.state.selectingFacultyIndex == selectedCityIndex) || selectedFaculties.length > 0) &&
                                                                                            <div className='border-1 mb-2'>
                                                                                                <Col xl={12}>
                                                                                                    {selectedFaculties.map((faculty, selectedFacultyIndex) => {
                                                                                                        return (
                                                                                                            <Row>
                                                                                                                <Col className='align-horizontal'>
                                                                                                                    <Label className='mt-2'>{faculty?.label ?? ''}</Label>
                                                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-selected-faculty' + selectedFacultyIndex}
                                                                                                                            onClick={() => {
                                                                                                                                this.selectFaculty(city, faculty);
                                                                                                                            }} />
                                                                                                                        <UncontrolledTooltip
                                                                                                                            placement="top"
                                                                                                                            id='tooltip-1'
                                                                                                                            target={'remove-selected-faculty' + selectedFacultyIndex}>
                                                                                                                            {t("hover.removeFaculty")}
                                                                                                                        </UncontrolledTooltip>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        )
                                                                                                    }, this)}

                                                                                                    {this.state.isAddingFaculty && this.state.selectingFacultyIndex == selectedCityIndex &&
                                                                                                        <div>
                                                                                                            {selectedFaculties.length > 0 &&
                                                                                                                <Row className='mt-1'>
                                                                                                                    <Col>
                                                                                                                        <div className='footer-box-text-grey'></div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            }

                                                                                                            <Row className='mt-2 mb-1'>
                                                                                                                <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                                                    <Label>{t('academic.school')} :</Label>
                                                                                                                </Col>
                                                                                                                <Col>
                                                                                                                    <Select className="react-select"
                                                                                                                        classNamePrefix="react-select"
                                                                                                                        name="states" id="states"
                                                                                                                        placeholder={t('placeholder.Select')}
                                                                                                                        options={remainFaculties}
                                                                                                                        value={this.state.selectedFaculty ? remainFaculties.find((f) => { return f.value == this.state.selectedFaculty.id }) : null}
                                                                                                                        onChange={(value) => {
                                                                                                                            this.setState({ selectedFaculty: value });
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <Row className='mt-2 mb-2'>
                                                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                                                    <div></div>
                                                                                                                    <div className='align-horizontal'>
                                                                                                                        <Button color="white mr-2"
                                                                                                                            onClick={() => { this.setState({ isAddingFaculty: false, selectedFaculty: null, selectingFacultyIndex: null }) }}>
                                                                                                                            <i className='uil'></i>{t("modal.close")}
                                                                                                                        </Button>
                                                                                                                        <Button color="primary"
                                                                                                                            disabled={this.state.selectedFaculty == null}
                                                                                                                            onClick={() => { this.selectFaculty(city, this.state.selectedFaculty) }}>
                                                                                                                            <i className='uil'></i>{t("general.Add")}
                                                                                                                        </Button>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Col>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }, this)}

                                                                    {this.state.isAddingCity &&
                                                                        <div className='borer-1'>
                                                                            {/* {selectedCities.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            } */}

                                                                            <Row className='mt-2 mb-1'>
                                                                                <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                    <Label>{t('general.Cities')} :</Label>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Select className="react-select"
                                                                                        classNamePrefix="react-select"
                                                                                        name="states" id="states"
                                                                                        placeholder={t('placeholder.Select')}
                                                                                        options={remainCities}
                                                                                        value={this.state.selectedCity ? remainCities.find((f) => { return f.value == this.state.selectedCity.value }) : null}
                                                                                        onChange={(value) => {
                                                                                            this.setState({ selectedCity: value });
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => { this.setState({ isAddingCity: false, selectedCity: null }) }}>
                                                                                            <i className='uil'></i>{t("modal.close")}
                                                                                        </Button>
                                                                                        <Button color="primary"
                                                                                            disabled={this.state.selectedCity == null}
                                                                                            onClick={() => { this.selectCity(this.state.selectedCity) }}>
                                                                                            <i className='uil'></i>{t("general.Add")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>
                                                </Row>
                                            }

                                            {this.state.addAcademyStep == ADD_ACADEMY_EXIST_ACADEMY &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3'>
                                                            <Col className='font-size-15 text-left mt-2' >
                                                                <Label className=''>{t('addAcademy.academyExistMessage1')}</Label>
                                                                <Label className=''>{t(this.props.editingAcademy ? 'addAcademy.academyExistMessage3' : 'addAcademy.academyExistMessage2')}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addAcademyStep == ADD_ACADEMY_EXIST_DOMAIN &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3 box-side'>
                                                            <div>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label>{format(t('network.domainsExist'), t('education.academics').toLowerCase())}</Label>
                                                                    {/* <img src={information} id="intro-help" className="icon-size-20 ml-3 mr-2" onClick={() => {
                                                                        this.setState({ showExistDomains: !this.state.showExistDomains });
                                                                    }} />
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        id='tooltip-1'
                                                                        target='intro-help'>
                                                                        {t("hover.existContactDetails")}
                                                                    </UncontrolledTooltip> */}
                                                                </Col>
                                                            </div>
                                                        </Row>

                                                        {this.state.existDomains.length > 0 && this.state.existDomains.map((element, index) => {
                                                            let domain = element.domain ?? '';
                                                            let owner = element.data?.name ?? '';

                                                            if (element.data?.cities?.length > 0) {
                                                                let location = locationString(element.data?.cities[0].country, element.data?.cities[0].state, element.data?.cities[0].city, countries);
                                                                if (location?.length > 0) {
                                                                    owner += (owner.length > 0 ? ', ' : '') + location;
                                                                }
                                                            }

                                                            if (domain.length > 0) {
                                                                owner += (owner.length > 0 ? ' - ' : '') + domain;
                                                            }

                                                            const checked = this.state.selectedExistAcademicIndex != null ? (this.state.selectedExistAcademicIndex == index) : false;
                                                            return (
                                                                <Row className='mb-3' key={index}>
                                                                    <Col className='font-size-15 text-left mt-2 align-horizontal'>
                                                                        <div className='mr-2 align-self-flex-start'>
                                                                            <SimpleCheckbox checked={checked} didChange={(checked) => { this.didSelectExistAcademic(index, checked) }} />
                                                                        </div>
                                                                        <Label className='mt-0 mb-0'>{owner}</Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                        }
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addAcademyStep == ADD_ACADEMY_NETWORKED &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3 box-side'>
                                                            <Col className='font-size-15 text-left mt-2' >
                                                                <Label className='text-color-alert'>{format(t('network.existObject'), t('education.academics').toLowerCase())}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            <br />

                                            <Row>
                                                <Col md={1} xl={1}></Col>
                                                <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                    <div>
                                                        <Button color="white" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.props.close(null);
                                                        }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                        {this.state.addAcademyStep != ADD_ACADEMY_IDENTIFICATION &&
                                                            <Button className='ml-2' color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onBack()
                                                            }}><i className='uil'></i>{t(this.state.addAcademyStep == ADD_ACADEMY_FACULTY ? 'codeInput.previous' : 'terms.back')}</Button>
                                                        }
                                                        {!nextHidden &&
                                                            <Button className='ml-2' color="primary" disabled={!nextable} onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onNext();
                                                            }}><i className='uil'></i>{t('sub.Next')}</Button>
                                                        }
                                                    </div>
                                                    <div>
                                                        {!saveHidden &&
                                                            <Button className='ml-2' disabled={!saveEnable} color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onValidate();
                                                            }}><i className='uil'></i>{validateButton}</Button>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { menuHierarchy } = state.Profile;
    const { allNetworkAcademics, network, existUsers } = state.Network;
    return { country, countries, menuHierarchy, allNetworkAcademics, network, existUsers, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    checkExistAcademy,
    checkExistNetworkDomain,
    checkExistAcademyDomains,
    addExistAcademicToNetwork,
    createNetworkAcademy,
    getAllAcademics,
    editNetworkAcademy,
    getAllNetworkAcademics
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddAcademyModal));