import classNames from 'classnames';
import MetisMenu from 'metismenujs/dist/metismenujs';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { changeActiveMenuFromLocation, initMenu } from '../redux/actions';


const MenuItemWithChildren = ({ item, linkClassNames, subMenuClassNames, activatedMenuItemIds, didSelectMenuItem }) => {
    const Icon = item.icon || null;
    return (
        <li className={classNames('side-nav-item', { 'mm-active': activatedMenuItemIds.indexOf(item.id) >= 0 })}>
            <Link
                to="/"
                className={classNames('side-sub-nav-link', linkClassNames)}
                aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}
                onClick={() => {
                    if (item.onClick) { item.onClick(item.id) }
                    if (didSelectMenuItem) { didSelectMenuItem(item.id, true) }
                }}>
                {item.icon && <Icon />}
                {item.badge && (
                    <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>
                )}
                <span> {item.name} </span>
                <span className="menu-arrow"></span>
            </Link>

            <ul
                className={classNames(subMenuClassNames, 'mm-collapse', {
                    'mm-collapsed mm-show': activatedMenuItemIds.indexOf(item.id) >= 0,
                })}
                aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}>
                {item.children.map((child, i) => {
                    return (
                        <React.Fragment key={i}>
                            {child.children ? (
                                <MenuItemWithChildren
                                    item={child}
                                    linkClassNames=""
                                    activatedMenuItemIds={activatedMenuItemIds}
                                    subMenuClassNames="side-nav-third-level"
                                    didSelectMenuItem={didSelectMenuItem}
                                />
                            ) : (
                                <MenuItem
                                    item={child}
                                    className={classNames({ active: activatedMenuItemIds.indexOf(child.id) >= 0 })}
                                    linkClassName=""
                                    didSelectMenuItem={didSelectMenuItem}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </li>
    );
};

const MenuItem = ({ item, className, linkClassName, didSelectMenuItem }) => {
    return (
        <li className={classNames('side-nav-item', className)}>
            <MenuItemLink item={item} className={linkClassName} didSelectMenuItem={didSelectMenuItem} />
        </li>
    );
};

const MenuItemLink = ({ item, className, didSelectMenuItem }) => {
    const Icon = item.icon || null;
    return (
        <Link to={item.path} className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
            onClick={() => {
                if (item.onClick) { item.onClick(item.id) }
                if (didSelectMenuItem) { didSelectMenuItem(item.id, false) }
            }}>
            {item.icon && <Icon />}
            {item.badge && <span className={`font-size-12 badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
            <span> {item.name} </span>
        </Link>
    );
};

/**
 * Renders the application menu
 */

class StaticMenu extends Component {
    menuRef = null;

    static defaultProps = {
        mode: 'vertical',
    };

    constructor(props) {
        super(props);

        this.state = {
            lastSelectedKey: -1,
            activatedKeys: []
        };
    }

    componentDidMount = () => {
        this.initMenu();
        this.setState({activatedKeys: this.props.activatedKeys})
    };

    componentDidUpdate = prevProps => {
        if (
            !prevProps.menu.menuItems ||
            (prevProps.menu.menuItems && prevProps.menu.menuItems !== this.props.menu.menuItems)
        ) {
            this.initMenu();
        }
    };

    initMenu() {
        /*
        if (this.props.mode === 'horizontal') {
            const menuRef = this.menuRef = new MetisMenu('#menu-bar').on('shown.metisMenu', function (event) {
                window.addEventListener('click', function menuClick(e) {
                    if (!event.target.contains(e.target)) {
                        menuRef.hide(event.detail.shownElement);
                        window.removeEventListener('click', menuClick);
                    }
                });
            });
        } else {
            this.menuRef = new MetisMenu('#menu-bar');
        }*/
    }

    didSelectMenuItem = (id, hasChildren) => {
        var activatedKeys = this.state.activatedKeys;
        /*
        const index = this.state.activatedKeys.indexOf(this.state.lastSelectedKey);
        if (index >= 0){
            activatedKeys.splice(index, 1)
        }
        if (!hasChildren || (index < 0) || (id !== this.state.lastSelectedKey)){
            activatedKeys.push(id);
        }*/

        for (let i = 0; i < this.props.menu.menuItems.length; i++) {
            let item = this.props.menu.menuItems[i];
            if (item.id === id) {
                const index = this.state.activatedKeys.indexOf(item.id);
                if (index >= 0) {
                    activatedKeys.splice(index, 1)
                }
                else {
                    activatedKeys.push(item.id);
                }
            }
            else {
                // Check child is selected or not
                var isChildSelected = false;
                if (item.children) {
                    for (let j = 0; j < item.children.length; j++) {
                        let child = item.children[j];
                        if (child.id === id) {
                            isChildSelected = true;
                            const index = this.state.activatedKeys.indexOf(child.id);
                            if (index < 0) {
                                activatedKeys.push(child.id);
                            }
                            break;
                        }
                    }
                }

                if (!isChildSelected) {
                    const index = this.state.activatedKeys.indexOf(item.id);
                    if (index >= 0) {
                        activatedKeys.splice(index, 1)
                    }
                }
            }
        }

        this.setState({ activatedKeys: activatedKeys, lastSelectedKey: id });
    }

    render() {
        const isHorizontal = this.props.mode === 'horizontal';
        const activatedKeys = this.state.activatedKeys;
        const { t } = this.props;
        return (
            <React.Fragment>

                {this.props.menu && this.props.menu.menuItems && (
                    <ul className="metismenu" id="menu-bar">
                        {this.props.menu.menuItems.map((itemOriginal, i) => {
                            let item = Object.assign({}, itemOriginal);
                            item.name = t(itemOriginal.name);
                            if (item.children) {
                                var children = [];
                                item.children.forEach(element => {
                                    let newElement = Object.assign({}, element);
                                    newElement.name = t(element.name);
                                    children.push(newElement);
                                });
                                item.children = children;
                            }
                            return (
                                <React.Fragment key={item.id}>
                                    {item.header && !isHorizontal && (
                                        <li className="menu-title" key={i + '-el'}>
                                            {item.header}
                                        </li>
                                    )}

                                    {item.children ? (
                                        <MenuItemWithChildren
                                            item={item}
                                            subMenuClassNames="nav-second-level"
                                            activatedMenuItemIds={activatedKeys}
                                            linkClassNames="side-nav-link"
                                            didSelectMenuItem={this.didSelectMenuItem}
                                        />
                                    ) : (
                                        <MenuItem
                                            item={item}
                                            className={classNames({ 'mm-active': activatedKeys.indexOf(item.id) >= 0 })}
                                            linkClassName="side-nav-link"
                                            didSelectMenuItem={this.didSelectMenuItem}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations')(StaticMenu);
