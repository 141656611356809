// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    GET_NEXION, GET_NEXION_FAILED, GET_NEXION_SUCCESS,
    GET_NEXION_INFO, GET_NEXION_INFO_FAILED, GET_NEXION_INFO_SUCCESS,
    CREATE_NEXION_REQUEST, CREATE_NEXION_REQUEST_FAILED, CREATE_NEXION_REQUEST_SUCCESS,
    EDIT_NEXION_REQUEST, EDIT_NEXION_REQUEST_SUCCESS, EDIT_NEXION_REQUEST_FAILED,
    DELETE_NEXION_REQUEST, DELETE_NEXION_REQUEST_SUCCESS, DELETE_NEXION_REQUEST_FAILED,
    CREATE_NEXION_OFFER, CREATE_NEXION_OFFER_FAILED, CREATE_NEXION_OFFER_SUCCESS,
    EDIT_NEXION_OFFER, EDIT_NEXION_OFFER_SUCCESS, EDIT_NEXION_OFFER_FAILED,
    DELETE_NEXION_OFFER, DELETE_NEXION_OFFER_SUCCESS, DELETE_NEXION_OFFER_FAILED,
    GET_NEXION_HIERARCHY, GET_NEXION_HIERARCHY_SUCCESS, GET_NEXION_HIERARCHY_FAILED,
    GET_NEXION_TYPE, GET_NEXION_TYPE_SUCCESS, GET_NEXION_TYPE_FAILED,
    GET_NEXION_OFFER_KEYWORDS, GET_NEXION_OFFER_KEYWORDS_SUCCESS, GET_NEXION_OFFER_KEYWORDS_FAILED,
    CREATE_NEXION_OFFER_KEYWORDS, CREATE_NEXION_OFFER_KEYWORDS_SUCCESS, CREATE_NEXION_OFFER_KEYWORDS_FAILED,
} from './constants';

const INIT_STATE = {
    nexions: null,
    nexionsOfType: [],
    nexionOfferKeywords: [],
    nexionInfo: null,
    nexionsHierarchy: null,
    loading: false,
    error: null
};

const Nexion = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEXION:
            return { ...state, loading: true };
        case GET_NEXION_SUCCESS:
            return { ...state, nexions: action.payload, loading: false, error: null };
        case GET_NEXION_FAILED:
            return { ...state, nexions: null, error: action.payload, loading: false };

        case GET_NEXION_TYPE:
            return { ...state, loading: true };
        case GET_NEXION_TYPE_SUCCESS:
            return { ...state, nexionsOfType: action.payload, loading: false, error: null };
        case GET_NEXION_TYPE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NEXION_INFO:
            return { ...state, loading: true };
        case GET_NEXION_INFO_SUCCESS:
            return { ...state, nexionInfo: action.payload, loading: false, error: null };
        case GET_NEXION_INFO_FAILED:
            return { ...state, nexionInfo: null, error: action.payload, loading: false };

        case CREATE_NEXION_REQUEST:
            return { ...state, loading: true };
        case CREATE_NEXION_REQUEST_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_NEXION_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NEXION_REQUEST:
            return { ...state, loading: true };
        case EDIT_NEXION_REQUEST_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_NEXION_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NEXION_REQUEST:
            return { ...state, loading: true };
        case DELETE_NEXION_REQUEST_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_NEXION_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_NEXION_OFFER:
            return { ...state, loading: true };
        case CREATE_NEXION_OFFER_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_NEXION_OFFER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NEXION_OFFER:
            return { ...state, loading: true };
        case EDIT_NEXION_OFFER_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_NEXION_OFFER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NEXION_OFFER:
            return { ...state, loading: true };
        case DELETE_NEXION_OFFER_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_NEXION_OFFER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NEXION_HIERARCHY:
            return { ...state, nexionHierarchy: null, loading: true };
        case GET_NEXION_HIERARCHY_SUCCESS:
            return { ...state, nexionHierarchy: action.payload, loading: false, error: null };
        case GET_NEXION_HIERARCHY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NEXION_OFFER_KEYWORDS:
            return { ...state, nexionHierarchy: null, loading: true };
        case GET_NEXION_OFFER_KEYWORDS_SUCCESS:
            return { ...state, nexionOfferKeywords: action.payload, loading: false, error: null };
        case GET_NEXION_OFFER_KEYWORDS_FAILED:
            return { ...state, nexionOfferKeywords: [], error: action.payload, loading: false };

        case CREATE_NEXION_OFFER_KEYWORDS:
            return { ...state, nexionHierarchy: null, loading: true };
        case CREATE_NEXION_OFFER_KEYWORDS_SUCCESS:
            return { ...state, nexionOfferKeywords: action.payload, loading: false, error: null };
        case CREATE_NEXION_OFFER_KEYWORDS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Nexion;
