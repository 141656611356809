// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Share2, Trash,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Button,
    Table, Col, Row,
    Label,
    UncontrolledTooltip,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from 'reactstrap';
import { childrenOfObject, countryCodeToString, getLocalizedString, locationString, stateCodeToString } from '../../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    changeTheme,
    getAllNetworkAcademics,
    getCompanySpecialties,
    getAllContentsCompany,
    editCompany
} from '../../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth } from '../../../../../helpers/authUtils';
import * as layoutConstants from '../../../../../constants/layout';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import SummaryTable from '../../../Supports/SummaryTable';
import { format } from '../../../../../helpers/stringFormat';

class NetworkAcademicsInformation extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            isEditingCompanyProfile: false,
            selectedCompanyProfile: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());

        if (this.props.allNetworkAcademics == null) {
            this.props.getAllNetworkAcademics({});
        }
        if (this.props.companies?.length == 0) {
            this.props.getAllContentsCompany({});
        }
        if (this.props.companySpecialties == null) {
            this.props.getCompanySpecialties({});
        }


        this.getAcademicsDetails();
    }

    componentDidUpdate = prevProps => {
        if (prevProps.academyId !== this.props.academyId || prevProps.allNetworkAcademics !== this.props.allNetworkAcademics || prevProps.companyProfiles !== this.props.companyProfiles) {
            this.getAcademicsDetails();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    getAcademicsDetails = () => {
        if (this.props.academyId != null && this.props.allNetworkAcademics != null) {
            let academics = this.props.allNetworkAcademics?.find((element) => { return element.id == this.props.academyId });
            this.setState({ academics: academics });
        }
    };

    generalModule = () => {
        return (
            <Row>
                <Col>
                    <Label className='ml-4'>{'No information'}</Label>
                </Col>
            </Row>
        )
    }

    geoTypes = () => {
        return this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
            (element, i) => {
                element.label = getLocalizedString(element.value);
                return element;
            }
        ) ?? [];
    }

    industryWithId = (id) => {
        let industry = null;
        if (id && this.props.menuHierarchy?.industryGroup) {
            let industries = childrenOfObject(this.props.menuHierarchy?.industryGroup);
            industry = industries.find((ind) => { return ind.id == id });
        }
        return industry;
    }

    companyProfiles = () => {
        const profiles = this.props.companyProfiles?.map((profile) => {
            profile.label = profile.name ? getLocalizedString(profile.name) : '';
            return profile;
        }
        );
        return profiles ?? [];
    }

    dataActivities = (company) => {
        let profiles = this.companyProfiles();
        let companyProfile = profiles.find((element) => { return element.id == company.profileId });

        if (companyProfile == null) {
            return [];
        }

        let dataActivities = companyProfile?.dataActivityDetail?.map((element, i) => {
            element.label = element.value ? getLocalizedString(element.value) : '';
            return element;
        }) ?? [];

        if (companyProfile.additionalDataActivityId) {
            if (company.industryGroupId) {
                var data = companyProfile.additionalDataActivityId.find((element) => {
                    return element.industryGroupId == company.industryGroupId;
                });
                if (data?.dataActivityDetail) {
                    data.dataActivityDetail.forEach((element, i) => {
                        element.label = element.value ? getLocalizedString(element.value) : '';
                        if (!dataActivities.some((e) => { return e.id == element.id })) {
                            dataActivities.push(element)
                        }
                    });
                }
            }
            else if (company.industryGroupIds?.length > 0) {
                company.industryGroupIds.forEach(industryGroup => {
                    if (industryGroup.id) {
                        var data = companyProfile.additionalDataActivityId.find((element) => {
                            return element.industryGroupId == industryGroup.id;
                        });
                        if (data?.dataActivityDetail) {
                            data.dataActivityDetail.forEach((element, i) => {
                                element.label = element.value ? getLocalizedString(element.value) : '';
                                if (!dataActivities.some((e) => { return e.id == element.id })) {
                                    dataActivities.push(element)
                                }
                            });
                        }
                    }
                });
            }
        }

        return dataActivities
    }

    academicsSummary = () => {
        let { t } = this.props;

        const countries = this.allCountries();

        let summary = {};
        let academics = this.state.academics;

        if (academics) {
            summary[format(t('general.0name'), t('education.academics'))] = academics.name ?? '';
        }

        return summary;
    }

    changeCompanyProfile = (value) => {
        this.setState({ selectedCompanyProfile: value }, () => {
            var data = {
                profileId: value.id,
                id: this.state.company?.company?.id
            }
            this.props.editCompany(data, {
                callbackOnFailure: (error) => {
                    console.log('edit false');
                },
                callbackOnSuccess: (response) => {
                    let company = this.state.company;
                    if (company.company) {
                        company.company.profileId = value.id;
                    }
                    this.setState({ isEditingCompanyProfile: false, company: company })
                },
            });
        })
    }

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
                selectedNode: null
            });
        }
    }

    informationModule = () => {
        let { t } = this.props;

        if (this.state.academics == null) {
            return this.generalModule();
        }

        let summary = this.academicsSummary();

        return (
            <div className='ml-4 mr-4 mb-3'>
                <div>
                    <Table className="mb-3" striped>
                        <tbody>
                            {Object.keys(summary).length > 0 && Object.keys(summary).map((key, index) => {
                                return (
                                    <tr key={key}>
                                        <th style={{ 'width': '30%' }} scope="row">{key}</th>
                                        {(typeof summary[key] === 'string') ?

                                                <td style={{ 'width': '70%' }}>{summary[key]}</td>

                                            :
                                            <td style={{ 'width': '70%' }}>
                                                <div className='align-vertical'>
                                                    {summary[key].map((value) => {
                                                        return (
                                                            <Label className='font-weight-normal mt-0'>{value}</Label>
                                                        )
                                                    })
                                                    }
                                                </div>

                                            </td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Nav tabs className="">
                        <NavItem key={0}>
                            <NavLink
                                href="#"
                                className={classnames({
                                    active: this.state.activeTabInfo === 1,
                                })}
                                onClick={() => {
                                    this.toggleTabInfo(1);
                                }}>
                                <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                <span className="d-none d-sm-block">
                                    {' '}
                                    {t('academic.schools')}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem key={1}>
                            <NavLink
                                href="#"
                                className={classnames({
                                    active: this.state.activeTabInfo === 2,
                                })}
                                onClick={() => {
                                    this.toggleTabInfo(2);
                                }}>
                                <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                <span className="d-none d-sm-block">
                                    {t('company.locations')}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem key={2}>
                            <NavLink
                                href="#"
                                className={classnames({
                                    active: this.state.activeTabInfo === 3,
                                })}
                                onClick={() => {
                                    this.toggleTabInfo(3);
                                }}>
                                <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                <span className="d-none d-sm-block">{t('company.domains')}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <div className=''>
                        <div className='align-horizontal align-items-flex-start'>
                            <TabContent style={{ width: '100%' }} activeTab={this.state.activeTabInfo}>
                                <TabPane tabId={1} key={1}>
                                    {this.state.activeTabInfo == 1 &&
                                        <div className='chart-border scroll-enable'>
                                            {this.schoolsModule()}
                                        </div>}
                                </TabPane>
                                <TabPane tabId={2} key={2}>
                                    {this.state.activeTabInfo == 2 &&
                                        <div className='chart-border scroll-enable'>
                                            {this.locationsModule()}
                                        </div>}
                                </TabPane>
                                <TabPane tabId={3} key={3}>
                                    {this.state.activeTabInfo == 3 &&
                                        <div className='chart-border scroll-enable'>
                                            {this.domainsModule()}
                                        </div>}
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    allCountries = () => {
        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );
        return countries ?? [];
    }

    schoolsModule = () => {
        let countries = this.allCountries();
        let facultyDoc = this.props.academicDoc?.facultyDoc?.academyFaculties?.map((f) => { return { id: f.order, label: f.value ? getLocalizedString(f.value) : '', entity: f } }) ?? [];
        let allCities = this.state.academics?.cities ?? [];
        let faculties = this.state.academics?.faculties ?? [];
        let summary = {};
        faculties.forEach((f) => {
            let faculty = facultyDoc.find((e) => { return e.id == f.facultyId });
            let cities = (f?.cities ?? []).map((c) => {
                let city = allCities.find((city) => { return city.id == c });
                let location = locationString(city.country, city.state, city.city, countries);
                return location;
            });

            if (faculty) {
                summary[faculty?.label] = cities;
            }
        });

        return <SummaryTable summary={summary} />
    }

    locationsModule = () => {
        let countries = this.allCountries();
        let facultyDoc = this.props.academicDoc?.facultyDoc?.academyFaculties?.map((f) => { return { id: f.order, label: f.value ? getLocalizedString(f.value) : '', entity: f } }) ?? [];
        let allCities = this.state.academics?.cities ?? [];
        let groups = [];
        allCities.forEach((city) => {
            let cityName = city.city ?? '';
            if (city.state?.length > 0) {
                let state = stateCodeToString(city.country, city.state, countries);
                cityName += (cityName.length > 0 ? ', ' : '') + state;
            }

            let faculties = (this.state.academics?.faculties ?? []).filter((f) => { return (f.cities ?? []).some((c) => { return c == city.id }) });
            if (faculties.length > 0) {
                faculties = faculties.map((f) => { return facultyDoc.find((e) => { return e.id == f.facultyId })?.label ?? '' });

                let groupIndex = groups.findIndex((g) => { return g.country == city.country });
                if (groupIndex >= 0) {
                    groups[groupIndex].summary[cityName ?? ''] = faculties;
                }
                else {
                    let summary = {};
                    summary[cityName ?? ''] = faculties
                    groups.push({ country: city.country, summary: summary });
                }
            }
        });

        return <div>
            {groups.map((group) => {
                let country = countryCodeToString(group.country, countries);
                return <div key={group.country} className='path-component align-vertical mb-3'>
                    <Label className="link-selected ml-3">{country} </Label>
                    <SummaryTable summary={group.summary ?? {}} />
                </div>
            })}
        </div>
    }

    domainsModule = () => {
        return <SummaryTable list={this.state.academics?.domains ?? []} />
    }

    render() {
        return (
            <React.Fragment>
                {this.informationModule()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { countries, loading, error } = state.Auth;
    const { menuHierarchy } = state.Profile;
    const { companies, companySpecialties, companyProfiles } = state.Company;
    const { academicDoc } = state.Academics;
    const { allNetworkAcademics } = state.Network;

    return { countries, menuHierarchy, companies, academicDoc, allNetworkAcademics, companySpecialties, companyProfiles, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAllNetworkAcademics,
    getCompanySpecialties,
    getAllContentsCompany,
    editCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademicsInformation));
