import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class SimpleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {
        // this.setState({ checked: this.props.checked ?? false });
    }

    render() {
        let { color, selectedColor, disabled } = this.props;
        let className = this.props.className ?? '';
        let checked = this.props.checked ?? false;
        return (
            <div className={'box-center size-px-20 border-ratio-button rounded-10 ' + className} style={{ borderColor: color ?? '' }}
                onClick={() => {
                    if (disabled ?? false) {
                        return
                    }
                    if (this.props.didChange) { this.props.didChange(!checked) }
                }}>
                {checked &&
                    <div className='size-px-15 rounded-8 bg-color-label' style={{ backgroundColor: selectedColor ?? (color ?? '') }}></div>
                }
            </div>
        )
    }
}

export default withTranslation('translations')(SimpleCheckbox);
