/* AUTH */
export const GET_TRAINING = 'GET_TRAINING';
export const GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_TRAINING_FAILED = 'GET_TRAINING_FAILED';

export const GET_TRAINING_BY_TYPE_ID = 'GET_TRAINING_BY_TYPE_ID';
export const GET_TRAINING_BY_TYPE_ID_SUCCESS = 'GET_TRAINING_BY_TYPE_ID_SUCCESS';
export const GET_TRAINING_BY_TYPE_ID_FAILED = 'GET_TRAINING_BY_TYPE_ID_FAILED';

export const EDIT_TRAINING_DOC = 'EDIT_TRAINING_DOC';
export const EDIT_TRAINING_DOC_SUCCESS = 'EDIT_TRAINING_DOC_SUCCESS';
export const EDIT_TRAINING_DOC_FAILED = 'EDIT_TRAINING_DOC_FAILED';

export const DELETE_TRAINING_DOC = 'DELETE_TRAINING_DOC';
export const DELETE_TRAINING_DOC_SUCCESS = 'DELETE_TRAINING_DOC_SUCCESS';
export const DELETE_TRAINING_DOC_FAILED = 'DELETE_TRAINING_DOC_FAILED';

export const CREATE_TRAINING = 'CREATE_TRAINING';
export const CREATE_TRAINING_SUCCESS = 'CREATE_TRAINING_SUCCESS';
export const CREATE_TRAINING_FAILED = 'CREATE_TRAINING_FAILED';

export const GET_TRAINING_BY_ID = 'GET_TRAINING_BY_ID';
export const GET_TRAINING_BY_ID_SUCCESS = 'GET_TRAINING_BY_ID_SUCCESS';
export const GET_TRAINING_BY_ID_FAILED = 'GET_TRAINING_BY_ID_FAILED';
