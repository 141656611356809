import { AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Modal, Row } from 'reactstrap';
import Loader from '../../components/Loader';
import { isDarkMode } from '../../helpers/authUtils';

class CodeInputModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            size: 'lg',
            code: '',
            className: "custom-modal-style"
        };
    }

    onVerifyButton = () => {
        this.props.verify(this.state.code)
    }

    render() {
        const { t } = this.props;
        let isDark = isDarkMode();
        const props = {
            inputStyle: {
                fontFamily: 'monospace',
                margin: '4px',
                MozAppearance: 'textfield',
                width: '42px',
                borderRadius: '3px',
                fontSize: '18px',
                height: '42px',
                paddingLeft: '7px',
                border: '1px solid gray',
                backgroundColor: isDark ? '#323742' : 'white',
            },
        }

        const sendable = true;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.toggle();
                    }}
                    className={this.state.className}
                    size={this.state.size}
                    style={{ maxWidth: '400px', width: '100%' }}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{t('codeInput.verifyYourPhoneNumber')}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {this.state.errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <br />
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{this.state.errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.toggle();
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ errorMessage: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col xl={12}>

                                                        <br />
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-13'>{t("codeInput.enter6DigitCode") + ': ' + this.props.phoneNumber}</Label>
                                                            </Col>
                                                        </Row>

                                                        <br />
                                                        <Row className='box-center'>
                                                            <Col className='box-center'>
                                                                <ReactCodeInput type='number' fields={6} {...props} onChange={(code) => this.setState({ code })} />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left box-center'>
                                                                <Button color="link" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.resendCode()
                                                                }}><i className='uil'></i>{t('phoneInput.resendCode')}
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <br />

                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.toggle();
                                                                }}><i className='uil'></i>{t("codeInput.cancel")}
                                                                </Button>
                                                                <Button className='ml-3' disabled={!sendable} color="primary" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.onVerifyButton();
                                                                }}><i className='uil'></i>{t('codeInput.continue')}
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        {/* <br />

                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left box-center'>
                                                                <Button color="link" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                }}><i className='uil'></i>{t('phoneInput.termOfService')}
                                                                </Button>
                                                                <Button color="link" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                }}><i className='uil'></i>{t('phoneInput.privacyPolicy')}
                                                                </Button>
                                                            </Col>
                                                        </Row> */}

                                                    </Col>
                                                </Row>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    }
}

export default withTranslation('translations')(CodeInputModal);
