// @flow
import {
    GET_TRAINING,GET_TRAINING_FAILED,GET_TRAINING_SUCCESS,
    EDIT_TRAINING_DOC,EDIT_TRAINING_DOC_FAILED,EDIT_TRAINING_DOC_SUCCESS,
    CREATE_TRAINING, CREATE_TRAINING_FAILED, CREATE_TRAINING_SUCCESS,
    DELETE_TRAINING_DOC, DELETE_TRAINING_DOC_FAILED, DELETE_TRAINING_DOC_SUCCESS,
    GET_TRAINING_BY_TYPE_ID, GET_TRAINING_BY_TYPE_ID_FAILED, GET_TRAINING_BY_TYPE_ID_SUCCESS,
    GET_TRAINING_BY_ID, GET_TRAINING_BY_ID_FAILED, GET_TRAINING_BY_ID_SUCCESS
} from './constants';

export const getTraining = (userId, callbacks) => ({
    type: GET_TRAINING,
    payload: { userId, callbacks },
});

export const getTrainingSuccess = (data) => ({
    type: GET_TRAINING_SUCCESS,
    payload: data,
});

export const getTrainingFailed = (error) => ({
    type: GET_TRAINING_FAILED,
    payload: error,
});

export const getTrainingById = (id, callbacks) => ({
    type: GET_TRAINING_BY_ID,
    payload: { id, callbacks },
});

export const getTrainingByIdSuccess = (data) => ({
    type: GET_TRAINING_BY_ID_SUCCESS,
    payload: data,
});

export const getTrainingByIdFailed = (error) => ({
    type: GET_TRAINING_BY_ID_FAILED,
    payload: error,
});

export const getTrainingByType = (idType, callbacks) => ({
    type: GET_TRAINING_BY_TYPE_ID,
    payload: { idType, callbacks },
});

export const getTrainingByTypeSuccess = (data) => ({
    type: GET_TRAINING_BY_TYPE_ID_SUCCESS,
    payload: data,
});

export const getTrainingByTypeFailed = (error) => ({
    type: GET_TRAINING_BY_TYPE_ID_FAILED,
    payload: error,
});

export const editTrainingDoc = ( data, callbacks) => ({
    type: EDIT_TRAINING_DOC,
    payload: { data, callbacks },
});

export const editTrainingDocSuccess = (data) => ({
    type: EDIT_TRAINING_DOC_SUCCESS,
    payload: data,
});

export const editTrainingDocFailed = (error) => ({
    type: EDIT_TRAINING_DOC_FAILED,
    payload: error,
});

export const deleteTrainingDoc = ( data, callbacks) => ({
    type: DELETE_TRAINING_DOC,
    payload: { data, callbacks },
});

export const deleteTrainingDocSuccess = (data) => ({
    type: DELETE_TRAINING_DOC_SUCCESS,
    payload: data,
});

export const deleteTrainingDocFailed = (error) => ({
    type: DELETE_TRAINING_DOC_FAILED,
    payload: error,
});

export const createTraining = (data, callbacks) => ({
    type: CREATE_TRAINING,
    payload: { data, callbacks },
});

export const createTrainingSuccess = (data) => ({
    type: CREATE_TRAINING_SUCCESS,
    payload: data,
});

export const createTrainingFailed = (error) => ({
    type: CREATE_TRAINING_FAILED,
    payload: error,
});

