import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import { Edit, Edit2, Eye, Lock, PlusSquare, Star, Trash, User } from 'react-feather';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Row, FormGroup, Input, CustomInput, InputGroup,
    InputGroupAddon, Label, Modal, Form, UncontrolledTooltip, Container
} from 'reactstrap';
import Select from 'react-select';
import Loader from '../../../../../components/Loader';
import {
    changePassword, createExperience, editExperience, getRelationAction, getCompanies, getAllNetworkCompanies,
    getAllContentsCompany, getCompanySpecialties, getCompanyProfiles, getCompanyPositionTypes, getGoalsOfCompany, getGoalsOfAcademic, getPersonalSubscription
} from '../../../../../redux/actions';
import { allLanguages, childrenOfObject, customAlertPopup, getCurrentLanguage, getLocalizedString, locationString, monthOnlyDisplayString, uniqueArray, uniqueSimpleArray } from '../../../../../helpers/utils';
import { param } from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import { format } from '../../../../../helpers/stringFormat';
import AlertPopupView from '../../../Supports/AlertPopupView';
import SimpleCheckbox from '../../../Supports/SimpleCheckbox';
import { index, interval, json, sum, timeHours } from 'd3';
import { NETWORK_COMPANY_TYPE_COUNTRY } from '../../../Home/Network/NetworkCompany';
import { getLoggedInUser, getLoggedInUserProfile, isDarkMode } from '../../../../../helpers/authUtils';
import { REGISTRATION_STATUS_IN_PROCESS, REGISTRATION_STATUS_NONE, REGISTRATION_STATUS_REGISTERED } from '../../../Home/Network/NetworkPerson';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from '../../../../../helpers/DatePickerFormat';
import moment from 'moment';

export const EXPERIENCE_TYPE_COMPANY = 0;
export const EXPERIENCE_TYPE_ACADEMIC = 1;

const ADD_EXPERIENCE_CONTEXT = 0;
const ADD_EXPERIENCE_INTEGRATION = 1;
const ADD_EXPERIENCE_PERSONAL = 2;

class AddExperienceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            className: "custom-modal-style",

            dataType: null,
            experienceId: null,
            actionNodeId: null,

            stepTitle: 'Context',
            selectedEType: null,
            selectedDepartment: null,

            selectedGoal: null,
            goalDescription: '',

            selectedLanguage: null,
            selectedDescriptionLanguage: null,

            openSelectIssueTypeDialog: false,
            integrationName: '',
            integrationDescription: '',

            isSelectingIssue: false,
            selectedIssue: null,
            issueDescription: '',
            issues: [],

            isSelectingSource: false,
            selectedSource: null,
            sourceDescription: '',
            selectedSources: [],

            isSelectingTechnology: false,
            selectedTechNology: null,
            technologyDescription: '',
            selectedTechnologies: [],

            isSelectingResult: false,
            selectedResult: null,
            resultDescription: '',
            selectedResults: [],

            isSelectingDepartment: false,
            deployedDepartment: null,
            departmentDescription: '',
            selectedDepartments: [],

            isSelectingContribution: false,
            selectedContributor: null,
            selectedRight: null,
            isSelectingContributionType: false,
            editingContributionType: null,
            selectedContributionType: null,
            contributionRoles: [],
            isSelectingContributionRoleOfTypeAtIndex: null,
            selectedContributionRole: null,
            selectedContributionRoleList: [],
            selectedContributionRoles: [],
            selectedContributions: [],
            contributions: [],
            editingContributionIndex: null,
            editingContributionRole: null,

            isSelectingContributionRoleList: false,
            selectedTechContributionOptional: null,

            personalSubscription: null,
            selectedCareerPosition: null,
            monthStart: null,
            monthEnd: null,

            isSelectingSaleContributionOptional: false,
            selectedSaleContributionOptional: null,

            thirdPartyName: '',
            companyNodeId: null,
            reloadDataExxp: false,
            issueHieachy: [],

            currentStep: ADD_EXPERIENCE_CONTEXT,
        };
    }

    componentDidMount = () => {
        this.props.getRelationAction({})
        if (this.props.companyProfiles == null || this.props.companyProfiles?.length == 0) {
            this.props.getCompanyProfiles({});
        }
        if (this.props.companies?.length == 0) {
            this.props.getAllContentsCompany({});
        }

        this.setState({ stepTitle: this.props.t('experience.Context'), dataType: this.props.dataType }, () => {
            this.prefillData();
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataType != this.props.dataType) {
            this.setState({ dataType: this.props.dataType }, () => {
                this.prefillData();
            });
        }
    }

    prefillData = () => {
        let editingExperience = this.props.editingExperience;
        if (editingExperience == null) {
            this.setState({ companyNodeId: this.props.companyNodeId }, () => {
                if (this.state.companyNodeId != null) {
                    this.prefillGoal(this.state.dataType, this.state.companyNodeId, null);
                }
            });
            return;
        }

        let state = {};
        state.dataType = editingExperience.dataType ?? EXPERIENCE_TYPE_COMPANY;
        state.experienceId = editingExperience.id;

        let experienceTypes = this.props.menuHierarchy?.allActionGroup?.children?.map((element) => { return { value: element.id, label: element.name, entity: element } }) ?? [];
        experienceTypes = experienceTypes.filter((t) => { return t.value == 292 });
        state.selectedEType = editingExperience.type ? experienceTypes.find((element) => { return element.value == editingExperience.type }) : null;

        state.companyNodeId = editingExperience?.companyNodeId;
        if (state.companyNodeId != null) {
            this.prefillGoal(state.dataType, state.companyNodeId, editingExperience?.goalNodeId);
        }

        let languages = allLanguages();
        state.selectedLanguage = languages.find((l) => { return l.value == editingExperience.submitIntegrationINTL });
        state.selectedDescriptionLanguage = languages.find((l) => { return l.value == editingExperience.submitIntegrationDescriptionINTL });

        state.integrationName = editingExperience.submitIntegrationName ?? '';
        state.integrationDescription = editingExperience.submitIntegrationDescription ?? '';

        let technologies = this.objectsFromGroup(this.props.menuHierarchy?.techGroup, 'technologies').map((element) => { return { value: element.id, label: element.name, entity: element } });
        let results = this.childrenFromObject(this.props.menuHierarchy?.buildGroup);
        if (results.length > 0) {
            results.shift();
            results = results.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        state.selectedSources = editingExperience.sources?.map((element) => { return { sourceGroupId: element.sourceGroupId, description: element.description } }) ?? [];
        state.selectedTechnologies = editingExperience.technologies?.map((element) => {
            let tech = technologies.find((t) => { return t.value == element.techId });
            return { techGroupId: element.techId, description: element.description, label: tech?.label ?? '' }
        }) ?? [];
        state.selectedResults = editingExperience.results?.map((element) => {
            let result = results.find((t) => { return t.value == element.buildGroupId });
            return { buildGroupId: element.buildGroupId, description: element.description, label: result?.label ?? '' }
        }) ?? [];
        state.selectedDepartments = editingExperience.departments?.map((element) => { return { departmentGroupId: element.departmentGroupId, description: element.description } }) ?? [];
        let contributions = editingExperience.contributions ?? [];
        contributions.forEach((c) => {
            c.contributions = this.mergeContributions(c.contributions);
        });

        let networkPeople = this.networkPeople();
        contributions = contributions.filter((c) => { return networkPeople.some((p) => { return c.contributorId == p.value }) });
        state.contributions = contributions;

        state.currentStep = ADD_EXPERIENCE_CONTEXT;
        if (this.props.editingExperience != null) {
            if (this.props.isEditingContributors ?? false) {
                state.currentStep = ADD_EXPERIENCE_PERSONAL;
            }
            else if ((this.props.editingExperience.userId ?? '') == getLoggedInUser().id) {
                state.currentStep = ADD_EXPERIENCE_CONTEXT;
            }
            else {
                let myContribution = (this.props.editingExperience.contributions ?? []).find((c) => { return c.contributorId == getLoggedInUserProfile().user.nodeId });
                if (myContribution != null) {
                    let userRight = myContribution.userRight ?? 3;
                    state.currentStep = userRight == 1 ? ADD_EXPERIENCE_INTEGRATION : ADD_EXPERIENCE_PERSONAL;
                }
            }
        }

        this.setState(state);
    }

    prefillGoal = (dataType, companyNodeId, goalNodeId) => {
        if (dataType == EXPERIENCE_TYPE_COMPANY) {
            this.props.getGoalsOfCompany(companyNodeId, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ goals: response }, () => {
                        let goals = this.goals();
                        let selectedGoal = goalNodeId ? goals?.find((element) => { return element.value == goalNodeId }) : null;
                        this.setState({ selectedGoal: selectedGoal });
                    });
                },
            });
        }
        else if (dataType == EXPERIENCE_TYPE_ACADEMIC) {
            this.props.getGoalsOfAcademic(companyNodeId, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ goals: response }, () => {
                        let goals = this.goals();
                        let selectedGoal = goalNodeId ? goals?.find((element) => { return element.value == goalNodeId }) : null;
                        this.setState({ selectedGoal: selectedGoal });
                    });
                },
            });
        }
    }

    cancelAddingSource = () => {
        this.setState({ isSelectingSource: false, selectedSource: null, sourceDescription: '' })
    }

    finishAddingSource = () => {
        var selectedSources = this.state.selectedSources;
        selectedSources.push({
            sourceGroupId: this.state.selectedSource?.value,
            description: this.state.sourceDescription
        })
        this.setState({ isSelectingSource: false, selectedSources: selectedSources, selectedSource: null, sourceDescription: '' })
    }

    cancelAddingTechnology = () => {
        this.setState({ isSelectingTechnology: false, selectedTechNology: null, technologyDescription: '' })
    }

    finishAddingTechnology = () => {
        var selectedTechnologies = this.state.selectedTechnologies;
        selectedTechnologies.push({
            techGroupId: this.state.selectedTechNology?.value,
            description: this.state.technologyDescription,
            label: this.state.selectedTechNology?.label ?? '',
        })
        this.setState({ isSelectingTechnology: false, selectedTechnologies: selectedTechnologies, selectedTechNology: null, technologyDescription: '' })
    }


    cancelAddingResult = () => {
        this.setState({ isSelectingResult: false, selectedResult: null, resultDescription: '' })
    }

    finishAddingResult = () => {
        var selectedResults = this.state.selectedResults;
        selectedResults.push({
            buildGroupId: this.state.selectedResult?.value,
            description: this.state.resultDescription,
            label: this.state.selectedResult?.label ?? '',
        })
        this.setState({ isSelectingResult: false, selectedResults: selectedResults, selectedResult: null, resultDescription: '' })
    }

    cancelAddingDepartment = () => {
        this.setState({ isSelectingDepartment: false, selectedDepartment: null, departmentDescription: '' })
    }

    finishAddingDepartment = () => {
        var selectedDepartments = this.state.selectedDepartments;
        selectedDepartments.push({
            departmentGroupId: this.state.deployedDepartment?.id,
            description: this.state.departmentDescription
        })
        this.setState({ isSelectingDepartment: false, selectedDepartments: selectedDepartments, deployedDepartment: null, departmentDescription: '' })
    }


    cancelAddingContribution = () => {
        this.setState({ isSelectingContribution: false, personalSubscription: null, selectedCareerPosition: null, editingContributionIndex: null, selectedContributor: null, selectedRight: null, selectedContributions: [], selectedTechContributionOptional: null })
    }

    finishAddingContribution = () => {
        var contributions = this.state.contributions;

        let newContribution = {
            contributorId: this.state.selectedContributor?.value,
            userRight: this.state.selectedRight.value,
            careerPositionId: this.state.selectedCareerPosition?.id,
            careerPositionSubId: this.state.selectedCareerPosition?.subId,
            contributions: this.state.selectedContributions.map((c) => { return { id: c.id, roles: c.roles } })
        };

        if (this.state.editingContributionIndex != null) {
            contributions[this.state.editingContributionIndex] = newContribution;
        }
        else {
            contributions.push(newContribution)
        }
        this.setState({ isSelectingContribution: false, personalSubscription: null, selectedCareerPosition: null, editingContributionIndex: null, contributions: contributions, selectedContributor: null, selectedRight: null, selectedContributions: [] })
    }

    editContribution = (contribution, index) => {
        let networkPeople = this.networkPeople();
        let rights = this.props.relationAction?.relationAction?.rights?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];

        let contributor = null;
        let right = null;
        let selectedContributions = [];

        if (contribution.contributorId != null) {
            contributor = networkPeople.find((p) => { return p.value == contribution.contributorId });
        }
        if (contribution.userRight != null) {
            right = rights.find((p) => { return p.value == contribution.userRight });
        }

        if ((contribution.contributions ?? []).length > 0) {
            contribution.contributions.forEach((c) => {
                selectedContributions.push({ isSelected: true, id: c.id, roles: c.roles });
            });
        }

        this.setState({ editingContributionIndex: index, isSelectingContribution: true, selectedContributor: contributor ? JSON.parse(JSON.stringify(contributor)) : null, selectedRight: right, selectedContributions: selectedContributions ? JSON.parse(JSON.stringify(selectedContributions)) : null }, () => {
            this.getPersonalSubscription(this.state.selectedContributor?.entity?.id);
        });
    }

    cancelAddingContributionType = () => {
        this.setState({ isSelectingContributionType: false, contributionRoles: [], editingContributionType: null, selectedContributionType: null, isSelectingContributionRoleOfTypeAtIndex: null, selectedContributionRole: null, selectedContributionRoleList: [] })
    }

    finishAddingContributionType = () => {
        let selectedContributions = this.state.selectedContributions;
        if (this.state.editingContributionType != null) {
            let index = this.state.selectedContributions.findIndex((t) => { return t.id == this.state.editingContributionType.id });
            if (index >= 0) {
                this.state.selectedContributions[index] = this.state.selectedContributionType;
            }
        }
        else {
            selectedContributions.push(this.state.selectedContributionType);
        }
        this.setState({ isSelectingContributionType: false, selectedContributions: selectedContributions, contributionRoles: [], editingContributionType: null, selectedContributionType: null, isSelectingContributionRoleOfTypeAtIndex: null, selectedContributionRole: null, selectedContributionRoleList: [] })
    }

    editContributionType = (contributionType) => {
        let contributions = this.props.relationAction?.relationAction?.relations?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];
        let contribution = contributions.find((c) => { return c.value == contributionType.id });
        let contributionRoles = contribution?.entity?.roles?.map((role) => { return { value: role.id, label: getLocalizedString(role.name), entity: role } }) ?? [];
        this.setState({ isSelectingContributionType: true, editingContributionType: JSON.parse(JSON.stringify(contributionType)), selectedContributionType: JSON.parse(JSON.stringify(contributionType)), contributionRoles: contributionRoles, selectedContributionRoles: contributionType.roles, isSelectingContributionRoleOfTypeAtIndex: null, selectedContributionRole: null, selectedContributionRoleList: [] })
    }

    cancelAddingContributionRoleList = () => {
        this.setState({ isSelectingContributionRoleList: false, selectedTechContributionOptional: null })
    }

    finishAddingContributionRoleList = (selectedContributionType) => {
        let roleList = this.state.selectedContributionRoleList;
        let id = this.state.selectedTechContributionOptional.value;
        if (selectedContributionType.id == 'CONTRIBUTOR') {
            id = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechContributionOptional.techGroupId : this.state.selectedTechContributionOptional.buildGroupId;
        }
        else {
            id = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechContributionOptional.techGroupId : this.state.selectedTechContributionOptional.value;
        }
        roleList.push({ id: id });
        this.setState({ selectedContributionRoleList: roleList, isSelectingContributionRoleList: false, selectedTechContributionOptional: null })
    }

    removeTechContributionOptional = (typeIndex, item) => {
        this.setState({ isSelectingContributionRoleList: false, selectedTechContributionOptional: null })

        let selectedContributionType = this.state.selectedContributionType;
        if (typeIndex >= 0) {
            selectedContributionType = this.state.selectedContributions[typeIndex];
        }

        var roleList = this.state.selectedContributionRoleList;
        let id = item.value;
        if (selectedContributionType.id == 'CONTRIBUTOR') {
            id = this.state.selectedContributionRole.value == 1 ? item.techGroupId : item.buildGroupId;
        }
        else {
            id = this.state.selectedContributionRole.value == 1 ? item.techGroupId : item.value;
        }
        let index = roleList.findIndex((c) => { return c.id == id });
        if (index >= 0) {
            roleList.splice(index, 1);
            this.setState({ selectedContributionRoleList: roleList });
        }
    }

    cancelAddingContributionRole = () => {
        this.setState({ isSelectingContributionRoleOfTypeAtIndex: null, editingContributionRole: null, selectedContributionRole: null, selectedContributionRoleList: [], monthStart: null, monthEnd: null, monthOfSales: null });
    }

    finishAddingContributionRole = (typeIndex) => {
        let selectedContributionType = this.state.selectedContributionType;
        if (typeIndex >= 0) {
            selectedContributionType = this.state.selectedContributions[typeIndex];
        }
        let newRole = {
            id: this.state.selectedContributionRole?.value,
            list: this.state.selectedContributionRoleList,
        };
        if (this.state.monthStart) {
            newRole.monthStart = this.state.monthStart?.valueOf();
        }
        if (this.state.monthEnd) {
            newRole.monthEnd = this.state.monthEnd?.valueOf();
        }
        if (this.state.monthOfSales?.length > 0) {
            newRole.monthOfSales = parseInt(this.state.monthOfSales);
        }

        if (this.state.editingContributionRole != null) {
            let index = selectedContributionType.roles.findIndex((r) => { return r.id == this.state.editingContributionRole.value });
            if (index >= 0) {
                selectedContributionType.roles[index] = newRole;
            }
        }
        else {
            selectedContributionType.roles.push(newRole);
        }

        let params = {
            selectedContributions: this.state.selectedContributions,
            selectedContributionType: this.state.selectedContributionType,
            isSelectingContributionRoleOfTypeAtIndex: null,
            editingContributionRole: null,
            selectedContributionRole: null,
            selectedContributionRoleList: [],
            monthStart: null,
            monthEnd: null,
            monthOfSales: null
        };
        if (typeIndex >= 0) {
            let selectedContributions = this.state.selectedContributions;
            selectedContributions[typeIndex] = selectedContributionType;
            params.selectedContributions = selectedContributions;
        }
        else {
            params.selectedContributionType = selectedContributionType;
        }

        this.setState(params);
    }

    editContributionRole = (contributionRoles, typeIndex, roleId) => {
        let role = contributionRoles.find((r) => { return r.value == roleId });
        let selectedContributionType = this.state.selectedContributionType;
        if (typeIndex >= 0) {
            selectedContributionType = this.state.selectedContributions[typeIndex];
        }

        let selectedRole = selectedContributionType?.roles?.find((r) => { return r.id == roleId });
        let list = selectedRole?.list ?? [];
        let monthStart = selectedRole.monthStart ? moment(selectedRole.monthStart)?.toDate() : null;
        let monthEnd = selectedRole.monthEnd ? moment(selectedRole.monthEnd)?.toDate() : null;
        let monthOfSales = selectedRole.monthOfSales?.toString();
        this.setState({
            isSelectingContributionRoleOfTypeAtIndex: typeIndex,
            editingContributionRole: JSON.parse(JSON.stringify(role)),
            selectedContributionRole: JSON.parse(JSON.stringify(role)),
            selectedContributionRoleList: JSON.parse(JSON.stringify(list)),
            monthStart: monthStart,
            monthEnd: monthEnd,
            monthOfSales: monthOfSales
        });
    }

    removeTechContributionRole = (typeIndex, roleIndex) => {
        if (typeIndex >= 0) {
            let selectedContributions = this.state.selectedContributions;
            selectedContributions[typeIndex].roles.splice(roleIndex, 1);
            this.setState({ selectedContributions: selectedContributions });
        }
        else {
            let selectedContributionType = this.state.selectedContributionType;
            selectedContributionType.roles.splice(roleIndex, 1);
            this.setState({ selectedContributionType: selectedContributionType });
        }
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    parameters = () => {
        if (this.state.dataType == null) {
            return {};
        }

        let params = { dataType: this.state.dataType };

        params.type = this.state.selectedEType?.value;
        params.companyNodeId = this.state.companyNodeId;

        if (this.props.editingExperience != null) {
            if (this.props.editingExperience?.userId != getLoggedInUser().id) {
                params.type = this.props.editingExperience.type;
                params.companyNodeId = this.props.editingExperience.companyNodeId;
            }
            if (this.props.editingExperience.actionNodeId != null) {
                params.actionNodeId = this.props.editingExperience.actionNodeId;
            }
        }

        params.goalNodeId = this.state.selectedGoal?.value;
        params.submitIntegrationINTL = this.state.selectedLanguage?.value;

        if (this.state.integrationName.length > 0) {
            params.submitIntegrationName = this.state.integrationName;
        }

        if (this.state.integrationDescription.length > 0) {
            params.submitIntegrationDescriptionINTL = this.state.selectedDescriptionLanguage?.value;
            params.submitIntegrationDescription = this.state.integrationDescription;
        }

        if (this.state.selectedSources.length > 0) {
            params.sources = this.state.selectedSources.map((element, index) => { return { order: index, sourceGroupId: element.sourceGroupId, description: element.description } });
        }

        if (this.state.selectedTechnologies.length > 0) {
            params.technologies = this.state.selectedTechnologies.map((element, index) => { return { order: index, techId: element.techGroupId, description: element.description } });
        }

        if (this.state.selectedResults.length > 0) {
            params.results = this.state.selectedResults.map((element, index) => { return { order: index, buildGroupId: element.buildGroupId, description: element.description } });
        }

        if (this.state.selectedDepartments.length > 0) {
            params.departments = this.state.selectedDepartments.map((element, index) => { return { order: index, departmentGroupId: element.departmentGroupId, description: element.description } });
        }

        if (this.state.contributions.length > 0) {
            params.contributions = this.state.contributions;
        }

        return params;
    }

    saveExperience = (isDone) => {
        let params = this.parameters();
        if (isDone) {
            params.isDone = true;
        }

        if (this.state.experienceId) {
            this.props.editExperience(this.state.experienceId, params, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({
                        reloadDataExxp: true
                    }, function () {
                        if (this.props.reloadData) {
                            this.props.reloadData();
                        }
                        if (this.props.editingExperience != null || (isDone ?? false)) {
                            this.props.onCancel(true);
                        }
                    });
                },
            })
        }
        else {
            this.props.createExperience(params, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?                    
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({
                        experienceId: response.id,
                        reloadDataExxp: true
                    }, function () {
                        if (this.props.reloadData) {
                            this.props.reloadData();
                        }
                        if (isDone ?? false) {
                            this.props.onCancel(true);
                        }
                    });
                },
            })
        }
    }

    childrenFromObject = (object) => {
        if (object == null) {
            return [];
        }

        let child = Object.assign({}, object);
        child.children = null;
        let children = [];
        children.push(child);

        let grandChildren = object.children ?? [];
        if (grandChildren.length > 0) {
            for (let i = 0; i < grandChildren.length; i++) {
                let grand = this.childrenFromObject(grandChildren[i])
                children = children.concat(grand)
            }
        }
        return children;
    }

    objectsFromGroup = (group, key) => {
        if (group == null) {
            return [];
        }

        let object = [];
        if (group[key]?.length > 0) {
            object = group[key];
        }

        if (group.children?.length > 0) {
            for (let i = 0; i < group.children.length; i++) {
                let grandChildren = this.objectsFromGroup(group.children[i], key);
                object = object.concat(grandChildren);
            }
        }
        return object;
    }

    addIssueGroupToIssueHieachy = (issueHieachy, issueGroup) => {
        if (issueGroup == null) {
            return
        }
        let hieachy = issueHieachy;
        let children = issueGroup.children?.map((element) => { return { value: element.id, label: element.name, entity: element } }) ?? [];
        let group = { value: issueGroup.id, label: issueGroup.name, children: children, selectedChild: null, entity: issueGroup };
        hieachy.push(group);
        this.setState({ issueHieachy: hieachy });
    }

    selectIssueGroup = (issueGroup) => {
        let hieachy = this.state.issueHieachy;
        let index = hieachy.findIndex((element) => { return element.children.find((e) => { return e.value == issueGroup.id }) != null })
        if (index >= 0) {
            hieachy[index].selectedChild = issueGroup;
            if (hieachy.length > index + 1) {
                hieachy = hieachy.slice(0, index + 1);
            }
        }
        this.addIssueGroupToIssueHieachy(hieachy, issueGroup);
        this.setState({ selectedIssue: issueGroup }, () => { })
    }

    allNetworkCompanies = () => {
        let companies = [];
        if (this.props.allNetworkCompanies) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );

            companies = this.props.allNetworkCompanies?.map((company) => {
                let name = company.name;
                let location = locationString(company.countryHQ, company.stateHQ, company.cityHQ, countries);
                if (location.length > 0) {
                    name += (name.length > 0 ? ' - ' : '') + location;
                }
                company.label = name;

                return company;
            }) ?? [];
        }

        if (companies.length > 1) {
            companies.sort((a, b) => {
                let nameA = a.label ?? '';
                let nameB = b.label ?? '';

                return nameA > nameB ? 1 : -1;
            })
        }

        return companies;
    }

    allNetworkAcademics = () => {
        let academics = [];
        if (this.props.allNetworkAcademics) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );

            academics = this.props.allNetworkAcademics?.map((company) => {
                let name = company.name;
                let location = locationString(company.countryHQ, company.stateHQ, company.cityHQ, countries);
                if (location.length > 0) {
                    name += (name.length > 0 ? ' - ' : '') + location;
                }
                company.label = name;

                return company;
            }) ?? [];
        }

        if (academics.length > 1) {
            academics.sort((a, b) => {
                let nameA = a.label ?? '';
                let nameB = b.label ?? '';

                return nameA > nameB ? 1 : -1;
            })
        }

        return academics;
    }

    departments = () => {
        let departments = childrenOfObject(this.props.menuHierarchy?.departmentGroup);
        if (departments.length > 0) {
            departments = departments.map((element) => {
                element.label = element.name;
                return element
            })
        }
        if (departments?.length > 0) {
            departments.splice(0, 1);
        }
        return departments ?? [];
    }

    goals = () => {
        return this.state.goals?.map((goal) => { return { value: goal.id ?? goal.value, label: goal.name ?? goal.label, entity: goal } }) ?? [];
    }

    onBack = () => {
        this.setState({ currentStep: this.state.currentStep - 1 });
    }

    onNext = () => {
        if (this.state.currentStep == ADD_EXPERIENCE_CONTEXT) {
            // Check goal
            let companyNodeId = this.state.companyNodeId;
            if (this.props.editingExperience != null && this.props.editingExperience.userId != getLoggedInUser().id) {
                companyNodeId = this.props.editingExperience.companyNodeId;
            }
            this.setState({ currentStep: this.state.currentStep + 1 });
        }
        else if (this.state.selectedSources.length == 0 || this.state.selectedTechnologies.length == 0 || this.state.selectedResults.length == 0 || this.state.selectedDepartments.length == 0) {
            this.setState({ error: { message: this.props.t('experience.integrationDetailsMissing') } });
        }
        else {
            this.setState({ currentStep: this.state.currentStep + 1 });
        }
    }

    disableNext = () => {
        let nextable = false;
        switch (this.state.currentStep) {
            case ADD_EXPERIENCE_CONTEXT:
                nextable = this.state.selectedEType != null && this.state.companyNodeId != null && this.state.selectedGoal != null && this.state.integrationName?.length > 0 && this.state.selectedLanguage != null;
                if (nextable && this.state.integrationDescription?.length > 0) {
                    nextable = this.state.selectedDescriptionLanguage != null;
                }
                break;
            case ADD_EXPERIENCE_INTEGRATION:
                nextable = true;
                break;
            default: break;
        }

        return !nextable;
    }

    networkPeople = () => {
        let people = [];
        if (this.props.allNetworkPeople) {
            people = this.props.allNetworkPeople?.map((element) => {
                let name = element.firstName ?? '';
                if (element.lastName?.length > 0) {
                    name += (name.length > 0 ? ' ' : '') + element.lastName;
                }
                return { value: element.nodeId, label: name, entity: element };
            }) ?? [];

            people = people.sort((a, b) => {
                return b.label > a.label ? -1 : 1;
            })
        }
        if (this.props.editingExperience?.contributions?.length > 0 && this.props.subscriptionProfile?.people != null) {
            this.props.editingExperience.contributions.forEach((contribution) => {
                if (!people.some((p) => { return p.value == contribution.contributorId }) && this.props.subscriptionProfile.people[contribution.contributorId] != null) {
                    people.unshift({ value: contribution.contributorId, label: this.props.subscriptionProfile.people[contribution.contributorId], entity: { id: '' } });
                }
                let saleContribution = contribution.contributions?.find((c) => { return c.id == 'SALES' });
                let competencySale = saleContribution?.roles?.find((r) => { return r.id == 2 });
                if (competencySale?.list?.length > 0) {
                    competencySale.list.forEach((item) => {
                        if (!people.some((p) => { return p.value == item.id }) && this.props.subscriptionProfile.people[item.id] != null) {
                            people.unshift({ value: item.id, label: this.props.subscriptionProfile.people[item.id], entity: { id: '' } });
                        }
                    });
                }
            });
        }

        let myIndex = people.findIndex((element) => { return element.value == getLoggedInUserProfile().user.nodeId });
        if (people.length > 1 && myIndex >= 0) {
            let me = people[myIndex];
            me.label = '- ' + this.props.t('profile.me') + ' -';
            people.splice(myIndex, 1);
            people.unshift(me);
            people = people.sort((a, b) => {
                return b.id == getLoggedInUser().id ? -1 : 1;
            })
        }

        return people;
    }

    theSame = (source, destination) => {
        let same = source?.length == destination?.length;
        if (same && source?.length > 0) {
            source.forEach((e, index) => {
                for (var key in Object.keys(e)) {
                    same = e[key] == destination[index][key];
                    if (!same) {
                        return false;
                    }
                }
            });
        }
        return same;
    }

    mergeContributions = (contributions) => {
        let mergedContributions = [];

        let contributionIds = contributions.map((c) => { return c.id });
        let uniqueContributionIds = uniqueSimpleArray(contributionIds);
        uniqueContributionIds.forEach((id) => {
            let contributionsById = contributions.filter((c) => { return c.id == id });
            let contribution = { id: id, roles: [] };
            contributionsById.forEach((c) => {
                if (c.roles?.length > 0) {
                    c.roles.forEach((role) => {
                        let roleIndex = contribution.roles.findIndex((r) => { return r.id == role.id });
                        if (roleIndex == -1) {
                            contribution.roles.push(role);
                        }
                        else {
                            contribution.roles[roleIndex].list = [...contribution.roles[roleIndex].list, ...role.list];
                            contribution.roles[roleIndex].list = uniqueArray(contribution.roles[roleIndex].list, 'id');
                        }
                    });
                }
            });

            mergedContributions.push(contribution);
        });

        return mergedContributions;
    }

    getPersonalSubscription = (userId) => {
        if (userId?.length > 0) {
            this.props.getPersonalSubscription(userId, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ personalSubscription: response }, () => {
                        this.fillSelectedCareer();
                    });
                },
            });
        }
    }

    fillSelectedCareer = () => {
        if (this.state.editingContributionIndex != null) {
            let contribution = this.state.contributions[this.state.editingContributionIndex];
            let positions = this.careerPositions();
            let selectedCareerPosition = (contribution?.careerPositionId && positions.length > 0) ? positions.find((p) => { return p.id == contribution.careerPositionId }) : null;
            this.setState({ selectedCareerPosition: selectedCareerPosition });
        }
    }

    editContributionModule = () => {
        let { t, menuHierarchy, relationAction } = this.props;

        let networkPeople = this.networkPeople();
        let remainingNetwork = networkPeople.filter((e) => {
            return !(this.state.contributions.some((c) => { return c.contributorId == e.value }))
        });

        let positions = this.careerPositions();

        let sources = this.childrenFromObject(menuHierarchy?.sourceGroup);
        if (sources.length > 0) {
            sources.shift();
            sources = sources.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        let contributions = relationAction?.relationAction?.relations?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];
        let remainingContributions = contributions.filter((contribution) => {
            return !this.state.selectedContributions.some((c) => { return c.id == contribution.value });
        });
        let rights = relationAction?.relationAction?.rights?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];

        let valueStyle = { minWidth: '300px' }

        let showPreviousButton = this.state.currentStep != ADD_EXPERIENCE_CONTEXT;
        if (this.props.editingExperience != null) {
            if (this.state.currentStep == ADD_EXPERIENCE_INTEGRATION) {
                showPreviousButton = this.props.editingExperience?.userId == getLoggedInUser().id;
            }
            else if (this.state.currentStep == ADD_EXPERIENCE_PERSONAL) {
                if (this.props.editingExperience?.userId == getLoggedInUser().id) {
                    showPreviousButton = true;
                }
                else {
                    let myContribution = (this.props.editingExperience.contributions ?? []).find((c) => { return c.contributorId == getLoggedInUserProfile().user.nodeId });
                    if (myContribution != null) {
                        let userRight = myContribution.userRight ?? 3;
                        showPreviousButton = userRight == 1;
                    }
                }
            }
        }

        //Selecting Contribution Role
        let selectedRoles = [];
        (this.state.selectedContributionType?.roles ?? []).forEach((role) => {
            let foundObject = this.state.contributionRoles.find((r) => { return r.value == role.id });
            if (foundObject) {
                selectedRoles.push(foundObject);
            }
        });

        let remainingRoles = this.state.contributionRoles.filter((e) => {
            let filtered = (this.state.selectedContributionType?.roles ?? []).filter((r) => { return r.id == e.value });
            return filtered.length == 0
        });
        if (this.state.editingContributionRole != null) {
            remainingRoles.push(this.state.editingContributionRole);
        }

        let optionalTitle = '';
        let optionalList = [];
        let selectedOptionalList = [];
        let remainingOptionalList = [];
        if (this.state.selectedContributionType?.id == 'CONTRIBUTOR' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'experience.Result');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : this.state.selectedResults;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }

        else if (this.state.selectedContributionType?.id == 'SALES' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'general.person');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : networkPeople;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }
        //Selecting Contribution Role

        const disableAddContributorType = this.state.isSelectingContributionRoleOfTypeAtIndex != null;

        let person = this.state.selectedContributor?.entity ?? null;
        const updated = person?.dateUpdate != null;
        const requested = person?.username?.length > 0;
        const registrationStatus = requested ? (updated ? REGISTRATION_STATUS_REGISTERED : REGISTRATION_STATUS_IN_PROCESS) : REGISTRATION_STATUS_NONE;

        return <div className='width-percent-100 input-container'>
            <Row className={showPreviousButton ? 'mb-2' : ''}>
                <Col md={showPreviousButton ? 7 : 12} xl={showPreviousButton ? 7 : 12}>
                    {this.state.editingContributionIndex != null ?
                        <div className={'align-horizontal ml-4 mb-2'}>
                            {this.userType('userType' + this.state.editingContributionIndex, registrationStatus, person?.invitations?.requests?.length > 0)}
                            <Label className='font-bold ml-2'>{this.state.selectedContributor?.label ?? ''}</Label>
                            {!showPreviousButton &&
                                <div>
                                    <Label className='ml-4 mr-4'>{'|'}</Label>
                                    {this.state.selectedRight?.value == 3 &&
                                        <Eye className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />
                                    }
                                    {this.state.selectedRight?.value == 2 &&
                                        <Edit2 className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />
                                    }
                                    {this.state.selectedRight?.value == 1 &&
                                        <Star className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />
                                    }
                                    <Label className='font-medium'>{this.state.selectedRight?.label ?? ''}</Label>
                                </div>
                            }
                        </div>
                        :
                        <Select className="react-select mb-2"
                            classNamePrefix="react-select"
                            name="technologies" id="technologies"
                            options={remainingNetwork}
                            placeholder={t('placeholder.Select')}
                            // getOptionLabel={e => (
                            //     <div style={{ display: 'flex', alignItems: 'center' }}>
                            //         <User className="size-px-18 mr-2" color='gray' style={{ 'transform': 'translateY(-1px)' }} />
                            //         <span style={{ marginLeft: 3 }}>{e.label}</span>
                            //     </div>
                            // )}
                            value={this.state.selectedContributor}
                            onChange={(value) => {
                                if (value.value != this.state.selectedContributor?.value) {
                                    this.setState({ selectedContributor: value, selectedCareerPosition: null, personalSubscription: null }, () => {
                                        this.getPersonalSubscription(value.entity.id);
                                    })
                                }
                            }} />
                    }
                </Col>
                {showPreviousButton &&
                    <Col md={5} xl={5}>
                        <Select className="react-select"
                            classNamePrefix="react-select"
                            name="technologies" id="technologies"
                            options={rights}
                            placeholder={t('placeholder.Select')}
                            getOptionLabel={e => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {e.value == 1 &&
                                        <Star className="size-px-18 mr-2" color='gray' />
                                    }
                                    {e.value == 2 &&
                                        <Edit2 className="size-px-18 mr-2" color='gray' />
                                    }
                                    {e.value == 3 &&
                                        <Eye className="size-px-18 mr-2" color='gray' />
                                    }
                                    <span style={{ marginLeft: 3 }}>{e.label}</span>
                                </div>
                            )}
                            value={this.state.selectedRight}
                            onChange={(value) => { this.setState({ selectedRight: value }) }} />
                    </Col>
                }
            </Row>

            {this.state.selectedContributor &&
                <Row>
                    <Col className='mb-4' md={12} xl={12}>
                        <Row>
                            <Col className='path-component font-size-15 text-left ml-3'>
                                <Label className='link-selected font-size-15'>{t('career.position')}</Label>
                            </Col>
                        </Row>
                        <Select className="react-select"
                            classNamePrefix="react-select"
                            name="technologies" id="technologies"
                            options={positions}
                            placeholder={t('placeholder.Select')}
                            value={(this.state.selectedCareerPosition && positions.length > 0) ? positions.find((element) => { return element.id == this.state.selectedCareerPosition.id }) : null}
                            onChange={(value) => { this.setState({ selectedCareerPosition: value }) }} />
                    </Col>
                </Row>
            }

            <div className='height-px-1 width-percent-100 bg-color-separator'></div>

            <Col className='mb-4' md={12} xl={12} style={valueStyle}>
                <div className='align-vertical width-percent-100'>
                    {this.state.selectedContributions.length > 0 &&
                        <div className='mt-2'>
                            {this.state.selectedContributions.map((type, index) => {
                                let contributionType = contributions.find((c) => { return c.value == type.id });
                                let description = getLocalizedString(contributionType.entity?.description ?? '');
                                return <div key={index}>
                                    <div className='align-vertical mb-3'>
                                        <div className='path-component align-horizontal ml-3'>
                                            <div>
                                                <Label id='contribution_type' className={'link-selected font-size-15' + (description?.length > 0 ? ' cursor-pointer' : '')}>{contributionType.label ?? ''}</Label>
                                                {description?.length > 0 &&
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        id='contribution_type'
                                                        target='contribution_type'>
                                                        {description}
                                                    </UncontrolledTooltip>
                                                }
                                            </div>
                                            <div className='box-center ml-4'>
                                                <div className='width-px-40 box-center ml-1' onClick={() => {
                                                    if (!(this.state.isSelectingContributionType || this.state.isSelectingContributionRoleOfTypeAtIndex != null)) {
                                                        let selectedContributions = this.state.selectedContributions;
                                                        selectedContributions.splice(index, 1);
                                                        this.setState({ selectedContributions: selectedContributions });
                                                        this.cancelAddingContributionType();
                                                    }
                                                }}>
                                                    <Trash className={"icon-dual ml-2 icon-size-20" + ((this.state.isSelectingContributionType || this.state.isSelectingContributionRoleOfTypeAtIndex != null) ? ' feather-disable' : '')} id="remove-type" />
                                                    {!(this.state.isSelectingContributionType || this.state.isSelectingContributionRoleOfTypeAtIndex != null) &&
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            id='tooltip-1'
                                                            target='remove-type'>
                                                            {t("experience.hover.removeContributionType")}
                                                        </UncontrolledTooltip>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='ml-4 mr-4'>
                                            {this.contributionRolesModule(type, index)}
                                        </div>
                                    </div>

                                    {(index == this.state.selectedContributions.length - 1) && remainingContributions.length > 0 && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                </div>
                            })}
                        </div>
                    }
                    <Col className='align-vertical font-size-15 text-left mt-2'>
                        {!this.state.isSelectingContributionType && remainingContributions.length > 0 &&
                            <Label className={'align-horizontal ml-3 mt-1 mb-3 link-selected font-size-15' + (disableAddContributorType ? ' text-color-subtitle' : ' cursor-pointer')}
                                onClick={() => {
                                    if (disableAddContributorType) {
                                        return;
                                    }
                                    if (this.state.isSelectingContributionRoleOfTypeAtIndex != null) {
                                        return;
                                    }
                                    if (this.state.isSelectingContributionType) {
                                        this.cancelAddingContributionType()
                                    }
                                    else {
                                        this.setState({ isSelectingContributionType: true })
                                    }
                                }}
                            >
                                <div className='align-horizontal'>
                                    <PlusSquare className={"icon-dual mr-2 size-px-20" + (disableAddContributorType ? ' feather-disable' : '')} id="icon-add-source" />
                                    {!disableAddContributorType &&
                                        <UncontrolledTooltip
                                            placement="top"
                                            id='tooltip-1'
                                            target='icon-add-source'>
                                            {t("experience.hover.addSource")}
                                        </UncontrolledTooltip>
                                    }
                                </div>
                                {t('general.contributorType')}
                            </Label>
                        }

                        {(this.state.isSelectingContributionType || remainingContributions?.length > 0) &&
                            <div className='width-percent-100 mt-2'>
                                {this.state.isSelectingContributionType &&
                                    <div className='input-container'>
                                        <Select className="react-select"
                                            classNamePrefix="react-select"
                                            name="career" id="career"
                                            options={remainingContributions}
                                            placeholder={t('placeholder.Select')}
                                            value={(this.state.selectedContributionType && remainingContributions.length > 0) ? remainingContributions.find((element) => { return element.value == this.state.selectedContributionType.id }) : null}
                                            onChange={(value) => { this.setState({ selectedContributionType: { id: value.value, roles: [] }, contributionRoles: value?.entity?.roles?.map((role) => { return { value: role.id, label: getLocalizedString(role.name), entity: role } }) ?? [] }) }} />

                                        {this.state.selectedContributionType != null &&
                                            <Row className='width-percent-100 ml-3 mt-3 mb-0'>
                                                <Col md={11} xl={11}>
                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                        <Label className='link-selected font-size-15'>{t('general.contributionRoles')}</Label>
                                                    </Col>

                                                    {this.contributionRolesModule(this.state.selectedContributionType, -1)}
                                                </Col>
                                            </Row>
                                        }

                                        <ul className="list-inline wizard mt-3">
                                            <li className="previous list-inline-item">
                                                <Button className='mr-2' onClick={() => { this.cancelAddingContributionType() }} color="white">{t("personalInfo.cancel")}</Button>
                                                <Button onClick={() => { this.finishAddingContributionType() }} color="primary" disabled={this.state.selectedContributionType == null || this.state.isSelectingContributionRoleOfTypeAtIndex}>{t('general.Add')}</Button>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        }
                    </Col>
                </div>
            </Col>

            <ul className="list-inline wizard mt-3">
                <li className="previous list-inline-item">
                    <Button className='mr-2' onClick={() => { this.cancelAddingContribution() }} color="white">{t("personalInfo.cancel")}</Button>
                    <Button onClick={() => { this.finishAddingContribution() }} color="primary"
                        disabled={
                            this.state.selectedContributor == null ||
                            this.state.selectedRight == null ||
                            this.state.selectedContributions.length == 0 ||
                            this.state.selectedContributions.find((contribution) => {
                                return contribution.roles.length == 0
                            }) != null
                        }
                    >{t(this.state.editingContributionIndex != null ? 'updateCV.validate' : 'general.Add')}</Button>
                </li>
            </ul>
        </div>
    }

    contributionRolesModule = (selectedContributionType, index) => {
        let { t, menuHierarchy, relationAction } = this.props;

        //Selecting Contribution Role
        let networkPeople = this.networkPeople();

        let contributions = relationAction?.relationAction?.relations?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];
        let contribution = contributions.find((c) => { return c.value == selectedContributionType.id });
        let contributionRoles = contribution?.entity?.roles?.map((role) => { return { value: role.id, label: getLocalizedString(role.name), entity: role } }) ?? [];

        let selectedRoles = [];
        (selectedContributionType?.roles ?? []).forEach((role) => {
            let foundObject = contributionRoles.find((r) => { return r.value == role.id });
            if (foundObject) {
                selectedRoles.push(foundObject);
            }
        });
        let remainingRoles = contributionRoles.filter((e) => {
            let filtered = (selectedContributionType?.roles ?? []).filter((r) => { return r.id == e.value });
            return filtered.length == 0
        });
        if (this.state.editingContributionRole != null) {
            remainingRoles.push(this.state.editingContributionRole);
        }

        let optionalTitle = '';
        let optionalList = [];
        let selectedOptionalList = [];
        let remainingOptionalList = [];
        if (selectedContributionType?.id == 'CONTRIBUTOR' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'experience.Result');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : this.state.selectedResults;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }

        else if (selectedContributionType?.id == 'SALES' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'general.person');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : networkPeople;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }
        //Selecting Contribution Role

        const disableAddContributionRole = this.state.isSelectingContributionType ? (index != -1) : (this.state.isSelectingContributionRoleOfTypeAtIndex != null && this.state.isSelectingContributionRoleOfTypeAtIndex != index);

        const isDark = isDarkMode();
        let disableAddRole = this.state.isSelectingContributionRoleList || this.state.selectedContributionRole == null || this.state.selectedContributionRoleList.length == 0;
        if (disableAddRole) {
            if (selectedContributionType?.id == 'CONTRIBUTOR') {
                disableAddRole = this.state.monthStart == null
            }
            else if (selectedContributionType?.id == 'SALES') {
                disableAddRole = this.state.monthOfSales == null || this.state.monthOfSales?.length == 0;
            }
        }

        return <div className='border-1 rounded-3 align-vertical width-percent-100'>
            {selectedRoles.length > 0 && selectedRoles.map((role, roleIndex) => {
                let listString = '';
                let list = (selectedContributionType?.roles ?? []).find((r) => { return r.id == role.value })?.list ?? [];
                if (list.length > 0) {
                    let optionals = [];
                    if (selectedContributionType?.id == 'CONTRIBUTOR') {
                        optionals = role.value == 1 ? this.state.selectedTechnologies : this.state.selectedResults;
                    }
                    else {
                        optionals = role.value == 1 ? this.state.selectedTechnologies : networkPeople;
                    }

                    list.forEach((objectId) => {
                        let object = optionals.find((o) => {
                            let id = o.value;
                            if (selectedContributionType?.id == 'CONTRIBUTOR') {
                                id = role.value == 1 ? o.techGroupId : o.buildGroupId;
                            }
                            else {
                                id = role.value == 1 ? o.techGroupId : o.value;
                            }
                            return id == objectId.id
                        });
                        if (object == null && this.props.subscriptionProfile?.people != null) {
                            object = this.props.subscriptionProfile.people[objectId.id];
                        }
                        if (object != null) {
                            listString += (listString.length == 0 ? '' : ', ') + (object.label ?? object);
                        }
                    });
                }
                return <div key={roleIndex}>
                    <div className='align-vertical'>
                        <div className='align-horizontal ml-3'>
                            <Label>{role.label ?? ''}</Label>
                            <div className='box-center ml-4'>
                                <div className='width-px-40 box-center' onClick={() => {
                                    if (this.state.isSelectingContributionRoleOfTypeAtIndex == null) {
                                        this.editContributionRole(contributionRoles, index, role.value);
                                    }
                                }}>
                                    <Edit className={"icon-dual ml-2 icon-size-20" + (this.state.isSelectingContributionRoleOfTypeAtIndex != null ? ' feather-disable' : '')} id="edit-contribution-role" />
                                    {this.state.isSelectingContributionRoleOfTypeAtIndex == null &&
                                        <UncontrolledTooltip
                                            placement="top"
                                            id='tooltip-1'
                                            target='edit-contribution-role'>
                                            {t("experience.hover.editContributionRole")}
                                        </UncontrolledTooltip>
                                    }
                                </div>
                                <div className='width-px-40 box-center ml-1' onClick={() => {
                                    if (this.state.isSelectingContributionRoleOfTypeAtIndex == null) {
                                        this.removeTechContributionRole(index, roleIndex);
                                    }
                                }}>
                                    <Trash className={"icon-dual ml-2 icon-size-20" + (this.state.isSelectingContributionRoleOfTypeAtIndex != null ? ' feather-disable' : '')} id="remove-contribution-role" />
                                    {this.state.isSelectingContributionRoleOfTypeAtIndex == null &&
                                        <UncontrolledTooltip
                                            placement="top"
                                            id='tooltip-1'
                                            target='remove-contribution-role'>
                                            {t("experience.hover.removeContributionRole")}
                                        </UncontrolledTooltip>
                                    }
                                </div>
                            </div>
                        </div>
                        <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{listString}</Label>
                    </div>

                    {(roleIndex == selectedRoles.length - 1) && remainingRoles.length > 0 && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                </div>
            })}

            <Col className='align-vertical font-size-15 text-left mt-2'>
                {(this.state.isSelectingContributionRoleOfTypeAtIndex != index) && remainingRoles.length > 0 &&
                    <div className='align-horizontal mb-2 ml-1'>
                        <Label className={'ml-2 mt-1 mb-1 align-horizontal link-selected font-size-15' + (disableAddContributionRole ? ' text-color-subtitle' : ' cursor-pointer')}
                            onClick={() => {
                                if (!disableAddContributionRole && this.state.isSelectingContributionRoleOfTypeAtIndex == null) {
                                    this.setState({ isSelectingContributionRoleOfTypeAtIndex: index, selectedContributionRole: null, selectedContributionRoleList: [] })
                                }
                            }}
                        >
                            <div className='align-horizontal'>
                                <PlusSquare className={"icon-dual icon-size-20 mr-2" + (disableAddContributionRole ? ' feather-disable' : '')} id="icon-add-tech-contributor-optional" />
                                {!disableAddContributionRole &&
                                    <UncontrolledTooltip
                                        placement="top"
                                        id='tooltip-1'
                                        target='icon-add-tech-contributor-optional'>
                                        {t("experience.hover.addTechnologyContributionOptional")}
                                    </UncontrolledTooltip>
                                }
                            </div>
                            {t('general.contributionRole')}
                        </Label>
                    </div>
                }

                {this.state.isSelectingContributionRoleOfTypeAtIndex == index && remainingRoles.length > 0 &&
                    <div className='input-container'>
                        <Select className="react-select"
                            classNamePrefix="react-select"
                            name="technologies" id="technologies"
                            options={remainingRoles}
                            placeholder={t('placeholder.Select')}
                            value={(this.state.selectedContributionRole && remainingRoles.length > 0) ? remainingRoles.find((element) => { return element.value == this.state.selectedContributionRole.value }) : null}
                            onChange={(value) => { this.setState({ selectedContributionRole: value, selectedContributionRoleList: [], isSelectingContributionRoleList: false }) }} />

                        {optionalList.length > 0 &&
                            <Row className='width-percent-100 ml-5 mt-0 mb-0'>
                                <Col md={10} xl={10}>
                                    <Row className='mt-2 mb-0'>
                                        <Col className='path-component font-size-15 text-left mt-2'>
                                            <Label className='link-selected font-size-15'>{optionalTitle}</Label>
                                        </Col>
                                    </Row>

                                    <Row className='mb-0 border-1 rounded-3'>
                                        <div className='align-vertical width-percent-100'>
                                            {selectedOptionalList.length > 0 && selectedOptionalList.map((techOptional, optionIndex) => {
                                                return <div key={optionIndex}>
                                                    <div className='align-vertical'>
                                                        <div className='align-horizontal ml-3'>
                                                            {this.state.selectedContributionType?.id == 'SALES' && this.state.selectedContributionRole?.value == 2 &&
                                                                <User className="size-px-20 mr-2" />
                                                            }
                                                            <Label>{techOptional.label ?? ''}</Label>
                                                            <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                this.removeTechContributionOptional(index, techOptional)
                                                            }}>
                                                                <Trash className="icon-dual ml-2 icon-size-20" id="remove-source" />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    id='tooltip-1'
                                                                    target='remove-source'>
                                                                    {t("experience.hover.removeContributionList")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        </div>
                                                        <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{techOptional?.description ?? ''}</Label>
                                                    </div>

                                                    {(optionIndex == selectedOptionalList.length - 1) && remainingOptionalList.length > 0 && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                </div>
                                            })}

                                            <Col className='align-vertical font-size-15 text-left mt-2'>
                                                {!this.state.isSelectingContributionRoleList && remainingOptionalList.length > 0 &&
                                                    <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                        <div className='align-horizontal'>
                                                            <PlusSquare className="icon-dual icon-size-20" id="icon-add-tech-contributor-optional"
                                                                onClick={() => {
                                                                    if (this.state.isSelectingContributionRoleList) {
                                                                        this.cancelAddingContributionRoleList()
                                                                    }
                                                                    else {
                                                                        this.setState({ isSelectingContributionRoleList: true })
                                                                    }
                                                                }} />
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                id='tooltip-1'
                                                                target='icon-add-tech-contributor-optional'>
                                                                {t("experience.hover.addTechnologyContributionOptional")}
                                                            </UncontrolledTooltip>
                                                        </div>

                                                        <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                            onClick={() => {
                                                                if (this.state.isSelectingContributionRoleList) {
                                                                    this.cancelAddingContributionRoleList()
                                                                }
                                                                else {
                                                                    this.setState({ isSelectingContributionRoleList: true })
                                                                }
                                                            }}
                                                        >{optionalTitle}</Label>
                                                    </div>
                                                }

                                                {this.state.isSelectingContributionRoleList && remainingOptionalList.length > 0 &&
                                                    <div className='input-container'>
                                                        <Select className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="technologies" id="technologies"
                                                            options={remainingOptionalList}
                                                            placeholder={t('placeholder.Select')}
                                                            // getOptionLabel={e => (
                                                            //     <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            //         {this.state.parentContribution?.value == 'SALES' && this.state.selectedContributionRole?.value == 2 &&
                                                            //             <User className="size-px-18 mr-2" color='gray' style={{ 'transform': 'translateY(-1px)' }} />
                                                            //         }
                                                            //         <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                            //     </div>
                                                            // )}
                                                            value={(this.state.selectedTechContributionOptional && remainingOptionalList.length > 0) ? remainingOptionalList.find((element) => { return element.value == this.state.selectedTechContributionOptional.value }) : null}
                                                            onChange={(value) => { this.setState({ selectedTechContributionOptional: value }) }} />

                                                        <div className="list-inline wizard mt-3 mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <div className="previous list-inline-item">
                                                                <Button className='mr-2' onClick={() => { this.cancelAddingContributionRoleList() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                <Button onClick={() => { this.finishAddingContributionRoleList(selectedContributionType) }} color="primary" disabled={this.state.selectedTechContributionOptional == null}>{t('general.Add')}</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        }

                        {this.state.selectedContributionRole != null &&
                            <div>
                                {selectedContributionType?.id == 'CONTRIBUTOR' ?
                                    <Row className='width-percent-100 ml-5 mt-0 mb-0'>
                                        <Col md={5} xl={5}>
                                            <Row className='mt-2 mb-0'>
                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                    <Label className='link-selected font-size-15'>{t('career.monthStart')}</Label>
                                                </Col>
                                            </Row>
                                            <div className='width-percent-100'>
                                                <DatePicker
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    placeholderText="(MM/YYYY)"
                                                    selected={this.state.monthStart}
                                                    className="date-picker width-percent-100 p-2"
                                                    // minDate={this.state.selectedCareerPosition?.monthStart}
                                                    // maxDate={this.state.selectedCareerPosition?.monthEnd}
                                                    onChange={(value) => { this.setState({ monthStart: value }) }}
                                                    locale={getCurrentLanguage()}
                                                />
                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                            </div>
                                        </Col>
                                        <Col md={5} xl={5}>
                                            <Row className='mt-2 mb-0'>
                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                    <Label className='link-selected font-size-15'>{t('career.monthEnd')}</Label>
                                                </Col>
                                            </Row>
                                            <div className='width-percent-100'>
                                                <DatePicker
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    placeholderText="(MM/YYYY)"
                                                    selected={this.state.monthEnd}
                                                    className="date-picker width-percent-100 p-2"
                                                    minDate={this.state.monthStart}
                                                    // maxDate={this.state.selectedCareerPosition?.monthEnd}
                                                    onChange={(value) => { this.setState({ monthEnd: value }) }}
                                                    locale={getCurrentLanguage()}
                                                />
                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className='width-percent-100 ml-5 mt-0 mb-0'>
                                        <Col md={10} xl={10}>
                                            <Row className='mt-2 mb-0'>
                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                    <Label className='link-selected font-size-15'>{t('contributor.monthOfSales')}</Label>
                                                </Col>
                                            </Row>
                                            <Input
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                type="number"
                                                name="monthOfSales"
                                                id="monthOfSales"
                                                autoComplete="off"
                                                // placeholder={t('contributor.monthOfSales')}
                                                value={this.state.monthOfSales}
                                                onChange={(e) => { this.setState({ monthOfSales: e.target.value }) }}></Input>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        }

                        <ul className="list-inline wizard mt-3">
                            <li className="previous list-inline-item">
                                <Button className='mr-2' onClick={() => {
                                    this.cancelAddingContributionRole()
                                }} color="white">{t("personalInfo.cancel")}</Button>
                                <Button onClick={() => {
                                    this.finishAddingContributionRole(index)
                                }} color="primary" disabled={disableAddRole}>{t(this.state.editingContributionRole != null ? 'personalInfo.save' : 'general.Add')}</Button>
                            </li>
                        </ul>
                    </div>
                }
            </Col>
        </div>
    }

    userType = (id, registrationStatus, invited) => {
        let color = 'gray';
        let hover = 'personStatus.noInvitation';
        switch (registrationStatus) {
            case REGISTRATION_STATUS_REGISTERED:
                color = 'green';
                hover = 'personStatus.registered'
                break;
            case REGISTRATION_STATUS_IN_PROCESS:
                color = 'orange';
                hover = 'personStatus.underSignup'
                break;
            default:
                color = invited ? 'red' : 'gray';
                hover = invited ? 'personStatus.invited' : 'personStatus.noInvitation'
                break;
        }
        return (
            <div className={'box-center size-px-20'}>
                <User id={id} className="size-px-20" color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    positionLevels = () => {
        const companiesPositionType = this.props.companies?.positionType?.listDocDetails?.positionType?.map(
            (element, i) => {
                return { value: i, label: getLocalizedString(element.value), en: element.value.en, id: element.id };
            }
        );

        return companiesPositionType ?? [];
    }

    careerPositions = () => {
        let levels = this.positionLevels();
        let positions = [];
        (this.state.personalSubscription?.careers ?? []).forEach((career) => {
            let companyName = career.company?.name ?? '';
            (career.career?.career ?? []).forEach((c) => {
                let summary = companyName;
                if (c.positionLevel) {
                    let position = levels.find((p) => { return p.id == c.positionLevel })?.label ?? '';
                    if (position.length > 0) {
                        summary += (summary.length > 0 ? ', ' : '') + position;
                    }
                }
                let time = (c.monthStart ?? '-') + ' - ' + (c.monthEnd ?? this.props.t('general.present'));
                summary += (summary.length > 0 ? ' | ' : '') + time;

                positions.push({
                    id: career.career.id,
                    subId: c.id,
                    label: summary,
                    monthStart: c.monthStart?.length > 0 ? moment(c.monthStart, 'MM/YYYY').toDate() : null,
                    monthEnd: c.monthEnd?.length > 0 ? moment(c.monthEnd, 'MM/YYYY').toDate() : null,
                });
            });
        });

        return positions;
    }

    render() {
        const { t, menuHierarchy, relationAction } = this.props;

        let allNetworkCompanies = [];
        if (this.state.dataType != null) {
            allNetworkCompanies = this.state.dataType == EXPERIENCE_TYPE_COMPANY ? this.allNetworkCompanies() : this.allNetworkAcademics();
        }

        let dataTypes = [
            { label: t('subscription.company'), id: EXPERIENCE_TYPE_COMPANY },
            { label: t('education.academics'), id: EXPERIENCE_TYPE_ACADEMIC },
        ];

        let selectedDataType = this.state.dataType != null ? dataTypes.find((t) => { return t.id == this.state.dataType }) : null;
        let typeName = selectedDataType?.label ?? '';

        let experienceTypes = menuHierarchy?.allActionGroup?.children?.map((element) => { return { value: element.id, label: element.name, entity: element } }) ?? [];
        experienceTypes = experienceTypes.filter((t) => { return t.value == 292 });

        let departments = this.departments();
        let goals = this.goals();
        let languages = allLanguages();
        let technologies = this.objectsFromGroup(menuHierarchy?.techGroup, 'technologies').map((element) => { return { value: element.id, label: element.name, entity: element } });
        let remainingTechnologies = technologies.filter((t) => { return !this.state.selectedTechnologies.some((st) => { return st.techGroupId == t.value }) });

        let sources = this.childrenFromObject(menuHierarchy?.sourceGroup);
        if (sources.length > 0) {
            sources.shift();
            sources = sources.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }
        let remainingSources = sources.filter((s) => { return !this.state.selectedSources.some((ss) => { return ss.sourceGroupId == s.value }) });

        let results = this.childrenFromObject(menuHierarchy?.buildGroup);
        if (results.length > 0) {
            results.shift();
            results = results.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }
        let remainingResults = results.filter((r) => { return !this.state.selectedResults.some((sr) => { return sr.buildGroupId == r.value }) });

        let allDepartments = this.childrenFromObject(menuHierarchy?.departmentGroup);
        if (allDepartments.length > 0) {
            allDepartments.shift();
            allDepartments = allDepartments.map((element) => { element.label = element.name; return element })
        }
        let remainingDepartments = allDepartments.filter((d) => { return !this.state.selectedDepartments.some((sd) => { return sd.departmentGroupId == d.id }) });

        let networkPeople = this.networkPeople();
        let remainingNetwork = networkPeople.filter((e) => {
            return !(this.state.contributions.some((c) => { return c.contributorId == e.value }))
        });

        let contributions = relationAction?.relationAction?.relations?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];
        let remainingContributions = contributions.filter((contribution) => {
            return !this.state.selectedContributions.some((c) => { return c.id == contribution.id });
        });
        let rights = relationAction?.relationAction?.rights?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];

        let valueStyle = { minWidth: '300px' }

        let headerTitle = format(t(this.props.editingExperience ? t('general.modify0') : t('general.add0')), t(this.props.isEditingContributors ? 'general.contributors' : 'home.experience').toLowerCase());
        let errorMessage = this.state.error?.response?.data?.message ? this.state.error?.response?.data?.message : this.state.error?.message;

        let disableNext = this.disableNext();


        let isEditingContributors = this.props.isEditingContributors ?? false;
        let showPreviousButton = this.state.currentStep != ADD_EXPERIENCE_CONTEXT;
        if (this.props.editingExperience != null) {
            if (this.state.currentStep == ADD_EXPERIENCE_INTEGRATION) {
                showPreviousButton = this.props.editingExperience?.userId == getLoggedInUser().id;
            }
            else if (this.state.currentStep == ADD_EXPERIENCE_PERSONAL) {
                if (this.props.editingExperience?.userId == getLoggedInUser().id) {
                    showPreviousButton = true;
                }
                else {
                    let myContribution = (this.props.editingExperience.contributions ?? []).find((c) => { return c.contributorId == getLoggedInUserProfile().user.nodeId });
                    if (myContribution != null) {
                        let userRight = myContribution.userRight ?? 3;
                        showPreviousButton = userRight == 1;
                    }
                }
            }
        }

        let isSelectingItems = this.state.isSelectingSource || this.state.isSelectingTechnology || this.state.isSelectingResult || this.state.isSelectingDepartment || this.state.isSelectingContribution;
        let disableSaveButton = this.state.selectedEType == null || this.state.companyNodeId == null || isSelectingItems;
        if (this.props.editingExperience != null && this.props.editingExperience?.userId != getLoggedInUser().id) {
            disableSaveButton = isSelectingItems;
        }

        let userRight = 1;
        if (this.props.editingExperience != null) {
            if ((this.props.editingExperience.userId ?? '') == getLoggedInUser().id) {
                userRight = 1;
            }
            else {
                let myContribution = (this.props.editingExperience.contributions ?? []).find((c) => { return c.contributorId == getLoggedInUserProfile().user.nodeId });
                if (myContribution != null) {
                    userRight = myContribution.userRight ?? 3;
                }
            }
        }

        let didntChangeAnything = false;
        if (this.props.editingExperience != null) {
            let params = this.parameters();

            let sameType = params.companyNodeId == this.props.editingExperience.companyNodeId;
            let sameCompany = params.companyNodeId == this.props.editingExperience.companyNodeId;
            let sameGoal = params.goalNodeId == this.props.editingExperience.goalNodeId;
            let sameLanguage = (params.submitIntegrationINTL ?? '') == (this.props.editingExperience.submitIntegrationINTL ?? '');
            let sameIntegrationName = (params.submitIntegrationName ?? '') == (this.props.editingExperience.submitIntegrationName ?? '');
            let sameIDesLanguage = (params.submitIntegrationDescriptionINTL ?? '') == (this.props.editingExperience.submitIntegrationDescriptionINTL ?? '');
            let sameIDes = (params.submitIntegrationDescription ?? '') == (this.props.editingExperience.submitIntegrationDescription ?? '');

            let sameSources = this.theSame(params.sources, this.props.editingExperience.sources);
            let sameTechnologies = this.theSame(params.technologies, this.props.editingExperience.technologies);
            let sameResults = this.theSame(params.results, this.props.editingExperience.results);
            let sameDepartments = this.theSame(params.departments, this.props.editingExperience.departments);

            let sameContributions = params.contributions?.length == this.props.editingExperience.contributions?.length;
            if (sameContributions && params.contributions?.length > 0) {
                for (var i = 0; i < params.contributions.length; i++) {
                    sameContributions = params.contributions[i].contributorId == this.props.editingExperience.contributions[i].contributorId &&
                        params.contributions[i].userRight == this.props.editingExperience.contributions[i].userRight &&
                        params.contributions[i].careerPositionId == this.props.editingExperience.contributions[i].careerPositionId &&
                        params.contributions[i].careerPositionSubId == this.props.editingExperience.contributions[i].careerPositionSubId &&
                        params.contributions[i].contributions?.length == this.props.editingExperience.contributions[i].contributions?.length;
                    if (sameContributions) {
                        if (params.contributions[i].contributions?.length > 0 && params.contributions[i].contributions?.length == this.props.editingExperience.contributions[i].contributions?.length) {
                            for (var j = 0; j < params.contributions[i].contributions.length; j++) {
                                sameContributions = params.contributions[i].contributions[j].id == this.props.editingExperience?.contributions[i]?.contributions[j]?.id &&
                                    params.contributions[i].contributions[j].roles.length == this.props.editingExperience?.contributions[i].contributions[j].roles.length;
                                if (sameContributions) {
                                    if (params.contributions[i].contributions[j].roles?.length > 0 && params.contributions[i].contributions[j].roles.length == this.props.editingExperience?.contributions[i].contributions[j].roles.length) {
                                        for (var k = 0; k < params.contributions[i].contributions[j].roles.length; k++) {
                                            sameContributions = params.contributions[i].contributions[j].roles[k].id == this.props.editingExperience?.contributions[i].contributions[j].roles[k].id &&
                                                params.contributions[i].contributions[j].roles[k].list?.length == this.props.editingExperience?.contributions[i].contributions[j].roles[k].list?.length;
                                            if (sameContributions) {
                                                if (params.contributions[i].contributions[j].id == 'CONTRIBUTOR') {
                                                    sameContributions = params.contributions[i].contributions[j].roles[k].monthStart == this.props.editingExperience?.contributions[i].contributions[j].roles[k].monthStart &&
                                                        params.contributions[i].contributions[j].roles[k].monthEnd == this.props.editingExperience?.contributions[i].contributions[j].roles[k].monthEnd;
                                                }
                                                else if (params.contributions[i].contributions[j].id == 'SALES') {
                                                    sameContributions = params.contributions[i].contributions[j].roles[k].monthOfSales == this.props.editingExperience?.contributions[i].contributions[j].roles[k].monthOfSales;
                                                }
                                            }
                                            if (sameContributions) {
                                                sameContributions = this.theSame(params.contributions[i].contributions[j].roles[k].list, this.props.editingExperience?.contributions[i].contributions[j].roles[k].list);
                                                if (sameContributions) { break; }
                                            } else { break; }
                                        }
                                    }
                                } else { break; }
                            }
                        }
                    } else { break; }
                }
            }

            didntChangeAnything = sameType && sameCompany &&
                sameGoal && sameLanguage && sameIntegrationName && sameIDesLanguage && sameIDes &&
                sameSources && sameTechnologies && sameResults && sameDepartments &&
                sameContributions;
        }


        //Selecting Contribution Role
        let selectedRoles = [];
        (this.state.selectedContributionType?.roles ?? []).forEach((role) => {
            let foundObject = this.state.contributionRoles.find((r) => { return r.value == role.id });
            if (foundObject) {
                selectedRoles.push(foundObject);
            }
        });

        let remainingRoles = this.state.contributionRoles.filter((e) => {
            let filtered = (this.state.selectedContributionType?.roles ?? []).filter((r) => { return r.id == e.value });
            return filtered.length == 0
        });
        if (this.state.editingContributionRole != null) {
            remainingRoles.push(this.state.editingContributionRole);
        }

        let optionalTitle = '';
        let optionalList = [];
        let selectedOptionalList = [];
        let remainingOptionalList = [];
        if (this.state.selectedContributionType?.id == 'CONTRIBUTOR' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'experience.Result');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : this.state.selectedResults;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.buildGroupId;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }

        else if (this.state.selectedContributionType?.id == 'SALES' && this.state.selectedContributionRole != null) {
            optionalTitle = t(this.state.selectedContributionRole.value == 1 ? 'experience.Technology' : 'general.person');
            optionalList = this.state.selectedContributionRole.value == 1 ? this.state.selectedTechnologies : networkPeople;
            selectedOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length > 0
            });
            remainingOptionalList = optionalList.filter((e) => {
                let id = this.state.selectedContributionRole.value == 1 ? e.techGroupId : e.value;
                let filtered = this.state.selectedContributionRoleList.filter((r) => { return r.id == id });
                return filtered.length == 0
            });
        }
        //Selecting Contribution Role

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.onCancel(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}
                    style={{ maxWidth: '600px', width: '100%' }}>

                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.onCancel(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    let state = {};
                                                                    state.error = null;
                                                                    // if (this.state.error.type == 'empty goal') {
                                                                    //     state.companyNodeId = null;
                                                                    // }
                                                                    this.setState(state);
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <div>
                                                    <Row>
                                                        {this.state.currentStep == ADD_EXPERIENCE_CONTEXT &&
                                                            <Col xl={12}>
                                                                <Label className="font-bold font-size-17">{t('experience.Context')}</Label>
                                                                <br />
                                                                <br />

                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{format(t('general.select0'), t('experience.ExperienceType').toLowerCase())}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="action" id="action"
                                                                            options={experienceTypes}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={(this.state.selectedEType != null && experienceTypes.length > 0) ? experienceTypes.find((element) => { return element.value == this.state.selectedEType.value }) : null}
                                                                            onChange={(value) => { this.setState({ selectedEType: value }) }} />
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{format(t('general.select0'), t('home.network').toLowerCase())}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="action" id="action"
                                                                            options={dataTypes}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={this.state.dataType != null ? dataTypes.find((element) => { return element.id == this.state.dataType }) : null}
                                                                            onChange={(value) => { this.setState({ dataType: value.id, companyNodeId: null, selectedGoal: null }) }} 
                                                                            isDisabled = {this.props.editingExperience != null}/>
                                                                    </Col>
                                                                </Row>

                                                                {this.state.dataType != null &&
                                                                    <div className=' align-vertical'>
                                                                        <Row className='mb-3'>
                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                <Label className='link-selected font-size-15'>{format(t('general.0name'), typeName)}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="career" id="career"
                                                                            options={allNetworkCompanies}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={(this.state.companyNodeId != null && allNetworkCompanies?.length > 0) ? allNetworkCompanies.find((element) => { return element.nodeId == this.state.companyNodeId }) : null}
                                                                            onChange={(value) => {
                                                                                this.setState({ companyNodeId: value.nodeId }, () => {
                                                                                    if (this.state.companyNodeId != null) {
                                                                                        if (this.state.dataType == EXPERIENCE_TYPE_COMPANY) {
                                                                                            this.props.getGoalsOfCompany(this.state.companyNodeId, {
                                                                                                callbackOnBegin: () => {
                                                                                                    this.setState({ loading: true });
                                                                                                },
                                                                                                callbackOnFailure: (error) => {
                                                                                                    // show error to your users or stay quiet
                                                                                                    this.setState({ loading: false, error: error });
                                                                                                },
                                                                                                callbackOnFinish: () => {
                                                                                                    // Update loading state?
                                                                                                    this.setState({ loading: false });
                                                                                                },
                                                                                                callbackOnSuccess: (response) => {
                                                                                                    this.setState({ goals: response }, () => {
                                                                                                        if (this.state.goals.length == 0) {
                                                                                                            let error = {};
                                                                                                            error.type = 'empty goal';
                                                                                                                    error.message = format(t('error.noGoalAvailable'), typeName.toLowerCase(), typeName.toLowerCase());
                                                                                                            this.setState({ error: error, selectedGoal: null });
                                                                                                        }
                                                                                                    });
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                        else if (this.state.dataType == EXPERIENCE_TYPE_ACADEMIC) {
                                                                                            this.props.getGoalsOfAcademic(this.state.companyNodeId, {
                                                                                                callbackOnBegin: () => {
                                                                                                    this.setState({ loading: true });
                                                                                                },
                                                                                                callbackOnFailure: (error) => {
                                                                                                    // show error to your users or stay quiet
                                                                                                    this.setState({ loading: false, error: error });
                                                                                                },
                                                                                                callbackOnFinish: () => {
                                                                                                    // Update loading state?
                                                                                                    this.setState({ loading: false });
                                                                                                },
                                                                                                callbackOnSuccess: (response) => {
                                                                                                    this.setState({ goals: response }, () => {
                                                                                                        if (this.state.goals.length == 0) {
                                                                                                            let error = {};
                                                                                                            error.type = 'empty goal';
                                                                                                                    error.message = format(t('error.noGoalAvailable'), typeName.toLowerCase(), typeName.toLowerCase());
                                                                                                            this.setState({ error: error, selectedGoal: null });
                                                                                                        }
                                                                                                    });
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.Goal')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='input-container'>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="career" id="career"
                                                                            options={goals ?? []}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={(this.state.selectedGoal != null && goals?.length > 0) ? goals.find((element) => { return element.value == this.state.selectedGoal.value }) : null}
                                                                            onChange={(value) => { this.setState({ selectedGoal: value }) }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.Language')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='input-container'>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="career" id="career"
                                                                            options={languages}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={(this.state.selectedLanguage != null && languages?.length > 0) ? languages.find((element) => { return element.value == this.state.selectedLanguage.value }) : null}
                                                                            onChange={(value) => {
                                                                                let state = { selectedLanguage: value };
                                                                                if (this.state.selectedDescriptionLanguage == null || this.state.integrationDescription == null || this.state.integrationDescription?.length == 0) {
                                                                                    state.selectedDescriptionLanguage = value;
                                                                                }
                                                                                this.setState(state);
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.IntegrationName')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='input-container'>
                                                                        <Input
                                                                            className="react-select"
                                                                            type="text"
                                                                            name="integrationName"
                                                                            id="integrationName"
                                                                            autoComplete="off"
                                                                            placeholder={t('experience.IntegrationName')}
                                                                            value={this.state.integrationName}
                                                                            onChange={(e) => { this.setState({ integrationName: e.target.value }) }}></Input>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.descriptionLanguage')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='input-container'>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="career" id="career"
                                                                            options={languages}
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={(this.state.selectedDescriptionLanguage != null && languages?.length > 0) ? languages.find((element) => { return element.value == this.state.selectedDescriptionLanguage.value }) : null}
                                                                            onChange={(value) => { this.setState({ selectedDescriptionLanguage: value }) }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('nexions.description')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='input-container'>
                                                                        <Input
                                                                            className="react-select"
                                                                            type="text"
                                                                            name="integrationDescription"
                                                                            id="integrationDescription"
                                                                            autoComplete="off"
                                                                            placeholder={t('nexions.description')}
                                                                            value={this.state.integrationDescription}
                                                                            onChange={(e) => { this.setState({ integrationDescription: e.target.value }) }}></Input>
                                                                    </Col>
                                                                </Row>
                                                                    </div>
                                                                }

                                                            </Col>
                                                        }

                                                        {this.state.currentStep == ADD_EXPERIENCE_INTEGRATION &&
                                                            <Col xl={12}>
                                                                <Label className="font-bold font-size-17">{t('experience.integration')}</Label>
                                                                <br />
                                                                <br />

                                                                <Row className='mb-0'>
                                                                    <Col className='path-component font-size-15 text-left'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.Source')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-4 border-1 rounded-3'>
                                                                    <div className='align-vertical width-percent-100'>
                                                                        {this.state.selectedSources?.length > 0 && this.state.selectedSources.map((element, index) => {
                                                                            let source = sources?.find((e) => { return e.value == element.sourceGroupId });

                                                                            return <div key={index}>
                                                                                <div className='align-vertical'>
                                                                                    <div className='align-horizontal ml-3'>
                                                                                        <Label>{source?.label ?? ''}</Label>
                                                                                        <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                            var selectedSources = this.state.selectedSources;
                                                                                            selectedSources.splice(index, 1);
                                                                                            this.setState({ selectedSources: selectedSources });
                                                                                        }}>
                                                                                            <Trash className="icon-dual ml-2 icon-size-20" id="remove-source" />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='remove-source'>
                                                                                                {t("experience.hover.removeSource")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                                </div>

                                                                                {(index == this.state.selectedSources.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                            </div>
                                                                        })}

                                                                        <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                            {!this.state.isSelectingSource &&
                                                                                <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                    <div className='align-horizontal'>
                                                                                        <PlusSquare className="icon-dual icon-size-20" id="icon-add-source"
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingSource) {
                                                                                                    this.cancelAddingSource()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingSource: true })
                                                                                                }
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target='icon-add-source'>
                                                                                            {t("experience.hover.addSource")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>

                                                                                    <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            if (this.state.isSelectingSource) {
                                                                                                this.cancelAddingSource()
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ isSelectingSource: true })
                                                                                            }
                                                                                        }}
                                                                                    >{t('experience.Source')}</Label>
                                                                                </div>
                                                                            }

                                                                            {(this.state.isSelectingSource || sources?.length > 0) &&
                                                                                <div className='width-percent-100'>
                                                                                    {this.state.isSelectingSource &&
                                                                                        <div className='input-container'>
                                                                                            <Select className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                name="career" id="career"
                                                                                                options={remainingSources}
                                                                                                placeholder={t('placeholder.Select')}
                                                                                                value={(this.state.selectedSource && remainingSources.length > 0) ? remainingSources.find((element) => { return element.value == this.state.selectedSource.value }) : null}
                                                                                                onChange={(value) => { this.setState({ selectedSource: value }) }} />

                                                                                            <Input
                                                                                                className="react-select mt-3"
                                                                                                classNamePrefix="react-select"
                                                                                                type="text"
                                                                                                name="goalDescription"
                                                                                                id="goalDescription"
                                                                                                autoComplete="off"
                                                                                                placeholder={t('nexions.description')}
                                                                                                value={this.state.sourceDescription}
                                                                                                onChange={(e) => { this.setState({ sourceDescription: e.target.value }) }}></Input>

                                                                                            <ul className="list-inline wizard mt-3">
                                                                                                <li className="previous list-inline-item">
                                                                                                    <Button className='mr-2' onClick={() => { this.cancelAddingSource() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                    <Button onClick={() => { this.finishAddingSource() }} color="primary" disabled={this.state.selectedSource == null}>{t('general.Add')}</Button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </div>
                                                                </Row>

                                                                <Row className='mt-2 mb-0'>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.Technology')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-4 border-1 rounded-3'>
                                                                    <div className='align-vertical width-percent-100'>
                                                                        {this.state.selectedTechnologies?.length > 0 && this.state.selectedTechnologies.map((element, index) => {
                                                                            let technology = technologies?.find((e) => { return e.value == element.techGroupId });

                                                                            return <div key={index}>
                                                                                <div className='align-vertical'>
                                                                                    <div className='align-horizontal ml-3'>
                                                                                        <Label>{technology?.label ?? ''}</Label>
                                                                                        <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                            var selectedTechnologies = this.state.selectedTechnologies;
                                                                                            selectedTechnologies.splice(index, 1);
                                                                                            this.setState({ selectedTechnologies: selectedTechnologies });
                                                                                        }}>
                                                                                            <Trash className="icon-dual ml-2 icon-size-20" id="remove-source" />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='remove-source'>
                                                                                                {t("experience.hover.removeTechnology")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                                </div>

                                                                                {(index == this.state.selectedTechnologies.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                            </div>
                                                                        })}

                                                                        <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                            {!this.state.isSelectingTechnology &&
                                                                                <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                    <div className='align-horizontal'>
                                                                                        <PlusSquare className="icon-dual icon-size-20" id="icon-add-technology"
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingTechnology) {
                                                                                                    this.cancelAddingTechnology()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingTechnology: true })
                                                                                                }
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target='icon-add-technology'>
                                                                                            {t("experience.hover.addTechnology")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>

                                                                                    <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            if (this.state.isSelectingTechnology) {
                                                                                                this.cancelAddingTechnology()
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ isSelectingTechnology: true })
                                                                                            }
                                                                                        }}
                                                                                    >{t('experience.Technology')}</Label>
                                                                                </div>
                                                                            }

                                                                            {(this.state.isSelectingTechnology || technologies?.length > 0) &&
                                                                                <div className='width-percent-100'>
                                                                                    {this.state.isSelectingTechnology &&
                                                                                        <div className='input-container'>
                                                                                            <Select className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                name="technologies" id="technologies"
                                                                                                options={remainingTechnologies}
                                                                                                placeholder={t('placeholder.Select')}
                                                                                                value={(this.state.selectedTechNology && remainingTechnologies.length > 0) ? remainingTechnologies.find((element) => { return element.value == this.state.selectedTechNology.value }) : null}
                                                                                                onChange={(value) => { this.setState({ selectedTechNology: value }) }} />

                                                                                            <Input
                                                                                                className="react-select mt-3"
                                                                                                classNamePrefix="react-select"
                                                                                                type="text"
                                                                                                name="goalDescription"
                                                                                                id="goalDescription"
                                                                                                autoComplete="off"
                                                                                                placeholder={t('nexions.description')}
                                                                                                value={this.state.technologyDescription}
                                                                                                onChange={(e) => { this.setState({ technologyDescription: e.target.value }) }}></Input>

                                                                                            <ul className="list-inline wizard mt-3">
                                                                                                <li className="previous list-inline-item">
                                                                                                    <Button className='mr-2' onClick={() => { this.cancelAddingTechnology() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                    <Button onClick={() => { this.finishAddingTechnology() }} color="primary" disabled={this.state.selectedTechNology == null}>{t('general.Add')}</Button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </div>
                                                                </Row>

                                                                <Row className='mt-2 mb-0'>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.Result')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-4 border-1 rounded-3'>
                                                                    <div className='align-vertical width-percent-100'>
                                                                        {this.state.selectedResults?.length > 0 && this.state.selectedResults.map((element, index) => {
                                                                            let result = results?.find((e) => { return e.value == element.buildGroupId });

                                                                            return <div key={index}>
                                                                                <div className='align-vertical'>
                                                                                    <div className='align-horizontal ml-3'>
                                                                                        <Label>{result?.label ?? ''}</Label>
                                                                                        <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                            var selectedResults = this.state.selectedResults;
                                                                                            selectedResults.splice(index, 1);
                                                                                            this.setState({ selectedResults: selectedResults });
                                                                                        }}>
                                                                                            <Trash className="icon-dual ml-2 icon-size-20" id="remove-result" />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='remove-result'>
                                                                                                {t("experience.hover.removeResult")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                                </div>

                                                                                {(index == this.state.selectedResults.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                            </div>
                                                                        })}

                                                                        <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                            {!this.state.isSelectingResult &&
                                                                                <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                    <div className='align-horizontal'>
                                                                                        <PlusSquare className="icon-dual icon-size-20" id="icon-add-result"
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingResult) {
                                                                                                    this.cancelAddingResult()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingResult: true })
                                                                                                }
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target='icon-add-result'>
                                                                                            {t("experience.hover.addResult")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>

                                                                                    <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            if (this.state.isSelectingResult) {
                                                                                                this.cancelAddingResult()
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ isSelectingResult: true })
                                                                                            }
                                                                                        }}
                                                                                    >{t('experience.Result')}</Label>
                                                                                </div>
                                                                            }

                                                                            {(this.state.isSelectingResult || results?.length > 0) &&
                                                                                <div className='width-percent-100'>
                                                                                    {this.state.isSelectingResult &&
                                                                                        <div className='input-container'>
                                                                                            <Select className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                name="technologies" id="technologies"
                                                                                                options={remainingResults}
                                                                                                placeholder={t('placeholder.Select')}
                                                                                                value={(this.state.selectedResult && remainingResults.length > 0) ? remainingResults.find((element) => { return element.value == this.state.selectedResult.value }) : null}
                                                                                                onChange={(value) => { this.setState({ selectedResult: value }) }} />

                                                                                            <Input
                                                                                                className="react-select mt-3"
                                                                                                classNamePrefix="react-select"
                                                                                                type="text"
                                                                                                name="goalDescription"
                                                                                                id="goalDescription"
                                                                                                autoComplete="off"
                                                                                                placeholder={t('nexions.description')}
                                                                                                value={this.state.resultDescription}
                                                                                                onChange={(e) => { this.setState({ resultDescription: e.target.value }) }}></Input>

                                                                                            <ul className="list-inline wizard mt-3">
                                                                                                <li className="previous list-inline-item">
                                                                                                    <Button className='mr-2' onClick={() => { this.cancelAddingResult() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                    <Button onClick={() => { this.finishAddingResult() }} color="primary" disabled={this.state.selectedResult == null}>{t('general.Add')}</Button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </div>
                                                                </Row>

                                                                <Row className='mt-2 mb-0'>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('experience.DeployToDepartment')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-4 border-1 rounded-3'>
                                                                    <div className='align-vertical width-percent-100'>
                                                                        {this.state.selectedDepartments?.length > 0 && this.state.selectedDepartments.map((element, index) => {
                                                                            let department = departments?.find((e) => { return e.id == element.departmentGroupId });

                                                                            return <div key={index}>
                                                                                <div className='align-vertical'>
                                                                                    <div className='align-horizontal ml-3'>
                                                                                        <Label>{department?.label ?? ''}</Label>
                                                                                        <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                            var selectedDepartments = this.state.selectedDepartments;
                                                                                            selectedDepartments.splice(index, 1);
                                                                                            this.setState({ selectedDepartments: selectedDepartments });
                                                                                        }}>
                                                                                            <Trash className="icon-dual ml-2 icon-size-20" id="remove-department" />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='remove-department'>
                                                                                                {t("experience.hover.removeDepartment")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                                </div>

                                                                                {(index == this.state.selectedDepartments.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                            </div>
                                                                        })}

                                                                        <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                            {!this.state.isSelectingDepartment &&
                                                                                <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                    <div className='align-horizontal'>
                                                                                        <PlusSquare className="icon-dual icon-size-20" id="icon-add-department"
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingDepartment) {
                                                                                                    this.cancelAddingDepartment()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingDepartment: true })
                                                                                                }
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target='icon-add-department'>
                                                                                            {t("experience.hover.addDepartment")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>

                                                                                    <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            if (this.state.isSelectingDepartment) {
                                                                                                this.cancelAddingDepartment()
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ isSelectingDepartment: true })
                                                                                            }
                                                                                        }}
                                                                                    >{t('career.department')}</Label>
                                                                                </div>
                                                                            }

                                                                            {(this.state.isSelectingDepartment || allDepartments?.length > 0) &&
                                                                                <div className='width-percent-100'>
                                                                                    {this.state.isSelectingDepartment &&
                                                                                        <div className='input-container'>
                                                                                            <Select className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                name="technologies" id="technologies"
                                                                                                options={remainingDepartments}
                                                                                                placeholder={t('placeholder.Select')}
                                                                                                value={this.state.deployedDepartment}
                                                                                                onChange={(value) => { this.setState({ deployedDepartment: value }) }} />

                                                                                            <Input
                                                                                                className="react-select mt-3"
                                                                                                classNamePrefix="react-select"
                                                                                                type="text"
                                                                                                name="goalDescription"
                                                                                                id="goalDescription"
                                                                                                autoComplete="off"
                                                                                                placeholder={t('nexions.description')}
                                                                                                value={this.state.departmentDescription}
                                                                                                onChange={(e) => { this.setState({ departmentDescription: e.target.value }) }}></Input>

                                                                                            <ul className="list-inline wizard mt-3">
                                                                                                <li className="previous list-inline-item">
                                                                                                    <Button className='mr-2' onClick={() => { this.cancelAddingDepartment() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                    <Button onClick={() => { this.finishAddingDepartment() }} color="primary" disabled={this.state.deployedDepartment == null}>{t('general.Add')}</Button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        }

                                                        {this.state.currentStep == ADD_EXPERIENCE_PERSONAL &&
                                                            <Col xl={12}>
                                                                {/* <Label className="font-bold font-size-17">{t('general.contributions')}</Label>
                                                                <br />
                                                                <br /> */}

                                                                <Row>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{this.state.isSelectingContribution ? format(t(this.state.editingContributionIndex != null ? t('general.modify0') : t('general.add0')), t('general.contribution').toLowerCase()) : t('general.contributions')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                {this.state.isSelectingContribution == true ?
                                                                    <div>
                                                                        {this.editContributionModule()}
                                                                    </div>
                                                                    :
                                                                    <Row className='mb-4 border-1 rounded-3'>
                                                                        <div className='align-vertical width-percent-100'>
                                                                            {this.state.contributions?.length > 0 && this.state.contributions.map((element, index) => {
                                                                                let person = networkPeople?.find((e) => { return e.value == element.contributorId });
                                                                                let userRight = rights?.find((e) => { return e.value == element.userRight });

                                                                                let roleStrings = [];
                                                                                element.contributions.forEach((c, i) => {
                                                                                    let contr = contributions.find((conObj) => { return conObj.value == c.id });
                                                                                    if (contr != null) {
                                                                                        let contributions_roles = contr?.entity?.roles?.map((role) => { return { value: role.id, label: getLocalizedString(role.name), entity: role } }) ?? [];
                                                                                        (c.roles ?? []).forEach((r) => {
                                                                                            let role = contributions_roles.find((rObj) => { return rObj.value == r.id });
                                                                                            let contributionsString = role?.label ?? '';
                                                                                            if (r.list?.length > 0) {
                                                                                                let optionalList = [];
                                                                                                if (c.id == 'CONTRIBUTOR') {
                                                                                                    optionalList = role.value == 1 ? technologies : results;
                                                                                                }
                                                                                                else {
                                                                                                    optionalList = role.value == 1 ? technologies : networkPeople;
                                                                                                }

                                                                                                let listString = '';
                                                                                                r.list.forEach((item) => {
                                                                                                    let object = optionalList.find((obj) => {
                                                                                                        return obj.value == item.id
                                                                                                    });

                                                                                                    if (object?.label?.length > 0) {
                                                                                                        listString += (listString.length == 0 ? '' : ', ') + object.label;
                                                                                                    }
                                                                                                });

                                                                                                if (listString.length > 0) {
                                                                                                    contributionsString += ' (' + listString + ')';
                                                                                                }
                                                                                            }
                                                                                            if (contributionsString.length > 0) {
                                                                                                roleStrings.push(contributionsString);
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                });


                                                                                let editable = true;
                                                                                if (this.props.editingExperience?.userId != null) {
                                                                                    if (this.props.editingExperience.userId == getLoggedInUser().id) {
                                                                                        editable = true;
                                                                                    }
                                                                                    else {
                                                                                        if (showPreviousButton) {
                                                                                            editable = true;
                                                                                        }
                                                                                        else {
                                                                                            editable = element.contributorId == getLoggedInUserProfile().user.nodeId && (element.userRight ?? 3) < 3;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                const personDetails = person?.entity;
                                                                                const updated = personDetails?.dateUpdate != null;
                                                                                const requested = personDetails?.username?.length > 0;
                                                                                const registrationStatus = requested ? (updated ? REGISTRATION_STATUS_REGISTERED : REGISTRATION_STATUS_IN_PROCESS) : REGISTRATION_STATUS_NONE;

                                                                                return <div key={index}>
                                                                                    <div className='align-vertical'>
                                                                                        <div className='align-horizontal align-items-flex-start ml-3 mr-4'>
                                                                                            <div className='mt-3' style={{ 'transform': 'translateY(-1px)' }} >
                                                                                                {this.userType('userType' + index, registrationStatus, personDetails?.invitations?.requests?.length > 0)}
                                                                                            </div>
                                                                                            {/* <User className="size-px-20 mr-2 mt-3" /> */}
                                                                                            <div className='align-vertical ml-2'>
                                                                                                <div className='align-horizontal mt-1'>
                                                                                                    <Label>{person?.label ?? ''}</Label>
                                                                                                    <Label className='ml-2 mr-2'>{'|'}</Label>
                                                                                                    <Label>
                                                                                                        {userRight?.value == 1 && <Star className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />}
                                                                                                        {userRight?.value == 2 && <Edit2 className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />}
                                                                                                        {userRight?.value == 3 && <Eye className="size-px-15 mr-2" style={{ 'transform': 'translateY(-2px)' }} />}
                                                                                                        {userRight?.label ?? ''}
                                                                                                    </Label>
                                                                                                    {editable &&
                                                                                                        <div className='box-center ml-4'>
                                                                                                            <div className='width-px-40 box-center' onClick={() => {
                                                                                                                this.editContribution(element, index)
                                                                                                            }}>
                                                                                                                <Edit className="icon-dual ml-2 icon-size-20" id="edit-contribution" />
                                                                                                                <UncontrolledTooltip
                                                                                                                    placement="top"
                                                                                                                    id='tooltip-1'
                                                                                                                    target='edit-contribution'>
                                                                                                                    {t("experience.hover.editContribution")}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </div>

                                                                                                            {(this.props.editingExperience == null || this.props.editingExperience.userId == getLoggedInUser().id || userRight?.value != 2) &&
                                                                                                                <div className='width-px-40 box-center ml-1' onClick={() => {
                                                                                                                    var contributions = this.state.contributions;
                                                                                                                    contributions.splice(index, 1);
                                                                                                                    this.setState({ contributions: contributions });
                                                                                                                    // this.cancelAddingContribution();
                                                                                                                }}>
                                                                                                                    <Trash className="icon-dual ml-2 icon-size-20" id="remove-contribution" />
                                                                                                                    <UncontrolledTooltip
                                                                                                                        placement="top"
                                                                                                                        id='tooltip-1'
                                                                                                                        target='remove-contribution'>
                                                                                                                        {t("experience.hover.removeContribution")}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                {roleStrings.map((string) => {
                                                                                                    return <Label key={string} className='text-color-subtitle'>{string}</Label>
                                                                                                })}

                                                                                            </div>

                                                                                        </div>
                                                                                        <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                                    </div>

                                                                                    {((index == this.state.contributions.length - 1) && (showPreviousButton || this.state.isSelectingContribution)) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                </div>
                                                                            })}

                                                                            <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                {!this.state.isSelectingContribution && showPreviousButton &&
                                                                                    <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                        <div className='align-horizontal'>
                                                                                            <PlusSquare className="icon-dual icon-size-20" id="icon-add-contribution"
                                                                                                onClick={() => {
                                                                                                    if (this.state.isSelectingContribution) {
                                                                                                        this.cancelAddingContribution()
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({ isSelectingContribution: true })
                                                                                                    }
                                                                                                }} />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='icon-add-contribution'>
                                                                                                {t("experience.hover.addContribution")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>

                                                                                        <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingContribution) {
                                                                                                    this.cancelAddingContribution()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingContribution: true })
                                                                                                }
                                                                                            }}
                                                                                        >{t('general.contribution')}</Label>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </div>
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        }
                                                    </Row>

                                                    {!this.state.isSelectingContribution &&
                                                        <div>
                                                            <br />
                                                            <Row>
                                                                <Col md={1} xl={1}></Col>
                                                                <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                                    <div>
                                                                        <Button color="white" onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.props.onCancel(null);
                                                                        }}><i className='uil'></i>{t((this.state.readOnly ?? false) ? 'modal.close' : "codeInput.cancel")}</Button>

                                                                        {!isEditingContributors && showPreviousButton &&
                                                                            <Button className='ml-2' color="primary" disabled={isSelectingItems} onClick={(e) => {
                                                                                e.currentTarget.blur();
                                                                                this.onBack();
                                                                            }}><i className='uil'></i>{t('codeInput.previous')}</Button>
                                                                        }

                                                                        {this.state.currentStep != ADD_EXPERIENCE_PERSONAL &&
                                                                            <Button className='ml-2' color="primary" disabled={isSelectingItems || disableNext} onClick={(e) => {
                                                                                e.currentTarget.blur();
                                                                                this.onNext();
                                                                            }}><i className='uil'></i>{t('sub.Next')}</Button>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <Button disabled={disableSaveButton || didntChangeAnything} onClick={() => { this.saveExperience() }} color="primary">{t("personalInfo.save")}</Button>
                                                                        {this.state.currentStep == ADD_EXPERIENCE_PERSONAL && (this.props.editingExperience == null || !(this.props.editingExperience?.isDone ?? true)) &&
                                                                            <Button className='ml-2' onClick={() => { this.saveExperience(true) }} color="success" disabled={isSelectingItems || this.state.contributions.length == 0 || didntChangeAnything}>{t("updateCV.validate")}</Button>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, profile, relationAction, loading, error } = state.Profile;
    const { allCompanies, companies, companyProfiles, companyPositionTypes, companySpecialties } = state.Company;
    const { allNetworkCompanies, allNetworkAcademics, allNetworkPeople } = state.Network;

    return { subscriptionProfile, profile, relationAction, allCompanies, companies, companyProfiles, companyPositionTypes, companySpecialties, allNetworkCompanies, allNetworkAcademics, allNetworkPeople, loading, error };
};

const mapDispatchToProps = {
    changePassword,
    createExperience,
    editExperience,
    getRelationAction,
    getCompanies,
    getAllNetworkCompanies,
    getAllContentsCompany,
    getCompanySpecialties,
    getCompanyProfiles,
    getCompanyPositionTypes,
    getGoalsOfCompany,
    getGoalsOfAcademic,
    getPersonalSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddExperienceModal));
