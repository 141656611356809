/* AUTH */
export const SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY = 1;
export const SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY = 2;
export const SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY_ORTHER = 22;
export const SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE = 3;

export const SCREEN_GRADUATE_DEGREE_SKIP = 0;
export const SCREEN_GRADUATE_DEGREE_NEXT = 1;
export const SCREEN_GRADUATE_DEGREE_VALIDATE = 2;
export const SCREEN_GRADUATE_DEGREE_ERASE = 3;
export const SCREEN_GRADUATE_DEGREE_SAVE_F = 4;
export const SCREEN_GRADUATE_DEGREE_SAVE_GD = 5;