/* AUTH */
export const SAVE_NEXT_PATH = 'SAVE_NEXT_PATH';
export const SAVE_NEXT_PATH_SUCCESS = 'SAVE_NEXT_PATH_SUCCESS';
export const SAVE_NEXT_PATH_FAILED = 'SAVE_NEXT_PATH_FAILED';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const GET_COUNTRY_INFO = 'GET_COUNTRY_INFO';
export const GET_COUNTRY_INFO_SUCCESS = 'GET_COUNTRY_INFO_SUCCESS';
export const GET_COUNTRY_INFO_FAILED = 'GET_COUNTRY_INFO_FAILED';
export const GET_API_COUNTRY_INFO = 'GET_API_COUNTRY_INFO';
export const GET_API_COUNTRY_INFO_SUCCESS = 'GET_API_COUNTRY_INFO_SUCCESS';
export const GET_API_COUNTRY_INFO_FAILED = 'GET_API_COUNTRY_INFO_FAILED';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED';
export const CHECK_PHONENUMBERINUSED = 'CHECK_PHONENUMBERINUSED';
export const CHECK_PHONENUMBERINUSED_SUCCESS = 'CHECK_PHONENUMBERINUSED_SUCCESS';
export const CHECK_PHONENUMBERINUSED_FAILED = 'CHECK_PHONENUMBERINUSED_FAILED';
export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const REQUEST_SIGNUP_SUCCESS = 'REQUEST_SIGNUP_SUCCESS';
export const REQUEST_SIGNUP_FAILED = 'REQUEST_SIGNUP_FAILED';
