// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { dateStringFromTimeInterval, durationBetweenDates, formatDateString, getLocalizedString, capitalize } from '../../../../helpers/utils';
import { getAPICountryInfo, checkExistUsers, createContact, createInvite } from '../../../../redux/actions';
import { isDarkMode } from '../../../../helpers/authUtils';
import PhoneInput from 'react-phone-input-2';
import { Mail, PlusSquare, User } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import SimpleCheckbox from '../../Supports/SimpleCheckbox';
import SVG from 'react-inlinesvg';
import companyIcon from '../../../../assets/images/menu/companies.svg';
import moment from 'moment';
import { EMAIL_TYPE_COMPANY, EMAIL_TYPE_PERSONAL } from './ContactModal';
import { INTRODUCE_EMAIL, INTRODUCE_PHONE } from '../../../auth/Register';

export const INVITATION_INVITE = 0;
export const INVITATION_STATUS = 1;
export const INVITATION_REMIND = 2;

class InvitationModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            selectedCountry: null,
            size: 'xx',
            firstName: '',
            lastName: '',
            emails: [],
            selectedEmail: null,
            phones: [],
            selectedMobile: null,
            signupStart: null,
            signupEnd: null,
            actionType: null,
            invitations: [],
            invitationSource: null
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({
                callbackOnSuccess: (response) => {
                    this.filterCountry();
                },
            });
        }
        else {
            this.filterCountry();
        }

        if (this.props.contact) {
            let t = this.props.t;

            let emails = [];
            if (this.props.contact?.emails?.length > 0) {
                let emailTypes = [{ value: EMAIL_TYPE_PERSONAL, label: t('email.personal') }, { value: EMAIL_TYPE_COMPANY, label: t('email.professional') }];
                this.props.contact.emails.forEach((element, index) => {
                    emails.push({ id: index, value: element.email, label: element.email, type: emailTypes.find((t) => { return t.value == element.type }) });
                });
            }
            else if (this.props.contact?.email) {
                emails = [
                    { id: 0, value: this.props.contact?.email, label: this.props.contact?.email, type: { value: EMAIL_TYPE_PERSONAL, label: this.props.t('email.personal') } }
                ];
            }
            let phones = [];
            if (this.props.contact?.mobiles?.length > 0) {
                this.props.contact.mobiles.forEach(element => {
                    phones.push({ value: element, label: element });
                });
            }
            else if (this.props.contact?.mobileNumber) {
                phones = [{ value: this.props.contact?.mobileNumber, label: this.props.contact?.mobileNumber }];
            }

            let signupStart = this.props.contact?.dateStartSignUp ?? '';
            this.setState({
                firstName: this.props.contact?.firstName ?? '',
                lastName: this.props.contact?.lastName ?? '',
                emails: emails,
                phones: phones,
                actionType: (this.props.contact.invitations?.requests?.length > 0 && signupStart.length == 0) ? INVITATION_STATUS : INVITATION_INVITE,
                signupStart: signupStart,
                signupEnd: this.props.contact?.dateEndSignUp ?? '',
                invitations: this.props.contact?.invitations?.requests ?? []
            });
        }
    }

    filterCountry = () => {
        if (this.props.contact?.country) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let selectedCountry = countries?.find((country) => { return country.value == this.props.contact.country });
            this.setState({ selectedCountry: selectedCountry });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    selectInvitationSource = (value) => {
        let state = {};
        state.invitationSource = value;
        if (value != this.state.invitationSource) {
            state.selectedEmail = null;
            state.selectedMobile = null;
        }
        this.setState(state);
    }

    onInvite = () => {
        let id = this.props.userId;
        if (id?.length > 0) {
            this.sendInvitationRequest(id);
        }
        else {
            this.checkExistUser();
        }
    }

    checkExistUser = () => {
        let params = {};
        params.firstName = '';
        params.lastName = '';
        params.country = '';

        if (this.props.contact?.email?.length > 0) {
            params.emails = [{ email: this.props.contact?.email, type: 0 }];
        }
        if (this.props.contact?.phone?.length > 0) {
            params.mobiles = [this.props.contact?.phone];
        }

        this.props.checkExistUsers(params, {
            callbackOnBegin: () => {
                this.setState({ loading: true, error: null });
            },
            callbackOnFailure: (error) => {
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                if (response.internalUsers == null || response.internalUsers.length == 0) {
                    params.signupRequestId = this.props.contact?.id;
                    this.createUser(params);
                }
            },
        });
    }

    createUser = (params) => {
        this.props.createContact(params, {
            callbackOnBegin: () => {
                this.setState({ loading: true, error: null });
            },
            callbackOnFailure: (error) => {
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                response.signupRequestId = params.signupRequestId;
                this.sendInvitationRequest(response.id);
            },
        });
    }

    sendInvitationRequest = (userId) => {
        let params = {};
        params.email = this.state.selectedEmail?.value;
        params.mobile = this.state.selectedMobile?.value;
        params.type = 0;
        if (this.state.comment.length > 0) {
            params.comment = this.state.comment;
        }
        params.signupRequestId = this.props.contact?.id;

        this.props.createInvite(params, userId, {
            callbackOnBegin: () => {
                this.setState({ loading: true, error: null });
            },
            callbackOnFailure: (error) => {
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                this.props.close(response);
            },
        });
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const sendable = this.state.actionType == INVITATION_STATUS ? true : (this.state.selectedEmail != null || this.state.selectedMobile != null);

        let headerTitle = t('network.invite0');
        let submitTitle = t('reset.send');
        switch (this.state.actionType) {
            case INVITATION_INVITE:
                headerTitle = t('network.invite');
                break;
            case INVITATION_STATUS:
                headerTitle = t('network.invitationStatus');
                submitTitle = t('general.remind');
                break;
            case INVITATION_REMIND:
                headerTitle = t('network.remindInvitation');
                break;
            default: break;
        }

        let user = this.state.firstName ?? '';
        if (this.state.lastName) {
            user += (user.length > 0 ? ' ' : '') + this.state.lastName;
        }
        if (this.state.selectedCountry?.label) {
            user += (user.length > 0 ? ', ' : '') + this.state.selectedCountry?.label;
        }

        let invitations = this.state.invitations;

        let signupStart = this.state.signupStart ? formatDateString(this.state.signupStart, 'MMM. DD, YYYY') : '';
        let signupEnd = this.state.signupEnd ? formatDateString(this.state.signupEnd, 'MMM. DD, YYYY') : '';

        const invitationSources = [
            { value: INTRODUCE_EMAIL, label: t('signUp.email') },
            { value: INTRODUCE_PHONE, label: t('personalInfo.mobilephone') },
        ];
        const hasBoth = this.state.emails?.length > 0 && this.state.phones?.length > 0;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{this.state.actionType ? format(headerTitle, user) : t('network.invite')}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.actionType == INVITATION_INVITE &&
                                            <AvForm className="authentication-form">
                                                {this.state.loading && <Loader />}
                                                <Row>
                                                    <Col xl={12}>
                                                        {hasBoth &&
                                                            <Row>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for="country">{t("general.inviteBy")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        name="country" id="country"
                                                                        placeholder={t('placeholder.Select')}
                                                                        options={invitationSources}
                                                                        value={this.state.invitationSource}
                                                                        onChange={(value) => {
                                                                            this.selectInvitationSource(value);
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {(hasBoth ? this.state.invitationSource?.value == INTRODUCE_EMAIL : true) && this.state.emails?.length > 0 &&
                                                            <Row className='mt-3'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for="country">{t("signUp.email")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        name="country" id="country"
                                                                        placeholder={t('placeholder.Select')}
                                                                        options={this.state.emails}
                                                                        value={this.state.selectedEmail ? this.state.emails?.filter((email) => { return email.id == this.state.selectedEmail.id }) : null}
                                                                        onChange={(value) => {
                                                                            this.setState({ selectedEmail: value });
                                                                        }}
                                                                        getOptionLabel={e => (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {e.type.value == EMAIL_TYPE_PERSONAL ?
                                                                                    <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                                    <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                                                }
                                                                                <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                                            </div>
                                                                        )}

                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }

                                                        {(hasBoth ? this.state.invitationSource?.value == INTRODUCE_PHONE : true) && this.state.phones?.length > 0 &&
                                                            <Row className='mt-3'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{t("reset.mobile")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        placeholder={t('placeholder.Select')}
                                                                        options={this.state.phones}
                                                                        value={this.state.selectedMobile ? this.state.phones?.filter((mobile) => { return mobile.value == this.state.selectedMobile.value }) : null}
                                                                        onChange={(value) => {
                                                                            this.setState({ selectedMobile: value });
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Col>


                                                    <Col xl={12} className='mt-3'>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for="country">{t("general.comment")}</Label>
                                                            </Col>
                                                        </Row>
                                                        <div className="firebaseui-relative-wrapper mt-2">
                                                            <textarea className="firebaseui-input border-1 rounded-5 height-min-60 height-max-300 bg-color-transparent text-color-input padding-5"
                                                                onChange={(e) => {
                                                                    this.setState({ comment: e.target.value })
                                                                }}
                                                            />
                                                            <div className="firebaseui-error-wrapper">
                                                                <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <br />

                                                <Row>
                                                    <Col md={1} xl={1}></Col>
                                                    <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div>
                                                            <Button color="white" onClick={() => {
                                                                this.props.close(null);
                                                            }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                            <Button className='ml-2' color="primary" disabled={!sendable} onClick={() => {
                                                                this.onInvite();
                                                            }}><i className='uil'></i>{t('reset.send')}</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        }
                                        {this.state.actionType == INVITATION_STATUS &&
                                            <AvForm className="authentication-form">
                                                {this.state.loading && <Loader />}
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for="country">{t("network.invitationHistory")} :</Label>
                                                            </Col>
                                                        </Row>
                                                        {invitations.length > 0 && invitations.map((invitation) => {
                                                            let invitationDate = invitation.date ? (moment(invitation.date)?.format('MMM. DD, YYYY hh:mm A') ?? '') : '';
                                                            let invite = invitationDate;
                                                            if (invitation.mobile?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.mobile;
                                                            }
                                                            if (invitation.email?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.email;
                                                            }
                                                            if (invitation.comment?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.comment;
                                                            }
                                                            if (invitation.mailActions?.length > 0) {
                                                                for (let i = 0; i < invitation.mailActions?.length; i++) {
                                                                    invite += (invite.length > 0 ? ' | ' : '') + capitalize(invitation.mailActions[i].event);
                                                                }
                                                            }

                                                            let color = '';
                                                            if (invitation.date) {
                                                                let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
                                                                let weeks = durationBetweenDates(invite, "MMM. DD, YYYY", now, "DD/MM/YYYY", ['weeks']);
                                                                if (weeks < 2) {
                                                                    color = 'green';
                                                                }
                                                                else if (weeks >= 2 && weeks < 4) {
                                                                    color = 'orange';
                                                                }
                                                                else {
                                                                    color = 'red';
                                                                }
                                                            }

                                                            return (
                                                                <Row>
                                                                    <Col className='font-size-15 text-left ml-2 align-horizontal'>
                                                                        <Mail className={"size-px-20 mr-2 align-self-flex-start"} color={color.length > 0 ? color : 'gray'} />
                                                                        <Label className='mt-0'>{invite}</Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }, this)}
                                                    </Col>

                                                    {signupStart?.length > 0 &&
                                                        <Col xl={12} className='mt-2'>
                                                            <Row>
                                                                <Col md={4} xl={4} className='font-size-15 text-left'>
                                                                    <Label for="country">{t("Sign-up start")} :</Label>
                                                                </Col>
                                                                <Col className='font-size-15 text-left'>
                                                                    <Label for="country">{signupStart}</Label>
                                                                </Col>
                                                            </Row>
                                                            {signupEnd?.length > 0 &&
                                                                <Row>
                                                                    <Col md={4} xl={4} className='font-size-15 text-left'>
                                                                        <Label for="country">{t("Sign-up end")} :</Label>
                                                                    </Col>
                                                                    <Col className='font-size-15 text-left'>
                                                                        <Label for="country">{signupEnd}</Label>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Col>
                                                    }
                                                </Row>

                                                <br />

                                                <Row>
                                                    <Col md={1} xl={1}></Col>
                                                    <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div>
                                                            <Button color="white" onClick={() => {
                                                                this.props.close(null);
                                                            }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                            {signupStart?.length == 0 &&
                                                                <Button className='ml-2' color="primary" disabled={!sendable} onClick={() => {
                                                                    if (this.state.actionType == INVITATION_STATUS) {
                                                                        this.setState({ actionType: INVITATION_REMIND });
                                                                    }
                                                                    else {
                                                                        this.onInvite();
                                                                    }
                                                                }}><i className='uil'></i>{submitTitle}</Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        }
                                        {this.state.actionType == INVITATION_REMIND &&
                                            <AvForm className="authentication-form">
                                                {this.state.loading && <Loader />}
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for="country">{t("network.previousInvitations")} :</Label>
                                                            </Col>
                                                        </Row>
                                                        {invitations.length > 0 && invitations.map((invitation) => {
                                                            let invitationDate = invitation.date ? formatDateString(invitation.date, 'MMM. DD, YYYY') : '';
                                                            let invite = invitationDate;
                                                            if (invitation.mobile?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.mobile;
                                                            }
                                                            if (invitation.email?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.email;
                                                            }
                                                            if (invitation.comment?.length > 0) {
                                                                invite += (invite.length > 0 ? ' | ' : '') + invitation.comment;
                                                            }

                                                            let color = '';
                                                            if (invitation.date) {
                                                                let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
                                                                let weeks = durationBetweenDates(invite, "MMM. DD, YYYY", now, "DD/MM/YYYY", ['weeks']);
                                                                if (weeks < 2) {
                                                                    color = 'green';
                                                                }
                                                                else if (weeks >= 2 && weeks < 4) {
                                                                    color = 'orange';
                                                                }
                                                                else {
                                                                    color = 'red';
                                                                }
                                                            }

                                                            return (
                                                                <Row>
                                                                    <Col className='font-size-15 text-left ml-2'>
                                                                        <Mail className={"size-px-20 mr-2"} color={color.length > 0 ? color : 'gray'} />
                                                                        <Label for="country">{invite}</Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }, this)}
                                                    </Col>

                                                    {this.state.emails?.length > 0 &&
                                                        <Col xl={12} className='mt-4'>
                                                            {hasBoth &&
                                                                <Row>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15' for="country">{t("general.inviteBy")}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="country" id="country"
                                                                            placeholder={t('placeholder.Select')}
                                                                            options={invitationSources}
                                                                            value={this.state.invitationSource}
                                                                            onChange={(value) => {
                                                                                this.selectInvitationSource(value);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {(hasBoth ? this.state.invitationSource?.value == INTRODUCE_EMAIL : true) && this.state.emails?.length > 0 &&
                                                                <Row className='mt-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15' for="country">{t("signUp.email")}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            name="country" id="country"
                                                                            placeholder={t('placeholder.Select')}
                                                                            options={this.state.emails}
                                                                            value={this.state.selectedEmail ? this.state.emails?.filter((email) => { return email.id == this.state.selectedEmail.id }) : null}
                                                                            onChange={(value) => {
                                                                                this.setState({ selectedEmail: value });
                                                                            }}
                                                                            getOptionLabel={e => (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {e.type.value == EMAIL_TYPE_PERSONAL ?
                                                                                        <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                                        <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                                                    }
                                                                                    <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                                                </div>
                                                                            )}

                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {(hasBoth ? this.state.invitationSource?.value == INTRODUCE_PHONE : true) && this.state.phones?.length > 0 &&
                                                                <Row className='mt-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t("reset.mobile")}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            placeholder={t('placeholder.Select')}
                                                                            options={this.state.phones}
                                                                            value={this.state.selectedMobile ? this.state.phones?.filter((mobile) => { return mobile.value == this.state.selectedMobile.value }) : null}
                                                                            onChange={(value) => {
                                                                                this.setState({ selectedMobile: value });
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Col>
                                                    }

                                                    <Col xl={12} className='mt-3'>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for="country">{t("general.comment")}</Label>
                                                            </Col>
                                                        </Row>
                                                        <div className="firebaseui-relative-wrapper mt-2">
                                                            <textarea className="firebaseui-input border-1 rounded-5 height-min-60 height-max-300 bg-color-transparent text-color-input padding-5"
                                                                onChange={(e) => {
                                                                    this.setState({ comment: e.target.value })
                                                                }}
                                                            />
                                                            <div className="firebaseui-error-wrapper">
                                                                <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <br />

                                                <Row>
                                                    <Col md={1} xl={1}></Col>
                                                    <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div>
                                                            <Button color="white" onClick={() => {
                                                                this.props.close(null);
                                                            }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                            <Button className='ml-2' color="primary" disabled={!sendable} onClick={() => {
                                                                this.onInvite();
                                                            }}><i className='uil'></i>{t('reset.send')}</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { network } = state.Network;
    return { country, countries, network, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    checkExistUsers,
    createContact,
    createInvite
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(InvitationModal));