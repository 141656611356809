import classnames from 'classnames';
import React,{Component} from 'react';
import "react-bubble-ui/dist/index.css";
import {withTranslation} from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import {connect} from 'react-redux';
import {Card,CardBody,Col,Label,Nav,NavItem,NavLink,Row,TabContent,TabPane} from 'reactstrap';
import {getRepoCyclesteps} from '../../redux/actions';
import {getLocalizedString} from './../../helpers/utils';
import ToggleTabImageInform from "./ToggleTabImageInform";


class HowDatanexions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabHow: 1,
            dataStore: [],
        };
    }

    componentDidMount() {
        this.props.getRepoCyclesteps({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                this.setState({ dataStore: response })
            },
        });
    }

    toggle = (tab) => {
        if (this.state.activeTabHow !== tab) {
            this.setState({
                activeTabHow: tab,
            });
        }
    };

    render() {

        const { t, error } = this.props;
        // 
        var cycleSteps = []
        this.state.dataStore.cycleSteps && this.state.dataStore.cycleSteps.map(cy => {
            cycleSteps.push(cy)
        })
        const informData = cycleSteps[0]
        const discoverData = cycleSteps[1]
        const shareData = cycleSteps[2]
        const leverageData = cycleSteps[3]
        return (

            <React.Fragment>
                <div className='top-border-gray mb-2 ml-3 mr-3'>
                </div>
                <Row >

                    <Nav className="nav">
                        {cycleSteps.map((cy, idx) => {
                            return (
                                
                                    <Col>
                                        <NavItem key={(idx+1)}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTabHow === (idx+1) })}
                                                onClick={() => {
                                                    this.toggle((idx+1));
                                                }} >
                                                <Label className={(this.state.activeTabHow === (idx+1)) ? 'title-dark-small-label mr-5' : 'title-gray-small-label mr-5'}>
                                                    {getLocalizedString(cy.cycleCategoryTypeDoc.name)}
                                                </Label>
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                            )
                        })}
                    </Nav>
                </Row>

                <TabContent activeTab={this.state.activeTabHow}>
                    {cycleSteps.map((cy, idx) => {
                        return (
                                <TabPane tabId={(idx+1)} key={(idx+1)}>
                                    <Col xs={12}>

                                        {cy && cy.cycleCategoryTypeDoc && cy.cycleCategoryTypeDoc.elements &&
                                            <div>
                                                <Card className="intro-card-bottom mb-3 mt-1">
                                                    <CardBody>
                                                        <Label className="title-left-small-label">{getLocalizedString(cy.cycleCategoryTypeDoc.description)}</Label>
                                                    </CardBody>
                                                </Card>

                                                {cy.cycleCategoryTypeDoc.elements.map(el => (
                                                    <ToggleTabImageInform el={el} />
                                                ))
                                                }
                                            </div>
                                        }

                                    </Col>
                                </TabPane>
                        )
                    })}
                    </TabContent>

            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    const { repocyclesteps, loading, error } = state.RepoCyclesteps;
    return { repocyclesteps, loading, error };
};


export default connect(mapStateToProps, { getRepoCyclesteps })((withTranslation('translations')(HowDatanexions)));
