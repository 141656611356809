/* AUTH */
export const GET_ACADEMICS = 'GET_ACADEMICS';
export const GET_ACADEMICS_SUCCESS = 'GET_ACADEMICS_SUCCESS';
export const GET_ACADEMICS_FAILED = 'GET_ACADEMICS_FAILED';

export const GET_ACADEMIC_BY_ID = 'GET_ACADEMIC_BY_ID';
export const GET_ACADEMIC_BY_ID_SUCCESS = 'GET_ACADEMIC_BY_ID_SUCCESS';
export const GET_ACADEMIC_BY_ID_FAILED = 'GET_ACADEMIC_BY_ID_FAILED';

export const GET_ACADEMICS_IN_COUNTRY = 'GET_ACADEMICS_IN_COUNTRY';
export const GET_ACADEMICS_IN_COUNTRY_SUCCESS = 'GET_ACADEMICS_IN_COUNTRY_SUCCESS';
export const GET_ACADEMICS_IN_COUNTRY_FAILED = 'GET_ACADEMICS_IN_COUNTRY_FAILED';

export const GET_ACADEMIC_DOC = 'GET_ACADEMIC_DOC';
export const GET_ACADEMIC_DOC_SUCCESS = 'GET_ACADEMIC_DOC_SUCCESS';
export const GET_ACADEMIC_DOC_FAILED = 'GET_ACADEMIC_DOC_FAILED';

export const CREATE_ACADEMIC = 'CREATE_ACADEMIC';
export const CREATE_ACADEMIC_SUCCESS = 'CREATE_ACADEMIC_SUCCESS';
export const CREATE_ACADEMIC_FAILED = 'CREATE_ACADEMIC_FAILED';

export const DELETE_ACADEMIC = 'DELETE_ACADEMIC'
export const DELETE_ACADEMIC_SUCCESS = 'DELETE_ACADEMIC_SUCCESS'
export const DELETE_ACADEMIC_FAILED = 'DELETE_ACADEMIC_FAILED'

export const GET_PUBLIC_ACADEMY = 'GET_PUBLIC_ACADEMY';
export const GET_PUBLIC_ACADEMY_SUCCESS = 'GET_PUBLIC_ACADEMY_SUCCESS';
export const GET_PUBLIC_ACADEMY_FAILED = 'GET_PUBLIC_ACADEMY_FAILED';

export const GET_EMAILS_ACADEMICS = 'GET_EMAILS_ACADEMICS';
export const GET_EMAILS_ACADEMICS_SUCCESS = 'GET_EMAILS_ACADEMICS_SUCCESS';
export const GET_EMAILS_ACADEMICS_FAILED = 'GET_EMAILS_ACADEMICS_FAILED';

export const GET_ACADEMIC_GOALS = 'GET_ACADEMIC_GOALS';
export const GET_ACADEMIC_GOALS_SUCCESS = 'GET_ACADEMIC_GOALS_SUCCESS';
export const GET_ACADEMIC_GOALS_FAILED = 'GET_ACADEMIC_GOALS_FAILED';

export const GET_ACADEMIC_FELLOWS = 'GET_ACADEMIC_FELLOWS';
export const GET_ACADEMIC_FELLOWS_SUCCESS = 'GET_ACADEMIC_FELLOWS_SUCCESS';
export const GET_ACADEMIC_FELLOWS_FAILED = 'GET_ACADEMIC_FELLOWS_FAILED';

export const SET_ACADEMICS_ADMIN = 'SET_ACADEMICS_ADMIN';
export const SET_ACADEMICS_ADMIN_SUCCESS = 'SET_ACADEMICS_ADMIN_SUCCESS';
export const SET_ACADEMICS_ADMIN_FAILED = 'SET_ACADEMICS_ADMIN_FAILED';
