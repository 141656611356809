// @flow
import {
    CHECK_PHONENUMBERINUSED,CHECK_PHONENUMBERINUSED_FAILED,CHECK_PHONENUMBERINUSED_SUCCESS,
    FORGET_PASSWORD,FORGET_PASSWORD_FAILED,FORGET_PASSWORD_SUCCESS,GET_API_COUNTRY_INFO,
    GET_API_COUNTRY_INFO_FAILED,GET_API_COUNTRY_INFO_SUCCESS,GET_COUNTRY_INFO,GET_COUNTRY_INFO_FAILED,
    GET_COUNTRY_INFO_SUCCESS,LOGIN_USER,LOGIN_USER_FAILED,LOGIN_USER_SUCCESS,LOGOUT_USER,NEW_PASSWORD,
    NEW_PASSWORD_FAILED,NEW_PASSWORD_SUCCESS,REGISTER_USER,REGISTER_USER_FAILED,REGISTER_USER_SUCCESS,
    REQUEST_SIGNUP, REQUEST_SIGNUP_SUCCESS, REQUEST_SIGNUP_FAILED,
    SAVE_NEXT_PATH, SAVE_NEXT_PATH_SUCCESS, SAVE_NEXT_PATH_FAILED
} from './constants';

export const saveNextPath = (path, callbacks) => ({
    type: SAVE_NEXT_PATH,
    payload: { path, callbacks },
});

export const saveNextPathSuccess = (path) => ({
    type: SAVE_NEXT_PATH_SUCCESS,
    payload: path,
});

export const saveNextPathFailed = (error) => ({
    type: SAVE_NEXT_PATH_FAILED,
    payload: error,
});

export const loginUser = (info, callbacks) => ({
    type: LOGIN_USER,
    payload: { info, callbacks },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (params, callbacks) => ({
    type: REGISTER_USER,
    payload: { params, callbacks },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (data, callbacks) => ({
    type: FORGET_PASSWORD,
    payload: { data, callbacks },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: { passwordResetStatus },
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const getCountryInfo = (code) => ({
    type: GET_COUNTRY_INFO,
    payload: { code },
});

export const getCountryInfoSuccess = (country) => ({
    type: GET_COUNTRY_INFO_SUCCESS,
    payload: country,
});

export const getCountryInfoFailed = (error) => ({
    type: GET_COUNTRY_INFO_FAILED,
    payload: error,
});

export const getAPICountryInfo= (callbacks)=>({
    type: GET_API_COUNTRY_INFO,
    payload: { callbacks },
})

export const getAPICountryInfoSuccess = (country) => ({
    type: GET_API_COUNTRY_INFO_SUCCESS,
    payload: country,
});

export const getAPICountryInfoFailed = (error) => ({
    type: GET_API_COUNTRY_INFO_FAILED,
    payload: error,
});

export const setNewPassword = (data, callbacks) => ({
    type: NEW_PASSWORD,
    payload: { data, callbacks },
});

export const setNewPasswordSuccess = (country) => ({
    type: NEW_PASSWORD_SUCCESS,
    payload: country,
});

export const setNewPasswordFailed = (error) => ({
    type: NEW_PASSWORD_FAILED,
    payload: error,
});

export const checkPhoneNumberIsInUsed = (phone, callbacks) => ({
    type: CHECK_PHONENUMBERINUSED,
    payload: { phone, callbacks },
});

export const checkPhoneNumberIsInUsedSuccess = (phone) => ({
    type: CHECK_PHONENUMBERINUSED_SUCCESS,
    payload: phone,
});

export const checkPhoneNumberIsInUsedFailed = (error) => ({
    type: CHECK_PHONENUMBERINUSED_FAILED,
    payload: error,
});

export const requestSignup = (email, phone, callbacks) => ({
    type: REQUEST_SIGNUP,
    payload: { email, phone, callbacks },
});

export const requestSignupSuccess = (user) => ({
    type: REQUEST_SIGNUP_SUCCESS,
    payload: user,
});

export const requestSignupFailed = (error) => ({
    type: REQUEST_SIGNUP_FAILED,
    payload: error,
});
