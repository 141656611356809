// @flow
import { json } from 'd3';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getAdminUser, setImpersonateUser, getLoggedInUser, setLoggedInUser, setLoggedInUserProfile } from '../../helpers/authUtils';
import { BASE_URL } from './../constants';
import {
    changeEmailFailed, changeEmailSuccess, changePasswordFailed,
    changePasswordSuccess, checkEmailVerificationStatusFailed,
    checkEmailVerificationStatusSuccess, createSecurityFailed,
    createSecuritySuccess, deleteCVSFailed, deleteCVSSuccess,
    deletePhotosFailed, deletePhotosSuccess, getAllCompaniesFailed,
    getAllCompaniesSuccess, getCVSFailed, getCVSSuccess, getPhotosFailed,
    getPhotosSuccess, getProfileFailed, getSubscriptionProfileFailed,
    getSubscriptionProfileSuccess, getProfileSuccess, getVisualSummaryFailed,
    getVisualSummarySuccess, resendVerificationEmailFailed,
    resendVerificationEmailSuccess, searchEmployeeFailed,
    searchEmployeeSuccess, updatePhoneNumberFailed, updatePhoneNumberSuccess,
    updateProfileFailed, updateProfileSuccess, updateSecurityFailed,
    updateSecuritySuccess, uploadCVFailed, uploadCVSuccess,
    uploadPhotoFailed, uploadPhotoSuccess,
    getSummaryDataChartSuccess, getSummaryDataChartFailed,
    getMenuHierarchySuccess, getMenuHierarchyFailed,
    getDataExperienceSuccess, getDataExperienceFailed,
    createExperienceSuccess, createExperienceFailed,
    editExperienceSuccess, editExperienceFailed,
    deleteExperienceSuccess, deleteExperienceFailed,
    getActionGroupsSuccess, getActionGroupsFailed, getActionGroups,
    getRelationActionSuccess, getRelationActionFailed,
    getGoalsByUserSuccess, getGoalsByUserFailed,
    getActionsByUserSuccess, getActionsByUserFailed,
    getAlertsSuccess, getAlertsFailed,
    getPublicProfileSuccess, getPublicProfileFailed,
    getGoalsActionsByUserSuccess, getGoalsActionsByUserFailed,
    createAcademySecuritySuccess, createAcademySecurityFailed,
    updateAcademySecuritySuccess, updateAcademySecurityFailed,
    switchUserSuccess, switchUserFailed, getDataExperienceByIdSuccess, getDataExperienceByIdFailed, getPersonalSubscriptionSuccess, getPersonalSubscriptionFailed, getFailedPaymentsSuccess, getFailedPaymentsFailed, payFailedPaymentSuccess, payFailedPaymentFailed, synchronizePaymentSuccess, synchronizePaymentFailed,
    getDashboardSuccess, getDashboardFailed,
    addEmailSuccess, addEmailFailed, editEmailSuccess, editEmailFailed, removeEmailSuccess, removeEmailFailed,
    addPhoneSuccess, addPhoneFailed, editPhoneSuccess, editPhoneFailed, removePhoneSuccess, removePhoneFailed
} from './actions';
import {
    CHANGE_EMAIL, CHANGE_PASSWORD, CHECK_EMAILVERIFICATIONSTATUS, CREATE_SECURITY, DELETE_CVS, DELETE_PHOTOS, GET_ALL_COMPANY, GET_CVS, GET_PHOTOS, GET_PROFILE,
    GET_SUBCRIPTION_PROFILE,
    PROFILE_VISUAL_SUMMARY,
    RESEND_VERIFICATION_EMAIL, SEARCH_EMPLOYEE, UPDATE_PHONENUMBER, UPDATE_PROFILE, UPDATE_SECURITY, UPLOAD_CV, UPLOAD_PHOTO,
    PROFILE_SUMMARY_DATA_CHART, GET_MENU_HIERARCHY, GET_DATA_EXPERIENCE, CREATE_EXPERIENCE, EDIT_EXPERIENCE, DELETE_EXPERIENCE,
    GET_ACTION_GROUPS, GET_RELATION_ACTION, GET_GOALS_BY_USER, GET_ALERTS,
    GET_PUBLIC_PROFILE, GET_ACTIONS_BY_USER, GET_GOALS_ACTIONS_BY_USER,
    CREATE_ACADEMY_SECURITY, UPDATE_ACADEMY_SECURITY, SWITCH_USER, GET_DATA_EXPERIENCE_BY_ID, GET_PERSONAL_SUBCRIPTION, GET_FAILED_PAYMENT, PAY_FAILED_PAYMENT, SYNCHRONIZE_PAYMENT,
    GET_DASHBOARD,
    ADD_EMAIL, ADD_PHONE, EDIT_EMAIL, REMOVE_EMAIL, EDIT_PHONE, REMOVE_PHONE
} from './constants';

/**
 * Register the user
 */
function* getVisualSummaryInternal({ payload: { userId, callbacks } }) {
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };


    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/visualsummary/' + userId, options);
        yield put(getVisualSummarySuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getVisualSummaryFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }

}

/**
 * Register the user
 */
function* getUserProfileInternal({ payload: { callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id, options);

        setLoggedInUserProfile(response);
        yield put(getProfileSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);    
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getProfileFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getSummaryDataChart({ payload: { userId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/summary-chart-data/' + userId, options);

        yield put(getSummaryDataChartSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getSummaryDataChartFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getDataExperienceInternal({ payload: { userId, goal, actions, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();


    let params = {};
    params.userId = userId;
    params.goal = goal;
    params.actions = actions;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/data-experience/', options);

        yield put(getDataExperienceSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getDataExperienceFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getDataExperienceByIdInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/' + id, options);

        yield put(getDataExperienceByIdSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getDataExperienceByIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createExperienceInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/create-experience', options);

        yield put(createExperienceSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createExperienceFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editExperienceInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/edit-experience/' + id, options);

        yield put(editExperienceSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editExperienceFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteExperienceInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken },
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/' + id, options);

        yield put(deleteExperienceSuccess(id));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, id);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteExperienceFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getActionGroupsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/action-groups' , options);

        yield put(getActionGroupsSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getActionGroupsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPersonalSubscriptionInternal({ payload: { userId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/subscription/' + userId, options);

        yield put(getPersonalSubscriptionSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPersonalSubscriptionFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getSubscriptionProfile({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/subscription/' + user.id, options);

        yield put(getSubscriptionProfileSuccess(response));
        setLoggedInUserProfile(response);

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getSubscriptionProfileFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Register the user
 */
function* updateProfileInternal({ payload: { profile, updateDateTime, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        body: profile,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/update' + (updateDateTime ? '/true' : '/false'), options);
        setLoggedInUserProfile(response);
        yield put(updateProfileSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateProfileFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createSecurity({ payload: { security, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        body: security,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/create-security', options);
        setLoggedInUserProfile(response);
        yield put(createSecuritySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createSecurityFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* updateSecurity({ payload: { security, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        body: security,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/update-security', options);
        setLoggedInUserProfile(response);
        yield put(updateSecuritySuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateSecurityFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* uploadCVInternal({ payload: { file, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const formData = new FormData();

    formData.append("file", file);

    const options = {
        body: formData,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/cvs', options);
        yield put(uploadCVSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

        // Get CVS to update data 
        const optionsGetCVS = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
        };

        try {
            const response = yield call(fetchJSON, BASE_URL + 'profiles/cvs', optionsGetCVS);
            yield put(getCVSSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
            yield put(getCVSFailed(message));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(uploadCVFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* searchEmployeeInternal({ payload: { keyword } }) {

    const user = getLoggedInUser();

    const options = {
        body: keyword,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/search', options);
        yield put(searchEmployeeSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(searchEmployeeFailed(message));
    }
}

function* getCompanies({ payload: { token } }) {

    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'companies/', options);
        var companies = response;
        yield put(getAllCompaniesSuccess(companies));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAllCompaniesFailed(message));
    }
}

function* updatePhoneNumberInternal({ payload: { info } }) {
    const user = getLoggedInUser();
    const options = {
        body: info,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/phone', options);
        setLoggedInUserProfile(response);
        yield put(updatePhoneNumberSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updatePhoneNumberFailed(message));
    }
}

function* checkEmailVerificationStatusInternal({ payload: { info } }) {
    const user = getLoggedInUser();
    const options = {
        body: info,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/emailstatus', options);
        yield put(checkEmailVerificationStatusSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(checkEmailVerificationStatusFailed(message));
    }
}

function* changeEmailInternal({ payload: { type, info, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        body: info,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/changeemail/' + type, options);
        setLoggedInUserProfile(response);
        yield put(changeEmailSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(changeEmailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* resendVerificationEmailInternal({ payload: { email } }) {
    const user = getLoggedInUser();
    const options = {
        body: email,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/resend_verification_email', options);
        setLoggedInUserProfile(response);
        yield put(resendVerificationEmailSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(resendVerificationEmailFailed(message));
    }
}

function* changePasswordInternal({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();
    const options = {
        body: data,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/changepassword', options);
        yield put(changePasswordSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(changePasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCVSInternal({ payload: callbacks }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/cvs', options);
        yield put(getCVSSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCVSFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteCVSInternal({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/cvs/' + data, options);
        yield put(deleteCVSSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

        // Get CVS to update data 
        const optionsGetCVS = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
        };

        try {
            const response = yield call(fetchJSON, BASE_URL + 'profiles/cvs', optionsGetCVS);
            yield put(getCVSSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
            yield put(getCVSFailed(message));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteCVSFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPhotosInternal({ payload: callbacks }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/photos', options);
        yield put(getPhotosSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPhotosFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deletePhotosInternal({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/photos/' + data, options);
        yield put(deletePhotosSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
        // Get Photo to update data 
        const optionsGetPhotos = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
        };

        try {
            const response = yield call(fetchJSON, BASE_URL + 'profiles/photos', optionsGetPhotos);
            yield put(getPhotosSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
            yield put(getPhotosFailed(message));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deletePhotosFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* uploadPhotoInternal({ payload: { file, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const formData = new FormData();

    formData.append("file", file);

    const options = {
        body: formData,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/photos', options);
        yield put(uploadPhotoSuccess(response));

        // Get Photos to update data 
        const optionsGetPhotos = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
        };

        try {
            const responsePhoto = yield call(fetchJSON, BASE_URL + 'profiles/photos', optionsGetPhotos);
            yield put(getPhotosSuccess(responsePhoto));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
            yield put(getPhotosFailed(message));
            if (callbackOnFailure) {
                yield call(callbackOnFailure, error);
            }
        }

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(uploadPhotoFailed(message));
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getMenuHierarchyInternal() {
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'profiles/menu-hierarchy', options);
        yield put(getMenuHierarchySuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getMenuHierarchyFailed(message));
    }
}

function* getRelationActionInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/relation-action', options);
        yield put(getRelationActionSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getRelationActionFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getGoalsByUserInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/goals-by-user', options);
        yield put(getGoalsByUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getGoalsByUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getActionsByUserInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/actions-by-user', options);
        yield put(getActionsByUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getActionsByUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getGoalsActionsByUserInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/goals-actions-by-user', options);
        yield put(getGoalsActionsByUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getGoalsActionsByUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAlertsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/alert/', options);
        yield put(getAlertsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAlertsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getPublicProfileInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'profiles/get-user-by-id/' + id, options);

        yield put(getPublicProfileSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPublicProfileFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* createAcademySecurityInternal({ payload: { security, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        body: security,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/create-academy-security', options);
        setLoggedInUserProfile(response);
        yield put(createAcademySecuritySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createAcademySecurityFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* updateAcademySecurityInternal({ payload: { security, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        body: security,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/' + user.id + '/update-academy-security', options);
        setLoggedInUserProfile(response);
        yield put(updateAcademySecuritySuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateAcademySecurityFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}



function* switchUserInternal({ payload: { username, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();
    const adminUser = getAdminUser();

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let response = null;

        if (username == null || (adminUser != null && adminUser.username == username)) {
            response = adminUser;
            setImpersonateUser(null);
            setLoggedInUser(response);
        }
        else {
            let token = user.accessToken;
            if (adminUser != null){
                token = adminUser.accessToken;
            }
            var formData = new FormData();
            formData.append("username", username);
        
            const options = {
                body: formData,
                method: 'POST',
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': "Bearer " + token }
            };
        
            response = yield call(fetchJSON, BASE_URL + 'impersonate', options);

            response.roles.push("Admin");
            response.role = "Admin"
            response.accessToken = response.token;
            response.token = null;

            setImpersonateUser(response);
        }


        // Try to get user profile
        const optionsPro = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + response.accessToken }
        };
        const responsePro = yield call(fetchJSON, BASE_URL + 'profiles/' + response.id, optionsPro);
        setLoggedInUserProfile(responsePro);
        //

        yield put(switchUserSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(switchUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getFailedPaymentsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/payment/failed-subscriptions', options);
        yield put(getFailedPaymentsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getFailedPaymentsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* payFailedPaymentInternal({ payload: {subscriptionId, action, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/payment/pay-or-cancel-subscriptions/' + subscriptionId + '/' + action, options);
        yield put(payFailedPaymentSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(payFailedPaymentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* synchronizePaymentInternal({ payload: {userId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/synchronize-payment/' + userId , options);
        yield put(synchronizePaymentSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(synchronizePaymentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getDashboardInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/dash-board', options);
        yield put(getDashboardSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getDashboardFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* addEmailInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/add-email/' + params.email + '/' + params.type, options);
        yield put(addEmailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addEmailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editEmailInternal({ payload: { oldEmail, newEmail, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/change-email/' + oldEmail + '/' + newEmail, options);
        yield put(editEmailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editEmailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* removeEmailInternal({ payload: { email, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/remove-email/' + email, options);
        yield put(removeEmailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(removeEmailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* addMobileInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/add-mobile/' + params.phone + '/' + params.type, options);
        yield put(addEmailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addEmailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editMobileInternal({ payload: { oldPhone, newPhone, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/change-mobile/' + oldPhone + '/' + newPhone, options);
        yield put(editPhoneSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editPhoneFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* removeMobileInternal({ payload: { phone, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();

    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + user.accessToken }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'profiles/remove-mobile/' + phone, options);
        yield put(removePhoneSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(removePhoneFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetUserProfile() {
    yield takeEvery(GET_PROFILE, getUserProfileInternal);
}

export function* watchVisualSummary() {
    yield takeEvery(PROFILE_VISUAL_SUMMARY, getVisualSummaryInternal);
}

export function* watchUpdateUserProfile() {
    yield takeEvery(UPDATE_PROFILE, updateProfileInternal);
}

export function* watchUploadCV() {
    yield takeEvery(UPLOAD_CV, uploadCVInternal);
}

export function* watchSearchEmployee() {
    yield takeEvery(SEARCH_EMPLOYEE, searchEmployeeInternal);
}

export function* watchGetPersonalSubscription() {
    yield takeEvery(GET_PERSONAL_SUBCRIPTION, getPersonalSubscriptionInternal);
}

export function* watchProfileSubscription() {
    yield takeEvery(GET_SUBCRIPTION_PROFILE, getSubscriptionProfile);
}

export function* watchAllCompanies() {
    yield takeEvery(GET_ALL_COMPANY, getCompanies);
}

export function* watchUpdatePhoneNumber() {
    yield takeEvery(UPDATE_PHONENUMBER, updatePhoneNumberInternal);
}

export function* watchCheckEmailVerificationStatus() {
    yield takeEvery(CHECK_EMAILVERIFICATIONSTATUS, checkEmailVerificationStatusInternal);
}

export function* watchChangeEmail() {
    yield takeEvery(CHANGE_EMAIL, changeEmailInternal);
}

export function* watchResendVerificationEmail() {
    yield takeEvery(RESEND_VERIFICATION_EMAIL, resendVerificationEmailInternal);
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordInternal);
}

export function* watchGetCVS() {
    yield takeEvery(GET_CVS, getCVSInternal);
}

export function* watchDeleteCVS() {
    yield takeEvery(DELETE_CVS, deleteCVSInternal);
}

export function* watchGetPhotos() {
    yield takeEvery(GET_PHOTOS, getPhotosInternal);
}

export function* watchDeletePhotos() {
    yield takeEvery(DELETE_PHOTOS, deletePhotosInternal);
}

export function* watchUploadPhoto() {
    yield takeEvery(UPLOAD_PHOTO, uploadPhotoInternal);
}

export function* watchCreateSecurity() {
    yield takeEvery(CREATE_SECURITY, createSecurity);
}

export function* watchUpdateSecurity() {
    yield takeEvery(UPDATE_SECURITY, updateSecurity);
}

export function* watchGetSummaryDataChart() {
    yield takeEvery(PROFILE_SUMMARY_DATA_CHART, getSummaryDataChart);
}

export function* watchGetMenuHierachy() {
    yield takeEvery(GET_MENU_HIERARCHY, getMenuHierarchyInternal);
}
export function* watchGetDataExperience() {
    yield takeEvery(GET_DATA_EXPERIENCE, getDataExperienceInternal);
}

export function* watchGetDataExperienceById() {
    yield takeEvery(GET_DATA_EXPERIENCE_BY_ID, getDataExperienceByIdInternal);
}

export function* watchCreateExperience() {
    yield takeEvery(CREATE_EXPERIENCE, createExperienceInternal);
}

export function* watchEditExperience() {
    yield takeEvery(EDIT_EXPERIENCE, editExperienceInternal);
}

export function* watchDeleteExperience() {
    yield takeEvery(DELETE_EXPERIENCE, deleteExperienceInternal);
}

export function* watchGetActionGroup() {
    yield takeEvery(GET_ACTION_GROUPS, getActionGroupsInternal);
}

export function* watchGetRelationAction() {
    yield takeEvery(GET_RELATION_ACTION, getRelationActionInternal);
}

export function* watchGetGoalsByUser() {
    yield takeEvery(GET_GOALS_BY_USER, getGoalsByUserInternal);
}

export function* watchGetActionsByUser() {
    yield takeEvery(GET_ACTIONS_BY_USER, getActionsByUserInternal);
}

export function* watchGetGoalsActionsByUser() {
    yield takeEvery(GET_GOALS_ACTIONS_BY_USER, getGoalsActionsByUserInternal);
}

export function* watchGetAlerts() {
    yield takeEvery(GET_ALERTS, getAlertsInternal);
}

export function* watchGetPublicProfile() {
    yield takeEvery(GET_PUBLIC_PROFILE, getPublicProfileInternal);
}

export function* watchCreateAcademySecurity() {
    yield takeEvery(CREATE_ACADEMY_SECURITY, createAcademySecurityInternal);
}

export function* watchUpdateAcademySecurity() {
    yield takeEvery(UPDATE_ACADEMY_SECURITY, updateAcademySecurityInternal);
}

export function* watchSwitchUser() {
    yield takeEvery(SWITCH_USER, switchUserInternal);
}

export function* watchGetFailedPayments() {
    yield takeEvery(GET_FAILED_PAYMENT, getFailedPaymentsInternal);
}

export function* watchPayFailedPayment() {
    yield takeEvery(PAY_FAILED_PAYMENT, payFailedPaymentInternal);
}

export function* watchSynchronizePayment() {
    yield takeEvery(SYNCHRONIZE_PAYMENT, synchronizePaymentInternal);
}

export function* watchGetDashboard() {
    yield takeEvery(GET_DASHBOARD, getDashboardInternal);
}

export function* watchAddEmail() {
    yield takeEvery(ADD_EMAIL, addEmailInternal);
}

export function* watchEditEmail() {
    yield takeEvery(EDIT_EMAIL, editEmailInternal);
}

export function* watchRemoveEmail() {
    yield takeEvery(REMOVE_EMAIL, removeEmailInternal);
}

export function* watchAddMobile() {
    yield takeEvery(ADD_PHONE, addMobileInternal);
}

export function* watchEditMobile() {
    yield takeEvery(EDIT_PHONE, editMobileInternal);
}

export function* watchRemoveMobile() {
    yield takeEvery(REMOVE_PHONE, removeMobileInternal);
}

function* profileSaga() {
    yield all([fork(watchGetUserProfile), fork(watchVisualSummary), fork(watchUpdateUserProfile),
    fork(watchUploadCV), fork(watchSearchEmployee), fork(watchAllCompanies), fork(watchUpdatePhoneNumber),
    fork(watchCheckEmailVerificationStatus), fork(watchChangeEmail), fork(watchUpdateSecurity),
    fork(watchResendVerificationEmail), fork(watchChangePassword), fork(watchGetCVS),
    fork(watchDeleteCVS), fork(watchGetPhotos), fork(watchDeletePhotos), fork(watchUploadPhoto),
    fork(watchGetPersonalSubscription), fork(watchProfileSubscription), fork(watchCreateSecurity), fork(watchGetSummaryDataChart),
    fork(watchGetMenuHierachy), fork(watchGetDataExperience), fork(watchGetDataExperienceById), fork(watchCreateExperience),
    fork(watchEditExperience), fork(watchDeleteExperience), fork(watchGetActionsByUser),
    fork(watchGetActionGroup), fork(watchGetRelationAction), fork(watchGetGoalsByUser),
    fork(watchGetAlerts), fork(watchGetPublicProfile), fork(watchGetGoalsActionsByUser),
    fork(watchCreateAcademySecurity), fork(watchUpdateAcademySecurity), fork(watchSwitchUser),
    fork(watchGetFailedPayments), fork(watchPayFailedPayment), fork(watchSynchronizePayment), 
    fork(watchGetDashboard),
    fork(watchAddEmail), fork(watchEditEmail), fork(watchRemoveEmail),
    fork(watchAddMobile), fork(watchEditMobile), fork(watchRemoveMobile)]);
}

export default profileSaga;
