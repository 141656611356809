import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { Lock, Mail, Phone, User } from 'react-feather';
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { Button, Col, InputGroup, Label, Row, UncontrolledAlert, UncontrolledTooltip } from 'reactstrap';
import information from '../../assets/images/information.svg';
import Loader from '../../components/Loader';
import firebase from '../../firebase/firebaseIndex';
import { isUserAuthenticated } from '../../helpers/authUtils';
import { forgetPassword, getCountryInfo, setNewPassword } from '../../redux/actions';
import CodeInputModal from './CodeInputModal';
import SVG from 'react-inlinesvg';


class ForgetPassword extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            phone: '',
            countryCode: '',
            showCodeInputDialog: 0,
            isCodePhoneVerified: 0,
            userEmail: '',
            userPhone: '',
            userName: '',
            newPassword: '',
            password: '',
            repassword: '',
            isInvalidPassword: 0,
            isInvalidPhone: 0,
            changePasswordSucess: 0,
            passwordResetSucess: false,
            preparToChangePassword: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    toggleCodeInputModal = () => {
        this.setState({
            showCodeInputDialog: 2,
        });
    };

    handleLoginByPhoneNumber = async () => {
        try {
            this.setState({
                isInvalidPhone: 0,
            });

            const appVerifier = window.appVerifier;
            // console.log("signInWithPhoneNumber: " + this.state.phoneNumber);
            const response = await firebase
                .auth()
                .signInWithPhoneNumber('+' + this.state.countryCode + this.state.phoneNumber, appVerifier)

            if (response && response.verificationId) {
                window.confirmationResult = response;
                this.setState({
                    showCodeInputDialog: 1
                });
            }
            else {
                appVerifier.reset(window.recaptchaWidgetId);
            }
            // console.log(response.toString());
        } catch (error) {
            const appVerifier = window.appVerifier;
            appVerifier.reset(window.recaptchaWidgetId);
            this.setState({
                isInvalidPhone: 1,
            });
        }
    };
    onVerifyButton = () => {
        this.verifyPhoneNumber(this.state.country, this.state.phone)
    }
    verifyPhoneNumber = (country, phone) => {
        this.setState({ phoneNumber: phone, countryCode: country }, () => {
            // console.log(this.state.phoneNumber, 'phoneNumber');
            this.handleLoginByPhoneNumber();

        });
    };

    verifyCodeNumber = (code) => {
        this.setState({
            showCodeInputDialog: 2,
        });
        this.setState({ verifyNumber: code }, () => {
            this.verifyCodeSubmit();
        });
    };
    verifyCodeSubmit = async () => {
        try {
            const verificationId = this.state.verifyNumber;
            const result = await window.confirmationResult
                .confirm(verificationId)

            if (result && result.user) {
                // Save login info

                const info = {
                    username: this.state.username, password: this.state.password,
                    email: this.state.email,
                    country: this.state.countryCode, mobileNumber: this.state.phoneNumber
                };

                // Redirect to main
                this.props.forgetPassword({ phone: '+' + this.state.countryCode + this.state.phoneNumber }, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        //this.setState({ loading: true, error: null });
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                    },
                    callbackOnSuccess: (response) => {
                        // whatever
                        // console.log('callbackOnSuccess');
                        this.setState({ preparToChangePassword: true, isCodePhoneVerified: 1 });
                    },
                })

            }
            else {
                //alert('The input code is invalid.');
                this.setState({
                    showCodeInputDialog: 1,
                });
            }
        } catch (error) {
            alert(error.toString());
            this.setState({
                showCodeInputDialog: 1,
            });
        }
    }

    handleValidSubmit = () => {
        const { password, repassword } = this.state;
        // perform all neccassary validations
        if (password !== repassword) {
            this.setState({
                isInvalidPassword: 1
            })
        } else {
            this.props.setNewPassword({
                password: password,
                code: this.props.passwordResetRequested.passwordResetStatus.resetPasswordVerificationCode
            }, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                },
                callbackOnFinish: () => {
                    // Update loading state?
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    this.setState({ passwordResetSucess: true, isCodePhoneVerified: 0 });
                },
            });
        }
    }

    resendCode = () => {
        this.handleLoginByPhoneNumber();
    }

    changedPasswordSucessAndLogin() {
        this.setState({ passwordResetSucess: false, preparToChangePassword: false });
        this.props.setResetMode(0);
        this.props.onCancelButton();
        this.props.setChangePasswordSucess(1);

    }

    Alert = (props) => {
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <UncontrolledAlert color="danger" key="alert" md={6}>
                {message != null ? message : "Cannot connect to server."}
            </UncontrolledAlert>
        }
        return ""
    }

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
    }

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        const { t, error } = this.props;
        const userInfoReset = this.props.passwordResetRequested.passwordResetStatus
        return (
            <React.Fragment>
                {(this.props.checkTabMode > 0) && this.state.preparToChangePassword && this.props.setResetMode(1)}
                {this.state.passwordResetSucess && this.changedPasswordSucessAndLogin()}
                {this.state.showCodeInputDialog == 1 && <CodeInputModal
                    modal={this.state.showCodeInputDialog == 1}
                    phoneNumber={'+' + this.state.countryCode + this.state.phone}
                    toggle={this.toggleCodeInputModal}
                    verify={this.verifyCodeNumber}
                    resendCode={this.resendCode} />}
                {this.state.isCodePhoneVerified == 1
                    ?
                    <Row>
                        <Col md={12}>
                            { /* preloader */}
                            {this.props.loading && <Loader />}

                            <Label className="title-left-label ml-3">{t('forgotPassword.resetPassword')} :</Label>

                            {error && <this.Alert error={error} />}
                            {(this.state.isInvalidPassword == 1) &&
                                <UncontrolledAlert className='col-md-6' color="danger" key="alert">
                                    {t('reset.passwordDontMatch')}
                                </UncontrolledAlert>
                            }
                            <Row>

                                <Col md={6} xs={6} >
                                    <Row className='mt-3'>
                                        <Col md={4}>
                                            <User className="icon-dual mr-1" />
                                            <Label for="">{t('signIn.username1')}:</Label>
                                        </Col>

                                        <Label for="">{userInfoReset && userInfoReset.username}</Label>

                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Mail className="icon-dual mr-1" />
                                            <Label for="">{t('signUp.email')} :</Label>
                                        </Col>

                                        <Label for="">{userInfoReset && userInfoReset.email} </Label>


                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Phone className="icon-dual mr-1" />
                                            <Label for="">{t('reset.mobile')} :</Label>
                                        </Col>

                                        <Label for="">{userInfoReset && userInfoReset.mobileNumber} </Label>

                                    </Row>
                                </Col>
                                <Col md={6} xs={6} >
                                    <AvForm onValidSubmit={this.handleValidSubmit} className="authentication-form mt-1">
                                        <Row>
                                            <Col md={5}>
                                                <AvGroup className="">
                                                    <Row className='ml-1'>
                                                        <Lock className="icon-dual mr-1" />
                                                        <Label for="password">{t("changePassword.enterNewPassword")}</Label>
                                                        <div className='size-px-20 box-center ml-1 mr-1 mb-1' id='intro-info'>
                                                            <SVG src={information} className="size-px-20" />
                                                        </div>
                                                    </Row>

                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        id='tooltip-1'
                                                        target='intro-help'>
                                                        Enter your password you want
                                                    </UncontrolledTooltip>
                                                    <InputGroup className='input-container'>

                                                        <AvInput autoComplete="off" type="password" name="password" id="password" placeholder={t('signIn.passwordPlaceHolder')}
                                                            onChange={(value, data, event, formattedValue) => {
                                                                this.setState({ password: data })
                                                            }}
                                                            required />
                                                    </InputGroup>
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col md={5}>
                                                <AvGroup className="">
                                                    <Row className='ml-1'>
                                                        <Lock className="icon-dual mr-1" />
                                                        <Label for="password">{t("changePassword.retypeNewPassword")}</Label>
                                                    </Row>
                                                    <InputGroup className='input-container'>

                                                        <AvInput type="password" name="password" id="password" placeholder={t('signIn.passwordPlaceHolder')}
                                                            onChange={(value, data, event, formattedValue) => {
                                                                this.setState({ repassword: data })
                                                            }}
                                                            required />
                                                    </InputGroup>

                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>

                                            <AvGroup className="">
                                                <Button onClick={() => this.handleValidSubmit()} color="primary" className="btn-block btn-top-margin">{t("reset.join")}</Button>
                                            </AvGroup>
                                        </Row>
                                    </AvForm>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col md={12}>
                            { /* preloader */}
                            {this.props.loading && <Loader />}

                            <Label className="title-left-label">{t("reset.accountInformationForgotten")}</Label>
                            <div className='size-px-20 box-center ml-2 mr-2 mb-2' id='intro-info'>
                                <SVG src={information} className="size-px-20" />
                            </div>
                            <UncontrolledTooltip
                                placement="top"
                                id='tooltip-1'
                                target='intro-info'>
                                Enter your phone number to recover your account
                            </UncontrolledTooltip>
                            {error && <this.Alert error={error} />}
                            {(this.state.isInvalidPhone == 1) &&
                                <UncontrolledAlert className='col-md-6' color="danger" key="alert">
                                    {t('forgot.wrongPhoneFormat')}
                                </UncontrolledAlert>
                            }
                            <AvForm onValidSubmit={this.handleValidSubmit} className="authentication-form mt-1">
                                <Row>
                                    <Col md={4}>
                                        <AvGroup className="">
                                            <User className="icon-dual mr-1" />
                                            <Label for="username">{t("reset.mobilePhone")}</Label>
                                            <PhoneInput
                                                containerClass={"phone-input-custom"}
                                                country={this.props.country && this.props.country.countryCode.toLowerCase() ? this.props.country.countryCode.toLowerCase() : 'fr'}
                                                onChange={(value, data, event, formattedValue) => {
                                                    this.setState({ phone: value.slice(data.dialCode.length), country: data.dialCode })
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>

                                    <AvGroup className="mr-3">
                                        <Button color="white" onClick={this.props.onCancelButton} className="btn-outline-secondary btn-top-margin">{t("personalInfo.cancel")}</Button>

                                    </AvGroup>
                                    <AvGroup className="">
                                        <Button color="primary" onClick={this.onVerifyButton} className="btn-block btn-top-margin">{t("reset.send")}</Button>
                                    </AvGroup>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { passwordResetRequested, passwordReset, loading, error } = state.Auth;
    const country = state.Auth.country;

    return { passwordResetRequested, passwordReset, country, loading, error };

};

export default connect(mapStateToProps, { forgetPassword, getCountryInfo, setNewPassword })(withTranslation('translations')(ForgetPassword));
