// @flow
import {
    CHECK_EXIST_NETWORK_DOMAIN, CHECK_EXIST_NETWORK_DOMAIN_SUCCESS, CHECK_EXIST_NETWORK_DOMAIN_FAILED,
    CREATE_CONTACT, CREATE_CONTACT_SUCCESS, CREATE_CONTACT_FAILED,
    EDIT_CONTACT, EDIT_CONTACT_SUCCESS, EDIT_CONTACT_FAILED,
    DELETE_CONTACT, DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAILED,
    GET_NETWORK, GET_NETWORK_SUCCESS, GET_NETWORK_FAILED,
    CREATE_INVITE, CREATE_INVITE_SUCCESS, CREATE_INVITE_FAILED,
    GET_ALL_NETWORK_PERSONS, GET_ALL_NETWORK_PERSONS_FAILED, GET_ALL_NETWORK_PERSONS_SUCCESS,
    GET_ALL_ACADEMICS, GET_ALL_ACADEMICS_SUCCESS, GET_ALL_ACADEMICS_FAILED,
    GET_ALL_COMPANIES2, GET_ALL_COMPANIES2_SUCCESS, GET_ALL_COMPANIES2_FAILED,
    GET_ALL_NETWORK_PEOPLE, GET_ALL_NETWORK_PEOPLE_SUCCESS, GET_ALL_NETWORK_PEOPLE_FAILED,
    GET_NETWORK_PEOPLE, GET_NETWORK_PEOPLE_SUCCESS, GET_NETWORK_PEOPLE_FAILED,
    GET_ALL_NETWORK_COMPANIES, GET_ALL_NETWORK_COMPANIES_SUCCESS, GET_ALL_NETWORK_COMPANIES_FAILED,
    GET_NETWORK_COMPANIES, GET_NETWORK_COMPANIES_SUCCESS, GET_NETWORK_COMPANIES_FAILED,
    CHECK_EXIST_USERS, CHECK_EXIST_USERS_SUCCESS, CHECK_EXIST_USERS_FAILED,
    RESET_EXIST_USERS, RESET_EXIST_USERS_SUCCESS, RESET_EXIST_USERS_FAILED,
    GET_NETWORK_PERSON_INVITATIONS, GET_NETWORK_PERSON_INVITATIONS_SUCCESS, GET_NETWORK_PERSON_INVITATIONS_FAILED,
    GET_NETWORK_PERSON_VALIDATIONS, GET_NETWORK_PERSON_VALIDATIONS_SUCCESS, GET_NETWORK_PERSON_VALIDATIONS_FAILED,
    GET_NETWORK_PERSON_EXPERIENCES, GET_NETWORK_PERSON_EXPERIENCES_SUCCESS, GET_NETWORK_PERSON_EXPERIENCES_FAILED,
    GET_NETWORK_PERSON_NETWORK, GET_NETWORK_PERSON_NETWORK_SUCCESS, GET_NETWORK_PERSON_NETWORK_FAILED,

    CHECK_EXIST_COMPANY, CHECK_EXIST_COMPANY_SUCCESS, CHECK_EXIST_COMPANY_FAILED,
    CHECK_EXIST_COMPANY_DOMAINS, CHECK_EXIST_COMPANY_DOMAINS_SUCCESS, CHECK_EXIST_COMPANY_DOMAINS_FAILED,
    CREATE_NETWORK_COMPANY, CREATE_NETWORK_COMPANY_SUCCESS, CREATE_NETWORK_COMPANY_FAILED,
    EDIT_NETWORK_COMPANY, EDIT_NETWORK_COMPANY_SUCCESS, EDIT_NETWORK_COMPANY_FAILED,
    DELETE_NETWORK_COMPANY, DELETE_NETWORK_COMPANY_SUCCESS, DELETE_NETWORK_COMPANY_FAILED,
    CREATE_NETWORK_COMPANY_GOAL, CREATE_NETWORK_COMPANY_GOAL_SUCCESS, CREATE_NETWORK_COMPANY_GOAL_FAILED,
    EDIT_NETWORK_COMPANY_GOAL, EDIT_NETWORK_COMPANY_GOAL_SUCCESS, EDIT_NETWORK_COMPANY_GOAL_FAILED,
    DELETE_NETWORK_COMPANY_GOAL, DELETE_NETWORK_COMPANY_GOAL_SUCCESS, DELETE_NETWORK_COMPANY_GOAL_FAILED,
    GET_NETWORK_COMPANY_EXPERIENCES, GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS, GET_NETWORK_COMPANY_EXPERIENCES_FAILED,

    CHECK_EXIST_ACADEMY, CHECK_EXIST_ACADEMY_SUCCESS, CHECK_EXIST_ACADEMY_FAILED,
    CHECK_EXIST_ACADEMY_DOMAINS, CHECK_EXIST_ACADEMY_DOMAINS_SUCCESS, CHECK_EXIST_ACADEMY_DOMAINS_FAILED,
    CREATE_NETWORK_ACADEMY, CREATE_NETWORK_ACADEMY_SUCCESS, CREATE_NETWORK_ACADEMY_FAILED,
    EDIT_NETWORK_ACADEMY, EDIT_NETWORK_ACADEMY_SUCCESS, EDIT_NETWORK_ACADEMY_FAILED,
    DELETE_NETWORK_ACADEMY, DELETE_NETWORK_ACADEMY_SUCCESS, DELETE_NETWORK_ACADEMY_FAILED,
    GET_ALL_NETWORK_ACADEMICS, GET_ALL_NETWORK_ACADEMICS_SUCCESS, GET_ALL_NETWORK_ACADEMICS_FAILED,
    GET_NETWORK_ACADEMICS, GET_NETWORK_ACADEMICS_SUCCESS, GET_NETWORK_ACADEMICS_FAILED,
    GET_NETWORK_ACADEMIC_EXPERIENCES, GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS, GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED,
    
    CREATE_EDUCATION, CREATE_EDUCATION_SUCCESS, CREATE_EDUCATION_FAILED,
    EDIT_EDUCATION, EDIT_EDUCATION_SUCCESS, EDIT_EDUCATION_FAILED,
    DELETE_EDUCATION, DELETE_EDUCATION_SUCCESS, DELETE_EDUCATION_FAILED,
    
    GET_PEOPLE_BY_DOMAIN, GET_PEOPLE_BY_DOMAIN_SUCCESS, GET_PEOPLE_BY_DOMAIN_FAILED,
    CREATE_NETWORK_EVENT, CREATE_NETWORK_EVENT_SUCCESS, CREATE_NETWORK_EVENT_FAILED,
    EDIT_NETWORK_EVENT, EDIT_NETWORK_EVENT_SUCCESS, EDIT_NETWORK_EVENT_FAILED,
    DELETE_NETWORK_EVENT, DELETE_NETWORK_EVENT_SUCCESS, DELETE_NETWORK_EVENT_FAILED,
    GET_ALL_NETWORK_EVENTS, GET_ALL_NETWORK_EVENTS_SUCCESS, GET_ALL_NETWORK_EVENTS_FAILED,
    GET_NETWORK_EVENTS, GET_NETWORK_EVENTS_SUCCESS, GET_NETWORK_EVENTS_FAILED,
    CHECK_EXIST_EVENT, CHECK_EXIST_EVENT_SUCCESS, CHECK_EXIST_EVENT_FAILED,
    GET_EVENT_GROUP, GET_EVENT_GROUP_SUCCESS, GET_EVENT_GROUP_FAILED,

    GET_SIGNUP_REQUESTS, GET_SIGNUP_REQUESTS_SUCCESS, GET_SIGNUP_REQUESTS_FAILED,
    DECLINE_SIGNUP_REQUEST, DECLINE_SIGNUP_REQUEST_SUCCESS, DECLINE_SIGNUP_REQUEST_FAILED,

    ADD_EXIST_COMPANY_TO_NETWORK, ADD_EXIST_COMPANY_TO_NETWORK_SUCCESS, ADD_EXIST_COMPANY_TO_NETWORK_FAILED,
    ADD_EXIST_ACADEMIC_TO_NETWORK, ADD_EXIST_ACADEMIC_TO_NETWORK_SUCCESS, ADD_EXIST_ACADEMIC_TO_NETWORK_FAILED

} from './constants';

export const checkExistNetworkDomain = (domain, callbacks) => ({
    type: CHECK_EXIST_NETWORK_DOMAIN,
    payload: { domain, callbacks },
});

export const checkExistNetworkDomainSuccess = (response) => ({
    type: CHECK_EXIST_NETWORK_DOMAIN_SUCCESS,
    payload: { response },
});

export const checkExistNetworkDomainFailed = (error) => ({
    type: CHECK_EXIST_NETWORK_DOMAIN_FAILED,
    payload: error,
});

export const createContact = (params, callbacks) => ({
    type: CREATE_CONTACT,
    payload: { params, callbacks },
});

export const createContactSuccess = (response) => ({
    type: CREATE_CONTACT_SUCCESS,
    payload: response,
});

export const createContactFailed = (error) => ({
    type: CREATE_CONTACT_FAILED,
    payload: error,
});

export const createInvite = (params, contactId, callbacks) => ({
    type: CREATE_INVITE,
    payload: { params, contactId, callbacks },
});

export const createInviteSuccess = (network) => ({
    type: CREATE_INVITE_SUCCESS,
    payload: { network },
});

export const createInviteFailed = (error) => ({
    type: CREATE_INVITE_FAILED,
    payload: error,
});

export const editContact = (id, params, callbacks) => ({
    type: EDIT_CONTACT,
    payload: { id, params, callbacks },
});

export const editContactSuccess = (response) => ({
    type: EDIT_CONTACT_SUCCESS,
    payload: response,
});

export const editContactFailed = (error) => ({
    type: EDIT_CONTACT_FAILED,
    payload: error,
});


export const deleteContact = (id, callbacks) => ({
    type: DELETE_CONTACT,
    payload: { id, callbacks },
});

export const deleteContactSuccess = (response) => ({
    type: DELETE_CONTACT_SUCCESS,
    payload: response,
});

export const deleteContactFailed = (error) => ({
    type: DELETE_CONTACT_FAILED,
    payload: error,
});


export const getNetwork = (callbacks) => ({
    type: GET_NETWORK,
    payload: { callbacks },
});

export const getNetworkSuccess = (network) => ({
    type: GET_NETWORK_SUCCESS,
    payload: { network },
});

export const getNetworkFailed = (error) => ({
    type: GET_NETWORK_FAILED,
    payload: error,
});

export const getAllNetworkPeople = (callbacks) => ({
    type: GET_ALL_NETWORK_PEOPLE,
    payload: { callbacks },
});

export const getAllNetworkPeopleSuccess = (network) => ({
    type: GET_ALL_NETWORK_PEOPLE_SUCCESS,
    payload: network,
});

export const getAllNetworkPeopleFailed = (error) => ({
    type: GET_ALL_NETWORK_PEOPLE_FAILED,
    payload: error,
});

export const getAllNetworkPersons = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_ALL_NETWORK_PERSONS,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getAllNetworkPersonsSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_ALL_NETWORK_PERSONS_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getAllNetworkPersonsFailed = (error) => ({
    type: GET_ALL_NETWORK_PERSONS_FAILED,
    payload: error,
});

export const getAllAcademics = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_ALL_ACADEMICS,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getAllAcademicsSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_ALL_ACADEMICS_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getAllAcademicsFailed = (error) => ({
    type: GET_ALL_ACADEMICS_FAILED,
    payload: error,
});

export const getAllCompanies2 = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_ALL_COMPANIES2,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getAllCompanies2Success = (type, page, loadDetails, keyword, network) => ({
    type: GET_ALL_COMPANIES2_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getAllCompanies2Failed = (error) => ({
    type: GET_ALL_COMPANIES2_FAILED,
    payload: error,
});

export const getNetworkPeople = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_NETWORK_PEOPLE,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getNetworkPeopleSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_NETWORK_PEOPLE_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getNetworkPeopleFailed = (error) => ({
    type: GET_NETWORK_PEOPLE_FAILED,
    payload: error,
});

export const getAllNetworkCompanies = (callbacks) => ({
    type: GET_ALL_NETWORK_COMPANIES,
    payload: { callbacks },
});

export const getAllNetworkCompaniesSuccess = (network) => ({
    type: GET_ALL_NETWORK_COMPANIES_SUCCESS,
    payload: network,
});

export const getAllNetworkCompaniesFailed = (error) => ({
    type: GET_ALL_NETWORK_COMPANIES_FAILED,
    payload: error,
});

export const getNetworkCompanies = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_NETWORK_COMPANIES,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getNetworkCompaniesSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_NETWORK_COMPANIES_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getNetworkCompaniesFailed = (error) => ({
    type: GET_NETWORK_COMPANIES_FAILED,
    payload: error,
});

export const checkExistUsers = (params, callbacks) => ({
    type: CHECK_EXIST_USERS,
    payload: { params, callbacks },
});

export const checkExistUsersSuccess = (response) => ({
    type: CHECK_EXIST_USERS_SUCCESS,
    payload: { response },
});

export const checkExistUsersFailed = (error) => ({
    type: CHECK_EXIST_USERS_FAILED,
    payload: error,
});

export const resetExistUsers = (callbacks) => ({
    type: RESET_EXIST_USERS,
    payload: { callbacks },
});

export const resetExistUsersSuccess = () => ({
    type: RESET_EXIST_USERS_SUCCESS,
    payload: {},
});

export const resetExistUsersFailed = (error) => ({
    type: RESET_EXIST_USERS_FAILED,
    payload: error,
});

export const getNetworkPersonInvitations = (id, withMe, callbacks) => ({
    type: GET_NETWORK_PERSON_INVITATIONS,
    payload: { id, withMe, callbacks },
});

export const getNetworkPersonInvitationsSuccess = (response, withMe) => ({
    type: GET_NETWORK_PERSON_INVITATIONS_SUCCESS,
    payload: { response, withMe },
});

export const getNetworkPersonInvitationsFailed = (error) => ({
    type: GET_NETWORK_PERSON_INVITATIONS_FAILED,
    payload: error,
});

export const getNetworkPersonValidations = (id, withMe, callbacks) => ({
    type: GET_NETWORK_PERSON_VALIDATIONS,
    payload: { id, withMe, callbacks },
});

export const getNetworkPersonValidationsSuccess = (response, withMe) => ({
    type: GET_NETWORK_PERSON_VALIDATIONS_SUCCESS,
    payload: { response, withMe },
});

export const getNetworkPersonValidationsFailed = (error) => ({
    type: GET_NETWORK_PERSON_VALIDATIONS_FAILED,
    payload: error,
});

export const getNetworkPersonExperiences = (id, withMe, callbacks) => ({
    type: GET_NETWORK_PERSON_EXPERIENCES,
    payload: { id, withMe, callbacks },
});

export const getNetworkPersonExperiencesSuccess = (response, withMe) => ({
    type: GET_NETWORK_PERSON_EXPERIENCES_SUCCESS,
    payload: { response, withMe },
});

export const getNetworkPersonExperiencesFailed = (error) => ({
    type: GET_NETWORK_PERSON_EXPERIENCES_FAILED,
    payload: error,
});

export const getNetworkPersonnetwork = (id, callbacks) => ({
    type: GET_NETWORK_PERSON_NETWORK,
    payload: { id, callbacks },
});

export const getNetworkPersonnetworkSuccess = (response) => ({
    type: GET_NETWORK_PERSON_NETWORK_SUCCESS,
    payload: { response },
});

export const getNetworkPersonnetworkFailed = (error) => ({
    type: GET_NETWORK_PERSON_NETWORK_FAILED,
    payload: error,
});

export const checkExistCompany = (params, callbacks) => ({
    type: CHECK_EXIST_COMPANY,
    payload: { params, callbacks },
});

export const checkExistCompanySuccess = (response) => ({
    type: CHECK_EXIST_COMPANY_SUCCESS,
    payload: { response },
});

export const checkExistCompanyFailed = (error) => ({
    type: CHECK_EXIST_COMPANY_FAILED,
    payload: error,
});

export const checkExistCompanyDomains = (params, callbacks) => ({
    type: CHECK_EXIST_COMPANY_DOMAINS,
    payload: { params, callbacks },
});

export const checkExistCompanyDomainsSuccess = (response) => ({
    type: CHECK_EXIST_COMPANY_DOMAINS_SUCCESS,
    payload: { response },
});

export const checkExistCompanyDomainsFailed = (error) => ({
    type: CHECK_EXIST_COMPANY_DOMAINS_FAILED,
    payload: error,
});

export const createNetworkCompany = (params, callbacks) => ({
    type: CREATE_NETWORK_COMPANY,
    payload: { params, callbacks },
});

export const createNetworkCompanySuccess = (response) => ({
    type: CREATE_NETWORK_COMPANY_SUCCESS,
    payload: response,
});

export const createNetworkCompanyFailed = (error) => ({
    type: CREATE_NETWORK_COMPANY_FAILED,
    payload: error,
});

export const editNetworkCompany = (id, params, callbacks) => ({
    type: EDIT_NETWORK_COMPANY,
    payload: { id, params, callbacks },
});

export const editNetworkCompanySuccess = (response) => ({
    type: EDIT_NETWORK_COMPANY_SUCCESS,
    payload: response,
});

export const editNetworkCompanyFailed = (error) => ({
    type: EDIT_NETWORK_COMPANY_FAILED,
    payload: error,
});

export const deleteNetworkCompany = (id, callbacks) => ({
    type: DELETE_NETWORK_COMPANY,
    payload: { id, callbacks },
});

export const deleteNetworkCompanySuccess = (response) => ({
    type: DELETE_NETWORK_COMPANY_SUCCESS,
    payload: response,
});

export const deleteNetworkCompanyFailed = (error) => ({
    type: DELETE_NETWORK_COMPANY_FAILED,
    payload: error,
});


export const createNetworkCompanyGoal = (companyNodeId, params, callbacks) => ({
    type: CREATE_NETWORK_COMPANY_GOAL,
    payload: { companyNodeId, params, callbacks },
});

export const createNetworkCompanyGoalSuccess = (response) => ({
    type: CREATE_NETWORK_COMPANY_GOAL_SUCCESS,
    payload: { response },
});

export const createNetworkCompanyGoalFailed = (error) => ({
    type: CREATE_NETWORK_COMPANY_GOAL_FAILED,
    payload: error,
});

export const editNetworkCompanyGoal = (goalId, params, callbacks) => ({
    type: EDIT_NETWORK_COMPANY_GOAL,
    payload: { goalId, params, callbacks },
});

export const editNetworkCompanyGoalSuccess = (response) => ({
    type: EDIT_NETWORK_COMPANY_GOAL_SUCCESS,
    payload: { response },
});

export const editNetworkCompanyGoalFailed = (error) => ({
    type: EDIT_NETWORK_COMPANY_GOAL_FAILED,
    payload: error,
});

export const deleteNetworkCompanyGoal = (goalId, callbacks) => ({
    type: DELETE_NETWORK_COMPANY_GOAL,
    payload: { goalId, callbacks },
});

export const deleteNetworkCompanyGoalSuccess = (response) => ({
    type: DELETE_NETWORK_COMPANY_GOAL_SUCCESS,
    payload: { response },
});

export const deleteNetworkCompanyGoalFailed = (error) => ({
    type: DELETE_NETWORK_COMPANY_GOAL_FAILED,
    payload: error,
});

export const getNetworkCompanyExperiences = (companyId, callbacks) => ({
    type: GET_NETWORK_COMPANY_EXPERIENCES,
    payload: { companyId, callbacks },
});

export const getNetworkCompanyExperiencesSuccess = (response) => ({
    type: GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS,
    payload: response,
});

export const getNetworkCompanyExperiencesFailed = (error) => ({
    type: GET_NETWORK_COMPANY_EXPERIENCES_FAILED,
    payload: error,
});

//----Network Academics----

export const getNetworkAcademicExperiences = (academicId, callbacks) => ({
    type: GET_NETWORK_ACADEMIC_EXPERIENCES,
    payload: { academicId, callbacks },
});

export const getNetworkAcademicExperiencesSuccess = (response) => ({
    type: GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS,
    payload: response,
});

export const getNetworkAcademicExperiencesFailed = (error) => ({
    type: GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED,
    payload: error,
});

export const checkExistAcademy = (params, callbacks) => ({
    type: CHECK_EXIST_ACADEMY,
    payload: { params, callbacks },
});

export const checkExistAcademySuccess = (response) => ({
    type: CHECK_EXIST_ACADEMY_SUCCESS,
    payload: { response },
});

export const checkExistAcademyFailed = (error) => ({
    type: CHECK_EXIST_ACADEMY_FAILED,
    payload: error,
});

export const checkExistAcademyDomains = (params, callbacks) => ({
    type: CHECK_EXIST_ACADEMY_DOMAINS,
    payload: { params, callbacks },
});

export const checkExistAcademyDomainsSuccess = (response) => ({
    type: CHECK_EXIST_ACADEMY_DOMAINS_SUCCESS,
    payload: { response },
});

export const checkExistAcademyDomainsFailed = (error) => ({
    type: CHECK_EXIST_ACADEMY_DOMAINS_FAILED,
    payload: error,
});

export const createNetworkAcademy = (params, callbacks) => ({
    type: CREATE_NETWORK_ACADEMY,
    payload: { params, callbacks },
});

export const createNetworkAcademySuccess = (response) => ({
    type: CREATE_NETWORK_ACADEMY_SUCCESS,
    payload: response,
});

export const createNetworkAcademyFailed = (error) => ({
    type: CREATE_NETWORK_ACADEMY_FAILED,
    payload: error,
});

export const editNetworkAcademy = (id, params, callbacks) => ({
    type: EDIT_NETWORK_ACADEMY,
    payload: { id, params, callbacks },
});

export const editNetworkAcademySuccess = (response) => ({
    type: EDIT_NETWORK_ACADEMY_SUCCESS,
    payload: response,
});

export const editNetworkAcademyFailed = (error) => ({
    type: EDIT_NETWORK_ACADEMY_FAILED,
    payload: error,
});

export const deleteNetworkAcademy = (id, callbacks) => ({
    type: DELETE_NETWORK_ACADEMY,
    payload: { id, callbacks },
});

export const deleteNetworkAcademySuccess = (response) => ({
    type: DELETE_NETWORK_ACADEMY_SUCCESS,
    payload: response,
});

export const deleteNetworkAcademyFailed = (error) => ({
    type: DELETE_NETWORK_ACADEMY_FAILED,
    payload: error,
});

export const getAllNetworkAcademics = (callbacks) => ({
    type: GET_ALL_NETWORK_ACADEMICS,
    payload: { callbacks },
});

export const getAllNetworkAcademicsSuccess = (network) => ({
    type: GET_ALL_NETWORK_ACADEMICS_SUCCESS,
    payload: network,
});

export const getAllNetworkAcademicsFailed = (error) => ({
    type: GET_ALL_NETWORK_ACADEMICS_FAILED,
    payload: error,
});

export const getNetworkAcademics = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_NETWORK_ACADEMICS,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getNetworkAcademicsSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_NETWORK_ACADEMICS_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getNetworkAcademicsFailed = (error) => ({
    type: GET_NETWORK_ACADEMICS_FAILED,
    payload: error,
});

export const createEducation = (params, callbacks) => ({
    type: CREATE_EDUCATION,
    payload: { params, callbacks },
});

export const createEducationSuccess = (response) => ({
    type: CREATE_EDUCATION_SUCCESS,
    payload: { response },
});

export const createEducationFailed = (error) => ({
    type: CREATE_EDUCATION_FAILED,
    payload: error,
});

export const editEducation = (params, callbacks) => ({
    type: EDIT_EDUCATION,
    payload: { params, callbacks },
});

export const editEducationSuccess = (response) => ({
    type: EDIT_EDUCATION_SUCCESS,
    payload: { response },
});

export const editEducationFailed = (error) => ({
    type: EDIT_EDUCATION_FAILED,
    payload: error,
});

export const deleteEducation = (academyId, nodeId, placeId, callbacks) => ({
    type: DELETE_EDUCATION,
    payload: { academyId, nodeId, placeId, callbacks },
});

export const deleteEducationSuccess = (response) => ({
    type: DELETE_EDUCATION_SUCCESS,
    payload: { response },
});

export const deleteEducationFailed = (error) => ({
    type: DELETE_EDUCATION_FAILED,
    payload: error,
});

export const getPeopleByDomain = (domain, callbacks) => ({
    type: GET_PEOPLE_BY_DOMAIN,
    payload: { domain, callbacks },
});

export const getPeopleByDomainSuccess = (network) => ({
    type: GET_PEOPLE_BY_DOMAIN_SUCCESS,
    payload: { network },
});

export const getPeopleByDomainFailed = (error) => ({
    type: GET_PEOPLE_BY_DOMAIN_FAILED,
    payload: error,
});

// Network events
export const createNetworkEvent = (params, callbacks) => ({
    type: CREATE_NETWORK_EVENT,
    payload: { params, callbacks },
});

export const createNetworkEventSuccess = (response) => ({
    type: CREATE_NETWORK_EVENT_SUCCESS,
    payload: response,
});

export const createNetworkEventFailed = (error) => ({
    type: CREATE_NETWORK_EVENT_FAILED,
    payload: error,
});

export const editNetworkEvent = (id, params, callbacks) => ({
    type: EDIT_NETWORK_EVENT,
    payload: { id, params, callbacks },
});

export const editNetworkEventSuccess = (response) => ({
    type: EDIT_NETWORK_EVENT_SUCCESS,
    payload: response,
});

export const editNetworkEventFailed = (error) => ({
    type: EDIT_NETWORK_EVENT_FAILED,
    payload: error,
});

export const deleteNetworkEvent = (id, callbacks) => ({
    type: DELETE_NETWORK_EVENT,
    payload: { id, callbacks },
});

export const deleteNetworkEventSuccess = (response) => ({
    type: DELETE_NETWORK_EVENT_SUCCESS,
    payload: response,
});

export const deleteNetworkEventFailed = (error) => ({
    type: DELETE_NETWORK_EVENT_FAILED,
    payload: error,
});

export const getAllNetworkEvents = (callbacks) => ({
    type: GET_ALL_NETWORK_EVENTS,
    payload: { callbacks },
});

export const getAllNetworkEventsSuccess = (response) => ({
    type: GET_ALL_NETWORK_EVENTS_SUCCESS,
    payload: response,
});

export const getAllNetworkEventsFailed = (error) => ({
    type: GET_ALL_NETWORK_EVENTS_FAILED,
    payload: error,
});

export const getNetworkEvents = (type, page, loadDetails, keyword, callbacks) => ({
    type: GET_NETWORK_EVENTS,
    payload: { type, page, loadDetails, keyword, callbacks },
});

export const getNetworkEventsSuccess = (type, page, loadDetails, keyword, network) => ({
    type: GET_NETWORK_EVENTS_SUCCESS,
    payload: { type, page, loadDetails, keyword, network },
});

export const getNetworkEventsFailed = (error) => ({
    type: GET_NETWORK_EVENTS_FAILED,
    payload: error,
});

export const checkExistEvent = (params, callbacks) => ({
    type: CHECK_EXIST_EVENT,
    payload: { params, callbacks },
});

export const checkExistEventSuccess = (response) => ({
    type: CHECK_EXIST_EVENT_SUCCESS,
    payload: { response },
});

export const checkExistEventFailed = (error) => ({
    type: CHECK_EXIST_EVENT_FAILED,
    payload: error,
});

export const getEventGroup = (callbacks) => ({
    type: GET_EVENT_GROUP,
    payload: { callbacks },
});

export const getEventGroupSuccess = (group) => ({
    type: GET_EVENT_GROUP_SUCCESS,
    payload: group,
});

export const getEventGroupFailed = (error) => ({
    type: GET_EVENT_GROUP_FAILED,
    payload: error,
});

export const getSignupRequests = (callbacks) => ({
    type: GET_SIGNUP_REQUESTS,
    payload: { callbacks },
});

export const getSignupRequestsSuccess = (response) => ({
    type: GET_SIGNUP_REQUESTS_SUCCESS,
    payload: response,
});

export const getSignupRequestsFailed = (error) => ({
    type: GET_SIGNUP_REQUESTS_FAILED,
    payload: error,
});

export const declineSignupRequest = (id, callbacks) => ({
    type: DECLINE_SIGNUP_REQUEST,
    payload: { id, callbacks },
});

export const declineSignupRequestSuccess = (response) => ({
    type: DECLINE_SIGNUP_REQUEST_SUCCESS,
    payload: response,
});

export const declineSignupRequestFailed = (error) => ({
    type: DECLINE_SIGNUP_REQUEST_FAILED,
    payload: error,
});


export const addExistCompanyToNetwork = (id, callbacks) => ({
    type: ADD_EXIST_COMPANY_TO_NETWORK,
    payload: { id, callbacks },
});

export const addExistCompanyToNetworkSuccess = (response) => ({
    type: ADD_EXIST_COMPANY_TO_NETWORK_SUCCESS,
    payload: response,
});

export const addExistCompanyToNetworkFailed = (error) => ({
    type: ADD_EXIST_COMPANY_TO_NETWORK_FAILED,
    payload: error,
});


export const addExistAcademicToNetwork = (id, callbacks) => ({
    type: ADD_EXIST_ACADEMIC_TO_NETWORK,
    payload: { id, callbacks },
});

export const addExistAcademicToNetworkSuccess = (response) => ({
    type: ADD_EXIST_ACADEMIC_TO_NETWORK_SUCCESS,
    payload: response,
});

export const addExistAcademicToNetworkFailed = (error) => ({
    type: ADD_EXIST_ACADEMIC_TO_NETWORK_FAILED,
    payload: error,
});