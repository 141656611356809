// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { DatePickerWrapperStyles, DatePickerWrapperDarkStyles } from '../../../../../helpers/DatePickerFormat';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input
} from 'reactstrap';
import Loader from '../../../../../components/Loader';
import { getCurrentLanguage, getLocalizedString, timeFromDateString } from '../../../../../helpers/utils';
import { getAllCompanies, getAPICountryInfo, getCountryInfo, getProfile, searchVerifier, createValidation, editValidation } from '../../../../../redux/actions';
import { isDarkMode } from '../../../../../helpers/authUtils';
import { Search } from 'react-feather';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import ReactPaginate from 'react-paginate';
import { param } from 'jquery';
import SimpleCheckbox from '../../../Supports/SimpleCheckbox';

class RequestValidationModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.props.getCountryInfo();

        this.state = {
            loading: false,
            error: null,
            size: 'lg',
            source: 0,
            selectedVerifier: null,
            fullName: '',
            email: '',
            country: 'fr',
            phone: '+33',
            description: '',

            keyword: '',
            verifiers: null,
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.editingValidation) {
            let params = {};
            params.editingValidation = this.props.editingValidation;
            params.description = this.props.editingValidation.description ?? '';

            if (this.props.editingValidation.referenceUserId) {
                params.source = 0
                params.referenceUserId = this.props.editingValidation.referenceUserId;

                this.setState({ selectedVerifier: this.props.editingValidation.referenceUserId }, () => {
                    if (this.props.editingValidation.referenceUserName.length > 0) {
                        let words = this.props.editingValidation.referenceUserName.split(' ');
                        if (words?.length > 0) {
                            this.searchVerifier(words[0]);
                        }
                    }
                })
            }
            else {
                params.source = 1
                params.fullName = this.props.editingValidation.referenceUserName ?? '';
                params.email = this.props.editingValidation.referenceUserEmail ?? '';
                params.phone = this.props.editingValidation.referenceUserPhone ?? '+33';
            }

            this.setState(params);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    sourceOptions = () => {
        const { t } = this.props;
        return [
            { value: 0, label: t('validation.peopleInside') },
            { value: 1, label: t('validation.peopleOutside') },
        ]
    }

    searchVerifier = (keyword) => {
        if (keyword != this.state.keyword) {
            let params = { keyword: keyword };
            if (keyword.length == 0) {
                params.selectedVerifier = null;
            }

            this.setState(params, () => {
                if (keyword.length > 0) {
                    this.props.searchVerifier(keyword, 0, {
                        callbackOnSuccess: (response) => {
                            if (response.keyword == this.state.keyword) {
                                this.setState({ verifiers: response.results });
                            }
                        }
                    })
                }
            })
        }
    }

    handlePageClick = (page) => {
        this.props.searchVerifier(this.state.keyword, page, {
            callbackOnSuccess: (response) => {
                if (response.keyword == this.state.keyword) {
                    this.setState({ verifiers: response.results });
                }
            }
        })
    }

    requestValidation = () => {
        let requestable = this.props.editingValidation != null || (this.props.documentType != null && this.props.documentId != null)

        if (!requestable) {
            return
        }

        let params = {};
        params.documentType = this.props.documentType;
        params.documentId = this.props.documentId;
        if (this.props.documentSubId) {
            params.documentSubId = this.props.documentSubId;
        }

        if (this.state.source == 0) {
            params.referenceUserId = this.state.selectedVerifier;
        }
        else {
            params.referenceUserName = this.state.fullName;
            params.referenceUserEmail = this.state.email;
            params.referenceUserPhone = this.state.phone;
        }

        if (this.state.description.length > 0) {
            params.description = this.state.description;
        }

        if (this.props.editingValidation) {
            this.props.editValidation(this.props.editingValidation.id, params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    const message = 'You have requested a validation.';
                    this.props.close(message);
                },
            })
        }
        else {
            this.props.createValidation(params, {
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    // this.setState({ loading: false, error: error });
                    const message = 'You cannot request a validation.';
                    this.props.false(message);
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    const message = 'You have requested a validation.';
                    this.props.success();
                },
            })
        }
    }


    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            if (error.response.status == 400) {
                message = t('You have already sent a validation request to this person');
            }

            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const isDark = isDarkMode();

        let isCanSave = false;
        if (this.state.source == 0) {
            isCanSave = this.state.selectedVerifier != null;
        }
        else {
            isCanSave = this.state.fullName.length > 0 && this.state.email.length > 0 && this.state.country.length > 0 && this.state.phone.length > 0;
        }

        const sources = this.sourceOptions();

        const vers = this.state.verifiers?.content ?? [];
        let totalPages = this.state.verifiers?.pageable?.totalPages ?? 0;

        const selectedUserId = this.state.selectedVerifier ?? '';

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{this.props.title}</Label>
                                    </CardHeader>
                                    <CardBody>

                                        <AvForm onValidSubmit={this.requestValidation} className="authentication-form">
                                            <Row className='align-horizontal mb-2'>
                                                {this.state.loading && <Loader />}
                                                <Col xl={5}>
                                                    <Label for="source">{t("Request validation from")}</Label>
                                                </Col>
                                                <Col xl={7}>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder={t('placeholder.Select')}
                                                        value={sources.find(op => {
                                                            return op.value == this.state.source
                                                        })}
                                                        options={sources}
                                                        onChange={(value, data, event, formattedValue) => {
                                                            this.setState({ source: value.value })
                                                        }}

                                                    ></Select>
                                                </Col>
                                            </Row>

                                            {(this.state.source == 0) ?
                                                <div>
                                                    <Row className={'align-horizontal' + (totalPages > 1 ? ' mb-2' : '')}>
                                                        {this.state.loading && <Loader />}
                                                        <Col xl={5}>
                                                            <Label for="source">{t("Choose Verifier")}</Label>
                                                        </Col>
                                                        <Col xl={7}>
                                                            <div className="width-percent-100 float-sm-right mt-3 mt-sm-0">
                                                                <div className="width-percent-100 task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                                                    <form>
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control search-input rounded-5"
                                                                                placeholder="Search..."
                                                                                onChange={(e) => {
                                                                                    this.searchVerifier(e.target.value);
                                                                                }}
                                                                            />
                                                                            <span className="uil uil-search icon-search"></span>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {vers.length > 0 &&
                                                        <Row className='mt-2'>
                                                            <Col xl={12}>
                                                                <div className="align-vertical rounded-5 border-1 scroll-enable" style={{ minHeight: '60px', maxHeight: 'calc(80vh - 300px)' }}>
                                                                    {vers.map((user, idx) => {
                                                                        return (
                                                                            <div className={'align-horizontal mt-2 ml-4' + (idx == vers.length - 1 ? ' mb-2' : '')}>
                                                                                <SimpleCheckbox className='cursor-pointer mr-3' checked={selectedUserId.length > 0 ? selectedUserId == user.id : false} didChange={() => { this.setState({ selectedVerifier: user.id }) }} />
                                                                                <Label className='cursor-pointer' onClick={() => { this.setState({ selectedVerifier: user.id }) }}>{user.firstName + ' ' + user.lastName}</Label>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                                {totalPages > 0 &&
                                                                    <div className='float-right mt-2'>
                                                                        <ReactPaginate
                                                                            previousLabel="Previous"
                                                                            nextLabel="Next"
                                                                            pageClassName="page-item"
                                                                            pageLinkClassName="page-link"
                                                                            previousClassName="page-item"
                                                                            previousLinkClassName="page-link"
                                                                            nextClassName="page-item"
                                                                            nextLinkClassName="page-link"
                                                                            breakLabel="..."
                                                                            breakClassName="page-item"
                                                                            breakLinkClassName="page-link"
                                                                            pageCount={totalPages}
                                                                            marginPagesDisplayed={2}
                                                                            pageRangeDisplayed={5}
                                                                            onPageChange={(e) => { this.handlePageClick(e.selected) }}
                                                                            containerClassName="pagination"
                                                                            activeClassName="active"
                                                                        // forcePage={pageOffset}
                                                                        />
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                                :
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='align-horizontal mb-2'>
                                                            <Col xl={5}>
                                                                <Label for="name">{t("validation.fullname")}</Label>
                                                            </Col>
                                                            <Col xl={7}>
                                                                <AvInput
                                                                    autoComplete="off"
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                        this.setState({ fullName: data })
                                                                    }}
                                                                    type="text" name="about" id="about" placeholder={t("validation.fullname")}
                                                                    value={this.state.fullName ?? ''}
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <Row className='align-horizontal mb-2'>
                                                            <Col xl={5}>
                                                                <Label for="name">{t("signUp.email")}</Label>
                                                            </Col>
                                                            <Col xl={7}>
                                                                <AvInput
                                                                    autoComplete="off"
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                        this.setState({ email: data })
                                                                    }}
                                                                    type="text" name="about" id="about" placeholder={t("signUp.email")}
                                                                    value={this.state.email ?? ''}
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <Row className='align-horizontal mb-2'>
                                                            <Col xl={5}>
                                                                <Label for="name">{t("policy.mobilePhone")}</Label>
                                                            </Col>
                                                            <Col xl={7}>
                                                                <PhoneInput
                                                                    className='width-percent-100'
                                                                    containerClass={"phone-input-custom"}
                                                                    country={this.state.country.toLowerCase()}
                                                                    value={this.props.editingValidation?.referenceUserPhone ?? ''}
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                        let phone = '';
                                                                        if (data?.dialCode) {
                                                                            phone = '+' + data.dialCode + value.slice(data.dialCode.length);
                                                                        }
                                                                        this.setState({ country: data.dialCode, phone: phone })
                                                                    }}
                                                                />
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>}
                                            <hr />

                                            <Row>
                                                <Col xl={12}>
                                                    <AvGroup className="">
                                                        <Label for="about">{t("personalInfo.aboutMe")}</Label>

                                                        <InputGroup className='input-container'>
                                                            <AvInput
                                                                autoComplete="off"
                                                                onChange={(value, data, event, formattedValue) => {
                                                                    this.setState({ description: data })
                                                                }}
                                                                type="text" name="about" id="about" placeholder={t("personalInfo.saySomethingAboutYou")}
                                                                value={this.state.description ?? ''}
                                                            />
                                                        </InputGroup>
                                                    </AvGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup className="form-group mt-2 mb-0 text-center button-list">
                                                <Button color="white" onClick={() => {
                                                    this.props.close(null);
                                                }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                <Button disabled={!isCanSave} color="primary"><i className='uil'></i>{t("confirm.modifyEmail1")}</Button>
                                                <Button disabled={!isCanSave} color="primary"><i className='uil'></i>{t("nexions.Request")}</Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { profile, companies, loading, error } = state.Profile;
    const { country, countries } = state.Auth;
    return { profile, companies, country, countries, loading, error };
};

const mapDispatchToProps = {
    getAllCompanies,
    getProfile,
    getCountryInfo, getAPICountryInfo,
    searchVerifier,
    createValidation,
    editValidation
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(RequestValidationModal));