// @flow
import React,{Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {Dropdown,DropdownMenu,DropdownToggle,UncontrolledTooltip} from 'reactstrap';
import {getAvailableLanguages,setCurrentLanguage} from '../../helpers/utils';
import {i18n} from '../../translations/i18n';
import frFlag from './flags/french.jpg';
import germanyFlag from './flags/germany.jpg';
import italyFlag from './flags/italy.jpg';
import russiaFlag from './flags/russia.jpg';
import spainFlag from './flags/spain.jpg';
import enFlag from './flags/us.jpg';



class LanguageDropdown extends Component {
    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);

        this.state = {
            dropdownOpen: false,
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    onChooseLanguage = (lang) => {
        i18n.changeLanguage(lang.id);
        setCurrentLanguage(lang.id);
    }

    flagForLanguage = (id) => {
        if (id === 'en'){
            return enFlag;
        }
        else if (id === 'fr'){
            return frFlag;
        }
        else if (id === 'de'){
            return germanyFlag;
        }
        else if (id === 'it'){
            return italyFlag;
        }
        else if (id === 'es'){
            return spainFlag;
        }
        else if (id === 'ru'){
            return russiaFlag;
        }
    }

    render() {
        const tag = this.props.tag || "div";
        const currentLanguage = i18n.language;
        const languages = getAvailableLanguages();
        const { t } = this.props;
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="d-none d-lg-block" tag={tag} id="langDropdown">
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="a"
                        className="nav-link mr-0 box-center"
                        onClick={this.toggleDropdown}
                        aria-expanded={this.state.dropdownOpen}>
                        <label className='dropdown-active-color mt-0'>{currentLanguage.toUpperCase()}</label>
                    </DropdownToggle>
                    <DropdownMenu right className="">
                        <div onClick={this.toggleDropdown}>
                            {languages.map((lang, i) => {
                                return (
                                    <div onClick={() => {
                                        this.onChooseLanguage(lang);
                                    }} className="dropdown-item notify-item" key={i + '-lang'}>
                                        <span className="align-middle">{lang.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </DropdownMenu>
                </Dropdown>

                <UncontrolledTooltip placement="left" target="langDropdown">{t("index.changeLanguage")}</UncontrolledTooltip>
            </React.Fragment>
        );
    }
}

export default withTranslation('translations')(LanguageDropdown);
