// @flow
import {
    GET_REPOPRODUCTS,GET_REPOPRODUCTS_FAILED,GET_REPOPRODUCTS_SUCCESS
} from './constants';

const INIT_STATE = {
    repoproducts: [],
    currencies: [],
    loading: false,
    error: null
};

const RepoProducts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPOPRODUCTS:
            return { ...state, loading: true };
        case GET_REPOPRODUCTS_SUCCESS:
            return { ...state, repoproducts: action.payload.products, currencies: action.payload.currencies, loading: false, error: null };
        case GET_REPOPRODUCTS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RepoProducts;
