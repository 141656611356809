// @flow
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import {
    Button, Card, CardBody, Col, Label, Row, Table
} from 'reactstrap';
import {
    getAcademicsInCountry
} from '../../../../redux/actions';
// Include its styles in you build process as well
import "react-d3-treemap/dist/react.d3.treemap.css";
import { getTheme, getSidebarWidth, getLoggedInUser } from '../../../../helpers/authUtils';
import * as layoutConstants from '../../../../constants/layout';
import { findItemWithId, initHomeMenuItems } from '../../Settings/Profile/SettingsMenuUtils';
import { getLocalizedString } from '../../../../helpers/utils';
import { format } from '../../../../helpers/stringFormat';
var CountryLanguage = require('country-language');

class EducationDetails extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            details: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains("sidebar-enable"))
                    document.body.classList.remove("sidebar-enable");
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains("left-side-menu-condensed"))
                    document.body.classList.remove("left-side-menu-condensed");
                document.body.classList.add("sidebar-enable");
            }
        }

        if (this.props.editingAcademy?.country) {
            this.props.getAcademicsInCountry(this.props.editingAcademy?.country, {})
        }
    }

    componentDidUpdate = prevProps => {
        // if (prevProps.location != this.props.location || prevProps.menuHierarchy != this.props.menuHierarchy) {
        //     this.loadDetails();
        // }
        // if (prevProps.menuHierarchy != this.props.menuHierarchy) {
        //     this.setCareerCompanies(this.props.subscriptionProfile);
        // }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    educationDetails = () => {
        const { t, countries, facultyDoc, graduateDegreeDoc, academics, editingAcademy, editingPlace, academicsInCountry } = this.props;

        let details = {};
        let editingDegrees = {};

        if (editingAcademy != null && editingPlace != null) {
            let academicsEntity = academicsInCountry?.map((element, i) => {
                return {
                    value: element.id,
                    label: element.name,
                    cities: element.cities ? element.cities : null,
                    faculties: element.faculties ? element.faculties : null,
                    entity: element,
                    nodeId: element.nodeId ?? null
                };
            }) ?? [];

            const selectedAcademicsEntity = editingAcademy.academyId != null ? academicsEntity?.find(op => { return op.value === editingAcademy.academyId }) : null;
            const citiesDocument = selectedAcademicsEntity?.cities;
            let facultiesDocument = selectedAcademicsEntity?.faculties;

            const typeOfFaculties = facultyDoc?.map((element, i) => {
                return {
                    value: element?.order?.toString(),
                    label: getLocalizedString(element?.value),
                    specialties: element?.specialties,
                    faculty: element ?? null
                };
            });

            if (editingAcademy.academyId) {
                details[t('Academy')] = (editingAcademy.academyId != null && academicsEntity?.length > 0) ? academicsEntity.find(op => { return op.value === editingAcademy.academyId })?.label : editingAcademy.academyId;
            }

            let country = countries?.find((country) => { return country.value == (editingAcademy.country ?? '') });
            let statesOfCountry = country?.states;
            let state = editingPlace.state != null ? statesOfCountry?.find((e) => e.id === editingPlace.state) : null;
            let stateName = getLocalizedString(state?.name);
            let cityName = '';
            if (editingPlace?.cityId != null && editingAcademy?.academyDetail?.cities?.length > 0) {
                cityName = editingAcademy?.academyDetail?.cities.find((city) => { return city.id == editingPlace?.cityId })?.city;
            }
            else {
                cityName = editingPlace.academyNewCityIntl;
            }

            details[t('personalInfo.country')] = country?.label ?? '';
            if (editingPlace.state) {
                details[t('personalInfo.state')] = stateName;
            }
            details[t('personalInfo.city')] = cityName;

            if (editingAcademy.academyNewIntlCode) {
                var allLanguageCodes = CountryLanguage.getLanguages();
                const languages = allLanguageCodes?.map((element, i) => {
                    return {
                        value: element.iso639_2en,
                        label: element.name?.length > 0 ? element.name[0] : '',
                        language: element
                    };
                });
                details[t('experience.Language')] = (editingAcademy.academyNewIntlCode != null && languages?.length > 0) ? languages.find(op => { return op.value === editingAcademy.academyNewIntlCode })?.label ?? '' : editingAcademy.academyNewIntlCode;
            }

            if (editingPlace.facultyId) {
                details[t('Faculty')] = (editingPlace.facultyId.toString() != null && typeOfFaculties?.length > 0) ? typeOfFaculties.find(op => { return op.value === editingPlace.facultyId.toString() })?.label ?? '' : '';
            }

            details[t('career.monthStart')] = editingPlace.monthStart ?? '';
            if (editingPlace.monthEnd) {
                details[t('career.monthEnd')] = editingPlace.monthEnd ?? '';
            }


            if (editingPlace.graduateDegrees?.length > 0) {

                let facultySelected = editingPlace.facultyDetail;
                if (facultySelected == null && editingPlace.facultyId != null && editingAcademy.academyDetail?.faculties?.length > 0) {
                    facultySelected = editingAcademy.academyDetail?.faculties?.find((f) => { return f.facultyId == editingPlace.facultyId })?.facultyDetail;
                }

                var arrGraduateDegree = [];
                if (facultySelected?.graduateDegrees != null) {
                    for (let index = 0; index < facultySelected.graduateDegrees.length; index++) {
                        const element = facultySelected.graduateDegrees[index];
                        for (let indexJ = 0; indexJ < graduateDegreeDoc.length; indexJ++) {
                            if (graduateDegreeDoc[indexJ].order === element) {
                                arrGraduateDegree.push(graduateDegreeDoc[indexJ]);
                            }
                        }
                    }
                }

                const graduateDoc = arrGraduateDegree?.map((element, i) => {
                    return {
                        value: element.order?.toString(),
                        label: getLocalizedString(element.value),
                        states: element.specialties ? element.specialties : null,
                        graduateSelected: element
                    };
                });

                const graduateSpecialtiesDoc = facultySelected?.specialties?.map((element, i) => {
                    return {
                        value: element.order?.toString(),
                        label: getLocalizedString(element.value),
                        special: element.specialties ? element.specialties : null,
                    };
                });

                (editingPlace.graduateDegrees ?? []).forEach(graduateDegree => {
                    let degree = (graduateDegree.graduateDegreeId != null && graduateDoc?.length > 0) ? graduateDoc.find(op => { return op.value == graduateDegree.graduateDegreeId })?.label ?? '' : '';
                    let specialty = (graduateDegree.facultySpecialtyId != null && graduateSpecialtiesDoc?.length > 0) ? graduateSpecialtiesDoc.find(op => { return op.value == graduateDegree.facultySpecialtyId })?.label ?? '' : '';

                    let dict = {};
                    dict[t('academic.graduateDegree')] = degree;
                    if (graduateDegree.graduateDiplomaInt?.length > 0) {
                        dict[t('academic.graduateDegree')] = graduateDegree.graduateDiplomaInt + (degree.length > 0 ? (" (" + degree + ')') : '');
                    }
                    dict[t('academic.graduationSpecialty')] = specialty;
                    if (graduateDegree.graduationSpecialtyInt?.length > 0) {
                        dict[t('academic.graduationSpecialty')] = graduateDegree.graduationSpecialtyInt + (specialty.length > 0 ? (" (" + specialty + ')') : '');
                    }
                    dict[t('academic.dateOfDegree')] = (graduateDegree?.underPreparation ?? false) ? t('academic.underPreparation') : (graduateDegree.dateDegree ?? '');

                    editingDegrees[degree] = dict;
                });
            }
        }

        return [details, editingDegrees ?? {}];
    }

    render() {
        const { t, countries, facultyDoc, graduateDegreeDoc, academics, editingAcademy, editingPlace } = this.props;

        let educationDetails = this.educationDetails();
        let details = educationDetails[0];
        let graduateDegrees = educationDetails[1];

        return (
            <React.Fragment>
                <Card className="application-settings">
                    <CardBody>
                        <Col xl={12}>
                            <Table className="mb-0" striped>
                                {/* <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Details</th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    {Object.keys(details).length > 0 && Object.keys(details).map((key, index) => {
                                        return (
                                            <tr key={key}>
                                                <th style={{ 'width': '35%' }} scope="row">{key}</th>
                                                <td style={{ 'width': '65%' }}>{details[key]}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>

                        <br />
                        <Col xl={12} className='mt-4'>
                            <Label className='ml-2'>{t('academy.graduateDegrees')}</Label>

                            {Object.keys(graduateDegrees).length > 0 && Object.keys(graduateDegrees).map((degree, idx) => {
                                let graduateDegree = graduateDegrees[degree];
                                return (
                                    <Table className="mb-3" striped>
                                        <thead>
                                            <tr>
                                                <th style={{ 'width': '35%' }}>{degree}</th>
                                                <th style={{ 'width': '65%' }}>{''}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(graduateDegree).length > 0 && Object.keys(graduateDegree).map((k, idx) => {
                                                return (
                                                    <tr key={k}>
                                                        <th style={{ 'width': '35%' }} scope="row">{k}</th>
                                                        <td style={{ 'width': '65%' }}>{graduateDegree[k]}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                )
                            })}
                        </Col>

                        <br />
                        <div className="buttons-wrapper mt-4 align-horizontal">
                            <Row className='width-px-500 space-between mt-4 align-horizontal'>
                                <Button color="primary" onClick={() => {this.props.onClose(false)}}><i className='uil'></i>{t("modal.close")}</Button>
                            </Row>
                        </div>
                    </CardBody>


                </Card>
            </React.Fragment>
        );
    };
}


const mapStateToProps = (state) => {
    const { academicsInCountry, loading, error } = state.Academics;
    return { academicsInCountry, loading, error };
};

const mapDispatchToProps = {
    getAcademicsInCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(EducationDetails));
