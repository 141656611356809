// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {BASE_URL} from './../constants';
import {
    getRepoProfilesFailed,getRepoProfilesSuccess
} from './actions';
import {
    GET_REPOPROFILES
} from './constants';

function* getRepoProfilesInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/localize/repo-profiles', options);
        yield put(getRepoProfilesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getRepoProfilesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }

    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetRepoProfiles() {
    yield takeEvery(GET_REPOPROFILES, getRepoProfilesInternal);
}

function* repoprofilesSaga() {
    yield all([fork(watchGetRepoProfiles)]);
}

export default repoprofilesSaga;
