/* APP MENU */
export const INIT_MENU = 'INIT_MENU';
export const INIT_MENU_SUBCRIPTION = 'INIT_MENU_SUBCRIPTION';
export const INIT_MENU_WITH_MENU_ITEMS = 'INIT_MENU_WITH_MENU_ITEMS';
export const INIT_MENU_SUCCESS = 'INIT_MENU_SUCCESS';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION = 'CHANGE_ACTIVE_MENU_FROM_LOCATION';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS = 'CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS';
export const CHANGE_ACTIVE_MENU_WITH_MENU_ID = 'CHANGE_ACTIVE_MENU_WITH_MENU_ID';
export const CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS = 'CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS';
export const CHANGE_MENU_TYPE = 'CHANGE_LEFT_MENU_TYPE';
export const CHANGE_MENU_TYPE_SUCCESS = 'CHANGE_LEFT_MENU_TYPE_SUCCESS';

/* APP MENU TYPE */
export const MENU_TYPE_NONE = -1;
export const MENU_TYPE_HOME = 0;
export const MENU_TYPE_SETTINGS = 1;
export const MENU_TYPE_ISSUE = 2;
export const MENU_TYPE_REPOSITORIES = 3;
export const MENU_TYPE_CONEXIONS = 4;
export const MENU_TYPE_SEARCH = 5;