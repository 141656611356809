// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    PlusSquare,
    Share2, Trash,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Col,
    Label,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    changeTheme,
    getAcademicFellows,
    createContact
} from '../../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth } from '../../../../../helpers/authUtils';
import * as layoutConstants from '../../../../../constants/layout';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import { format } from '../../../../../helpers/stringFormat';

class NetworkAcademicsMember extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            userFellows: null
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());

        this.getMembers();
    }

    componentDidUpdate = prevProps => {
        if (prevProps.academyId !== this.props.academyId) {
            this.getMembers();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        this.props.history.push({ pathname: '/network-person' });
    }

    getMembers = () => {
        if (this.props.academyId != null) {
            this.props.getAcademicFellows(this.props.academyId, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ userFellows: response });
                },
            });
        }
    };

    closeAddGoalModal = () => {
        this.setState({
            showAddGoalModal: false,
            editingGoal: null
        })
    }

    generalModule = () => {
        return (
            <Row>
                <Col>
                    <Label className='ml-4'>{'No information'}</Label>
                </Col>
            </Row>
        )
    }

    memberSummary = (member) => {
        let summary = member?.firstName ?? '';
        if (member?.lastName?.length > 0) {
            summary += (summary.length > 0 ? ' ' : '') + member?.lastName;
        }
        // if (member?.mobileNumber?.length > 0) {
        //     summary += (summary.length > 0 ? ', ' : '') + member?.mobileNumber;
        // }
        if (member.email?.length > 0) {
            summary += (summary.length > 0 ? ', ' : '') + member.email;
        }
        else if (member.security?.email?.length > 0) {
            summary += (summary.length > 0 ? ', ' : '') + member.security?.email;
        }

        return summary;
    }

    membersModule = (userFellows) => {
        if (userFellows == null || userFellows.length == 0) {
            return this.generalModule();
        }

        let { t } = this.props;
        return (
            <Table className="mb-0" striped>
                <tbody>
                    {userFellows && userFellows.map((member, idx) => {
                        let summary = this.memberSummary(member);
                        return (
                            <tr key={summary}>
                                <td className='align-horizontal'>
                                    <Label className='mt-0 mb-0'>{summary}</Label>
                                    {!(member.inNetwork ?? true) &&
                                        <div className='align-horizontal ml-3'>
                                            <PlusSquare className="icon-dual icon-size-20" id="add-network-person"
                                                onClick={() => {
                                                    this.addContact(member.id);
                                                }} />
                                            <UncontrolledTooltip placement="top" id='tooltip-1' target='add-network-person'>
                                                {format(t('general.add0'), t('home.network'))}
                                            </UncontrolledTooltip>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    }, this)}
                </tbody>
            </Table>
        )
    }

    addContact = (userId) => {
        if (userId != null) {
            this.props.createContact({ internalUserId: userId }, {
                callbackOnBegin: () => {
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.getMembers();
                },
            });
        }
    }

    render() {
        let module = this.state.userFellows ? this.membersModule : this.generalModule;

        return (
            <React.Fragment>
                {module(this.state.userFellows)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, error } = state.Company;
    return { loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAcademicFellows,
    createContact
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademicsMember));
