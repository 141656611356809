// @flow
import { getLoggedInUserProfile } from '../../helpers/authUtils';
import {
    CHANGE_EMAIL, CHANGE_EMAIL_FAILED, CHANGE_EMAIL_SUCCESS, CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_SUCCESS, CHECK_EMAILVERIFICATIONSTATUS, CHECK_EMAILVERIFICATIONSTATUS_FAILED, CHECK_EMAILVERIFICATIONSTATUS_SUCCESS, CREATE_SECURITY, CREATE_SECURITY_FAILED, CREATE_SECURITY_SUCCESS, DELETE_CVS, DELETE_CVS_FAILED, DELETE_CVS_SUCCESS, DELETE_EMPLOYEE, DELETE_EMPLOYEE_FAILED, DELETE_EMPLOYEE_SUCCESS, DELETE_PHOTOS, DELETE_PHOTOS_FAILED, DELETE_PHOTOS_SUCCESS, GET_ALL_COMPANY, GET_ALL_COMPANY_FAILED, GET_ALL_COMPANY_SUCCESS, GET_CVS, GET_CVS_FAILED, GET_CVS_SUCCESS, GET_EMPLOYEE, GET_EMPLOYEE_FAILED, GET_EMPLOYEE_SUCCESS, GET_PHOTOS, GET_PHOTOS_FAILED, GET_PHOTOS_SUCCESS, GET_PROFILE, GET_PROFILE_FAILED, GET_PROFILE_SUCCESS,
    PROFILE_VISUAL_SUMMARY, PROFILE_VISUAL_SUMMARY_FAILED, PROFILE_VISUAL_SUMMARY_SUCCESS,
    REGISTER_EMPLOYEE, REGISTER_EMPLOYEE_FAILED, REGISTER_EMPLOYEE_SUCCESS, RESEND_VERIFICATION_EMAIL, RESEND_VERIFICATION_EMAIL_FAILED, RESEND_VERIFICATION_EMAIL_SUCCESS, SEARCH_EMPLOYEE, SEARCH_EMPLOYEE_FAILED, SEARCH_EMPLOYEE_SUCCESS, UPDATE_PHONENUMBER, UPDATE_PHONENUMBER_FAILED, UPDATE_PHONENUMBER_SUCCESS, UPDATE_PROFILE, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_SUCCESS, UPDATE_SECURITY, UPDATE_SECURITY_FAILED, UPDATE_SECURITY_SUCCESS, UPLOAD_CV, UPLOAD_CV_FAILED, UPLOAD_CV_SUCCESS, UPLOAD_PHOTO, UPLOAD_PHOTO_FAILED, UPLOAD_PHOTO_SUCCESS,
    PROFILE_SUMMARY_DATA_CHART, PROFILE_SUMMARY_DATA_CHART_SUCCESS,
    PROFILE_SUMMARY_DATA_CHART_FAILED,
    GET_PERSONAL_SUBCRIPTION, GET_PERSONAL_SUBCRIPTION_SUCCESS, GET_PERSONAL_SUBCRIPTION_FAILED,
    GET_SUBCRIPTION_PROFILE, GET_SUBCRIPTION_PROFILE_SUCCESS, GET_SUBCRIPTION_PROFILE_FAILED,
    GET_MENU_HIERARCHY, GET_MENU_HIERARCHY_SUCCESS, GET_MENU_HIERARCHY_FAILED,
    GET_DATA_EXPERIENCE, GET_DATA_EXPERIENCE_SUCCESS, GET_DATA_EXPERIENCE_FAILED,
    GET_DATA_EXPERIENCE_BY_ID, GET_DATA_EXPERIENCE_BY_ID_SUCCESS, GET_DATA_EXPERIENCE_BY_ID_FAILED,
    CREATE_EXPERIENCE, CREATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_FAILED,
    EDIT_EXPERIENCE, EDIT_EXPERIENCE_SUCCESS, EDIT_EXPERIENCE_FAILED,
    DELETE_EXPERIENCE, DELETE_EXPERIENCE_SUCCESS, DELETE_EXPERIENCE_FAILED,
    GET_ACTION_GROUPS, GET_ACTION_GROUPS_SUCCESS, GET_ACTION_GROUPS_FAILED,
    GET_RELATION_ACTION, GET_RELATION_ACTION_SUCCESS, GET_RELATION_ACTION_FAILED,
    GET_GOALS_BY_USER, GET_GOALS_BY_USER_SUCCESS, GET_GOALS_BY_USER_FAILED,
    GET_ACTIONS_BY_USER, GET_ACTIONS_BY_USER_SUCCESS, GET_ACTIONS_BY_USER_FAILED,
    GET_ALERTS, GET_ALERTS_SUCCESS, GET_ALERTS_FAILED,
    GET_PUBLIC_PROFILE, GET_PUBLIC_PROFILE_SUCCESS, GET_PUBLIC_PROFILE_FAILED,
    GET_GOALS_ACTIONS_BY_USER, GET_GOALS_ACTIONS_BY_USER_SUCCESS, GET_GOALS_ACTIONS_BY_USER_FAILED,
    CREATE_ACADEMY_SECURITY, CREATE_ACADEMY_SECURITY_SUCCESS, CREATE_ACADEMY_SECURITY_FAILED,
    UPDATE_ACADEMY_SECURITY, UPDATE_ACADEMY_SECURITY_SUCCESS, UPDATE_ACADEMY_SECURITY_FAILED,
    SWITCH_USER, SWITCH_USER_SUCCESS, SWITCH_USER_FAILED, GET_FAILED_PAYMENT, GET_FAILED_PAYMENT_SUCCESS, GET_FAILED_PAYMENT_FAILED, PAY_FAILED_PAYMENT, PAY_FAILED_PAYMENT_SUCCESS, PAY_FAILED_PAYMENT_FAILED, SYNCHRONIZE_PAYMENT, SYNCHRONIZE_PAYMENT_SUCCESS, SYNCHRONIZE_PAYMENT_FAILED,
    GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED

} from './constants';

import {
    EDIT_CAREER,
    EDIT_CAREER_SUCCESS,
    EDIT_CAREER_FAILED,
    DELETE_EMAIL_COMPANY, DELETE_EMAIL_COMPANY_SUCCESS, DELETE_EMAIL_COMPANY_FAILED,
    SET_COMPANY_ADMIN, SET_COMPANY_ADMIN_SUCCESS, SET_COMPANY_ADMIN_FAILED
} from '../company/constants';

import {
    CREATE_ACADEMIC, CREATE_ACADEMIC_FAILED, CREATE_ACADEMIC_SUCCESS,
    DELETE_ACADEMIC, DELETE_ACADEMIC_FAILED, DELETE_ACADEMIC_SUCCESS,
} from '../academics/constants';

import {
    CREATE_EDUCATION, CREATE_EDUCATION_SUCCESS, CREATE_EDUCATION_FAILED,
    EDIT_EDUCATION, EDIT_EDUCATION_SUCCESS, EDIT_EDUCATION_FAILED,
    DELETE_EDUCATION, DELETE_EDUCATION_SUCCESS, DELETE_EDUCATION_FAILED,
} from '../network/constants';

import {
    CREATE_VALIDATION, CREATE_VALIDATION_SUCCESS, CREATE_VALIDATION_FAILED,
    EDIT_VALIDATION, EDIT_VALIDATION_SUCCESS, EDIT_VALIDATION_FAILED, VALIDATION_DOCUMENT_EDUCATION, DELETE_VALIDATION, DELETE_VALIDATION_SUCCESS, DELETE_VALIDATION_FAILED
} from '../validation/constants';


import {
    LOGOUT_USER,
} from '../auth/constants';


import moment from 'moment';


const INIT_STATE = {
    data: [],
    profile: getLoggedInUserProfile(),
    companies: [],
    visualSummaryOwnerId: null,
    visualSummary: null,
    summaryOwnerId: null,
    summaryDataChart: null,
    cvs: [],
    photos: [],
    loading: false,
    error: null,
    subscriptionProfile: null,
    menuHierarchy: {},
    dataExperienceOwnerId: null,
    dataExperience: null,
    //actionGroup: null,
    relationAction: null,
    goalsByUser: [],
    actionsByUser: [],
    goalsActionsByUser: null,
    alerts: null,
    publicProfile: null,
    failedPayments: [],
    dashboard: null
};

const Profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE:
            return { ...state, loading: true };
        case GET_EMPLOYEE_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case GET_EMPLOYEE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_EMPLOYEE:
            return { ...state, loading: true };
        case REGISTER_EMPLOYEE_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case REGISTER_EMPLOYEE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PROFILE:
            return { ...state, loading: true };
        case GET_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case GET_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case PROFILE_SUMMARY_DATA_CHART:
            return { ...state, summaryOwnerId: action.payload.userId, loading: true };
        case PROFILE_SUMMARY_DATA_CHART_SUCCESS:
            if (action.payload.userId == state.summaryOwnerId) {
                return { ...state, summaryDataChart: action.payload, loading: false, error: null };
            }
            else {
                return { ...state, loading: false, error: null };
            }
        case PROFILE_SUMMARY_DATA_CHART_FAILED:
            return { ...state, summaryDataChart: null, error: action.payload, loading: false };

        case GET_PROFILE:
            return { ...state, loading: true };
        case GET_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case GET_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_PROFILE:
            return { ...state, loading: true };
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case UPDATE_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_SECURITY:
            return { ...state, loading: true };
        case CREATE_SECURITY_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case CREATE_SECURITY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_SECURITY:
            return { ...state, loading: true };
        case UPDATE_SECURITY_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case UPDATE_SECURITY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPLOAD_CV:
            return { ...state, loading: true };
        case UPLOAD_CV_SUCCESS:
            return { ...state, loading: false, error: null };
        case UPLOAD_CV_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SEARCH_EMPLOYEE:
            return { ...state, data: [], loading: true };
        case SEARCH_EMPLOYEE_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case SEARCH_EMPLOYEE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EMPLOYEE:
            return { ...state, loading: true };
        case DELETE_EMPLOYEE_SUCCESS:
            {
                const deletedUserId = action.payload.profile.id;
                const newList = state.data.filter(e => e.id !== deletedUserId)
                return { ...state, data: newList, loading: false, error: null };
            }
        case DELETE_EMPLOYEE_FAILED:
            return { ...state, loading: false };

        case GET_ALL_COMPANY:
            return { ...state, loading: true };
        case GET_ALL_COMPANY_SUCCESS:
            return { ...state, companies: action.payload, loading: false, error: null };
        case GET_ALL_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case PROFILE_VISUAL_SUMMARY:
            return { ...state, visualSummaryOwnerId: action.payload.userId, loading: true };
        case PROFILE_VISUAL_SUMMARY_SUCCESS:
            if (state.visualSummaryOwnerId == action.payload.userId) {
                return { ...state, visualSummary: action.payload.data, loading: false, error: null };
            }
            else {
                return { ...state, loading: false, error: null };
            }
        case PROFILE_VISUAL_SUMMARY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_PHONENUMBER:
            return { ...state, loading: true };
        case UPDATE_PHONENUMBER_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case UPDATE_PHONENUMBER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CHECK_EMAILVERIFICATIONSTATUS:
            return { ...state, loading: false };
        case CHECK_EMAILVERIFICATIONSTATUS_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHECK_EMAILVERIFICATIONSTATUS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CHANGE_EMAIL:
            return { ...state, loading: true };
        case CHANGE_EMAIL_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case CHANGE_EMAIL_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RESEND_VERIFICATION_EMAIL:
            return { ...state, loading: true };
        case RESEND_VERIFICATION_EMAIL_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case RESEND_VERIFICATION_EMAIL_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CHANGE_PASSWORD:
            return { ...state, loading: true };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHANGE_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_CVS:
            return { ...state, loading: true };
        case GET_CVS_SUCCESS:
            return { ...state, cvs: action.payload, loading: false, error: null };
        case GET_CVS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_CVS:
            return { ...state, loading: true };
        case DELETE_CVS_SUCCESS:
            // Since there is only 1 cv, so we remove all cvs from state when deleted
            return { ...state, cvs: [], loading: false, error: null };
        case DELETE_CVS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PHOTOS:
            return { ...state, loading: true };
        case GET_PHOTOS_SUCCESS:
            return { ...state, photos: action.payload, loading: false, error: null };
        case GET_PHOTOS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_PHOTOS:
            return { ...state, loading: true };
        case DELETE_PHOTOS_SUCCESS:
            // Since there is only 1 photo, so we remove all photos from state when deleted
            return { ...state, photos: [], loading: false, error: null };
        case DELETE_PHOTOS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPLOAD_PHOTO:
            return { ...state, loading: true };
        case UPLOAD_PHOTO_SUCCESS:
            return { ...state, loading: false, error: null };
        case UPLOAD_PHOTO_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PERSONAL_SUBCRIPTION:
            return { ...state, loading: true };
        case GET_PERSONAL_SUBCRIPTION_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_PERSONAL_SUBCRIPTION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_SUBCRIPTION_PROFILE:
            return { ...state, loading: true };
        case GET_SUBCRIPTION_PROFILE_SUCCESS:
            {
                let profile = action.payload;
                if (profile?.careers != null) {
                    profile.careers = profile.careers.sort((a, b) => moment(a.career?.monthStart, 'MM/YYYY').valueOf() > moment(b.career?.monthStart, 'MM/YYYY').valueOf() ? 1 : -1);
                }
                return { ...state, subscriptionProfile: profile, loading: false, error: null };
            }
        case GET_SUBCRIPTION_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_MENU_HIERARCHY:
            return { ...state, loading: true };
        case GET_MENU_HIERARCHY_SUCCESS:
            return { ...state, menuHierarchy: action.payload, loading: false, error: null };
        case GET_MENU_HIERARCHY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACTION_GROUPS:
            return { ...state, loading: true };
        case GET_ACTION_GROUPS_SUCCESS:
            let menuHierarchy = state.menuHierarchy;
            menuHierarchy.actionGroup = action.payload;
            return { ...state, menuHierarchy: menuHierarchy, loading: false, error: null };
        case GET_ACTION_GROUPS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DATA_EXPERIENCE:
            return { ...state, dataExperienceOwnerId: action.payload.userId, dataExperience: null, loading: true };
        case GET_DATA_EXPERIENCE_SUCCESS:
            if (state.dataExperienceOwnerId == action.payload.userId) {
                return { ...state, dataExperience: action.payload.data, loading: false, error: null };
            }
            else {
                if (state.dataExperienceOwnerId == action.payload.userId) {
                    return { ...state, loading: false, error: null };
                }
            }
        case GET_DATA_EXPERIENCE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DATA_EXPERIENCE_BY_ID:
            return { ...state, loading: true };
        case GET_DATA_EXPERIENCE_BY_ID_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_DATA_EXPERIENCE_BY_ID_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_EXPERIENCE:
            return { ...state, loading: true };
        case CREATE_EXPERIENCE_SUCCESS:
            {
                let currentSP = state.subscriptionProfile;
                if (currentSP) {
                    let experiences = currentSP.experiences ?? [];
                    experiences.push(action.payload);
                    currentSP.experiences = experiences;
                }
                return { ...state, subscriptionProfile: currentSP, loading: false, error: null };
            }
        case CREATE_EXPERIENCE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_EXPERIENCE:
            return { ...state, loading: true };
        case EDIT_EXPERIENCE_SUCCESS:
            {
                let currentSP = state.subscriptionProfile;
                if (currentSP) {
                    let experiences = currentSP.experiences ?? [];
                    let index = experiences.findIndex((element) => { return element.id == action.payload.id });
                    if (index >= 0) {
                        experiences[index] = action.payload;
                    }
                    currentSP.experiences = experiences;
                }
                return { ...state, subscriptionProfile: currentSP, loading: false, error: null };
            }
        case EDIT_EXPERIENCE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EXPERIENCE:
            return { ...state, loading: true };
        case DELETE_EXPERIENCE_SUCCESS:
            let currentSP = state.subscriptionProfile;
            let deletedExperienceId = action.payload;
            if (currentSP?.experiences && deletedExperienceId) {
                let experiences = currentSP.experiences;
                let index = experiences.findIndex((element) => { return element?.id == deletedExperienceId })
                if (index >= 0) {
                    experiences.splice(index, 1);
                }
            }
            return { ...state, subscriptionProfile: currentSP, loading: false, error: null };
        case DELETE_EXPERIENCE_FAILED:
            return { ...state, error: action.payload, loading: false };

        // case GET_ACTION_GROUPS:
        //     return { ...state, actionGroup: null, loading: true };
        // case GET_ACTION_GROUPS_SUCCESS:
        //     return { ...state, actionGroup: action.payload, loading: false, error: null };
        // case GET_ACTION_GROUPS_FAILED:
        //     return { ...state, error: action.payload, loading: false };

        case GET_RELATION_ACTION:
            return { ...state, actionGroup: null, loading: true };
        case GET_RELATION_ACTION_SUCCESS:
            return { ...state, relationAction: action.payload, loading: false, error: null };
        case GET_RELATION_ACTION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_GOALS_BY_USER:
            return { ...state, loading: true };
        case GET_GOALS_BY_USER_SUCCESS:
            return { ...state, goalsByUser: action.payload ?? [], loading: false, error: null };
        case GET_GOALS_BY_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ACTIONS_BY_USER:
            return { ...state, loading: true };
        case GET_ACTIONS_BY_USER_SUCCESS:
            return { ...state, actionsByUser: action.payload ?? [], loading: false, error: null };
        case GET_ACTIONS_BY_USER_FAILED:
            return { ...state, error: action.payload, loading: false };


        case GET_GOALS_ACTIONS_BY_USER:
            return { ...state, loading: true };
        case GET_GOALS_ACTIONS_BY_USER_SUCCESS:
            return { ...state, goalsActionsByUser: action.payload ?? null, loading: false, error: null };
        case GET_GOALS_ACTIONS_BY_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_CAREER:
            return { ...state, loading: true };
        case EDIT_CAREER_SUCCESS:
            let current = state.subscriptionProfile;
            let editedCareer = action.payload;
            if (current?.careers && editedCareer) {
                let careers = current.careers;
                let index = careers.findIndex((element) => { return element?.career?.id == editedCareer.id })
                if (index >= 0) {
                    careers[index].career = editedCareer
                }
            }
            return { ...state, subscriptionProfile: current, loading: false, error: null };
        case EDIT_CAREER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EMAIL_COMPANY:
            return { ...state, loading: true };
        case DELETE_EMAIL_COMPANY_SUCCESS:
            {
                let current = state.subscriptionProfile;
                let email = action.payload;
                if (current?.security?.companies && email) {
                    let index = current?.security?.companies.findIndex((element) => { return element.email == email });
                    if (index >= 0) {
                        current.security.companies = current.security.companies.splice(index, 1);
                    }
                }
                return { ...state, subscriptionProfile: current, loading: false, error: null };
            }
        case DELETE_EMAIL_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SET_COMPANY_ADMIN:
            return { ...state, loading: true };
        case SET_COMPANY_ADMIN_SUCCESS:
            {
                let current = state.subscriptionProfile;
                if (current?.security) {
                    current.security.companies = action.payload?.companies;
                }
                return { ...state, subscriptionProfile: current, loading: false, error: null };
            }
        case SET_COMPANY_ADMIN_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALERTS:
            return { ...state };
        case GET_ALERTS_SUCCESS:
            {
                let current = state.alerts;
                if (current?.alerts?.length != action.payload.alerts?.length) {
                    current = action.payload;
                    return { ...state, alerts: current, error: null };
                }
                else if (current?.alerts?.length > 0 && current?.alerts[0].id != action.payload.alerts[0].id) {
                    current = action.payload;
                    return { ...state, alerts: current, error: null };
                }
                return { ...state, error: null };
            }
        case GET_ALERTS_FAILED:
            return { ...state, error: action.payload };

        case GET_PUBLIC_PROFILE:
            return { ...state, loading: true };
        case GET_PUBLIC_PROFILE_SUCCESS:
            return { ...state, publicProfile: action.payload, loading: false, error: null };
        case GET_PUBLIC_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_ACADEMIC:
            return { ...state, loading: true };
        case CREATE_ACADEMIC_SUCCESS:
            {
                let currentSP = state.subscriptionProfile;
                if (currentSP) {
                    currentSP.academics = action.payload;
                }
                return { ...state, subscriptionProfile: currentSP, loading: false, error: null };
            }
        case CREATE_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_ACADEMIC:
            return { ...state, loading: true };
        case DELETE_ACADEMIC_SUCCESS:
            {
                let currentSP = state.subscriptionProfile;
                if (currentSP) {
                    currentSP.academics = action.payload;
                }
                return { ...state, subscriptionProfile: currentSP, loading: false, error: null };
            }
        case DELETE_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_ACADEMY_SECURITY:
            return { ...state, loading: true };
        case CREATE_ACADEMY_SECURITY_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case CREATE_ACADEMY_SECURITY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_ACADEMY_SECURITY:
            return { ...state, loading: true };
        case UPDATE_ACADEMY_SECURITY_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case UPDATE_ACADEMY_SECURITY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_EDUCATION:
            return { ...state, loading: true };
        case CREATE_EDUCATION_SUCCESS:
            {
                let currentSubscriptionProfile = state.subscriptionProfile;
                if (currentSubscriptionProfile) {
                    currentSubscriptionProfile.academics = action.payload.response.academics;
                }
                return { ...state, subscriptionProfile: currentSubscriptionProfile, loading: false, error: null };

            }
        case CREATE_EDUCATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_EDUCATION:
            return { ...state, loading: true };
        case EDIT_EDUCATION_SUCCESS:
            {
                let currentSubscriptionProfile = state.subscriptionProfile;
                if (currentSubscriptionProfile) {
                    currentSubscriptionProfile.academics = action.payload.response.academics;
                }
                return { ...state, subscriptionProfile: currentSubscriptionProfile, loading: false, error: null };

            }
        case EDIT_EDUCATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EDUCATION:
            return { ...state, loading: true };
        case DELETE_EDUCATION_SUCCESS:
            {
                let currentSubscriptionProfile = state.subscriptionProfile;
                if (currentSubscriptionProfile) {
                    currentSubscriptionProfile.academics = action.payload.response;
                }
                return { ...state, subscriptionProfile: currentSubscriptionProfile, loading: false, error: null };

            }
        case DELETE_EDUCATION_FAILED:
            return { ...state, error: action.payload, loading: false };


        case CREATE_VALIDATION:
            return { ...state, loading: true };
        case CREATE_VALIDATION_SUCCESS:
            {
                let currentSubscriptionProfile = state.subscriptionProfile;
                let validation = action.payload.data;
                if (currentSubscriptionProfile && validation) {
                    switch (validation.documentType) {
                        case VALIDATION_DOCUMENT_EDUCATION:
                            let academics = currentSubscriptionProfile.academics?.academics ?? [];
                            let aIndex = academics.findIndex((a) => { return a.academyId == validation.documentId });
                            if (aIndex >= 0) {
                                let places = academics[aIndex].academyPlaces ?? [];
                                let pIndex = places.findIndex((p) => { return p.id == validation.documentSubId });
                                if (pIndex >= 0) {
                                    if (validation.documentChildId && parseInt(validation.documentChildId)) {
                                        let graduateDegrees = places[pIndex].graduateDegrees ?? [];
                                        let dIndex = graduateDegrees.findIndex((d) => { return d.order == parseInt(validation.documentChildId) });
                                        if (dIndex >= 0) {
                                            let validations = graduateDegrees[dIndex].validationsStatus ?? [];
                                            validations.push(validation);
                                            currentSubscriptionProfile.academics.academics[aIndex].academyPlaces[pIndex].graduateDegrees[dIndex].validationsStatus = validations;
                                        }
                                    }
                                    else {
                                        let validations = places[pIndex].validationsStatus ?? [];
                                        validations.push(validation);
                                        currentSubscriptionProfile.academics.academics[aIndex].academyPlaces[pIndex].validationsStatus = validations;
                                    }
                                }
                            }
                            break;

                        default: break;
                    }
                }
                return { ...state, subscriptionProfile: currentSubscriptionProfile, loading: false, error: null };

            }
        case CREATE_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGOUT_USER:
            return INIT_STATE;


        case SWITCH_USER:
            return { ...state, loading: true };
        case SWITCH_USER_SUCCESS:
            return { ...state, loading: false, error: null };
        case SWITCH_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_FAILED_PAYMENT:
            return { ...state, loading: true };
        case GET_FAILED_PAYMENT_SUCCESS:
            return { ...state, failedPayments: action.payload, loading: false, error: null };
        case GET_FAILED_PAYMENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case PAY_FAILED_PAYMENT:
            return { ...state, loading: true };
        case PAY_FAILED_PAYMENT_SUCCESS:
            return { ...state, loading: false, error: null };
        case PAY_FAILED_PAYMENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SYNCHRONIZE_PAYMENT:
            return { ...state, loading: true };
        case SYNCHRONIZE_PAYMENT_SUCCESS:
            return { ...state, loading: false, error: null };
        case SYNCHRONIZE_PAYMENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DASHBOARD:
            return { ...state, loading: true };
        case GET_DASHBOARD_SUCCESS:
            return { ...state, dashboard: action.payload, loading: false, error: null };
        case GET_DASHBOARD_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Profile;
