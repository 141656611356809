// @flow
import {
    GET_REPOPRODUCTS,GET_REPOPRODUCTS_FAILED,GET_REPOPRODUCTS_SUCCESS
} from './constants';

export const getRepoProducts = (callbacks) => ({
    type: GET_REPOPRODUCTS,
    payload: { callbacks },
});

export const getRepoProductsSuccess = (data) => ({
    type: GET_REPOPRODUCTS_SUCCESS,
    payload: data,
});

export const getRepoProductsFailed = (error) => ({
    type: GET_REPOPRODUCTS_FAILED,
    payload: error,
});
