/* AUTH */
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILED = 'GET_EMPLOYEE_FAILED';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_PERSONAL_SUBCRIPTION = 'GET_PERSONAL_SUBCRIPTION';
export const GET_PERSONAL_SUBCRIPTION_SUCCESS = 'GET_PERSONAL_SUBCRIPTION_SUCCESS';
export const GET_PERSONAL_SUBCRIPTION_FAILED = 'GET_PERSONAL_SUBCRIPTION_FAILED';

export const GET_SUBCRIPTION_PROFILE = 'GET_SUBCRIPTION_PROFILE';
export const GET_SUBCRIPTION_PROFILE_SUCCESS = 'GET_SUBCRIPTION_PROFILE_SUCCESS';
export const GET_SUBCRIPTION_PROFILE_FAILED = 'GET_SUBCRIPTION_PROFILE_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const UPDATE_SECURITY = 'UPDATE_SECURITY';
export const UPDATE_SECURITY_SUCCESS = 'UPDATE_SECURITY_SUCCESS';
export const UPDATE_SECURITY_FAILED = 'UPDATE_SECURITY_FAILED';

export const CREATE_SECURITY = 'CREATE_SECURITY';
export const CREATE_SECURITY_SUCCESS = 'CREATE_SECURITY_SUCCESS';
export const CREATE_SECURITY_FAILED = 'CREATE_SECURITY_FAILED';

export const UPLOAD_CV = 'UPLOAD_CV';
export const UPLOAD_CV_SUCCESS = 'UPLOAD_CV_SUCCESS';
export const UPLOAD_CV_FAILED = 'UPLOAD_CV_FAILED';

export const SEARCH_EMPLOYEE = 'SEARCH_EMPLOYEE';
export const SEARCH_EMPLOYEE_SUCCESS = 'SEARCH_EMPLOYEE_SUCCESS';
export const SEARCH_EMPLOYEE_FAILED = 'SEARCH_EMPLOYEE_FAILED';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';

export const REGISTER_EMPLOYEE = 'REGISTER_EMPLOYEE';
export const REGISTER_EMPLOYEE_SUCCESS = 'REGISTER_EMPLOYEE_SUCCESS';
export const REGISTER_EMPLOYEE_FAILED = 'REGISTER_EMPLOYEE_FAILED';

export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const GET_ALL_COMPANY_SUCCESS = 'GET_ALL_COMPANY_SUCCESS';
export const GET_ALL_COMPANY_FAILED = 'GET_ALL_COMPANY_FAILED';

export const PROFILE_VISUAL_SUMMARY = 'PROFILE_VISUAL_SUMMARY';
export const PROFILE_VISUAL_SUMMARY_SUCCESS = 'PROFILE_VISUAL_SUMMARY_SUCCESS';
export const PROFILE_VISUAL_SUMMARY_FAILED = 'PROFILE_VISUAL_SUMMARY_FAILED';

export const PROFILE_SUMMARY_DATA_CHART = 'PROFILE_SUMMARY_DATA_CHART';
export const PROFILE_SUMMARY_DATA_CHART_SUCCESS = 'PROFILE_SUMMARY_DATA_CHART_SUCCESS';
export const PROFILE_SUMMARY_DATA_CHART_FAILED = 'PROFILE_SUMMARY_DATA_CHART_FAILED';

export const UPDATE_PHONENUMBER = 'UPDATE_PHONENUMBER';
export const UPDATE_PHONENUMBER_SUCCESS = 'UPDATE_PHONENUMBER_SUCCESS';
export const UPDATE_PHONENUMBER_FAILED = 'UPDATE_PHONENUMBER_FAILED';

export const CHECK_EMAILVERIFICATIONSTATUS = 'CHECK_EMAILVERIFICATIONSTATUS';
export const CHECK_EMAILVERIFICATIONSTATUS_SUCCESS = 'CHECK_EMAILVERIFICATIONSTATUS_SUCCESS';
export const CHECK_EMAILVERIFICATIONSTATUS_FAILED = 'CHECK_EMAILVERIFICATIONSTATUS_FAILED';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILED = 'RESEND_VERIFICATION_EMAIL_FAILED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const GET_CVS = 'GET_CVS';
export const GET_CVS_SUCCESS = 'GET_CVS_SUCCESS';
export const GET_CVS_FAILED = 'GET_CVS_FAILED';
export const DELETE_CVS = 'DELETE_CVS';
export const DELETE_CVS_SUCCESS = 'DELETE_CVS_SUCCESS';
export const DELETE_CVS_FAILED = 'DELETE_CVS_FAILED';

export const GET_PHOTOS = 'GET_PHOTOS';
export const GET_PHOTOS_SUCCESS = 'GET_PHOTOS_SUCCESS';
export const GET_PHOTOS_FAILED = 'GET_PHOTOS_FAILED';
export const DELETE_PHOTOS = 'DELETE_PHOTOS';
export const DELETE_PHOTOS_SUCCESS = 'DELETE_PHOTOS_SUCCESS';
export const DELETE_PHOTOS_FAILED = 'DELETE_PHOTOS_FAILED';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILED = 'UPLOAD_PHOTO_FAILED';

export const GET_MENU_HIERARCHY = 'GET_MENU_HIERARCHY';
export const GET_MENU_HIERARCHY_SUCCESS = 'GET_MENU_HIERARCHY_SUCCESS';
export const GET_MENU_HIERARCHY_FAILED = 'GET_MENU_HIERARCHY_FAILED';

export const GET_DATA_EXPERIENCE = 'GET_DATA_EXPERIENCE';
export const GET_DATA_EXPERIENCE_SUCCESS = 'GET_DATA_EXPERIENCE_SUCCESS';
export const GET_DATA_EXPERIENCE_FAILED = 'GET_DATA_EXPERIENCE_FAILED';

export const GET_DATA_EXPERIENCE_BY_ID = 'GET_DATA_EXPERIENCE_BY_ID';
export const GET_DATA_EXPERIENCE_BY_ID_SUCCESS = 'GET_DATA_EXPERIENCE_BY_ID_SUCCESS';
export const GET_DATA_EXPERIENCE_BY_ID_FAILED = 'GET_DATA_EXPERIENCE_BY_ID_FAILED';

export const CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
export const CREATE_EXPERIENCE_SUCCESS = 'CREATE_EXPERIENCE_SUCCESS';
export const CREATE_EXPERIENCE_FAILED = 'CREATE_EXPERIENCE_FAILED';

export const EDIT_EXPERIENCE = 'EDIT_EXPERIENCE';
export const EDIT_EXPERIENCE_SUCCESS = 'EDIT_EXPERIENCE_SUCCESS';
export const EDIT_EXPERIENCE_FAILED = 'EDIT_EXPERIENCE_FAILED';

export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_FAILED = 'DELETE_EXPERIENCE_FAILED';

export const GET_ACTION_GROUPS = 'GET_ACTION_GROUPS';
export const GET_ACTION_GROUPS_SUCCESS = 'GET_ACTION_GROUPS_SUCCESS';
export const GET_ACTION_GROUPS_FAILED = 'GET_ACTION_GROUPS_FAILED';

export const GET_RELATION_ACTION = 'GET_RELATION_ACTION';
export const GET_RELATION_ACTION_SUCCESS = 'GET_RELATION_ACTION_SUCCESS';
export const GET_RELATION_ACTION_FAILED = 'GET_RELATION_ACTION_FAILED';

export const GET_GOALS_BY_USER = 'GET_GOALS_BY_USER';
export const GET_GOALS_BY_USER_SUCCESS = 'GET_GOALS_BY_USER_SUCCESS';
export const GET_GOALS_BY_USER_FAILED = 'GET_GOALS_BY_USER_FAILED';

export const GET_ACTIONS_BY_USER = 'GET_ACTIONS_BY_USER';
export const GET_ACTIONS_BY_USER_SUCCESS = 'GET_ACTIONS_BY_USER_SUCCESS';
export const GET_ACTIONS_BY_USER_FAILED = 'GET_ACTIONS_BY_USER_FAILED';

export const GET_GOALS_ACTIONS_BY_USER = 'GET_GOALS_ACTIONS_BY_USER';
export const GET_GOALS_ACTIONS_BY_USER_SUCCESS = 'GET_GOALS_ACTIONS_BY_USER_SUCCESS';
export const GET_GOALS_ACTIONS_BY_USER_FAILED = 'GET_GOALS_ACTIONS_BY_USER_FAILED';

export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_FAILED = 'GET_ALERTS_FAILED';

export const GET_PUBLIC_PROFILE = 'GET_PUBLIC_PROFILE';
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAILED = 'GET_PUBLIC_PROFILE_FAILED';


export const UPDATE_ACADEMY_SECURITY = 'UPDATE_ACADEMY_SECURITY';
export const UPDATE_ACADEMY_SECURITY_SUCCESS = 'UPDATE_ACADEMY_SECURITY_SUCCESS';
export const UPDATE_ACADEMY_SECURITY_FAILED = 'UPDATE_ACADEMY_SECURITY_FAILED';

export const CREATE_ACADEMY_SECURITY = 'CREATE_ACADEMY_SECURITY';
export const CREATE_ACADEMY_SECURITY_SUCCESS = 'CREATE_ACADEMY_SECURITY_SUCCESS';
export const CREATE_ACADEMY_SECURITY_FAILED = 'CREATE_ACADEMY_SECURITY_FAILED';

export const SWITCH_USER = 'SWITCH_USER';
export const SWITCH_USER_SUCCESS = 'SWITCH_USER_SUCCESS';
export const SWITCH_USER_FAILED = 'SWITCH_USER_FAILED';


export const PRODUCT_STATUS_LIVE = 0;
export const PRODUCT_STATUS_EXPIRED = 1;
export const PRODUCT_STATUS_PAYMENT_FAILED = 2;
export const PRODUCT_STATUS_PAYMENT_STOPPED = 3;

export const PRO_EMAIL_TYPE_INDIVIDUAL = 0;
export const PRO_EMAIL_TYPE_COMPANY = 1;
export const PRO_EMAIL_TYPE_ACADEMIC = 2;

export const GET_FAILED_PAYMENT = 'GET_FAILED_PAYMENT';
export const GET_FAILED_PAYMENT_SUCCESS = 'GET_FAILED_PAYMENT_SUCCESS';
export const GET_FAILED_PAYMENT_FAILED = 'GET_FAILED_PAYMENT_FAILED';

export const PAY_FAILED_PAYMENT = 'PAY_FAILED_PAYMENT';
export const PAY_FAILED_PAYMENT_SUCCESS = 'PAY_FAILED_PAYMENT_SUCCESS';
export const PAY_FAILED_PAYMENT_FAILED = 'PAY_FAILED_PAYMENT_FAILED';

export const SYNCHRONIZE_PAYMENT = 'SYNCHRONIZE_PAYMENT';
export const SYNCHRONIZE_PAYMENT_SUCCESS = 'SYNCHRONIZE_PAYMENT_SUCCESS';
export const SYNCHRONIZE_PAYMENT_FAILED = 'SYNCHRONIZE_PAYMENT_FAILED';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';

export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_EMAIL_SUCCESS = 'ADD_EMAIL_SUCCESS';
export const ADD_EMAIL_FAILED = 'ADD_EMAIL_FAILED';

export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_FAILED = 'EDIT_EMAIL_FAILED';

export const REMOVE_EMAIL = 'REMOVE_EMAIL';
export const REMOVE_EMAIL_SUCCESS = 'REMOVE_EMAIL_SUCCESS';
export const REMOVE_EMAIL_FAILED = 'REMOVE_EMAIL_FAILED';

export const ADD_PHONE = 'ADD_PHONE';
export const ADD_PHONE_SUCCESS = 'ADD_PHONE_SUCCESS';
export const ADD_PHONE_FAILED = 'ADD_PHONE_FAILED';

export const EDIT_PHONE = 'EDIT_PHONE';
export const EDIT_PHONE_SUCCESS = 'EDIT_PHONE_SUCCESS';
export const EDIT_PHONE_FAILED = 'EDIT_PHONE_FAILED';

export const REMOVE_PHONE = 'REMOVE_PHONE';
export const REMOVE_PHONE_SUCCESS = 'REMOVE_PHONE_SUCCESS';
export const REMOVE_PHONE_FAILED = 'REMOVE_PHONE_FAILED';

export const CONTACT_TYPE_PERSONAL = 0;
export const CONTACT_TYPE_PROFESSIONAL = 1;

export const CONTACT_EMAIL = 0;
export const CONTACT_MOBILE = 1;