/* AUTH */
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED';

export const GET_COMPANY_PROFILES = 'GET_COMPANY_PROFILES';
export const GET_COMPANY_PROFILES_SUCCESS = 'GET_COMPANY_PROFILES_SUCCESS';
export const GET_COMPANY_PROFILES_FAILED = 'GET_COMPANY_PROFILES_FAILED';

export const GET_COMPANY_SPECIALTIES = 'GET_COMPANY_SPECIALTIES';
export const GET_COMPANY_SPECIALTIES_SUCCESS = 'GET_COMPANY_SPECIALTIES_SUCCESS';
export const GET_COMPANY_SPECIALTIES_FAILED = 'GET_COMPANY_SPECIALTIES_FAILED';

export const GET_COMPANY_POSITION_TYPES = 'GET_COMPANY_POSITION_TYPES';
export const GET_COMPANY_POSITION_TYPES_SUCCESS = 'GET_COMPANY_POSITION_TYPES_SUCCESS';
export const GET_COMPANY_POSITION_TYPES_FAILED = 'GET_COMPANY_POSITION_TYPES_FAILED';

export const GET_CAREER_COMPANY_DETAILS = 'GET_CAREER_COMPANY_DETAILS';
export const GET_CAREER_COMPANY_DETAILS_SUCCESS = 'GET_CAREER_COMPANY_DETAILS_SUCCESS';
export const GET_CAREER_COMPANY_DETAILS_FAILED = 'GET_CAREER_COMPANY_DETAILS_FAILED';

export const CREATE_CAREER = 'CREATE_CAREER';
export const CREATE_CAREER_SUCCESS = 'CREATE_CAREER_SUCCESS';
export const CREATE_CAREER_FAILED = 'CREATE_CAREER_FAILED';

export const EDIT_CAREER = 'EDIT_CAREER';
export const EDIT_CAREER_SUCCESS = 'EDIT_CAREER_SUCCESS';
export const EDIT_CAREER_FAILED = 'EDIT_CAREER_FAILED';

export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILED = "CREATE_COMPANY_FAILED";

export const GET_EMAILS_COMPANY = "GET_EMAILS_COMPANY";
export const GET_EMAILS_COMPANY_SUCCESS = "GET_EMAILS_COMPANY_SUCCESS";
export const GET_EMAILS_COMPANY_FAILED = "GET_EMAILS_COMPANY_FAILED";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";

export const CHECK_NAME_COMPANY = "CHECK_NAME_COMPANY";
export const CHECK_NAME_COMPANY_SUCCESS = "CHECK_NAME_COMPANY_SUCCESS";
export const CHECK_NAME_COMPANY_FAILED = "CHECK_NAME_COMPANY_FAILED";

export const CHECK_EMAIL_COMPANY = "CHECK_EMAIL_COMPANY";
export const CHECK_EMAIL_COMPANY_SUCCESS = "CHECK_EMAIL_COMPANY_SUCCESS";
export const CHECK_EMAIL_COMPANY_FAILED = "CHECK_EMAIL_COMPANY_FAILED";

export const CHANGE_EMAIL_COMPANY = "CHANGE_EMAIL_COMPANY";
export const CHANGE_EMAIL_COMPANY_SUCCESS = "CHANGE_EMAIL_COMPANY_SUCCESS";
export const CHANGE_EMAIL_COMPANY_FAILED = "CHANGE_EMAIL_COMPANY_FAILED";

export const DELETE_EMAIL_COMPANY = "DELETE_EMAIL_COMPANY";
export const DELETE_EMAIL_COMPANY_SUCCESS = "DELETE_EMAIL_COMPANY_SUCCESS";
export const DELETE_EMAIL_COMPANY_FAILED = "DELETE_EMAIL_COMPANY_FAILED";

export const CHECK_COMPANY_ID = "CHECK_COMPANY_ID";
export const CHECK_COMPANY_ID_SUCCESS = "CHECK_COMPANY_ID_SUCCESS";
export const CHECK_COMPANY_ID_FAILED = "CHECK_COMPANY_ID_FAILED";

export const CHANGE_EMAIL_ACADEMIC = "CHANGE_EMAIL_ACADEMIC";
export const CHANGE_EMAIL_ACADEMIC_SUCCESS = "CHANGE_EMAIL_ACADEMIC_SUCCESS";
export const CHANGE_EMAIL_ACADEMIC_FAILED = "CHANGE_EMAIL_ACADEMIC_FAILED";

export const DELETE_EMAIL_ACADEMIC = "DELETE_EMAIL_ACADEMIC";
export const DELETE_EMAIL_ACADEMIC_SUCCESS = "DELETE_EMAIL_ACADEMIC_SUCCESS";
export const DELETE_EMAIL_ACADEMIC_FAILED = "DELETE_EMAIL_ACADEMIC_FAILED";

export const GET_FELLOWS = 'GET_FELLOWS';
export const GET_FELLOWS_SUCCESS = 'GET_FELLOWS_SUCCESS';
export const GET_FELLOWS_FAILED = 'GET_FELLOWS_FAILED';

export const GET_CAREER_COMPANIES = 'GET_CAREER_COMPANIES';
export const GET_CAREER_COMPANIES_SUCCESS = 'GET_CAREER_COMPANIES_SUCCESS';
export const GET_CAREER_COMPANIES_FAILED = 'GET_CAREER_COMPANIES_FAILED';

export const DELETE_CAREER_COMPANY = 'DELETE_CAREER_COMPANY';
export const DELETE_CAREER_COMPANY_SUCCESS = 'DELETE_CAREER_COMPANY_SUCCESS';
export const DELETE_CAREER_COMPANY_FAILED = 'DELETE_CAREER_COMPANY_FAILED';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';

export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILED = 'EDIT_COMPANY_FAILED';

export const EDIT_COMPANY_CAREER = 'EDIT_COMPANY_CAREER';
export const EDIT_COMPANY_CAREER_SUCCESS = 'EDIT_COMPANY_CAREER_SUCCESS';
export const EDIT_COMPANY_CAREER_FAILED = 'EDIT_COMPANY_CAREER_FAILED';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';

export const GET_POSITIONS_OF_COMPANY = 'GET_POSITIONS_OF_COMPANY';
export const GET_POSITIONS_OF_COMPANY_SUCCESS = 'GET_POSITIONS_OF_COMPANY_SUCCESS';
export const GET_POSITIONS_OF_COMPANY_FAILED = 'GET_POSITIONS_OF_COMPANY_FAILED';

export const GET_PUBLIC_COMPANY = 'GET_PUBLIC_COMPANY';
export const GET_PUBLIC_COMPANY_SUCCESS = 'GET_PUBLIC_COMPANY_SUCCESS';
export const GET_PUBLIC_COMPANY_FAILED = 'GET_PUBLIC_COMPANY_FAILED';

export const GET_COMPANY_GOALS = 'GET_COMPANY_GOALS';
export const GET_COMPANY_GOALS_SUCCESS = 'GET_COMPANY_GOALS_SUCCESS';
export const GET_COMPANY_GOALS_FAILED = 'GET_COMPANY_GOALS_FAILED';

export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN';
export const SET_COMPANY_ADMIN_SUCCESS = 'SET_COMPANY_ADMIN_SUCCESS';
export const SET_COMPANY_ADMIN_FAILED = 'SET_COMPANY_ADMIN_FAILED';