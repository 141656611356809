import React,{Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import Scroll from 'react-scroll';
import LanguageDropdown from '../components/LanguageDropdown';

class TopbarAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="navbar navbar-expand-lg navbar-light" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">

              <Scroll.Link to="formSignElement" >
                <p className="nav-link active" onClick={this.props.headerBarFuncCompany} >{t('login.company')}</p>
              </Scroll.Link>
            </li>
            <li className="nav-item">
              <Scroll.Link to="formSignElement" >
                <p className={this.props.tabviewMode == 2 ? "nav-link active" : "nav-link"} onClick={this.props.headerBarFuncSignUp} >{t("login.signUp1")}</p>
              </Scroll.Link>
            </li>
            <li className="nav-item">
              <Scroll.Link to="formSignElement" >
                <p className={this.props.tabviewMode == 1 ? "nav-link active" : "nav-link"} onClick={this.props.headerBarFuncSignIn} to="formSignElement" >{t("login.signIn1")}</p>
              </Scroll.Link>
            </li>

            <LanguageDropdown />
          </ul>
        </div>
      </React.Fragment >
    );
  }
}

export default connect(
  null,
  {  }
)(withTranslation('translations')(TopbarAuth));
