import React from "react";

export default function CompanyBubble(props) {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor + "d0",
      }}
      className={"companyBubble"}
    >
          <p
            style={{
              color: props.textColor,
              fontSize: 14,
              fontWeight: 1000,
              maxWidth: 150,
              textAlign: "center",
              marginTop: 20
            }}
          >
            {props.symbol}
          </p>
       
    </div>
  );
}
