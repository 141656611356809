// @flow
import {
    SELECT_SEARCH_CATEGORY, SELECT_SEARCH_CATEGORY_SUCCESS, SELECT_SEARCH_CATEGORY_FAILED,
    SEARCH, SEARCH_SUCCESS, SEARCH_FAILED,
    SELECT_PERSONAL_NETWORK, SELECT_PERSONAL_NETWORK_SUCCESS, SELECT_PERSONAL_NETWORK_FAILED,

} from './constants';

export const selectSearchCategory = (category, callbacks) => ({
    type: SELECT_SEARCH_CATEGORY,
    payload: { category, callbacks },
});

export const selectSearchCategorySuccess = (category) => ({
    type: SELECT_SEARCH_CATEGORY_SUCCESS,
    payload: { category },
});

export const selectSearchCategoryFailed = (error) => ({
    type: SELECT_SEARCH_CATEGORY_FAILED,
    payload: error,
});

export const search = (keyword, categories, personalNetwork, page, callbacks) => ({
    type: SEARCH,
    payload: { keyword, categories, personalNetwork, page, callbacks },
});

export const searchSuccess = (keyword, data) => ({
    type: SEARCH_SUCCESS,
    payload: { keyword, data },
});

export const searchFailed = (error) => ({
    type: SEARCH_FAILED,
    payload: error,
});

export const selectPersonalNetwork = (isOn, callbacks) => ({
    type: SELECT_PERSONAL_NETWORK,
    payload: { isOn, callbacks },
});

export const selectPersonalNetworkSuccess = (isOn) => ({
    type: SELECT_PERSONAL_NETWORK_SUCCESS,
    payload: { isOn },
});

export const selectPersonalNetworkFailed = (error) => ({
    type: SELECT_PERSONAL_NETWORK_FAILED,
    payload: error,
});