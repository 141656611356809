import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as layoutConstants from '../constants/layout';
import { AuthenticationStatus, getLoggedInUserPayment, userAuthenticatedStatus } from '../helpers/authUtils';
import Home from '../pages/auth/Home';
import { allFlattenRoutes as routes } from './index';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerticalLayout = Loadable({
    loader: () => import('../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerificationLayout = Loadable({
    loader: () => import('../layouts/Verification'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});


class Routes extends Component {

    // returns the layout
    getLayout = () => {
        const status = userAuthenticatedStatus();

        if ((status == AuthenticationStatus.UnAuthorize) || (status == AuthenticationStatus.LoggedIn)
            || (status == AuthenticationStatus.PhoneNotVerified) || getLoggedInUserPayment() !== null) {
            return AuthLayout;
        }
        else if ((status == AuthenticationStatus.EmailNotVerified)
            || (status == AuthenticationStatus.ProfileNotProvided)
            || (status  == AuthenticationStatus.NotSubscribe)) {
            return VerificationLayout;
        }
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    render() {
        const Layout = this.getLayout();

        const pathname = window?.location?.pathname ?? '';
        const isExternalValidation = window.location.search.startsWith("?validation_code=");
        const isInvitation = window.location.search.includes("invitation_email=") || window.location.search.includes("invitation_phone=");
        const unAuthorize = userAuthenticatedStatus() == AuthenticationStatus.UnAuthorize;
        const canOpenSubPage = (pathname.length > 0 && pathname != '/') && !unAuthorize;
        const loginNeeded = (isExternalValidation || isInvitation) ? false : !canOpenSubPage;
        const notSubscribe = userAuthenticatedStatus() == AuthenticationStatus.NotSubscribe;

        // rendering the router with layout
        return <BrowserRouter>

            {loginNeeded &&
                <Redirect to={{ pathname: process.env.PUBLIC_URL + '/', state: { nextPath: (pathname ?? '') + (window.location?.search ?? '') } }} />
            }

            {loginNeeded && notSubscribe &&
                <Redirect to={{ pathname: process.env.PUBLIC_URL + '/account/subscribe'}} />
            }

            <Layout {...this.props}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                </Switch>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            !route.children ?
                                <route.route
                                    key={index}
                                    path={route.path}
                                    roles={route.roles}
                                    exact={route.exact}
                                    component={route.component}></route.route>
                                : null
                        );
                    })}
                </Switch>
            </Layout>
        </BrowserRouter>
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
