// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Mail,
    Trash,
    User,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Label,
    Row,
    UncontrolledTooltip,
} from 'reactstrap';
import { countryCodeToString, dateStringFromTimeInterval, durationBetweenDates, formatDateString, getLocalizedString, isAdmin, locationString, objectsFromGroup } from '../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    changeTheme,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getNetworkCompanies,
    getAllContentsCompany,
    deleteNetworkCompany
} from '../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, getLoggedInUser } from '../../../../helpers/authUtils';
import UserInfo from '../../Settings/Profile/UserInfo';
import * as layoutConstants from '../../../../constants/layout';
import SVG from 'react-inlinesvg';
import { format } from '../../../../helpers/stringFormat';
import Select from 'react-select';

import companyIcon from '../../../../assets/images/menu/companies.svg';
import addCompanyIcon from '../../../../assets/images/menu/company-plus.svg';
import AddCompanyModal from './AddCompanyModal';
import { MENU_TYPE_HOME } from '../../../../redux/appMenu/constants';
import AlertPopupView, { ALERT_TYPE_DANGER } from '../../Supports/AlertPopupView';
import RemoveNetworkModal from './RemoveNetworkModal';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Loader';

export const NETWORK_COMPANY_TYPE_COUNTRY = 0;
export const NETWORK_COMPANY_TYPE_ACTIVITY = 1;

class NetworkCompany extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loadingCount: 0,
            error: null,
            activeTabInfo: 1,
            displayWidth: 800,
            selectedNode: null,
            showAddCompanyDialog: 0,
            showInviteProfileDialog: 0,
            idType: null,
            editingCompetency: null,
            editingCompany: null,
            inviteContact: null,
            selectedCountry: 'All Countries',
            selectedState: 'All States',
            selectedIndustry: -1,
            showDetails: false,
            groupByCountry: true,
            showAlertPopup: false,
            currentPage: 0,
            keyword: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
        this.props.getAllContentsCompany({});

        this.getCompanies();

        // if (this.props.subscriptionProfile == null) {
        //     this.getProfileAndCompanies();
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        // this.props.history.push({ pathname: '/network' });
    }

    getProfileAndCompanies = () => {
        this.props.getSubscriptionProfile({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
                this.setState({ loadingCount: this.state.loadingCount + 1 });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0 });
            },
            callbackOnSuccess: (response) => {
            },
        });
    };

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }

    onSelectedNode = (node) => {
        // alert('onSelectedNode: ' + node.labels[0]);
        this.setState({ selectedNode: node });
    };

    showAddCompanyModal = (editingCompany) => {
        this.setState({
            editingCompany: editingCompany,
            showAddCompanyDialog: 1
        });
    };

    closeAddCompanyModal = (response) => {
        if (response) {
            this.callGetCompaniesAPI();
        }
        this.setState({ showAddCompanyDialog: 0, editingCompany: null }, () => {
        });
    };

    companyNetworkByCountry = () => {
        let network = [];

        let companies = this.props.networkCompanies?.data ?? [];
        companies.forEach(c => {
            let company = c.company;

            company.admins = c.admins;
            company.fellows = c.fellows;

            let users = {};
            if (c.externalUsers?.length > 0) {
                users.external = c.externalUsers;
            }
            if (c.invitedUsers?.length > 0) {
                users.invited = c.invitedUsers;
            }
            if (c.underSignupUsers?.length > 0) {
                users.underSignup = c.underSignupUsers;
            }
            if (c.registeredUsers?.length > 0) {
                users.user = c.registeredUsers;
            }
            company.network = users;

            if (c.user) {
                company.admins = [c.user];
            }
            let countryCode = company?.countryHQ ?? '';
            let index = network.findIndex((group) => { return group.country == countryCode });
            if (index >= 0) {
                let coms = network[index].companies ?? [];
                coms.push(company);
                network[index].companies = coms;
            }
            else {
                network.push({ country: countryCode, companies: [company] });
            }
        }, this);

        network.forEach(element => {
            if (element.companies?.length > 1) {
                element.companies.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    companyNetworkByActivity = () => {
        let network = [];

        let companies = this.props.networkCompanies?.data ?? [];
        companies.forEach(c => {
            let company = c.company;
            company.admins = c.admins;
            company.fellows = c.fellows;

            let users = {};
            if ((c.externalUsers ?? 0) > 0) {
                users.external = c.externalUsers;
            }
            if ((c.invitedUsers ?? 0) > 0) {
                users.invited = c.invitedUsers;
            }
            if ((c.underSignupUsers ?? 0) > 0) {
                users.underSignup = c.underSignupUsers;
            }
            if ((c.registeredUsers ?? 0) > 0) {
                users.user = c.registeredUsers;
            }
            company.network = users;

            if (c.user) {
                company.admins = [c.user];
            }

            if (company.industryGroupIds?.length > 0) {
                company.industryGroupIds.forEach(industryGroup => {
                    let industryGroupId = industryGroup.id;
                    let index = network.findIndex((group) => { return group.industryGroupId == industryGroupId });
                    if (index >= 0) {
                        let coms = network[index].companies ?? [];
                        coms.push(company);
                        network[index].companies = coms;
                    }
                    else {
                        network.push({ industryGroupId: industryGroupId, companies: [company] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.industryGroupId == 1000 });
                if (index >= 0) {
                    let coms = network[index].companies ?? [];
                    coms.push(company);
                    network[index].companies = coms;
                }
                else {
                    network.push({ industryGroupId: 1000, companies: [company] });
                }
            }
        });

        network.forEach(element => {
            if (element.companies?.length > 1) {
                element.companies.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    companyType = (id, informationMissing, managed) => {
        let color = informationMissing ? (managed ? 'orange' : 'gray') : (managed ? 'green' : 'red');
        let hover = informationMissing ? (managed ? 'informationStatus.managedMissingInfo' : 'informationStatus.notManagedMissingInfo') : (managed ? 'informationStatus.managedFullInfo' : 'informationStatus.notManagedFullInfo');
        return (
            <div id={id} className={'box-center size-px-20'}>
                <SVG src={companyIcon} className="size-px-20 " color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    networkByGeoLevels = (network) => {
        let geoLevels = this.props.companies?.internationalGeoLevels?.listDocDetails?.internationalGeoLevels?.map((element, i) => {
            let label = typeof element === 'string' ? element : getLocalizedString(element.value);
            let countries = objectsFromGroup(element, 'countries');

            return {
                order: i,
                label: label,
                countries: countries,
                network: {}
            };
        }) ?? [];

        if ((Object.keys(network) ?? []).length > 0) {
            geoLevels.forEach(level => {
                let networkByGeoLevel = {};
                (Object.keys(network) ?? []).forEach(key => {
                    networkByGeoLevel[key] = (network[key] ?? []).filter((user) => {
                        return level.countries.some((c) => {
                            let countries = [user.country ?? ''];
                            if ((user.country ?? '').includes(',')) {
                                countries = (user.country ?? '').split(',');
                            }

                            let belongToThisLevel = false;
                            for (let index = 0; index < countries.length; index++) {
                                if (level.countries.some((c) => { return c == countries[index] })) {
                                    belongToThisLevel = true;
                                    break;
                                }
                            }
                            return belongToThisLevel;
                        })
                    });
                });

                level.network = networkByGeoLevel;
            });
        }

        geoLevels = geoLevels.filter((level) => {
            let hasObject = false;
            let network = level.network ?? {};
            let keys = Object.keys(network);
            for (let index = 0; index < keys.length; index++) {
                hasObject = network[keys[index]].length > 0;
                if (hasObject) { break }
            }
            return hasObject;
        })

        return geoLevels;
    }

    networkPerson = (id, type, value) => {
        let t = this.props.t;
        let color = 'bg-color-black';
        switch (type) {
            case 'external':
                color = 'bg-color-black';
                break;
            case 'invited':
                color = 'bg-color-red';
                break;
            case 'underSignup':
                color = 'bg-color-orange';
                break;
            case 'user':
                color = 'bg-color-green';
                break;
            default:
                break;
        }

        return (
            <div id={type + id} className={'box-center size-px-15 rounded-8 mr-2' + ' ' + color}>
                <Label className='font-size-10 text-color-white'>{value}</Label>
                <UncontrolledTooltip placement="bottom" target={type + id}>{type}</UncontrolledTooltip>
            </div>
        )
    }

    networkPersonModule = (id, branch) => {
        let network = branch.network;
        return (
            <div className='align-horizontal center-vertical'>
                {Object.keys(network).length > 0 &&
                    <div className='align-horizontal'>
                        {network.external?.length > 0 &&
                            this.networkPerson(id, 'external', network.external.length)
                        }
                        {network.invited?.length > 0 &&
                            this.networkPerson(id, 'invited', network.invited.length)
                        }
                        {network.underSignup?.length > 0 &&
                            this.networkPerson(id, 'underSignup', network.underSignup.length)
                        }
                        {network.user?.length > 0 &&
                            this.networkPerson(id, 'user', network.user.length)
                        }
                        <div>
                            <User className="icon-dual size-px-20" />
                        </div>
                    </div>}
            </div>
        )
    }

    invitationModule = (id, inviteable, invitationDate) => {
        let t = this.props.t;
        let color = '';
        if (invitationDate) {
            let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
            let weeks = durationBetweenDates(invitationDate, "DD/MM/YYYY", now, "DD/MM/YYYY", ['weeks']);
            if (weeks < 2) {
                color = 'bg-color-green';
            }
            else if (weeks >= 2 && weeks < 4) {
                color = 'bg-color-orange';
            }
            else {
                color = 'bg-color-red';
            }
        }

        return (
            <div className='box-center mt-1'>
                <Mail
                    className="icon-dual ml-2 icon-size-20"
                    color={color.length > 0 ? 'gray' : (inviteable ? 'green' : 'red')}
                    id={id}
                    onClick={() => { }}
                />
                {color.length > 0 &&
                    <div className={'size-px-10 rounded-5' + ' ' + color} style={{ 'transform': 'translate(-8px, 5px)' }}></div>
                }
                <UncontrolledTooltip placement="bottom" target={id}>
                    {t('network.invite')}
                </UncontrolledTooltip>
            </div>
        )
    }

    childrenFromObject = (object) => {
        if (object == null) {
            return [];
        }

        let child = Object.assign({}, object);
        child.children = null;
        let children = [];
        children.push(child);

        let grandChildren = object.children ?? [];
        if (grandChildren.length > 0) {
            for (let i = 0; i < grandChildren.length; i++) {
                let grand = this.childrenFromObject(grandChildren[i])
                children = children.concat(grand)
            }
        }
        return children
    }

    industryWithId = (id) => {
        let industry = null;
        if (id && this.props.menuHierarchy?.industryGroup) {
            let industries = this.childrenFromObject(this.props.menuHierarchy?.industryGroup);
            industry = industries.find((ind) => { return ind.id == id });
        }
        return industry;
    }

    adminString = (company, countries) => {
        let admins = '';
        (company.admins ?? []).forEach(admin => {
            let name = admin.firstName ?? '';
            if (admin.lastName) {
                name += (name.length > 0 ? ' ' : '') + admin.lastName;
            }

            let location = locationString(company.country, admin.state, admin.city, countries);

            let adminInfo = name;
            if (location) {
                adminInfo += (adminInfo.length > 0 ? ', ' : '') + location;
            }
            // if (admin.branch) {
            //     adminInfo += (adminInfo.length > 0 ? ', ' : '') + admin.branch;
            // }
            if (adminInfo.length > 0) {
                admins += (admins.length > 0 ? ' | ' : '') + adminInfo;
            }
        });

        if (admins.length > 0) {
            admins = this.props.t('company.admin') + ' : ' + admins;
        }

        return admins;
    }

    createdByString = (company, countries) => {
        let t = this.props.t;

        let createdBy = company?.createdByUserDetails?.firstName ?? '';
        if (company?.createdByUserDetails?.lastName?.length > 0) {
            createdBy += (createdBy.length > 0 ? ' ' : '') + company?.createdByUserDetails?.lastName;
        }
        let userCountry = company?.createdByUserDetails?.country ? countryCodeToString(company?.createdByUserDetails?.country, countries) : '';
        if (createdBy.length > 0 && userCountry?.length > 0) {
            createdBy += (createdBy.length > 0 ? ', ' : '') + userCountry;
        }

        if (createdBy.length == 0) {
            createdBy = t('company.admin');
        }

        let createdDate = company.dateCreate ? formatDateString(company.dateCreate, 'MMM. DD, YYYY') : '';

        return createdBy.length > 0 ? (createdDate?.length > 0 ? format(t('general.createdByOn'), createdBy, createdDate) : format(t('general.createdBy'), createdBy)) : '';
    }

    viewNetworkCompanyDetails = (company) => {
        if (this.props.history) {
            this.props.history.push({
                pathname: '/network-company-details/' + company.id,
                state: {
                    networkCompany: company
                }
            });
        }
    }

    getCompanies = () => {
        if (this.props.networkCompanyKeyword != this.state.keyword || this.props.networkCompanies == null) {
            this.callGetCompaniesAPI();
        }
    }

    callGetCompaniesAPI = () => {
        this.props.getNetworkCompanies(this.state.groupByCountry ? NETWORK_COMPANY_TYPE_COUNTRY : NETWORK_COMPANY_TYPE_ACTIVITY, 0, false, this.state.keyword, {
            callbackOnBegin: () => {
                if(this._isMounted)
                    this.setState({ loadingCount: this.state.loadingCount + 1, error: null });
            },
            callbackOnFailure: (error) => {
                this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0, error: error });
            },
            callbackOnFinish: () => {
                if(this._isMounted)
                    this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0 });
            },
        });
    }

    handlePageClick = (page) => {
        this.setState({ currentPage: page }, () => {
            this.props.getNetworkCompanies(this.state.groupByCountry ? NETWORK_COMPANY_TYPE_COUNTRY : NETWORK_COMPANY_TYPE_ACTIVITY, page, false, this.state.keyword, {
                callbackOnBegin: () => {
                    this.setState({ loadingCount: this.state.loadingCount + 1, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0, error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0 });
                },
                callbackOnSuccess: (response) => {
                    console.log('123123');
                },
            });
        })
    }

    render() {
        const { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let geoTypes = this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
            (element, i) => {
                return { value: i, label: getLocalizedString(element.value), en: element.value.en, id: element.id };
            }
        );

        let groupByCountry = this.state.groupByCountry;

        let network = groupByCountry ? this.companyNetworkByCountry() : this.companyNetworkByActivity();
        // if (this.state.keyword?.length >= 3) {
        //     network = network.map((group) => {
        //         let companies = group.companies?.filter((company) => {
        //             const isValidName = (company.name ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());
        //             const isValidDomain = (company.domains ?? []).some((domain) => { return domain.toLowerCase().includes(this.state.keyword.toLowerCase()) });

        //             let country = company.countryHQ?.length == 0 ? '' : countryCodeToString(company.countryHQ, countries);
        //             const isValidCountry = country?.length == 0 ? true : (country ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());
        //             const isValidCity = company.cityHQ?.length == 0 ? true : (company.cityHQ ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());

        //             let isValidIndustry = false;
        //             if (company.industryGroupIds != null) {
        //                 for (let i = 0; i < company.industryGroupIds.length; i++) {
        //                     let industryGroupId = company.industryGroupIds[i].id;
        //                     let industry = industryGroupId == 1000 ? company.submitCompanyIndustry : this.industryWithId(industryGroupId)?.name;
        //                     if (industry?.length > 0) {
        //                         isValidIndustry = industry?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                         if (isValidIndustry) {
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }

        //             let isValidAdmin = false;
        //             let adminString = this.adminString(company, countries);
        //             if (adminString.length > 0) {
        //                 isValidAdmin = adminString?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //             }

        //             let isValidCreatedBy = false;
        //             let createdByString = this.createdByString(company, countries);
        //             if (createdByString?.length > 0) {
        //                 isValidCreatedBy = createdByString.toLowerCase().includes(this.state.keyword.toLowerCase());
        //             }

        //             let states = company.countryHQ?.length == 0 ? null : countries?.find((country) => { return country.value == company.countryHQ })?.states;
        //             if (states == null) {
        //                 return isValidName || isValidDomain || isValidCountry || isValidCity || isValidIndustry || isValidCreatedBy || isValidAdmin;
        //             }

        //             let state = states?.find((state) => { return state.id == company.stateHQ ?? '' });
        //             let stateName = state?.name ? getLocalizedString(state?.name) : '';
        //             let isValidState = stateName.toLowerCase().includes(this.state.keyword.toLowerCase());

        //             return isValidName || isValidDomain || isValidCountry || isValidCity || isValidState || isValidIndustry || isValidCreatedBy || isValidAdmin;
        //         }, this);
        //         group.companies = companies;
        //         return group;
        //     }, this);
        // }
        let filteredNetwork = [];
        if (groupByCountry) {
            filteredNetwork = this.state.selectedCountry == 'All Countries' ? network : network.filter((group) => { return group.country == this.state.selectedCountry });
        }
        else {
            filteredNetwork = this.state.selectedIndustry == -1 ? network : network.filter((group) => { return group.industryGroupId == this.state.selectedIndustry });
        }

        let availableCountries = [];
        let networkCountries = [];
        let availableIndustries = [];
        let networkIndustries = [];
        if (groupByCountry) {
            availableCountries = [
                {
                    value: 'All Countries',
                    label: t('country.allCountries'),
                    states: null,
                }
            ];

            networkCountries = network.map((group) => { return group.country });
            networkCountries = networkCountries?.map((countryCode) => {
                if (countryCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return countries.find((c) => { return c.value == countryCode });
            });
            networkCountries.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkCountries.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableCountries = availableCountries.concat(networkCountries);
        }
        else {
            availableIndustries = [
                {
                    value: -1,
                    label: t('activity.allActivities'),
                }
            ];

            networkIndustries = network.map((group) => { return group.industryGroupId });
            networkIndustries = networkIndustries?.map((industryGroupId) => {
                if (industryGroupId == 1000) {
                    return {
                        value: industryGroupId,
                        label: t('sub.Others'),

                    }
                }

                let industry = this.industryWithId(industryGroupId);

                return {
                    value: industryGroupId,
                    label: industry?.name ?? ''
                };
            }, this);
            networkIndustries.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkIndustries.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableIndustries = availableIndustries.concat(networkIndustries);
        }

        let selectedCountry = (this.state.selectedCountry && availableCountries.length > 0) ? availableCountries.find((element) => { return element.value == this.state.selectedCountry }) : null;
        let states = selectedCountry?.states?.map((state, i) => {
            return {
                value: state.id,
                label: getLocalizedString(state.name),
            };
        }) ?? [];

        let availableStates = [];
        if (filteredNetwork.length == 1 && states.length > 0) {
            let companies = filteredNetwork[0].companies ?? [];
            let stateHQs = [];
            companies.forEach(company => {
                let stateHQ = company.stateHQ ?? '';
                const exist = stateHQs.some((stateCode) => { return stateCode == stateHQ });
                if (!exist) {
                    stateHQs.push(stateHQ);
                }
            });
            stateHQs = stateHQs.map((stateCode) => {
                if (stateCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return states.find((c) => { return c.value == stateCode });
            });
            stateHQs.sort(function (a, b) { return b.label.localeCompare(a.label) });
            stateHQs.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });

            availableStates = [
                {
                    value: 'All States',
                    label: t('country.allStates'),
                }
            ];
            availableStates = availableStates.concat(stateHQs);
        }

        filteredNetwork = filteredNetwork.map(group => {
            if (this.state.selectedState && this.state.selectedState != 'All States') {
                let companies = group.companies;
                companies = companies?.filter((company) => { return (company.stateHQ ?? '') == (this.state.selectedState == 'Other' ? '' : this.state.selectedState) })
                group.companies = companies;
            }
            return group
        }, this);
        filteredNetwork = filteredNetwork.filter((group) => { return group.companies?.length > 0 });
        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                let countryA = a.country?.length == 0 ? '' : countryCodeToString(a.country, countries)
                let countryB = b.country?.length == 0 ? '' : countryCodeToString(b.country, countries)
                return a.country.length == 0 ? 1 : countryB.localeCompare(countryA)
            }
            else {
                let industryA = a.industryGroupId == 1000 ? 'Others' : (this.industryWithId(a.industryGroupId)?.name ?? '');
                let industryB = b.industryGroupId == 1000 ? 'Others' : (this.industryWithId(b.industryGroupId)?.name ?? '');
                return industryA == 'Others' ? 1 : industryB.localeCompare(industryA)
            }
        }, this);
        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                return a.country.length == 0 ? 1 : -1
            }
            else {
                return a.industryGroupId == 1000 ? 1 : -1
            }
        });

        return (
            <React.Fragment>
                {(this.state.showAlertPopup ?? false) &&
                    <RemoveNetworkModal
                        modal={this.state.showAlertPopup}
                        networkCompany={this.state.editingCompany}
                        onCancel={() => { this.setState({ showAlertPopup: false, editingContact: null }) }}
                        onSubmit={() => {
                            if (this.state.editingCompany?.id) {
                                this.props.deleteNetworkCompany(this.state.editingCompany.id, {
                                    callbackOnBegin: () => {
                                        // Update loading state?
                                        // console.log('callbackOnBegin');
                                        this.setState({ loadingCount: this.state.loadingCount + 1 });
                                    },
                                    callbackOnFailure: (error) => {
                                        // show error to your users or stay quiet
                                        this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0, error: error });
                                    },
                                    callbackOnFinish: () => {
                                        // Update loading state?
                                        this.setState({ loadingCount: this.state.loadingCount - 1 > 0 ? this.state.loadingCount - 1 : 0 });
                                    },
                                    callbackOnSuccess: (response) => {
                                        this.setState({ showAlertPopup: false, editingCompany: null })
                                    },
                                });
                            }
                        }}
                    />
                }

                {this.state.showAddCompanyDialog == 1 &&
                    <AddCompanyModal
                        id="add-contact"
                        title={t('profile.updatePersonalInformation')}
                        editingCompany={this.state.editingCompany ? JSON.parse(JSON.stringify(this.state.editingCompany)) : null}
                        modal={this.state.showAddCompanyDialog == 1}
                        close={this.closeAddCompanyModal}
                    />
                }
                <Row className="step-company">
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        {(this.state.loadingCount > 0) && <Loader />}
                        <div className="path-component align-horizontal">
                            <Label className="link" onClick={this.onBack}>{t('Network')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link-selected">{t('subscription.company')} </Label>
                            <div id="add-contact" className='align-horizontal'>
                                <SVG src={addCompanyIcon} className="ml-3 size-px-20" onClick={() => { this.showAddCompanyModal() }} />
                                <UncontrolledTooltip placement="bottom" target="add-contact">
                                    {t('network.addCompany')}
                                </UncontrolledTooltip>
                                {/* <div className='box-center size-px-10 rounded-5 bg-color-test1' style={{ 'transform': 'translate(-5px, 0px)' }}>
                                    <Label className='mt-0 mb-0 font-bold'>+</Label>
                                </div> */}
                            </div>
                        </div>
                    </CardBody>
                    <CardBody className="text-box">
                        <Row className='ml-4'>
                            <Col xl={3}>
                                {groupByCountry ?
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableCountries}
                                        placeholder={t('placeholder.Select')}
                                        value={selectedCountry}
                                        onChange={(value) => {
                                            if (this.state.selectedCountry != value.value) {
                                                this.setState({ selectedCountry: value.value == 'Other' ? '' : value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                    :
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableIndustries}
                                        placeholder={t('placeholder.Select')}
                                        value={(this.state.selectedIndustry && availableIndustries.length > 0) ? availableIndustries.find((element) => { return element.value == this.state.selectedIndustry }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedIndustry != value.value) {
                                                this.setState({ selectedIndustry: value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                }
                            </Col>
                            {states.length > 0 &&
                                <Col xl={3}>

                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableStates}
                                        placeholder={t('placeholder.Select')}
                                        value={this.state.selectedState ? availableStates.find((element) => { return element.value == this.state.selectedState }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedState != value.value) {
                                                this.setState({ selectedState: value.value })
                                            }
                                        }} />

                                </Col>
                            }

                            <Col xl={3} className='box-side'>
                                <div className="width-percent-100 float-sm-right mt-3 mt-sm-0 mb-3">
                                    <div className="width-percent-100 task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control search-input rounded-5"
                                                    placeholder="Search..."
                                                    value={this.state.keyword ?? ''}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({ keyword: e.target.value ?? '' }, () => {
                                                            if (!(this.props.networkCompanyKeyword.length < 3 && this.state.keyword.length < 3)) {
                                                                this.getCompanies();
                                                            }
                                                        })
                                                    }}
                                                />
                                                <span className="uil uil-search icon-search"></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>

                            <Col xl={3} className='box-side'>
                                <div className='align-horizontal mb-2'>
                                    <div>
                                        <label className="cursor-pointer mr-2 mb-3" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            {t('company.byCountry')}
                                        </label>
                                    </div>
                                    <div className='hard-custom-switch'>
                                        <div className="custom-control custom-switch mb-3 cursor-pointer" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            <input
                                                type="radio"
                                                className="custom-control-input cursor-pointer"
                                                checked={!this.state.groupByCountry}
                                            />
                                            <label className="custom-control-label cursor-pointer">
                                                {t('company.byActivity')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col className='box-side'>
                                {groupByCountry && states.length > 0 &&
                                    <div></div>
                                }
                                <div className={"custom-control custom-switch mb-3 cursor-pointer" + (groupByCountry ? ' mr-2' : '')} onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}>
                                    <input
                                        type="radio"
                                        className="custom-control-input cursor-pointer"
                                        checked={this.state.showDetails}
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                                        {t('general.details')}
                                    </label>
                                </div>
                            </Col>

                        </Row>

                        <br />

                        {filteredNetwork.length > 0 && filteredNetwork.map((item, index) => {
                            let country = item.country?.length == 0 ? t('sub.Others') : countryCodeToString(item.country, countries);
                            let industry = item.industryGroupId == 1000 ? t('sub.Others') : (this.industryWithId(item.industryGroupId)?.name ?? '');
                            return (
                                <div key={'network' + index} >
                                    <div className={'mb-3'}>
                                        <Row className="path-component ml-4">
                                            <Col>
                                                <Label className='link-selected'>{groupByCountry ? (country ?? '') : (industry ?? '')}</Label>
                                            </Col>
                                        </Row>
                                        {item.companies.length > 0 && item.companies.map((company, cidx) => {
                                            let removingInfo = {};
                                            removingInfo.id = company.id;

                                            let info = company.name ?? '';
                                            removingInfo.name = company.name;
                                            if (company.geoTypeId == 1) {
                                                let geoType = geoTypes?.find((g) => { return g.id == company.geoTypeId })?.label ?? '';
                                                if (geoType.length > 0) {
                                                    info += (info.length > 0 ? ' | ' : '') + geoType;
                                                    removingInfo.geoType = geoType;
                                                }
                                            }
                                            removingInfo.geoTypeId = company.geoTypeId;

                                            let location = '';
                                            if (company.countryHQ) {
                                                let countryHQ = countries.find((c) => { return c.value == company.countryHQ });
                                                location = countryHQ?.label ?? '';
                                                let statesInCountry = countryHQ?.states ?? [];
                                                if (company.stateHQ && statesInCountry.length > 0) {
                                                    let stateHQ = statesInCountry.find((s) => { return s.id == company.stateHQ });
                                                    if (stateHQ?.name) {
                                                        location += (location.length > 0 ? ', ' : '') + getLocalizedString(stateHQ.name);
                                                    }
                                                }
                                            }
                                            if (company.cityHQ) {
                                                location += (location?.length > 0 ? ', ' : '') + company.cityHQ;
                                            }

                                            if (location.length > 0) {
                                                info += (info.length > 0 ? (company.geoTypeId == 1 ? ', HQ ' : ' | ') : '') + location;
                                                removingInfo.location = location;
                                            }

                                            let industries = '';
                                            let inds = [];
                                            (company.industryGroupIds ?? []).forEach(industryGroup => {
                                                let industryGroupId = industryGroup.id;
                                                let industry = industryGroupId == 1000 ? company?.submitCompanyIndustry : this.industryWithId(industryGroupId)?.name;
                                                if (industry?.length > 0) {
                                                    industries += (industries.length > 0 ? ', ' : '') + industry;
                                                    inds.push(industry);
                                                }
                                            });
                                            if (industries.length > 0) {
                                                info += (info.length > 0 ? ' | ' : '') + industries;
                                            }
                                            removingInfo.industries = inds;

                                            let domains = '';
                                            (company.domains ?? []).forEach(domain => {
                                                domains += (domains.length > 0 ? ', ' : '') + domain;
                                            });
                                            removingInfo.domains = company?.domains;

                                            let admins = this.adminString(company, countries);
                                            removingInfo.domains = company?.admins;

                                            // let companyBranches = [
                                            //     { location: 'Vietnam', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: true },
                                            //     { location: 'AMERICAS', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: false },
                                            //     { location: 'APAC', network: { external: 3, invited: 3, underSignup: 3, user: 3 }, headQuater: false }
                                            // ]

                                            let networkByGeoLevels = [];
                                            if (company.network && Object.keys(company.network).length > 0) {
                                                networkByGeoLevels = this.networkByGeoLevels(company.network);
                                            }

                                            let createdByString = this.createdByString(company, countries);

                                            const hasDetails = (networkByGeoLevels.length > 0 && company.geoTypeId == 1) || admins.length > 0 || domains.length > 0 || createdByString.length > 0;

                                            const informationMissing = company?.name == null || company?.countryHQ == null || company?.cityHQ == null || company?.industryGroupIds?.length == 0 || company?.geoTypeId == null;
                                            const managed = company.admins?.length > 0;

                                            let loggedInUser = getLoggedInUser();
                                            let editable = company?.createdByUserId == loggedInUser?.id || isAdmin() || managed;
                                            let removeable = (company?.members ?? []).some((element) => { return element == loggedInUser.id }) || (company?.createdByUserId == loggedInUser?.id || isAdmin()) && !managed;

                                            return (
                                                <div key={cidx} className='ml-4'>
                                                    <div className='align-horizontal path-component ml-2'>
                                                        {this.companyType('companyType' + index + cidx, informationMissing, managed)}
                                                        <Label className='ml-2 link-horver font-size-15' onClick={() => { this.viewNetworkCompanyDetails(company) }}>{info}</Label>

                                                        {Object.keys(company.network).length >= 1 &&
                                                            <div className='align-horizontal'>
                                                                {info?.length > 0 &&
                                                                    <div className='ml-3 mr-3'>|</div>
                                                                }
                                                                {this.networkPersonModule('' + index + cidx + 'hq', company)}
                                                            </div>
                                                        }

                                                        {editable &&
                                                            <div onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.showAddCompanyModal(company)
                                                            }}>
                                                                <Edit className="icon-size-20 cursor-pointer ml-2" color='gray' id={'edit' + index + cidx} />
                                                                <UncontrolledTooltip placement="bottom" target={'edit' + index + cidx}>
                                                                    {t('network.editNetworkCompany')}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        }

                                                        {removeable &&
                                                            <div className='float-right size-px-20 box-center ml-2'>
                                                                <Trash className="icon-dual size-px-20 cursor-pointer" id={'delete' + index + cidx}
                                                                    onClick={() => {
                                                                        this.setState({ editingCompany: removingInfo, showAlertPopup: true })
                                                                    }} />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    id='tooltip-1'
                                                                    target={'delete' + index + cidx}>
                                                                    {t("experience.hover.removePerson")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        }

                                                    </div>
                                                    {this.props.didLoadCompaniesDetails && this.state.showDetails && hasDetails &&
                                                        <div className='bg-color-details align-vertical ml-2'>
                                                            <div className='align-vertical mt-1'>
                                                                {company.geoTypeId == 1 && networkByGeoLevels.length > 0 && networkByGeoLevels.map((geoLevel, geoLevelIndex) => {
                                                                    return (
                                                                        <Row className='ml-3 mb-2 mt-1' key={geoLevelIndex}>
                                                                            <Col xl={1}>
                                                                                <Label className='mb-0'>{geoLevel?.label ?? ''}</Label>
                                                                            </Col>
                                                                            <Col>
                                                                                {this.networkPersonModule('' + index + cidx + geoLevelIndex, geoLevel)}
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }, this)
                                                                }

                                                                {domains.length > 0 &&
                                                                    <Row className='ml-3 mb-1 mt-1'>
                                                                        <Col className='align-horizontal'>
                                                                            <Label className=' mt-0 mb-1'>{t('company.domains')} :</Label>
                                                                            <Label className='ml-1 mt-0 mb-1'>{domains}</Label>
                                                                        </Col>
                                                                    </Row>
                                                                }

                                                                {admins.length > 0 &&
                                                                    <Row className='ml-3 mb-1 mt-1'>
                                                                        <Col className='align-horizontal'>
                                                                            <Label className='mt-0 mb-1'>{admins}</Label>
                                                                        </Col>
                                                                    </Row>
                                                                }

                                                                {createdByString.length > 0 &&
                                                                    <Row className='ml-3 mb-1 mt-1'>
                                                                        <Col>
                                                                            <Label className='mb-0 mt-1 text-color-system-notice'>{createdByString}</Label>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }, this)}
                                    </div>
                                    {(index < filteredNetwork.length - 1) &&
                                        <Row className="path-component ml-3">
                                            <Col>
                                                <div className='separator-line mb-3'></div>
                                            </Col>
                                        </Row>

                                    }
                                </div>
                            )
                        }, this)}

                        {this.props.networkCompanies?.total_pages > 1 &&
                            <div className='float-right mt-2'>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.props.networkCompanies?.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    forcePage={this.state.currentPage}
                                    onPageChange={(e) => { this.handlePageClick(e.selected) }}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, menuHierarchy, loading, error } = state.Profile;
    const { countries } = state.Auth;
    const { networkCompanyKeyword, networkCompanies, didLoadCompaniesDetails } = state.Network;
    const { companies } = state.Company;
    return { menuHierarchy, subscriptionProfile, countries, networkCompanyKeyword, networkCompanies, didLoadCompaniesDetails, companies, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getNetworkCompanies,
    getAllContentsCompany,
    deleteNetworkCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkCompany));
