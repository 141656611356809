import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Redirect, Route } from 'react-router-dom';
import { getLoggedInUser, isUserAuthenticated } from '../helpers/authUtils';
import NetworkAcademicsDetails from '../pages/apps/Home/Network/NetworkAcademicsDetails/NetworkAcademicsDetails';
import InvalidVerificationCode from '../pages/auth/InvalidVerificationCode';

// auth
const Home = React.lazy(() => import('../pages/auth/Home'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const NewPassword = React.lazy(() => import('../pages/auth/NewPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const PersonalInfo = React.lazy(() => import('../pages/auth/PersonalInfo'));
const Subscribe = React.lazy(() => import('../pages/auth/Subscribe'));

// pages
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

const Interface = React.lazy(() => import('../pages/apps/Settings/Profile/Application/Interface'));
const Api = React.lazy(() => import('../pages/apps/Settings/Profile/Application/Api'));
const Companies = React.lazy(() => import('../pages/apps/Settings/Profile/Companies/Companies'));
const PersonalAcademics = React.lazy(() => import('../pages/apps/Settings/Profile/Academics/PersonalAcademics'));
const MySummary = React.lazy(() => import('../pages/apps/Settings/Background/MySummary/MySummary'));
const Experience = React.lazy(() => import('../pages/apps/Settings/Background/Experience/Experience'));
const DataExperienceDetails = React.lazy(() => import('../pages/apps/Settings/Background/Experience/DataExperienceDetails'));

const Events = React.lazy(() => import('../pages/apps/Settings/Background/Events/Events'));
const EventDetails = React.lazy(() => import('../pages/apps/Settings/Background/Events/EventDetails'));
const Competencies = React.lazy(() => import('../pages/apps/Settings/Background/Competencies/Competencies'));
const CompetencyDetails = React.lazy(() => import('../pages/apps/Settings/Background/Competencies/CompetencyDetails'));
const Trainings = React.lazy(() => import('../pages/apps/Settings/Background/Trainings/Trainings'));
const TrainingDetails = React.lazy(() => import('../pages/apps/Settings/Background/Trainings/TrainingDetails'));

const Departments = React.lazy(() => import('../pages/apps/Home/Departments/Departments'));
const DepartmentDetails = React.lazy(() => import('../pages/apps/Home/Departments/DepartmentDetails'));

const Industries = React.lazy(() => import('../pages/apps/Home/Industries/Industries'));
const IndustryDetails = React.lazy(() => import('../pages/apps/Home/Industries/IndustryDetails'));
const Technologies = React.lazy(() => import('../pages/apps/Home/Technologies/Technologies'));
const TechnologyDetails = React.lazy(() => import('../pages/apps/Home/Technologies/TechnologyDetails'));

const Network = React.lazy(() => import('../pages/apps/Home/Network/Network'));
const NetworkPerson = React.lazy(() => import('../pages/apps/Home/Network/NetworkPerson'));
const NetworkPersonDetails = React.lazy(() => import('../pages/apps/Home/Network/NetworkPersonDetails'));
const NetworkCompany = React.lazy(() => import('../pages/apps/Home/Network/NetworkCompany'));
const NetworkCompanyDetails = React.lazy(() => import('../pages/apps/Home/Network/NetworkCompanyDetails'));
const NetworkAcademics = React.lazy(() => import('../pages/apps/Home/Network/NetworkAcademics'));
const NetworkEvents = React.lazy(() => import('../pages/apps/Home/Network/NetworkEvents'));

const Issues = React.lazy(() => import('../pages/apps/Issues/Issues'));
const IssueDetails = React.lazy(() => import('../pages/apps/Issues/IssueDetails'));

const Connexions = React.lazy(() => import('../pages/apps/Connexions/Connexions'));
const ConnexionDetails = React.lazy(() => import('../pages/apps/Connexions/ConnexionDetails'));

const Repositories = React.lazy(() => import('../pages/apps/Repositories/Repositories'));
const CompanyDetails = React.lazy(() => import('../pages/apps/Settings/Profile/Companies/CompanyDetails'));
const PaymentResult = React.lazy(() => import('../pages/apps/Payment/PaymentResult'));

const PersonalInformation = React.lazy(() => import('../pages/apps/Settings/Profile/PersonalInformation'));
const LinkedIn = React.lazy(() => import('../pages/apps/Settings/Profile/LinkedIn'));
const Photo = React.lazy(() => import('../pages/apps/Settings/Profile/Photo'));
const CVDetails = React.lazy(() => import('../pages/apps/Settings/Profile/CVDetails'));

const Subscriptions = React.lazy(() => import('../pages/apps/Settings/Profile/Subscriptions/Subscriptions'));
const SubscriptionsPending = React.lazy(() => import('../pages/apps/Settings/Profile/Subscriptions/SubscriptionsPending'));
const SubscriptionsCompany = React.lazy(() => import('../pages/apps/Settings/Profile/Subscriptions/SubscriptionsCompany'));
const SubscriptionsAcademy = React.lazy(() => import('../pages/apps/Settings/Profile/Subscriptions/SubscriptionsAcademy'));
const SubscriptionDetails = React.lazy(() => import('../pages/apps/Settings/Profile/Subscriptions/SubscriptionDetails'));

const Academics = React.lazy(() => import('../pages/apps/Home/Education/Academics'));
const AcademyDetails = React.lazy(() => import('../pages/apps/Home/Education/AcademyDetails'));

const Validations = React.lazy(() => import('../pages/apps/Settings/Background/Validations/Validations'));
const ValidationsIn = React.lazy(() => import('../pages/apps/Settings/Background/Validations/ValidationsIn'));
const ValidationsOut = React.lazy(() => import('../pages/apps/Settings/Background/Validations/ValidationsOut'));

const Search = React.lazy(() => import('../pages/apps/Search/Search'));
const SearchResults = React.lazy(() => import('../pages/apps/Search/SearchResults'));

const Alerts = React.lazy(() => import('../pages/apps/Alerts/Alerts'));

const Dashboard = React.lazy(() => import('../pages/apps/Dashboard/Dashboard'));
const OverallDashboard = React.lazy(() => import('../pages/apps/Dashboard/OverallDashboard'));


// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                if (props.location.pathname != '/') {
                    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
                }
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;

        }}
    />
);

// Profile
const profileRootRoutes = {
    name: 'profile.profile',
    icon: FeatherIcon.User,
    children: [
        {
            path: '/profile',
            name: 'profile.me',
            component: PersonalInformation,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/linked-in',
            name: 'profile.me',
            component: LinkedIn,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/photo',
            name: 'profile.me',
            component: Photo,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/cv',
            name: 'profile.me',
            component: CVDetails,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/individual-subscription',
            name: 'profile.subscriptions',
            component: Subscriptions,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/individual-subscription-pending',
            name: 'profile.subscriptions',
            component: SubscriptionsPending,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/subscription-details/:id',
            name: 'profile.subscriptions',
            component: SubscriptionDetails,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/company-subscription',
            name: 'profile.subscriptions',
            component: SubscriptionsCompany,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/academy-subscription',
            name: 'general.memberOfAcademy',
            component: SubscriptionsAcademy,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const companyRootRoutes = {
    name: 'profile.profile',
    icon: FeatherIcon.User,
    path: '/companies',
    children: [
        {
            path: '/companies',
            name: 'company.companies',
            component: Companies,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/company-info',
            name: 'company.companies',
            component: Companies,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/company-details',
            name: 'company.companies',
            component: CompanyDetails,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const academicRootRoutes = {
    name: 'profile.profile',
    icon: FeatherIcon.User,
    path: '/education',
    children: [
        {
            path: '/education',
            name: 'company.academics',
            component: PersonalAcademics,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/education-info/:id',
            name: 'company.academics',
            component: PersonalAcademics,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const competencyRootRoutes = {
    name: 'me.competencies',
    icon: FeatherIcon.User,
    path: '/competencies',
    children: [
        {
            path: '/competencies',
            name: 'me.competencies',
            component: Competencies,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/competencies/:id',
            name: 'me.competencies',
            component: Competencies,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/competency-info',
            name: 'me.competencies',
            component: CompetencyDetails,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const trainingsRootRoutes = {
    name: 'me.trainings',
    icon: FeatherIcon.User,
    path: '/trainings',
    children: [
        {
            path: '/trainings',
            name: 'me.trainings',
            component: Trainings,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/training-info',
            name: 'me.trainings',
            component: Trainings,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const eventRootRoutes = {
    name: 'me.events',
    icon: FeatherIcon.User,
    path: '/events',
    children: [
        {
            path: '/events',
            name: 'me.events',
            component: Events,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/event-category',
            name: 'me.events',
            component: Events,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const paymentResultRoutes = {
    name: 'payment.result',
    icon: FeatherIcon.User,
    children: [
        {
            path: '/payment-result',
            name: 'payment.result',
            component: PaymentResult,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const profileRoutes = [profileRootRoutes];

const repositoriesRootRoutes = {
    name: 'Data Technology',
    path: '/repositories',
    children: [
        {
            path: '/repositories',
            name: 'Data Technology',
            component: Repositories,
            route: Route,
        }]
};
const repositoriesRoutes = [repositoriesRootRoutes];

const mySummaryRootRoutes = {
    name: 'me.mysummary',
    path: '/me',
    children: [
        {
            path: '/me',
            name: 'home.dashboard',
            component: Dashboard,
            route: Route,
        },
        {
            path: '/my-summary',
            name: 'me.mysummary',
            component: MySummary,
            route: Route,
        },
        {
            name: 'topBar.issues',
            path: '/my-issues',
            component: Issues,
            route: Route,
        },
        {
            path: '/validations',
            name: 'me.validations',
            component: Validations,
            route: Route,
        },
        {
            path: '/validations-in',
            name: 'me.validations_in',
            component: ValidationsIn,
            route: Route,
        },
        {
            path: '/validations-out',
            name: 'me.validations_out',
            component: ValidationsOut,
            route: Route,
        },
    ]
};

const netWorkRootRoutes = {
    name: 'home.network',
    path: '/network',
    children: [
        {
            name: 'home.dashboard',
            path: '/network',
            component: Dashboard,
            route: Route,
        },
        {
            name: 'general.person',
            path: '/network-person',
            component: NetworkPerson,
            route: Route,
        },
        {
            name: 'general.person',
            path: '/network-person-details/:id',
            component: NetworkPersonDetails,
            route: Route,
        },
        {
            name: 'subscription.company',
            path: '/network-company',
            component: NetworkCompany,
            route: Route,
        },
        {
            name: 'subscription.company',
            path: '/network-company-details/:id',
            component: NetworkCompanyDetails,
            route: Route,
        },
        {
            name: 'company.academics',
            path: '/network-academics',
            component: NetworkAcademics,
            route: Route,
        },
        {
            name: 'company.academics',
            path: '/network-academics-details/:id',
            component: NetworkAcademicsDetails,
            route: Route,
        },
        {
            name: 'me.events',
            path: '/network-events',
            component: NetworkEvents,
            route: Route,
        }
    ]
};

const experienceRootRoutes = {
    name: 'home.experience',
    path: '/experience',
    children: [
        {
            path: '/experience',
            name: 'home.experience',
            component: Experience,
            route: Route,
        },
        {
            path: '/experience-category',
            name: 'home.experience',
            component: Experience,
            route: Route,
        },
        {
            path: '/experience-details',
            name: 'home.experience',
            component: DataExperienceDetails,
            route: Route,
        }
    ]
};

const departmentsRootRoutes = {
    name: 'departments',
    path: '/departments',
    component: Departments,
    children: [
        {
            path: '/departments',
            name: 'profile.departments',
            component: Departments,
            route: Route,
        },
        {
            path: '/department/:id',
            name: 'department',
            component: DepartmentDetails,
            route: Route,
        }

    ]
};

const technologiesRootRoutes = {
    name: 'techGroup.technologies',
    path: '/technologies',
    component: Technologies,
    children: [
        {
            name: 'techGroup.technologies',
            path: '/technologies',
            component: Technologies,
            route: Route,
        },
        {
            path: '/technology/:id',
            name: 'technology',
            component: TechnologyDetails,
            route: Route,
        }

    ]
};

const industriesRootRoutes = {
    name: 'profile.industries',
    path: '/industries',
    component: Industries,
    children: [
        {
            name: 'profile.industries',
            path: '/industries',
            component: Industries,
            route: Route,
        },
        {
            path: '/industry/:id',
            name: 'industry',
            component: IndustryDetails,
            route: Route,
        }

    ]
};

const academicsRootRoutes = {
    name: 'profile.industries',
    path: '/academics',
    component: Academics,
    children: [
        {
            name: 'profile.industries',
            path: '/academics',
            component: Academics,
            route: Route,
        },
        {
            path: '/academy/:id',
            name: 'academy',
            component: AcademyDetails,
            route: Route,
        }

    ]
};


const issuesRootRoutes = {
    name: 'topBar.issues',
    path: '/issues',
    component: Issues,
    children: [
        {
            name: 'topBar.issues',
            path: '/issues',
            component: Issues,
            route: Route,
        },
        {
            path: '/issue/:id',
            name: 'issue',
            component: IssueDetails,
            route: Route,
        }

    ]
};

const connexionsRootRoutes = {
    name: 'topBar.connexions',
    path: '/connexions',
    component: Connexions,
    children: [
        {
            name: 'topBar.connexions',
            path: '/connexions',
            component: Connexions,
            route: Route,
        },
        {
            path: '/connexion/:id',
            name: 'connexion',
            component: Connexions,
            route: Route,
        }
    ]
};

const backgroundRootRoutes = {
    name: 'profile.background',
    path: '/background',
    children: [
        {
            name: 'profile.background',
            path: '/background',
            component: Dashboard,
            route: Route,
        }
    ]
};

const dashboardRootRoutes = {
    name: 'home.dashboard',
    path: '/dashboard',
    children: [
        {
            name: 'home.dashboard',
            path: '/dashboard',
            component: OverallDashboard,
            route: Route,
        }
    ]
};


const mySummaryRoutes = [mySummaryRootRoutes, netWorkRootRoutes, departmentsRootRoutes, technologiesRootRoutes,
    industriesRootRoutes, issuesRootRoutes, connexionsRootRoutes, academicsRootRoutes, backgroundRootRoutes, dashboardRootRoutes];


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Home',
            component: Home,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/new-password',
            name: 'New Password',
            component: NewPassword,
            route: Route,
        },
        {
            path: '/account/invalid-verification-code',
            name: 'Invalid Verification Code',
            component: InvalidVerificationCode,
            route: Route,
        },
        {
            path: '/account/personalinfo',
            name: 'PersonalInfo',
            component: PersonalInfo,
            route: Route,
        },
        {
            path: '/account/subscribe',
            name: 'Subscribe',
            component: Subscribe,
            route: Route,
        }
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// Application
const profileApplication = {
    name: 'menu.Application',
    icon: FeatherIcon.User,
    children: [
        {
            path: '/application/interface',
            name: 'Application Interface',
            component: Interface,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/application/api',
            name: 'Application API',
            component: Api,
            roles: ['Admin'],
            route: PrivateRoute
        },
    ]
};

const searchRootRoutes = {
    name: 'topBar.search',
    icon: FeatherIcon.User,
    path: '/search',
    children: [
        {
            path: '/search',
            name: 'topBar.search',
            component: Search,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/public_profile/:id',
            name: 'topBar.search',
            component: SearchResults,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/public_academy/:id',
            name: 'topBar.search',
            component: SearchResults,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/public_company/:id',
            name: 'topBar.search',
            component: SearchResults,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/public_competency/:id',
            name: 'topBar.search',
            component: SearchResults,
            roles: ['Admin'],
            route: PrivateRoute
        }
    ]
};

const searchRoutes = [searchRootRoutes];

const alertsRootRoutes = {
    name: 'topBar.alerts',
    icon: FeatherIcon.User,
    path: '/alerts',
    children: [
        {
            path: '/alerts',
            name: 'topBar.alerts',
            component: Alerts,
            roles: ['Admin'],
            route: PrivateRoute
        },
        {
            path: '/alerts/:id',
            name: 'topBar.alerts',
            component: Alerts,
            route: Route,
        }
    ]
};

const alertsRoutes = [alertsRootRoutes];

// All routes
const allRoutes = [
    ...profileRoutes,
    authRoutes,
    mySummaryRootRoutes,
    departmentsRootRoutes,
    technologiesRootRoutes,
    industriesRootRoutes,
    academicsRootRoutes,
    repositoriesRootRoutes,
    issuesRootRoutes,
    connexionsRootRoutes,
    companyRootRoutes,
    paymentResultRoutes,
    profileApplication,
    academicRootRoutes,
    competencyRootRoutes,
    trainingsRootRoutes,
    experienceRootRoutes,
    eventRootRoutes,
    searchRootRoutes,
    alertsRootRoutes,
    netWorkRootRoutes,
    backgroundRootRoutes,
    dashboardRootRoutes
];

const authRoutesTest = [repositoriesRootRoutes];
const repositoriesProtectedRoutes = [...authRoutesTest];
const authSubscriptions = [...profileRoutes];
const authProtectedRoutes = [...profileRoutes, repositoriesRoutes, mySummaryRoutes, searchRoutes, alertsRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes, repositoriesProtectedRoutes, authSubscriptions };

