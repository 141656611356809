import classnames from 'classnames';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Scroll from 'react-scroll';
import {
    Card, CardBody, Col, Container, Label, Nav, Button,
    NavItem, NavLink, Row, TabContent, TabPane, UncontrolledAlert, Alert
} from 'reactstrap';
import { getExternalValidation, updateExternalValidation, getAPICountryInfo } from '../../redux/actions';
import logo_with_text from '../../assets/images/logo-with-text.png';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Loader from '../../components/Loader';
import { VALIDATION_DOCUMENT_CAREER, VALIDATION_DOCUMENT_COMPETENCY, VALIDATION_DOCUMENT_EDUCATION, VALIDATION_DOCUMENT_EVENTS, VALIDATION_DOCUMENT_EXPERIENCE, VALIDATION_DOCUMENT_TRAINING, VALIDATION_TYPE_OUT } from '../../redux/validation/constants';
import { countryCodeToString, customAlertPopup, dateInputDate, getLocalizedString, monthOnlyDisplayString, stateCodeToString } from '../../helpers/utils';
import moment from 'moment';
import * as FeatherIcon from 'react-feather';
import countryList from 'react-select-country-list';
import { confirmAlert } from 'react-confirm-alert';
import { format } from '../../helpers/stringFormat';
import { group } from 'd3';
import AlertPopupView from '../apps/Supports/AlertPopupView';

class ExternalValidation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.validationCode != null) {
            this.props.getExternalValidation(this.props.validationCode, {
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                }
            });
        }

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onButtonReject = () => {
        this.updateValidation(false);
    }

    onButtonValidate = () => {
        this.updateValidation(true);
    }

    updateValidation = (status) => {
        if (this.props.externalValidation == null) {
            return;
        }

        let t = this.props.t;
        let title = (status ?? false) ? t('Accept Validation Request') : t('Reject Validation Request')
        let message = (status ?? false) ? t('Are you sure you want to accept this validation?') : t('Are you sure you want to reject this validation?')

        const countriesList = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let information = this.titleFromValidation(this.props.externalValidation, countriesList);

        this.setState({
            alertTitle:title,
            alertMessage: message,
            alertInformation: information,
            alertCancelTitle: t('No'),
            alertDoneTitle: t('Yes'),
            alertCommentEnable: true,
            alertCommentTitle: format(t('general.messageTo'), this.props.externalValidation?.validation?.userDetails?.fullName ?? ''),
            alertHandleCancel: () => { },
            alertHandleDone: (comment) => {
                this.props.updateExternalValidation(this.props.validationCode, status, comment, {
                    callbackOnBegin: () => {
                        this.setState({ loading: true });
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                        this.setState({ loading: false });
                        this.completeValidation();
                    }
                })
            },
            showAlertPopup: true
        });
    }

    completeValidation = () => {
        let t = this.props.t;
        this.setState({
            alertTitle: t('Validation Successed!'),
            alertMessage: t('You have validated informations successfully.'),
            alertInformation: null,
            alertCancelTitle: null,
            alertDoneTitle: t('Ok'),
            alertCommentEnable: null,
            alertHandleCancel: null,
            alertHandleDone: () => {
                this.props.onValidated();
            },
            showAlertPopup: true
        });
    }

    recursiveAddTypes = (group, targetName, competencyName, elementId, elementName) => {
        if (group) {
            if (elementId === '1000') {
                competencyName = group?.name + " / " + elementName;
            } else {
                for (let i = 0; i < group?.children.length; i++) {
                    let techGroupChildren = group?.children[i];
                    if (competencyName !== '') {
                        break;
                    }
                    for (let j = 0; j < techGroupChildren[targetName].length; j++) {
                        if (elementId === techGroupChildren[targetName][j].id.toString()) {
                            competencyName = techGroupChildren?.name + " / " + techGroupChildren[targetName][j].name;
                            break;
                        }
                    }

                    if (group?.children?.length > 0 && competencyName === '') {
                        for (let i = 0; i < group?.children?.length; i++) {
                            var competencyNameTerm = '';
                            competencyNameTerm = this.recursiveAddTypes(group.children[i], targetName, competencyNameTerm, elementId, elementName);
                            if (competencyNameTerm !== '') {
                                competencyName = techGroupChildren?.name + " / " + competencyNameTerm;
                            }
                            if (competencyName !== '') {
                                break;
                            }
                        }
                    }
                }
            }
        }
        return competencyName;
    }

    recursiveAddValues = (groupDetails, competencyName, elementId, elementName) => {
        let group = groupDetails?.value ?? groupDetails;
        if (group) {
            let groupName = group?.name ?? '';
            if (groupName.startsWith('All ')) {
                groupName = groupName.substring(4, groupName.length);
            }
            if (elementId === '1000') {
                competencyName = groupName + " / " + elementName;
            }
            else
                if (group?.children?.length > 0) {
                    for (let i = 0; i < group?.children?.length; i++) {
                        var competencyNameTerm = '';
                        competencyNameTerm = this.recursiveAddValues(group.children[i], competencyNameTerm, elementId, elementName);
                        if (competencyNameTerm !== '') {
                            competencyName = groupName + " / " + competencyNameTerm;
                        }
                        if (competencyName !== '') {
                            break;
                        }
                    }
                }
                else {
                    if (elementId === group.id.toString()) {
                        if (competencyName.length > 0) {
                            competencyName = competencyName + "/" + groupName
                        }
                        else {
                            competencyName = groupName
                        }
                    }
                }
        }
        return competencyName;
    }

    childrenFromObject = (object) => {
        if (object == null) {
            return [];
        }

        let child = Object.assign({}, object);
        child.children = null;
        let children = [];
        children.push(child);

        let grandChildren = object.children ?? [];
        if (grandChildren.length > 0) {
            for (let i = 0; i < grandChildren.length; i++) {
                let grand = this.childrenFromObject(grandChildren[i])
                children = children.concat(grand)
            }
        }
        return children;
    }

    objectsFromGroup = (group, key) => {
        if (group == null) {
            return [];
        }

        let object = [];
        if (group[key]?.length > 0) {
            object = group[key];
        }

        if (group.children?.length > 0) {
            for (let i = 0; i < group.children.length; i++) {
                let grandChildren = this.objectsFromGroup(group.children[i], key);
                object = object.concat(grandChildren);
            }
        }
        return object;
    }

    goalsFromActionGroup = (group) => {
        if (group == null) {
            return [];
        }

        let goals = [];
        if (group.actions?.length > 0) {
            for (let i = 0; i < group.actions.length; i++) {
                goals = goals.concat(group.actions[i].goals ?? [])
            }
        }

        if (group.children?.length > 0) {
            for (let j = 0; j < group.children.length; j++) {
                goals = goals.concat(this.goalsFromActionGroup(group.children[j]))
            }
        }

        return goals;
    }

    filteredGoals = (actionGroup, companyNodeId) => {
        if (companyNodeId == null || actionGroup == null) {
            return [];
        }

        let goals = this.goalsFromActionGroup(actionGroup);
        goals = goals.filter((element) => { return element.goal?.id == companyNodeId })
        return goals.map((element) => { return { value: element.id, label: element.name, entity: element } });
    }

    titleFromExperience = (experience, allCompanies, group) => {
        const { t } = this.props;

        let title = '';

        let companies = allCompanies?.map((company, index) => {
            let companyName = company?.name ? (company.name + (company.companyIndex ? (" (" + company.companyIndex + ')') : "")) : ''
            return { value: index, label: companyName, entity: company }
        }) ?? [];
        companies.push({ value: 1000, label: t("sub.Others") })

        let positions = this.objectsFromGroup(group?.positionGroup?.value, 'positions').map((element) => { return { value: element.id, label: element.name, entity: element } });

        let issueGroup = this.childrenFromObject(group?.issueGroup?.value);
        if (issueGroup.length > 0) {
            issueGroup = issueGroup.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        let sources = this.childrenFromObject(group?.sourceGroup?.value);
        if (sources.length > 0) {
            sources = sources.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        let results = this.childrenFromObject(group?.buildGroup?.value);
        if (results.length > 0) {
            results = results.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        let departments = this.childrenFromObject(group?.departmentGroup?.value);
        if (departments.length > 0) {
            departments = departments.map((element) => { return { value: element.id, label: element.name, entity: element } })
        }

        title = experience.companyDetail?.name ?? '';

        let selectedPosition = null;
        if (experience.positionNodeId) {
            selectedPosition = positions.find((element) => { return element.value == experience.positionNodeId });
            if (selectedPosition?.label?.length > 0) {
                title += title.length == 0 ? (selectedPosition?.label ?? '') : (' | ' + selectedPosition?.label ?? '');
            }
        }

        let goals = this.filteredGoals(group?.allActionGroup?.value, experience.companyNodeId);
        let selectedGoal = null;
        if (experience.goalNodeId) {
            selectedGoal = goals.find((element) => { return element.value == experience.goalNodeId });
            if (selectedGoal?.label?.length > 0) {
                title += title.length == 0 ? (selectedGoal?.label ?? '') : (' | ' + selectedGoal?.label ?? '');
            }
        }
        else {
            title += title.length == 0 ? (experience?.submitGoalName ?? '') : (' | ' + experience?.submitGoalName ?? '');
        }

        let integrationName = experience.submitIntegrationName ?? '';
        if (integrationName.length > 0) {
            title += title.length == 0 ? (integrationName ?? '') : (' | ' + integrationName);
        }

        let selectedContributions = experience.contributions ?? [];
        let contributionString = '';
        if (selectedContributions.length > 0) {
            for (let i = 0; i < selectedContributions.length; i++) {
                contributionString += (i == 0 ? selectedContributions[i] : (', ' + selectedContributions[i]))
            }
        }
        if (contributionString.length > 0) {
            title += title.length == 0 ? (contributionString) : (' | ' + contributionString);
        }

        return title
    }

    summaryOfAcademyPlace = (academy, place) => {
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        }) ?? [];

        let country = academy.country ? countryCodeToString(academy.country, countries) : '';
        let statesOfCountry = countries?.find((country) => { return country.value == (academy.country ?? '') })?.states;
        let state = place.state != null ? statesOfCountry?.find((e) => e.id === place.state) : null;
        let stateName = getLocalizedString(state?.name);

        let cityName = '';
        if (place?.cityId != null && academy?.academyDetail?.cities?.length > 0) {
            cityName = academy?.academyDetail?.cities.find((city) => { return city.id == place?.cityId })?.city;
        }
        else {
            cityName = place.academyNewCityIntl;
        }
        let location = cityName ?? '';
        if (stateName !== '---') {
            location += (location.length > 0 ? ', ' : '') + stateName;
        }
        if (country.length > 0) {
            location += (location.length > 0 ? ', ' : '') + country;
        }

        let summary = place.monthStart ? monthOnlyDisplayString(place.monthStart) : '';
        let monthEnd = (place.monthEnd ? monthOnlyDisplayString(place.monthEnd) : '')
        if (monthEnd.length > 0) {
            summary += (summary.length > 0 ? ' - ' : '') + monthEnd;
        }

        let facultyName = place.facultyId != 1000 ? (place.facultyDetail?.value ? (getLocalizedString(place.facultyDetail.value)) : '') : (academy.facultyName ?? '');
        if (facultyName.length > 0) {
            summary += (summary.length > 0 ? ' : ' : '') + facultyName;
        }

        place.graduateDegrees.map((degrees) => {
            var specialties = place.facultyDetail?.specialties?.find((op) => {
                return op.order?.toString() === (degrees.facultySpecialtyId?.toString());
            })?.value;

            let defaultGraduateDegree = degrees.graduateDegreeDetail?.value ? getLocalizedString(degrees.graduateDegreeDetail.value) : '';
            let graduateDegree = degrees.graduateDiplomaInt ?? '';
            if (graduateDegree.length == 0) {
                graduateDegree = defaultGraduateDegree;
            }
            else {
                graduateDegree += defaultGraduateDegree.length > 0 ? (' (' + defaultGraduateDegree + ')') : '';
            }

            let defaultSpecialty = specialties ? getLocalizedString(specialties) : '';
            let specialty = degrees.graduationSpecialtyInt ?? '';
            if (specialty.length == 0) {
                specialty = defaultSpecialty;
            }
            else {
                specialty += defaultSpecialty.length > 0 ? (' (' + defaultSpecialty + ')') : '';
            }

            let graduateDegreeDetails = graduateDegree;
            if (specialty.length > 0) {
                graduateDegreeDetails += (graduateDegreeDetails.length > 0 ? ' , ' : '') + specialty;
            }

            if (graduateDegreeDetails.length > 0) {
                summary += (summary.length > 0 ? ' | ' : '') + graduateDegreeDetails;
            }
        })

        return [location, summary];
    }

    titleFromValidation = (validation, countriesList) => {
        const { t } = this.props;

        let title = '';
        let documentDetails = validation?.validation?.documentDetails?.details;
        if (documentDetails == null) {
            return '';
        }

        if (validation?.validation?.documentType == VALIDATION_DOCUMENT_EDUCATION) {
            var academyDetail = documentDetails;
            let academyName = academyDetail?.academyDetail?.name ? academyDetail?.academyDetail.name : '';
            let summary = null;
            if (academyDetail?.academyPlaces?.length > 0) {
                summary = this.summaryOfAcademyPlace(academyDetail, academyDetail?.academyPlaces[0]);
            }

            title = academyName;
            if (summary != null) {
                title += (title.length > 0 ? ' | ' : '') + summary[0];
                title += (title.length > 0 ? ' | ' : '') + summary[1];
            }
        }
        else if (validation?.validation?.documentType == VALIDATION_DOCUMENT_TRAINING) {
            let training = documentDetails;
            var strDesc = '';
            if (training) {
                var companyName = training?.companyDetail?.name ? ("- " + training?.companyDetail.name + " ,") : '- '
                if (training?.companyNodeId == 1000) {
                    companyName = "- " + (training?.companyName ?? '') + " ,";
                }
                const trainingTypeName = validation?.validation?.documentDetails?.group?.value?.children?.find((op) => {
                    return op.id === training.trainingType;
                }).name;
                strDesc = companyName;
                strDesc += trainingTypeName ? (" " + trainingTypeName + " ,") : '';
                strDesc += training?.courseName ? (" " + training?.courseName + " ,") : '';
                strDesc += training?.dateStart ? (" " + monthOnlyDisplayString(moment(training?.dateStart)) + " ,") : '';
                strDesc += training?.nbHours ? (" " + training?.nbHours + " hours.") : '';

                title += strDesc;
            }
        }
        else if (validation?.validation?.documentType == VALIDATION_DOCUMENT_EVENTS) {
            let event = documentDetails;
            var strDesc = '';
            if (event) {
                var companyName = event?.companyDetail?.name ? ("- " + event?.companyDetail.name + " ,") : '- '
                if (event?.companyNodeId == 1000) {
                    companyName = "- " + (event?.companyName ?? '') + " ,";
                }

                const eventTypeName = validation?.validation?.documentDetails?.group?.value?.children?.find((op) => {
                    return op.id === event.eventType;
                }).name;

                strDesc = companyName;
                strDesc += eventTypeName ? (" " + eventTypeName + " ,") : '';
                strDesc += event?.courseName ? (" " + event?.courseName + " ,") : '';
                strDesc += event?.dateStart ? (" " + monthOnlyDisplayString(moment(event?.dateStart)) + " ,") : '';
                strDesc += event?.nbHours ? (" " + event?.nbHours + " hours.") : '';

                title += strDesc;
            }
        }
        else if (validation?.validation?.documentType == VALIDATION_DOCUMENT_CAREER) {
            let careerInfo = documentDetails;
            let companyDetails = validation?.validation?.documentDetails?.companyDetails;
            if (companyDetails?.name?.length > 0) {
                title += companyDetails?.name;
                title += ' - ';
            }

            if (careerInfo) {
                var companyIndustry = null;
                var values = values = validation?.validation?.documentDetails?.companyIndustries ?? [];
                if (validation?.validation?.documentDetails?.industryGroupIds?.length > 0) {
                    values.forEach((element) => {
                        if (element.id === validation?.validation?.documentDetails?.industryGroupIds[0].id) {
                            companyIndustry = element;
                        } else if (element.children) {
                            element.children.forEach((child) => {
                                if (child.id === validation?.validation?.documentDetails?.industryGroupIds[0].id) {
                                    companyIndustry = child;
                                }
                            });
                        }
                    });
                }

                const individualProfiles = this.setIndividualProfile(validation?.validation?.documentDetails?.group);
                var individualProfile = (individualProfiles?.length > 0) ? individualProfiles.find(op => {
                    return op.value === careerInfo.individualProfileId;
                }) : null;
                title += careerInfo?.monthStart ? monthOnlyDisplayString(careerInfo.monthStart) : '-';
                title += ' - ';
                title += careerInfo?.monthEnd ? monthOnlyDisplayString(careerInfo.monthEnd) : monthOnlyDisplayString(dateInputDate(moment()));
                title += ' | ';

                if (companyIndustry?.nodeDetails?.name != null) {
                    title += getLocalizedString(companyIndustry.nodeDetails.name);
                    title += ' - ';
                }

                title += careerInfo?.individualProfileDetail?.name ?? '';

                if (individualProfile) {
                    title += individualProfile?.label ?? '';
                }

                if (careerInfo?.officeCountry) {
                    if (individualProfile?.label?.length > 0) {
                        title += ' | ';
                    }
                    title += careerInfo?.officeCountry ? countryCodeToString(careerInfo.officeCountry, countriesList) : '';
                }
                if (careerInfo?.officeCity) {
                    title += ', ';
                    title += careerInfo?.officeCity ? careerInfo.officeCity : '';
                }
            }
        }
        else if (validation?.validation?.documentType == VALIDATION_DOCUMENT_COMPETENCY) {
            let competencyInfo = documentDetails;
            if (competencyInfo?.idType == 2) {
                // Technology
                if (validation?.validation?.documentDetails?.group?.length > 0) {
                    title += this.recursiveAddTypes(validation?.validation?.documentDetails?.group, 'technologies', '', competencyInfo.elementId, competencyInfo.elementName);
                }
            }
            else if (competencyInfo?.idType == 1) {
                // Business Group / Industry
                if (validation?.validation?.documentDetails?.group?.length > 0) {
                    title += this.recursiveAddTypes(validation?.documentDetails?.group, 'companies', '', competencyInfo.elementId, competencyInfo.elementName);
                }
            }
            else if (competencyInfo?.idType == 3) {
                title += this.recursiveAddValues(validation?.validation?.documentDetails?.group, '', competencyInfo.elementId, competencyInfo.elementName);
            }
            else if (competencyInfo?.idType == 4) {
                title += this.recursiveAddValues(validation?.validation?.documentDetails?.group, '', competencyInfo.elementId, competencyInfo.elementName);
            }
        }
        else if (validation?.validation?.documentType == VALIDATION_DOCUMENT_EXPERIENCE) {
            let experienceInfo = documentDetails;
            title += this.titleFromExperience(experienceInfo, validation?.companies, validation?.validation?.documentDetails?.group);
        }

        return title;
    }

    setIndividualProfile = (positionGroup) => {
        let individualProfiles = this.objectsFromGroup(positionGroup, 'positions').map((element) => { return { value: element.id, label: element.name, entity: element } });
        return individualProfiles;
    };


    render() {
        const { t, externalValidation } = this.props;

        const countriesList = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let user = externalValidation?.validation?.userDetails?.fullName ?? t('signIn.user');
        let email = externalValidation?.validation?.userDetails?.email ?? '';
        let phone = externalValidation?.validation?.userDetails?.phone ?? '';

        let type = '';
        switch (externalValidation?.validation?.documentType) {
            case VALIDATION_DOCUMENT_EDUCATION:
                type = t('academic.academics')
                break;
            case VALIDATION_DOCUMENT_TRAINING:
                type = t('me.trainings')
                break;
            case VALIDATION_DOCUMENT_CAREER:
                type = t('company.companies')
                break;
            case VALIDATION_DOCUMENT_EVENTS:
                type = t('me.events')
                break;
            case VALIDATION_DOCUMENT_EXPERIENCE:
                type = t('home.experience')
                break;
            case VALIDATION_DOCUMENT_COMPETENCY:
                type = t('me.competencies')
                break;
            default:
                break;
        }
        let message = format(t('validations.isRequestingValidation'), type);

        let title = this.titleFromValidation(externalValidation, countriesList);

        return (
            <React.Fragment>
                {this.props.loading && <Loader />}

                {(this.state.showAlertPopup ?? false) &&
                    <AlertPopupView
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        information={this.state.alertInformation}
                        cancelTitle={this.state.alertCancelTitle}
                        doneTitle={this.state.alertDoneTitle}
                        commentEnable={this.state.alertCommentEnable}
                        commentTitle={this.state.alertCommentTitle}
                        handleCancel={this.state.alertHandleCancel}
                        handleDone={this.state.alertHandleDone}
                        onClose={() => { this.setState({ showAlertPopup: false, alertTitle: null, alertMessage: null, alertInformation: null, alertCancelTitle: null, alertDoneTitle: null, alertHandleCancel: null, alertHandleDone: null, alertCommentEnable: null, alertCommentTitle: null }) }}
                    />
                }

                <div className="">
                    <span className="logo-sm">
                        <img src={logo_with_text} alt="" height="70" />
                    </span>
                </div>
                {externalValidation ?
                    <div className='width-percent-100 box-center align-vertical'>
                        <Label className='font-weight-bolder font-size-18'>{t('validations.informationValidation')}</Label>
                        <br />
                        <Label className='font-weight-bolder font-size-18'>{user}</Label>
                        <Label>{email}</Label>
                        <Label>{phone}</Label>
                        <br />
                        <Label>{message}</Label>

                        {/* <br />
                        <Label>{externalValidation ? JSON.stringify(externalValidation) : this.props.validationCode}</Label> */}

                        <br />
                        <div>
                            <Row >
                                <FeatherIcon.CheckCircle className="ml-4" color="green" size={18} />
                                <Label className="title  ml-2">
                                    {title}
                                </Label>
                            </Row>
                        </div>


                        <div className='align-horizontal box-center btn-top-margin'>
                            <Button color="danger" onClick={this.onButtonReject}>{t("common.reject")}</Button>
                            <Button color="primary" className="ml-4" onClick={this.onButtonValidate}>{t("updateCV.validate")}</Button>
                        </div>
                    </div> :
                    <div>
                        {this.state.error &&
                            <Label>{(this.state.error.response && this.state.error.response.data &&
                                this.state.error.response.data.message) ? this.state.error.response.data.message : this.state.error.message}</Label>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { externalValidation, loading, error } = state.Validation;
    const { countries } = state.Auth;

    return { externalValidation, countries, loading, error };
};

export default connect(mapStateToProps, { getExternalValidation, updateExternalValidation, getAPICountryInfo })((withTranslation('translations')(ExternalValidation)));
