// @flow
import {
    GET_REPOSITES,GET_REPOSITES_FAILED,GET_REPOSITES_SUCCESS
} from './constants';
   
   export const getRepoSites = (callbacks) => ({
       type: GET_REPOSITES,
       payload: { callbacks },
   });
   
   export const getRepoSitesSuccess = (data) => ({
       type: GET_REPOSITES_SUCCESS,
       payload: data,
   });
   
   export const getRepoSitesFailed = (error) => ({
       type: GET_REPOSITES_FAILED,
       payload: error,
   });
   