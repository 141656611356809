const companies = [{"symbol": "Data Viz", "name": "", "backgroundColor": "#EE1B2D", "textColor": "white"}, 
{"symbol": "Data Storage", "name": "", "backgroundColor": "#0096D7", "textColor": "white"}, 
{"symbol": "Data Flows", "name": "", "backgroundColor": "#00003A", "textColor": "white"}, 
{"symbol": "Citizens", "name": "", "backgroundColor": "#A100FF", "textColor": "white"}, 
{"symbol": "Industries", "name": "", "backgroundColor": "#000000", "textColor": "white"}, 
{"symbol": "Customers", "name": "", "backgroundColor": "#ED1C24", "textColor": "white"}, 
{"symbol": "Suppliers", "name": "", "backgroundColor": "#000000", "textColor": "white"}, 
{"symbol": "Data Science", "name": "", "backgroundColor": "#EB1B2A", "textColor": "white"}, 
{"symbol": "IA/ML", "name": "", "backgroundColor": "#000000", "textColor": "white"}, 
{"symbol": "Citizens", "name": "", "backgroundColor": "#00A7E1", "textColor": "white"}, 
{"symbol": "Logistics", "name": "", "backgroundColor": "#008FFE", "textColor": "white"}, 
{"symbol": "Employees", "name": "", "backgroundColor": "#009539", "textColor": "white"}];
 export default companies;