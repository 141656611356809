import React,{Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link,Redirect} from 'react-router-dom';
import {Card,CardBody,Col,Container,Row} from 'reactstrap';
import logo from '../../assets/images/logo.png';
import {isUserAuthenticated} from '../../helpers/authUtils';



class InvalidVerificationCode extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (isAuthTokenValid) {
            return <Redirect to='/' />
        }
    }

    render() {
        const { t } = this.props;
        const isAuthTokenValid = isUserAuthenticated();
        return (
            <React.Fragment>

                {this.renderRedirectToRoot()}

                {(this._isMounted || !isAuthTokenValid) && <div className="account-pages my-5">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="text-center">
                                    <CardBody className="p-4">
                                        <div className="mx-auto mb-5">
                                            <a href="/">
                                                <img src={logo} alt="" height="24" />
                                                <h3 className="d-inline align-middle ml-1 text-logo">{t("invalidVerify.invalidVerificationCode")}</h3>
                                            </a>
                                        </div>
                                        
                                        <h6 className="h5 mb-0 mt-4">{t("forgotPassword.dearUser")}</h6>
                                        <p className="text-muted mt-3 mb-3">{t("invalidVerify.ourVerificationCodeInvalidCheckMailAgain")}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="text-center">
                                <p className="text-muted">{t("forgotPassword.returnTo")}<Link to="/" className="text-primary font-weight-bold ml-1">{t("forgotPassword.login")}</Link></p>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </React.Fragment>
        )
    }
}

export default withTranslation('translations')(InvalidVerificationCode);