/* AUTH */
export const POST_PAYMENT = 'POST_PAYMENT';
export const POST_PAYMENT_SUCCESS = 'POST_PAYMENT_SUCCESS';
export const POST_PAYMENT_FAILED = 'POST_PAYMENT_FAILED';

export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED';

export const GET_ALL_PAYMENT = 'GET_ALL_PAYMENT';
export const GET_ALL_PAYMENT_SUCCESS = 'GET_ALL_PAYMENT_SUCCESS';
export const GET_ALL_PAYMENT_FAILED = 'GET_ALL_PAYMENT_FAILED';

export const POST_STATUS_SUBSCRIPTION = 'POST_STATUS_SUBSCRIPTION';
export const POST_STATUS_SUBSCRIPTION_SUCCESS = 'POST_STATUS_SUBSCRIPTION_SUCCESS';
export const POST_STATUS_SUBSCRIPTION_FAILED = 'POST_STATUS_SUBSCRIPTION_FAILED';

export const GET_CHECKOUT_LINK = 'GET_CHECKOUT_LINK';
export const GET_CHECKOUT_LINK_SUCCESS = 'GET_CHECKOUT_LINK_SUCCESS';
export const GET_CHECKOUT_LINK_FAILED = 'GET_CHECKOUT_LINK_FAILED';
