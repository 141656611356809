// @flow
import { AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Button, Card, CardBody, CardHeader, Col, Container,
    Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import { PlusSquare, Trash } from 'react-feather';
import { format } from '../../../helpers/stringFormat';

class ObjectAdjustmentModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            size: 'xx',
            isSelectingObject: false,
            selectedObject: null,
            description: '',
            selectedObjects: [],
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        this.setState({ selectedObjects: this.props.selectedObjects });
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    cancelAdjustObject = () => {
        this.setState({ isSelectingObject: false, selectedObject: null, description: '' })
    }

    finishAdjustObject = () => {
        var selectedObjects = this.state.selectedObjects;
        selectedObjects.push({
            id: this.state.selectedObject?.id,
            description: this.state.description
        })
        this.setState({ isSelectingObject: false, selectedObjects: selectedObjects, selectedObject: null, description: '' })
    }

    render() {
        const { t, objectType, object, allObjects } = this.props;

        let remainingObjects = allObjects.filter((element) => { return !this.state.selectedObjects.some((e) => { return e.id == element.id }) });

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{format(t('general.modify0'), objectType.toLowerCase())}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            <Row>
                                                <Col xl={12}>
                                                    <Row className='mt-2 mb-0'>
                                                        <Col className='path-component font-size-15 text-left mt-2'>
                                                            <Label className='link-selected font-size-15'>{objectType}</Label>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mb-4 border-1 rounded-3'>
                                                        <div className='align-vertical width-percent-100'>
                                                            {this.state.selectedObjects?.length > 0 && this.state.selectedObjects.map((element, index) => {
                                                                let object = allObjects?.find((e) => { return e.id == element.id });

                                                                return <div key={index}>
                                                                    <div className='align-vertical'>
                                                                        <div className='align-horizontal ml-3'>
                                                                            <Label>{object?.label ?? ''}</Label>
                                                                            <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                var selectedObjects = this.state.selectedObjects;
                                                                                selectedObjects.splice(index, 1);
                                                                                this.setState({ selectedObjects: selectedObjects });
                                                                            }}>
                                                                                <Trash className="icon-dual ml-2 icon-size-20" id="remove-object" />
                                                                                <UncontrolledTooltip
                                                                                    placement="top"
                                                                                    id='tooltip-1'
                                                                                    target='remove-object'>
                                                                                    {t("experience.hover.removeObject")}
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        </div>
                                                                        {element?.description?.length > 0 &&
                                                                            <Label className='text-color-subtitle ml-4' style={{ maxWidth: '500px' }} >{element?.description ?? ''}</Label>
                                                                        }
                                                                    </div>

                                                                    {(index == this.state.selectedObjects.length - 1) && remainingObjects.length > 0 && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                </div>
                                                            })}

                                                            <Col md={8} xl={8} className='align-vertical font-size-15 text-left mt-2'>
                                                                {!this.state.isSelectingObject && remainingObjects.length > 0 &&
                                                                    <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                        <div className='align-horizontal'>
                                                                            <PlusSquare className="icon-dual icon-size-20" id="icon-add-object"
                                                                                onClick={() => {
                                                                                    if (this.state.isSelectingObject) {
                                                                                        this.cancelAdjustObject()
                                                                                    }
                                                                                    else {
                                                                                        this.setState({ isSelectingObject: true })
                                                                                    }
                                                                                }} />
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                id='tooltip-1'
                                                                                target='icon-add-object'>
                                                                                {format(t('general.add0'), object.toLowerCase())}
                                                                            </UncontrolledTooltip>
                                                                        </div>

                                                                        <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                            onClick={() => {
                                                                                if (this.state.isSelectingObject) {
                                                                                    this.cancelAdjustObject()
                                                                                }
                                                                                else {
                                                                                    this.setState({ isSelectingObject: true })
                                                                                }
                                                                            }}
                                                                        >{object}</Label>
                                                                    </div>
                                                                }

                                                                {(this.state.isSelectingObject || remainingObjects?.length > 0) &&
                                                                    <div className='width-percent-100'>
                                                                        {this.state.isSelectingObject &&
                                                                            <div className='input-container'>
                                                                                <Select className="react-select"
                                                                                    classNamePrefix="react-select"
                                                                                    name="career" id="career"
                                                                                    options={remainingObjects}
                                                                                    placeholder={t('placeholder.Select')}
                                                                                    value={(this.state.selectedObject && remainingObjects.length > 0) ? remainingObjects.find((element) => { return element.id == this.state.selectedObject.id }) : null}
                                                                                    onChange={(value) => { this.setState({ selectedObject: value }) }} />

                                                                                {(this.props.description ?? true) &&
                                                                                    <Input
                                                                                        className="react-select mt-3"
                                                                                        classNamePrefix="react-select"
                                                                                        type="text"
                                                                                        name="goalDescription"
                                                                                        id="goalDescription"
                                                                                        autoComplete="off"
                                                                                        placeholder={t('nexions.description')}
                                                                                        value={this.state.description}
                                                                                        onChange={(e) => { this.setState({ description: e.target.value }) }}></Input>
                                                                                }

                                                                                <ul className="list-inline wizard mt-3">
                                                                                    <li className="previous list-inline-item">
                                                                                        <Button className='mr-2' onClick={() => { this.cancelAdjustObject() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                        <Button onClick={() => { this.finishAdjustObject() }} color="primary" disabled={this.state.selectedObject == null}>{t('general.Add')}</Button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <br />

                                            <Row>
                                                <Col md={1} xl={1}></Col>
                                                <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                    <div>
                                                        <Button color="white" onClick={() => {
                                                            this.props.close(null);
                                                        }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                        <Button className='ml-2' color="primary" onClick={() => {
                                                            this.props.adjustObject(this.state.selectedObjects);
                                                        }}><i className='uil'></i>{t('personalInfo.save')}</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { network } = state.Network;
    return { country, countries, network, loading, error };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ObjectAdjustmentModal));