// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from './../constants';
import {
    postPaymentFailed,
    postPaymentSuccess,
    getPaymentFailed,
    getPaymentSuccess,
    getAllPaymentFailed,
    getAllPaymentSuccess,
    postStatusSubscriptionFailed,
    postStatusSubscriptionSuccess,
    getCheckoutLinkSuccess, getCheckoutLinkFailed
} from './actions';
import { POST_PAYMENT, GET_PAYMENT, GET_ALL_PAYMENT, POST_STATUS_SUBSCRIPTION, GET_CHECKOUT_LINK } from './constants';

function* postPayment({ payload: { payment, callbacks } }) {
    const { callbackOnBegin, callbackOnFailure, callbackOnFinish, callbackOnSuccess } = callbacks;
    const token = getLoggedInUser().accessToken;
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: payment,
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/payment/', options);

        yield put(postPaymentSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(postPaymentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPayment({ payload: { payment, callbacks } }) {
    const { callbackOnBegin, callbackOnFailure, callbackOnFinish, callbackOnSuccess } = callbacks;
    const token = getLoggedInUser().accessToken;
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: payment,
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/payment/', options);

        yield put(getPaymentSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPaymentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllPayment({ payload: { email, callbacks } }) {
    const { callbackOnBegin, callbackOnFailure, callbackOnFinish, callbackOnSuccess } = callbacks;
    const token = getLoggedInUser().accessToken;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/payment/subscription/' + email, options);

        yield put(getAllPaymentSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAllPaymentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* statusSubscription({ payload: { content, callbacks } }) {
    const { callbackOnBegin, callbackOnFailure, callbackOnFinish, callbackOnSuccess } = callbacks;
    const token = getLoggedInUser().accessToken;
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body:{
            'subscriptionId': content.subscriptionId,
            'status': content.status
        }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/payment/stop-or-downgrade-subscription', options);

        yield put(postStatusSubscriptionSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(postStatusSubscriptionFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCheckoutLinkInternal({ payload: { subscriptionId, callbacks } }) {
    const { callbackOnBegin, callbackOnFailure, callbackOnFinish, callbackOnSuccess } = callbacks;
    const token = getLoggedInUser().accessToken;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/payment/get-checkout-link/' + subscriptionId, options);

        yield put(getCheckoutLinkSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCheckoutLinkFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchPostPayment() {
    yield takeEvery(POST_PAYMENT, postPayment);
}

export function* watchPostStatusSubscription() {
    yield takeEvery(POST_STATUS_SUBSCRIPTION, statusSubscription);
}

export function* watchGetPayment() {
    yield takeEvery(GET_PAYMENT, getPayment);
}

export function* watchGetAllPayment() {
    yield takeEvery(GET_ALL_PAYMENT, getAllPayment);
}

export function* watchGetCheckoutLink() {
    yield takeEvery(GET_CHECKOUT_LINK, getCheckoutLinkInternal);
}

function* paymentSaga() {
    yield all([fork(watchPostPayment), fork(watchGetPayment), fork(watchGetAllPayment), fork(watchPostStatusSubscription), fork(watchGetCheckoutLink)]);
}

export default paymentSaga;
