// @flow
import { param } from 'jquery';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from './../constants';
import {
    checkCompanyIdFailed, checkCompanyIdSuccess, checkNameCompanyFailed, checkNameCompanySuccess,
    createCompanyFailed, createCompanySuccess, getAllContentsCompanyFailed, getAllContentsCompanySuccess,
    getCareerCompanyDetailsFailed, getCareerCompanyDetailsSuccess, getFellowsCompanyFailed, getFellowsCompanySuccess,
    updateCompanyFailed, updateCompanySuccess, checkEmailCompanyFailed, checkEmailCompanySuccess,
    createCareerSuccess, createCareerFailed,
    editCareerSuccess, editCareerFailed,
    getEmailsCompanySuccess, getEmailsCompanyFailed,
    getCareerCompaniesFailed, getCareerCompaniesSuccess,
    changeEmailOnCompanyFailed, changeEmailOnCompanySuccess,
    deleteEmailOnCompanySuccess, deleteEmailOnCompanyFailed,
    changeEmailOnAcademicSuccess, changeEmailOnAcademicFailed,
    deleteEmailOnAcademicSuccess, deleteEmailOnAcademicFailed,
    getCompanyProfilesSuccess, getCompanyProfilesFailed,
    getCompanySpecialtiesSuccess, getCompanySpecialtiesFailed,
    getCompanyPositionTypesSuccess, getCompanyPositionTypesFailed,
    deleteCareerCompanySuccess, deleteCareerCompanyFailed,
    deleteCompanySuccess, deleteCompanyFailed,
    editCompanySuccess, editCompanyFailed,
    getCompaniesSuccess, getCompaniesFailed,
    getPositionsOfCompanySuccess, getPositionsOfCompanyFailed,
    getPublicCompanySuccess, getPublicCompanyFailed,
    editCompanyCareerFailed, editCompanyCareerSuccess,
    getGoalsOfCompanySuccess, getGoalsOfCompanyFailed,
    setCompanyAdminSuccess, setCompanyAdminFailed
} from './actions';
import {
    CHECK_COMPANY_ID, CHECK_NAME_COMPANY, CREATE_COMPANY, GET_CAREER_COMPANY_DETAILS, GET_COMPANY, GET_FELLOWS,
    CREATE_CAREER, EDIT_CAREER, GET_EMAILS_COMPANY,
    GET_CAREER_COMPANIES, UPDATE_COMPANY,
    CHECK_EMAIL_COMPANY, CHANGE_EMAIL_COMPANY, DELETE_EMAIL_COMPANY,
    CHANGE_EMAIL_ACADEMIC, DELETE_EMAIL_ACADEMIC,
    GET_COMPANY_PROFILES, GET_COMPANY_SPECIALTIES, GET_COMPANY_POSITION_TYPES,
    DELETE_CAREER_COMPANY, EDIT_COMPANY_CAREER,
    DELETE_COMPANY, EDIT_COMPANY, GET_COMPANIES, GET_POSITIONS_OF_COMPANY, GET_PUBLIC_COMPANY,
    GET_COMPANY_GOALS,
    SET_COMPANY_ADMIN
} from './constants';

/**
 * Register the user
 */
function* getAllCompanyDetails({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/localize/companies-info', options);

        yield put(getAllContentsCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAllContentsCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get profiles of companies
 */
function* getCompanyProfilesInterval({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/company-profiles', options);

        yield put(getCompanyProfilesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCompanyProfilesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get specialties of faculties from profiles of companies
 */
function* getCompanySpecialtiesInterval({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/company-specialties', options);

        yield put(getCompanySpecialtiesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCompanySpecialtiesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get specialties of faculties from profiles of companies
 */
function* getCompanyPositionTypesInterval({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/company-positionTypes', options);

        yield put(getCompanyPositionTypesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCompanyPositionTypesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCareerCompanyDetails({ payload: { companyId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/career-company/' + companyId, options);

        yield put(getCareerCompanyDetailsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCareerCompanyDetailsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getFellowsCompanyInternal({ payload: { companyId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/get-company-fellow/' + companyId, options);

        yield put(getFellowsCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getFellowsCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCareerCompanies({ payload: { callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/list-careers-companies', options);

        yield put(getCareerCompaniesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCareerCompaniesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteCareerCompany({ payload: { companyId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/delete-career-company/' + companyId, options);

        yield put(deleteCareerCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteCareerCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteCompany({ payload: { companyId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/delete-company/' + companyId, options);

        yield put(deleteCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editCompany({ payload: { data, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/edit-company', options);

        yield put(editCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editCompanyCareer({ payload: { params, careerId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        var url = BASE_URL + "companies/" + careerId + "/edit-company-career"
        const response = yield call(fetchJSON, url, options);

        yield put(editCompanyCareerSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editCompanyCareerFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkEmailCompany({ payload: { email, callbacks } }) {

    const token = getLoggedInUser().accessToken;
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/email-isexit-companies/' + email, options);

        yield put(checkEmailCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(checkEmailCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* changeEmail({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/change-email', options);


        yield put(changeEmailOnCompanySuccess(response.body));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(changeEmailOnCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteEmailInternal({ payload: { email, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/delete-email/' + email, options);

        yield put(deleteEmailOnCompanySuccess(email));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, email);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteEmailOnCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* changeEmailAcademicInternal({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/academics/change-email', options);

        yield put(changeEmailOnAcademicSuccess(response.body));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(changeEmailOnAcademicFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteEmailAcademicInternal({ payload: { email, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/academics/delete-email/' + email, options);

        yield put(deleteEmailOnAcademicSuccess(email));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, email);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteEmailOnAcademicFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getEmailsCompany({ payload: { callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/list-emails-companies', options);

        yield put(getEmailsCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getEmailsCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNewCompany({ payload: { company, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: company
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/create-company', options);


        yield put(createCompanySuccess(response.body));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNewCareer({ payload: { career, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: career
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/create-career', options);

        yield put(createCareerSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createCareerFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editCareerInternal({ payload: { careerId, careerInfoId, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/edit-career/' + careerId + '/' + careerInfoId, options);

        yield put(editCareerSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editCareerFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* updateCompany({ payload: { company, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: company
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/update-company', options);


        yield put(updateCompanySuccess(response.body));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkNameCompany({ payload: { companyName, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: { "name": companyName }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/check-company-name', options);


        yield put(checkNameCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(checkNameCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkCompanyId({ payload: { companyId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: { "companyId": companyId }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/check-company-id', options);


        yield put(checkCompanyIdSuccess(response.body));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(checkCompanyIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCompaniesInternal({ payload: { callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/all', options);

        yield put(getCompaniesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCompaniesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPositionsOfCompanyInternal({ payload: { id, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/experiences/position-for-company/' + id, options);

        yield put(getPositionsOfCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPositionsOfCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPublicCompanyInternal({ payload: { id, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/company-by-id/' + id, options);

        yield put(getPublicCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPublicCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getGoalsOfCompanyInternal({ payload: { companyId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/' + companyId + '/goals' , options);

        yield put(getGoalsOfCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getGoalsOfCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* setCompanyAdminInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'companies/set-admin', options);

        yield put(setCompanyAdminSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setCompanyAdminFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetAllCompanyDetails() {
    yield takeEvery(GET_COMPANY, getAllCompanyDetails);
}

export function* watchGetCompanyProfiles() {
    yield takeEvery(GET_COMPANY_PROFILES, getCompanyProfilesInterval);
}

export function* watchGetCompanySpecialties() {
    yield takeEvery(GET_COMPANY_SPECIALTIES, getCompanySpecialtiesInterval);
}

export function* watchGetCompanyPositionTypes() {
    yield takeEvery(GET_COMPANY_POSITION_TYPES, getCompanyPositionTypesInterval);
}

export function* watchGetCareerCompany() {
    yield takeEvery(GET_CAREER_COMPANY_DETAILS, getCareerCompanyDetails);
}

export function* watchGetInfoUpgradeCompany() {
    yield takeEvery(GET_CAREER_COMPANIES, getCareerCompanies);
}

export function* watchGetInfoEmailsCompany() {
    yield takeEvery(GET_EMAILS_COMPANY, getEmailsCompany);
}

export function* watchCreateCompany() {
    yield takeEvery(CREATE_COMPANY, createNewCompany);
}

export function* watchCreateCareer() {
    yield takeEvery(CREATE_CAREER, createNewCareer);
}

export function* watchEditCareer() {
    yield takeEvery(EDIT_CAREER, editCareerInternal);
}

export function* watchUpdateCompany() {
    yield takeEvery(UPDATE_COMPANY, updateCompany);
}

export function* watchCheckNameCompany() {
    yield takeEvery(CHECK_NAME_COMPANY, checkNameCompany);
}

export function* watchCheckCompanyId() {
    yield takeEvery(CHECK_COMPANY_ID, checkCompanyId);
}

export function* watchGetFellowsCompany() {
    yield takeEvery(GET_FELLOWS, getFellowsCompanyInternal);
}

export function* watchCheckEmailExit() {
    yield takeEvery(CHECK_EMAIL_COMPANY, checkEmailCompany);
}

export function* watchChangeEmail() {
    yield takeEvery(CHANGE_EMAIL_COMPANY, changeEmail);
}

export function* watchDeleteEmail() {
    yield takeEvery(DELETE_EMAIL_COMPANY, deleteEmailInternal);
}

export function* watchChangeEmailAcademic() {
    yield takeEvery(CHANGE_EMAIL_ACADEMIC, changeEmailAcademicInternal);
}

export function* watchDeleteEmailAcademic() {
    yield takeEvery(DELETE_EMAIL_ACADEMIC, deleteEmailAcademicInternal);
}

export function* watchDeleteCareerCompany() {
    yield takeEvery(DELETE_CAREER_COMPANY, deleteCareerCompany);
}

export function* watchDeleteCompany() {
    yield takeEvery(DELETE_COMPANY, deleteCompany);
}

export function* watchEditCompany() {
    yield takeEvery(EDIT_COMPANY, editCompany);
}

export function* watchEditCompanyCareer() {
    yield takeEvery(EDIT_COMPANY_CAREER, editCompanyCareer);
}

export function* watchGetCompanies() {
    yield takeEvery(GET_COMPANIES, getCompaniesInternal);
}

export function* watchGetPositionsOfCompany() {
    yield takeEvery(GET_POSITIONS_OF_COMPANY, getPositionsOfCompanyInternal);
}

export function* watchGetPublicCompany() {
    yield takeEvery(GET_PUBLIC_COMPANY, getPublicCompanyInternal);
}

export function* watchGetGoalsOfCompany() {
    yield takeEvery(GET_COMPANY_GOALS, getGoalsOfCompanyInternal);
}

export function* watchSetAdmin() {
    yield takeEvery(SET_COMPANY_ADMIN, setCompanyAdminInternal);
}


function* companySaga() {
    yield all([fork(watchGetAllCompanyDetails), fork(watchGetCompanyProfiles), fork(watchGetCompanySpecialties), fork(watchGetCompanyPositionTypes),
    fork(watchCreateCompany), fork(watchUpdateCompany), 
    fork(watchCheckNameCompany), fork(watchGetFellowsCompany), fork(watchCheckCompanyId),
    fork(watchGetInfoUpgradeCompany), fork(watchGetCareerCompany), fork(watchCreateCareer), fork(watchEditCareer),
    fork(watchGetInfoEmailsCompany), fork(watchGetInfoEmailsCompany), fork(watchCheckEmailExit),
    fork(watchChangeEmail), fork(watchDeleteEmail),
    fork(watchChangeEmailAcademic), fork(watchDeleteEmailAcademic),
    fork(watchDeleteCareerCompany), fork(watchEditCompany),
    fork(watchGetCompanies), fork(watchGetPositionsOfCompany), fork(watchGetPublicCompany),
    fork(watchEditCompanyCareer), fork(watchGetGoalsOfCompany), fork(watchSetAdmin)]);
}

export default companySaga;
