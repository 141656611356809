// @flow
import {
    GET_ACADEMICS, GET_ACADEMICS_FAILED, GET_ACADEMICS_SUCCESS,
    GET_ACADEMIC_BY_ID, GET_ACADEMIC_BY_ID_SUCCESS, GET_ACADEMIC_BY_ID_FAILED,
    GET_ACADEMIC_DOC, GET_ACADEMIC_DOC_FAILED, GET_ACADEMIC_DOC_SUCCESS,
    CREATE_ACADEMIC, CREATE_ACADEMIC_FAILED, CREATE_ACADEMIC_SUCCESS,
    DELETE_ACADEMIC, DELETE_ACADEMIC_FAILED, DELETE_ACADEMIC_SUCCESS,
    GET_ACADEMICS_IN_COUNTRY, GET_ACADEMICS_IN_COUNTRY_FAILED, GET_ACADEMICS_IN_COUNTRY_SUCCESS,
    GET_PUBLIC_ACADEMY, GET_PUBLIC_ACADEMY_SUCCESS, GET_PUBLIC_ACADEMY_FAILED,
    GET_EMAILS_ACADEMICS, GET_EMAILS_ACADEMICS_SUCCESS, GET_EMAILS_ACADEMICS_FAILED,
    GET_ACADEMIC_GOALS, GET_ACADEMIC_GOALS_SUCCESS, GET_ACADEMIC_GOALS_FAILED,
    GET_ACADEMIC_FELLOWS, GET_ACADEMIC_FELLOWS_SUCCESS, GET_ACADEMIC_FELLOWS_FAILED,
    SET_ACADEMICS_ADMIN, SET_ACADEMICS_ADMIN_SUCCESS, SET_ACADEMICS_ADMIN_FAILED
} from './constants';

export const getAcademics = (userId, callbacks) => ({
    type: GET_ACADEMICS,
    payload: { userId, callbacks },
});

export const getAcademicsSuccess = (data) => ({
    type: GET_ACADEMICS_SUCCESS,
    payload: data,
});

export const getAcademicsFailed = (error) => ({
    type: GET_ACADEMICS_FAILED,
    payload: error,
});

export const getAcademicById = (id, callbacks) => ({
    type: GET_ACADEMIC_BY_ID,
    payload: { id, callbacks },
});

export const getAcademicByIdSuccess = (data) => ({
    type: GET_ACADEMIC_BY_ID_SUCCESS,
    payload: data,
});

export const getAcademicByIdFailed = (error) => ({
    type: GET_ACADEMIC_BY_ID_FAILED,
    payload: error,
});

export const getAcademicsInCountry = (countryCode, callbacks) => ({
    type: GET_ACADEMICS_IN_COUNTRY,
    payload: { countryCode, callbacks },
});

export const getAcademicsInCountrySuccess = (data) => ({
    type: GET_ACADEMICS_IN_COUNTRY_SUCCESS,
    payload: data,
});

export const getAcademicsInCountryFailed = (error) => ({
    type: GET_ACADEMICS_IN_COUNTRY_FAILED,
    payload: error,
});

export const getAcademicDoc = (callbacks) => ({
    type: GET_ACADEMIC_DOC,
    payload: { callbacks },
});

export const getAcademicDocSuccess = (data) => ({
    type: GET_ACADEMIC_DOC_SUCCESS,
    payload: data,
});

export const getAcademicDocFailed = (error) => ({
    type: GET_ACADEMIC_DOC_FAILED,
    payload: error,
});

export const createAcademic = (academic, callbacks) => ({
    type: CREATE_ACADEMIC,
    payload: { academic, callbacks },
});

export const createAcademicSuccess = (data) => ({
    type: CREATE_ACADEMIC_SUCCESS,
    payload: data,
});

export const createAcademicFailed = (error) => ({
    type: CREATE_ACADEMIC_FAILED,
    payload: error,
});

export const deleteAcademic = (academicId, placeId, callbacks) => ({
    type: DELETE_ACADEMIC,
    payload: { academicId, placeId, callbacks },
});

export const deleteAcademicSuccess = (data) => ({
    type: DELETE_ACADEMIC_SUCCESS,
    payload: data,
});

export const deleteAcademicFailed = (error) => ({
    type: DELETE_ACADEMIC_FAILED,
    payload: error,
});

export const getPublicAcademy = (id, callbacks) => ({
    type: GET_PUBLIC_ACADEMY,
    payload: { id, callbacks },
});

export const getPublicAcademySuccess = (academy) => ({
    type: GET_PUBLIC_ACADEMY_SUCCESS,
    payload: academy,
});

export const getPublicAcademyFailed = (error) => ({
    type: GET_PUBLIC_ACADEMY_FAILED,
    payload: error,
});


export const getEmailsAcademics = (callbacks) => ({
    type: GET_EMAILS_ACADEMICS,
    payload: { callbacks },
});

export const getEmailsAcademicsSuccess = (data) => ({
    type: GET_EMAILS_ACADEMICS_SUCCESS,
    payload: data,
});

export const getEmailsAcademicsFailed = (error) => ({
    type: GET_EMAILS_ACADEMICS_FAILED,
    payload: error,
});

export const getGoalsOfAcademic = (academicId, callbacks) => ({
    type: GET_ACADEMIC_GOALS,
    payload: { academicId, callbacks },
});

export const getGoalsOfAcademicSuccess = (goals) => ({
    type: GET_ACADEMIC_GOALS_SUCCESS,
    payload: goals,
});

export const getGoalsOfAcademicFailed = (error) => ({
    type: GET_ACADEMIC_GOALS_FAILED,
    payload: error,
});

export const getAcademicFellows = (academicId, callbacks) => ({
    type: GET_ACADEMIC_FELLOWS,
    payload: { academicId, callbacks },
});

export const getAcademicFellowsSuccess = (response) => ({
    type: GET_ACADEMIC_FELLOWS_SUCCESS,
    payload: response,
});

export const getAcademicFellowsFailed = (error) => ({
    type: GET_ACADEMIC_FELLOWS_FAILED,
    payload: error,
});

export const setAcademicsAdmin = (params, callbacks) => ({
    type: SET_ACADEMICS_ADMIN,
    payload: { params, callbacks },
});

export const setAcademicsAdminSuccess = (response) => ({
    type: SET_ACADEMICS_ADMIN_SUCCESS,
    payload: response,
});

export const setAcademicsAdminFailed = (error) => ({
    type: SET_ACADEMICS_ADMIN_FAILED,
    payload: error,
});