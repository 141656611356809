// @flow
import {
    GET_REPOPROFILES,GET_REPOPROFILES_FAILED,GET_REPOPROFILES_SUCCESS
} from './constants';

const INIT_STATE = {
    repoprofiles: [],
    introductionMedia: null,
    loading: false,
    error: null
};

const RepoProfiles = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPOPROFILES:
            return { ...state, loading: true };
        case GET_REPOPROFILES_SUCCESS:
            return { ...state, repoprofiles: action.payload.profiles, introductionMedia: action.payload.introductionMedia,loading: false, error: null };
        case GET_REPOPROFILES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RepoProfiles;
