// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    GET_TRAINING, GET_TRAINING_FAILED, GET_TRAINING_SUCCESS,
    EDIT_TRAINING_DOC, EDIT_TRAINING_DOC_FAILED, EDIT_TRAINING_DOC_SUCCESS,
    CREATE_TRAINING, CREATE_TRAINING_FAILED, CREATE_TRAINING_SUCCESS,
    DELETE_TRAINING_DOC, DELETE_TRAINING_DOC_FAILED, DELETE_TRAINING_DOC_SUCCESS,
    GET_TRAINING_BY_TYPE_ID, GET_TRAINING_BY_TYPE_ID_FAILED, GET_TRAINING_BY_TYPE_ID_SUCCESS,
    GET_TRAINING_BY_ID, GET_TRAINING_BY_ID_FAILED, GET_TRAINING_BY_ID_SUCCESS
} from './constants';

const INIT_STATE = {
    training: {},
    trainingsOwnerId: null,
    trainings: null,
    trainingsByType: null,
    loading: false,
    error: null
};

const Training = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRAINING:
            if (state.trainingsOwnerId != action.payload.userId) {
                return { ...state, trainings: null, trainingsOwnerId: action.payload.userId, loading: true };
            }
            else {
                return { ...state, loading: true };
            }
        case GET_TRAINING_SUCCESS:
            if (state.trainingsOwnerId == action.payload.userId) {
                return { ...state, trainings: action.payload.data, loading: false, error: null };
            }
            else {
                return { ...state, loading: false, error: null };
            }
        case GET_TRAINING_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_TRAINING_BY_TYPE_ID:
            return { ...state, loading: true };
        case GET_TRAINING_BY_TYPE_ID_SUCCESS:
            return { ...state, trainingsByType: action.payload, loading: false, error: null };
        case GET_TRAINING_BY_TYPE_ID_FAILED:
            return { ...state, trainingsByType: null, error: action.payload, loading: false };

        case GET_TRAINING_BY_ID:
            return { ...state, loading: true };
        case GET_TRAINING_BY_ID_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_TRAINING_BY_TYPE_ID_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_TRAINING_DOC:
            return { ...state, loading: true };
        case EDIT_TRAINING_DOC_SUCCESS:
            return { ...state, training: action.payload, loading: false, error: null };
        case EDIT_TRAINING_DOC_FAILED:
            return { ...state, training: null, error: action.payload, loading: false };

        case DELETE_TRAINING_DOC:
            return { ...state, loading: true };
        case DELETE_TRAINING_DOC_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_TRAINING_DOC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_TRAINING:
            return { ...state, loading: true };
        case CREATE_TRAINING_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_TRAINING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Training;
