// @flow
import {
    CHECK_COMPANY_ID, CHECK_COMPANY_ID_FAILED, CHECK_COMPANY_ID_SUCCESS,
    CHECK_NAME_COMPANY, CHECK_NAME_COMPANY_FAILED, CHECK_NAME_COMPANY_SUCCESS,
    CREATE_COMPANY, CREATE_COMPANY_FAILED, CREATE_COMPANY_SUCCESS, GET_COMPANY,
    GET_COMPANY_FAILED, GET_COMPANY_SUCCESS, GET_FELLOWS, GET_FELLOWS_FAILED,
    GET_COMPANY_PROFILES, GET_COMPANY_PROFILES_SUCCESS, GET_COMPANY_PROFILES_FAILED,
    GET_COMPANY_SPECIALTIES, GET_COMPANY_SPECIALTIES_SUCCESS, GET_COMPANY_SPECIALTIES_FAILED,
    GET_COMPANY_POSITION_TYPES, GET_COMPANY_POSITION_TYPES_SUCCESS, GET_COMPANY_POSITION_TYPES_FAILED,
    GET_FELLOWS_SUCCESS, GET_CAREER_COMPANIES, GET_CAREER_COMPANIES_FAILED,
    GET_CAREER_COMPANIES_SUCCESS, UPDATE_COMPANY, UPDATE_COMPANY_FAILED, UPDATE_COMPANY_SUCCESS,
    GET_CAREER_COMPANY_DETAILS, GET_CAREER_COMPANY_DETAILS_SUCCESS, GET_CAREER_COMPANY_DETAILS_FAILED,
    CREATE_CAREER, CREATE_CAREER_SUCCESS, CREATE_CAREER_FAILED,
    EDIT_CAREER, EDIT_CAREER_SUCCESS, EDIT_CAREER_FAILED,
    GET_EMAILS_COMPANY, GET_EMAILS_COMPANY_FAILED, GET_EMAILS_COMPANY_SUCCESS,
    CHECK_EMAIL_COMPANY, CHECK_EMAIL_COMPANY_FAILED, CHECK_EMAIL_COMPANY_SUCCESS,
    CHANGE_EMAIL_COMPANY, CHANGE_EMAIL_COMPANY_FAILED, CHANGE_EMAIL_COMPANY_SUCCESS,
    DELETE_EMAIL_COMPANY, DELETE_EMAIL_COMPANY_SUCCESS, DELETE_EMAIL_COMPANY_FAILED,
    CHANGE_EMAIL_ACADEMIC, CHANGE_EMAIL_ACADEMIC_SUCCESS, CHANGE_EMAIL_ACADEMIC_FAILED,
    DELETE_EMAIL_ACADEMIC, DELETE_EMAIL_ACADEMIC_SUCCESS, DELETE_EMAIL_ACADEMIC_FAILED,
    DELETE_CAREER_COMPANY, DELETE_CAREER_COMPANY_FAILED, DELETE_CAREER_COMPANY_SUCCESS,
    DELETE_COMPANY, DELETE_COMPANY_FAILED, DELETE_COMPANY_SUCCESS,
    EDIT_COMPANY, EDIT_COMPANY_FAILED, EDIT_COMPANY_SUCCESS,
    GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILED,
    GET_POSITIONS_OF_COMPANY, GET_POSITIONS_OF_COMPANY_SUCCESS, GET_POSITIONS_OF_COMPANY_FAILED,
    GET_PUBLIC_COMPANY, GET_PUBLIC_COMPANY_SUCCESS, GET_PUBLIC_COMPANY_FAILED,
    EDIT_COMPANY_CAREER, EDIT_COMPANY_CAREER_SUCCESS, EDIT_COMPANY_CAREER_FAILED,
    GET_COMPANY_GOALS, GET_COMPANY_GOALS_SUCCESS, GET_COMPANY_GOALS_FAILED,
    SET_COMPANY_ADMIN, SET_COMPANY_ADMIN_SUCCESS, SET_COMPANY_ADMIN_FAILED
} from './constants';

export const getAllContentsCompany = (callbacks) => ({
    type: GET_COMPANY,
    payload: { callbacks },
});

export const getAllContentsCompanySuccess = (photo) => ({
    type: GET_COMPANY_SUCCESS,
    payload: photo,
});

export const getAllContentsCompanyFailed = (error) => ({
    type: GET_COMPANY_FAILED,
    payload: error,
});

export const getCompanyProfiles = (callbacks) => ({
    type: GET_COMPANY_PROFILES,
    payload: { callbacks },
});

export const getCompanyProfilesSuccess = (profiles) => ({
    type: GET_COMPANY_PROFILES_SUCCESS,
    payload: profiles,
});

export const getCompanyProfilesFailed = (error) => ({
    type: GET_COMPANY_PROFILES_FAILED,
    payload: error,
});

export const getCompanySpecialties = (callbacks) => ({
    type: GET_COMPANY_SPECIALTIES,
    payload: { callbacks },
});

export const getCompanySpecialtiesSuccess = (specialties) => ({
    type: GET_COMPANY_SPECIALTIES_SUCCESS,
    payload: specialties,
});

export const getCompanySpecialtiesFailed = (error) => ({
    type: GET_COMPANY_SPECIALTIES_FAILED,
    payload: error,
});

export const getCompanyPositionTypes = (callbacks) => ({
    type: GET_COMPANY_POSITION_TYPES,
    payload: { callbacks },
});

export const getCompanyPositionTypesSuccess = (positionTypes) => ({
    type: GET_COMPANY_POSITION_TYPES_SUCCESS,
    payload: positionTypes,
});

export const getCompanyPositionTypesFailed = (error) => ({
    type: GET_COMPANY_POSITION_TYPES_FAILED,
    payload: error,
});

export const getCareerCompanyDetails = (companyId, callbacks) => ({
    type: GET_CAREER_COMPANY_DETAILS,
    payload: { companyId, callbacks },
});

export const getCareerCompanyDetailsSuccess = (careers) => ({
    type: GET_CAREER_COMPANY_DETAILS_SUCCESS,
    payload: careers,
});

export const getCareerCompanyDetailsFailed = (error) => ({
    type: GET_CAREER_COMPANY_DETAILS_FAILED,
    payload: error,
});

export const deleteCareerCompany = (companyId, callbacks) => ({
    type: DELETE_CAREER_COMPANY,
    payload: { companyId, callbacks },
});

export const deleteCareerCompanySuccess = (careers) => ({
    type: DELETE_CAREER_COMPANY_SUCCESS,
    payload: careers,
});

export const deleteCareerCompanyFailed = (error) => ({
    type: DELETE_CAREER_COMPANY_FAILED,
    payload: error,
});

export const deleteCompany = (companyId, callbacks) => ({
    type: DELETE_COMPANY,
    payload: { companyId, callbacks },
});

export const deleteCompanySuccess = (careers) => ({
    type: DELETE_COMPANY_SUCCESS,
    payload: careers,
});

export const deleteCompanyFailed = (error) => ({
    type: DELETE_COMPANY_FAILED,
    payload: error,
});

export const getFellowsCompany = (companyId, callbacks) => ({
    type: GET_FELLOWS,
    payload: { companyId, callbacks },
});

export const getFellowsCompanySuccess = (company) => ({
    type: GET_FELLOWS_SUCCESS,
    payload: company,
});

export const getFellowsCompanyFailed = (error) => ({
    type: GET_FELLOWS_FAILED,
    payload: error,
});

export const getCareerCompanies = (callbacks) => ({
    type: GET_CAREER_COMPANIES,
    payload: { callbacks },
});

export const getCareerCompaniesSuccess = (company) => ({
    type: GET_CAREER_COMPANIES_SUCCESS,
    payload: company,
});

export const getCareerCompaniesFailed = (error) => ({
    type: GET_CAREER_COMPANIES_FAILED,
    payload: error,
});

export const getEmailsCompany = (callbacks) => ({
    type: GET_EMAILS_COMPANY,
    payload: { callbacks },
});

export const getEmailsCompanySuccess = (company) => ({
    type: GET_EMAILS_COMPANY_SUCCESS,
    payload: company,
});

export const getEmailsCompanyFailed = (error) => ({
    type: GET_EMAILS_COMPANY_FAILED,
    payload: error,
});

export const createCompany = (company, callbacks) => ({
    type: CREATE_COMPANY,
    payload: { company, callbacks },
});

export const createCompanySuccess = (photo) => ({
    type: CREATE_COMPANY_SUCCESS,
    payload: photo,
});

export const createCompanyFailed = (error) => ({
    type: CREATE_COMPANY_FAILED,
    payload: error,
});

export const createCareer = (career, callbacks) => ({
    type: CREATE_CAREER,
    payload: { career, callbacks },
});

export const createCareerSuccess = (career) => ({
    type: CREATE_CAREER_SUCCESS,
    payload: career,
});

export const createCareerFailed = (error) => ({
    type: CREATE_CAREER_FAILED,
    payload: error,
});

export const editCareer = (careerId, careerInfoId, params, callbacks) => ({
    type: EDIT_CAREER,
    payload: { careerId, careerInfoId, params, callbacks },
});

export const editCareerSuccess = (career) => ({
    type: EDIT_CAREER_SUCCESS,
    payload: career,
});

export const editCareerFailed = (error) => ({
    type: EDIT_CAREER_FAILED,
    payload: error,
});

export const editCompanyCareer = (params, careerId, callbacks) => ({
    type: EDIT_COMPANY_CAREER,
    payload: { params, careerId, callbacks },
});

export const editCompanyCareerSuccess = (career) => ({
    type: EDIT_COMPANY_CAREER_SUCCESS,
    payload: career,
});

export const editCompanyCareerFailed = (error) => ({
    type: EDIT_COMPANY_CAREER_FAILED,
    payload: error,
});

export const editCompany = (data, callbacks) => ({
    type: EDIT_COMPANY,
    payload: { data, callbacks },
});

export const editCompanySuccess = (data) => ({
    type: EDIT_COMPANY_SUCCESS,
    payload: data,
});

export const editCompanyFailed = (error) => ({
    type: EDIT_COMPANY_FAILED,
    payload: error,
});

export const updateCompany = (company, callbacks) => ({
    type: UPDATE_COMPANY,
    payload: { company, callbacks },
});

export const updateCompanySuccess = (company) => ({
    type: UPDATE_COMPANY_SUCCESS,
    payload: company,
});

export const updateCompanyFailed = (error) => ({
    type: UPDATE_COMPANY_FAILED,
    payload: error,
});

export const checkNameCompany = (companyName, callbacks) => ({
    type: CHECK_NAME_COMPANY,
    payload: { companyName, callbacks },
});

export const checkNameCompanySuccess = (photo) => ({
    type: CHECK_NAME_COMPANY_SUCCESS,
    payload: photo,
});

export const checkNameCompanyFailed = (error) => ({
    type: CHECK_NAME_COMPANY_FAILED,
    payload: error,
});

export const checkEmailCompany = (email, callbacks) => ({
    type: CHECK_EMAIL_COMPANY,
    payload: { email, callbacks },
});

export const checkEmailCompanySuccess = (email) => ({
    type: CHECK_EMAIL_COMPANY_SUCCESS,
    payload: email,
});

export const checkEmailCompanyFailed = (error) => ({
    type: CHECK_EMAIL_COMPANY_FAILED,
    payload: error,
});

export const changeEmailOnCompany = (data, callbacks) => ({
    type: CHANGE_EMAIL_COMPANY,
    payload: { data, callbacks }
});

export const changeEmailOnCompanySuccess = (email) => ({
    type: CHANGE_EMAIL_COMPANY_SUCCESS,
    payload: email,
});

export const changeEmailOnCompanyFailed = (error) => ({
    type: CHANGE_EMAIL_COMPANY_FAILED,
    payload: error,
});

export const deleteEmailOnCompany = (email, callbacks) => ({
    type: DELETE_EMAIL_COMPANY,
    payload: { email, callbacks }
});

export const deleteEmailOnCompanySuccess = (email) => ({
    type: DELETE_EMAIL_COMPANY_SUCCESS,
    payload: email,
});

export const deleteEmailOnCompanyFailed = (error) => ({
    type: DELETE_EMAIL_COMPANY_FAILED,
    payload: error,
});

export const changeEmailOnAcademic = (data, callbacks) => ({
    type: CHANGE_EMAIL_ACADEMIC,
    payload: { data, callbacks }
});

export const changeEmailOnAcademicSuccess = (email) => ({
    type: CHANGE_EMAIL_ACADEMIC_SUCCESS,
    payload: email,
});

export const changeEmailOnAcademicFailed = (error) => ({
    type: CHANGE_EMAIL_ACADEMIC_FAILED,
    payload: error,
});

export const deleteEmailOnAcademic = (email, callbacks) => ({
    type: DELETE_EMAIL_ACADEMIC,
    payload: { email, callbacks }
});

export const deleteEmailOnAcademicSuccess = (email) => ({
    type: DELETE_EMAIL_ACADEMIC_SUCCESS,
    payload: email,
});

export const deleteEmailOnAcademicFailed = (error) => ({
    type: DELETE_EMAIL_ACADEMIC_FAILED,
    payload: error,
});

export const checkCompanyId = (companyId, callbacks) => ({
    type: CHECK_COMPANY_ID,
    payload: { companyId, callbacks },
});

export const checkCompanyIdSuccess = (photo) => ({
    type: CHECK_COMPANY_ID_SUCCESS,
    payload: photo,
});

export const checkCompanyIdFailed = (error) => ({
    type: CHECK_COMPANY_ID_FAILED,
    payload: error,
});

export const getCompanies = (callbacks) => ({
    type: GET_COMPANIES,
    payload: { callbacks },
});

export const getCompaniesSuccess = (companies) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: companies,
});

export const getCompaniesFailed = (error) => ({
    type: GET_COMPANIES_FAILED,
    payload: error,
});

export const getPositionsOfCompany = (id, callbacks) => ({
    type: GET_POSITIONS_OF_COMPANY,
    payload: { id, callbacks },
});

export const getPositionsOfCompanySuccess = (positions) => ({
    type: GET_POSITIONS_OF_COMPANY_SUCCESS,
    payload: positions,
});

export const getPositionsOfCompanyFailed = (error) => ({
    type: GET_POSITIONS_OF_COMPANY_FAILED,
    payload: error,
});

export const getPublicCompany = (id, callbacks) => ({
    type: GET_PUBLIC_COMPANY,
    payload: { id, callbacks },
});

export const getPublicCompanySuccess = (company) => ({
    type: GET_PUBLIC_COMPANY_SUCCESS,
    payload: company,
});

export const getPublicCompanyFailed = (error) => ({
    type: GET_PUBLIC_COMPANY_FAILED,
    payload: error,
});

export const getGoalsOfCompany = (companyId, callbacks) => ({
    type: GET_COMPANY_GOALS,
    payload: { companyId, callbacks },
});

export const getGoalsOfCompanySuccess = (goals) => ({
    type: GET_COMPANY_GOALS_SUCCESS,
    payload: goals,
});

export const getGoalsOfCompanyFailed = (error) => ({
    type: GET_COMPANY_GOALS_FAILED,
    payload: error,
});


export const setCompanyAdmin = (params, callbacks) => ({
    type: SET_COMPANY_ADMIN,
    payload: { params, callbacks },
});

export const setCompanyAdminSuccess = (response) => ({
    type: SET_COMPANY_ADMIN_SUCCESS,
    payload: response,
});

export const setCompanyAdminFailed = (error) => ({
    type: SET_COMPANY_ADMIN_FAILED,
    payload: error,
});