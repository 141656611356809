import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { Button, Label } from 'reactstrap';
import { isDarkMode } from '../../../helpers/authUtils';

export const ALERT_TYPE_PRIMARY = 0;
export const ALERT_TYPE_DANGER = 1;
export const ALERT_TYPE_WARNING = 2;

class AlertPopupView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: '',
            // className: "custom-modal-style"
        };
    }

    render() {
        let { t, type, title, message, information, cancelTitle, doneTitle, handleCancel, handleDone, onClose, commentEnable, commentTitle } = this.props;

        let color = 'primary';
        switch (type) {
            case ALERT_TYPE_DANGER:
                color = 'danger';
                break;
            case ALERT_TYPE_WARNING:
                color = 'warning';
                break;
            default:
                break;
        }

        return (
            <div id="react-confirm-alert">
                <div className="react-confirm-alert-overlay" onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}>
                    <div className="react-confirm-alert">
                        <div className={"react-confirm-alert-body bg-color-alert"}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <h1>{title ?? ''}</h1>
                            {message &&
                                <Label className='display-linebreak'>{message ?? ''}</Label>
                            }
                            {information &&
                                <Label className='display-linebreak mt-2 mb-0'>{typeof element === 'string' ? information : information[0]}</Label>
                            }
                            {(commentEnable ?? false) &&
                                <div className="firebaseui-relative-wrapper mt-3">
                                    <Label className='display-linebreak mb-2'>{commentTitle ?? t('general.comment')} :</Label>
                                    <textarea className="firebaseui-input height-min-60 height-max-300 bg-color-transparent text-color-input padding-5"
                                        onChange={(e) => {
                                            this.setState({ comment: e.target.value })
                                        }}
                                    />
                                    <div className="firebaseui-error-wrapper">
                                        <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error" />
                                    </div>
                                </div>}

                            <div className="react-confirm-alert-button-group mt-3">
                                {(cancelTitle?.length > 0 || handleCancel != null) &&
                                    <Button color="white" onClick={(e) => {
                                        e.stopPropagation();
                                        if (handleCancel != null) {
                                            handleCancel();
                                        }
                                        onClose();
                                    }}>{cancelTitle ?? ''}</Button>}
                                {(doneTitle?.length > 0 || handleDone != null) &&
                                    <Button color={color} onClick={(e) => {
                                        e.stopPropagation();
                                        if (handleDone != null) {
                                            handleDone(commentEnable ? (this.state.comment ?? '') : null);
                                        }
                                        onClose();
                                    }}>{doneTitle ?? ''}</Button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('translations')(AlertPopupView);
