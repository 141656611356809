import classnames from 'classnames';
import React,{Component} from 'react';
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import {withTranslation} from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import ReactPlayer from 'react-player';
import {connect} from 'react-redux';
import {Card,CardBody,Col,Label,Nav,NavItem,NavLink,Row,TabContent,TabPane} from 'reactstrap';
import {getLocalizedString} from '../../helpers/utils';
import { getRepoSites, getRepoProfiles, getRepoProducts } from '../../redux/actions';
import companyData from "./companies";
import CompanyBubble from "./CompanyBubble";
import NetworkTabView from "./NetworkTabView";
import logo from '../../assets/images/logo.png';
import {  isDarkMode } from '../../helpers/authUtils';
import { S3_BASE_URL } from '../../redux/constants';

class CompanyView extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 1,
            dataSites: [],
            selectedType: 0,
            detailType: 0,
            playing: true,
            muted: true,
            videoError: null
        };
        this.playerRef = React.createRef();

    }
    componentDidMount() {
        this.props.getRepoSites({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                this.setState({ dataSites:  response });
                // console.log('callbackOnSuccess');
            },
        });
        this.props.getRepoProfiles({});
        this.props.getRepoProducts({});
    }

    componentDidUpdate(prevProps) {
        
            if (this.playerRef && this.playerRef.current){
                this.playerRef.current.src = null;
            }
        
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    render() {
        const options = {
            size: 120,
            minSize: 20,
            gutter: 2,
            provideProps: true,
            numCols: 7,
            fringeWidth: 160,
            yRadius: 100,
            xRadius: 400,
            cornerRadius: 50,
            showGuides: false,
            compact: true,
            gravitation: 5,
        };

        const getStockBubbles = () => {
            return companyData.slice(0, 20).map((company, i) => {
                return <CompanyBubble {...company} key={i} />;
            });
        };
        const stockBubbles = getStockBubbles();
        const { t, error } = this.props;
        var dataAddress = []
        if (this.state.dataSites && this.state.dataSites.sites ) {
            this.state.dataSites.sites.map((ct) => {
                dataAddress.push(ct)
            })
        }

        const s3_base_url = S3_BASE_URL;
        var image = this.props.introductionMedia?.image ? s3_base_url + getLocalizedString(this.props.introductionMedia.image) : "";
        var video = this.props.introductionMedia ? s3_base_url + getLocalizedString(this.props.introductionMedia?.video) : "";

        if(isDarkMode()){
            if(this.props.introductionMedia?.imageB != null && this.props.introductionMedia?.imageB != ''){
                image = s3_base_url + getLocalizedString(this.props.introductionMedia.imageB);
            }
            if(this.props.introductionMedia?.videoB != null && this.props.introductionMedia?.videoB != ''){
                video = s3_base_url + getLocalizedString(this.props.introductionMedia.videoB);
            }
        }
        const videoError = this.state.videoError ? this.state.videoError : (video ? null : 'error.noVideoAvailable');
            const playing = this.state.playing;

        const countryList = dataAddress
        return (

            <React.Fragment>
                <Col >
                    <Nav tabs>

                        <NavItem key={1}>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === 1 })}
                                onClick={() => {
                                    this.toggle(1);
                                }}>
                                <i
                                    className={classnames(

                                        'd-sm-none',
                                        'd-block',
                                        'mr-1'
                                    )}></i>
                                <span className="d-none d-sm-block">{t('company.aboutUs')}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem key={2}>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === 2 })}
                                onClick={() => {
                                    this.toggle(2);
                                }}>
                                <i
                                    className={classnames(

                                        'd-sm-none',
                                        'd-block',
                                        'mr-1'
                                    )}></i>
                                <span className="d-none d-sm-block">{t('login.network')}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem key={3}>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === 3 })}
                                onClick={() => {
                                    this.toggle(3);
                                }}>
                                <i
                                    className={classnames(

                                        'd-sm-none',
                                        'd-block',
                                        'mr-1'
                                    )}></i>
                                <span className="d-none d-sm-block">{t('company.contact')}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>

                <TabContent className="mr-4" activeTab={this.state.activeTab}>

                    <TabPane tabId={1} key={1}>
                        <Row>
                            <Col >

                                {/* <Row>
                                    <Label className='title-label'>
                                        {t('company.rockData')}
                                    </Label>
                                    <Col>
                                        <BubbleUI className="myBubbleUI" options={options}>
                                            {stockBubbles}
                                        </BubbleUI>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Label className='title-label'>
                                        {t('company.rockData')}
                                    </Label>
                                </Row>
                                <div className="center-horizon">
                                    <div className="photo-no-border">
                                        <img
                                            className="img-responsive fit-image"
                                            src={image}
                                            alt={image}
                                            onError={(e) => {
                                                e.preventDefault();
                                                e.target.src = logo
                                            }}
                                        />
                                    </div>
                                    </div>
                                    <div className="mt-4 center-horizon">
                                    <div className="video-border">
                                        {videoError ? <Label className="title-dark-small-label">{t(videoError)}</Label> :
                                            <ReactPlayer
                                                className='react-player'
                                                ref={this.playerRef}
                                                url={video}
                                                playing={playing}
                                                muted={this.state.muted}
                                                controls={true}
                                                controlsList="nodownload"
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload',
                                                            onContextMenu: e => e.preventDefault()
                                                        }
                                                    }
                                                }}
                                                loop={true}
                                                onClick={(e) => {
                                                    this.setState({ playing: !this.playerRef.current.playing });
                                                }}
                                                onError={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ videoError: 'error.videoNotFound' });
                                                    // console.log('video error');
                                                }

                                                }
                                                width='100%'
                                                height='100%'
                                            />
                                        }

                                    </div>
                                        </div>

                                <Card className="intro-card mt-4">
                                    <CardBody>
                                        <Label className="content-label">{t("aboutTab.aboutTitle1")}
                                        </Label>
                                        <Label className="content-label mt-4">{t("aboutTab.aboutTitle2")}</Label>
                                        <Label className="content-label">{t("aboutTab.aboutTitle3")}</Label>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId={2} key={2}>
                        <Row>
                            <Col sm="12">
                                <NetworkTabView isSelected = {this.props.isSelected &&  (this.state.activeTab == 2)}/>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId={3} key={3}>

                        <Label>{t('company.introCompany')} abc</Label>
                        <Row>
                            
                            {countryList && countryList.map((country,i) =>
                                <Col key={i} md={3*country.sites.length} className={(i<countryList.length-1) ? "col-right-border" : ""}>
                                    <Row>
                                    {
                                        country.sites?.map((op,idx) => 
                                        <Col key={idx}>
                                            <ul className="list-unstyled mt-2">
                                                <li className="font-weight-bold " >
                                                    <h5>{(idx==0) ? getLocalizedString(country.countryDetails) : <span> &nbsp; </span>}</h5>
                                                </li>
                                                
                                                <li className="font-weight-bold mt-2">{op.siteTypesDetails?.map((val,i) => {return  (i < op.siteTypesDetails.length-1) ? (getLocalizedString(val.value)+", ") : getLocalizedString(val.value) } )}</li>
                                                <li className="font-weight-medium mt-2">{op.address1}</li>
                                                <li className="font-weight-medium mt-2">{op.postalCode}{", "}{op.city}</li>
                                                <li className="font-weight-medium mt-2">{op.registrationNum && op.registrationNum}</li>
                                                <li className="font-weight-medium mt-2">{op.phone}</li>
                                                <li className="font-weight-medium mt-2">{op.email}</li>
                                            </ul>
                                            </Col>
                                        )}
                                        
                                        </Row>
                                </Col>
                                
                            )}
                           </Row>
                        
                    </TabPane>

                </TabContent>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { reposites, loading, error } = state.RepoSites;
    const { repoprofiles, introductionMedia} = state.RepoProfiles;
    const { repoproducts } = state.RepoProducts;
    return { reposites, introductionMedia, loading, error, repoprofiles, repoproducts };
};

export default connect(mapStateToProps, { getRepoSites, getRepoProfiles, getRepoProducts })((withTranslation('translations')(CompanyView)));