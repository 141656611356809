/* AUTH */

export const SEARCH_CATEGORY_ALL = 10000;
export const SEARCH_CATEGORY_USER = 1;
export const SEARCH_CATEGORY_TECHNOLOGY = 2;
export const SEARCH_CATEGORY_EDUCATION = 4;
export const SEARCH_CATEGORY_CAREER = 8;
export const SEARCH_CATEGORY_ACADEMY = 16;
export const SEARCH_CATEGORY_COMPANY = 32;
export const SEARCH_CATEGORY_COMPETENCY = 64;
export const SEARCH_PERSONAL_NETWORK = 10001;

export const SELECT_SEARCH_CATEGORY = "SELECT_SEARCH_CATEGORY";
export const SELECT_SEARCH_CATEGORY_SUCCESS = 'SELECT_SEARCH_CATEGORY_SUCCESS';
export const SELECT_SEARCH_CATEGORY_FAILED = 'SELECT_SEARCH_CATEGORY_FAILED';

export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';

export const SELECT_PERSONAL_NETWORK = "SELECT_PERSONAL_NETWORK";
export const SELECT_PERSONAL_NETWORK_SUCCESS = 'SELECT_PERSONAL_NETWORK_SUCCESS';
export const SELECT_PERSONAL_NETWORK_FAILED = 'SELECT_PERSONAL_NETWORK_FAILED';