// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {getLoggedInUser} from '../../helpers/authUtils';
import {BASE_URL} from './../constants';
import {getCompetenciesSuccess, getCompetenciesFailed, getCompetencyInfoFailed, getCompetencyInfoSuccess,
createCompetencyFailed, createCompetencySuccess, getCompetenciesOfTypeFailed, getCompetenciesOfTypeSuccess,
getCompetencyHierarchySuccess, getCompetencyHierarchyFailed,
deleteCompetencyLevelSuccess, deleteCompetencyLevelFailed,
editCompetencySuccess, editCompetencyFailed,
editCompetencyLevelSuccess, editCompetencyLevelFailed,
createCompetencyLevelSuccess, createCompetencyLevelFailed,
getCompetencyByIdSuccess, getCompetencyByIdFailed,
getCompetencyContextSuccess, getCompetencyContextFailed} from './actions';
import {
    GET_COMPETENCY, GET_COMPETENCY_INFO, CREATE_COMPETENCY, 
    GET_COMPETENCY_HIERARCHY, GET_COMPETENCY_TYPE, DELETE_COMPETENCY_LEVEL,
    EDIT_COMPETENCY, EDIT_COMPETENCY_LEVEL, CREATE_COMPETENCY_LEVEL, GET_COMPETENCY_BY_ID, GET_COMPETENCY_BY_ID_SUCCESS, GET_COMPETENCY_CONTEXT
} from './constants';

function* getCompetenciesInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/competency/", options);
        yield put(getCompetenciesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetenciesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCompetenciesOfTypeInternal({ payload: { type, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/competency/" + type, options);
        yield put(getCompetenciesOfTypeSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetenciesOfTypeFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCompetenciesByIdInternal({ payload: { competencyId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/competency/find-by-id/" + competencyId, options);
        yield put(getCompetencyByIdSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetencyByIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCompetencyInfoInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/competency-info", options);
        yield put(getCompetencyInfoSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetencyInfoFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createCompetencyInternal({ payload: { competency, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: competency
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/create-competency", options);
        yield put(createCompetencySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createCompetencyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createCompetencyLevelInternal({ payload: { competency, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: competency
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/create-competency-level", options);
        yield put(createCompetencyLevelSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createCompetencyLevelFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editCompetencyInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: params
    };

    //let test = JSON.stringify(competency.level);
    //console.log(test);

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/edit-competency/" + id, options);
        yield put(editCompetencySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editCompetencyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editCompetencyLevelInternal({ payload: { competency, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: competency.level
    };

    //let test = JSON.stringify(competency.level);
    //console.log(test);

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/" + competency.id + "/" + competency.competencyId + 
        "/" + competency.levelId + "/edit-level", options);
        yield put(editCompetencyLevelSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editCompetencyLevelFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getCompetencyHierarchyInternal({ payload: { id, competencyId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/competency-context-hierarchy/" + id + '/' + competencyId, options);
        yield put(getCompetencyHierarchySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetencyHierarchyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteCompetencyLevelInternal({ payload: {id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/competency/" + id, options);
        yield put(deleteCompetencyLevelSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(deleteCompetencyLevelFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getCompetencyContextInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/competency/competency-context/", options);
        yield put(getCompetencyContextSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getCompetencyContextFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


export function* watchGetCompetencies() {
    yield takeEvery(GET_COMPETENCY, getCompetenciesInternal);
}

export function* watchGetCompetenciesOfType() {
    yield takeEvery(GET_COMPETENCY_TYPE, getCompetenciesOfTypeInternal);
}

export function* watchGetCompetencyInfo() {
    yield takeEvery(GET_COMPETENCY_INFO, getCompetencyInfoInternal);
}

export function* watchCreateCompetency() {
    yield takeEvery(CREATE_COMPETENCY, createCompetencyInternal);
}

export function* watchCreateCompetencyLevel() {
    yield takeEvery(CREATE_COMPETENCY_LEVEL, createCompetencyLevelInternal);
}

export function* watchEditCompetency() {
    yield takeEvery(EDIT_COMPETENCY, editCompetencyInternal);
}

export function* watchEditCompetencyLevel() {
    yield takeEvery(EDIT_COMPETENCY_LEVEL, editCompetencyLevelInternal);
}

export function* watchGetCompetencyHierarchy() {
    yield takeEvery(GET_COMPETENCY_HIERARCHY, getCompetencyHierarchyInternal);
}

export function* watchDeleteCompetencyLevel() {
    yield takeEvery(DELETE_COMPETENCY_LEVEL, deleteCompetencyLevelInternal);
}

export function* watchGetCompetenciesByIdInternal() {
    yield takeEvery(GET_COMPETENCY_BY_ID, getCompetenciesByIdInternal);
}

export function* watchGetCompetencyContext() {
    yield takeEvery(GET_COMPETENCY_CONTEXT, getCompetencyContextInternal);
}

function* repoCompetenciesSaga() {
    yield all([fork(watchGetCompetencies), fork(watchGetCompetencyInfo), fork(watchCreateCompetency),
    fork(watchGetCompetencyHierarchy), fork(watchGetCompetenciesOfType), fork(watchDeleteCompetencyLevel),
    fork(watchEditCompetency), fork(watchEditCompetencyLevel), fork(watchCreateCompetencyLevel), fork(watchGetCompetenciesByIdInternal)
    , fork(watchGetCompetencyContext)]);
}

export default repoCompetenciesSaga;
