// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from '../constants';
import {
    selectSearchCategorySuccess, selectSearchCategoryFailed,
    searchSuccess, searchFailed,
    selectPersonalNetworkSuccess, selectPersonalNetworkFailed,
} from './actions';
import {
    SELECT_SEARCH_CATEGORY,
    SEARCH,
    SELECT_PERSONAL_NETWORK,

} from './constants';


function* selectSearchCategoryInternal({ payload: { category, callbacks } }) {
    const {
        callbackOnSuccess
    } = callbacks;

    yield put(selectSearchCategorySuccess(category));
    if (callbackOnSuccess) {
        yield call(callbackOnSuccess, category);
    }
}

function* searchInternal({ payload: { keyword, categories, personalNetwork, page, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = 'api/search/' + keyword + '?page=' + page;

        let bit = null;
        if (categories.length > 0) {
            categories.forEach(element => {
                bit = bit | element
            });
            path += '&category=' + bit
        }
        if (personalNetwork ?? false) {
            path += '&personal_network=true'
        }

        if (keyword.length < 3) {
            let response = {
                content: [],
                empty: true,
                first: true,
                last: true,
                number: 0,
                numberOfElements: 0,
                size: 0,
                sort: {sorted: false, unsorted: true, empty: true},
                totalElements: 0,
                totalPages: 1,
                pageable: {}
            };
            yield put(searchSuccess(keyword, response));

            if (callbackOnSuccess) {
                yield call(callbackOnSuccess, response);
            }

            if (callbackOnFinish) {
                yield call(callbackOnFinish);
            }

            return;
        }

        const response = yield call(fetchJSON, BASE_URL + path, options);
        yield put(searchSuccess(keyword, response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(searchFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* selectPersonalNetworkInternal({ payload: { isOn, callbacks } }) {
    const {
        callbackOnSuccess
    } = callbacks;

    yield put(selectPersonalNetworkSuccess(isOn));
    if (callbackOnSuccess) {
        yield call(callbackOnSuccess, isOn);
    }
}

export function* watchSelectSearchCategories() {
    yield takeEvery(SELECT_SEARCH_CATEGORY, selectSearchCategoryInternal);
}

export function* watchSearch() {
    yield takeEvery(SEARCH, searchInternal);
}

export function* watchSelectPersonalNetwork() {
    yield takeEvery(SELECT_PERSONAL_NETWORK, selectPersonalNetworkInternal);
}


function* searchSaga() {
    yield all([fork(watchSelectSearchCategories), fork(watchSearch), , fork(watchSelectPersonalNetwork)]);
}

export default searchSaga;
