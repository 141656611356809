// @flow
import {
    CHANGE_EMAIL, CHANGE_EMAIL_FAILED, CHANGE_EMAIL_SUCCESS, CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_SUCCESS, CHECK_EMAILVERIFICATIONSTATUS, CHECK_EMAILVERIFICATIONSTATUS_FAILED, CHECK_EMAILVERIFICATIONSTATUS_SUCCESS, CREATE_SECURITY, CREATE_SECURITY_FAILED, CREATE_SECURITY_SUCCESS, DELETE_CVS, DELETE_CVS_FAILED, DELETE_CVS_SUCCESS, DELETE_EMPLOYEE, DELETE_EMPLOYEE_FAILED, DELETE_EMPLOYEE_SUCCESS, DELETE_PHOTOS, DELETE_PHOTOS_FAILED, DELETE_PHOTOS_SUCCESS, GET_ALL_COMPANY, GET_ALL_COMPANY_FAILED, GET_ALL_COMPANY_SUCCESS, GET_CVS, GET_CVS_FAILED, GET_CVS_SUCCESS, GET_EMPLOYEE, GET_EMPLOYEE_FAILED, GET_EMPLOYEE_SUCCESS, GET_PHOTOS, GET_PHOTOS_FAILED, GET_PHOTOS_SUCCESS, GET_PROFILE, GET_PROFILE_FAILED,
    GET_PERSONAL_SUBCRIPTION, GET_PERSONAL_SUBCRIPTION_SUCCESS, GET_PERSONAL_SUBCRIPTION_FAILED,
    GET_SUBCRIPTION_PROFILE, GET_SUBCRIPTION_PROFILE_FAILED, GET_SUBCRIPTION_PROFILE_SUCCESS, GET_PROFILE_SUCCESS,
    PROFILE_VISUAL_SUMMARY, PROFILE_VISUAL_SUMMARY_SUCCESS, PROFILE_VISUAL_SUMMARY_FAILED,
    RESEND_VERIFICATION_EMAIL, RESEND_VERIFICATION_EMAIL_FAILED, RESEND_VERIFICATION_EMAIL_SUCCESS, SEARCH_EMPLOYEE, SEARCH_EMPLOYEE_FAILED, SEARCH_EMPLOYEE_SUCCESS, UPDATE_PHONENUMBER, UPDATE_PHONENUMBER_FAILED, UPDATE_PHONENUMBER_SUCCESS, UPDATE_PROFILE, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_SUCCESS, UPDATE_SECURITY, UPDATE_SECURITY_FAILED, UPDATE_SECURITY_SUCCESS, UPLOAD_CV, UPLOAD_CV_FAILED, UPLOAD_CV_SUCCESS, UPLOAD_PHOTO, UPLOAD_PHOTO_FAILED, UPLOAD_PHOTO_SUCCESS,
    PROFILE_SUMMARY_DATA_CHART, PROFILE_SUMMARY_DATA_CHART_SUCCESS, PROFILE_SUMMARY_DATA_CHART_FAILED,
    GET_MENU_HIERARCHY, GET_MENU_HIERARCHY_SUCCESS, GET_MENU_HIERARCHY_FAILED,
    GET_DATA_EXPERIENCE, GET_DATA_EXPERIENCE_SUCCESS, GET_DATA_EXPERIENCE_FAILED,
    CREATE_EXPERIENCE, CREATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_FAILED,
    EDIT_EXPERIENCE, EDIT_EXPERIENCE_SUCCESS, EDIT_EXPERIENCE_FAILED,
    DELETE_EXPERIENCE, DELETE_EXPERIENCE_SUCCESS, DELETE_EXPERIENCE_FAILED,
    GET_ACTION_GROUPS, GET_ACTION_GROUPS_SUCCESS, GET_ACTION_GROUPS_FAILED,
    GET_RELATION_ACTION, GET_RELATION_ACTION_SUCCESS, GET_RELATION_ACTION_FAILED,
    GET_GOALS_BY_USER, GET_GOALS_BY_USER_SUCCESS, GET_GOALS_BY_USER_FAILED,
    GET_ACTIONS_BY_USER, GET_ACTIONS_BY_USER_SUCCESS, GET_ACTIONS_BY_USER_FAILED,
    GET_GOALS_ACTIONS_BY_USER, GET_GOALS_ACTIONS_BY_USER_SUCCESS, GET_GOALS_ACTIONS_BY_USER_FAILED,
    GET_ALERTS, GET_ALERTS_SUCCESS, GET_ALERTS_FAILED,
    GET_PUBLIC_PROFILE, GET_PUBLIC_PROFILE_SUCCESS, GET_PUBLIC_PROFILE_FAILED,
    CREATE_ACADEMY_SECURITY, CREATE_ACADEMY_SECURITY_SUCCESS, CREATE_ACADEMY_SECURITY_FAILED,
    UPDATE_ACADEMY_SECURITY, UPDATE_ACADEMY_SECURITY_SUCCESS, UPDATE_ACADEMY_SECURITY_FAILED,
    SWITCH_USER, SWITCH_USER_SUCCESS, SWITCH_USER_FAILED, GET_DATA_EXPERIENCE_BY_ID, GET_DATA_EXPERIENCE_BY_ID_SUCCESS, GET_DATA_EXPERIENCE_BY_ID_FAILED, GET_FAILED_PAYMENT, GET_FAILED_PAYMENT_SUCCESS, GET_FAILED_PAYMENT_FAILED, PAY_FAILED_PAYMENT, PAY_FAILED_PAYMENT_SUCCESS, PAY_FAILED_PAYMENT_FAILED, SYNCHRONIZE_PAYMENT, SYNCHRONIZE_PAYMENT_SUCCESS, SYNCHRONIZE_PAYMENT_FAILED,
    GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED,
    ADD_EMAIL, ADD_EMAIL_SUCCESS, ADD_EMAIL_FAILED,
    EDIT_EMAIL, EDIT_EMAIL_SUCCESS, EDIT_EMAIL_FAILED,
    REMOVE_EMAIL, REMOVE_EMAIL_SUCCESS, REMOVE_EMAIL_FAILED,
    ADD_PHONE, ADD_PHONE_SUCCESS, ADD_PHONE_FAILED,
    EDIT_PHONE, EDIT_PHONE_SUCCESS, EDIT_PHONE_FAILED,
    REMOVE_PHONE, REMOVE_PHONE_SUCCESS, REMOVE_PHONE_FAILED
} from './constants';

export const getVisualSummary = (userId, callbacks) => ({
    type: PROFILE_VISUAL_SUMMARY,
    payload: { userId, callbacks },
});

export const getVisualSummarySuccess = (visual) => ({
    type: PROFILE_VISUAL_SUMMARY_SUCCESS,
    payload: visual,
});

export const getVisualSummaryFailed = (error) => ({
    type: PROFILE_VISUAL_SUMMARY_FAILED,
    payload: error,
});

export const getSummaryDataChart = (userId, callbacks) => ({
    type: PROFILE_SUMMARY_DATA_CHART,
    payload: { userId, callbacks },
});

export const getSummaryDataChartSuccess = (visual) => ({
    type: PROFILE_SUMMARY_DATA_CHART_SUCCESS,
    payload: visual,
});

export const getSummaryDataChartFailed = (error) => ({
    type: PROFILE_SUMMARY_DATA_CHART_FAILED,
    payload: error,
});

export const getEmployee = (id, token) => ({
    type: GET_EMPLOYEE,
    payload: { id, token },
});

export const getEmployeeSuccess = (profile) => ({
    type: GET_EMPLOYEE_SUCCESS,
    payload: profile,
});

export const getEmployeeFailed = (error) => ({
    type: GET_EMPLOYEE_FAILED,
    payload: error,
});

export const getPersonalSubscription = (userId, callbacks) => ({
    type: GET_PERSONAL_SUBCRIPTION,
    payload: { userId, callbacks },
});

export const getPersonalSubscriptionSuccess = (profile) => ({
    type: GET_PERSONAL_SUBCRIPTION_SUCCESS,
    payload: profile,
});

export const getPersonalSubscriptionFailed = (error) => ({
    type: GET_PERSONAL_SUBCRIPTION_FAILED,
    payload: error,
});

export const getSubscriptionProfile = (callbacks) => ({
    type: GET_SUBCRIPTION_PROFILE,
    payload: { callbacks },
});

export const getSubscriptionProfileSuccess = (profile) => ({
    type: GET_SUBCRIPTION_PROFILE_SUCCESS,
    payload: profile,
});

export const getSubscriptionProfileFailed = (error) => ({
    type: GET_SUBCRIPTION_PROFILE_FAILED,
    payload: error,
});

export const getProfile = (callbacks) => ({
    type: GET_PROFILE,
    payload: { callbacks },
});

export const getProfileSuccess = (profile) => ({
    type: GET_PROFILE_SUCCESS,
    payload: profile,
});

export const getProfileFailed = (error) => ({
    type: GET_PROFILE_FAILED,
    payload: error,
});

export const updateProfile = (profile, updateDateTime, callbacks) => ({
    type: UPDATE_PROFILE,
    payload: { profile, updateDateTime, callbacks },
});

export const updateProfileSuccess = (profile) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: profile,
});

export const updateProfileFailed = (error) => ({
    type: UPDATE_PROFILE_FAILED,
    payload: error,
});


export const createSecurity = (security, callbacks) => ({
    type: CREATE_SECURITY,
    payload: { security, callbacks },
});

export const createSecuritySuccess = (profile) => ({
    type: CREATE_SECURITY_SUCCESS,
    payload: profile,
});

export const createSecurityFailed = (error) => ({
    type: CREATE_SECURITY_FAILED,
    payload: error,
});

export const updateSecurity = (security, callbacks) => ({
    type: UPDATE_SECURITY,
    payload: { security, callbacks },
});

export const updateSecuritySuccess = (profile) => ({
    type: UPDATE_SECURITY_SUCCESS,
    payload: profile,
});

export const updateSecurityFailed = (error) => ({
    type: UPDATE_SECURITY_FAILED,
    payload: error,
});

export const uploadCV = (file, callbacks) => ({
    type: UPLOAD_CV,
    payload: { file, callbacks },
});

export const uploadCVSuccess = (profile) => ({
    type: UPLOAD_CV_SUCCESS,
    payload: profile,
});

export const uploadCVFailed = (error) => ({
    type: UPLOAD_CV_FAILED,
    payload: error,
});

export const searchEmployee = (keyword) => ({
    type: SEARCH_EMPLOYEE,
    payload: { keyword },
});

export const searchEmployeeSuccess = (employees) => ({
    type: SEARCH_EMPLOYEE_SUCCESS,
    payload: employees,
});

export const searchEmployeeFailed = (error) => ({
    type: SEARCH_EMPLOYEE_FAILED,
    payload: error,
});


export const deleteEmployee = (id, token) => ({
    type: DELETE_EMPLOYEE,
    payload: { id, token },
});

export const deleteEmployeeSuccess = (success) => ({
    type: DELETE_EMPLOYEE_SUCCESS,
    payload: success,
});

export const deleteEmployeeFailed = (error) => ({
    type: DELETE_EMPLOYEE_FAILED,
    payload: error,
});

export const getAllCompanies = (token) => ({
    type: GET_ALL_COMPANY,
    payload: token,
});

export const getAllCompaniesSuccess = (companies) => ({
    type: GET_ALL_COMPANY_SUCCESS,
    payload: companies,
});

export const getAllCompaniesFailed = (error) => ({
    type: GET_ALL_COMPANY_FAILED,
    payload: error,
});


export const updatePhoneNumber = (profile) => ({
    type: UPDATE_PHONENUMBER,
    payload: { profile },
});

export const updatePhoneNumberSuccess = (profile) => ({
    type: UPDATE_PHONENUMBER_SUCCESS,
    payload: profile,
});

export const updatePhoneNumberFailed = (error) => ({
    type: UPDATE_PHONENUMBER_FAILED,
    payload: error,
});

export const checkEmailVerificationStatus = (info) => ({
    type: CHECK_EMAILVERIFICATIONSTATUS,
    payload: { info },
});

export const checkEmailVerificationStatusSuccess = (status) => ({
    type: CHECK_EMAILVERIFICATIONSTATUS_SUCCESS,
    payload: status,
});

export const checkEmailVerificationStatusFailed = (error) => ({
    type: CHECK_EMAILVERIFICATIONSTATUS_FAILED,
    payload: error,
});

export const changeEmail = (type, info, callbacks) => ({
    type: CHANGE_EMAIL,
    payload: { type, info, callbacks },
});

export const changeEmailSuccess = (profile) => ({
    type: CHANGE_EMAIL_SUCCESS,
    payload: profile,
});

export const changeEmailFailed = (error) => ({
    type: CHANGE_EMAIL_FAILED,
    payload: error,
});

export const resendVerificationEmail = (email) => ({
    type: RESEND_VERIFICATION_EMAIL,
    payload: { email },
});

export const resendVerificationEmailSuccess = (profile) => ({
    type: RESEND_VERIFICATION_EMAIL_SUCCESS,
    payload: profile,
});

export const resendVerificationEmailFailed = (error) => ({
    type: RESEND_VERIFICATION_EMAIL_FAILED,
    payload: error,
});


export const changePassword = (data, callbacks) => ({
    type: CHANGE_PASSWORD,
    payload: { data, callbacks },
});

export const changePasswordSuccess = (info) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: info,
});

export const changePasswordFailed = (error) => ({
    type: CHANGE_PASSWORD_FAILED,
    payload: error,
});


export const getCVS = (callbacks) => ({
    type: GET_CVS,
    payload: callbacks,
});

export const getCVSSuccess = (cvs) => ({
    type: GET_CVS_SUCCESS,
    payload: cvs,
});

export const getCVSFailed = (error) => ({
    type: GET_CVS_FAILED,
    payload: error,
});

export const deleteCVS = (data, callbacks) => ({
    type: DELETE_CVS,
    payload: { data, callbacks },
});

export const deleteCVSSuccess = (info) => ({
    type: DELETE_CVS_SUCCESS,
    payload: info,
});

export const deleteCVSFailed = (error) => ({
    type: DELETE_CVS_FAILED,
    payload: error,
});
export const getPhotos = (callbacks) => ({
    type: GET_PHOTOS,
    payload: callbacks,
});

export const getPhotosSuccess = (photos) => ({
    type: GET_PHOTOS_SUCCESS,
    payload: photos,
});

export const getPhotosFailed = (error) => ({
    type: GET_PHOTOS_FAILED,
    payload: error,
});
export const deletePhotos = (data, callbacks) => ({
    type: DELETE_PHOTOS,
    payload: { data, callbacks },
});

export const deletePhotosSuccess = (info) => ({
    type: DELETE_PHOTOS_SUCCESS,
    payload: info,
});

export const deletePhotosFailed = (error) => ({
    type: DELETE_PHOTOS_FAILED,
    payload: error,
});
export const uploadPhoto = (file, callbacks) => ({
    type: UPLOAD_PHOTO,
    payload: { file, callbacks },
});

export const uploadPhotoSuccess = (photo) => ({
    type: UPLOAD_PHOTO_SUCCESS,
    payload: photo,
});

export const uploadPhotoFailed = (error) => ({
    type: UPLOAD_PHOTO_FAILED,
    payload: error,
});

export const getMenuHierarchy = () => ({
    type: GET_MENU_HIERARCHY,
    payload: {},
});

export const getMenuHierarchySuccess = (menuHierachy) => ({
    type: GET_MENU_HIERARCHY_SUCCESS,
    payload: menuHierachy,
});

export const getMenuHierarchyFailed = (error) => ({
    type: GET_MENU_HIERARCHY_FAILED,
    payload: error,
});


export const getDataExperience = (userId, goal, actions, callbacks) => ({
    type: GET_DATA_EXPERIENCE,
    payload: { userId, goal, actions, callbacks },
});

export const getDataExperienceSuccess = (data) => ({
    type: GET_DATA_EXPERIENCE_SUCCESS,
    payload: data,
});

export const getDataExperienceFailed = (error) => ({
    type: GET_DATA_EXPERIENCE_FAILED,
    payload: error,
});

export const getDataExperienceById = (id, callbacks) => ({
    type: GET_DATA_EXPERIENCE_BY_ID,
    payload: { id, callbacks },
});

export const getDataExperienceByIdSuccess = (data) => ({
    type: GET_DATA_EXPERIENCE_BY_ID_SUCCESS,
    payload: data,
});

export const getDataExperienceByIdFailed = (error) => ({
    type: GET_DATA_EXPERIENCE_BY_ID_FAILED,
    payload: error,
});

export const createExperience = (params, callbacks) => ({
    type: CREATE_EXPERIENCE,
    payload: { params, callbacks },
});

export const createExperienceSuccess = (data) => ({
    type: CREATE_EXPERIENCE_SUCCESS,
    payload: data,
});

export const createExperienceFailed = (error) => ({
    type: CREATE_EXPERIENCE_FAILED,
    payload: error,
});

export const editExperience = (id, params, callbacks) => ({
    type: EDIT_EXPERIENCE,
    payload: { id, params, callbacks },
});

export const editExperienceSuccess = (data) => ({
    type: EDIT_EXPERIENCE_SUCCESS,
    payload: data,
});

export const editExperienceFailed = (error) => ({
    type: EDIT_EXPERIENCE_FAILED,
    payload: error,
});

export const deleteExperience = (id, callbacks) => ({
    type: DELETE_EXPERIENCE,
    payload: { id, callbacks },
});

export const deleteExperienceSuccess = (data) => ({
    type: DELETE_EXPERIENCE_SUCCESS,
    payload: data,
});

export const deleteExperienceFailed = (error) => ({
    type: DELETE_EXPERIENCE_FAILED,
    payload: error,
});

export const getActionGroups = (callbacks) => ({
    type: GET_ACTION_GROUPS,
    payload: { callbacks },
});

export const getActionGroupsSuccess = (data) => ({
    type: GET_ACTION_GROUPS_SUCCESS,
    payload: data,
});

export const getActionGroupsFailed = (error) => ({
    type: GET_ACTION_GROUPS_FAILED,
    payload: error,
});

export const getRelationAction = (callbacks) => ({
    type: GET_RELATION_ACTION,
    payload: { callbacks },
});

export const getRelationActionSuccess = (data) => ({
    type: GET_RELATION_ACTION_SUCCESS,
    payload: data,
});

export const getRelationActionFailed = (error) => ({
    type: GET_RELATION_ACTION_FAILED,
    payload: error,
});

export const getGoalsByUser = (callbacks) => ({
    type: GET_GOALS_BY_USER,
    payload: { callbacks },
});

export const getGoalsByUserSuccess = (data) => ({
    type: GET_GOALS_BY_USER_SUCCESS,
    payload: data,
});

export const getGoalsByUserFailed = (error) => ({
    type: GET_GOALS_BY_USER_FAILED,
    payload: error,
});

export const getGoalsActionsByUser = (callbacks) => ({
    type: GET_GOALS_ACTIONS_BY_USER,
    payload: { callbacks }
});

export const getGoalsActionsByUserSuccess = (data) => ({
    type: GET_GOALS_ACTIONS_BY_USER_SUCCESS,
    payload: data
});

export const getGoalsActionsByUserFailed = (error) => ({
    type: GET_GOALS_ACTIONS_BY_USER_FAILED,
    payload: error
});

export const getActionsByUser = (callbacks) => ({
    type: GET_ACTIONS_BY_USER,
    payload: { callbacks },
});

export const getActionsByUserSuccess = (data) => ({
    type: GET_ACTIONS_BY_USER_SUCCESS,
    payload: data,
});

export const getActionsByUserFailed = (error) => ({
    type: GET_ACTIONS_BY_USER_FAILED,
    payload: error,
});

export const getAlerts = (callbacks) => ({
    type: GET_ALERTS,
    payload: { callbacks },
});

export const getAlertsSuccess = (alerts) => ({
    type: GET_ALERTS_SUCCESS,
    payload: alerts,
});

export const getAlertsFailed = (error) => ({
    type: GET_ALERTS_FAILED,
    payload: error,
});

export const getPublicProfile = (id, callbacks) => ({
    type: GET_PUBLIC_PROFILE,
    payload: { id, callbacks },
});

export const getPublicProfileSuccess = (profile) => ({
    type: GET_PUBLIC_PROFILE_SUCCESS,
    payload: profile,
});

export const getPublicProfileFailed = (error) => ({
    type: GET_PUBLIC_PROFILE_FAILED,
    payload: error,
});



export const createAcademySecurity = (security, callbacks) => ({
    type: CREATE_ACADEMY_SECURITY,
    payload: { security, callbacks },
});

export const createAcademySecuritySuccess = (profile) => ({
    type: CREATE_ACADEMY_SECURITY_SUCCESS,
    payload: profile,
});

export const createAcademySecurityFailed = (error) => ({
    type: CREATE_ACADEMY_SECURITY_FAILED,
    payload: error,
});

export const updateAcademySecurity = (security, callbacks) => ({
    type: UPDATE_ACADEMY_SECURITY,
    payload: { security, callbacks },
});

export const updateAcademySecuritySuccess = (profile) => ({
    type: UPDATE_ACADEMY_SECURITY_SUCCESS,
    payload: profile,
});

export const updateAcademySecurityFailed = (error) => ({
    type: UPDATE_ACADEMY_SECURITY_FAILED,
    payload: error,
});

export const switchUser = (username, callbacks) => ({
    type: SWITCH_USER,
    payload: { username, callbacks },
});

export const switchUserSuccess = (username) => ({
    type: SWITCH_USER_SUCCESS,
    payload: username,
});

export const switchUserFailed = (error) => ({
    type: SWITCH_USER_FAILED,
    payload: error,
});


export const getFailedPayments = (callbacks) => ({
    type: GET_FAILED_PAYMENT,
    payload: { callbacks },
});

export const getFailedPaymentsSuccess = (payments) => ({
    type: GET_FAILED_PAYMENT_SUCCESS,
    payload: payments,
});

export const getFailedPaymentsFailed = (error) => ({
    type: GET_FAILED_PAYMENT_FAILED,
    payload: error,
});

export const payFailedPayment = (subscriptionId, action, callbacks) => ({
    type: PAY_FAILED_PAYMENT,
    payload: {subscriptionId, action, callbacks },
});

export const payFailedPaymentSuccess = (data) => ({
    type: PAY_FAILED_PAYMENT_SUCCESS,
    payload: data,
});

export const payFailedPaymentFailed = (error) => ({
    type: PAY_FAILED_PAYMENT_FAILED,
    payload: error,
});

export const synchronizePayment = (userId, callbacks) => ({
    type: SYNCHRONIZE_PAYMENT,
    payload: {userId, callbacks },
});

export const synchronizePaymentSuccess = (data) => ({
    type: SYNCHRONIZE_PAYMENT_SUCCESS,
    payload: data,
});

export const synchronizePaymentFailed = (error) => ({
    type: SYNCHRONIZE_PAYMENT_FAILED,
    payload: error,
});

export const getDashboard = (callbacks) => ({
    type: GET_DASHBOARD,
    payload: { callbacks },
});

export const getDashboardSuccess = (data) => ({
    type: GET_DASHBOARD_SUCCESS,
    payload: data,
});

export const getDashboardFailed = (error) => ({
    type: GET_DASHBOARD_FAILED,
    payload: error,
});


export const addEmail = (params, callbacks) => ({
    type: ADD_EMAIL,
    payload: { params, callbacks },
});

export const addEmailSuccess = (response) => ({
    type: ADD_EMAIL_SUCCESS,
    payload: response,
});

export const addEmailFailed = (error) => ({
    type: ADD_EMAIL_FAILED,
    payload: error,
});

export const editEmail = (oldEmail, newEmail, callbacks) => ({
    type: EDIT_EMAIL,
    payload: { oldEmail, newEmail, callbacks },
});

export const editEmailSuccess = (response) => ({
    type: EDIT_EMAIL_SUCCESS,
    payload: response,
});

export const editEmailFailed = (error) => ({
    type: EDIT_EMAIL_FAILED,
    payload: error,
});

export const removeEmail = (email, callbacks) => ({
    type: REMOVE_EMAIL,
    payload: { email, callbacks },
});

export const removeEmailSuccess = (response) => ({
    type: REMOVE_EMAIL_SUCCESS,
    payload: response,
});

export const removeEmailFailed = (error) => ({
    type: REMOVE_EMAIL_FAILED,
    payload: error,
});

export const addPhone = (params, callbacks) => ({
    type: ADD_PHONE,
    payload: { params, callbacks },
});

export const addPhoneSuccess = (response) => ({
    type: ADD_PHONE_SUCCESS,
    payload: response,
});

export const addPhoneFailed = (error) => ({
    type: ADD_PHONE_FAILED,
    payload: error,
});

export const editPhone = (oldPhone, newPhone, callbacks) => ({
    type: EDIT_PHONE,
    payload: { oldPhone, newPhone, callbacks },
});

export const editPhoneSuccess = (response) => ({
    type: EDIT_PHONE_SUCCESS,
    payload: response,
});

export const editPhoneFailed = (error) => ({
    type: EDIT_PHONE_FAILED,
    payload: error,
});

export const removePhone = (phone, callbacks) => ({
    type: REMOVE_PHONE,
    payload: { phone, callbacks },
});

export const removePhoneSuccess = (response) => ({
    type: REMOVE_PHONE_SUCCESS,
    payload: response,
});

export const removePhoneFailed = (error) => ({
    type: REMOVE_PHONE_FAILED,
    payload: error,
});


