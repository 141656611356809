// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {getCurrentLanguage} from '../../helpers/utils';
import {S3_BASE_URL} from './../constants';
import {getPrivacyPolicySuccess, getPrivacyPolicyFailed,getTermOfServiceSuccess, getTermOfServiceFailed, getContentHTMLSuccess, getContentHTMLFailed} from './actions';
import {
    GET_PRIVACYPOLICY,
    GET_TERMOFSERVICE,
    GET_CONTENT_HTML
} from './constants';


function* getPrivacyPolicy({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, S3_BASE_URL + "monroe-docs/privacyPolicy/content.json", options);
        yield put(getPrivacyPolicySuccess(response.docs));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response.docs);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getPrivacyPolicyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        try {
            var file_name = S3_BASE_URL + "monroe-docs/termsOfService/content.json"

        const response = yield call(fetchJSON, S3_BASE_URL + "monroe-docs/termsOfService/content.json", options);
        yield put(getTermOfServiceSuccess(response.docs));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response.docs);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getTermOfServiceFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
    }
}

function* getContentHTML({ payload: { api, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    // api = "privacyPolicy";
    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        var file_name = S3_BASE_URL + 'monroe-docs/' + api + '/index.' + getCurrentLanguage() + '.html'
       
        const response = yield call(fetchJSON, file_name, options);

        yield put(getContentHTMLSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        try {
            file_name = S3_BASE_URL + 'monroe-docs/' + api + '/index.en.html'
       
            const response = yield call(fetchJSON, file_name, options);
    
            yield put(getContentHTMLSuccess(response));
            if (callbackOnSuccess) {
                yield call(callbackOnSuccess, response);
            }
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
        
            yield put(getContentHTMLFailed(message));
            if (callbackOnFailure) {
                yield call(callbackOnFailure, error);
            }
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetPrivacyPolicy() {
    yield takeEvery(GET_PRIVACYPOLICY, getPrivacyPolicy);
}

export function* watchGetContentHTML() {
    yield takeEvery(GET_CONTENT_HTML, getContentHTML);
}


function* repoprivacyPolicySaga() {
    yield all([fork(watchGetPrivacyPolicy), fork(watchGetContentHTML)]);
}

export default repoprivacyPolicySaga;
