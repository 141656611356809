// @flow
import { AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, Label, Modal, Row, Table
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { allLanguages, facultyWithId, getLocalizedString } from '../../../../helpers/utils';
import { getAPICountryInfo, getAllNetworkPeople, createValidation, editValidation } from '../../../../redux/actions';
import SVG from 'react-inlinesvg';
import companyIcon from '../../../../assets/images/menu/companies.svg';
import { User } from 'react-feather';
import moment from 'moment';
import {
    VALIDATION_DOCUMENT_EDUCATION, VALIDATION_DOCUMENT_TRAINING,
    VALIDATION_DOCUMENT_CAREER,
    VALIDATION_DOCUMENT_COMPETENCY,
    VALIDATION_DOCUMENT_EVENTS
} from '../../../../redux/validation/constants';
import { EMAIL_TYPE_PERSONAL } from './ContactModal';
import { NETWORK_PERSON_TYPE_COUNTRY } from './NetworkPerson';

class SubmitValidationRequestModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'xx',
            selectedPerson: null,
            selectedEmail: null,
            selectedLanguage: null,
            comment: '',
            errorMessage: null,
            item1stLevel: null,
            item2ndLevel: null,
            item3rdLevel: null
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        // if (this.props.allNetworkPeople == null) {
        //     this.props.getAllNetworkPeople({});
        // }

        let item1stLevel = this.props.item1stLevel;
        let item2ndLevel = this.props.item2ndLevel;
        let item3rdLevel = this.props.item3rdLevel;
        if (this.props.editingValidation) {
            if (this.props.type == VALIDATION_DOCUMENT_EDUCATION) {
                item1stLevel = this.props.editingValidation?.documentDetails?.details;
                item2ndLevel = item1stLevel?.academyPlaces?.find((element) => { return element.id == this.props.editingValidation.documentSubId });
                item3rdLevel = item2ndLevel?.graduateDegrees?.find((element) => { return element.order?.toString() == this.props.editingValidation.documentChildId });
            }
            else if (this.props.type == VALIDATION_DOCUMENT_TRAINING) {
                item1stLevel = { id: this.props.editingValidation?.documentId };
                item2ndLevel = this.props.editingValidation?.documentDetails?.details;
                item3rdLevel = null;
            }
            else if (this.props.type == VALIDATION_DOCUMENT_CAREER) {
                item1stLevel = {
                    career: { id: this.props.editingValidation?.documentId, positionLevelDetail: this.props.editingValidation?.documentDetails?.details?.positionLevelDetail },
                    company: this.props.editingValidation?.documentDetails?.companyDetails
                };
                item2ndLevel = this.props.editingValidation?.documentDetails?.details;
                item3rdLevel = null;
            }
            else if (this.props.type == VALIDATION_DOCUMENT_EVENTS) {
                item1stLevel = this.props.editingValidation.documentId;
                item2ndLevel = this.props.editingValidation.documentId;
                item3rdLevel = null;
            }
        }

        this.setState({ item1stLevel: item1stLevel, item2ndLevel: item2ndLevel, item3rdLevel: item3rdLevel }, () => {
            if (this.props.editingValidation && this.props.allNetworkPeople) {
                this.prefillData();
            }
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.allNetworkPeople != this.props.allNetworkPeople) {
            this.prefillData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    prefillData = () => {
        let data = this.props.editingValidation;

        let network = (this.props.allNetworkPeople ?? []).filter((element) => { return element.emails?.length > 0 || element.email?.length > 0 });
        network = network.map((person) => {
            let name = person.firstName ?? '';
            if (person.lastName?.length > 0) {
                name += (name.length > 0 ? ' ' : '') + person.lastName;
            }
            return { label: name, entity: person }
        });
        let selectedPerson = data?.referenceUserId ? network.find((element) => { return element.entity.id == data?.referenceUserId }) : null;

        let emails = selectedPerson?.entity?.emails?.map((email) => { return { label: email.email, entity: email } });
        if (emails == null) {
            if (selectedPerson?.entity?.email?.length > 0) {
                emails = [{ label: selectedPerson.entity.email, entity: { email: selectedPerson.entity.email, type: EMAIL_TYPE_PERSONAL } }];
            }
        }
        else {
            if (selectedPerson?.entity?.email?.length > 0 && !emails.some((element) => { return element.label == selectedPerson.entity.email })) {
                emails.push({ label: selectedPerson.entity.email, entity: { email: selectedPerson.entity.email, type: EMAIL_TYPE_PERSONAL } });
            }
        }
        let selectedEmail = data?.referenceUserEmail ? emails?.find((element) => { return element.label == data.referenceUserEmail }) : null;

        let languages = allLanguages();
        let selectedLanguage = data?.language ? languages.find((element) => { return element.code == data?.language }) : null;
        let comment = data?.description ?? '';

        this.setState({
            selectedPerson: selectedPerson,
            selectedEmail: selectedEmail,
            selectedLanguage: selectedLanguage,
            comment: comment
        });
    }

    onRequest = () => {
        let requestable = this.props.editingValidation != null || (this.state.item1stLevel != null && this.state.item2ndLevel != null)
        if (!requestable) {
            return
        }

        let params = {};
        params.documentType = this.props.type;

        if (this.props.type == VALIDATION_DOCUMENT_EDUCATION) {
            params.documentId = this.state.item1stLevel.academyId;
            params.documentSubId = this.state.item2ndLevel.id;
        }
        else if (this.props.type == VALIDATION_DOCUMENT_TRAINING) {
            params.documentId = this.state.item1stLevel.id;
            params.documentSubId = this.state.item2ndLevel.id;
        }
        else if (this.props.type == VALIDATION_DOCUMENT_CAREER) {
            params.documentId = this.state.item1stLevel.career.id;
            params.documentSubId = this.state.item2ndLevel.id;
        }
        else if (this.props.type == VALIDATION_DOCUMENT_COMPETENCY) {
            params.documentId = this.state.item1stLevel;
            params.documentSubId = null;
        }
        else if (this.props.type == VALIDATION_DOCUMENT_EVENTS) {
            params.documentId = this.state.item1stLevel;
            params.documentSubId = null;
        }
        else {
            params.documentId = this.state.item1stLevel;
            params.documentSubId = null;
        }

        if (this.state.item3rdLevel) {
            params.documentChildId = this.state.item3rdLevel?.order?.toString();
        }

        params.referenceUserId = this.state.selectedPerson?.entity?.id;
        params.referenceUserName = this.state.selectedPerson?.label ?? '';
        params.referenceUserEmail = this.state.selectedEmail?.label ?? '';

        if (this.state.comment.length > 0) {
            params.language = this.state.selectedLanguage?.code;
            params.description = this.state.comment;
        }

        if (this.props.editingValidation && this.props.editingValidation.referenceUserId) {
            this.props.editValidation(this.props.editingValidation.id, params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error, errorMessage: (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    this.props.onClose(response);
                },
            })
        }
        else {
            this.props.createValidation(params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error, errorMessage: (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    this.props.onClose(response);
                },
            })
        }
    }

    summaryDetails = () => {
        let { t } = this.props;
        let school = this.state.item2ndLevel?.facultyId ? (facultyWithId(this.state.item2ndLevel?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)) : null;

        let details = {};
        if (this.props.type == VALIDATION_DOCUMENT_EDUCATION) {
            if (this.state.item1stLevel?.academyDetail?.name?.length > 0) {
                details[t("Academy")] = this.state.item1stLevel?.academyDetail?.name;
            }
            if (school?.label?.length > 0) {
                details[t("academic.school")] = school?.label;
            }
        }
        else if (this.props.type == VALIDATION_DOCUMENT_TRAINING) {
            if (this.state.item2ndLevel?.companyDetail?.name?.length > 0) {
                details[t("subscription.company")] = this.state.item2ndLevel?.companyDetail?.name;
            }
            if (this.state.item2ndLevel?.courseName?.length > 0) {
                details[t("training.courseName")] = this.state.item2ndLevel?.courseName;
            }
        }
        else if (this.props.type == VALIDATION_DOCUMENT_CAREER) {
            if (this.state.item1stLevel?.company?.name.length > 0) {
                details[t("subscription.company")] = this.state.item1stLevel?.company?.name;
            }
            if (this.state.item1stLevel?.career?.positionLevelDetail?.value != null) {
                details[t("company.Position")] = getLocalizedString(this.state.item1stLevel?.career?.positionLevelDetail?.value);
            }
        }

        if (this.state.item3rdLevel) {
            let languages = allLanguages();

            let graduateDegrees = school?.entity?.graduateDegreesDetails?.map((element) => {
                element.label = getLocalizedString(element.value);
                return element
            });
            let specialties = school?.entity?.specialties?.map((element) => {
                element.label = getLocalizedString(element.value);
                return element
            });

            let informationLanguage = this.state.item2ndLevel?.informationLanguage ? languages.find((element) => { return element.code == this.state.item2ndLevel?.informationLanguage }) : null;
            let item3rdLevel = graduateDegrees?.find((gd) => { return gd.order == this.state.item3rdLevel.graduateDegreeId });
            let graduationSpecialty = specialties?.find((s) => { return s.order == this.state.item3rdLevel.facultySpecialtyId });
            let underPreparation = this.state.item3rdLevel?.underPreparation ?? false;

            let graduate = item3rdLevel?.label ?? '';
            if (this.state.item3rdLevel?.graduateDiplomaInt?.length > 0) {
                graduate += (graduate.length > 0 ? ' - ' : '') + this.state.item3rdLevel.graduateDiplomaInt;
                if (informationLanguage?.label?.length > 0) {
                    graduate += ' (' + informationLanguage.label + ')';
                }
            }

            let specialty = graduationSpecialty?.label ?? '';
            if (this.state.item3rdLevel?.graduationSpecialtyInt?.length > 0) {
                specialty += (specialty.length > 0 ? ' - ' : '') + this.state.item3rdLevel.graduationSpecialtyInt;
                if (informationLanguage?.label?.length > 0) {
                    specialty += ' (' + informationLanguage.label + ')';
                }
            }

            if (graduate.length > 0) {
                details[t("academic.item3rdLevel")] = graduate;
            }
            if (specialty.length > 0) {
                details[t("academic.graduationSpecialty")] = specialty;
            }
            if (!underPreparation && this.state.item3rdLevel?.dateDegree?.length > 0) {
                details[t("academic.dateOfDegree")] = this.state.item3rdLevel?.dateDegree;
            }

            if (underPreparation) {
                details[' '] = t("academic.underPreparation");
            }
        }
        else {
            if (this.props.type == VALIDATION_DOCUMENT_EDUCATION) {
                if (this.state.item2ndLevel?.monthStart?.length > 0) {
                    details[t("career.monthStart")] = this.state.item2ndLevel?.monthStart;
                }
                if (this.state.item2ndLevel?.monthEnd?.length > 0) {
                    details[t("career.monthEnd")] = this.state.item2ndLevel?.monthEnd;
                }
            }
            else if (this.props.type == VALIDATION_DOCUMENT_TRAINING) {
                if (this.state.item2ndLevel?.dateStart?.length > 0) {
                    details[t("training.dateStart")] = this.state.item2ndLevel?.dateStart;
                }
                if (this.state.item2ndLevel?.dateEnd?.length > 0) {
                    details[t("training.dateEnd")] = this.state.item2ndLevel?.dateEnd;
                }
            }
            else if (this.props.type == VALIDATION_DOCUMENT_CAREER) {
                if (this.state.item2ndLevel?.monthStart?.length > 0) {
                    details[t("career.monthStart")] = this.state.item2ndLevel?.monthStart;
                }
                if (this.state.item2ndLevel?.monthEnd?.length > 0) {
                    details[t("career.monthEnd")] = this.state.item2ndLevel?.monthEnd;
                }
            }
        }

        return details;
    }

    validationSummary = () => {
        let t = this.props.t;

        let details = this.props.summary ?? this.summaryDetails();

        return (
            <div>
                <Table className="mb-0" striped>
                    <tbody>
                        {Object.keys(details).length > 0 && Object.keys(details).map((key, index) => {
                            return (
                                <tr key={key}>
                                    <th style={{ 'width': '35%' }} scope="row">{key}</th>
                                    {(typeof details[key] === 'string') ?
                                        <td style={{ 'width': '65%' }}>{details[key]}</td>
                                        :
                                        <td style={{ 'width': '65%' }}>
                                            <div className='align-vertical'>
                                                {details[key].map((value) => {
                                                    return (
                                                        <Label className='font-weight-normal'>{value}</Label>
                                                    )
                                                })
                                                }
                                            </div>

                                        </td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        let languages = allLanguages();

        let network = (this.props.allNetworkPeople ?? []).filter((element) => { return element.emails?.length > 0 || element.email?.length > 0 });
        network = network.map((person) => {
            let name = person.firstName ?? '';
            if (person.lastName?.length > 0) {
                name += (name.length > 0 ? ' ' : '') + person.lastName;
            }
            return { label: name, entity: person }
        });
        let emails = this.state.selectedPerson?.entity?.emails?.map((email) => { return { label: email.email, entity: email } });
        if (emails == null) {
            if (this.state.selectedPerson?.entity?.email?.length > 0) {
                emails = [{ label: this.state.selectedPerson.entity.email, entity: { email: this.state.selectedPerson.entity.email, type: EMAIL_TYPE_PERSONAL } }];
            }
        }
        else {
            if (this.state.selectedPerson?.entity?.email?.length > 0 && !emails.some((element) => { return element.label == this.state.selectedPerson.entity.email })) {
                emails.push({ label: this.state.selectedPerson.entity.email, entity: { email: this.state.selectedPerson.entity.email, type: EMAIL_TYPE_PERSONAL } });
            }
        }

        const sendable = this.state.selectedPerson != null && this.state.selectedEmail != null;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{t('education.submitValidationRequest')}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {this.state.errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{this.state.errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.onClose(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ errorMessage: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='link-selected font-size-15' for="country">{t("me.mysummary")}</Label>
                                                            </Col>
                                                        </Row>

                                                        {this.validationSummary()}

                                                        <Row className='mt-3'>
                                                            <Col md={12} xl={12}>
                                                                <div className='height-px-1 width-percent-100 bg-color-separator'></div>
                                                            </Col>
                                                        </Row>


                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='item1stLevel'>{t("general.person")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="item1stLevel"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={network}
                                                                    value={this.state.selectedPerson}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedPerson: value, selectedEmail: null });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='email'>{t("signUp.email")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="email"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={emails}
                                                                    value={this.state.selectedEmail}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedEmail: value });
                                                                    }}
                                                                    getOptionLabel={e => {
                                                                        return (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {e.entity.type == EMAIL_TYPE_PERSONAL ?
                                                                                    <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                                    <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                                                }
                                                                                <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='informationLanguage'>{t("education.commentEntryLanguage")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="informationLanguage"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={languages}
                                                                    value={this.state.selectedLanguage}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedLanguage: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for="country">{t("general.comment")}</Label>
                                                            </Col>
                                                        </Row>
                                                        <div className="firebaseui-relative-wrapper mt-0">
                                                            <textarea className="firebaseui-input border-1 rounded-5 height-min-60 height-max-300 bg-color-transparent text-color-input padding-5"
                                                                value={this.state.comment}
                                                                onChange={(e) => {
                                                                    this.setState({ comment: e.target.value })
                                                                }}
                                                            />
                                                            <div className="firebaseui-error-wrapper">
                                                                <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error" />
                                                            </div>
                                                        </div>

                                                        <br />

                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal box-side'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.onClose(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button className='' disabled={!sendable} color="primary" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.onRequest();
                                                                }}><i className='uil'></i>{t('personalInfo.save')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { allNetworkPeople } = state.Network;
    return { country, countries, allNetworkPeople, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    getAllNetworkPeople,
    createValidation,
    editValidation
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SubmitValidationRequestModal));