// @flow
import {
    ADD_COMMENT, ADD_COMMENT_FAILED, ADD_COMMENT_SUCCESS, DELETE_COMMENT, DELETE_COMMENT_FAILED, DELETE_COMMENT_SUCCESS, GET_COMMENTS, GET_COMMENTS_FAILED, GET_COMMENTS_SUCCESS, GET_REPOSITORIES, GET_REPOSITORIES_FAILED, GET_REPOSITORIES_SUCCESS
} from './constants';

export const getRepositories = (callbacks) => ({
    type: GET_REPOSITORIES,
    payload: { callbacks },
});

export const getRepositoriesSuccess = (repo) => ({
    type: GET_REPOSITORIES_SUCCESS,
    payload: repo,
});

export const getRepositoriesFailed = (error) => ({
    type: GET_REPOSITORIES_FAILED,
    payload: error,
});

export const addComment = (comment, callbacks) => ({
    type: ADD_COMMENT,
    payload: { comment, callbacks },
});

export const addCommentSuccess = (comment) => ({
    type: ADD_COMMENT_SUCCESS,
    payload: comment,
});

export const addCommentFailed = (error) => ({
    type: ADD_COMMENT_FAILED,
    payload: error,
});

export const getComments = (query, callbacks) => ({
    type: GET_COMMENTS,
    payload: { query, callbacks },
});

export const getCommentsSuccess = (comments) => ({
    type: GET_COMMENTS_SUCCESS,
    payload: comments,
});

export const getCommentsFailed = (error) => ({
    type: GET_COMMENTS_FAILED,
    payload: error,
});


export const deleteComment = (id, callbacks) => ({
    type: DELETE_COMMENT,
    payload: { id, callbacks },
});

export const deleteCommentSuccess = (id) => ({
    type: DELETE_COMMENT_SUCCESS,
    payload: id,
});

export const deleteCommentFailed = (error) => ({
    type: DELETE_COMMENT_FAILED,
    payload: error,
});