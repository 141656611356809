/* AUTH */
export const GET_NEXION = 'GET_NEXION';
export const GET_NEXION_SUCCESS = 'GET_NEXION_SUCCESS';
export const GET_NEXION_FAILED = 'GET_NEXION_FAILED';

export const GET_NEXION_TYPE = 'GET_NEXION_TYPE';
export const GET_NEXION_TYPE_SUCCESS = 'GET_NEXION_TYPE_SUCCESS';
export const GET_NEXION_TYPE_FAILED = 'GET_NEXION_TYPE_FAILED';

export const GET_NEXION_INFO = 'GET_NEXION_INFO';
export const GET_NEXION_INFO_SUCCESS = 'GET_NEXION_INFO_SUCCESS';
export const GET_NEXION_INFO_FAILED = 'GET_NEXION_INFO_FAILED';

export const CREATE_NEXION_REQUEST = 'CREATE_NEXION_REQUEST';
export const CREATE_NEXION_REQUEST_SUCCESS = 'CREATE_NEXION_REQUEST_SUCCESS';
export const CREATE_NEXION_REQUEST_FAILED = 'CREATE_NEXION_REQUEST_FAILED';

export const EDIT_NEXION_REQUEST = 'EDIT_NEXION_REQUEST';
export const EDIT_NEXION_REQUEST_SUCCESS = 'EDIT_NEXION_REQUEST_SUCCESS';
export const EDIT_NEXION_REQUEST_FAILED = 'EDIT_NEXION_REQUEST_FAILED';

export const DELETE_NEXION_REQUEST = 'DELETE_NEXION_REQUEST';
export const DELETE_NEXION_REQUEST_SUCCESS = 'DELETE_NEXION_REQUEST_SUCCESS';
export const DELETE_NEXION_REQUEST_FAILED = 'DELETE_NEXION_REQUEST_FAILED';

export const CREATE_NEXION_OFFER = 'CREATE_NEXION_OFFER';
export const CREATE_NEXION_OFFER_SUCCESS = 'CREATE_NEXION_OFFER_SUCCESS';
export const CREATE_NEXION_OFFER_FAILED = 'CREATE_NEXION_OFFER_FAILED';

export const EDIT_NEXION_OFFER = 'EDIT_NEXION_OFFER';
export const EDIT_NEXION_OFFER_SUCCESS = 'EDIT_NEXION_OFFER_SUCCESS';
export const EDIT_NEXION_OFFER_FAILED = 'EDIT_NEXION_OFFER_FAILED';

export const DELETE_NEXION_OFFER = 'DELETE_NEXION_OFFER';
export const DELETE_NEXION_OFFER_SUCCESS = 'DELETE_NEXION_OFFER_SUCCESS';
export const DELETE_NEXION_OFFER_FAILED = 'DELETE_NEXION_OFFER_FAILED';

export const GET_NEXION_HIERARCHY = 'GET_NEXION_HIERARCHY';
export const GET_NEXION_HIERARCHY_SUCCESS = 'GET_NEXION_HIERARCHY_SUCCESS';
export const GET_NEXION_HIERARCHY_FAILED = 'GET_NEXION_HIERARCHY_FAILED';

export const GET_NEXION_OFFER_KEYWORDS = 'GET_NEXION_OFFER_KEYWORDS';
export const GET_NEXION_OFFER_KEYWORDS_SUCCESS = 'GET_NEXION_OFFER_KEYWORDS_SUCCESS';
export const GET_NEXION_OFFER_KEYWORDS_FAILED = 'GET_NEXION_OFFER_KEYWORDS_FAILED';

export const CREATE_NEXION_OFFER_KEYWORDS = 'CREATE_NEXION_OFFER_KEYWORDS';
export const CREATE_NEXION_OFFER_KEYWORDS_SUCCESS = 'CREATE_NEXION_OFFER_KEYWORDS_SUCCESS';
export const CREATE_NEXION_OFFER_KEYWORDS_FAILED = 'CREATE_NEXION_OFFER_KEYWORDS_FAILED';
