import {AvFeedback,AvForm,AvGroup,AvInput} from 'availity-reactstrap-validation';
import React,{Component} from 'react';
import {Lock,Mail,User} from 'react-feather';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button,Col,InputGroup,Label,Row,UncontrolledAlert} from 'reactstrap';
import Loader from '../../components/Loader';
import firebase from '../../firebase/firebaseIndex';
import {AuthenticationStatus,userAuthenticatedStatus} from '../../helpers/authUtils';
import {checkPhoneNumberIsInUsed,getCountryInfo,loginUser} from '../../redux/actions';
import CodeInputModal from './CodeInputModal';
import PhoneInputModal from './PhoneInputModal';

class SignIn extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.props.getCountryInfo();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            username: '',
            password: '',
            tabviewMode: 1,
            countryCode: '',
            phoneNumber: '',
            verifyNumber: '',
            showPhoneInputDialog: 0,
            showCodeInputDialog: 0,
            sendingPhoneNumber: false,
            phoneNumberError: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    toggleTabView(tab) {
        if (this.state.tabviewMode !== tab) {
            this.setState({
                tabviewMode: tab
            });
        }
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        this.setState({ password: values.password });
        this.props.loginUser({ username: values.username, password: values.password }, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                const status = userAuthenticatedStatus();
                if (status === AuthenticationStatus.PhoneNotVerified) {
                    this.setState({ username: response.username, showPhoneInputDialog: 1 });
                }
            },
        });
    }



    handleLoginByPhoneNumber = async (resend) => {
        try {
            const appVerifier = window.appVerifier;
            const response = await firebase
                .auth()
                .signInWithPhoneNumber('+' + this.state.countryCode + this.state.phoneNumber, appVerifier)

            if (response && response.verificationId) {
                window.confirmationResult = response;
                this.setState({
                    showCodeInputDialog: 1
                });
            }
            else {
                appVerifier.reset(window.recaptchaWidgetId);
            }
            // console.log(response.toString());
        } catch (error) {
            alert(error.toString());
            this.setState({
                showPhoneInputDialog: 1,
            });
        }
    };

    verifyCodeSubmit = async () => {
        try {
            const verificationId = this.state.verifyNumber;
            const result = await window.confirmationResult
                .confirm(verificationId)

            if (result && result.user) {
                // Save login info
               
                const info = {
                    username: this.state.username, password: this.state.password,
                    email: this.state.email,
                    //country: this.state.countryCode, 
                    mobileNumber: this.state.countryCode + this.state.phoneNumber
                };
                this.props.loginUser(info, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                    },
                    callbackOnSuccess: (response) => {
                        // whatever
                        // console.log('callbackOnSuccess');

                    },
                });
                
            }
            else {
                //alert('The input code is invalid.');
                this.setState({
                    showCodeInputDialog: 1,
                });
            }
        } catch (error) {
            alert(error.toString());
            this.setState({
                showCodeInputDialog: 1,
            });
        }
    }

    hidePhoneInputModal = () => {
        this.setState({
            showPhoneInputDialog: 2,
        });
    };

    verifyPhoneNumber = (country, phone) => {
        // Check the number is belong to another account or not
        const fullNumber = '+' + country + phone;
        this.props.checkPhoneNumberIsInUsed(fullNumber, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ phoneNumberError: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                if (response && !response.in_used) {
                    this.setState({
                        showPhoneInputDialog: 2,
                    });
                    this.setState({ phoneNumber: phone, countryCode: country }, () => {
                        // console.log(this.state.phoneNumber, 'phoneNumber');
                        this.handleLoginByPhoneNumber(false);

                    });
                }
                else {
                    // Display error
                    this.setState({ phoneNumberError: this.props.t('phoneInput.numberInUsed') });
                }
            },
        });

    };

    toggleCodeInputModal = () => {
        this.setState({
            showCodeInputDialog: 2,
        });
    };

    verifyCodeNumber = (code) => {
        this.setState({
            showCodeInputDialog: 2,
        });
        this.setState({ verifyNumber: code }, () => {
            this.verifyCodeSubmit();
        });
    };

    resendCode = () => {
        this.handleLoginByPhoneNumber(true);
    }


    /**
     * Redirect to root
     */
    
    Alert = (props) => {
        const error = props.error;
        const { t } = this.props;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <UncontrolledAlert color="danger" key="alert" md={6}>
                {message != null ? t(message) : "Cannot connect to server."}
            </UncontrolledAlert>
        }
        return ""
    }

    render() {
        //const isAuthTokenValid = isUserAuthenticated();
        const { t, error } = this.props;
        return (
            <React.Fragment>
                {this.state.showPhoneInputDialog == 1 && <PhoneInputModal
                    title={'Enter your phone number'}
                    country={this.props.country && this.props.country.countryCode ? this.props.country.countryCode : 'fr'}
                    defaultNumber={'+84912933633'}
                    error={this.state.phoneNumberError}
                    modal={this.state.showPhoneInputDialog == 1}
                    toggle={this.hidePhoneInputModal} verify={this.verifyPhoneNumber} />}


                {this.state.showCodeInputDialog == 1 && <CodeInputModal
                    modal={this.state.showCodeInputDialog == 1}
                    phoneNumber={'+' + this.state.countryCode + this.state.phoneNumber}
                    toggle={this.toggleCodeInputModal}
                    verify={this.verifyCodeNumber}
                    resendCode={this.resendCode} />}

                {/* {this.renderRedirectToRoot()} */}

                <Row>
                    <Col md={12}>
                        { /* preloader */}
                        {this.props.loading && <Loader />}

                        <Label className="title-left-label">{t("signIn.welcome")}</Label>
                        {(this.props.changePasswordSucess == 1) &&
                            <UncontrolledAlert color="success" key="alert" md={6}>
                                {t('signIn.changePasswordSucess')}
                            </UncontrolledAlert>
                        }
                        {error && <this.Alert error={error} />}

                        <AvForm onValidSubmit={this.handleValidSubmit} className="authentication-form mt-1">
                            <Row>
                                <Col xl={4}>
                                    <AvGroup className="">
                                        <User className="icon-dual mr-1" />
                                        <Mail className="icon-dual mr-1" />
                                        <Label for="username">{t("signIn.user")}</Label>

                                        <InputGroup className='input-container'>
                                            <AvInput autoComplete="off" type="text" name="username" id="username"
                                                placeholder={t("signIn.userPlaceHolder")} value={this.state.username} required />
                                        </InputGroup>

                                        <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                    </AvGroup>
                                </Col>

                                <Col xl={4}>
                                    <AvGroup className="">
                                        <Lock className="icon-dual mr-1" />
                                        <Label for="password">{t("signIn.password")}</Label>
                                        <InputGroup className='input-container'>
                                            <AvInput autoComplete="off" type="password" name="password" id="password" placeholder={t("signIn.passwordPlaceHolder")} value={this.state.password} required />
                                        </InputGroup>
                                        <Link to={{}} onClick={this.props.onForgotButton} className="float-left text-muted text-unline-dashed ml-1 mt-2">{t("signIn.forgotPassword")}</Link>
                                        <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                    </AvGroup>
                                </Col>

                                <AvGroup className="">
                                    <Button color="primary" className="btn-block btn-top-margin">{t("signIn.logIn")}</Button>
                                </AvGroup>
                            </Row>
                        </AvForm>
                    </Col>
                </Row>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error, country } = state.Auth;
    return { user, loading, error, country };
};

export default connect(mapStateToProps, { loginUser, getCountryInfo, checkPhoneNumberIsInUsed })((withTranslation('translations')(SignIn)));
