// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {setLoggedInUser,setLoggedInUserProfile, setLoggedInUserPayment, getLoggedInUser} from '../../helpers/authUtils';
import {BASE_URL} from './../constants';
import {
    checkPhoneNumberIsInUsedFailed,checkPhoneNumberIsInUsedSuccess,forgetPasswordFailed,
    forgetPasswordSuccess,getAPICountryInfoFailed,getAPICountryInfoSuccess,getCountryInfoFailed,
    getCountryInfoSuccess,loginUserFailed,loginUserSuccess,registerUserFailed,registerUserSuccess,setNewPasswordFailed,
    setNewPasswordSuccess, requestSignupSuccess, requestSignupFailed,
    saveNextPathSuccess, saveNextPathFailed, saveNextPath
} from './actions';
import {CHECK_PHONENUMBERINUSED,FORGET_PASSWORD,GET_API_COUNTRY_INFO,GET_COUNTRY_INFO,
    LOGIN_USER,LOGOUT_USER,NEW_PASSWORD,REGISTER_USER, REQUEST_SIGNUP, SAVE_NEXT_PATH} from './constants';

/**
 * Save nextPath to push after login successfully
 * @param {*} payload - username and password
 */
 function* saveNextPathInternal({ payload: { path, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        yield put(saveNextPathSuccess(path));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, path);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setLoggedInUser(null);
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { info, callbacks } }) {
    const options = {
        body: info,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/auth/signin', options);
        response.roles.push("Admin");
        response.role = "Admin"
        if (info.email) {
            response.email = info.email;
            response.emailValidationSentDate = new Date();
        }
        setLoggedInUser(response);

        // Try to get user profile
        const optionsPro = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + response.accessToken }
        };
        const responsePro = yield call(fetchJSON, BASE_URL + 'profiles/' + response.id, optionsPro);
        setLoggedInUserProfile(responsePro);
        //

        yield put(loginUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setLoggedInUser(null);
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setLoggedInUser(null);
        setLoggedInUserProfile(null);
        setLoggedInUserPayment(null);
        yield call(() => {
           history.push('/');
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { params, callbacks } }) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: params,
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/auth/signup', options);
        setLoggedInUser(response);
        
        yield put(registerUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* countryInfo({ payload: { code } }) {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, 'https://extreme-ip-lookup.com/json/', options);
        yield put(getCountryInfoSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCountryInfoFailed(message));
    }
}

function* apiCountryInfo({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/localize/countries-info', options);
        yield put(getAPICountryInfoSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAPICountryInfoFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* setNewPasswordInternal({ payload: { data, callbacks } }) {
    const options = {
        body: data,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/auth/newpassword', options);
        yield put(setNewPasswordSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setNewPasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { data, callbacks } }) {
    const options = {
        body: data,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/auth/resetpassword', options);
        yield put(forgetPasswordSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkPhoneNumberIsInUsedInternal({ payload: { phone, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser()?.accessToken;
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/auth/check-phone-inused/' + phone, options);
        yield put(checkPhoneNumberIsInUsedSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(checkPhoneNumberIsInUsedFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* requestSignupInternal({ payload: { email, phone, callbacks } }) {
    const options = {
        body: {email, phone},
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/auth/request-signup', options);
        yield put(requestSignupSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(requestSignupFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchSaveNextPath() {
    yield takeEvery(SAVE_NEXT_PATH, saveNextPathInternal);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchGetCountryInfo() {
    yield takeEvery(GET_COUNTRY_INFO, countryInfo);
}

export function* watchGetAPICountryInfo() {
    yield takeEvery(GET_API_COUNTRY_INFO, apiCountryInfo);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchSetNewPassword() {
    yield takeEvery(NEW_PASSWORD, setNewPasswordInternal);
}

export function* watchCheckPhoneNumberIsInUsed() {
    yield takeEvery(CHECK_PHONENUMBERINUSED, checkPhoneNumberIsInUsedInternal);
}

export function* watchRequestSignup() {
    yield takeEvery(REQUEST_SIGNUP, requestSignupInternal);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser),
    fork(watchCheckPhoneNumberIsInUsed), fork(watchGetAPICountryInfo),
    fork(watchForgetPassword), fork(watchGetCountryInfo), fork(watchSetNewPassword),
    fork(watchRequestSignup), fork(watchSaveNextPath)]);
}

export default authSaga;
