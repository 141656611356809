import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { Lock, Mail, Phone, User, UserCheck } from 'react-feather';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Alert, Button, Col, InputGroup, Label, Row, UncontrolledAlert } from 'reactstrap';
import Loader from '../../components/Loader';
import firebase from '../../firebase/firebaseIndex';
import { isUserAuthenticated } from '../../helpers/authUtils';
import {
    checkPhoneNumberIsInUsed, getCountryInfo, loginUser,
    registerUser, updatePhoneNumber, requestSignup
} from '../../redux/actions';
import CodeInputModal from './CodeInputModal';
import PhoneInputModal from './PhoneInputModal';
import { BASE_URL, IS_STAGING } from './../../redux/constants';
import PhoneInput from 'react-phone-input-2';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';


export const INTRODUCE_EMAIL = 0;
export const INTRODUCE_PHONE = 1;


class Register extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.props.getCountryInfo();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            countryCode: '',
            phoneNumber: '',
            verifyNumber: '',
            showPhoneInputDialog: 0,
            showCodeInputDialog: 0,
            username: '',
            password: '',
            email: '',
            mobile: '',
            sendingPhoneNumber: false,
            phoneNumberError: null,
            signupMessage: null,
            introducedBy: null,
            introducedMail: '',
            firstName: '',
            lastName: '',
            country: 'fr',
            phone: '+33',
            signupWithInvitation: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        // console.log('componentDidMount: Register.js');
        this.processInvitation();
    }

    componentDidUpdate(prevProps) {
        if (this.props.invitation != prevProps.invitation) {
            this.processInvitation();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    processInvitation = () => {
        let invitation = this.props.invitation;
        if (invitation) {
            invitation = decodeURI(invitation);
            if (invitation?.length > 0) {
                if (invitation.substring(0, 1) == '?') {
                    invitation = invitation.slice(1);
                }

                let params = invitation.split('&');

                let introducedMail = params.find((element) => { return element.includes('invitee_email=') });
                introducedMail = introducedMail ? introducedMail.split('invitee_email=')[1] : '';
                if (introducedMail == 'null') {
                    introducedMail = null;
                }
                let mobile = params.find((element) => { return element.includes('invitation_phone=') });
                mobile = mobile ? mobile.split('invitation_phone=')[1] : '';
                if (mobile == 'null') {
                    mobile = null;
                }
                let email = params.find((element) => { return element.includes('invitation_email=') });
                email = email ? email.split('invitation_email=')[1] : '';
                if (email == 'null') {
                    email = null;
                }
                let firstName = params.find((element) => { return element.includes('first_name=') });
                firstName = firstName ? firstName.split('first_name=')[1] : null;
                if (firstName == 'null') {
                    firstName = '';
                }
                let lastName = params.find((element) => { return element.includes('last_name=') });
                lastName = lastName ? lastName.split('last_name=')[1] : null;
                if (lastName == 'null') {
                    lastName = '';
                }
                this.setState({
                    email: email, mobile: mobile, introducedBy: INTRODUCE_EMAIL,
                    introducedMail: introducedMail,
                    firstName: firstName, lastName: lastName,
                    signupWithInvitation: true
                });
            }
        }
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        let introduceBy = this.state.introducedBy == INTRODUCE_EMAIL ? values.introduceEmail : this.state.phone;
        this.setState({ username: values.username, password: values.password, email: values.email, introduceBy: introduceBy });

        let params = {};
        params.username = values.username;
        params.email = values.email?.length > 0 ? values.email : null;
        params.mobile = this.state.mobile?.length > 0 ? this.state.mobile : null;
        params.password = values.password;
        params.introducedBy = introduceBy;
        if (this.state.introducedMail.length > 0) {
            params.invitedByNetwork = true;
            params.firstName = decodeURIComponent(this.state.firstName ?? '');
            params.lastName = decodeURIComponent(this.state.lastName ?? '');
        }

        this.props.registerUser(params, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                this.verifyUserPhoneNumber();
            },
        });

        this.setState({ showPhoneInputDialog: 0 });
    }

    handleRequestSignup = (event, values) => {
        // Just show message
        const { t } = this.props;
        let phone = '+' + this.state.country + this.state.phone;
        this.props.requestSignup(values.email, phone, {
            callbackOnSuccess: (response) => {
                this.setState({ signupMessage: t('signUp.thankyou') });
            }
        });
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    Info = (props) => {
        const message = props.message;
        if (message != null) {
            return <UncontrolledAlert color="success" key="alert" toggle={() => {
                this.setState({ signupMessage: null });
            }}>
                {message}
            </UncontrolledAlert>
        }
        return ""
    }

    verifyUserPhoneNumber = () => {
        this.setState({ showPhoneInputDialog: 1 });
    }

    handleLoginByPhoneNumber = async (resend) => {
        try {
            const appVerifier = window.appVerifier;
            const response = await firebase
                .auth()
                .signInWithPhoneNumber('+' + this.state.countryCode + this.state.phoneNumber, appVerifier)

            if (response && response.verificationId) {
                window.confirmationResult = response;
                this.setState({
                    showCodeInputDialog: 1
                });
            }
            else {
                appVerifier.reset(window.recaptchaWidgetId);
            }
            // console.log(response.toString());
        } catch (error) {
            alert(error.toString());
            this.setState({
                showPhoneInputDialog: 1,
            });
        }
    };

    verifyCodeSubmit = async () => {
        try {
            const verificationId = this.state.verifyNumber;
            const result = await window.confirmationResult
                .confirm(verificationId)

            if (result && result.user) {
                // Save login info

                const info = {
                    username: this.state.username, password: this.state.password,
                    email: this.state.email,
                    //country: this.state.countryCode, 
                    mobileNumber: this.state.countryCode + this.state.phoneNumber
                };
                this.props.loginUser(info, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                    },
                    callbackOnSuccess: (response) => {
                        // whatever
                        // console.log('callbackOnSuccess');
                    },
                });
            }
            else {
                this.setState({
                    showCodeInputDialog: 1,
                });
            }
        } catch (error) {
            alert(error.toString());
            this.setState({
                showCodeInputDialog: 1,
            });
        }
    }

    hidePhoneInputModal = () => {
        this.setState({
            showPhoneInputDialog: 2,
        });
    };

    verifyPhoneNumber = (country, phone) => {
        // Check the number is belong to another account or not
        const fullNumer = '+' + country + phone;
        this.props.checkPhoneNumberIsInUsed(fullNumer, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ phoneNumberError: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                if (response && !response.in_used) {
                    this.setState({
                        showPhoneInputDialog: 2,
                    });
                    this.setState({ phoneNumber: phone, countryCode: country }, () => {
                        // console.log(this.state.phoneNumber, 'phoneNumber');
                        this.handleLoginByPhoneNumber(false);

                    });
                }
                else {
                    // Display error
                    this.setState({ phoneNumberError: this.props.t('phoneInput.numberInUsed') });
                }
            },
        });

    };

    toggleCodeInputModal = () => {
        this.setState({
            showCodeInputDialog: 2,
        });
    };

    verifyCodeNumber = (code) => {
        this.setState({
            showCodeInputDialog: 2,
        });
        this.setState({ verifyNumber: code }, () => {
            this.verifyCodeSubmit();
        });
    };

    resendCode = () => {
        this.handleLoginByPhoneNumber(true);
    }

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        const { t, error } = this.props;
        const isProduction = !IS_STAGING;

        const introduceOptions = [
            { value: INTRODUCE_EMAIL, label: t('signUp.email') },
            { value: INTRODUCE_PHONE, label: t('personalInfo.mobilephone') },
        ];

        return (
            <React.Fragment>
                {this.state.showPhoneInputDialog == 1 && <PhoneInputModal
                    title={'Enter your phone number'}
                    country={this.props.country && this.props.country.countryCode ? this.props.country.countryCode : 'fr'}
                    defaultNumber={this.state.mobile}
                    error={this.state.phoneNumberError}
                    modal={this.state.showPhoneInputDialog == 1}
                    toggle={this.hidePhoneInputModal} verify={this.verifyPhoneNumber} />}

                {this.state.showCodeInputDialog == 1 && <CodeInputModal
                    modal={this.state.showCodeInputDialog == 1}
                    phoneNumber={'+' + this.state.countryCode + this.state.phoneNumber}
                    toggle={this.toggleCodeInputModal}
                    verify={this.verifyCodeNumber}
                    resendCode={this.resendCode} />}

                {(this._isMounted || !isAuthTokenValid) &&
                    <div className="account-pages mt-0">
                        {(isProduction && !this.state.signupWithInvitation) ?
                            <AvForm onValidSubmit={this.handleRequestSignup} className="authentication-form mt-1">
                                <Row>
                                    <Col md={12}>
                                        {this.props.loading && <Loader />}
                                        {this.state.signupMessage && <this.Info message={this.state.signupMessage} />}
                                        <Label className="title-left-label">{t("signUp.signUpPrivateNetworkMessage")}</Label>

                                         {error && <this.Alert error={error} />}
                                        <Row className="align-items-center">
                                            <Col xl={4}>
                                                <AvGroup className="">
                                                    <Mail className="icon-dual mr-1" />
                                                    <Label for="email">{t("signUp.email")}</Label>
                                                    <InputGroup className='input-container'>

                                                        <AvInput autoComplete="off" type="email" name="email" id="email" placeholder={t("signUp.emailSignUp")} />
                                                    </InputGroup>

                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                            <Col xl={4}>
                                                <AvGroup className="mb-3">
                                                    <Phone className="icon-dual mr-1" />
                                                    <Label for="password">{t("reset.mobilePhone")}</Label>
                                                    <InputGroup className='input-container'>
                                                        <PhoneInput
                                                            containerClass={"phone-input-custom"}
                                                            country={this.props.country && this.props.country.countryCode.toLowerCase() ? this.props.country.countryCode.toLowerCase() : 'fr'}
                                                            onChange={(value, data, event, formattedValue) => {
                                                                this.setState({ phone: value.slice(data.dialCode.length), country: data.dialCode })
                                                            }}
                                                        />
                                                        {/* <AvInput autoComplete="off" type="phone" name="phone" id="phone" placeholder={t("signIn.phonePlaceHolder")} /> */}
                                                    </InputGroup>
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                            <AvGroup className="">
                                                <Button color="primary" className="btn-block btn-top-margin">{t("reset.send")}</Button>
                                            </AvGroup>
                                        </Row>
                                        <Row>
                                            <Col xl={8}>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Label className="title-left-small-hyperlink"><a onClick={
                                            () => {
                                                this.setState({ signupWithInvitation: true });
                                            }
                                        }>{t("I have email/mobile invitation by a member.")}</a></Label>
                                    </Col>
                                </Row>
                            </AvForm> :

                            <AvForm onValidSubmit={this.handleValidSubmit} className="authentication-form mt-1">
                                <Row>
                                    <Col md={12}>
                                        {this.props.loading && <Loader />}
                                        <Label className="title-left-label">{t("signUp.welcomeSignUp")}</Label>
                                        {error && <this.Alert error={error} />}

                                        <Row className="align-items-center">
                                            <Col xl={4}>
                                                <AvGroup className="mb-3">
                                                    <UserCheck className="icon-dual mr-1" />
                                                    <Label for="introducedBy">{t("signUp.introducedBy")}</Label>
                                                    <Select className="react-select"
                                                        isDisabled={this.state.introducedMail.length > 0}
                                                        classNamePrefix="react-select"
                                                        name="action" id="action"
                                                        options={introduceOptions}
                                                        placeholder={t('placeholder.Select')}
                                                        value={this.state.introducedBy != null ? introduceOptions.find((element) => { return element.value == this.state.introducedBy }) : null}
                                                        onChange={(value) => { this.setState({ introducedBy: value.value }) }} />
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                            {this.state.introducedBy == INTRODUCE_EMAIL &&
                                                <Col xl={4}>
                                                    <AvGroup className="mb-3">
                                                        <Mail className="icon-dual mr-1" />
                                                        <Label for="introduceEmail">{t("signUp.email")}</Label>
                                                        <InputGroup className='input-container'>
                                                            <AvInput autoComplete="off" type="email" name="introduceEmail" id="introduceEmail" placeholder={t("signUp.introduceEmail")} value={this.state.introducedMail} disabled={this.state.introducedMail.length > 0} required />
                                                        </InputGroup>
                                                        <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                    </AvGroup>
                                                </Col>
                                            }

                                            {this.state.introducedBy == INTRODUCE_PHONE &&
                                                <Col xl={4}>
                                                    <AvGroup className="mb-3">
                                                        <Phone className="icon-dual mr-1" />
                                                        <Label for="introducePhone">{t("personalInfo.mobilephone")}</Label>
                                                        <PhoneInput className='width-percent-100'
                                                            containerClass={"phone-input-custom"}
                                                            country={this.state.country.toLowerCase()}
                                                            value={this.props.editingValidation?.referenceUserPhone ?? ''}
                                                            onChange={(value, data, event, formattedValue) => {
                                                                let phone = '';
                                                                if (data?.dialCode) {
                                                                    phone = '+' + data.dialCode + value.slice(data.dialCode.length);
                                                                }
                                                                this.setState({ country: data.dialCode, phone: phone })
                                                            }}
                                                        />
                                                        <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                    </AvGroup>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col xl={4}>
                                                <AvGroup className="">
                                                    <User className="icon-dual mr-1" />
                                                    <Label for="username">{t("signUp.usernameSignUp")}</Label>
                                                    <InputGroup className='input-container'>
                                                        <AvInput type="text" name="username" id="username" placeholder={t("signUp.usernameSignUp")} required />
                                                    </InputGroup>
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col xl={4}>
                                                <AvGroup className="">
                                                    <Mail className="icon-dual mr-1" />
                                                    <Label for="email">{t("signUp.email")}</Label>
                                                    <InputGroup className='input-container'>
                                                        <AvInput autoComplete="off" type="email" name="email" id="email" placeholder={t("signUp.emailSignUp")} value={this.state.email} disabled={this.state.email.length > 0} required />
                                                    </InputGroup>
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                            <Col xl={4}>
                                                <AvGroup className="mb-3">
                                                    <Lock className="icon-dual mr-1" />
                                                    <Label for="password">{t("signIn.password")}</Label>
                                                    <InputGroup className='input-container'>
                                                        <AvInput autoComplete="off" type="password" name="password" id="password" placeholder={t("signIn.passwordPlaceHolder")} required />
                                                    </InputGroup>
                                                    <AvFeedback>{t("signIn.fieldInvalid")}</AvFeedback>
                                                </AvGroup>
                                            </Col>

                                            <AvGroup className="">
                                                <Button color="primary" className="btn-block btn-top-margin">{t("signIn.signUp")}</Button>
                                            </AvGroup>
                                        </Row>

                                    </Col>
                                </Row>
                                {isProduction &&
                                    <>
                                        <Row>
                                            <Col xl={8}>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Label className="title-left-small-hyperlink"><a onClick={
                                            () => {
                                                this.setState({ signupWithInvitation: false });
                                            }
                                        }>{t("Back to request to join page.")}</a></Label>
                                    </>}
                            </AvForm>
                        }
                    </div>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const user = state.Auth.user;
    const country = state.Auth.country;
    const profile = state.Profile.profile;
    const loading = state.Auth.loading;
    const error = state.Auth.error ? state.Auth.error : state.Profile.error;
    return { user, country, profile, loading, error };
};

export default connect(mapStateToProps, {
    loginUser,
    registerUser, getCountryInfo, updatePhoneNumber,
    checkPhoneNumberIsInUsed, requestSignup
})((withTranslation('translations')(Register)));
