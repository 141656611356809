import React,{Component} from 'react';
import {withTranslation} from "react-i18next";
import {Card,CardBody,CardFooter,CardHeader,Col,Label,Media,Row,TabPane} from 'reactstrap';
import {format} from '../../helpers/stringFormat';
import {getLocalizedString} from './../../helpers/utils';

class ProductView extends Component {
    constructor(props) {
        super(props);
        this.priceString = this.priceString.bind(this);
        this.priceOptionString = this.priceOptionString.bind(this);
        this.state = {
            // isTab1Open: false,
        };
    }

    componentDidMount() {

    }

    priceOptionString = (option, engagementTypeDetail, index) => {
        const { t } = this.props
        const nameCur = getLocalizedString(this.props.selectTypePay.name)
        if (option) {
            if (option.subscriptionPeriod == 1) {
                return (
                    <div className="mt-1" key={index}>
                        <Row>

                            <button className="btn btn-primary mr-1 btn-height-50">{format(t('pricing.priceTag'), option.monthPrice[nameCur], this.props.selectTypePay.symbol)}</button>
                            <Col >
                                <Label>{format(t('pricing.priceEngagement'), option.paymentPlan, option.subscriptionPeriod ? option.subscriptionPeriod : option.paymentPlan, engagementTypeDetail ? getLocalizedString(engagementTypeDetail) : '')}</Label>
                            </Col>
                        </Row>

                    </div>
                )
            }
            else {
                return (
                    <div className="mt-1" key={index}>
                        <Row>

                            <button className="btn btn-primary mr-1 btn-height-50">{format(t('pricing.priceTag'), option.monthPrice[nameCur], this.props.selectTypePay.symbol)}</button>
                            <Col>
                                <Label>{format(t('pricing.priceEngagement'), option.paymentPlan, option.subscriptionPeriod ? option.subscriptionPeriod : option.paymentPlan, engagementTypeDetail ? getLocalizedString(engagementTypeDetail) : '')}</Label>
                            </Col>
                        </Row>
                    </div>

                )
            }
        }
        return ''
    }

    priceString = (price) => {
        return price.options.map((op, index) => {
            return this.priceOptionString(price.options[index], price.engagementTypeDetail && price.engagementTypeDetail.name , index);
        })
    }

    render() {
        const index = this.props.index;
        const product = this.props.product;
        const productDocs = product.productDocs;
        const priceString = this.priceString;
        const selectTypePay = this.props.selectTypePay
        const { t } = this.props
        return (
            <TabPane tabId={index} key={index}>
                <Row>
                    {productDocs.map(function (d, idx) {
                        // console.log(idx);
                        return (

                            <Col lg={4} className="d-flex align-items-stretch" key={idx}>
                                <Card className='white-height-card'>
                                    <CardHeader className="intro-card-bottom m-1">
                                        <Label key={idx} className="title-dark-small-label">{d.detail && d.detail.name ? getLocalizedString(d.detail.name) : ''}</Label>
                                    </CardHeader>
                                    <CardBody className="p-4">
                                        <Media>
                                            <Media body>
                                                <div className='card-mini-70'>
                                                    {idx > 0 ?
                                                        <h5>{format(t('pricing.extension'), getLocalizedString(productDocs[idx - 1].detail?.name))}</h5>
                                                        :
                                                        <h5>{t('pricing.freeExtension')}</h5>
                                                    }
                                                </div>

                                                {d.detail?.features?.map((feature,index) =>
                                                    <li className="mt-0 mb-2" key={index}>
                                                        <span>{feature.name ? getLocalizedString(feature.name) : ''}</span>
                                                    </li>
                                                )}
                                            </Media>
                                        </Media>
                                    </CardBody>
                                    <CardFooter className="clear-card-bottom m-1">
                                        {d.detail && d.detail.pricing && (d.detail.pricing.length > 0) ?
                                            <div>

                                                {d.detail.pricing.map((price) =>

                                                    priceString(price)

                                                )}

                                            </div>
                                            : <button className="btn btn-primary btn-height-50">{format(t('pricing.priceTag'),"0",selectTypePay.symbol )}</button>}

                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    })
                    }

                </Row>
            </TabPane>
        )
    }
}

export default withTranslation('translations')(ProductView)
