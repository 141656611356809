// @flow
import { AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, Label, Modal, Row, Table
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { countryCodeToString, getLocalizedString, locationString } from '../../../../helpers/utils';
import { getAPICountryInfo } from '../../../../redux/actions';
import SVG from 'react-inlinesvg';
import companyIcon from '../../../../assets/images/menu/companies.svg';
import { User } from 'react-feather';
import { EMAIL_TYPE_PERSONAL } from './ContactModal';
import { format } from '../../../../helpers/stringFormat';
import { findPhoneNumbersInText } from 'libphonenumber-js'

class RemoveNetworkModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'xx',
            errorMessage: null,
            academy: null,
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    nerworkPersonSummary = () => {
        let t = this.props.t;
        let person = this.props.networkPerson;
        let summary = {};
        if (person?.firstName?.length > 0) {
            summary[t('personalInfo.firstName')] = person?.firstName;
        }
        if (person?.lastName?.length > 0) {
            summary[t('personalInfo.lastName')] = person?.lastName;
        }
        if (person?.emails?.length > 0) {
            summary[t('profile.e-mails')] = person?.emails;
        }
        else if (person?.email?.length > 0) {
            summary[t('signUp.email')] = [{ email: person?.email, type: EMAIL_TYPE_PERSONAL }];
        }

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        if (person?.mobiles?.length > 0) {
            let phones = [];
            person.mobiles.forEach(element => {
                let founded = findPhoneNumbersInText(element.mobile || element);

                let country = '';
                if (founded?.length > 0) {
                    country = countryCodeToString(founded[0].number?.country, countries);
                } else {
                    country = countryCodeToString(person.country, countries);
                }
                phones.push({ country: country, phone: element.mobile || element });
            });

            summary[t('general.mobiles')] = phones;
        }
        else if (person?.mobileNumber?.length > 0) {
            let founded = findPhoneNumbersInText(person?.mobileNumber);
            let country = '';
            if (founded?.length > 0) {
                country = countryCodeToString(founded[0].number?.country, countries);
            }
            summary[t('reset.mobile')] = [{ country: country, phone: person?.mobileNumber }];
        }

        return summary;
    }

    nerworkCompanySummary = () => {
        let t = this.props.t;

        let summary = {};

        let company = this.props.networkCompany;
        if (company) {
            if (company?.name?.length > 0) {
                summary[t('sub.Companyname')] = company.name;
            }

            if (company?.geoType?.length > 0) {
                summary[t('company.geographicalType')] = company.geoType;
            }

            if (company?.location?.length > 0) {
                summary[t(company?.geoTypeId == 1 ? 'general.headquarter' : 'nexions.locations')] = company.location;
            }

            if (company?.industries?.length > 0) {
                summary[t('profile.industries')] = company.industries;
            }

            if (company?.domains?.length > 0) {
                summary[t('company.domains')] = company.domains?.map((element) => { return element.email });
            }

            // if (company?.admins?.length > 0) {
            //     summary[t('company.admin')] = company.admins;
            // }
        }

        return summary;
    }

    nerworkAcademySummary = () => {
        let t = this.props.t;
        let summary = {};

        let academy = this.props.networkAcademy;
        if (academy) {
            if (academy?.name?.length > 0) {
                summary[t('Academy')] = academy.name;
            }
            if (academy?.faculties?.length > 0) {
                summary[t('academy.faculties')] = academy.faculties;
            }
            if (academy?.cities?.length > 0) {
                const countries = this.props.countries?.map(
                    (element, i) => {
                        return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                    }
                );
                summary[t('company.locations')] = academy?.cities.map((element) => { return locationString(element.country, element.state, element.city, countries) });
            }

            if (academy?.domains?.length > 0) {
                summary[t('company.domains')] = academy.domains;
            }
        }

        return summary;
    }

    summaryTable = (summary) => {
        let t = this.props.t;
        return (
            <div>
                <Table className="mb-0" striped>
                    <tbody>
                        {Object.keys(summary).length > 0 && Object.keys(summary).map((key, index) => {
                            const keyEmail = key == t('profile.e-mails') || key == t('signUp.email');
                            const keyMobile = key == t('general.mobiles') || key == t('reset.mobile');
                            const keyArray = !(typeof summary[key] === 'string');

                            return (
                                <tr key={key}>
                                    <th style={{ 'width': '35%' }} scope="row">{key}</th>
                                    {keyEmail &&
                                        <td style={{ 'width': '65%' }}>{
                                            <div className='align-vertical'>
                                                {summary[key].map((element, index) => {
                                                    return (
                                                        <div className={index > 0 ? 'mt-2' : ''} style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                            {element.type == EMAIL_TYPE_PERSONAL ?
                                                                <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                            }
                                                            <span style={{ marginLeft: 3 }}>{element.email}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }</td>
                                    }

                                    {keyMobile &&
                                        <td style={{ 'width': '65%' }}>{
                                            <div className='align-vertical'>
                                                {summary[key].map((element, index) => {
                                                    return (
                                                        <div className={(index > 0 ? 'mt-2' : '') + ' align-horizontal'} key={index}>
                                                            <span>{element.phone}</span>
                                                            <span className={'ml-2 mr-2'}>{'|'}</span>
                                                            <span>{element.country}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }</td>
                                    }

                                    {keyArray && !keyMobile && !keyEmail &&
                                        <td style={{ 'width': '65%' }}>{
                                            <div className='align-vertical'>
                                                {summary[key].map((element, index) => {
                                                    return (
                                                        <span key={index} className={index > 0 ? 'mt-2' : ''}>{element}</span>
                                                    )
                                                })}
                                            </div>
                                        }</td>
                                    }

                                    {(!keyEmail && !keyMobile && !keyArray) &&
                                        <td style={{ 'width': '65%' }}>{summary[key]}</td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    closeModal = (response) => {
        if (this.props.onCancel) {
            this.props.onCancel(response);
        }
    }

    render() {
        const { t } = this.props;

        let title = '';
        let message = '';
        let summary = {};
        if (this.props.networkPerson) {
            title = format(t('alert.deleteTitle'), t('general.person').toLowerCase());
            message = format(t('alert.removeNetworkMessage'), t('general.person').toLowerCase());
            summary = this.nerworkPersonSummary();
        }
        else if (this.props.networkCompany) {
            title = format(t('alert.deleteTitle'), t('subscription.company').toLowerCase());
            message = format(t('alert.removeNetworkMessage'), t('subscription.company').toLowerCase());
            summary = this.nerworkCompanySummary();
        }
        else if (this.props.networkAcademy) {
            title = format(t('alert.deleteTitle'), t('Academy').toLowerCase());
            message = format(t('alert.removeNetworkMessage'), t('Academy').toLowerCase());
            summary = this.nerworkAcademySummary();
        }
        else {
            title = format(t('alert.deleteTitle'), t(this.props.objectType ?? ''));
            message = this.props?.sourceType ? format(t('alert.removeObjectConfirmation'), t(this.props.objectType ?? '').toLowerCase(), t(this.props.sourceType ?? '').toLowerCase()) : format(t('alert.removeNetworkMessage'), t(this.props.objectType ?? '').toLowerCase());
            summary = this.props.summary;
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.closeModal(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{title}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {this.state.errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{this.state.errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.closeModal(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ errorMessage: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='link-selected font-size-15' for="country">{t("general.summary")}</Label>
                                                            </Col>
                                                        </Row>

                                                        {this.summaryTable(summary)}

                                                        <Row className='mt-3'>
                                                            <Col md={12} xl={12}>
                                                                <div className='height-px-1 width-percent-100 bg-color-separator'></div>
                                                            </Col>
                                                        </Row>

                                                        <br />

                                                        <Row className=''>
                                                            <Col md={12} xl={12}>
                                                                <Label>{message}</Label>
                                                            </Col>
                                                        </Row>

                                                        <br />

                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal box-side'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.closeModal(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="danger" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    if (this.props.onSubmit) {
                                                                        this.props.onSubmit();
                                                                    }
                                                                }}><i className='uil'></i>{t('Remove')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { networkPeople } = state.Network;
    return { country, countries, networkPeople, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(RemoveNetworkModal));