// @flow
import React from 'react';
import { S3_BASE_URL } from '../redux/constants';

// const BASE_ICONS_URL = process.env.PUBLIC_URL + '/final-svg/';
const BASE_ICONS_URL = S3_BASE_URL + "/monroe-icons";
const SubIcon = BASE_ICONS_URL + '/sub.svg';
const LogOutIcon = BASE_ICONS_URL + '/log-out.svg';
const AvatarIcon = BASE_ICONS_URL + '/avatar.svg';
const XOfferAdd = BASE_ICONS_URL + '/xoffer_add.svg'
const XRequestAdd = BASE_ICONS_URL + '/Axrequest_add.svg'


// Home
const HomeIcon = BASE_ICONS_URL + '/home.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/home.svg';
const MeIcon = BASE_ICONS_URL + '/me.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/barre/me.svg';
const AcademicsIcon = BASE_ICONS_URL + '/education.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/barre/education.svg';
const IndustriesIcon = BASE_ICONS_URL + '/industrie.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/barre/industrie.svg';
const DepartmentsIcon = BASE_ICONS_URL + '/departments.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/barre/departments.svg';
const TechnologiesIcon = BASE_ICONS_URL + '/technologies.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/home/barre/technologies.svg';

// Issue
const IssuesIcon = BASE_ICONS_URL + '/issues.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/issues.svg';
const IssuesTechnologyIcon = BASE_ICONS_URL + '/barre/technology.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/barre/technology.svg';
const IssuesProjectIcon = BASE_ICONS_URL + '/barre/project.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/barre/project.svg';
const IssuesHumanIcon = BASE_ICONS_URL + '/barre/human.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/barre/human.svg';
const IssuesDepartmentalIcon = BASE_ICONS_URL + '/barre/departmental.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/barre/departmental.svg';
const IssuesCorporateIcon = BASE_ICONS_URL + '/barre/corporate.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/issues/barre/corporate.svg';

// Connexions
const ConnexionsIcon = BASE_ICONS_URL + '/conneXions.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/conneXions.svg';
const ConnexionsEventingIcon = BASE_ICONS_URL + '/eventing.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/eventing.svg';
const ConnexionsTechnologyIcon = BASE_ICONS_URL + '/technology.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/technology.svg';
const ConnexionsRecruitmentIcon = BASE_ICONS_URL + '/recruitment.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/recruitment.svg';
const ConnexionsEducationIcon = BASE_ICONS_URL + '/education.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/education.svg';
const ConnexionsIntegrationIcon = BASE_ICONS_URL + '/integration.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/integration.svg';
const ConnexionsStrategyIcon = BASE_ICONS_URL + '/strategy.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/strategy.svg';
const ConnexionsChangeIcon = BASE_ICONS_URL + '/change.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/change.svg';
const ConnexionsCoachingIcon = BASE_ICONS_URL + '/coaching.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/coaching.svg';
const ConnexionsTrainingIcon = BASE_ICONS_URL + '/training.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/conneXions/barre/training.svg';

// Repositories
const RepositoriesIcon = BASE_ICONS_URL + '/repositories.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/repositories.svg';
const RepositoriesAllIssuesIcon = BASE_ICONS_URL + '/allissues.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/barre/allissues.svg';
const RepositoriesDataTechnologyIcon = BASE_ICONS_URL + '/datatechnology.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/barre/datatechnology.svg';
const RepositoriesAllIndustriesIcon = BASE_ICONS_URL + '/allindustries.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/barre/allindustries.svg';
const RepositoriesAllDepartmentsIcon = BASE_ICONS_URL + '/alldepartments.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/barre/alldepartments.svg';
const RepositoriesEducationIcon = BASE_ICONS_URL + '/education.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/repositories/barre/education.svg';

// Settings
const SettingsIcon = BASE_ICONS_URL + '/settings.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/settings/settings.svg';
const SettingsApplicationIcon = BASE_ICONS_URL + '/application.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/settings/barre/application.svg';
const SettingsSubscriptionsIcon = BASE_ICONS_URL + '/subscriptions.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/settings/barre/subscriptions.svg';
const SettingsProfileIcon = BASE_ICONS_URL + '/profile.svg';//'https://datanexions.s3.eu-west-2.amazonaws.com/monroe-icons/menu/settings/barre/profile.svg';
const SettingsBackgroundIcon = BASE_ICONS_URL + '/background.svg';
const ActAsIcon = BASE_ICONS_URL + '/profile.svg';

// Validation
const Requested = BASE_ICONS_URL + "/validated.svg";
const Validation = BASE_ICONS_URL + "/validation.svg";
const Validations = BASE_ICONS_URL + "/validations.svg";
const Validated = BASE_ICONS_URL + "/validation_checked.svg";
const Waiting = BASE_ICONS_URL + "/validation_waiting.svg";
const Rejected = BASE_ICONS_URL + "/validation_rejected.svg";
const ValidationRequest = BASE_ICONS_URL + "/validation-request.svg";
const ValidationIn = BASE_ICONS_URL + "/validation-in.svg";
const ValidationOut = BASE_ICONS_URL + "/validation-out.svg";

// Network
const NetworkIcon = BASE_ICONS_URL + "/network.svg";


export {AcademicsIcon, HomeIcon, MeIcon, DepartmentsIcon,
    TechnologiesIcon, IndustriesIcon, SubIcon, LogOutIcon,
    AvatarIcon, XOfferAdd, XRequestAdd,
    IssuesIcon, IssuesTechnologyIcon, IssuesProjectIcon, IssuesHumanIcon, IssuesDepartmentalIcon, IssuesCorporateIcon,
    ConnexionsIcon, ConnexionsEventingIcon, ConnexionsTechnologyIcon, ConnexionsRecruitmentIcon, ConnexionsEducationIcon,
    ConnexionsIntegrationIcon, ConnexionsStrategyIcon, ConnexionsChangeIcon, ConnexionsCoachingIcon, ConnexionsTrainingIcon,
    RepositoriesIcon, RepositoriesAllIssuesIcon, RepositoriesDataTechnologyIcon, RepositoriesAllIndustriesIcon, RepositoriesAllDepartmentsIcon, RepositoriesEducationIcon,
    SettingsIcon, SettingsApplicationIcon, SettingsSubscriptionsIcon, SettingsProfileIcon,
    Requested, Validated, Validation, Waiting, Rejected, ValidationRequest, SettingsBackgroundIcon,
    NetworkIcon, ActAsIcon,
    ValidationIn, ValidationOut, Validations
};
