import { ifStatement } from '@babel/types';
import { ready } from 'jquery';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Button, Col, Label, Row } from 'reactstrap';
import { getLocalizedString } from '../../../../../../helpers/utils';
var CountryLanguage = require('country-language');


class StepAddCountryToAcademic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            className: 'custom-modal-style',
        };
    }

    componentDidMount = () => {

    };

    render() {
        const { t, disabledNext, currentCountryToAcademicEntity, currentNewIntCodeToAcademicEntity,
            currentStateOfCountryToAcademicEntity, isEditing } = this.props;
        const countries = this.props.countries;
        var allLanguageCodes = CountryLanguage.getLanguages();

        const languages = allLanguageCodes?.map((element, i) => {
            return {
                value: element.iso639_2en,
                label: element.name?.length > 0 ? element.name[0] : '',
                language: element
            };
        });

        const statesOfCountry = this.props.statesOfCountry?.map((element, i) => {
            return {
                value: element.id,
                label: getLocalizedString(element.name),
                states: element.states ? element.states : null,
            };
        });

        // let enableSave = currentCountryToAcademicEntity != null || currentNewIntCodeToAcademicEntity != null;

        const editing = isEditing ?? false;
        const readOnly = this.props.readOnly ?? false;

        return (
            <div className='align-vertical mt-4' xl={12}>
                <Label className="font-bold mb-3"> {this.props.title ?? t('academic.titleStep1')} :</Label>
                <Label className="font-bold">{t('academic.subStep1')}</Label>

                <br />
                <Row>
                    <Col xl={5} className="align-vertical mr-5 mb-4" >
                        <Label className=''>{t(editing ? 'sub.Country1' : 'sub.Selectyourcountry')}</Label>
                        {(editing || readOnly) ?
                            <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentCountryToAcademicEntity != null && countries?.length > 0) ? countries.find(op => {
                                return op.value === currentCountryToAcademicEntity
                            })?.label : ''}</Label>
                            :
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder={t('placeholder.Select')}
                                options={countries}
                                value={(currentCountryToAcademicEntity != null && countries?.length > 0) ? countries.find(op => {
                                    return op.value === currentCountryToAcademicEntity
                                }) : null}
                                onChange={this.props.onChangeCountryToAcademicEntity}
                            ></Select>
                        }
                    </Col>

                    {statesOfCountry &&
                        <Col xl={5} className='align-vertical mb-4'>
                            <Label className=''>{t(readOnly ? 'personalInfo.state' : 'personalInfo.state')} :</Label>
                            {readOnly ?
                                <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentStateOfCountryToAcademicEntity != null && statesOfCountry?.length > 0) ? statesOfCountry.find(op => {
                                    return op.value === currentStateOfCountryToAcademicEntity
                                })?.label ?? '' : ''}</Label>
                                :
                                <Select
                                    isDisabled={this.props.readOnly ?? false}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder={t('placeholder.Select')}
                                    options={statesOfCountry}
                                    value={(currentStateOfCountryToAcademicEntity != null && statesOfCountry?.length > 0) ? statesOfCountry.find(op => {
                                        return op.value === currentStateOfCountryToAcademicEntity
                                    }) : null}
                                    onChange={this.props.onChangeStateOfCountryToAcademicEntity}
                                ></Select>
                            }
                        </Col>}
                </Row>
                <Row>
                    <Col xl={5} className="align-vertical mr-5 mb-4">
                        <Label>{t(readOnly ? 'experience.Language' : 'academic.selectLanguageForInformation')} :</Label>
                        {readOnly ?
                            <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentNewIntCodeToAcademicEntity != null && languages?.length > 0) ? languages.find(op => {
                                return op.value === currentNewIntCodeToAcademicEntity
                            })?.label ?? '' : ''}</Label>
                            :
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder={t('placeholder.Select')}
                                onChange={this.props.onChangeNewIntCodeToAcademicEntity}
                                options={languages}
                                value={(currentNewIntCodeToAcademicEntity != null && languages?.length > 0) ? languages.find(op => {
                                    return op.value === currentNewIntCodeToAcademicEntity
                                }) : null}></Select>
                        }
                    </Col>
                </Row>

                <br />
                <div className="buttons-wrapper mt-4">
                    <div className='width-px-300 space-between mt-4 align-horizontal'>
                        <div className="align-horizontal">
                            <Button color="primary" onClick={this.props.cancelStep}><i className='uil'></i>{t((this.props.readOnly ?? false) ? "modal.close" : "codeInput.cancel")}</Button>
                            {/* {!(this.props.readOnly ?? false) &&
                                <div className='ml-4'><Button color="primary" onClick={this.props.onSave} disabled={!enableSave}><i className='uil'></i>{t("personalInfo.save")}</Button></div>
                            } */}
                        </div>
                        <div className="ml-5 aligned-right">
                            <Button color="primary" className="" onClick={this.props.nextStepAddCountryToAcademicEntity} disabled={disabledNext}><i className='uil'></i>{t("sub.Next")}</Button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

export default withTranslation('translations')(StepAddCountryToAcademic);
