// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    POST_PAYMENT,
    POST_PAYMENT_FAILED,
    POST_PAYMENT_SUCCESS,
    GET_PAYMENT,
    GET_PAYMENT_FAILED,
    GET_PAYMENT_SUCCESS,
    GET_ALL_PAYMENT,
    GET_ALL_PAYMENT_FAILED,
    GET_ALL_PAYMENT_SUCCESS,
    POST_STATUS_SUBSCRIPTION,
    POST_STATUS_SUBSCRIPTION_SUCCESS,
    POST_STATUS_SUBSCRIPTION_FAILED
} from './constants';

const INIT_STATE = {
    payment: {},
    payments: [],
    security: {},
    loading: false,
    error: null,
};

const Payment = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_PAYMENT:
            return { ...state, loading: true };
        case POST_PAYMENT_SUCCESS:
            return { ...state, payment: action.payload, loading: false, error: null };
        case POST_PAYMENT_FAILED:
            return { ...state, payment: {}, error: action.payload, loading: false };
        case POST_STATUS_SUBSCRIPTION:
            return { ...state, loading: true };
        case POST_STATUS_SUBSCRIPTION_SUCCESS:
            return { ...state, security: action.payload, loading: false, error: null };
        case POST_STATUS_SUBSCRIPTION_FAILED:
            return { ...state, security: {}, error: action.payload, loading: false };

        case GET_PAYMENT:
            return { ...state, loading: true };
        case GET_PAYMENT_SUCCESS:
            return { ...state, payment: action.payload, loading: false, error: null };
        case GET_PAYMENT_FAILED:
            return { ...state, payment: {}, error: action.payload, loading: false };

        case GET_ALL_PAYMENT:
            return { ...state, payments: [], loading: true };
        case GET_ALL_PAYMENT_SUCCESS:
            return { ...state, payments: action.payload, loading: false, error: null };
        case GET_ALL_PAYMENT_FAILED:
            return { ...state, payments: [], error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Payment;
