// @flow
import {
    GET_REPOCYCLESTEPS,GET_REPOCYCLESTEPS_FAILED,GET_REPOCYCLESTEPS_SUCCESS
} from './constants';

export const getRepoCyclesteps = (callbacks) => ({
    type: GET_REPOCYCLESTEPS,
    payload: { callbacks },
});

export const getRepoCyclestepsSuccess = (data) => ({
    type: GET_REPOCYCLESTEPS_SUCCESS,
    payload: data,
});

export const getRepoCyclestepsFailed = (error) => ({
    type: GET_REPOCYCLESTEPS_FAILED,
    payload: error,
});
