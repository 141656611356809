// @flow
import {
    CHECK_COMPANY_ID,
    CHECK_COMPANY_ID_FAILED,
    CHECK_COMPANY_ID_SUCCESS,
    CHECK_NAME_COMPANY,
    CHECK_NAME_COMPANY_FAILED,
    CHECK_NAME_COMPANY_SUCCESS,
    CREATE_COMPANY,
    CREATE_COMPANY_FAILED,
    CREATE_COMPANY_SUCCESS,
    GET_COMPANY,
    GET_COMPANY_FAILED,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_PROFILES,
    GET_COMPANY_PROFILES_SUCCESS,
    GET_COMPANY_PROFILES_FAILED,
    GET_COMPANY_SPECIALTIES, GET_COMPANY_SPECIALTIES_SUCCESS, GET_COMPANY_SPECIALTIES_FAILED,
    GET_COMPANY_POSITION_TYPES, GET_COMPANY_POSITION_TYPES_SUCCESS, GET_COMPANY_POSITION_TYPES_FAILED,
    GET_FELLOWS,
    GET_FELLOWS_FAILED,
    GET_FELLOWS_SUCCESS,
    GET_CAREER_COMPANIES,
    GET_CAREER_COMPANIES_FAILED,
    GET_CAREER_COMPANIES_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_FAILED,
    UPDATE_COMPANY_SUCCESS,
    GET_CAREER_COMPANY_DETAILS,
    GET_CAREER_COMPANY_DETAILS_SUCCESS,
    GET_CAREER_COMPANY_DETAILS_FAILED,
    CREATE_CAREER,
    CREATE_CAREER_SUCCESS,
    CREATE_CAREER_FAILED,
    EDIT_CAREER,
    EDIT_CAREER_SUCCESS,
    EDIT_CAREER_FAILED,
    GET_EMAILS_COMPANY, GET_EMAILS_COMPANY_FAILED, GET_EMAILS_COMPANY_SUCCESS,
    CHECK_EMAIL_COMPANY, CHECK_EMAIL_COMPANY_FAILED, CHECK_EMAIL_COMPANY_SUCCESS,
    CHANGE_EMAIL_COMPANY, CHANGE_EMAIL_COMPANY_FAILED, CHANGE_EMAIL_COMPANY_SUCCESS,
    DELETE_EMAIL_COMPANY, DELETE_EMAIL_COMPANY_SUCCESS, DELETE_EMAIL_COMPANY_FAILED,
    CHANGE_EMAIL_ACADEMIC, CHANGE_EMAIL_ACADEMIC_SUCCESS, CHANGE_EMAIL_ACADEMIC_FAILED,
    DELETE_EMAIL_ACADEMIC, DELETE_EMAIL_ACADEMIC_SUCCESS, DELETE_EMAIL_ACADEMIC_FAILED,
    DELETE_CAREER_COMPANY, DELETE_CAREER_COMPANY_FAILED, DELETE_CAREER_COMPANY_SUCCESS,
    DELETE_COMPANY, DELETE_COMPANY_FAILED, DELETE_COMPANY_SUCCESS,
    EDIT_COMPANY, EDIT_COMPANY_FAILED, EDIT_COMPANY_SUCCESS,
    GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILED,
    GET_POSITIONS_OF_COMPANY, GET_POSITIONS_OF_COMPANY_SUCCESS, GET_POSITIONS_OF_COMPANY_FAILED,
    GET_PUBLIC_COMPANY, GET_PUBLIC_COMPANY_SUCCESS, GET_PUBLIC_COMPANY_FAILED,
    EDIT_COMPANY_CAREER, EDIT_COMPANY_CAREER_SUCCESS, EDIT_COMPANY_CAREER_FAILED,
    GET_COMPANY_GOALS, GET_COMPANY_GOALS_SUCCESS, GET_COMPANY_GOALS_FAILED,
} from './constants';

import {
    CREATE_NETWORK_COMPANY_GOAL, CREATE_NETWORK_COMPANY_GOAL_SUCCESS, CREATE_NETWORK_COMPANY_GOAL_FAILED,
    EDIT_NETWORK_COMPANY_GOAL, EDIT_NETWORK_COMPANY_GOAL_SUCCESS, EDIT_NETWORK_COMPANY_GOAL_FAILED,
    DELETE_NETWORK_COMPANY_GOAL, DELETE_NETWORK_COMPANY_GOAL_SUCCESS, DELETE_NETWORK_COMPANY_GOAL_FAILED
} from '../network/constants';
import { LOGOUT_USER } from '../auth/constants';

const INIT_STATE = {
    companies: [],
    companyProfiles: [],
    companySpecialties: null,
    companyPositionTypes: null,
    userFellows: [],
    careers: [],
    career: {},
    company: {},
    loading: false,
    error: null,
    allCompanies: [],
    positions: [],
    publicCompany: null,
    goals: []
};

const Company = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return { ...state, loading: true };
        case GET_COMPANY_SUCCESS:
            return { ...state, companies: action.payload ?? [], loading: false, error: null };
        case GET_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPANY_PROFILES:
            return { ...state, loading: true };
        case GET_COMPANY_PROFILES_SUCCESS:
            return { ...state, companyProfiles: action.payload.profiles, loading: false, error: null };
        case GET_COMPANY_PROFILES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPANY_SPECIALTIES:
            return { ...state, loading: true };
        case GET_COMPANY_SPECIALTIES_SUCCESS:
            return { ...state, companySpecialties: action.payload, loading: false, error: null };
        case GET_COMPANY_SPECIALTIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPANY_POSITION_TYPES:
            return { ...state, loading: true };
        case GET_COMPANY_POSITION_TYPES_SUCCESS:
            return { ...state, companyPositionTypes: action.payload, loading: false, error: null };
        case GET_COMPANY_POSITION_TYPES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EMAILS_COMPANY:
            return { ...state, loading: true };
        case GET_EMAILS_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case GET_EMAILS_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHANGE_EMAIL_COMPANY:
            return { ...state, loading: true };
        case CHANGE_EMAIL_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHANGE_EMAIL_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EMAIL_COMPANY:
            return { ...state, loading: true };
        case DELETE_EMAIL_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_EMAIL_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHANGE_EMAIL_ACADEMIC:
            return { ...state, loading: true };
        case CHANGE_EMAIL_ACADEMIC_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHANGE_EMAIL_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_EMAIL_ACADEMIC:
            return { ...state, loading: true };
        case DELETE_EMAIL_ACADEMIC_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_EMAIL_ACADEMIC_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHECK_EMAIL_COMPANY:
            return { ...state, loading: true };
        case CHECK_EMAIL_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHECK_EMAIL_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_CAREER_COMPANY_DETAILS:
            return { ...state, loading: true };
        case GET_CAREER_COMPANY_DETAILS_SUCCESS:
            return { ...state, career: action.payload, loading: false, error: null };
        case GET_CAREER_COMPANY_DETAILS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_FELLOWS:
            return { ...state, loading: true };
        case GET_FELLOWS_SUCCESS:
            return { ...state, userFellows: action.payload, loading: false, error: null };
        case GET_FELLOWS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_CAREER_COMPANIES:
            return { ...state, loading: true };
        case GET_CAREER_COMPANIES_SUCCESS:
            return { ...state, careers: action.payload, loading: false, error: null };
        case GET_CAREER_COMPANIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_CAREER_COMPANY:
            return { ...state, loading: true };
        case DELETE_CAREER_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_CAREER_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_COMPANY:
            return { ...state, loading: true };
        case DELETE_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_COMPANY:
            return { ...state, loading: true };
        case EDIT_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_COMPANY_CAREER:
            return { ...state, loading: true };
        case EDIT_COMPANY_CAREER_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_COMPANY_CAREER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_COMPANY:
            return { ...state, loading: true };
        case CREATE_COMPANY_SUCCESS:
            return { ...state, company: action.payload, loading: false, error: null };
        case CREATE_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_CAREER:
            return { ...state, loading: true };
        case CREATE_CAREER_SUCCESS:
            return { ...state, career: action.payload, loading: false, error: null };
        case CREATE_CAREER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_CAREER:
            return { ...state, loading: true };
        case EDIT_CAREER_SUCCESS:
            let current = state.careers;
            let editedCareer = action.payload;
            if (current && editedCareer) {
                let index = current.findIndex((element) => { return element.id == editedCareer.id })
                if (index >= 0) {
                    current[index] = editedCareer
                }
            }
            return { ...state, careers: current, loading: false, error: null };
        case EDIT_CAREER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_COMPANY:
            return { ...state, loading: true };
        case UPDATE_COMPANY_SUCCESS:
            return { ...state, company: action.payload, loading: false, error: null };
        case UPDATE_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHECK_NAME_COMPANY:
            return { ...state, loading: true };
        case CHECK_NAME_COMPANY_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHECK_NAME_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHECK_COMPANY_ID:
            return { ...state, loading: true };
        case CHECK_COMPANY_ID_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHECK_COMPANY_ID_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPANIES:
            return { ...state, loading: true };
        case GET_COMPANIES_SUCCESS:
            return { ...state, allCompanies: action.payload, loading: false, error: null };
        case GET_COMPANIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_POSITIONS_OF_COMPANY:
            return { ...state, loading: true };
        case GET_POSITIONS_OF_COMPANY_SUCCESS:
            return { ...state, positions: action.payload, loading: false, error: null };
        case GET_POSITIONS_OF_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PUBLIC_COMPANY:
            return { ...state, loading: true };
        case GET_PUBLIC_COMPANY_SUCCESS:
            return { ...state, publicCompany: action.payload, loading: false, error: null };
        case GET_PUBLIC_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPANY_GOALS:
            return { ...state, loading: true };
        case GET_COMPANY_GOALS_SUCCESS:
            return { ...state, goals: action.payload ?? [], loading: false, error: null };
        case GET_COMPANY_GOALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case CREATE_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                let currentGoals = state.goals ?? [];
                currentGoals.push(action.payload.response);
                return { ...state, goals: currentGoals, loading: false, error: null };
            }
        case CREATE_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case EDIT_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                let currentGoals = state.goals ?? [];
                let index = currentGoals.findIndex((element) => { return element.id == action.payload.response?.id });
                if (index >= 0) {
                    currentGoals[index] = action.payload.response;
                }
                return { ...state, goals: currentGoals, loading: false, error: null };
            }
        case EDIT_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case DELETE_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                let currentGoals = state.goals ?? [];
                let index = currentGoals.findIndex((element) => { return element.id == action.payload.response });
                if (index >= 0) {
                    currentGoals.splice(index, 1);
                }
                return { ...state, goals: currentGoals, loading: false, error: null };
            }
        case DELETE_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Company;
