// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    ADD_COMMENT, ADD_COMMENT_FAILED, ADD_COMMENT_SUCCESS, DELETE_COMMENT, DELETE_COMMENT_FAILED, DELETE_COMMENT_SUCCESS, GET_COMMENTS, GET_COMMENTS_FAILED, GET_COMMENTS_SUCCESS, GET_REPOSITORIES, GET_REPOSITORIES_FAILED, GET_REPOSITORIES_SUCCESS
} from './constants';

const INIT_STATE = {
    repositories: null,
    comments: [],
    loading: false,
    error: null
};

const Repositories = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPOSITORIES:
            return { ...state, loading: true };
        case GET_REPOSITORIES_SUCCESS:
            var repo = action.payload;
            if (repo && repo.children) {
                repo.children.forEach(grp => {
                    if (grp.children != null) {
                        grp.children.sort(function (a, b) { return a.id - b.id });
                    }
                });
                repo.children.sort(function (a, b) { return a.id - b.id });

            }
            return { ...state, repositories: repo, loading: false, error: null };
        case GET_REPOSITORIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ADD_COMMENT:
            return { ...state, loading: true };
        case ADD_COMMENT_SUCCESS:
            return { ...state, loading: false, error: null };
        case ADD_COMMENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMMENTS:
            return { ...state, loading: true };
        case GET_COMMENTS_SUCCESS:
            return { ...state, comments: action.payload, loading: false, error: null };
        case GET_COMMENTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_COMMENT:
            return { ...state, loading: true };
        case DELETE_COMMENT_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_COMMENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Repositories;
