import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { countryCodeToString, dateStringFromTimeInterval, getCurrentLanguage, getLocalizedString, stateCodeToString } from '../../../../../../helpers/utils';
import { format } from '../../../../../../helpers/stringFormat';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from '../../../../../../helpers/DatePickerFormat';
import { isDarkMode } from '../../../../../../helpers/authUtils';
import moment from 'moment';
import {
    SCREEN_GRADUATE_DEGREE_SKIP, SCREEN_GRADUATE_DEGREE_NEXT, SCREEN_GRADUATE_DEGREE_VALIDATE, SCREEN_GRADUATE_DEGREE_SAVE_GD
} from './constants'

class StepAddAcademicEntityGraduateDegree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            className: 'custom-modal-style',
        };
    }

    componentDidMount = () => {

    };

    render() {
        const { t, disabledNext, graduateDegreeDoc, currentAcademicGraduateDegree,
            facultySelected, currentAcademicGraduationSpecialty, currentCountryToAcademicEntity,
            currentAcademicDiplomaInt, currentAcademicGraduationSpecialtyInt,
            newIntCode, currentAcademicEntity, currentAcademicEntityName, degrees,
            countries, currentStateOfCountryToAcademicEntity, underPreparation, validateVisible } = this.props;


        var arrGraduateDegree = [];
        if (facultySelected?.graduateDegrees != null) {
            for (let index = 0; index < facultySelected.graduateDegrees.length; index++) {
                const element = facultySelected.graduateDegrees[index];
                for (let indexJ = 0; indexJ < graduateDegreeDoc.length; indexJ++) {
                    if (graduateDegreeDoc[indexJ].order === element) {
                        arrGraduateDegree.push(graduateDegreeDoc[indexJ]);
                    }
                }
            }
        }

        const graduateDoc = arrGraduateDegree?.map((element, i) => {
            return {
                value: element.order?.toString(),
                label: getLocalizedString(element.value),
                states: element.specialties ? element.specialties : null,
                graduateSelected: element
            };
        });

        const graduateSpecialtiesDoc = facultySelected?.specialties?.map((element, i) => {
            return {
                value: element.order?.toString(),
                label: getLocalizedString(element.value),
                special: element.specialties ? element.specialties : null,
            };
        });

        const isDark = isDarkMode();

        const dateOfDegree = this.props.dateOfDegree ? moment(this.props.dateOfDegree).toDate() : null;

        const didInput = currentAcademicGraduateDegree != null || currentAcademicGraduationSpecialty != null || (underPreparation ? true : (dateOfDegree != null));

        const readOnly = this.props.readOnly ?? false;

        return (
            <div className='align-vertical mt-4' xl={12}>
                <Label className="font-bold mb-3"> {this.props.title ?? t('academic.titleStep1')} :</Label>

                <Label className="font-bold">{format(t('academic.stepGraduateSub'), currentAcademicEntityName ?? '', currentCountryToAcademicEntity ? ("," + countryCodeToString(currentCountryToAcademicEntity, countries)) : '', currentStateOfCountryToAcademicEntity ? ("," + stateCodeToString(currentStateOfCountryToAcademicEntity, countries)) : '')}</Label>
                <Label className="font-bold">{format(t('academic.degreeNumber'), degrees ? (' ' + (degrees.length + 1) + ' :') : ' 1 :')}</Label>

                <br />
                <Row >
                    {/* {currentAcademicEntity !== "Others" ? */}
                    <Col xl={5} className='align-vertical mr-5 mb-4'>
                        <Label>{t(readOnly ? 'academic.graduateDegree' : 'academic.selectGraduateDegree')}</Label>
                        {readOnly ?
                            <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicGraduateDegree != null && graduateDoc?.length > 0) ? graduateDoc.find(op => {
                                return op.value == currentAcademicGraduateDegree
                            })?.label ?? '' : ''}</Label>
                            :
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder={t('placeholder.Select')}
                                value={(currentAcademicGraduateDegree != null && graduateDoc?.length > 0) ? graduateDoc.find(op => {
                                    return op.value == currentAcademicGraduateDegree
                                }) : null}
                                onChange={this.props.onChangeAcademicGraduateDegree}
                                options={graduateDoc}></Select>
                        }
                    </Col>
                    {(!readOnly || (readOnly && currentAcademicDiplomaInt?.length > 0)) &&
                        <Col xl={5} className='align-vertical input-container mr-5 mb-4'>
                            <Label>{format(t('academic.typeNameOfLocalGraduate'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                            {readOnly ?
                                <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{currentAcademicDiplomaInt ?? ''}</Label>
                                :
                                <Input
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    autoComplete="off"
                                    // placeholder={t('placeholder.InputCity')}
                                    value={currentAcademicDiplomaInt ?? ''}
                                    onChange={this.props.onChangeAcademicDiplomaInt}></Input>
                            }
                        </Col>
                    }
                </Row>
                <Row>
                    {/* {currentAcademicEntity !== "Others" ? */}
                    <Col xl={5} className='align-vertical mr-5 mb-4'>
                        <Label>{t(readOnly ? 'academic.graduationSpecialty' : 'academic.selectGraduationSpecialty')}</Label>
                        {readOnly ?
                            <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicGraduationSpecialty != null && graduateSpecialtiesDoc?.length > 0) ? graduateSpecialtiesDoc.find(op => {
                                return op.value == currentAcademicGraduationSpecialty
                            })?.label ?? '' : ''}</Label>
                            :
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder={t('placeholder.Select')}
                                value={(currentAcademicGraduationSpecialty != null && graduateSpecialtiesDoc?.length > 0) ? graduateSpecialtiesDoc.find(op => {
                                    return op.value == currentAcademicGraduationSpecialty
                                }) : null
                                }
                                onChange={this.props.onChangeAcademicGraduateSpecialty}
                                options={graduateSpecialtiesDoc}></Select>
                        }
                    </Col>
                    {(!readOnly || (readOnly && currentAcademicGraduationSpecialtyInt?.length > 0)) &&
                        <Col xl={5} className='align-vertical input-container mr-5 mb-4'>
                            <Label>{format(t('academic.typeNameOfLocalGraduationSpecialty'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                            {readOnly ?
                                <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{currentAcademicGraduationSpecialtyInt ?? ''}</Label>
                                :
                                <Input
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    autoComplete="off"
                                    // placeholder={t('placeholder.InputCity')}
                                    // onBlur={this.props.validationCityName}
                                    value={currentAcademicGraduationSpecialtyInt ?? ''}
                                    onChange={this.props.onChangeAcademicGraduateSpecialtyInt}></Input>
                            }
                        </Col>
                    }
                </Row>
                {!(underPreparation ?? true) &&
                    <Row>
                        <Col xl={3} className='align-vertical mr-5 mb-2'>
                            <Label>{t('academic.dateOfDegree')}</Label>
                            {readOnly ?
                                <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{this.props.dateOfDegree ? dateStringFromTimeInterval(this.props.dateOfDegree, 'MM/YYYY') : ''}</Label>
                                :
                                <DatePicker
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    placeholderText="(MM/YYYY)"
                                    selected={dateOfDegree}
                                    maxDate={moment().toDate()}
                                    className="date-picker p-2"
                                    // minDate={dateNow}
                                    onChange={this.props.dateOfDegreeChange}
                                    locale={getCurrentLanguage()}
                                />
                            }
                            {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                        </Col>
                    </Row>
                }

                {(!readOnly || (readOnly && underPreparation == true)) &&
                    <Row>
                        <Col xl={3} className='align-vertical input-container ml-4'>
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                autoComplete="off"
                                // defaultChecked={underPreparation ?? false}
                                checked={underPreparation ?? false}
                                onChange={() => {
                                    this.props.onchangePreparationStatus()
                                }}
                            />
                            <Label className='ml-1'>{t('academic.underPreparation')}</Label>
                        </Col>
                    </Row>
                }
                <br />
                <div className="buttons-wrapper mt-4 align-horizontal">
                    <Row className='width-px-500 space-between mt-4 align-horizontal'>
                        <div className="align-horizontal">
                            <Button color="primary" onClick={this.props.cancelStep}><i className='uil'></i>{t((this.props.readOnly ?? false) ? "modal.close" : "codeInput.cancel")}</Button>
                            {!(this.props.readOnly ?? false) &&
                                <Button color={didInput ? "danger" : "primary"} className="ml-4" onClick={() => didInput ? this.props.eraseGraduateDegree() : this.props.nextDegree(SCREEN_GRADUATE_DEGREE_SKIP)} ><i className='uil'></i>{didInput ? t("academic.erase") : t("academic.skip")}</Button>
                            }
                            {!(this.props.readOnly ?? false) &&
                                <div className='ml-4'><Button color="primary" onClick={() => this.props.nextDegree(didInput ? SCREEN_GRADUATE_DEGREE_SAVE_GD : SCREEN_GRADUATE_DEGREE_SKIP)}><i className='uil'></i>{t("personalInfo.save")}</Button></div>
                            }
                        </div>

                        <div className="ml-5">
                            <Button color="primary" className="mr-4" onClick={this.props.backStep}><i className='uil'></i>{t("terms.back")}</Button>
                        </div>
                        {!(this.props.hideNextButton ?? false) &&
                            <Button color="primary" className="mr-4" onClick={() => this.props.nextDegree(SCREEN_GRADUATE_DEGREE_NEXT)} disabled={disabledNext}><i className='uil'></i>{t("academic.nextDegree")}</Button>
                        }
                        {!(this.props.readOnly ?? false) && (validateVisible ?? true) &&
                            <Button color="primary" className="" onClick={() => this.props.nextDegree(SCREEN_GRADUATE_DEGREE_VALIDATE)} disabled={disabledNext}><i className='uil'></i>{t("updateCV.validate")}</Button>
                        }
                        {/* </Row> */}
                    </Row>
                </div>
                <br />
            </div>
        );
    }
}

export default withTranslation('translations')(StepAddAcademicEntityGraduateDegree);
