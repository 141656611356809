// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {getCurrentLanguage} from '../../helpers/utils';
import {getLoggedInUser} from '../../helpers/authUtils';
import {BASE_URL} from './../constants';
import {getTrainingSuccess, getTrainingFailed, editTrainingDocFailed, editTrainingDocSuccess,
createTrainingFailed, createTrainingSuccess, getTrainingByTypeFailed, getTrainingByTypeSuccess,
getTrainingByIdFailed, getTrainingByIdSuccess} from './actions';
import {
    GET_TRAINING, EDIT_TRAINING_DOC, CREATE_TRAINING, DELETE_TRAINING_DOC, GET_TRAINING_BY_TYPE_ID, GET_TRAINING_BY_ID
} from './constants';

function* getTraining({ payload: { userId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/training/all/" + userId, options);
        yield put(getTrainingSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getTrainingFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getTrainingByType({ payload: { idType, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/training/" + idType, options);
        yield put(getTrainingByTypeSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getTrainingByTypeFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getTrainingById({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/training/find-by-id/" + id, options);
        yield put(getTrainingByIdSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getTrainingByIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editTrainingDoc({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        var url = "api/training/" + data.id + "/" + data.trainingId + "/edit";
        const response = yield call(fetchJSON, BASE_URL + url, options);
        yield put(editTrainingDocSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editTrainingDocFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteTrainingDoc({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        var url = "api/training/" + data.id + "/" + data.trainingId;
        const response = yield call(fetchJSON, BASE_URL + url, options);
        yield put(editTrainingDocSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editTrainingDocFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createTrainingEntity({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/training/create-training", options);
        yield put(createTrainingSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createTrainingFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetTraining() {
    yield takeEvery(GET_TRAINING, getTraining);
}

export function* watchGetTrainingById() {
    yield takeEvery(GET_TRAINING_BY_ID, getTrainingById);
}

export function* watchGetTrainingByType() {
    yield takeEvery(GET_TRAINING_BY_TYPE_ID, getTrainingByType);
}

export function* watchEditTrainingDoc() {
    yield takeEvery(EDIT_TRAINING_DOC, editTrainingDoc);
}

export function* watchCreateTrainingEntity() {
    yield takeEvery(CREATE_TRAINING, createTrainingEntity);
}

export function* watchDeleteTrainingDoc() {
    yield takeEvery(DELETE_TRAINING_DOC, deleteTrainingDoc);
}

function* repoTrainingSaga() {
    yield all([fork(watchGetTraining), fork(watchEditTrainingDoc), fork(watchCreateTrainingEntity)
        , fork(watchDeleteTrainingDoc), fork(watchGetTrainingByType), fork(watchGetTrainingById)]);
}

export default repoTrainingSaga;
