// @flow
import jwtDecode from 'jwt-decode';
import {applyMiddleware,combineReducers,compose,createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import {isUserAuthenticated} from '../helpers/authUtils';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

/*
export function configureStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    sagaMiddleware.run(sagas);
    return store;
}
*/


const rootReducer = (state, action) => {
    if (action.type === 'RESET') return reducers(undefined, action);
    return reducers(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const configureStore = (initialState) => {
    const store = createStore(rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    store.subscribe(() => {
        if (!isUserAuthenticated()) {
            if (initialState){
                store.dispatch({type: 'RESET'});
            }
        } 
      });

    sagaMiddleware.run(sagas);
    return store;
}