import { AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Modal, Row } from 'reactstrap';
import Loader from '../../components/Loader';
import { mobileElements } from '../../helpers/utils';

class PhoneInputModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            size: 'lg',
            phone: '',
            country: '',
            className: "custom-modal-style"
        };
    }

    componentDidMount() {
        let mobile = this.props.defaultNumber;
        if (mobile?.length > 0) {
            let elements = mobileElements(this.props.defaultNumber);
            if (elements != null) {
                if (mobile.substring(0, 1) == '+') {
                    mobile = mobile.slice(1);
                }
                mobile = mobile.slice(elements.dialCode.length);
                this.setState({ country: elements?.dialCode, phone: mobile });
            }
        }
    }

    onVerifyButton = (e) => {
        e.preventDefault();
        this.props.verify(this.state.country, this.state.phone)
    }

    render() {
        const { t, defaultNumber, error } = this.props;

        let elements = mobileElements(defaultNumber);

        let errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
        const sendable = true;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.toggle();
                    }}
                    className={this.state.className}
                    size={this.state.size}
                    style={{ maxWidth: '400px', width: '100%' }}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className='h4'>{t('signUp.enterPhone')}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {this.state.errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <br />
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{this.state.errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.toggle();
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ errorMessage: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col xl={12}>
                                                        <br />
                                                        <Row className='box-center'>
                                                            <Col xl={12} className='box-center width-percent-100'>
                                                                <PhoneInput className='width-percent-100'
                                                                    inputStyle={{ width: '100%' }}
                                                                    containerClass={"phone-input-custom"}
                                                                    country={elements?.countryCode ?? this.props.country.toLowerCase()}
                                                                    value={elements?.mobile}
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                        this.setState({ phone: value.slice(data.dialCode.length), country: data.dialCode })
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <br />
                                                        <br />

                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.toggle();
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button className='ml-3' disabled={!sendable} color="primary" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.onVerifyButton(e);
                                                                }}><i className='uil'></i>{t('phoneInput.verify')}
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        {/* <br />

                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-13'>{t('phoneInput.tapVerifySMSSentApply')}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left box-center'>
                                                                <Button color="link" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                }}><i className='uil'></i>{t('phoneInput.termOfService')}
                                                                </Button>
                                                                <Button color="link" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                }}><i className='uil'></i>{t('phoneInput.privacyPolicy')}
                                                                </Button>
                                                            </Col>
                                                        </Row> */}

                                                    </Col>
                                                </Row>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    }
}

export default withTranslation('translations')(PhoneInputModal);
