// @flow
import { data } from 'jquery';
import { LOGOUT_USER } from '../auth/constants';
import {
    SELECT_SEARCH_CATEGORY, SELECT_SEARCH_CATEGORY_SUCCESS, SELECT_SEARCH_CATEGORY_FAILED,
    SEARCH, SEARCH_SUCCESS, SEARCH_FAILED, SEARCH_CATEGORY_ALL,
    SELECT_PERSONAL_NETWORK, SELECT_PERSONAL_NETWORK_SUCCESS, SELECT_PERSONAL_NETWORK_FAILED,

} from './constants';

const INIT_STATE = {
    keyword: '',
    categories: [],
    searchResults: null,
    isPersonalNetwork: false,
    loading: false,
    error: null,
};

const SearchReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SELECT_SEARCH_CATEGORY:
            return { ...state, loading: true };
        case SELECT_SEARCH_CATEGORY_SUCCESS:
            let currentCategories = state.categories;
            if (action.payload.category == SEARCH_CATEGORY_ALL) {
                currentCategories = [];
            }
            else {
                let index = currentCategories.findIndex((element) => { return element == action.payload.category });
                if (index >= 0) {
                    currentCategories.splice(index, 1);
                }
                else {
                    currentCategories.push(action.payload.category);
                }
            }
            return { ...state, categories: currentCategories, loading: false, error: null };
        case SELECT_SEARCH_CATEGORY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SEARCH:
            return { ...state, keyword: action.payload.keyword, loading: true };
        case SEARCH_SUCCESS:
            if (action.payload.keyword == state.keyword) {
                return { ...state, searchResults: action.payload.data, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case SEARCH_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SELECT_PERSONAL_NETWORK:
            return { ...state, loading: true };
        case SELECT_PERSONAL_NETWORK_SUCCESS:
            return { ...state, isPersonalNetwork: action.payload.isOn, loading: false, error: null };
        case SELECT_PERSONAL_NETWORK_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default SearchReducer;
