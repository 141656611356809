// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Share2, Trash,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import { customAlertPopup, getLocalizedString } from '../../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    changeTheme,
    getGoalsOfAcademic,
    getAllNetworkCompanies,
    deleteNetworkCompanyGoal
} from '../../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth } from '../../../../../helpers/authUtils';
import * as layoutConstants from '../../../../../constants/layout';
import SVG from 'react-inlinesvg';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import { format } from '../../../../../helpers/stringFormat';
import AddGoalModal from '../AddGoalModal';

class NetworkAcademicsGoals extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            goals: null,
            activeTabInfo: 1,
            companyNodeId: -1,
            editingGoal: null,
            showAddGoalModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());

        // if (this.props.allNetworkCompanies == null) {
        //     this.props.getAllNetworkCompanies({});
        // }
        this.getGoals();
    }

    componentDidUpdate = prevProps => {
        if (prevProps.academyId !== this.props.academyId || prevProps.allNetworkAcademics !== this.props.allNetworkAcademics) {
            this.getGoals();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        this.props.history.push({ pathname: '/network-person' });
    }

    getGoals = () => {
        if (this.props.academyId != null && this.props.allNetworkAcademics != null) {
            let nodeId = -1;
            let academics = this.props.allNetworkAcademics ?? [];
            for (let i = 0; i < academics.length; i++) {
                let c = academics[i];
                if (c.id == this.props.academyId) {
                    nodeId = c.nodeId;
                    break;
                }
            }

            this.setState({ companyNodeId: nodeId }, () => {
                this.props.getGoalsOfAcademic(nodeId, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                    },
                });
            })
        }
    };

    closeAddGoalModal = () => {
        this.setState({
            showAddGoalModal: false,
            editingGoal: null
        })
    }

    generalModule = () => {
        return (
            <Row>
                <Col>
                    <Label className='ml-4'>{'No information'}</Label>
                </Col>
            </Row>
        )
    }

    goalSummary = (goal) => {
        let summary = goal.name ?? '';
        let issues = "";
        if (goal.issues != null) {
            goal.issues.forEach(element => {
                if (element.name?.length > 0) {
                    issues += (issues.length > 0 ? ', ' : '') + element.name;
                }
            });
        }

        if (issues.length > 0) {
            summary += (summary.length > 0 ? ' | ' : '') + issues;
        }

        return summary;
    }

    deleteGoal = (goal) => {
        if (goal.id) {
            this.props.deleteNetworkCompanyGoal(goal.id, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                },
            });
        }
    }

    goalModule = (goals) => {
        if (goals == null || goals.length == 0) {
            return this.generalModule();
        }

        let { t } = this.props;
        return (
            <Table className="mb-0" striped>
                <tbody>
                    {goals && goals.map((goal, idx) => {
                        let summary = this.goalSummary(goal);
                        return (
                            <tr key={summary}>
                                <td ><div className='align-horizontal'>
                                    <Label className='mt-0 mb-0'>{summary}</Label>
                                    <div className="ml-2 align-horizontal box-center align-items-flex-start">
                                        <span className="ml-2" onClick={() => {
                                            this.setState({
                                                editingGoal: goal,
                                                showAddGoalModal: true
                                            });
                                        }}>
                                            <Edit className="icon-dual ml-2 cursor-pointer icon-action" id={"edit-goal" + idx} />
                                            <UncontrolledTooltip placement="bottom" target={"edit-goal" + idx}>
                                                {t('me.hover.editGoal')}
                                            </UncontrolledTooltip>
                                        </span>

                                        <span onClick={() => {
                                            customAlertPopup({
                                                title: format(t('alert.deleteTitle'), t('experience.Goal')),
                                                message: format(t('alert.deleteMessage'), t('experience.Goal')),
                                                cancelTitle: t('No'),
                                                doneTitle: t('Yes'),
                                                handleCancel: () => { },
                                                handleDone: () => {
                                                    this.deleteGoal(goal);
                                                }
                                            });
                                        }}>
                                            <Trash className="icon-dual ml-3  cursor-pointer icon-action" id={"delete-goal" + idx} />
                                            <UncontrolledTooltip placement="bottom" target={"delete-goal" + idx}>
                                                {t('me.hover.deleteGoal')}
                                            </UncontrolledTooltip>
                                        </span>
                                    </div>
                                </div></td>

                            </tr>
                        )
                    }, this)}
                </tbody>
            </Table>
        )
    }

    render() {
        let module = this.props.academicGoals ? this.goalModule : this.generalModule;
        return (
            <React.Fragment>
                {this.state.showAddGoalModal &&
                    <AddGoalModal
                        id="add-contact"
                        companyNodeId={this.state.companyNodeId}
                        editingGoal={this.state.editingGoal ? JSON.parse(JSON.stringify(this.state.editingGoal)) : null}
                        modal={this.state.showAddGoalModal}
                        close={this.closeAddGoalModal}
                    />
                }


                {module(this.props.academicGoals)}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, error } = state.Company;
    const { allNetworkAcademics } = state.Network;
    const { academicGoals } = state.Academics;
    
    return { allNetworkAcademics, academicGoals, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getGoalsOfAcademic,
    getAllNetworkCompanies,
    deleteNetworkCompanyGoal
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademicsGoals));
