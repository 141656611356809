// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { searchEmployeeFailed } from '../actions';
import { BASE_URL } from './../constants';
import {
    searchVerifierSuccess, searchVerifierFailed,
    getExternalValidationSuccess, getExternalValidationFailed,
    updateExternalValidationSuccess, updateExternalValidationFailed,
    updateValidationSuccess, updateValidationFailed,
    createValidationSuccess, createValidationFailed,
    editValidationSuccess, editValidationFailed,
    deleteValidationSuccess, deleteValidationFailed,
    getValidationsSuccess, getValidationsFailed,
    getValidationsSupersetFailed, getValidationsSupersetSuccess,
    getValidationGraphDataSuccess, getValidationGraphDataFailed
} from './actions';
import {
    SEARCH_VERIFIER,
    GET_EXTERNAL_VALIDATION,
    UPDATE_EXTERNAL_VALIDATION,
    UPDATE_VALIDATION,
    CREATE_VALIDATION,
    EDIT_VALIDATION,
    DELETE_VALIDATION,
    GET_VALIDATIONS,
    GET_VALIDATIONS_SUPERSET,
    GET_VALIDATION_GRAPH_DATA
} from './constants';

function* searchVerifierInternal({ payload: { keyword, page, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = 'api/validations/search/' + keyword + '/' + page;

        const response = yield call(fetchJSON, BASE_URL + path, options);
        yield put(searchVerifierSuccess(keyword, response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, { keyword: keyword, results: response });
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(searchVerifierFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getExternalValidationInternal({ payload: { code, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = 'api/validations/external-validation/' + code;

        const response = yield call(fetchJSON, BASE_URL + path, options);
        yield put(getExternalValidationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getExternalValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* updateExternalValidationInternal({ payload: { code, status, comment, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    let params = { "result": status };
    if (comment) {
        params.comment = comment
    }

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = 'api/validations/external-validation/' + code;

        const response = yield call(fetchJSON, BASE_URL + path, options);
        yield put(updateExternalValidationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(updateExternalValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* updateValidationInternal({ payload: { id, status, comment, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    let params = { "result": status }
    if (comment) {
        params.comment = comment
    }

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = 'api/validations/validation/' + id;

        const response = yield call(fetchJSON, BASE_URL + path, options);
        yield put(updateValidationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(updateValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createValidationInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/validations/create', options);

        yield put(createValidationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* editValidationInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/validations/edit/' + id, options);

        yield put(editValidationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* deleteValidationInternal({ payload: { id, comment, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: { 'comment': comment }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/validations/delete/' + id, options);

        yield put(deleteValidationSuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteValidationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getValidationsInternal({ payload: { type, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/validations/' + type, options);

        yield put(getValidationsSuccess(type, response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getValidationsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getValidationsSupersetInternal({ payload: { type, mode, filter, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/superset/charts/' + type + '?mode=' + mode + '&filter=' + filter, options);

        console.log('Superset Chart', response);
        yield put(getValidationsSupersetSuccess(type, response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getValidationsSupersetFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getValidationGraphDataInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + 'api/validations/graph-data', options);

        yield put(getValidationGraphDataSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getValidationGraphDataFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


export function* watchSearchVerifier() {
    yield takeEvery(SEARCH_VERIFIER, searchVerifierInternal);
}


export function* watchGetExternalValidation() {
    yield takeEvery(GET_EXTERNAL_VALIDATION, getExternalValidationInternal);
}

export function* watchUpdateExternalValidation() {
    yield takeEvery(UPDATE_EXTERNAL_VALIDATION, updateExternalValidationInternal);
}

export function* watchUpdateValidation() {
    yield takeEvery(UPDATE_VALIDATION, updateValidationInternal);
}

export function* watchCreateValidation() {
    yield takeEvery(CREATE_VALIDATION, createValidationInternal);
}

export function* watchEditValidation() {
    yield takeEvery(EDIT_VALIDATION, editValidationInternal);
}

export function* watchDeleteValidation() {
    yield takeEvery(DELETE_VALIDATION, deleteValidationInternal);
}

export function* watchGetValidations() {
    yield takeEvery(GET_VALIDATIONS, getValidationsInternal);
}

export function* watchGetValidationsSuperset() {
    yield takeEvery(GET_VALIDATIONS_SUPERSET, getValidationsSupersetInternal);
}

export function* watchGetValidationGraphData() {
    yield takeEvery(GET_VALIDATION_GRAPH_DATA, getValidationGraphDataInternal);
}

function* repoValidationSaga() {
    yield all([fork(watchSearchVerifier),
    fork(watchGetExternalValidation),
    fork(watchUpdateExternalValidation),
    fork(watchUpdateValidation),
    fork(watchCreateValidation),
    fork(watchEditValidation),
    fork(watchDeleteValidation),
    fork(watchGetValidations),
    fork(watchGetValidationsSuperset),
    fork(watchGetValidationGraphData)
    ]);
}

export default repoValidationSaga;
