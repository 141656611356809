/* AUTH */
export const GET_COMPETENCY = 'GET_COMPETENCY';
export const GET_COMPETENCY_SUCCESS = 'GET_COMPETENCY_SUCCESS';
export const GET_COMPETENCY_FAILED = 'GET_COMPETENCY_FAILED';

export const GET_COMPETENCY_BY_ID = 'GET_COMPETENCY_BY_ID'
export const GET_COMPETENCY_BY_ID_SUCCESS = 'GET_COMPETENCY_BY_ID_SUCCESS'
export const GET_COMPETENCY_BY_ID_FAILED = 'GET_COMPETENCY_BY_ID_FAILED'

export const GET_COMPETENCY_TYPE = 'GET_COMPETENCY_TYPE';
export const GET_COMPETENCY_TYPE_SUCCESS = 'GET_COMPETENCY_TYPE_SUCCESS';
export const GET_COMPETENCY_TYPE_FAILED = 'GET_COMPETENCY_TYPE_FAILED';

export const GET_COMPETENCY_INFO = 'GET_COMPETENCY_INFO';
export const GET_COMPETENCY_INFO_SUCCESS = 'GET_COMPETENCY_INFO_SUCCESS';
export const GET_COMPETENCY_INFO_FAILED = 'GET_COMPETENCY_INFO_FAILED';

export const CREATE_COMPETENCY = 'CREATE_COMPETENCY';
export const CREATE_COMPETENCY_SUCCESS = 'CREATE_COMPETENCY_SUCCESS';
export const CREATE_COMPETENCY_FAILED = 'CREATE_COMPETENCY_FAILED';

export const CREATE_COMPETENCY_LEVEL = 'CREATE_COMPETENCY_LEVEL';
export const CREATE_COMPETENCY_LEVEL_SUCCESS = 'CREATE_COMPETENCY_LEVEL_SUCCESS';
export const CREATE_COMPETENCY_LEVEL_FAILED = 'CREATE_COMPETENCY_LEVEL_FAILED';

export const EDIT_COMPETENCY = 'EDIT_COMPETENCY';
export const EDIT_COMPETENCY_SUCCESS = 'EDIT_COMPETENCY_SUCCESS';
export const EDIT_COMPETENCY_FAILED = 'EDIT_COMPETENCY_FAILED';

export const EDIT_COMPETENCY_LEVEL = 'EDIT_COMPETENCY_LEVEL';
export const EDIT_COMPETENCY_LEVEL_SUCCESS = 'EDIT_COMPETENCY_LEVEL_SUCCESS';
export const EDIT_COMPETENCY_LEVEL_FAILED = 'EDIT_COMPETENCY_LEVEL_FAILED';

export const GET_COMPETENCY_HIERARCHY = 'GET_COMPETENCY_HIERARCHY';
export const GET_COMPETENCY_HIERARCHY_SUCCESS = 'GET_COMPETENCY_HIERARCHY_SUCCESS';
export const GET_COMPETENCY_HIERARCHY_FAILED = 'GET_COMPETENCY_HIERARCHY_FAILED';

export const DELETE_COMPETENCY_LEVEL = 'DELETE_COMPETENCY_LEVEL';
export const DELETE_COMPETENCY_LEVEL_SUCCESS = 'DELETE_COMPETENCY_LEVEL_SUCCESS';
export const DELETE_COMPETENCY_LEVEL_FAILED = 'DELETE_COMPETENCY_LEVEL_FAILED';

export const GET_COMPETENCY_CONTEXT = 'GET_COMPETENCY_CONTEXT';
export const GET_COMPETENCY_CONTEXT_SUCCESS = 'GET_COMPETENCY_CONTEXT_SUCCESS';
export const GET_COMPETENCY_CONTEXT_FAILED = 'GET_COMPETENCY_CONTEXT_FAILED';
