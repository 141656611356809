// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Mail,
    Trash,
    User,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Label,
    Row,
    UncontrolledTooltip,
} from 'reactstrap';
import { countryCodeToString, dateStringFromTimeInterval, durationBetweenDates, facultyWithId, formatDateString, getLocalizedString, isAdmin, locationString } from '../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    changeTheme,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAcademicDoc,
    getNetworkEvents,
    deleteNetworkEvent,
    getAllContentsCompany,
    getAllNetworkCompanies,
    getCompetencyInfo,
    getEventGroup,
} from '../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, getLoggedInUser } from '../../../../helpers/authUtils';
import UserInfo from '../../Settings/Profile/UserInfo';
import * as layoutConstants from '../../../../constants/layout';
import SVG from 'react-inlinesvg';
import { format } from '../../../../helpers/stringFormat';
import Select from 'react-select';

import addEventIcon from '../../../../assets/images/menu/eventingplus.svg';
import AddEventModal from './AddEventModal';
import { ConnexionsEventingIcon, EventsIcon } from '../../../../components/Icons';
import { MENU_TYPE_HOME } from '../../../../redux/appMenu/constants';
import RemoveNetworkModal from './RemoveNetworkModal';
import { TRAINING_TYPE_BUSINESS_DOMAIN, TRAINING_TYPE_DEPARTMENTAL, TRAINING_TYPE_METHODOLOGY, TRAINING_TYPE_TECHNOLOGY } from '../../Settings/Background/Trainings/AddNewTrainingModal';
import ReactPaginate from 'react-paginate';

export const NETWORK_EVENT_TYPE_COUNTRY = 0;
export const NETWORK_EVENT_TYPE_COMPANY = 1;

class NetworkEvents extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            displayWidth: 800,
            selectedNode: null,
            showAddCompanyDialog: 0,
            showInviteProfileDialog: 0,
            idType: null,
            editingCompetency: null,
            editingEvent: null,
            inviteContact: null,
            selectedCountry: 'All Countries',
            selectedState: 'All States',
            selectedCompany: -1,
            showDetails: false,
            groupByCountry: true,
            showAlertPopup: false,
            currentPage: 0,
            keyword: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        // if (this.props.subscriptionProfile == null) {
        //     this.getProfileAndCompanies();
        // }

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
        this.props.getAcademicDoc({});

        this.getEvents();

        if (this.props.companies.length == 0) {
            this.props.getAllContentsCompany({});
        }

        if (this.props.competencyInfo == null) {
            this.props.getCompetencyInfo({})
        }

        if (this.props.eventGroup == null) {
            this.props.getEventGroup({})
        }

        // if (this.props.allNetworkCompanies == null) {
        //     this.props.getAllNetworkCompanies({});
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        // this.props.history.push({ pathname: '/network' });
    }

    getProfileAndCompanies = () => {
        this.props.getSubscriptionProfile({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                // this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
            },
        });
    }

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }

    onSelectedNode = (node) => {
        // alert('onSelectedNode: ' + node.labels[0]);
        this.setState({ selectedNode: node });
    };

    showAddEventModal = (editingEvent) => {
        this.setState({
            editingEvent: editingEvent,
            showAddCompanyDialog: 1
        });
    };

    closeAddEventModal = (response) => {
        this.setState({ showAddCompanyDialog: 0, editingEvent: null });
    };

    networkEventsByCountry = () => {
        let network = [];
        (this.props.networkEvents?.data ?? []).forEach(event => {
            if (event.locations?.length > 0) {
                event.locations.forEach(location => {
                    let countryCode = location.country ?? '';
                    let index = network.findIndex((group) => { return group.country == countryCode });
                    if (index >= 0) {
                        let evs = network[index].events ?? [];
                        if (!evs.some((a) => { return a.id == event.id })) {
                            evs.push(JSON.parse(JSON.stringify(event)));
                            network[index].events = evs;
                        }
                    }
                    else {
                        network.push({ country: countryCode, events: [JSON.parse(JSON.stringify(event))] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.country == '' });
                if (index >= 0) {
                    let evs = network[index].events ?? [];
                    if (!evs.some((a) => { return a.id == event.id })) {
                        evs.push(JSON.parse(JSON.stringify(event)));
                        network[index].events = evs;
                    }
                }
                else {
                    network.push({ country: '', events: [JSON.parse(JSON.stringify(event))] });
                }
            }
        });

        network.forEach(element => {
            (element.events ?? []).forEach(e => {
                let locations = (e.locations ?? []).filter((l) => { return l.country == element.country });
                e.locations = locations;
            });
        });

        network.forEach(element => {
            if (element.events?.length > 1) {
                element.events.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    networkEventsByCompany = () => {
        let network = [];
        (this.props.networkEvents?.data ?? []).forEach(event => {
            let companyNodeId = event.companyNodeId ?? '';
            let index = network.findIndex((group) => { return group.company == companyNodeId });
            if (index >= 0) {
                let evs = network[index].events ?? [];
                if (!evs.some((a) => { return a.id == event.id })) {
                    evs.push(event);
                    network[index].events = evs;
                }
            }
            else {
                network.push({ company: companyNodeId, events: [event] });
            }
        });

        network.forEach(element => {
            if (element.events?.length > 1) {
                element.events.sort((a, b) => {
                    let nameA = a.name ?? '';
                    let nameB = b.name ?? '';

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    networkCities = (event) => {
        let cities = (event.cities ?? []).map(city => {
            let faculties = event.faculties?.filter((f) => { return (f.cities ?? []).some((c) => { return c == city.id }) }) ?? [];
            return { city: city, faculties: faculties };
        });

        let network = event.network;
        if ((Object.keys(network) ?? []).length > 0) {
            cities.forEach(city => {
                city.faculties.forEach(faculty => {
                    let networkByFaculty = {};
                    (Object.keys(network) ?? []).forEach(key => {
                        let users = (network[key] ?? []).filter((user) => { return (user.eventPlaces ?? []).some((p) => { return p.facultyId == faculty.facultyId }) });
                        if (users.length > 0) {
                            networkByFaculty[key] = users;
                        }
                    });
                    if (Object.keys(networkByFaculty).length > 0) {
                        faculty.network = networkByFaculty;
                    }
                });
            });
        };

        cities = cities.filter((city) => {
            city.faculties = city.faculties.filter((faculty) => { return faculty.network != null });
            return city.faculties.length > 0
        });
        return cities;
    }


    eventType = (id, informationMissing, managed) => {
        let color = informationMissing ? (managed ? 'orange' : 'gray') : (managed ? 'green' : 'red');
        let hover = informationMissing ? (managed ? 'informationStatus.managedMissingInfo' : 'informationStatus.notManagedMissingInfo') : (managed ? 'informationStatus.managedFullInfo' : 'informationStatus.notManagedFullInfo');
        return (
            <div id={id} className={'box-center size-px-20'}>
                <SVG src={ConnexionsEventingIcon} className="size-px-20 " color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    networkPerson = (id, type, value) => {
        let t = this.props.t;
        let color = 'bg-color-black';
        switch (type) {
            case 'external':
                color = 'bg-color-black';
                break;
            case 'invited':
                color = 'bg-color-red';
                break;
            case 'underSignup':
                color = 'bg-color-orange';
                break;
            case 'user':
                color = 'bg-color-green';
                break;
            default:
                break;
        }

        return (
            <div id={type + id} className={'box-center size-px-15 rounded-8 mr-2' + ' ' + color}>
                <Label className='font-size-10 text-color-white'>{value}</Label>
                <UncontrolledTooltip placement="bottom" target={type + id}>{type}</UncontrolledTooltip>
            </div>
        )
    }

    networkPersonModule = (id, branch) => {
        let network = branch.network;
        return (
            <div className='align-horizontal center-vertical'>
                {Object.keys(network).length > 0 &&
                    <div className='align-horizontal'>
                        {network.external?.length > 0 &&
                            this.networkPerson(id, 'external', network.external.length)
                        }
                        {network.invited?.length > 0 &&
                            this.networkPerson(id, 'invited', network.invited.length)
                        }
                        {network.underSignup?.length > 0 &&
                            this.networkPerson(id, 'underSignup', network.underSignup.length)
                        }
                        {network.user?.length > 0 &&
                            this.networkPerson(id, 'user', network.user.length)
                        }
                        <div>
                            <User className="icon-dual size-px-20" />
                        </div>
                    </div>}
            </div>
        )
    }

    invitationModule = (id, inviteable, invitationDate) => {
        let t = this.props.t;
        let color = '';
        if (invitationDate) {
            let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
            let weeks = durationBetweenDates(invitationDate, "DD/MM/YYYY", now, "DD/MM/YYYY", ['weeks']);
            if (weeks < 2) {
                color = 'bg-color-green';
            }
            else if (weeks >= 2 && weeks < 4) {
                color = 'bg-color-orange';
            }
            else {
                color = 'bg-color-red';
            }
        }

        return (
            <div className='box-center mt-1'>
                <Mail
                    className="icon-dual ml-2 icon-size-20"
                    color={color.length > 0 ? 'gray' : (inviteable ? 'green' : 'red')}
                    id={id}
                    onClick={() => { }}
                />
                {color.length > 0 &&
                    <div className={'size-px-10 rounded-5' + ' ' + color} style={{ 'transform': 'translate(-8px, 5px)' }}></div>
                }
                <UncontrolledTooltip placement="bottom" target={id}>
                    {t('network.invite')}
                </UncontrolledTooltip>
            </div>
        )
    }

    childrenFromObject = (object) => {
        if (object == null) {
            return [];
        }

        let child = Object.assign({}, object);
        child.children = null;
        let children = [];
        children.push(child);

        let grandChildren = object.children ?? [];
        if (grandChildren.length > 0) {
            for (let i = 0; i < grandChildren.length; i++) {
                let grand = this.childrenFromObject(grandChildren[i])
                children = children.concat(grand)
            }
        }
        return children
    }

    adminString = (event, countries) => {
        let admins = '';
        (event?.admins ?? []).forEach(admin => {
            let name = admin.firstName ?? '';
            if (admin.lastName) {
                name += (name.length > 0 ? ' ' : '') + admin.lastName;
            }

            let location = locationString(event.country, admin.state, admin.city, countries);

            let adminInfo = name;
            if (location) {
                adminInfo += (adminInfo.length > 0 ? ', ' : '') + location;
            }
            // if (admin.branch) {
            //     adminInfo += (adminInfo.length > 0 ? ', ' : '') + admin.branch;
            // }
            if (adminInfo.length > 0) {
                admins += (admins.length > 0 ? ' | ' : '') + adminInfo;
            }
        });

        if (admins.length > 0) {
            admins = this.props.t('company.admin') + ' : ' + admins;
        }

        return admins;
    }

    createdByString = (event, countries) => {
        let t = this.props.t;

        let createdBy = event?.createdByUserDetails?.firstName ?? '';
        if (event?.createdByUserDetails?.lastName?.length > 0) {
            createdBy += (createdBy.length > 0 ? ' ' : '') + event?.createdByUserDetails?.lastName;
        }

        let userCountry = event?.createdByUserDetails?.country ? countryCodeToString(event?.createdByUserDetails?.country, countries) : '';
        if (createdBy.length > 0 && userCountry?.length > 0) {
            createdBy += (createdBy.length > 0 ? ', ' : '') + userCountry;
        }

        if (createdBy.length == 0) {
            createdBy = t('company.admin');
        }

        let createdDate = event.dateCreate ? formatDateString(event.dateCreate, 'MMM. DD, YYYY') : '';

        return (createdDate?.length > 0 ? format(t('general.createdByOn'), createdBy, createdDate) : format(t('general.createdBy'), createdBy));
    }

    subjectSummary = (element) => {
        let types = this.subjectTypes();
        let subjectType = element.type ? types?.find((e) => { return e.id == element.type }) : null;

        let categories = this.subjectCategories(element.type);
        let subjectCategories = [{ objects: categories, selectedObject: null }];
        let subject = null;
        if (element.id) {
            let lastCategory = this.lastCategory(element.type, element.id);
            if (lastCategory) {
                let tree = [lastCategory.category];
                if (lastCategory.category?.children?.length > 0) {
                    tree.push({ objects: lastCategory.category.children.map((element) => { element.label = element.name; return element }) });
                }
                tree = this.categoriesTree([lastCategory.category], lastCategory.ancestor, lastCategory.category.id);

                if (tree.length > 0) {
                    tree.forEach(element => {
                        subjectCategories[subjectCategories.length - 1].selectedObject = element;
                        if (element.children?.length > 0) {
                            subjectCategories.push({ objects: element.children.map((element) => { element.label = element.name; return element }) });
                        }
                    });
                }
            }

            let subjects = this.subjects(element.type, subjectCategories);
            subject = element.id ? subjects.find((element) => { return element.id == element.id || element.value == element.id }) : null;
        }

        let categoryName = '';
        (subjectCategories ?? []).forEach(element => {
            if (element?.selectedObject?.name?.length > 0) {
                categoryName += (categoryName.length > 0 ? ', ' : '') + element?.selectedObject?.name;
            }
        });

        let summary = subjectType?.label ?? '';
        if (categoryName.length > 0) {
            summary += (summary.length > 0 ? ', ' : '') + categoryName;
        }
        if (subject?.label?.length > 0) {
            summary += (summary.length > 0 ? ', ' : '') + subject.label;
        }

        return summary;
    }

    subjectTypes = () => {
        return (this.props.competencyInfo?.type?.competencyType ?? []).map((type) => { type.label = getLocalizedString(type.value); return type });
    }

    subjectCategories = (typeId) => {
        let categories = [];
        if (typeId == TRAINING_TYPE_TECHNOLOGY) {
            // Technology
            let techGroup = this.props.menuHierarchy?.techGroup;
            if (techGroup) {
                techGroup.label = techGroup.name;
                categories = [techGroup];
            }
            // if (this.props.menuHierarchy?.techGroup?.children?.length > 0) {
            //     categories = this.props.menuHierarchy?.techGroup?.children?.map((element) => {
            //         element.label = element.name;
            //         return element
            //     });

            //     categories = (categories ?? []).filter((element) => { return element.technology ?? true });
            // }
        }
        else if (typeId == TRAINING_TYPE_BUSINESS_DOMAIN) {
            // Business Group / Industry
            if (this.props.menuHierarchy?.industryGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.industryGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.businessDomain ?? true });
            }
        }
        else if (typeId == TRAINING_TYPE_DEPARTMENTAL) {
            let departmentGroup = this.props.menuHierarchy?.departmentGroup;
            if (departmentGroup) {
                if (departmentGroup.select ?? false) {
                    departmentGroup.label = departmentGroup.name;
                    categories = [departmentGroup];
                }
                else if (departmentGroup.children?.length > 0) {
                    categories = departmentGroup.children?.map((element) => {
                        element.label = element.name;
                        return element
                    }) ?? [];
                    categories = categories.filter((element) => { return element.departmental ?? true });
                }
            }
        }
        else if (typeId == TRAINING_TYPE_METHODOLOGY) {
            if (this.props.menuHierarchy?.methodologyGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.methodologyGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.methodology ?? true });
            }
        }

        return (categories ?? []).filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
    }

    subjects = (typeId, subjectCategories) => {
        let selectedCategory = null;
        if (subjectCategories?.length > 0) {
            selectedCategory = subjectCategories[subjectCategories.length - 1].selectedObject;
        }
        if (selectedCategory == null && subjectCategories?.length > 1) {
            selectedCategory = subjectCategories[subjectCategories.length - 2].selectedObject;
        }

        let subjects = [];
        let children = [];
        if (selectedCategory != null) {
            if (typeId == 2) {
                // Technology
                children = selectedCategory?.technologies ?? [];
            }
            else if (typeId == 4) {
                children = selectedCategory?.methodologies ?? [];

            }
        }

        if (children.length > 0) {
            subjects = children.map((element) => {
                element.label = element.name;
                return element;
            });
        }

        return subjects ?? [];
    }

    lastCategory = (typeId, objectId) => {
        let category = null;
        let ancestor = null;
        let categories = this.subjectCategories(typeId);
        for (var index = 0; index < categories.length; index++) {
            switch (typeId) {
                case TRAINING_TYPE_TECHNOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'technologies', objectId);
                    break;
                case TRAINING_TYPE_METHODOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'methodologies', objectId);
                    break;

                case TRAINING_TYPE_BUSINESS_DOMAIN:
                    category = this.parentCategoryOfObject(categories[index], 'businessdomain', objectId);
                    break;

                case TRAINING_TYPE_DEPARTMENTAL:
                    category = this.parentCategoryOfObject(categories[index], 'departmental', objectId);
                    break;

                default: break;
            }

            if (category != null) {
                category.selectedObject = (category.children ?? []).find((element) => { return element.id == objectId });
                ancestor = categories[index];
                break;
            }
        }

        return category ? { ancestor: ancestor, category: category } : null;
    }

    parentCategoryOfObject = (parent, type, objectId) => {
        if (parent[type]?.length > 0) {
            let index = parent[type].findIndex((element) => { return element.id == objectId });
            if (index >= 0) {
                return parent;
            }
        }

        if (parent.id.toString() == objectId) {
            return parent;
        }

        if (parent.children?.length > 0) {
            for (var index in parent.children) {
                let position = this.parentCategoryOfObject(parent.children[index], type, objectId);
                if (position != null) {
                    return position;
                }
            }
        }
        return null;
    }

    parentOfSelectedCategory = (ancestor, selectedCategoryId) => {
        let parent = null;
        if (ancestor.children?.length > 0) {
            if (ancestor.children.some((element) => { return element.id == selectedCategoryId })) {
                parent = ancestor;
            }

            if (parent == null) {
                for (var lvl in ancestor.children) {
                    let p = this.parentOfSelectedCategory(ancestor.children[lvl], selectedCategoryId);
                    if (p != null) {
                        parent = p;
                        break;
                    }
                }
            }
        }

        if (parent != null) {
            parent.selectedObject = (parent.children ?? []).find((element) => { return element.id == selectedCategoryId });
        }
        return parent;
    }

    categoriesTree = (tree, ancestor, selectedCategoryId) => {
        let parent = this.parentOfSelectedCategory(ancestor, selectedCategoryId);
        if (parent != null) {
            tree.unshift(parent);
            if (ancestor.id != parent.id) {
                return this.categoriesTree(tree, ancestor, parent?.id);
            }
            return tree;
        }
        return tree;
    }

    getEvents = () => {
        if (this.props.networkEventKeyword != this.state.keyword || this.props.networkEvents == null) {
            this.props.getNetworkEvents(this.state.groupByCountry ? NETWORK_EVENT_TYPE_COUNTRY : NETWORK_EVENT_TYPE_COMPANY, 0, false, this.state.keyword, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
    
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
    
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({ currentPage: page }, () => {
            this.props.getNetworkEvents(this.state.groupByCountry ? NETWORK_EVENT_TYPE_COUNTRY : NETWORK_EVENT_TYPE_COMPANY, page, false, this.state.keyword, {
                callbackOnBegin: () => {
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    console.log('123123');
                },
            });
        })
    }

    render() {
        const { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let groupByCountry = this.state.groupByCountry;

        let network = groupByCountry ? this.networkEventsByCountry() : this.networkEventsByCompany();
        // if (this.state.keyword?.length >= 3) {
        //     network = network.map((group) => {
        //         let events = group.events?.filter((event) => {
        //             const isValidName = (event?.name ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());

        //             const isValidLocation = event.locations?.length == 0;
        //             if (event?.locations != null) {
        //                 for (let i = 0; i < event.locations.length; i++) {
        //                     let location = event.locations[i];
        //                     let locationString = locationString(location.company, location.state, location.city, countries);
        //                     if (locationString?.length > 0) {
        //                         isValidLocation = locationString?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                         if (isValidLocation) {
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }

        //             let isValidFaculty = false;
        //             if (event?.faculties != null) {
        //                 for (let i = 0; i < event.faculties.length; i++) {
        //                     let faculty = event.faculties[i];
        //                     let facultyName = faculty.facultyId == 1000 ? (faculty.facultyName ?? t('sub.Others')) : (facultyWithId(faculty.facultyId, this.props.academicDoc?.facultyDoc?.eventFaculties)?.label ?? '');
        //                     if (facultyName?.length > 0) {
        //                         isValidFaculty = facultyName?.toLowerCase().includes(this.state.keyword.toLowerCase());
        //                         if (isValidFaculty) {
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }

        //             let isValidCreatedBy = false;
        //             let createdByString = this.createdByString(event, countries);
        //             if (createdByString?.length > 0) {
        //                 isValidCreatedBy = createdByString.toLowerCase().includes(this.state.keyword.toLowerCase());
        //             }

        //             return isValidName || isValidLocation || isValidFaculty || isValidCreatedBy;
        //         }, this);
        //         group.events = events;
        //         return group;
        //     }, this);
        // }

        let filteredNetwork = [];
        if (groupByCountry) {
            filteredNetwork = this.state.selectedCountry == 'All Countries' ? network : network.filter((group) => { return group.country == this.state.selectedCountry });
        }
        else {
            filteredNetwork = this.state.selectedCompany == -1 ? network : network.filter((group) => { return group.company == this.state.selectedCompany });
        }

        let availableCountries = [];
        let networkCountries = [];
        let availableFaculties = [];
        let networkFaculties = [];
        if (groupByCountry) {
            availableCountries = [
                {
                    value: 'All Countries',
                    label: t('country.allCountries'),
                    states: null,
                }
            ];

            networkCountries = network.map((group) => { return group.country });
            networkCountries = networkCountries?.map((countryCode) => {
                if (countryCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return countries.find((c) => { return c.value == countryCode });
            });
            networkCountries.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkCountries.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableCountries = availableCountries.concat(networkCountries);
        }
        else {
            availableFaculties = [
                {
                    value: -1,
                    label: format(t('general.all0'), t('general.companies').toLowerCase()),
                }
            ];

            networkFaculties = network.map((group) => { return group.company });
            networkFaculties = networkFaculties?.map((company) => {
                let companyName = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == company })?.name ?? '';
                return {
                    value: company,
                    label: companyName
                };
            }, this);
            networkFaculties.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkFaculties.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableFaculties = availableFaculties.concat(networkFaculties);
        }

        let selectedCountry = (this.state.selectedCountry && availableCountries.length > 0) ? availableCountries.find((element) => { return element.value == this.state.selectedCountry }) : null;
        let states = selectedCountry?.states?.map((state, i) => {
            return {
                value: state.id,
                label: getLocalizedString(state.name),
            };
        }) ?? [];

        let availableStates = [];
        if (filteredNetwork.length == 1 && states.length > 0) {
            let events = filteredNetwork[0].events ?? [];
            let cityStates = [];
            events.forEach(event => {
                let locations = (event.locations ?? []).filter((c) => { return c.country == this.state.selectedCountry });
                locations.forEach(location => {
                    let state = location.state ?? '';
                    const exist = cityStates.some((stateCode) => { return stateCode == state });
                    if (!exist) {
                        cityStates.push(state);
                    }
                });
            });
            cityStates = cityStates.map((stateCode) => {
                if (stateCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return states.find((c) => { return c.value == stateCode });
            });
            cityStates.sort(function (a, b) { return b.label.localeCompare(a.label) });
            cityStates.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });

            availableStates = [
                {
                    value: 'All States',
                    label: t('country.allStates'),
                }
            ];
            availableStates = availableStates.concat(cityStates);
        }

        filteredNetwork = filteredNetwork.map(group => {
            if (this.state.selectedState != 'All States') {
                group.events = group.events?.filter((event) => { return (event.locations ?? []).some((element) => { return element.state == (this.state.selectedState == 'Other' ? '' : this.state.selectedState) }) });
            }
            return group;
        }, this);

        filteredNetwork = filteredNetwork.filter((group) => { return group.events?.length > 0 });


        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                let countryA = a.country?.length == 0 ? '' : countryCodeToString(a.country, countries)
                let countryB = b.country?.length == 0 ? '' : countryCodeToString(b.country, countries)
                return a.country.length == 0 ? 1 : countryB.localeCompare(countryA)
            }
            else {
                let companyA = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == a.company })?.name ?? '';
                let companyB = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == b.company })?.name ?? '';
                return companyB.localeCompare(companyA);
            }
        }, this);
        filteredNetwork.sort((a, b) => {
            if (groupByCountry) {
                return a.country.length == 0 ? 1 : -1
            }
            else {
                return a.company == null ? 1 : -1
            }
        });

        return (
            <React.Fragment>
                {(this.state.showAlertPopup ?? false) &&
                    <RemoveNetworkModal
                        modal={this.state.showAlertPopup}
                        objectType={'global.event'}
                        summary={this.state.editingEvent}
                        onCancel={() => { this.setState({ showAlertPopup: false, editingContact: null }) }}
                        onSubmit={() => {
                            if (this.state.editingEvent?.id) {
                                this.props.deleteNetworkEvent(this.state.editingEvent.id, {
                                    callbackOnBegin: () => {
                                        // Update loading state?
                                        // console.log('callbackOnBegin');
                                        this.setState({ loading: true });
                                    },
                                    callbackOnFailure: (error) => {
                                        // show error to your users or stay quiet
                                        this.setState({ loading: false, error: error });
                                    },
                                    callbackOnFinish: () => {
                                        // Update loading state?
                                        this.setState({ loading: false });
                                    },
                                    callbackOnSuccess: (response) => {
                                        this.setState({ showAlertPopup: false, editingEvent: null })
                                    },
                                });
                            }
                        }}
                    />
                }

                {this.state.showAddCompanyDialog == 1 &&
                    <AddEventModal
                        id="add-contact"
                        title={t('profile.updatePersonalInformation')}
                        editingEvent={this.state.editingEvent ? JSON.parse(JSON.stringify(this.state.editingEvent)) : null}
                        modal={this.state.showAddCompanyDialog == 1}
                        close={this.closeAddEventModal}
                    />
                }
                <Row className="step-company">
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        <div className="path-component align-horizontal">
                            <Label className="link" onClick={this.onBack}>{t('Network')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link-selected">{t('me.events')} </Label>
                            <div id="add-contact" className='align-horizontal'>
                                <SVG src={addEventIcon} className="ml-3 size-px-30" onClick={() => { this.showAddEventModal() }} />
                                <UncontrolledTooltip placement="bottom" target="add-contact">
                                    {t('network.addEvent')}
                                </UncontrolledTooltip>
                                {/* <div className='box-center size-px-10 rounded-5 bg-color-test1' style={{ 'transform': 'translate(-5px, 0px)' }}>
                                    <Label className='mt-0 mb-0 font-bold'>+</Label>
                                </div> */}
                            </div>
                        </div>
                    </CardBody>
                    <CardBody className="text-box">
                        <Row className='ml-4'>
                            <Col xl={3}>
                                {groupByCountry ?
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableCountries}
                                        placeholder={t('placeholder.Select')}
                                        value={selectedCountry}
                                        onChange={(value) => {
                                            if (this.state.selectedCountry != value.value) {
                                                this.setState({ selectedCountry: value.value == 'Other' ? '' : value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                    :
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableFaculties}
                                        placeholder={t('placeholder.Select')}
                                        value={(this.state.selectedCompany && availableFaculties.length > 0) ? availableFaculties.find((element) => { return element.value == this.state.selectedCompany }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedCompany != value.value) {
                                                this.setState({ selectedCompany: value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                }
                            </Col>
                            {states.length > 0 &&
                                <Col xl={3}>

                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableStates}
                                        placeholder={t('placeholder.Select')}
                                        value={this.state.selectedState ? availableStates.find((element) => { return element.value == this.state.selectedState }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedState != value.value) {
                                                this.setState({ selectedState: value.value })
                                            }
                                        }} />

                                </Col>
                            }

                            <Col xl={3} className='box-side'>
                                <div className="width-percent-100 float-sm-right mt-3 mt-sm-0 mb-3">
                                    <div className="width-percent-100 task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control search-input rounded-5"
                                                    placeholder="Search..."
                                                    value={this.state.keyword ?? ''}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({ keyword: e.target.value }, () => {
                                                            if (!(this.props.networkEventKeyword.length < 3 && this.state.keyword.length < 3)) {
                                                                this.getEvents();
                                                            }
                                                        })
                                                    }}
                                                />
                                                <span className="uil uil-search icon-search"></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>

                            <Col xl={3} className='box-side'>
                                <div className='align-horizontal mb-2'>
                                    <div>
                                        <label className="cursor-pointer mr-2 mb-3" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            {t('company.byCountry')}
                                        </label>
                                    </div>
                                    {/* <div className='hard-custom-switch'> */}
                                    <div className="custom-control custom-switch mb-3 cursor-pointer" onClick={() => {
                                        this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                            this.handlePageClick(0);
                                        })
                                    }}>
                                        <input
                                            type="radio"
                                            className="custom-control-input cursor-pointer"
                                            checked={!this.state.groupByCountry}
                                        />
                                        <label className="custom-control-label cursor-pointer">
                                            {format(t('general.by0'), t('subscription.company'))}
                                        </label>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </Col>

                            <Col className='box-side'>
                                {groupByCountry && states.length > 0 &&
                                    <div></div>
                                }
                                <div className={"custom-control custom-switch mb-3 cursor-pointer" + (groupByCountry ? ' mr-2' : '')} onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}>
                                    <input
                                        type="radio"
                                        className="custom-control-input cursor-pointer"
                                        checked={this.state.showDetails}
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                                        {t('general.details')}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <br />

                        {filteredNetwork.length > 0 && filteredNetwork.map((item, index) => {
                            let country = item.country?.length == 0 ? t('sub.Others') : countryCodeToString(item.country, countries);
                            let companyName = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == item.company })?.name ?? '';

                            return (
                                <div key={'network' + index} >
                                    <div className={'mb-3'}>
                                        <Row className="path-component ml-4">
                                            <Col>
                                                <Label className='link-selected'>{groupByCountry ? (country ?? '') : (companyName ?? '')}</Label>
                                            </Col>
                                        </Row>
                                        {item.events.length > 0 && item.events.map((event, eventIndex) => {
                                            let removingInfo = {};
                                            removingInfo.id = event.id;

                                            let organization = event.companyNodeId ? this.props.allNetworkCompanies?.find((element) => { return element.nodeId == event.companyNodeId }) : null;
                                            removingInfo[t('subscription.company')] = organization?.company?.name;

                                            let info = '';
                                            if (event?.name?.length > 0) {
                                                info += (info.length > 0 ? ', ' : '') + event.name;
                                                removingInfo[t('global.event')] = event?.name;
                                            }

                                            let eventTypeName = '';
                                            let eventType = event.eventGroupId ? this.props.eventGroup?.children?.find((element) => { return element.id == event.eventGroupId }) : null;
                                            if (eventType?.name?.length > 0) {
                                                eventTypeName += (eventTypeName.length > 0 ? ', ' : '') + eventType?.name;
                                                removingInfo[t('event.type')] = eventType?.name;
                                            }

                                            let geoTypes = this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
                                                (element, i) => {
                                                    element.label = element.value ? getLocalizedString(element.value) : '';
                                                    return element;
                                                }
                                            ) ?? [];

                                            let geoType = event.geoTypeId ? geoTypes?.find((element) => { return element.id == event.geoTypeId }) : null;
                                            if (geoType?.label?.length > 0) {
                                                eventTypeName += (eventTypeName.length > 0 ? ' | ' : '') + geoType?.label;
                                                removingInfo[t('event.geoType')] = geoType?.label;
                                            }

                                            if (eventTypeName?.length > 0) {
                                                info += (info.length > 0 ? ', ' : '') + eventTypeName;
                                            }

                                            if (this.state.groupByCountry && organization?.company?.name?.length > 0) {
                                                let location = locationString(organization?.company.countryHQ, organization?.company.stateHQ, organization?.company.cityHQ, countries);
                                                let company = organization?.company?.name
                                                if (location?.length > 0) {
                                                    company += (company.length > 0 ? ', ' : '') + location;
                                                }
                                                info += (info.length > 0 ? ' | ' : '') + format(t('general.by0').toLowerCase(), company);
                                            }

                                            let subjects = '';
                                            (event.subjects ?? []).forEach(element => {
                                                let summary = this.subjectSummary(element);
                                                if (summary.length > 0) {
                                                    subjects += (subjects.length > 0 ? ' | ' : '') + summary;
                                                }
                                            });

                                            let locations = event.locations ?? [];
                                            let createdByString = this.createdByString(event, countries);

                                            const hasDetails = subjects.length > 0 || locations.length > 0 || createdByString.length > 0;

                                            return (
                                                <div className='ml-4' key={eventIndex}>
                                                    <div className='align-horizontal ml-2'>
                                                        {/* {this.eventType('eventType' + index + eventIndex, informationMissing, managed)} */}
                                                        <Label className='ml-2'>{info}</Label>

                                                        {(event?.createdByUserId == getLoggedInUser()?.id) &&
                                                            <div className='align-horizontal ml-3'>
                                                                <div onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.showAddEventModal(event)
                                                                }}>
                                                                    <Edit className="icon-size-20 cursor-pointer ml-2" color='gray' id={'edit' + index + eventIndex} />
                                                                    <UncontrolledTooltip placement="bottom" target={'edit' + index + eventIndex}>
                                                                        {t('network.editNetworkEvent')}
                                                                    </UncontrolledTooltip>
                                                                </div>

                                                                <div className='float-right size-px-20 box-center ml-2'>
                                                                    <Trash className="icon-dual size-px-20 cursor-pointer" id={'delete' + index + eventIndex}
                                                                        onClick={() => {
                                                                            this.setState({ editingEvent: removingInfo, showAlertPopup: 1 });
                                                                        }} />
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        id='tooltip-1'
                                                                        target={'delete' + index + eventIndex}>
                                                                        {t("experience.hover.removePerson")}
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                    {this.props.didLoadEventsDetails && this.state.showDetails && hasDetails &&
                                                        <div className='bg-color-details align-vertical ml-2'>
                                                            <Row className='ml-4 align-horizontal'>
                                                                <Label className='mb-0 mr-4 ml-1 mt-1'>{subjects}</Label>
                                                            </Row>

                                                            {locations.length > 0 && locations.map((location, locationIndex) => {
                                                                let locString = locationString(location.country, location.state, location.city, countries);
                                                                let periods = location.periods;

                                                                return (
                                                                    <div className='align-vertical'>
                                                                        <Row className='ml-4 align-horizontal'>
                                                                            <Label className='mb-0 mr-4 ml-1 mt-1'>{locString}</Label>
                                                                        </Row>
                                                                        {periods?.length > 0 && periods.map((element) => {
                                                                            let period = '';
                                                                            if (element.startDate != null) {
                                                                                period = dateStringFromTimeInterval(element.startDate, "DD/MM/YYYY");
                                                                            }
                                                                            if (element.endDate != null) {
                                                                                period += (period.length > 0 ? ' - ' : '') + dateStringFromTimeInterval(element.endDate, "DD/MM/YYYY");
                                                                            }
                                                                            else {
                                                                                period += (period.length > 0 ? ' - ' : '') + this.props.t('general.present');
                                                                            }

                                                                            let sponsors = '';
                                                                            (element.sponsors ?? []).forEach(s => {
                                                                                let sponsor = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == s });
                                                                                if (sponsor?.company?.name?.length > 0) {
                                                                                    sponsors += (sponsors.length > 0 ? ', ' : '') + sponsor.company.name;
                                                                                }
                                                                            });

                                                                            if (sponsors.length > 0) {
                                                                                sponsors = t('general.sponsors') + ': ' + sponsors;
                                                                            }

                                                                            let exhibitors = '';
                                                                            (element.exhibitors ?? []).forEach(s => {
                                                                                let exhibitor = this.props.allNetworkCompanies?.find((element) => { return element.nodeId == s });
                                                                                if (exhibitor?.company?.name?.length > 0) {
                                                                                    exhibitors += (exhibitors.length > 0 ? ', ' : '') + exhibitor.company.name;
                                                                                }
                                                                            });

                                                                            let companies = sponsors;
                                                                            if (exhibitors.length > 0) {
                                                                                exhibitors = t('event.exhibitors') + ': ' + exhibitors;
                                                                                companies += (companies.length > 0 ? '; ' : '') + exhibitors;
                                                                            }

                                                                            if (companies.length > 0) {
                                                                                period += (period.length > 0 ? ' : ' : '') + companies;
                                                                            }
                                                                            return (
                                                                                <Row className='ml-4 align-horizontal'>
                                                                                    <Label className='mb-0 ml-4 mt-1'>{period}</Label>
                                                                                </Row>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            }, this)
                                                            }

                                                            {createdByString.length > 0 &&
                                                                <Row className='ml-3 mb-1 mt-1'>
                                                                    <Col>
                                                                        <Label className='mb-0 mt-1 font-size-10 text-color-system-notice'>{createdByString}</Label>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }, this)}
                                    </div>
                                    {(index < filteredNetwork.length - 1) &&
                                        <Row className="path-component ml-3">
                                            <Col>
                                                <div className='separator-line mb-3'></div>
                                            </Col>
                                        </Row>

                                    }
                                </div>
                            )
                        }, this)}

                        {this.props.networkEvents?.total_pages > 1 &&
                            <div className='float-right mt-2'>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.props.networkEvents?.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    forcePage={this.state.currentPage}
                                    onPageChange={(e) => { this.handlePageClick(e.selected) }}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, menuHierarchy, loading, error } = state.Profile;
    const { countries } = state.Auth;
    //const { academicDoc } = state.Events;
    const { networkEventKeyword, networkEvents, didLoadEventsDetails, allNetworkCompanies, eventGroup } = state.Network;
    const { competencyInfo } = state.Competency;
    const { companies } = state.Company;

    return {
        menuHierarchy, subscriptionProfile, countries, networkEventKeyword, networkEvents, didLoadEventsDetails,
        competencyInfo, companies, allNetworkCompanies, eventGroup,
        loading, error
    };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAcademicDoc,
    getNetworkEvents,
    deleteNetworkEvent,
    getAllNetworkCompanies,
    getAllContentsCompany,
    getAllNetworkCompanies,
    getCompetencyInfo,
    getEventGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkEvents));
