import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { AvForm } from 'availity-reactstrap-validation';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, Row, UncontrolledTooltip } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import {
    getCompetencyInfo, createTraining, getCompetenciesOfType,
    editTrainingDoc, getAllNetworkCompanies, getAllNetworkPeople, getAPICountryInfo,
} from '../../../../../redux/actions';
import Loader from '../../../../../components/Loader';
import { isDarkMode } from '../../../../../helpers/authUtils';
import { allLanguages, dateOnlyDisplayString, dateOnlyString, getCurrentLanguage, getLocalizedString, locationString } from '../../../../../helpers/utils';
import moment from 'moment';
import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from '../../../../../helpers/DatePickerFormat';
import { ACTION_ADD_TRAINING, ACTION_EDIT_TRAINING, ACTION_VIEW_TRAINING } from './constants';
import { format } from '../../../../../helpers/stringFormat';
import { NETWORK_COMPANY_TYPE_COUNTRY } from '../../../Home/Network/NetworkCompany';
import { NETWORK_PERSON_TYPE_COUNTRY } from '../../../Home/Network/NetworkPerson';

var CountryLanguage = require('country-language');

export const TRAINING_TYPE_BUSINESS_DOMAIN = 1;
export const TRAINING_TYPE_TECHNOLOGY = 2;
export const TRAINING_TYPE_DEPARTMENTAL = 3;
export const TRAINING_TYPE_METHODOLOGY = 4;


class AddNewTrainingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            size: 'lg',
            currentCountryToAcademicEntity: null,
            className: 'custom-modal-style',
            disabledValidate: true,
            error: null,
            readOnly: false,

            selectedType: null,
            trainingCategories: [],
            selectedCompetency: null,
            selectedTraining: null,
            selectedNetwork: null,
            courseName: '',
            dateStart: null,
            dateEnd: null,
            nbHours: null,
        };
    }

    componentDidMount = () => {
        this.props.getCompetencyInfo({})
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({})
        }

        // if (this.props.allNetworkCompanies == null) {
        //     this.props.getAllNetworkCompanies({
        //         callbackOnBegin: () => {
        //             this.setState({ loading: true, error: null });
        //         },
        //         callbackOnFailure: (error) => {
        //             this.setState({ error: error });
        //         },
        //         callbackOnFinish: () => {
        //             this.setState({ loading: false });
        //         },
        //     });
        // }

        // if (this.props.allNetworkPeople == null) {
        //     this.props.getAllNetworkPeople({
        //         callbackOnBegin: () => {
        //             this.setState({ loading: true, error: null });
        //         },
        //         callbackOnFailure: (error) => {
        //             this.setState({ error: error });
        //         },
        //         callbackOnFinish: () => {
        //             this.setState({ loading: false });
        //         },
        //     });
        // }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.menuHierarchy != this.props.menuHierarchy || prevProps.competencyInfo != this.props.competencyInfo || prevProps.allNetworkCompanies != this.props.allNetworkCompanies || prevProps.allNetworkPeople != this.props.allNetworkPeople) {
            this.prefillData();
        }
    }

    prefillData = () => {
        if (this.props.menuHierarchy == null || this.props.competencyInfo == null || this.props.allNetworkCompanies == null) {
            return;
        }

        let info = this.props.competencyInfoSelected;
        if (info) {
            let types = this.competencyTypes();
            let selectedType = info?.idType ? types.find((element) => { return element.value == info?.idType }) : null;

            let competencies = [];

            let categories = this.competencyCategories(selectedType);
            let trainingCategories = [{ objects: categories, selectedObject: null }];
            let selectedCompetency = null;
            if (info?.elementId) {
                let lastCategory = this.lastCategory(selectedType, info?.elementId);
                if (lastCategory) {
                    let tree = [lastCategory.category];
                    if (lastCategory.category?.children?.length > 0) {
                        tree.push({ objects: lastCategory.category.children.map((element) => { element.label = element.name; return element }) });
                    }
                    tree = this.categoriesTree([lastCategory.category], lastCategory.ancestor, lastCategory.category.id);

                    if (tree.length > 0) {
                        tree.forEach(element => {
                            trainingCategories[trainingCategories.length - 1].selectedObject = element;
                            if (element.children?.length > 0) {
                                trainingCategories.push({ objects: element.children.map((element) => { element.label = element.name; return element }) });
                            }
                        });
                    }
                }

                competencies = this.competencies(selectedType, trainingCategories);
                selectedCompetency = info?.elementId ? competencies.find((element) => { return element.id == parseInt(info.elementId) || element.value == parseInt(info.elementId) }) : null;
            }

            let trainingTypes = this.trainingTypes();
            let selectedTraining = info?.trainingType ? trainingTypes.find((element) => { return element.value == info?.trainingType }) : null;

            let selectedNetwork = null;
            if (selectedTraining?.value == 316) {
                let people = this.networkPeople();
                selectedNetwork = info?.userId ? people.find((element) => { return element.value == info?.userId }) : null;
            }
            else {
                let companies = this.allNetworkCompanies();
                selectedNetwork = info?.companyNodeId ? companies.find((element) => { return element.value == info?.companyNodeId }) : null;
            }

            let courseName = info?.courseName ?? '';

            let dateStart = info?.dateStart ? moment(info.dateStart, 'YYYY-MM-DD').toDate() : null;
            let dateEnd = info?.dateEnd ? moment(info.dateEnd, 'YYYY-MM-DD').toDate() : null;

            let nbHours = info?.nbHours ? info.nbHours.toString() : null;

            this.setState({
                selectedType: selectedType,
                trainingCategories: trainingCategories,
                selectedCompetency: selectedCompetency,
                selectedTraining: selectedTraining,
                selectedNetwork: selectedNetwork,
                courseName: courseName,
                dateStart: dateStart,
                dateEnd: dateEnd,
                nbHours: nbHours
            });
        }
    }

    onChangeType = (value) => {
        let params = {};
        params.selectedType = value;
        if (value.value != this.state.selectedType?.value) {
            let categories = this.competencyCategories(value);
            params.trainingCategories = [{ objects: categories }];
            params.selectedCompetency = null;
        }

        this.setState(params, () => {
            this.props.getCompetenciesOfType((value.value), {});
        });
    }

    selectTrainingCategory = (categoryIndex, selectedValue) => {
        let trainingCategories = this.state.trainingCategories;
        trainingCategories[categoryIndex].selectedObject = selectedValue;
        trainingCategories.length = categoryIndex + 1;

        if (selectedValue.children?.length > 0) {
            let objects = selectedValue.children.map((element) => { element.label = element.name; return element });
            if (objects?.length > 0) {
                objects = objects.filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
            }
            trainingCategories.push({ objects: objects });
        }
        this.setState({ trainingCategories: trainingCategories });
    }

    selectedTraing = (value) => {
        let state = {};
        state.selectedTraining = value;
        if (value != this.state.selectedTraining) {
            state.selectedNetwork = null;
        }
        this.setState(state);
    }

    handleStartDateInputChange = (value) => {
        this.setState({ dateStart: moment(value).valueOf() });
    };

    handleEndDateInputChange = (value) => {
        this.setState({ dateEnd: moment(value).valueOf() });
    };

    onChangeNBHours = (e) => {
        this.setState(
            {
                nbHours: e.target.value,
            });
    }

    disabledValidate = () => {
        var valid = this.state.selectedType != null &&
            this.state.trainingCategories.some((element) => { return element.selectedObject != null }) &&
            this.state.selectedTraining != null &&
            (this.state.selectedTraining?.value == 315 ? true : this.state.selectedNetwork != null) &&
            ((this.state.selectedTraining?.value == 315 || this.state.selectedTraining?.value == 316) ? true : this.state.courseName.length > 0) &&
            this.state.dateStart != null &&
            this.state.dateEnd != null &&
            this.state.nbHours != null;

        if (this.state.selectedType?.value == TRAINING_TYPE_TECHNOLOGY || this.state.selectedType?.value == TRAINING_TYPE_METHODOLOGY) {
            let competencies = this.competencies(this.state.selectedType, this.state.trainingCategories);
            if (valid && competencies.length > 0) {
                valid = this.state.selectedCompetency != null;
            }
        }

        return !valid;
    }

    createTraining = () => {
        this.setState({ error: null });
        var data = {};
        data.idType = this.state.selectedType?.value;

        let competencies = this.competencies(this.state.selectedType, this.state.trainingCategories);

        if (this.state.selectedType?.value == TRAINING_TYPE_TECHNOLOGY || this.state.selectedType?.value == TRAINING_TYPE_METHODOLOGY) {
            data.elementId = (this.state.selectedCompetency?.id ?? this.state.selectedCompetency?.value).toString();
        }
        else {
            let category = this.state.trainingCategories[this.state.trainingCategories.length - 1];
            if (category == null && this.state.trainingCategories.length > 1) {
                category = this.state.trainingCategories[this.state.trainingCategories.length - 2];
            }
            data.elementId = category.selectedObject?.id;
        }

        data.trainingType = this.state.selectedTraining?.value;
        if (this.state.selectedTraining?.value == 315) {
            data.userId = null;
            data.companyNodeId = null;
            data.courseName = null;
        }
        else if (this.state.selectedTraining?.value == 316) {
            data.userId = this.state.selectedNetwork?.value;
            data.companyNodeId = null;
            data.courseName = null;
        }
        else {
            data.userId = null;
            data.companyNodeId = this.state.selectedNetwork?.value;
            data.courseName = this.state.courseName;
        }

        if (this.state.dateStart != null && this.state.dateStart != null) {
            // dateStart = moment(this.state.dateStart);
            var valueDateStart = dateOnlyString(this.state.dateStart);
            data.dateStart = valueDateStart;
        }
        if (this.state.dateEnd != null && this.state.dateEnd != null) {
            // dateEnd = moment(this.state.dateEnd);
            var valueDateEnd = dateOnlyString(this.state.dateEnd);
            data.dateEnd = valueDateEnd;
        }
        data.nbHours = parseInt(this.state.nbHours);

        if (this.props.statusAction == ACTION_EDIT_TRAINING) {
            data.id = this.props.competencyTypeId;
            data.trainingId = this.props.competencyInfoSelected?.id;

            this.props.editTrainingDoc(data, {
                callbackOnFailure: (response) => {
                    // whatever
                    this.setState({ error: response });
                },
                callbackOnSuccess: (response) => {
                    this.props.onAddedCompetency();
                }
            });
        }
        else {
            this.props.createTraining(data, {
                callbackOnFailure: (response) => {
                    // whatever
                    this.setState({ error: response });
                },
                callbackOnSuccess: (response) => {
                    this.props.onAddedCompetency();
                }
            });
        }

    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    competencyTypes = () => {
        return (this.props.competencyInfo?.type?.competencyType ?? []).map((type) => ({ value: type.id, label: getLocalizedString(type.value), element: type }));
    }

    competencyCategories = (selectedType) => {
        let categories = [];
        if (selectedType?.value == 2) {
            // Technology
            if (this.props.menuHierarchy?.techGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.techGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.technology ?? true });
            }
        }
        else if (selectedType?.value == 1) {
            // Business Group / Industry
            if (this.props.menuHierarchy?.industryGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.industryGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.businessDomain ?? true });
            }
        }
        else if (selectedType?.value == 3) {
            if (this.props.menuHierarchy?.departmentGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.departmentGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.departmental ?? true });
            }
        }
        else if (selectedType?.value == 4) {
            if (this.props.menuHierarchy?.departmentGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.methodologyGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.methodology ?? true });
            }
        }

        return (categories ?? []).filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
    }

    competencies = (selectedType, trainingCategories) => {
        let selectedCategory = null;
        if (trainingCategories?.length > 0) {
            selectedCategory = trainingCategories[trainingCategories.length - 1].selectedObject;
        }
        if (selectedCategory == null && trainingCategories?.length > 1) {
            selectedCategory = trainingCategories[trainingCategories.length - 2].selectedObject;
        }

        let competencies = [];
        let children = [];
        if (selectedCategory != null) {
            if (selectedType?.value == 2) {
                // Technology
                children = selectedCategory?.technologies ?? [];
            }
            else if (selectedType?.value == 4) {
                children = selectedCategory?.methodologies ?? [];

            }
        }

        if (children.length > 0) {
            competencies = children.map((element) => {
                element.label = element.name;
                return element;
            });
        }

        return competencies ?? [];
    }

    trainingTypes = () => {
        return this.props.menuHierarchy?.courseGroup?.children?.map((el) => { return { value: el.id, label: el.name, entity: el } }) ?? [];
    }

    allNetworkCompanies = () => {
        let companies = [];
        if (this.props.allNetworkCompanies) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );

            companies = this.props.allNetworkCompanies?.map((company) => {
                let name = company.name;
                let location = locationString(company.countryHQ, company.stateHQ, company.cityHQ, countries);
                if (location.length > 0) {
                    name += (name.length > 0 ? ' - ' : '') + location;
                }

                return { value: company.nodeId, label: name, entity: company }
            }) ?? [];
        }

        return companies;
    }

    networkPeople = () => {
        let people = [];
        if (this.props.allNetworkPeople) {
            people = this.props.allNetworkPeople?.map((element) => {
                let name = element.firstName ?? '';
                if (element.lastName?.length > 0) {
                    name += (name.length > 0 ? ' ' : '') + element.lastName;
                }
                return { value: element.id, label: name, entity: element };
            }) ?? [];
        }

        return people;
    }

    lastCategory = (type, objectId) => {
        let category = null;
        let ancestor = null;
        let categories = this.competencyCategories(type);
        for (var index = 0; index < categories.length; index++) {
            switch (type.value) {
                case TRAINING_TYPE_TECHNOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'technologies', objectId);
                    break;
                case TRAINING_TYPE_METHODOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'methodologies', objectId);
                    break;

                case TRAINING_TYPE_BUSINESS_DOMAIN:
                    category = this.parentCategoryOfObject(categories[index], 'businessdomain', objectId);
                    break;

                case TRAINING_TYPE_DEPARTMENTAL:
                    category = this.parentCategoryOfObject(categories[index], 'departmental', objectId);
                    break;

                default: break;
            }

            if (category != null) {
                category.selectedObject = (category.children ?? []).find((element) => { return element.id == objectId });
                ancestor = categories[index];
                break;
            }
        }

        return category ? { ancestor: ancestor, category: category } : null;
    }

    parentCategoryOfObject = (parent, type, objectId) => {
        if (parent[type]?.length > 0) {
            let index = parent[type].findIndex((element) => { return element.id == objectId });
            if (index >= 0) {
                return parent;
            }
        }

        if (parent.id.toString() == objectId) {
            return parent;
        }

        if (parent.children?.length > 0) {
            for (var index in parent.children) {
                let position = this.parentCategoryOfObject(parent.children[index], type, objectId);
                if (position != null) {
                    return position;
                }
            }
        }
        return null;
    }

    parentOfSelectedCategory = (ancestor, selectedCategoryId) => {
        let parent = null;
        if (ancestor.children?.length > 0) {
            if (ancestor.children.some((element) => { return element.id == selectedCategoryId })) {
                parent = ancestor;
            }

            if (parent == null) {
                for (var lvl in ancestor.children) {
                    let p = this.parentOfSelectedCategory(ancestor.children[lvl], selectedCategoryId);
                    if (p != null) {
                        parent = p;
                        break;
                    }
                }
            }
        }

        if (parent != null) {
            parent.selectedObject = (parent.children ?? []).find((element) => { return element.id == selectedCategoryId });
        }
        return parent;
    }

    categoriesTree = (tree, ancestor, selectedCategoryId) => {
        let parent = this.parentOfSelectedCategory(ancestor, selectedCategoryId);
        if (parent != null) {
            tree.unshift(parent);
            if (ancestor.id != parent.id) {
                return this.categoriesTree(tree, ancestor, parent?.id);
            }
            return tree;
        }
        return tree;
    }

    render() {
        const { t } = this.props;

        let types = this.competencyTypes();
        let categories = this.competencyCategories(this.state.selectedType);
        let competencies = this.competencies(this.state.selectedType, this.state.trainingCategories);
        let trainingTypes = this.trainingTypes();
        let companies = this.allNetworkCompanies();
        let people = this.networkPeople();
        let network = this.state.selectedTraining?.value == 316 ? people : companies;
        let languages = allLanguages();

        const isDark = isDarkMode();

        const disabledValidate = this.disabledValidate();

        let headerTitle = format(t(this.props.competencyInfoSelected ? t('general.modify0') : t('general.add0')), t('global.training').toLowerCase());
        let errorMessage = this.state.error?.response?.data?.message ? this.state.error?.response?.data?.message : this.state.error?.message;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.onCancel(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}
                    style={{ maxWidth: '600px', width: '100%' }}>

                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {/* {this.state.error && <this.Alert error={this.state.error} />} */}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.onClose();
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ error: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <div>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mb-3'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for='country'>{t(this.state.readOnly ? 'competency.type' : 'me.selectType')}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    {this.state.readOnly ? <Label>{this.state.selectedType?.label ?? ''}</Label> :
                                                                        <Select
                                                                            className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            placeholder={t('placeholder.Select')}
                                                                            value={this.state.selectedType}
                                                                            isDisabled={this.state.readOnly}
                                                                            onChange={
                                                                                (value) => { this.onChangeType(value) }
                                                                            }
                                                                            options={types}></Select>
                                                                    }
                                                                </Col>
                                                            </Row>

                                                            {this.state.trainingCategories.length > 0 &&
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15' for='country'>{this.state.readOnly ? t('competency.category') : (format(t('general.select0'), t((this.state.selectedType?.value == 1 ? 'company.industry' : (this.state.selectedType?.value == 3 ? 'career.department' : 'techGroup.category'))).toLowerCase()))}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8} className='align-vertical'>
                                                                        {this.state.trainingCategories.map((category, categoryIndex) => {
                                                                            return (
                                                                                <Select
                                                                                    className={"react-select" + (categoryIndex > 0 ? ' mt-3' : '')}
                                                                                    classNamePrefix="react-select"
                                                                                    isDisabled={this.state.readOnly}
                                                                                    placeholder={t('placeholder.Select')}
                                                                                    value={category.selectedObject ?? null}
                                                                                    onChange={(value) => { this.selectTrainingCategory(categoryIndex, value) }}
                                                                                    options={category.objects}></Select>
                                                                            )
                                                                        }, this)}
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {competencies?.length > 0 &&
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15' for='country'>{this.state.readOnly ? t(this.state.selectedType.value == 2 ? 'experience.Technology' : 'competency.methodology') : format(t('general.select0'), t(this.state.selectedType.value == 2 ? 'experience.Technology' : 'competency.methodology').toLowerCase())}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        {this.state.readOnly ? <Label>{this.state.selectedCompetency?.label ?? ''}</Label> :
                                                                            <Select
                                                                                className="react-select"
                                                                                classNamePrefix="react-select"
                                                                                isDisabled={this.state.readOnly}
                                                                                placeholder={t('placeholder.Select')}
                                                                                value={this.state.selectedCompetency}
                                                                                onChange={(value) => {
                                                                                    this.setState({ selectedCompetency: value })
                                                                                }}
                                                                                options={competencies}></Select>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {//(competencies.length == 0 ? this.state.trainingCategories[this.state.trainingCategories.length - 1].selectedObject != null : this.state.selectedCompetency != null) &&
                                                                <div>
                                                                    <Row className='mb-3'>
                                                                        <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                            <Label className='link-selected font-size-15' for='country'>{t(this.state.readOnly ? 'training.trainingType' : 'training.selectTrainingType')}</Label>
                                                                        </Col>
                                                                        <Col md={8} xl={8}>
                                                                            {this.state.readOnly ? <Label>{this.state.selectedTraining?.label ?? ''}</Label> :
                                                                                <Select
                                                                                    className="react-select"
                                                                                    classNamePrefix="react-select"
                                                                                    isDisabled={this.state.readOnly}
                                                                                    placeholder={t('placeholder.Select')}
                                                                                    value={this.state.selectedTraining}
                                                                                    onChange={(value) => { this.selectedTraing(value) }}
                                                                                    options={trainingTypes}></Select>
                                                                            }
                                                                        </Col>
                                                                    </Row>

                                                                    {this.state.selectedTraining && this.state.selectedTraining.value != 315 &&
                                                                        <Row className='mb-3'>
                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                <Label className='link-selected font-size-15' for='country'>{this.state.readOnly ? t(this.state.selectedTraining?.value == 316 ? 'general.person' : 'subscription.company') : format(t('general.select0'), t(this.state.selectedTraining?.value == 316 ? 'general.person' : 'subscription.company').toLowerCase())}</Label>
                                                                            </Col>
                                                                            <Col md={8} xl={8}>
                                                                                {this.state.readOnly ? <Label>{this.state.selectedNetwork?.label ?? ''}</Label> :
                                                                                    <Select
                                                                                        className="react-select"
                                                                                        classNamePrefix="react-select"
                                                                                        isDisabled={this.state.readOnly}
                                                                                        placeholder={t('placeholder.Select')}
                                                                                        value={this.state.selectedNetwork}
                                                                                        onChange={(value) => { this.setState({ selectedNetwork: value }) }}
                                                                                        options={network}></Select>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    }

                                                                    {(this.state.selectedTraining?.value != 315 ? this.state.selectedNetwork : true) &&
                                                                        <div>
                                                                            {this.state.selectedTraining?.value != 315 && this.state.selectedTraining?.value != 316 &&
                                                                                <Row className='mb-3'>
                                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                        <Label className='link-selected font-size-15' for='country'>{t('training.courseName')}</Label>
                                                                                    </Col>
                                                                                    <Col md={8} xl={8}>
                                                                                        {this.state.readOnly ? <Label>{this.state.courseName}</Label> :
                                                                                            <Input
                                                                                                isDisabled={this.state.readOnly}
                                                                                                className="react-select"
                                                                                                classNamePrefix="react-select"
                                                                                                type="text"
                                                                                                name="courseName"
                                                                                                id="courseName"
                                                                                                autoComplete="off"
                                                                                                // placeholder={t('placeholder.newCompetencyInt')}
                                                                                                value={this.state.courseName}
                                                                                                onChange={(e) => { this.setState({ courseName: e.target.value ?? '' }) }}></Input>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            <Row className='mb-3'>
                                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                    <Label className='link-selected font-size-15' for='country'>{t('training.dateStart')}</Label>
                                                                                </Col>
                                                                                <Col md={8} xl={8}>
                                                                                    {this.state.readOnly ? <Label>{this.props.competencyInfoSelected?.dateStart ?? ''}</Label> :
                                                                                        <DatePicker
                                                                                            isDisabled={this.state.readOnly}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            placeholderText="(dd/MM/YYYY)"
                                                                                            selected={this.state.dateStart}
                                                                                            className="date-picker p-2"
                                                                                            onChange={this.handleStartDateInputChange}
                                                                                            locale={getCurrentLanguage()}
                                                                                        />
                                                                                    }
                                                                                    {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className='mb-3'>
                                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                    <Label className='link-selected font-size-15' for='country'>{t('training.dateEnd')}</Label>
                                                                                </Col>
                                                                                <Col md={8} xl={8}>
                                                                                    {this.state.readOnly ? <Label>{this.props.competencyInfoSelected?.dateEnd ?? ''}</Label> :
                                                                                        <DatePicker
                                                                                            isDisabled={this.state.readOnly}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            placeholderText="(dd/MM/YYYY)"
                                                                                            minDate={this.state.dateStart}
                                                                                            selected={this.state.dateEnd}
                                                                                            className="date-picker p-2"
                                                                                            onChange={this.handleEndDateInputChange}
                                                                                            locale={getCurrentLanguage()}
                                                                                        />
                                                                                    }
                                                                                    {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                                                </Col>
                                                                            </Row>

                                                                            <Row >
                                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                    <Label className='link-selected font-size-15' for='country'>{t('training.nbHours')}</Label>
                                                                                </Col>
                                                                                <Col md={8} xl={8}>
                                                                                    {this.state.readOnly ? <Label>{this.state.nbHours ?? ''}</Label> :
                                                                                        <Input
                                                                                            isDisabled={this.state.readOnly}
                                                                                            className="react-select"
                                                                                            classNamePrefix="react-select"
                                                                                            type="number"
                                                                                            name="nbHours"
                                                                                            id="nbHours"
                                                                                            autoComplete="off"
                                                                                            // placeholder={t('placeholder.newCompetencyInt')}
                                                                                            value={this.state.nbHours ?? ''}
                                                                                            onChange={this.onChangeNBHours}></Input>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </Col >
                                                    </Row>

                                                    <br />

                                                    <Row>
                                                        <Col md={1} xl={1}></Col>
                                                        <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                            <div>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.onCancel(null);
                                                                }}><i className='uil'></i>{t((this.state.readOnly ?? false) ? 'modal.close' : "codeInput.cancel")}</Button>
                                                            </div>
                                                            <div>
                                                                {!(this.state.readOnly ?? false) &&
                                                                    <Button className='ml-2' disabled={disabledValidate} color="primary" onClick={(e) => {
                                                                        e.currentTarget.blur();
                                                                        this.createTraining();
                                                                    }}><i className='uil'></i>{t("updateCV.validate")}</Button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    const { countries } = state.Auth;
    const { menuHierarchy, subscriptionProfile } = state.Profile;
    const { competencyInfo, competenciesOfType, competencyHierarchy, loading, error } = state.Competency;
    const { allNetworkCompanies, allNetworkPeople } = state.Network;

    return { menuHierarchy, subscriptionProfile, competencyInfo, competenciesOfType, competencyHierarchy, allNetworkCompanies, allNetworkPeople, countries, loading, error };
};

const mapDispatchToProps = {
    getCompetencyInfo,
    createTraining,
    getCompetenciesOfType,
    editTrainingDoc,
    getAllNetworkCompanies,
    getAllNetworkPeople,
    getAPICountryInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddNewTrainingModal));