import moment from 'moment';
import React, { Component } from 'react';
import * as FeatherIcon from 'react-feather';
import {
    SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY, SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY,
    SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY_ORTHER, SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE,
    SCREEN_GRADUATE_DEGREE_SKIP, SCREEN_GRADUATE_DEGREE_NEXT, SCREEN_GRADUATE_DEGREE_VALIDATE, SCREEN_GRADUATE_DEGREE_ERASE, SCREEN_GRADUATE_DEGREE_SAVE, SCREEN_GRADUATE_DEGREE_SAVE_GD, SCREEN_GRADUATE_DEGREE_SAVE_F
} from './constants'
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { Alert, Button, Col, Label, Row } from 'reactstrap';
import {
    customAlertPopup,
    dateOnlyString,
    getCurrentLanguage,
    getLocalizedString,
    monthOnlyDisplayString,
} from '../../../../../../helpers/utils';
import {
    getAPICountryInfo,
    createAcademic,
    getAcademicsInCountry
} from '../../../../../../redux/actions';

import { format } from '../../../../../../helpers/stringFormat';
import Loader from '../../../../../../components/Loader';
import StepAddAcademicEntityGraduateDegree from './StepAddAcademicEntityGraduateDegree';
import StepAddAcademicEntityFaculty from './StepAddAcademicEntityFaculty';
import StepAddCountryToAcademic from './StepAddCountryToAcademic';
var CountryLanguage = require('country-language');

class NewCareerCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            academyNodeId: null,
            currentAcademicCityName: null,
            currentAcademicEntityName: null,
            newIntCode: null,
            academicEntitySelected: null,
            facultySelected: null,
            gradualteSelected: null,
            academicMonthStart: null,
            academicMonthEnd: null,
            currentNewIntCodeToAcademicEntity: null,
            currentCountryToAcademicEntity: null,
            currentAcademicEntity: null,
            currentAcademicFaculty: null,
            currentAcademicCity: null,
            currentAcademicGraduateDegree: null,
            currentAcademicDiplomaInt: null,
            currentAcademicGraduationSpecialty: null,
            currentAcademicGraduationSpecialtyInt: null,
            currentStateOfCountryToAcademicEntity: null,
            dateOfDegree: null,
            underPreparation: false,
            degrees: null,
            cureentShowScreen: SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY,
            disabledNext: true,
            editingDegrees: [],
            currentDegreeIndex: 0,
            isValidate: false,
            isEditing: false
        };
    }

    componentDidMount = () => {
        this.initContentData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.theme != prevProps.theme) {
            window.location.reload(false);
        }
    }

    initContentData = () => {
        let editingAcademy = this.props.editingAcademy;
        let editingPlace = this.props.editingPlace;
        if (editingAcademy != null && editingPlace != null) {
            let isValidate = editingPlace.isValidate;

            let academyNodeId = editingAcademy.academyDetail?.nodeId;
            let currentCountryToAcademicEntity = editingAcademy.country;
            let currentNewIntCodeToAcademicEntity = editingAcademy.academyNewIntlCode;
            let currentAcademicEntity = editingAcademy.academyId;
            let currentAcademicEntityName = editingAcademy.academyDetail?.name;

            let statesOfCountry = this.props.countries?.find((country) => { return country.value == currentCountryToAcademicEntity ?? '' })?.states;
            let currentStateOfCountryToAcademicEntity = editingPlace.state;

            let currentAcademicFaculty = editingPlace.facultyId?.toString();
            let currentAcademicCity = editingPlace.cityId;
            let currentAcademicCityName = editingPlace.academyNewCityIntl;
            if (currentAcademicCity == null && currentAcademicCityName != null) {
                currentAcademicCity = 1000;
            }
            let academicMonthStart = moment(editingPlace.monthStart, 'MM/YYYY').valueOf();
            let academicMonthEnd = moment(editingPlace.monthEnd, 'MM/YYYY').valueOf();

            let newIntCode = null;
            if (currentNewIntCodeToAcademicEntity?.length > 0) {
                var allLanguageCodes = CountryLanguage.getLanguages();
                newIntCode = allLanguageCodes?.find((language) => { return language.iso639_2en === currentNewIntCodeToAcademicEntity });
            }

            let facultySelected = editingPlace.facultyDetail;
            if (facultySelected == null && currentAcademicFaculty != null && editingAcademy.academyDetail?.faculties?.length > 0) {
                facultySelected = editingAcademy.academyDetail?.faculties?.find((f) => { return f.facultyId == currentAcademicFaculty })?.facultyDetail;
            }

            let editingDegrees = [];
            if (editingPlace.graduateDegrees?.length > 0) {
                editingDegrees = editingPlace.graduateDegrees.map((graduateDegree, index) => {
                    return {
                        order: index + 1,
                        graduateDegreeId: graduateDegree.graduateDegreeId,
                        graduateDiplomaInt: graduateDegree.graduateDiplomaInt,
                        facultySpecialtyId: graduateDegree.facultySpecialtyId,
                        graduationSpecialtyInt: graduateDegree.graduationSpecialtyInt,
                        dateDegree: graduateDegree.dateDegree?.length > 0 ? moment(graduateDegree.dateDegree, graduateDegree.dateDegree.includes('/') ? 'MM/YYYY' : 'YYYY-MM-dd').valueOf() : null,
                        underPreparation: graduateDegree.underPreparation ?? false
                    }
                });
            }

            this.setState({
                isEditing: true,
                isValidate: isValidate,
                academyNodeId: academyNodeId,
                currentCountryToAcademicEntity: currentCountryToAcademicEntity,
                statesOfCountry: statesOfCountry,
                currentStateOfCountryToAcademicEntity: currentStateOfCountryToAcademicEntity,
                currentNewIntCodeToAcademicEntity, currentNewIntCodeToAcademicEntity,
                newIntCode: newIntCode,
                currentAcademicEntity: currentAcademicEntity,
                facultySelected: facultySelected,
                selectedFacultyByAcademic: currentAcademicFaculty,
                currentAcademicFaculty: currentAcademicFaculty,
                currentAcademicCity: currentAcademicCity,
                currentAcademicCityName: currentAcademicCityName,
                currentAcademicEntityName: currentAcademicEntityName,
                academicMonthStart: academicMonthStart,
                academicMonthEnd: academicMonthEnd,

                editingDegrees: editingDegrees,

            }, () => {
                if (this.state.currentCountryToAcademicEntity != null) {
                    this.props.getAcademicsInCountry(this.state.currentCountryToAcademicEntity, {})
                }
                this.validationStepAddCountryToAcademicEntity()
            })
        }
    };

    // function Cancel Step
    cancelStep = () => {
        this.setState({
            academyNodeId: null,
            currentAcademicCityName: null,
            currentAcademicEntityName: null,
            newIntCode: null,
            academicEntitySelected: null,
            facultySelected: null,
            gradualteSelected: null,
            academicMonthStart: null,
            academicMonthEnd: null,
            currentNewIntCodeToAcademicEntity: null,
            currentCountryToAcademicEntity: null,
            currentAcademicEntity: null,
            selectedFacultyByAcademic: null,
            currentAcademicFaculty: null,
            currentAcademicCity: null,
            newAcademicCity: '',
            currentAcademicGraduateDegree: null,
            currentAcademicDiplomaInt: null,
            currentAcademicGraduationSpecialty: null,
            currentAcademicGraduationSpecialtyInt: null,
            currentStateOfCountryToAcademicEntity: null,
            dateOfDegree: null,
            facultyName: null,
            isValidate: false,
            cureentShowScreen: SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY,
            disabledNext: true
        }, () => {
            this.props.onClose(false);
        });
    };

    // function Cancel Step
    backStep = () => {
        var cureentShowScreen = this.state.cureentShowScreen;
        if (cureentShowScreen == SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE) {
            cureentShowScreen = SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY;
            this.setState({
                currentDegreeIndex: 0,
                cureentShowScreen: cureentShowScreen
            }, () => {
                this.validationStepAddAcademicEntityFaculty()
            });
        }
        else if (cureentShowScreen == SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY || cureentShowScreen == SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY_ORTHER) {
            cureentShowScreen = SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY;
            this.setState({
                cureentShowScreen: cureentShowScreen
            }, () => {
                this.validationStepAddCountryToAcademicEntity()
            });
        }
    };

    //   function of Step1
    validationStepAddCountryToAcademicEntity = () => {
        var disabledNext = true;
        if (this.state.currentCountryToAcademicEntity != null &&
            this.state.currentNewIntCodeToAcademicEntity != null) {
            disabledNext = false;
        }
        this.setState({
            disabledNext: disabledNext
        })
    };

    nextStepAddCountryToAcademicEntity = () => {
        var cureentShowScreen = SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY;

        let resetInput = true;
        if (this.props.editingAcademy != null) {

        }

        this.setState({
            cureentShowScreen: cureentShowScreen
        }, () => {
            this.validationStepAddAcademicEntityFaculty()
        });
    };

    onChangeStateOfCountryToAcademicEntity = (value, data, event, formattedValue) => {
        this.setState(
            {
                currentStateOfCountryToAcademicEntity: value.value,
            }, () => {
                this.validationStepAddCountryToAcademicEntity();
            }
        );
    };

    onChangeCountryToAcademicEntity = (value) => {
        let countryCode = value.value
        if (countryCode != null && this.state.currentCountryToAcademicEntity != countryCode) {
            this.props.getAcademicsInCountry(countryCode, {})
        }
        this.setState(
            {
                currentCountryToAcademicEntity: countryCode,
                currentStateOfCountryToAcademicEntity: null,
                statesOfCountry: value.states,
            }, () => {
                this.validationStepAddCountryToAcademicEntity();
            }
        );
    };

    onChangeNewIntCodeToAcademicEntity = (value) => {
        this.setState(
            {
                currentNewIntCodeToAcademicEntity: value.value,
                newIntCode: value.language,
            }, () => {
                this.validationStepAddCountryToAcademicEntity();
            }
        );
    };

    validationStepAddAcademicEntityFaculty = () => {
        const isValidAcademic = this.state.currentAcademicEntity === 'Others' ? this.state.currentAcademicEntityName?.length > 0 : this.state.currentAcademicEntity?.length > 0;
        let currentAcademicCity = this.state.currentAcademicEntity === 'Others' ? 1000 : this.state.currentAcademicCity;
        const isValidCity = currentAcademicCity == 1000 ? (this.state.currentAcademicCityName?.length > 0) : (currentAcademicCity != null);
        const isValidFaculty = this.state.selectedFacultyByAcademic === 'Other' ? (this.state.currentAcademicFaculty == 1000 ? this.state.facultyName?.length > 0 : this.state.currentAcademicFaculty?.length > 0) : (this.state.currentAcademicFaculty?.length > 0);
        const isValidMonthStart = this.state.academicMonthStart != null;
        const isValidMonthEnd = this.state.academicMonthEnd != null;

        const isValid = isValidAcademic && isValidCity && isValidFaculty && isValidMonthStart && isValidMonthEnd;

        this.setState({
            disabledNext: !isValid
        })
    };

    nextStepAddAcademicEntityFaculty = () => {
        var cureentShowScreen = SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE;

        let isNewFaculty = true;
        if (this.props.editingPlace?.facultyId != null) {
            let editingFaculty = this.props.editingPlace?.facultyId?.toString();
            if (this.state.currentAcademicEntity === this.props.editingAcademy?.academyId && this.state.currentAcademicFaculty === editingFaculty) {
                isNewFaculty = false
            }
        }
        let editingDegrees = isNewFaculty ? [] : this.state.editingDegrees;

        let currentAcademicDiplomaInt = null;
        let currentAcademicGraduationSpecialty = null;
        let currentAcademicGraduateDegree = null;
        let currentAcademicGraduationSpecialtyInt = null;
        let dateOfDegree = null;
        let underPreparation = false;
        if (editingDegrees.length > 0) {
            let editingDegree = editingDegrees[0];
            currentAcademicDiplomaInt = editingDegree.graduateDiplomaInt;
            currentAcademicGraduationSpecialty = editingDegree.facultySpecialtyId;
            currentAcademicGraduateDegree = editingDegree.graduateDegreeId;
            currentAcademicGraduationSpecialtyInt = editingDegree.graduationSpecialtyInt;
            dateOfDegree = editingDegree.dateDegree;
            underPreparation = editingDegree.underPreparation
        }

        this.setState({
            degrees: null,
            currentAcademicGraduationSpecialty: currentAcademicGraduationSpecialty,
            currentAcademicGraduationSpecialtyInt: currentAcademicGraduationSpecialtyInt,
            currentAcademicGraduateDegree: currentAcademicGraduateDegree,
            currentAcademicDiplomaInt: currentAcademicDiplomaInt,
            dateOfDegree: dateOfDegree,
            underPreparation: underPreparation,
            cureentShowScreen: cureentShowScreen
        }, () => {
            this.validationStepAddAcademicEntityGraduateDegree()
        });
    };

    handleAcademicStartDateInputChange = (value) => {
        this.setState({ academicMonthStart: value != null ? moment(value).valueOf() : null }, () => {
            this.validationStepAddAcademicEntityFaculty();
        });

    };

    handleAcademicEndDateInputChange = (value) => {
        this.setState({ academicMonthEnd: value != null ? moment(value).valueOf() : null }, () => {
            this.validationStepAddAcademicEntityFaculty();
        });
    };

    onChangeAcademicEntityName = (e) => {
        this.setState(
            {
                currentAcademicEntityName: e.target.value,
                academyNodeId: null,
            },
            function () {
                this.validationStepAddAcademicEntityFaculty();
            }
        );
    }

    onChangeAcademicEntity = (value, data, event, formattedValue) => {
        var nodeId = value.nodeId;
        if (value.entity?.name == 'Others') {
            nodeId = null;
        }

        this.setState(
            {
                academyNodeId: nodeId,
                currentAcademicEntity: value.value,
                academicEntitySelected: value.entity,
                currentAcademicEntityName: value.entity?.name,
                currentAcademicCity: value.value === 'Others' ? 1000 : null,
                currentAcademicCityName: null,
                selectedFacultyByAcademic: value.value === 'Others' ? 'Other' : null,
                currentAcademicFaculty: null,
                facultyName: null

            }, () => {
                if (value.entity?.name != 'Others') {
                    this.validationStepAddAcademicEntityFaculty();
                }
                else {
                    this.validationStepAddAcademicEntityFaculty()
                }
            });
    };

    onSelectFacultyByAcademic = (value) => {
        this.setState(
            {
                selectedFacultyByAcademic: value.value,
                currentAcademicFaculty: value.value === 'Other' ? null : value.value,
                facultySelected: value.faculty,
                facultyName: null
            }, () => {
                this.validationStepAddAcademicEntityFaculty();
            }
        );
    };

    onChangeAcademicFaculty = (value) => {
        this.setState(
            {
                currentAcademicFaculty: value.value,
                facultySelected: value.faculty,
                facultyName: null
            }, () => {
                this.validationStepAddAcademicEntityFaculty();
            }
        );
    };

    onChangeAcademicEntityFacultyName = (e) => {
        this.setState(
            {
                facultyName: e.target.value,
            },
            function () {
                this.validationStepAddAcademicEntityFaculty();
            }
        );
    }

    onChangeAcademicEntityCityName = (e) => {
        this.setState(
            {
                currentAcademicCityName: e.target.value,
            },
            function () {
                this.validationStepAddAcademicEntityFaculty();
            }
        );
    }

    onChangeAcademicCity = (value, data, event, formattedValue) => {
        this.setState(
            {
                currentAcademicCity: value.id,
                selectedFacultyByAcademic: value.value === 'Other' ? 'Other' : null,
                currentAcademicFaculty: null,
            }, () => {
                this.validationStepAddAcademicEntityFaculty();
            });
    };

    onChangeAcademicCityName = (e) => {
        this.setState(
            {
                newAcademicCity: e.target?.value ?? '',
            }, () => {
                this.validationStepAddAcademicEntityFaculty();
            });
    };

    nextStepAddAcademicEntityFacultyOther = () => {
        var cureentShowScreen = SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE;
        this.setState({
            cureentShowScreen: cureentShowScreen
        }, () => {
            this.validationStepAddAcademicEntityGraduateDegree()
        });
    };

    validationStepAddAcademicEntityGraduateDegree = () => {
        const isValidSpecialty = this.state.currentAcademicGraduationSpecialty != null;
        const isValidDegree = this.state.currentAcademicGraduateDegree != null;
        const isValidDegreeDate = this.state.underPreparation ? true : (this.state.dateOfDegree != null && !isNaN(this.state.dateOfDegree));

        this.setState({
            disabledNext: !isValidSpecialty || !isValidDegree || !isValidDegreeDate
        })
    };

    eraseGraduateDegree = () => {
        let t = this.props.t;
        customAlertPopup({
            title: t('Erase Graduate Degree'),
            message: t('This graduate degree will not be saved. Are you sure you want to erase this graduate degree?'),
            cancelTitle: t('No'),
            doneTitle: t('Yes'),
            handleCancel: () => { },
            handleDone: () => {
                let editingDegrees = this.state.editingDegrees ?? [];
                let currentDegreeIndex = this.state.currentDegreeIndex ?? 0;
                if (currentDegreeIndex < editingDegrees.length) {
                    editingDegrees.splice(currentDegreeIndex, 1);
                }
                this.setState({ editingDegrees: editingDegrees }, () => {
                    this.nextDegree(SCREEN_GRADUATE_DEGREE_ERASE)
                })
            }
        });
    }

    nextDegree = (typeNextDegree) => {
        let currentDegreeIndex = this.state.currentDegreeIndex ?? 0;
        let nextDegreeIndex = currentDegreeIndex + (typeNextDegree == SCREEN_GRADUATE_DEGREE_NEXT ? 1 : 0);

        var degrees = currentDegreeIndex == 0 ? [] : this.state.degrees;
        var valueDateDegree = null;
        var editingDegree = null;

        if (this.state.dateOfDegree != null) {
            let mm = moment(this.state.dateOfDegree);
            valueDateDegree = mm.format('MM/YYYY');
        }

        if (typeNextDegree == SCREEN_GRADUATE_DEGREE_NEXT || typeNextDegree == SCREEN_GRADUATE_DEGREE_SAVE_GD || typeNextDegree == SCREEN_GRADUATE_DEGREE_VALIDATE) {
            let degree = {
                order: degrees.length + 1,
                graduateDiplomaInt: this.state.currentAcademicDiplomaInt ?? null,
                facultySpecialtyId: this.state.currentAcademicGraduationSpecialty,
                graduateDegreeId: this.state.currentAcademicGraduateDegree,
                graduationSpecialtyInt: this.state.currentAcademicGraduationSpecialtyInt ?? null,
                underPreparation: this.state.underPreparation,
                dateDegree: this.state.underPreparation ? null : valueDateDegree,
            };
            degrees.push(degree);
        }

        //Find data
        if (typeNextDegree == SCREEN_GRADUATE_DEGREE_ERASE || typeNextDegree == SCREEN_GRADUATE_DEGREE_NEXT) {
            let isNewFaculty = true;
            if (this.props.editingPlace?.facultyId != null) {
                let editingFaculty = this.props.editingPlace?.facultyId?.toString();
                if (this.state.currentAcademicEntity === this.props.editingAcademy?.academyId && this.state.currentAcademicFaculty === editingFaculty) {
                    isNewFaculty = false
                }
            }
            let editingDegrees = isNewFaculty ? [] : this.state.editingDegrees;
            if (editingDegrees.length > 0 && nextDegreeIndex < editingDegrees.length) {
                editingDegree = editingDegrees[nextDegreeIndex];
            }
        }

        //Fillter editing data for next degree
        let currentAcademicDiplomaInt = null;
        let currentAcademicGraduationSpecialty = null;
        let currentAcademicGraduateDegree = null;
        let currentAcademicGraduationSpecialtyInt = null;
        let dateOfDegree = null;
        let underPreparation = false;
        if (editingDegree != null) {
            currentAcademicDiplomaInt = editingDegree.graduateDiplomaInt;
            currentAcademicGraduationSpecialty = editingDegree.facultySpecialtyId;
            currentAcademicGraduateDegree = editingDegree.graduateDegreeId;
            currentAcademicGraduationSpecialtyInt = editingDegree.graduationSpecialtyInt;
            dateOfDegree = editingDegree.dateDegree;
            underPreparation = editingDegree.underPreparation
        }

        //apply next degree data and move to next step (edit next degree or validate)
        this.setState({
            degrees: degrees,
            currentDegreeIndex: nextDegreeIndex,
            currentAcademicDiplomaInt: currentAcademicDiplomaInt,
            currentAcademicGraduationSpecialty: currentAcademicGraduationSpecialty,
            currentAcademicGraduateDegree: currentAcademicGraduateDegree,
            currentAcademicGraduationSpecialtyInt: currentAcademicGraduationSpecialtyInt,
            dateOfDegree: dateOfDegree,
            underPreparation: underPreparation
        }, () => {
            this.validationStepAddAcademicEntityGraduateDegree();
            if (typeNextDegree == SCREEN_GRADUATE_DEGREE_SKIP || typeNextDegree == SCREEN_GRADUATE_DEGREE_SAVE_GD || typeNextDegree == SCREEN_GRADUATE_DEGREE_SAVE_F || typeNextDegree == SCREEN_GRADUATE_DEGREE_VALIDATE) {
                let isValidate = typeNextDegree == SCREEN_GRADUATE_DEGREE_VALIDATE ? true : (this.state.isValidate ?? false);
                if (degrees.length < this.state.editingDegrees.length) {
                    for (var idx = degrees.length; idx < this.state.editingDegrees.length; idx++) {
                        let editingDegree = this.state.editingDegrees[idx];

                        var valueDateDegree = null;                
                        if (editingDegree.dateDegree != null) {
                            let mm = moment(editingDegree.dateDegree);
                            valueDateDegree = mm.format('MM/YYYY');
                        }

                        let degree = {
                            order: degrees.length + 1,
                            graduateDiplomaInt: editingDegree.graduateDiplomaInt,
                            facultySpecialtyId: editingDegree.facultySpecialtyId,
                            graduateDegreeId: editingDegree.graduateDegreeId,
                            graduationSpecialtyInt: editingDegree.graduationSpecialtyInt,
                            underPreparation: editingDegree.underPreparation,
                            dateDegree: valueDateDegree,
                        };
                        degrees.push(degree);                    
                    }
                }

                this.setState({ degrees:degrees, isValidate: isValidate }, () => { this.createAcademic() });
            }
        });
    };

    createAcademic = () => {
        var academic = {}
        academic.isValidate = this.state.isValidate;
        if (this.props.editingAcademy != null) {
            academic.subAcademyId = this.props.editingAcademy?.id.toString();
            academic.placeId = this.props.editingPlace?.id.toString();
        }
        academic.facultyId = this.state.currentAcademicFaculty;
        academic.language = this.state.currentNewIntCodeToAcademicEntity;
        academic.country = this.state.currentCountryToAcademicEntity;
        academic.academyId = this.state.currentAcademicEntity;
        academic.academyNodeId = this.state.academyNodeId;
        academic.stateCode = this.state.currentStateOfCountryToAcademicEntity;
        academic.facultyName = this.state.facultyName;
        var dateStart = null;
        var valueDateStart = null;
        var dateEnd = null;
        var valueDateEnd = null;
        if (this.state.academicMonthStart != null && this.state.academicMonthStart != null) {
            dateStart = moment(this.state.academicMonthStart);
            valueDateStart = dateStart.format('MM/YYYY');
            academic.monthStart = valueDateStart;
        }
        if (this.state.academicMonthEnd != null && this.state.academicMonthEnd != null) {
            dateEnd = moment(this.state.academicMonthEnd);
            valueDateEnd = dateEnd.format('MM/YYYY');
            academic.monthEnd = valueDateEnd;
        }

        if (this.state.currentAcademicCity == 1000) {
            academic.academyNewCityIntl = this.state.currentAcademicCityName;
        }
        else {
            academic.cityId = this.state.currentAcademicCity;
        }

        if (this.state.currentAcademicEntity === 'Others') {
            academic.academyName = this.state.currentAcademicEntityName;
        }

        academic.degrees = this.state.degrees;

        this.props.createAcademic(academic, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                this.props.onClose(true)
            },
        })
    };

    dateOfDegreeChange = (value) => {
        this.setState({ dateOfDegree: value != null ? moment(value).valueOf() : null }, () => {
            this.validationStepAddAcademicEntityGraduateDegree();
        });
    };

    onChangeAcademicGraduateDegree = (value, data, event, formattedValue) => {
        this.setState(
            {
                currentAcademicGraduateDegree: value.value,
            }, () => {
                this.validationStepAddAcademicEntityGraduateDegree()
            });
    };

    onChangeAcademicDiplomaInt = (e) => {
        this.setState(
            {
                currentAcademicDiplomaInt: e.target.value,
            },
            function () {
                this.validationStepAddAcademicEntityGraduateDegree();
            }
        );
    };

    onChangeAcademicGraduateSpecialty = (value, data, event, formattedValue) => {
        this.setState(
            {
                currentAcademicGraduationSpecialty: value.value,
            }, () => {
                this.validationStepAddAcademicEntityGraduateDegree()
            });
    };

    onChangeAcademicGraduateSpecialtyInt = (e) => {
        this.setState(
            {
                currentAcademicGraduationSpecialtyInt: e.target.value,
            },
            function () {
                this.validationStepAddAcademicEntityGraduateDegree();
            }
        );
    };

    onchangePreparationStatus = () => {
        this.setState(
            {
                underPreparation: !this.state.underPreparation,
            },
            function () {
                this.validationStepAddAcademicEntityGraduateDegree();
            }
        );
    };

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    countryCodeToString = (code) => {
        const countries = this.props.countries;
        const c = countries.filter((e) => e.value === code);
        return c.length > 0 ? c[0].label : code;
    };

    stateCodeToString = (countryCode, stateCode) => {
        const countries = this.props.countries;

        const c = countries.find((e) => e.value === countryCode);
        var state = stateCode;
        if (c.states) {
            const states = c?.states?.map((element, i) => {
                return { value: element.id, label: getLocalizedString(element.name) };
            });
            state = states.find((e) => e.value === stateCode);
        }

        return (state ? state.label : stateCode) + ', ';
    };

    render() {
        const { t, } = this.props;

        let title = (this.props.readOnly ?? false) ? t('academic.educationDetails') : (t(this.props.editingAcademy != null ? 'academic.titleEditStep1' : 'academic.titleStep1'));
        const validateVisible = this.state.currentDegreeIndex >= this.state.editingDegrees.length - 1;

        return (
            <div>
                <Col md={12}>
                    {this.props.loading && <Loader />}
                    <this.Alert error={this.props.error} />
                </Col>
                {this.state.cureentShowScreen === SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY &&
                    <StepAddCountryToAcademic
                        readOnly={this.props.readOnly ?? false}
                        title={title}
                        isEditing={this.state.isEditing}
                        nextStepAddCountryToAcademicEntity={this.nextStepAddCountryToAcademicEntity}
                        backStep={this.backStep}
                        cancelStep={this.cancelStep}
                        onChangeCountryToAcademicEntity={this.onChangeCountryToAcademicEntity}
                        onChangeNewIntCodeToAcademicEntity={this.onChangeNewIntCodeToAcademicEntity}
                        onChangeStateOfCountryToAcademicEntity={this.onChangeStateOfCountryToAcademicEntity}
                        countryCodeToString={this.countryCodeToString}
                        disabledNext={this.state.disabledNext}
                        countries={this.props.countries}
                        statesOfCountry={this.state.statesOfCountry}
                        currentStateOfCountryToAcademicEntity={this.state.currentStateOfCountryToAcademicEntity}
                        currentCountryToAcademicEntity={this.state.currentCountryToAcademicEntity}
                        currentNewIntCodeToAcademicEntity={this.state.currentNewIntCodeToAcademicEntity} />
                }

                {(this.state.cureentShowScreen === SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY || this.state.cureentShowScreen === SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY_ORTHER) &&
                    <StepAddAcademicEntityFaculty
                        readOnly={this.props.readOnly ?? false}
                        title={title}
                        isEditing={this.state.isEditing}
                        academicsInCountry={this.props.academicsInCountry}
                        currentCountryToAcademicEntity={this.state.currentCountryToAcademicEntity}
                        currentStateOfCountryToAcademicEntity={this.state.currentStateOfCountryToAcademicEntity}
                        currentAcademicEntity={this.state.currentAcademicEntity}
                        currentAcademicEntityName={this.state.currentAcademicEntityName}
                        currentAcademicCity={this.state.currentAcademicCity}
                        currentAcademicCityName={this.state.currentAcademicCityName}
                        selectedFacultyByAcademic={this.state.selectedFacultyByAcademic}
                        currentAcademicFaculty={this.state.currentAcademicFaculty}
                        academicMonthStart={this.state.academicMonthStart}
                        academicMonthEnd={this.state.academicMonthEnd}
                        facultyDoc={this.props.facultyDoc}
                        countries={this.props.countries}
                        nextDegree={this.nextDegree}
                        nextStepAddAcademicEntityFaculty={this.nextStepAddAcademicEntityFaculty}
                        backStep={this.backStep}
                        cancelStep={this.cancelStep}
                        facultyName={this.state.facultyName}
                        onChangeAcademicEntity={this.onChangeAcademicEntity}
                        onChangeAcademicEntityName={this.onChangeAcademicEntityName}
                        onChangeAcademicCity={this.onChangeAcademicCity}
                        onChangeAcademicEntityCityName={this.onChangeAcademicEntityCityName}
                        onSelectFacultyByAcademic={this.onSelectFacultyByAcademic}
                        onChangeAcademicFaculty={this.onChangeAcademicFaculty}
                        onChangeAcademicEntityFacultyName={this.onChangeAcademicEntityFacultyName}
                        handleAcademicStartDateInputChange={this.handleAcademicStartDateInputChange}
                        handleAcademicEndDateInputChange={this.handleAcademicEndDateInputChange}
                        countryCodeToString={this.countryCodeToString}
                        newIntCode={this.state.newIntCode}
                        disabledNext={this.state.disabledNext}
                    />
                }

                {/* {this.state.cureentShowScreen === SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_FACULTY_ORTHER &&
                    <StepAddAcademicEntityGraduateDegree
                        nextStepAddCountryToAcademicEntity={this.nextStepAddCountryToAcademicEntity} />
                } */}

                {this.state.cureentShowScreen === SCREEN_ADD_COUNTRY_ACADEMIC_ENTITY_GRADUATE_DEGREE &&
                    <StepAddAcademicEntityGraduateDegree
                        readOnly={this.props.readOnly ?? false}
                        hideNextButton={(this.props.readOnly ?? false) && this.state.currentDegreeIndex >= this.state.editingDegrees.length - 1}
                        title={title}
                        countries={this.props.countries}
                        currentStateOfCountryToAcademicEntity={this.state.currentStateOfCountryToAcademicEntity}
                        graduateDegreeDoc={this.props.graduateDegreeDoc}
                        degrees={this.state.degrees}
                        eraseGraduateDegree={this.eraseGraduateDegree}
                        nextDegree={this.nextDegree}
                        backStep={this.backStep}
                        cancelStep={this.cancelStep}
                        onChangeAcademicGraduateDegree={this.onChangeAcademicGraduateDegree}
                        onChangeAcademicDiplomaInt={this.onChangeAcademicDiplomaInt}
                        onChangeAcademicGraduateSpecialty={this.onChangeAcademicGraduateSpecialty}
                        onChangeAcademicGraduateSpecialtyInt={this.onChangeAcademicGraduateSpecialtyInt}
                        countryCodeToString={this.countryCodeToString}
                        dateOfDegreeChange={this.dateOfDegreeChange}
                        dateOfDegree={this.state.dateOfDegree}
                        underPreparation={this.state.underPreparation}
                        onchangePreparationStatus={this.onchangePreparationStatus}
                        currentAcademicEntity={this.state.currentAcademicEntity}
                        currentAcademicEntityName={this.state.currentAcademicEntityName}
                        newIntCode={this.state.newIntCode}
                        academicEntitySelected={this.state.academicEntitySelected}
                        facultySelected={this.state.facultySelected}
                        facultyName={this.state.facultyName}
                        currentCountryToAcademicEntity={this.state.currentCountryToAcademicEntity}
                        currentAcademicGraduateDegree={this.state.currentAcademicGraduateDegree}
                        currentAcademicDiplomaInt={this.state.currentAcademicDiplomaInt}
                        currentAcademicGraduationSpecialty={this.state.currentAcademicGraduationSpecialty}
                        currentAcademicGraduationSpecialtyInt={this.state.currentAcademicGraduationSpecialtyInt}
                        disabledNext={this.state.disabledNext}
                        validateVisible={validateVisible}
                        />
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { academicsInCountry, loading, error } = state.Academics;
    return { academicsInCountry, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    createAcademic,
    getAcademicsInCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NewCareerCompany));
