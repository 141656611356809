import React, { Component } from 'react';
import { CheckCircle, Edit, PlusCircle, PlusSquare, Star, Trash, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { getLocalizedString, monthOnlyDisplayString, countryCodeToString, customAlertPopup, colorForValidationStatuses, locationString, formatDateString, textColorForValidationStatus } from '../../../../../helpers/utils';
import {
    changeMenuType,
    getCareerCompanies,
    getAPICountryInfo,
    getAllContentsCompany,
    getAcademics,
    changeSidebarType,
    getSubscriptionProfile,
    setTopBarTitle,
    getAcademicDoc,
    deleteEducation,
    initMenuWithMenuItems
} from '../../../../../redux/actions';
import UserInfo from '../UserInfo';
import { Redirect } from 'react-router';
import * as layoutConstants from '../../../../../constants/layout';
import { getSidebarWidth, setLoggedInUserProfile } from '../../../../../helpers/authUtils';
import NewAcademic from './AddNewAcademic/NewAcademic';
import { AcademicsIcon, Requested, ValidationRequest } from '../../../../../components/Icons';
import SVG from 'react-inlinesvg';
import { VALIDATION_DOCUMENT_EDUCATION, VALIDATION_STATUS_REJECTED, VALIDATION_STATUS_SUBMITTED, VALIDATION_STATUS_VALIDATED } from '../../../../../redux/validation/constants';
import RequestValidationModal from '../../Background/Validations/RequestValidationModal';
import EducationDetails from '../../../Home/Education/EducationDetails';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import AddEducationModal from './AddEducationModal';
import SubmitValidationRequestModal from '../../../Home/Network/SubmitValidationRequestModal';
import { format } from '../../../../../helpers/stringFormat';
import moment from 'moment';

export const ValidationCount = ({ t, id, className, status, count }) => {
    let color = 'bg-color-gray';
    let hover = t('me.validations');
    switch (status) {
        case VALIDATION_STATUS_SUBMITTED:
            color = 'bg-color-orange';
            hover = t('validation.status.waiting');
            break;
        case VALIDATION_STATUS_VALIDATED:
            color = 'bg-color-green';
            hover = t('me.validations');
            break;
        case VALIDATION_STATUS_REJECTED:
            color = 'bg-color-red';
            hover = t('validation.status.rejected');
            break;
        default:
            break;
    }

    return (
        <div id={id} className={className + ' box-center'}>
            <div className={color + ' multilayer size-px-16 rounded-8'}>
                <Label className={'text-color-white font-weight-bold centered mt-0 mb-0'} style={{ fontSize: '12px', height: '10px', lineHeight: '10px' }}>{count}</Label>
            </div>
            <UncontrolledTooltip placement="bottom" target={id}>
                {hover}
            </UncontrolledTooltip>
        </div>

    )
}

class Educations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            className: 'custom-modal-style',
            showInterface: false,
            showAcademics: false,
            academyIdSelected: '',
            companyIdSelected: '',
            academics: [],
            academySelected: null,
            isAddingNewEducation: false,
            showRequestValidationDialog: false,
            targetElement: null,
            subElement: null,
            editingAcademy: null,
            editingPlace: null,
            readOnly: false,
            showEducationDetails: false,
            viewAcademicDetails: false,
        };
    }

    componentDidMount = () => {
        this.props.setTopBarTitle(this.props.t('topBar.home'));
        this.props.changeMenuType(MENU_TYPE_HOME, {});

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains("sidebar-enable"))
                    document.body.classList.remove("sidebar-enable");
                changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains("left-side-menu-condensed"))
                    document.body.classList.remove("left-side-menu-condensed");
                document.body.classList.add("sidebar-enable");
            }
        }
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
        this.props.getAcademicDoc({});
        this.initUi();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location?.pathname?.length > 16) {
            let id = this.props.location.pathname.substring(16);
            if (id.length > 0 && this.state.academyIdSelected != id) {
                this.setState({ academyIdSelected: id });
            }
        }
    }

    initUi = () => {
        if (this.props.userId == null && this.props.subscriptionProfile == null) {
            this.props.getSubscriptionProfile({
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    this.setState(
                        {
                            showAPI: false,
                        });
                },
            });
        }
    }

    countryCodeToString = (countryCode) => {
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });
        const c = countries.filter((e) => e.value === countryCode);

        var country = c.length > 0 ? c[0].label : countryCode;

        if (typeof c[0]?.states !== 'undefined' && c[0].states !== null) {
            country += ' / ';
            const stateCode =
                this.props.subscriptionProfile && this.props.subscriptionProfile.user && this.props.subscriptionProfile.user.state
                    ? this.props.subscriptionProfile.user.state
                    : '';
            const state = c[0].states.filter((e) => e.id === stateCode);

            if (state.length > 0) {
                const s = getLocalizedString(state[0].name);
                country += s.length > 0 ? s : stateCode;
            }
        }
        return country;
    };

    onClickAcademic = (academic) => {
        if (this.props.userId == null) {
            this.setState({
                academySelected: academic
            }, () => {
                if (this.props.history) {
                    this.props.history.push(academic ? { pathname: "/education-info/" + this.state.academySelected?.id ?? '' } : { pathname: "/education" });
                }
            });
        }
    }

    onClose = (response) => {
        this.setState({
            readOnly: false,
            editingAcademy: null,
            isAddingNewEducation: false,
            viewAcademicDetails: false
        }, () => {
            if (response) {
                setLoggedInUserProfile(this.props.subscriptionProfile);
            }
        });
    };

    closeRequestValidationModal = () => {
        this.setState({
            showRequestValidationDialog: false
        })
    }

    requestValidationModalSuccess = () => {
        this.setState({
            showRequestValidationDialog: false
        }, () => {
            this.props.getSubscriptionProfile({});
        });
    }

    requestValidationModalFalse = (message) => {
        this.setState({
            showRequestValidationDialog: false
        }, () => {

        });
    }

    summaryOfAcademyPlace = (academy, place) => {
        if (academy == null || place == null) {
            return ['', ''];
        }

        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
                cities: element.cities ? element.cities : null,
            };
        });

        let period = place?.monthStart ? monthOnlyDisplayString(place.monthStart) : '';
        let monthEnd = (place?.monthEnd ? monthOnlyDisplayString(place.monthEnd) : '')
        if (monthEnd.length > 0) {
            period += (period.length > 0 ? ' - ' : '') + monthEnd;
        }

        let cityName = '';
        if (place?.cityId != null && academy?.academyDetail?.cities?.length > 0) {
            cityName = academy?.academyDetail?.cities.find((city) => { return city.id == place?.cityId })?.city;
        }
        else {
            cityName = place.academyNewCityIntl;
        }
        let location = locationString(academy.country, place.state, cityName, countries);

        let facultyName = place?.facultyId != 1000 ? (place?.facultyDetail?.value ? (getLocalizedString(place.facultyDetail.value)) : '') : (academy?.facultyName ?? '');

        let defaultInfo = period;
        if (location.length > 0) {
            defaultInfo += (defaultInfo.length > 0 ? ' | ' : '') + location;
        }
        if (facultyName.length > 0) {
            defaultInfo += (defaultInfo.length > 0 ? ' | ' : '') + facultyName;
        }

        if (defaultInfo.length == 0) {
            defaultInfo = this.props.t('message.noInformation');
        }

        let graduateDegrees = [];

        (place?.graduateDegrees ?? []).forEach((degree) => {
            var specialties = place?.facultyDetail?.specialties?.find((op) => {
                return op.order?.toString() === (degree.facultySpecialtyId?.toString());
            })?.value;

            let defaultGraduateDegree = degree?.graduateDegreeDetail?.value ? getLocalizedString(degree?.graduateDegreeDetail?.value) : '';
            let graduateDegree = degree.graduateDiplomaInt ?? '';
            if (graduateDegree.length == 0) {
                graduateDegree = defaultGraduateDegree;
            }
            else {
                graduateDegree += defaultGraduateDegree.length > 0 ? (' (' + defaultGraduateDegree + ')') : '';
            }

            let defaultSpecialty = specialties ? getLocalizedString(specialties) : '';
            let specialty = degree.graduationSpecialtyInt ?? '';
            if (specialty.length == 0) {
                specialty = defaultSpecialty;
            }
            else {
                specialty += defaultSpecialty.length > 0 ? (' (' + defaultSpecialty + ')') : '';
            }

            let graduateDegreeDetails = graduateDegree;
            if (specialty.length > 0) {
                graduateDegreeDetails += (graduateDegreeDetails.length > 0 ? ' , ' : '') + specialty;
            }

            let summary = degree.dateDegree ? (monthOnlyDisplayString(degree.dateDegree) ?? '') : this.props.t('academic.underPreparation');
            if (graduateDegreeDetails.length > 0) {
                summary += (summary.length > 0 ? ' | ' : '') + graduateDegreeDetails;
            }

            graduateDegrees.push({ summary: summary, entity: degree });
        });

        return [defaultInfo, graduateDegrees];
    }

    listModule = () => {
        let { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
                cities: element.cities ? element.cities : null,
            };
        });

        let subscriptionProfile = this.props.userId != null ? this.props.personalSubscription : this.props.subscriptionProfile;
        let academics = subscriptionProfile?.academics?.academics?.filter((academy) => { return academy.academyPlaces?.length > 0 });
        if (academics) {
            academics.map(element => {
                element.academyPlaces.sort((a, b) => moment(a.monthStart, 'MM/YYYY').valueOf() < moment(b.monthStart, 'MM/YYYY').valueOf() ? 1 : -1);
                return element;
            });

            academics.sort((a, b) => moment(a.academyPlaces[0].monthStart, 'MM/YYYY').valueOf() < moment(b.academyPlaces[0].monthStart, 'MM/YYYY').valueOf() ? 1 : -1);
        }


        if (this.state.academyIdSelected) {
            academics = academics?.filter((academy) => { return academy.id == this.state.academyIdSelected })
        }
        const facultyDoc = this.props.academicDoc?.facultyDoc?.academyFaculties;

        const graduateDegreeDoc = this.props.academicDoc?.graduateDegreesDoc?.academyGraduateDegrees;

        return this.state.viewAcademicDetails ?
            <EducationDetails
                countries={countries}
                facultyDoc={facultyDoc}
                graduateDegreeDoc={graduateDegreeDoc}
                academics={academics}
                editingAcademy={this.state.editingAcademy}
                editingPlace={this.state.editingPlace}
                onClose={this.onClose}
            />
            :
            <div>
                {academics && academics.map((academy, acIdx) => {
                    let academyDetail = academy?.academyDetail;
                    return (
                        <div key={acIdx} className="ml-3 mr-4">
                            <br />
                            <Row className="path-component">
                                <Col md={12} xl={12}>
                                    <Label className={"link font-weight-bold company-name mt-0 mb-0 ml-3" + (this.props.userId != null ? '' : ' selectable')} onClick={() => this.onClickAcademic(academy)}>
                                        {academyDetail?.name ? academyDetail.name : ''}
                                    </Label>
                                </Col>
                            </Row>

                            {academy.academyPlaces && academy.academyPlaces.map((place, plcIdx) => {
                                let summary = this.summaryOfAcademyPlace(academy, place);

                                let validations = place.validationsStatus ?? [];
                                let submitted = validations.filter((element) => { return element.status == 'SUBMITTED' });
                                let validated = validations.filter((element) => { return element.status == 'VALIDATED' });
                                let rejected = validations.filter((element) => { return element.status == 'REJECTED' });
                                let color = colorForValidationStatuses(validations, validated, rejected);

                                let validationStatus = t('validation.status.noValidation');
                                if (validated.length > 0) {
                                    validationStatus = t('validation.status.validated');
                                }
                                else if (rejected.length > 0) {
                                    validationStatus = t('validation.status.rejected');
                                }
                                else if (validations.length > 0) {
                                    validationStatus = t('validation.status.underProcess');
                                }

                                return (
                                    <div key={plcIdx}>
                                        <Row className="align-horizontal">
                                            <div id={"check-status" + acIdx + plcIdx}>
                                                <CheckCircle className="" color={color} size={18} />
                                                <UncontrolledTooltip placement="bottom" target={"check-status" + acIdx + plcIdx}>
                                                    {validationStatus}
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className='align-horizontal mb-2 ml-1' onClick={() => {
                                                this.setState({
                                                    editingAcademy: academy,
                                                    editingPlace: place,
                                                    viewAcademicDetails: true,
                                                });
                                            }}>
                                                <Label className="cursor-pointer ml-1 mb-0">{summary[0]}</Label>
                                            </div>

                                            <div className="ml-2 align-horizontal box-center align-items-flex-start">
                                                {(validated.length > 0 || submitted.length || rejected.length > 0) &&
                                                    <Label className="mr-2">|</Label>
                                                }
                                                {validated.length > 0 &&
                                                    <ValidationCount t={this.props.t} id={"validation-validated" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_VALIDATED} count={validated.length} />
                                                }

                                                {submitted.length > 0 &&
                                                    <ValidationCount t={this.props.t} id={"validation-submited" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_SUBMITTED} count={submitted.length} />
                                                }

                                                {rejected.length > 0 &&
                                                    <ValidationCount t={this.props.t} id={"validation-rejected" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_REJECTED} count={rejected.length} />
                                                }
                                                {this.props.userId == null &&
                                                    <div className='align-horizontal'>
                                                        {(place.isValidate ?? false) &&
                                                            <div className='align-horizontal'>
                                                                {(validations.length > 0 || validated.length > 0 || rejected.length > 0) &&
                                                                    <Label className="mr-1">|</Label>
                                                                }
                                                                <div className='box-center align-horizontal' id={"request-validation" + acIdx + plcIdx}>
                                                                    <SVG src={ValidationRequest} className="ml-1 size-px-20 cursor-pointer" onClick={(e) => {
                                                                        this.setState({ targetElement: academy, subElement: place, showRequestValidationDialog: true });
                                                                    }} />
                                                                    <UncontrolledTooltip placement="bottom" target={"request-validation" + acIdx + plcIdx}>
                                                                        {t('validation.requestValidation')}
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div>
                                                        }

                                                        {!(place.isValidate ?? false) &&
                                                            <div className='draft-icon ml-2'>
                                                                <Label style={{ 'transform': 'translateY(-1px)' }}>{t('Draft')}</Label>
                                                            </div>
                                                        }
                                                        <span className="ml-2" onClick={() => {
                                                            this.setState({
                                                                editingAcademy: academy,
                                                                editingPlace: place,
                                                                isAddingNewEducation: true,
                                                            });
                                                        }}>
                                                            <Edit className="icon-dual ml-2 cursor-pointer icon-action" id={"edit-career" + acIdx + plcIdx} />
                                                            <UncontrolledTooltip placement="bottom" target={"edit-career" + acIdx + plcIdx}>
                                                                {t('me.hover.editAcademic')}
                                                            </UncontrolledTooltip>
                                                        </span>

                                                        <span onClick={() => {
                                                            customAlertPopup({
                                                                title: format(t('alert.deleteTitle'), t('academic.academics')),
                                                                message: format(t('alert.deleteMessage'), t('academic.academics')),
                                                                cancelTitle: t('No'),
                                                                doneTitle: t('Yes'),
                                                                handleCancel: () => { },
                                                                handleDone: () => {
                                                                    if (academy.academyId && academy.nodeId && place.id) {
                                                                        this.props.deleteEducation(academy.academyId, academy.nodeId, place.id, {
                                                                            callbackOnSuccess: (response) => {
                                                                                setLoggedInUserProfile(this.props.subscriptionProfile);
                                                                                this.setState({ isAddingNewEducation: false });
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            });
                                                        }}>
                                                            <Trash className="icon-dual ml-3  cursor-pointer icon-action" id={"delete-career" + acIdx + plcIdx} />
                                                            <UncontrolledTooltip placement="bottom" target={"delete-career" + acIdx + plcIdx}>
                                                                {t('me.hover.deleteEducation')}
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </Row>

                                        {this.state.showEducationDetails && summary[1].length > 0 &&
                                            <Row className='bg-color-details align-vertical mb-3'>
                                                <div className='align-vertical mb-1'>
                                                    {summary[1].length > 0 && summary[1].map((degree, dIndex) => {

                                                        let validationsDegree = degree.entity?.validationsStatus ?? [];
                                                        let submittedDegree = validationsDegree.filter((element) => { return element.status == 'SUBMITTED' });
                                                        let validatedDegree = validationsDegree.filter((element) => { return element.status == 'VALIDATED' });
                                                        let rejectedDegree = validationsDegree.filter((element) => { return element.status == 'REJECTED' });

                                                        return (
                                                            <Row className='ml-3 mb-1 mt-2'>
                                                                <Col className='align-horizontal'>
                                                                    <Label className='mt-0 mb-0'>{degree.summary}</Label>
                                                                    {(place.isValidate ?? false) &&
                                                                        <div className="ml-2 align-horizontal box-center align-items-flex-start">
                                                                            <Label className="mt-0 mb-0 mr-2">|</Label>

                                                                            {validatedDegree.length > 0 &&
                                                                                <ValidationCount t={this.props.t} id={"validation-validated" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_VALIDATED} count={validatedDegree.length} />
                                                                            }

                                                                            {submittedDegree.length > 0 &&
                                                                                <ValidationCount t={this.props.t} id={"validation-submited" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_SUBMITTED} count={submittedDegree.length} />
                                                                            }

                                                                            {rejectedDegree.length > 0 &&
                                                                                <ValidationCount t={this.props.t} id={"validation-rejected" + acIdx + plcIdx} className='mr-2' status={VALIDATION_STATUS_REJECTED} count={rejectedDegree.length} />
                                                                            }
                                                                            {(place.isValidate ?? false) &&
                                                                                <div className='align-horizontal'>
                                                                                    {(validationsDegree.length > 0 || validatedDegree.length > 0 || rejectedDegree.length > 0) &&
                                                                                        <Label className="mt-0 mb-0 mr-1">|</Label>
                                                                                    }
                                                                                    <div className='box-center align-horizontal' id={'degree-validation' + acIdx + plcIdx + dIndex}>
                                                                                        <SVG src={ValidationRequest} className="ml-1 size-px-20 cursor-pointer" onClick={(e) => {
                                                                                            this.setState({ targetElement: academy, subElement: place, childElement: degree.entity, showRequestValidationDialog: true });
                                                                                        }} />
                                                                                        <UncontrolledTooltip placement="bottom" target={'degree-validation' + acIdx + plcIdx + dIndex}>
                                                                                            {t('validation.requestValidation')}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        // <span className='ml-2' id={'degree-validation' + acIdx + plcIdx + dIndex} onClick={() => {
                                                                        //     this.setState({ targetElement: academy, subElement: place, childElement: degree.entity, showRequestValidationDialog: true });
                                                                        // }}>
                                                                        //     <SVG src={ValidationRequest} className="size-px-20 cursor-pointer" />
                                                                        //     <UncontrolledTooltip
                                                                        //         placement="top"
                                                                        //         id='tooltip-1'
                                                                        //         target={'degree-validation' + acIdx + plcIdx + dIndex}>
                                                                        //         {t("experience.hover.requestValidation")}
                                                                        //     </UncontrolledTooltip>
                                                                        // </span>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }, this)}
                                                </div>
                                            </Row>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    );
                }, this)}
            </div>
    }

    render() {
        const { t } = this.props;

        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
                cities: element.cities ? element.cities : null,
            };
        });

        let subscriptionProfile = this.props.userId != null ? this.props.personalSubscription : this.props.subscriptionProfile;
        let academics = subscriptionProfile?.academics?.academics?.filter((academy) => { return academy.academyPlaces?.length > 0 });
        if (academics) {
            academics.map(element => {
                element.academyPlaces.sort((a, b) => moment(a.monthStart, 'MM/YYYY').valueOf() < moment(b.monthStart, 'MM/YYYY').valueOf() ? 1 : -1);
                return element;
            });

            academics.sort((a, b) => moment(a.academyPlaces[0].monthStart, 'MM/YYYY').valueOf() < moment(b.academyPlaces[0].monthStart, 'MM/YYYY').valueOf() ? 1 : -1);
        }


        if (this.state.academyIdSelected) {
            academics = academics?.filter((academy) => { return academy.id == this.state.academyIdSelected })
        }
        const facultyDoc = this.props.academicDoc?.facultyDoc?.academyFaculties;

        const graduateDegreeDoc = this.props.academicDoc?.graduateDegreesDoc?.academyGraduateDegrees;

        let title = (this.state.readOnly ?? false) ? t('academic.educationDetails') : (t(this.state.editingAcademy != null ? 'academic.titleEditStep1' : 'academic.titleStep1'));

        return this.props.userId != null ?
            <div className='ml-3 mb-3'>
                {this.listModule()}
            </div>
            :
            <div xl={12}>
                {this.state.showInterface && (
                    <Redirect
                        to={{
                            pathname: '/interface'
                        }}
                    />
                )}
                <Row className='step-company'>
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                            history={this.props.history}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        {this.state.showRequestValidationDialog &&
                            <SubmitValidationRequestModal
                                type={VALIDATION_DOCUMENT_EDUCATION}
                                item1stLevelDoc={this.props.academicDoc}
                                item1stLevel={this.state.targetElement}
                                item2ndLevel={this.state.subElement}
                                item3rdLevel={this.state.childElement}
                                modal={this.state.showRequestValidationDialog}
                                onClose={(response) => {
                                    this.setState({ showRequestValidationDialog: false, targetElement: null, subElement: null, childElement: null }, () => {
                                        if (response) {
                                            this.props.getSubscriptionProfile({});
                                        }
                                    })
                                }}
                            />
                        }

                        {this.state.isAddingNewEducation &&
                            <AddEducationModal
                                countries={countries}
                                facultyDoc={facultyDoc}
                                graduateDegreeDoc={graduateDegreeDoc}
                                editingAcademy={this.state.editingAcademy}
                                editingPlace={this.state.editingPlace}
                                modal={this.state.isAddingNewEducation}
                                onClose={this.onClose}
                            />
                        }

                        <div>
                            <Row>
                                <Col md={8} xl={8}>
                                    <div className='path-component align-horizontal mb-2'>
                                        <Label className="link">{t('profile.background')}</Label>
                                        <Label className="link-separator ml-2 mr-2">/</Label>
                                        <Label className={this.state.academyIdSelected ? "link selectable" : "link-selected"} onClick={() => { this.onClickAcademic(null) }}>{t('academic.academics')}</Label>
                                        {this.state.academyIdSelected &&
                                            <div className='align-horizontal'>
                                                <Label className="link-separator ml-2 mr-2">/</Label>
                                                {academics?.length > 0 &&
                                                    <Label className={"link-selected"}>{academics[0]?.academyDetail?.name ?? ''}</Label>
                                                }
                                            </div>
                                        }
                                        <span className="ml-2" id="add-company" onClick={() => { this.setState({ isAddingNewEducation: true }) }}>
                                            <SVG src={AcademicsIcon} className="ml-2 size-px-20" />
                                            <PlusCircle className='bg-color-details' style={{ width: '12', height: '12', transform: 'translate(-8px, 5px)', borderRadius: '6' }} />
                                            {/* <PlusSquare className="icon-dual ml-2 icon-size-20" id="add-company" /> */}
                                            <UncontrolledTooltip placement="bottom" target="add-company">
                                                {t('me.hover.addAcademic')}
                                            </UncontrolledTooltip>
                                        </span>
                                    </div>
                                </Col>
                                <Col className='mt-1'>
                                    <div className={"custom-control custom-switch cursor-pointer mt-2"} onClick={() => { this.setState({ showEducationDetails: !this.state.showEducationDetails }) }}>
                                        <input
                                            type="radio"
                                            readOnly={true}
                                            className="custom-control-input cursor-pointer"
                                            checked={this.state.showEducationDetails}
                                        />
                                        <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                                            {t('general.details')}
                                        </label>
                                    </div>
                                </Col>
                            </Row>

                            {this.listModule()}
                        </div>
                    </CardBody>
                </Card>
            </div >
            ;
    }
}

const mapStateToProps = (state) => {
    const { countries } = state.Auth;
    const { academics, academicDoc, loading, error } = state.Academics;
    const { profile, subscriptionProfile, menuHierarchy } = state.Profile;
    const { careers } = state.Company;

    return {
        profile, academicDoc, subscriptionProfile, academics, careers,
        menuHierarchy, loading, error, countries
    };
};

const mapDispatchToProps = {
    changeMenuType,
    initMenuWithMenuItems,
    getCareerCompanies,
    getAPICountryInfo,
    getAllContentsCompany,
    getAcademics,
    changeSidebarType,
    getSubscriptionProfile,
    setTopBarTitle,
    getAcademicDoc,
    deleteEducation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Educations));