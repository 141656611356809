// @flow
import {
    GET_REPOPROFILES,GET_REPOPROFILES_FAILED,GET_REPOPROFILES_SUCCESS
} from './constants';

export const getRepoProfiles = (callbacks) => ({
    type: GET_REPOPROFILES,
    payload: { callbacks },
});

export const getRepoProfilesSuccess = (data) => ({
    type: GET_REPOPROFILES_SUCCESS,
    payload: data,
});

export const getRepoProfilesFailed = (error) => ({
    type: GET_REPOPROFILES_FAILED,
    payload: error,
});
