import classnames from 'classnames';
import React from 'react';
import * as FeatherIcon from 'react-feather';
import { ArrowRightCircle } from 'react-feather';
import StarRatings from 'react-star-ratings';
import { Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { AvatarIcon } from '../../../../components/Icons';
//import profilePic from '../../../assets/images/users/avatar-7.svg';
import { getLocalizedString } from '../../../../helpers/utils';
//import { ReactComponent as ProfilePic } from '../../../assets/images/users/avatar-7.svg';
import SVG from 'react-inlinesvg';

export default class UserInfo extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            activeTab: 0, profile: null
        };
        if (this.props.selectEmailTab != null) {
            this.props.selectEmailTab(this.state.profile?.security.companies[0]);
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            if (this.props.selectEmailTab != null) {
                this.props.selectEmailTab(this.props.profile?.security?.companies[this.state.activeTab]);
            }

            this.setState({
                activeTab: tab
            });
        }
    }

    countryCodeToString = (code) => {

        if ((this.props.countries === null || typeof this.props.countries === 'undefined')
            || this.props.countries.length === 0 || code.length === 0) {
            return "";
        }

        const c = this.props.countries?.filter((e) => e.value === code);

        var country = c.length > 0 ? c[0].label : code;

        if (typeof (c[0]?.states) !== 'undefined' && c[0].states !== null) {
            country += ' / ';
            const stateCode = this.props.profile && this.props.profile.user ? this.props.profile.user.state : '';
            const state = c[0].states.filter((e) => e.id === stateCode);

            if (state.length > 0) {
                const s = getLocalizedString(state[0].name);
                country += s.length > 0 ? s : stateCode;
            }
        }

        return country;
    }



    UserProfile = (props) => {
        var { profile } = props;
        const { missingPersonal, missingPersonalIndependant, waitingForEmail } = this.props;
        const isCompany = this.props.isCompany ?? true
        var showSubscription = this.props.showSubscription;
        const pro_security = profile && profile.security && profile.security.companies ? profile.security.companies.filter(item => (item?.emailTypeId) != 1) : null;
        const userEmail = profile && profile.security && profile.security.companies ? profile.security.companies.find(item => (item?.emailTypeId) === 1) : null
        const country = this.countryCodeToString(profile && profile.user && profile.user.country ? profile.user.country : '')
        return <React.Fragment>
            <div className="media ml-4 mt-4 mb-4">
                <SVG src={AvatarIcon} className="avatar-sm mr-2" alt="Datanexions"/>
                <div className="media-body">
                    <div className='ml-3'>
                        <Row className='align-items-center'>
                            <h6 className="pro-user-name mt-0 mb-0">{profile && profile.user ? (profile.user.firstName + ' ' + profile.user.lastName) : ''}</h6>
                            {profile && profile.user && profile.user.linkedIn &&
                                <Col className='mt-0 mb-1'><FeatherIcon.Linkedin className='mr-2' color="darkblue" size={18} /><FeatherIcon.CheckCircle color="green" size={18} /></Col>}
                        </Row>
                        <Row>
                            <span className="pro-user-desc">{profile && profile.user ? (country + ' | ' + profile.user.city) : ''}</span>

                        </Row>
                        <Row>
                            {profile && profile.security && (profile.security.companies?.length > 0) ?
                                <TabPane tabId={profile.security.companies.indexOf(0)}>
                                    <span className="pro-user-desc">{profile.security.email}</span>
                                </TabPane>
                                : ''}

                        </Row>
                    </div>
                    {/* {isCompany && pro_security && (pro_security.length > 0) &&

                        <Row className="card-box-outline">
                            <Col xs="11" sm="11" md="11">
                                <TabContent activeTab={this.state.activeTab}>
                                    {profile && profile.security && profile.security.security ?
                                        pro_security.map((email, index) => {
                                            return <TabPane tabId={index}>
                                                {showSubscription &&
                                                    <div>
                                                        {((email.companyId === null || typeof (email.isValidated) === 'undefined'
                                                            || email.isValidated === null || email.isValidated === false) && (email.emailTypeId > 0)) &&
                                                            email.individualProfileId !== 1 && (email.emailTypeId !== 1) &&

                                                            <Row className="ml-2">
                                                                <Label>{this.props.NoActiveCompany}</Label>
                                                            </Row>
                                                        }
                                                        {email.companyId && email.emailTypeId && email.emailTypeId !== 1 && (email.isValidated || email.individualProfileId === 1) &&
                                                            <div>

                                                                <Row className="ml-2">
                                                                    <Label>{email.companyDetail?.name}  {(email.individualProfileDetail?.value ? email.individualProfileDetail?.value : '') ? (' | ' + getLocalizedString(email.individualProfileDetail?.value)) : ""} {(email.positionLevelDetail?.value ? email.positionLevelDetail?.value : '') ? (' | ' + getLocalizedString(email.positionLevelDetail?.value)) : ""}</Label>
                                                                </Row>
                                                                <Row className="ml-2">
                                                                    <Label>
                                                                        {email.operationRoles && email.operationRoles.length > 0 && email.operationRolesDetail.map((role, idx) => {
                                                                            if (idx === email.operationRolesDetail.length - 1) {
                                                                                return getLocalizedString(role.value) + "."
                                                                            } else {
                                                                                return getLocalizedString(role.value) + ", "
                                                                            }
                                                                        })
                                                                        }
                                                                    </Label>
                                                                </Row>

                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <Row className="ml-2">
                                                    <Label>{email.email}{showSubscription && email.companyId && email.companyDetail.country && (" | " + ((email.companyDetail.country) ? this.countryCodeToString(email.companyDetail.country) : ""))}</Label>

                                                </Row>
                                                {email.companyRoleId && email.companyRoleId === 3 &&
                                                    <Row className="ml-2">
                                                        {(email.isValidated || (userEmail && userEmail.email === email.email)) ?
                                                            <Label style={{ color: 'orange' }}>{(email.companyDetail?.domains?.length > 0) ? missingPersonal : missingPersonalIndependant} &nbsp; &nbsp;</Label>
                                                            :
                                                            <Label style={{ color: 'red' }}>{waitingForEmail}&nbsp; &nbsp;</Label>
                                                        }
                                                        <ArrowRightCircle color={(email.isValidated || (userEmail && userEmail.email === email.email)) ? 'orange' : 'red'} size={22}
                                                            onClick={(e) => {
                                                                if (this.props.getListEmailShow) {
                                                                    this.props.getListEmailShow(email.email);
                                                                }
                                                                else {
                                                                    if (this.props.history)
                                                                        this.props.history.push({
                                                                            pathname: "/subscriptions",
                                                                            state: {
                                                                                missingPersonalArrowClick: email.email
                                                                            }
                                                                        });
                                                                }
                                                            }} />
                                                    </Row>
                                                }
                                            </TabPane>

                                        }) : ''
                                    }

                                </TabContent>

                            </Col>

                            <Col xs="1" sm="1" md="1">
                                <Nav tabs vertical pills>

                                    {pro_security ? pro_security.map(email => (
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === pro_security.indexOf(email) })}
                                                onClick={() => {
                                                    this.toggle(pro_security.indexOf(email));
                                                }}
                                            >
                                                {pro_security.indexOf(email) + 1}
                                            </NavLink>
                                        </NavItem>
                                    )) : ''}
                                </Nav>
                            </Col>
                        </Row>
                    } */}
                </div>
            </div>
        </React.Fragment>
    }

    ratingChanged = (newRating) => {
        // console.log(newRating);
    };

    Validation = () => {
        return <React.Fragment>
            <div className="media user-profile mt-2 mb-2">
                <div className="media-body">
                    <FeatherIcon.CheckCircle className="icon-dual icon-xs mr-2" />
                    <Label>Validated</Label>

                    <StarRatings
                        rating={4}
                        starRatedColor="orange"
                        changeRating={this.ratingChanged}
                        numberOfStars={5}
                        starDimension="18px"
                        starSpacing="0px"
                        name='rating'
                    />
                </div>
            </div>
        </React.Fragment>
    }

    render() {
        const { showValidation, profile } = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col md={10}>
                        <this.UserProfile profile={profile} />
                    </Col>
                    {showValidation ? <Col md={2}>
                        <this.Validation />
                    </Col> : ''}

                </Row>
            </React.Fragment>
        );
    }
}