// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../../components/Loader';
import { countryCodeToString, customAlertPopup, facultyWithId, getCurrentLanguage, getLocalizedString } from '../../../../../helpers/utils';
import {
    getAPICountryInfo,
    getAcademicDoc,
    getNetworkAcademics,
    createEducation,
    editEducation,
} from '../../../../../redux/actions';
import { allLanguages } from '../../../../../helpers/utils';
import { format } from '../../../../../helpers/stringFormat';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css"
import { isDarkMode } from '../../../../../helpers/authUtils';
import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from '../../../../../helpers/DatePickerFormat';

export const ADD_EDUCATION_IDENTIFICATION = 0;
export const ADD_EDUCATION_PLACE = 1;
export const ADD_EDUCATION_GRADUATE_DEGREE = 3;
export const ADD_EDUCATION_ERASE_WARNING = 4;

class AddEducationModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'xx',
            addEducationStep: ADD_EDUCATION_IDENTIFICATION,
            editingPlaceId: null,
            currentGraduateDegree: 0,
            selectedCountry: null,
            selectedAcademy: null,
            selectedInformationLanguage: null,
            selectedCity: null,
            selectedSchool: null,
            monthStart: null,
            monthEnd: null,
            selectedGraduateDegree: null,
            localGraduateDegree: '',
            selectedGraduationSpecialty: null,
            localGraduationSpecialty: '',
            dateOfDegree: null,
            isUnderPreparation: false,
            graduateDegrees: [],
            loadingCountries: false,
            loadingAcademicDoc: false,
            loadingNetwork: false
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({
                callbackOnBegin: () => {
                    this.setState({ loadingCountries: true, loading: true });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loadingCountries: false, loading: this.state.loadingAcademicDoc });
                },
            });
        }
        if (this.props.academicDoc == null) {
            this.props.getAcademicDoc({
                callbackOnBegin: () => {
                    this.setState({ loadingAcademicDoc: true, loading: true });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loadingAcademicDoc: false, loading: this.state.loadingCountries });
                },
            });
        }
        // if (this.props.allNetworkAcademics == null) {
        //     this.props.getNetworkAcademics(false, {
        //         callbackOnBegin: () => {
        //             this.setState({ loadingNetwork: true, loading: true });
        //         },
        //         callbackOnFinish: () => {
        //             // Update loading state?
        //             this.setState({ loadingNetwork: false, loading: this.state.loadingCountries || this.state.loadingAcademicDoc });
        //         },
        //     });
        // }

        if (this.props.editingAcademy) {
            this.prefillData();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.allNetworkAcademics != this.props.allNetworkAcademics || prevProps.countries != this.props.countries || prevProps.academicDoc != this.props.academicDoc) {
            this.prefillData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    prefillData = () => {
        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );
        let languages = allLanguages();

        let network = this.academicsNetworkByCountry(countries);

        if (network.length > 0 && this.props.editingAcademy && this.props.academicDoc) {
            let editingAcademy = this.props.editingAcademy;
            let editingPlace = this.props.editingPlace;

            let city = editingPlace?.cityId ? this.props.editingAcademy?.academyDetail?.cities?.find((c) => { return c.id == editingPlace?.cityId }) : null;

            let selectedCountry = null;
            let selectedAcademy = null;
            if (city) {
                selectedCountry = city?.country ? network.find((group) => { return group.countryCode == city?.country }) : null;
                selectedAcademy = editingAcademy?.academyId ? selectedCountry?.academics?.find((a) => { return a.entity.id == editingAcademy?.academyId }) : null;
            }
            else if (editingAcademy?.academyId) {
                for (var i = 0; i < network.length; i++) {
                    selectedAcademy = network[i].academics?.find((element) => { return element.entity?.id == editingAcademy.academyId });
                    if (selectedAcademy) {
                        selectedCountry = network[i];
                        break;
                    }
                };
            }

            let selectedInformationLanguage = editingPlace?.informationLanguage ? languages.find((element) => { return element.code == editingPlace?.informationLanguage }) : null;

            let cities = selectedAcademy?.entity?.cities?.map((element) => { return { label: element.city, entity: element } })
            let selectedCity = city?.id ? cities?.find((c) => { return c.entity.id == city.id }) : null;

            let schools = selectedCity ? selectedAcademy?.entity?.faculties?.filter((element) => { return element.cities.some((c) => { return c == selectedCity.entity.id }) }) : null;
            schools = schools?.map((element) => { return { label: facultyWithId(element.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '', entity: element } });
            let selectedSchool = editingPlace?.facultyId ? schools?.find((c) => { return c.entity.facultyId == editingPlace?.facultyId }) : null;

            let monthStart = editingPlace?.monthStart ? moment(editingPlace.monthStart, 'MM/YYYY').toDate() : null;
            let monthEnd = editingPlace?.monthEnd ? moment(editingPlace.monthEnd, 'MM/YYYY').toDate() : null;

            let selectedFaculty = facultyWithId(selectedSchool?.entity?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties);
            let graduateDegrees = selectedFaculty?.entity?.graduateDegreesDetails?.map((element) => {
                element.label = getLocalizedString(element.value);
                return element
            });
            let specialties = selectedFaculty?.entity?.specialties?.map((element) => {
                element.label = getLocalizedString(element.value);
                return element
            });

            let degrees = editingPlace?.graduateDegrees?.map((d) => {
                let graduateDegree = graduateDegrees?.find((gd) => { return gd.order == d.graduateDegreeId });
                let localGraduateDegree = d?.graduateDiplomaInt ?? '';
                let graduationSpecialty = specialties?.find((s) => { return s.order == d.facultySpecialtyId });
                let localGraduationSpecialty = d?.graduationSpecialtyInt ?? '';
                let dateDegree = d.dateDegree ? moment(d.dateDegree, 'MM/YYYY').toDate() : null;
                let underPreparation = d?.underPreparation ?? false;

                return {
                    graduateDegree: graduateDegree,
                    graduateDiplomaInt: localGraduateDegree,
                    facultySpecialty: graduationSpecialty,
                    graduationSpecialtyInt: localGraduationSpecialty,
                    dateDegree: dateDegree,
                    underPreparation: underPreparation,
                    validationsStatus: d?.validationsStatus
                }
            });

            this.setState({
                editingPlaceId: editingPlace?.id,
                selectedCountry: selectedCountry,
                selectedAcademy: selectedAcademy,
                selectedInformationLanguage: selectedInformationLanguage,
                selectedCity: selectedCity,
                selectedSchool: selectedSchool,
                monthStart: monthStart,
                monthEnd: monthEnd,
                graduateDegrees: degrees,
                isValidate: editingPlace?.isValidate ?? false
            });
        }
    }

    academicsNetworkByCountry = (countries) => {
        let t = this.props.t;
        let network = [];

        let academics = this.props.allNetworkAcademics ?? [];

        academics.forEach(academy => {
            if (academy?.cities?.length > 0) {
                academy.cities.forEach(city => {
                    let countryCode = city.country ?? '';

                    let index = network.findIndex((group) => { return group.countryCode == countryCode });
                    if (index >= 0) {
                        let acads = network[index].academics ?? [];
                        if (!acads.some((a) => { return a.entity.id == academy.id })) {
                            acads.push({ label: academy.name ?? '', entity: academy });
                            network[index].academics = acads;
                        }
                    }
                    else {
                        network.push({ label: countryCodeToString(countryCode, countries), countryCode: countryCode, academics: [{ label: academy.name ?? '', entity: academy }] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.countryCode?.length == 0 });
                if (index >= 0) {
                    let acads = network[index].academics ?? [];
                    acads.push({ label: academy.name ?? '', entity: academy });
                    network[index].academics = acads;
                }
                else {
                    network.push({ label: t('sub.Others'), countryCode: '', academics: [{ label: academy.name ?? '', entity: academy }] });
                }
            }
        }, this);

        if (network.length > 1) {
            network.sort((a, b) => {
                let countryA = a.countryCode?.length == 0 ? '' : countryCodeToString(a.countryCode, countries)
                let countryB = b.countryCode?.length == 0 ? '' : countryCodeToString(b.countryCode, countries)
                return a.countryCode?.length == 0 ? 1 : countryA.localeCompare(countryB)
            }, this);
        }

        return network;
    }

    onBack = () => {
        switch (this.state.addEducationStep) {
            case ADD_EDUCATION_PLACE:
                this.setState({ addEducationStep: ADD_EDUCATION_IDENTIFICATION });
                break;
            case ADD_EDUCATION_GRADUATE_DEGREE:
                if (this.state.currentGraduateDegree > 0) {
                    let prevDegree = this.state.graduateDegrees[this.state.currentGraduateDegree - 1];
                    this.setState({
                        selectedGraduateDegree: prevDegree?.graduateDegree,
                        localGraduateDegree: prevDegree?.graduateDiplomaInt ?? '',
                        selectedGraduationSpecialty: prevDegree?.facultySpecialty,
                        localGraduationSpecialty: prevDegree?.graduationSpecialtyInt ?? '',
                        dateOfDegree: prevDegree?.dateDegree,
                        isUnderPreparation: prevDegree?.underPreparation ?? false,
                        currentGraduateDegree: this.state.currentGraduateDegree - 1
                    })
                }
                else {
                    this.setState({ addEducationStep: ADD_EDUCATION_PLACE });
                }
                break;
            default:
                break;
        }
    }

    onNext = () => {
        switch (this.state.addEducationStep) {
            case ADD_EDUCATION_IDENTIFICATION:
                this.setState({ addEducationStep: ADD_EDUCATION_PLACE });
                break;
            case ADD_EDUCATION_PLACE:
                let nextDegree = this.state.graduateDegrees.length > 0 ? this.state.graduateDegrees[0] : null;
                this.setState({
                    selectedGraduateDegree: nextDegree?.graduateDegree ?? null,
                    localGraduateDegree: nextDegree?.graduateDiplomaInt ?? '',
                    selectedGraduationSpecialty: nextDegree?.facultySpecialty ?? null,
                    localGraduationSpecialty: nextDegree?.graduationSpecialtyInt ?? '',
                    dateOfDegree: nextDegree?.dateDegree ?? null,
                    isUnderPreparation: nextDegree?.isUnderPreparation ?? false,
                    addEducationStep: ADD_EDUCATION_GRADUATE_DEGREE
                });
                break;
            case ADD_EDUCATION_GRADUATE_DEGREE:
                this.updateGraduateDegrees(
                    () => {
                        this.editNextDegree();
                    }
                );
                break;
            case ADD_EDUCATION_ERASE_WARNING:
                this.setState({
                    selectedGraduateDegree: null,
                    localGraduateDegree: '',
                    selectedGraduationSpecialty: null,
                    localGraduationSpecialty: '',
                    dateOfDegree: null,
                    isUnderPreparation: false,
                    addEducationStep: ADD_EDUCATION_GRADUATE_DEGREE
                });
                break;
            default:
                break;
        }

    }

    onSave = (isValidate) => {
        this.updateGraduateDegrees(() => {
            this.createEducationAction(isValidate);
        })
    }

    createEducationAction = (isValidate) => {
        var params = {}
        params.isValidate = isValidate ? isValidate : (this.state.isValidate ?? false);
        params.originalAcademyId = this.props.editingAcademy?.academyId?.toString();
        params.originalNodeId = this.props.editingAcademy?.nodeId;
        params.academyId = this.state.selectedAcademy?.entity?.id?.toString();
        params.nodeId = this.state.selectedAcademy?.entity?.nodeId;
        params.informationLanguage = this.state.selectedInformationLanguage?.code;
        params.cityId = this.state.selectedCity?.entity?.id;
        params.facultyId = this.state.selectedSchool?.entity?.facultyId;
        var dateStart = null;
        var valueDateStart = null;
        var dateEnd = null;
        var valueDateEnd = null;
        if (this.state.monthStart != null) {
            dateStart = moment(this.state.monthStart);
            valueDateStart = dateStart.format('MM/YYYY');
            params.monthStart = valueDateStart;
        }
        if (this.state.monthEnd != null) {
            dateEnd = moment(this.state.monthEnd);
            valueDateEnd = dateEnd.format('MM/YYYY');
            params.monthEnd = valueDateEnd;
        }

        let degrees = this.state.graduateDegrees.filter((element) => {
            return element.graduateDegree != null || element.graduationSpecialty != null || element.dateDegree != null || element.graduateDiplomaInt?.length > 0 || element.graduationSpecialtyInt?.length > 0
        })
        params.degrees = degrees.map((degree, index) => {
            let dateDegree = null;
            if (!degree.underPreparation && degree.dateDegree != null) {
                dateDegree = moment(degree.dateDegree);
                dateDegree = dateDegree.format('MM/YYYY');
            }

            return {
                order: index + 1,
                graduateDegreeId: degree.graduateDegree?.order,
                graduateDiplomaInt: degree.graduateDiplomaInt?.length > 0 ? degree.graduateDiplomaInt : null,
                facultySpecialtyId: degree.facultySpecialty?.order,
                graduationSpecialtyInt: degree.graduationSpecialtyInt?.length > 0 ? degree.graduationSpecialtyInt : null,
                dateDegree: dateDegree,
                underPreparation: degree.underPreparation,
                validationsStatus: degree.validationsStatus
            }
        });

        if (this.state.editingPlaceId != null) {
            params.placeId = this.state.editingPlaceId.toString();
            params.validationsStatus = this.props.editingPlace?.validationsStatus;

            this.props.editEducation(params, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
                callbackOnFailure: (error) => {
                    this.setState({ error: error });
                },
                callbackOnSuccess: (response) => {
                    if (isValidate) {
                        this.props.onClose(true)
                    }
                }
            });
        }
        else {
            this.props.createEducation(params, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
                callbackOnFailure: (error) => {
                    this.setState({ error: error });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ editingPlaceId: response.placeId }, () => {
                        if (isValidate) {
                            this.props.onClose(true)
                        }
                    })
                }
            });
        }
    }

    checkNextable = () => {
        switch (this.state.addEducationStep) {
            case ADD_EDUCATION_IDENTIFICATION:
                return this.state.selectedCountry != null && this.state.selectedAcademy != null && this.state.selectedInformationLanguage != null;
            case ADD_EDUCATION_PLACE:
                return this.state.selectedCity != null && this.state.selectedSchool != null && this.state.monthStart != null && this.state.monthEnd != null;
            case ADD_EDUCATION_GRADUATE_DEGREE:
                return this.state.selectedGraduateDegree != null && this.state.selectedGraduationSpecialty != null && (this.state.isUnderPreparation ? true : (this.state.dateOfDegree != null));
            default:
                return true;
        }
    }

    updateGraduateDegrees = (callback) => {
        const didInput = this.state.selectedGraduateDegree != null || this.state.selectedGraduationSpecialty != null || this.state.dateOfDegree != null || this.state.localGraduateDegree != '';
        if (!didInput) {
            if (callback) {
                callback()
                return;
            }
        }

        let degree = {
            graduateDegree: this.state.selectedGraduateDegree,
            graduateDiplomaInt: this.state.localGraduateDegree,
            facultySpecialty: this.state.selectedGraduationSpecialty,
            graduationSpecialtyInt: this.state.localGraduationSpecialty,
            dateDegree: this.state.dateOfDegree,
            underPreparation: this.state.isUnderPreparation
        }
        let degrees = this.state.graduateDegrees;
        if (this.state.currentGraduateDegree <= degrees.length - 1) {
            degree.validationsStatus = degrees[this.state.currentGraduateDegree].validationsStatus;
            degrees[this.state.currentGraduateDegree] = degree;
        }
        else {
            degrees.push(degree);
        }

        this.setState({ graduateDegrees: degrees }, () => {
            if (callback) {
                callback()
            }
        })
    }

    eraseGraduateDegree = () => {
        this.setState({ addEducationStep: ADD_EDUCATION_ERASE_WARNING });
    }

    editNextDegree = () => {
        let nextDegree = (this.state.currentGraduateDegree < this.state.graduateDegrees.length - 1) ? this.state.graduateDegrees[this.state.currentGraduateDegree + 1] : null;
        this.setState({
            selectedGraduateDegree: nextDegree?.graduateDegree ?? null,
            localGraduateDegree: nextDegree?.graduateDiplomaInt ?? '',
            selectedGraduationSpecialty: nextDegree?.facultySpecialty ?? null,
            localGraduationSpecialty: nextDegree?.graduationSpecialtyInt ?? '',
            dateOfDegree: nextDegree?.dateDegree ?? null,
            isUnderPreparation: nextDegree?.isUnderPreparation ?? false,
            currentGraduateDegree: this.state.currentGraduateDegree + 1
        });
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );
        let languages = allLanguages();

        let network = this.academicsNetworkByCountry(countries);
        let academics = this.state.selectedCountry?.academics;
        let cities = this.state.selectedAcademy?.entity?.cities?.map((element) => { return { label: element.city, entity: element } })
        let schools = this.state.selectedCity ? this.state.selectedAcademy?.entity?.faculties?.filter((element) => { return element.cities.some((c) => { return c == this.state.selectedCity.entity.id }) }) : null;
        schools = schools?.map((element) => { return { label: facultyWithId(element.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties)?.label ?? '', entity: element } });

        let selectedFaculty = facultyWithId(this.state.selectedSchool?.entity?.facultyId, this.props.academicDoc?.facultyDoc?.academyFaculties);
        let graduateDegrees = selectedFaculty?.entity?.graduateDegreesDetails?.map((element) => {
            element.label = getLocalizedString(element.value);
            return element
        });
        let specialties = selectedFaculty?.entity?.specialties?.map((element) => {
            element.label = getLocalizedString(element.value);
            return element
        });

        const didInput = this.state.selectedGraduateDegree != null || this.state.selectedGraduationSpecialty != null || this.state.dateOfDegree != null || this.state.localGraduateDegree != '' || this.state.localGraduationSpecialty != '' || this.state.isUnderPreparation;

        const nextable = this.checkNextable();
        const isWarningForErasion = this.state.addEducationStep == ADD_EDUCATION_ERASE_WARNING
        const backVisible = this.state.addEducationStep != ADD_EDUCATION_IDENTIFICATION && !isWarningForErasion;
        const saveVisible = !isWarningForErasion;
        const saveable = this.state.selectedAcademy != null;
        const skipVisible = this.state.addEducationStep == ADD_EDUCATION_GRADUATE_DEGREE;
        const validateVisible = this.state.addEducationStep == ADD_EDUCATION_GRADUATE_DEGREE && (this.state.currentGraduateDegree >= this.state.graduateDegrees.length - 1);

        let headerTitle = (this.props.editingAcademy ? t('education.modifyEducationBackground') : t('education.addEducationBackground'));
        if (this.state.addEducationStep != ADD_EDUCATION_IDENTIFICATION) {
            let educationIdentification = this.state.selectedAcademy?.label ?? '';
            if (this.state.addEducationStep == ADD_EDUCATION_GRADUATE_DEGREE) {
                educationIdentification += ', ' + this.state.selectedCity.label;
                educationIdentification += ', ' + this.state.selectedSchool.label;
            }
            headerTitle = format((this.props.editingAcademy ? t('education.modifyEducationBackgroundAt') : t('education.addEducationBackgroundAt')), educationIdentification);
        }


        let isDark = isDarkMode();

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}
                                            {this.state.addEducationStep == ADD_EDUCATION_IDENTIFICATION &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.country")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="country"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={network}
                                                                    value={this.state.selectedCountry}
                                                                    onChange={(value) => {
                                                                        const didChange = value.countryCode != this.state.selectedCountry?.countryCode;
                                                                        if (didChange) {
                                                                            this.setState({
                                                                                selectedCountry: value,
                                                                                selectedAcademy: null,
                                                                                selectedCity: null,
                                                                                selectedSchool: null,
                                                                                graduateDegrees: []
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("Academy")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="academy"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={academics}
                                                                    value={this.state.selectedAcademy}
                                                                    onChange={(value) => {
                                                                        const didChange = value.entity?.nodeId != this.state.selectedAcademy?.entity?.nodeId;
                                                                        if (didChange) {
                                                                            this.setState({
                                                                                selectedAcademy: value,
                                                                                selectedCity: null,
                                                                                selectedSchool: null,
                                                                                graduateDegrees: []
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='informationLanguage'>{t("education.informationEntryLanguage")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="informationLanguage"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={languages}
                                                                    value={this.state.selectedInformationLanguage}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedInformationLanguage: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addEducationStep == ADD_EDUCATION_PLACE &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.city")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="city"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={cities}
                                                                    value={this.state.selectedCity}
                                                                    onChange={(value) => {
                                                                        const didChange = value.entity?.id != this.state.selectedCity?.entity?.id;
                                                                        if (didChange) {
                                                                            this.setState({
                                                                                selectedCity: value,
                                                                                selectedSchool: null,
                                                                                graduateDegrees: []
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("academic.school")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="school"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={schools}
                                                                    value={this.state.selectedSchool}
                                                                    onChange={(value) => {
                                                                        const didChange = value.entity?.facultyId != this.state.selectedSchool?.entity?.facultyId;
                                                                        if (didChange) {
                                                                            this.setState({
                                                                                selectedSchool: value,
                                                                                graduateDegrees: []
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("career.monthStart")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <DatePicker
                                                                    showMonthYearPicker
                                                                    dateFormat="MM/yyyy"
                                                                    placeholderText="(MM/YYYY)"
                                                                    selected={this.state.monthStart}
                                                                    className="date-picker width-percent-100 p-2"
                                                                    // minDate={dateNow}
                                                                    onChange={(value) => {
                                                                        this.setState({ monthStart: value });
                                                                    }}
                                                                    locale={getCurrentLanguage()}
                                                                />
                                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("career.monthEnd")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <DatePicker
                                                                    showMonthYearPicker
                                                                    dateFormat="MM/yyyy"
                                                                    placeholderText="(MM/YYYY)"
                                                                    selected={this.state.monthEnd}
                                                                    className="date-picker width-percent-100 p-2"
                                                                    minDate={this.state.monthStart}
                                                                    onChange={(value) => {
                                                                        this.setState({ monthEnd: value });
                                                                    }}
                                                                    locale={getCurrentLanguage()}
                                                                />
                                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addEducationStep == ADD_EDUCATION_GRADUATE_DEGREE &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("academic.graduateDegree")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="graduateDegree"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={graduateDegrees}
                                                                    value={this.state.selectedGraduateDegree}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedGraduateDegree: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("education.localDegree") + (this.state.selectedInformationLanguage?.label.length > 0 ? (' (' + this.state.selectedInformationLanguage?.label + ')') : '')}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <InputGroup className='input-container'>
                                                                    <AvInput autoComplete="off" type="text" name="localDegree" id="localDegree" placeholder={""}
                                                                        value={this.state.localGraduateDegree}
                                                                        onChange={(value, data, event, formattedValue) => {
                                                                            this.setState({ localGraduateDegree: data })
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("academic.graduationSpecialty")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="specialty"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={specialties}
                                                                    value={this.state.selectedGraduationSpecialty}
                                                                    onChange={(value) => {
                                                                        this.setState({ selectedGraduationSpecialty: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='academy'>{t("education.localSpecialty") + (this.state.selectedInformationLanguage?.label.length > 0 ? (' (' + this.state.selectedInformationLanguage?.label + ')') : '')}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <InputGroup className='input-container'>
                                                                    <AvInput autoComplete="off" type="text" name="localSpecialty" id="localSpecialty" placeholder={""}
                                                                        value={this.state.localGraduationSpecialty}
                                                                        onChange={(value, data, event, formattedValue) => {
                                                                            this.setState({ localGraduationSpecialty: data })
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>

                                                        {!this.state.isUnderPreparation &&
                                                            <Row className='mt-3'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for='academy'>{t("academic.dateOfDegree")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <DatePicker
                                                                        showMonthYearPicker
                                                                        dateFormat="MM/yyyy"
                                                                        placeholderText="(MM/YYYY)"
                                                                        selected={this.state.dateOfDegree}
                                                                        className="date-picker width-percent-100 p-2"
                                                                        // minDate={dateNow}
                                                                        onChange={(value) => {
                                                                            this.setState({ dateOfDegree: value });
                                                                        }}
                                                                        locale={getCurrentLanguage()}
                                                                    />
                                                                    {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                                </Col>
                                                            </Row>
                                                        }

                                                        <Row className='mt-4 ml-1'>
                                                            <Col className='path-component align-vertical input-container ml-1'>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    autoComplete="off"
                                                                    checked={this.state.isUnderPreparation}
                                                                    onChange={() => {
                                                                        this.setState({ isUnderPreparation: !this.state.isUnderPreparation })
                                                                    }}
                                                                />
                                                                <Label className='link-selected font-size-15 ml-1'>{t('academic.underPreparation')}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addEducationStep == ADD_EDUCATION_ERASE_WARNING &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Col className='font-size-15 text-left mt-2 align-horizontal'>
                                                            <div className='align-horizontal'>
                                                                <Label className='mt-0 mb-0'>{t('Erase Graduate Degree')}</Label>
                                                            </div>
                                                        </Col>
                                                        <Col className='font-size-15 text-left mt-2 align-horizontal'>
                                                            <div className='align-horizontal'>
                                                                <Label className='mt-0 mb-0'>{t('This graduate degree will not be saved. Are you sure you want to erase this graduate degree?')}</Label>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            }

                                            <br />

                                            <Row>
                                                <Col md={12} xl={12} className='font-size-15 text-left align-horizontal box-side'>
                                                    <div>
                                                        <Button color="white" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            isWarningForErasion ? this.setState({ addEducationStep: ADD_EDUCATION_GRADUATE_DEGREE }) : this.props.onClose(null);
                                                        }}><i className='uil'></i>{t(isWarningForErasion ? 'subscriptions.NO' : "personalInfo.cancel")}</Button>
                                                        {backVisible &&
                                                            <Button className='ml-2' color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onBack();
                                                            }}><i className='uil'></i>{t('terms.back')}</Button>
                                                        }
                                                        <Button className='ml-2' color="primary" disabled={!nextable} onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.onNext();
                                                        }}><i className='uil'></i>{t(isWarningForErasion ? 'subscriptions.YES' : (skipVisible ? 'academic.nextDegree' : 'sub.Next'))}</Button>
                                                    </div>
                                                    <div>
                                                        {skipVisible &&
                                                            <Button color={didInput ? "danger" : "primary"} className="mr-2" onClick={() => {
                                                                didInput ? this.eraseGraduateDegree() : this.onSave(true)
                                                            }} ><i className='uil'></i>{didInput ? t("academic.erase") : t("academic.skip")}</Button>
                                                        }
                                                        {saveVisible &&
                                                            <Button className='' disabled={!saveable} color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onSave(false);
                                                            }}><i className='uil'></i>{t('personalInfo.save')}</Button>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            {validateVisible &&
                                                <Row className='mt-2'>
                                                    <Col md={12} xl={12} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div></div>
                                                        <Button className='ml-2' color="success" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.onSave(true);
                                                        }}><i className='uil'></i>{t('updateCV.validate')}</Button>
                                                    </Col>
                                                </Row>
                                            }

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { menuHierarchy } = state.Profile;
    const { academicDoc } = state.Academics;
    const { allNetworkAcademics } = state.Network;

    return { country, countries, menuHierarchy, academicDoc, allNetworkAcademics, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    getAcademicDoc,
    getNetworkAcademics,
    createEducation,
    editEducation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddEducationModal));