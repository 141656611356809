// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Crosshair,
    Edit,
    PlusSquare,
    Star,
    User,
    Users,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import { childrenOfObject, getLocalizedString, locationString, monthOnlyDisplayString } from '../../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    changeTheme,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAllNetworkAcademics,
    getCompanyProfiles,
    getNetworkAcademicExperiences,
    getAcademicDoc,
    getGoalsOfAcademic
} from '../../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, setNetworkAcademics, getNetworkAcademics } from '../../../../../helpers/authUtils';
import UserInfo from '../../../Settings/Profile/UserInfo';
import * as layoutConstants from '../../../../../constants/layout';
import { IndustriesIcon, Requested } from '../../../../../components/Icons';
import SVG from 'react-inlinesvg';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import { REGISTRATION_STATUS_IN_PROCESS, REGISTRATION_STATUS_REGISTERED } from '../NetworkPerson';
import companyIcon from '../../../../../assets/images/menu/companies.svg';
import NetworkAcademicsInformation from './NetworkAcademicsInformation';
import NetworkAcademicsGoals from './NetworkAcademicsGoals';
import NetworkAcademicsMember from './NetworkAcademicsMember';
import NetworkAcademicsExperiences from './NetworkAcademicsExperiences';
import AddExperienceModal, { EXPERIENCE_TYPE_ACADEMIC } from '../../../Settings/Background/Experience/AddExperienceModal';
import { format } from '../../../../../helpers/stringFormat';
import AlertPopupView from '../../../Supports/AlertPopupView';
import AddGoalModal from '../AddGoalModal';
import AddCompanyModal from '../AddCompanyModal';
import AddAcademyModal from '../AddAcademyModal';

const ACADEMICS_DETAILS_INFORMATION = 0;
const ACADEMICS_DETAILS_GOAL = 1;
const ACADEMICS_DETAILS_MEMBERS = 2;
const ACADEMICS_DETAILS_EXPERIENCE = 3;

class NetworkAcademicsDetails extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: ACADEMICS_DETAILS_INFORMATION,
            selectedNode: null,
            academyId: null,
            academyNodeId: null,
            showEducationDetails: false,
            showAddAcademicDialog: false,
            showAddDataExperienceDialog: false,
            showAddGoalDialog: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }

        if (this.props.companyProfiles == null || this.props.companyProfiles.length == 0) {
            this.props.getCompanyProfiles({});
        }

        if (this.props.subscriptionProfile == null) {
            this.getProfileAndCompanies();
        }

        if (this.props.academicDoc == null) {
            this.props.getAcademicDoc({});
        }

        if (this.props.location.pathname?.length > 27) {
            let id = this.props.location.pathname.substring(27);
            let nodeId = -1;
            let companies = this.props.allNetworkAcademics ?? [];
            for (let i = 0; i < companies.length; i++) {
                let c = companies[i];
                if (c.id == id) {
                    nodeId = c.nodeId;
                    break;
                }
            }
            this.setState({ academyId: id, academyNodeId: nodeId ?? -1 });
        }
        setNetworkAcademics(this.props.location?.state?.networkAcademics);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
        setNetworkAcademics(null);
    }

    onBack = () => {
        this.props.history.push({ pathname: '/network-academics' });
    }

    getProfileAndCompanies = () => {
        this.props.getSubscriptionProfile({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
            },
        });
    };

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }

    onSelectedNode = (node) => {
        alert('onSelectedNode: ' + node.labels[0]);
        this.setState({ selectedNode: node });
    };

    userType = (id, registrationStatus, invited) => {
        let color = 'gray';
        let hover = 'personStatus.noInvitation';
        switch (registrationStatus) {
            case REGISTRATION_STATUS_REGISTERED:
                color = 'green';
                hover = 'personStatus.registered'
                break;
            case REGISTRATION_STATUS_IN_PROCESS:
                color = 'orange';
                hover = 'personStatus.underSignup'
                break;
            default:
                color = invited ? 'red' : 'gray';
                hover = invited ? 'personStatus.invited' : 'personStatus.noInvitation'
                break;
        }
        return (
            <div className={'box-center size-px-20'}>
                <User id={id} className="size-px-20" color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    validationStatus = (id, type, value) => {
        let t = this.props.t;
        let color = 'bg-color-orange';
        switch (type) {
            case 'in_submited':
                color = 'bg-color-orange';
                break;
            case 'in_validated':
                color = 'bg-color-green';
                break;
            case 'in_rejected':
                color = 'bg-color-red';
                break;
            case 'out_submited':
                color = 'bg-color-orange';
                break;
            case 'out_validated':
                color = 'bg-color-green';
                break;
            case 'out_rejected':
                color = 'bg-color-red';
                break;
            default:
                break;
        }

        return (
            <div id={type + id} className={'box-center size-px-15 rounded-8 ml-2' + ' ' + color}>
                <Label className='font-size-10 text-color-white mt-0 mb-0'>{value}</Label>
                <UncontrolledTooltip placement="bottom" target={type + id}>{type}</UncontrolledTooltip>
            </div>
        )
    }

    validationStatusModule = (id, validationStatus) => {
        return (
            <div className='align-horizontal center-vertical'>
                {(validationStatus.in_submited ?? 0) > 0 &&
                    this.validationStatus(id, 'in_submited', validationStatus.in_submited)
                }
                {(validationStatus.in_validated ?? 0) > 0 &&
                    this.validationStatus(id, 'in_validated', validationStatus.in_validated)
                }
                {(validationStatus.in_rejected ?? 0) > 0 &&
                    this.validationStatus(id, 'in_rejected', validationStatus.in_rejected)
                }
                <div>
                    <SVG src={Requested} className="icon-chevron ml-2 mb-1" />
                </div>
                {(validationStatus.out_submited ?? 0) > 0 &&
                    this.validationStatus(id, 'out_submited', validationStatus.out_submited)
                }
                {(validationStatus.out_validated ?? 0) > 0 &&
                    this.validationStatus(id, 'out_validated', validationStatus.out_validated)
                }
                {(validationStatus.out_rejected ?? 0) > 0 &&
                    this.validationStatus(id, 'out_rejected', validationStatus.out_rejected)
                }
            </div>
        )
    }

    editNetworkAcademics = () => {
        if (this.state.academyId != null && this.props.allNetworkAcademics != null) {
            let editingAcademic = this.props.allNetworkAcademics?.find((element) => { return element.id == this.state.academyId });
            if (editingAcademic != null) {
                this.setState({
                    editingAcademic: editingAcademic,
                    showAddAcademicDialog: true
                });    
            }
        }
    }

    closeAddAcademyModal = (response) => {
        if (response?.academy != null) {
            setNetworkAcademics(response.academy);
        }
        this.setState({ showAddAcademicDialog: false, editingAcademic: null }, () => {
            if (response) {
                this.props.getAllNetworkAcademics({
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                });
            }
        });
    };

    addDataExperience = () => {
        this.setState({ showAddDataExperienceDialog: true });
    }

    reloadExperiences = () => {
        if (this.state.academyId != null && this.props.allNetworkAcademics != null) {
            let nodeId = this.props.allNetworkAcademics?.find((element) => { return element.id == this.state.academyId })?.nodeId;
            if (nodeId != null) {
                this.props.getNetworkAcademicExperiences(nodeId, {
                    callbackOnBegin: () => {
                        this.setState({ loading: true });
                    },
                    callbackOnFailure: (error) => {
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                    },
                });
            }
        }
    }

    closeAddDataExperienceModal = (reload) => {
        if (reload ?? false) {
            // this.reloadExperiences();
        }
        this.setState({ showAddDataExperienceDialog: false })
    }

    addGoal = () => {
        if (this.state.academyNodeId >= 0) {
            this.setState({ showAddGoalDialog: true })
        }
        else {
            if (this.state.academyId != null && this.props.allNetworkAcademics != null) {
                let nodeId = this.props.allNetworkAcademics?.find((element) => { return element.id == this.state.academyId })?.nodeId;
                if (nodeId != null) {
                    this.setState({ academyNodeId: nodeId, showAddGoalDialog: true })
                }
            }
        }
    }

    reloadGoals = () => {
        if (this.state.academyId != null && this.props.allNetworkAcademics != null) {
            let nodeId = this.props.allNetworkAcademics?.find((element) => { return element.id == this.state.academyId })?.nodeId;
            if (nodeId != null) {
                this.props.getGoalsOfAcademic(nodeId, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                    },
                });
            }
        }
    }

    closeAddGoalModal = (response) => {
        if (response) {
            this.reloadGoals();
        }
        this.setState({ showAddGoalDialog: false })
    }

    viewExperienceDetails = (experience) => {
        if (this.props.history) {
            this.props.history.push({
                pathname: '/experience-details/' + experience.id,
                state: {
                    experience: experience
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let networkAcademics = getNetworkAcademics();

        let basicInfo = '';
        if (networkAcademics) {
            basicInfo = networkAcademics.name ?? '';
            let location = locationString(networkAcademics.countryHQ, networkAcademics.stateHQ, networkAcademics.cityHQ, countries);
            if (location?.length > 0) {
                basicInfo += (basicInfo.length > 0 ? ', ' : '') + location;
            }
        }

        return (
            <React.Fragment>

                {this.state.showAddAcademicDialog &&
                    <AddAcademyModal
                        id="add-academic"
                        title={t('profile.updatePersonalInformation')}
                        academicDoc={this.props.academicDoc}
                        editingAcademy={this.state.editingAcademic ? JSON.parse(JSON.stringify(this.state.editingAcademic)) : null}
                        modal={this.state.showAddAcademicDialog == 1}
                        close={this.closeAddAcademyModal}
                    />
                }

                {this.state.showAddDataExperienceDialog &&
                    <AddExperienceModal
                        modal={this.state.showAddDataExperienceDialog}
                        menuHierarchy={this.props.menuHierarchy}
                        countries={countries}
                        dataType={EXPERIENCE_TYPE_ACADEMIC}
                        careers={this.props.subscriptionProfile?.careers ?? []}
                        defaultCompanyId={this.state.academyId}
                        companyNodeId={this.state.academyNodeId}
                        // editingExperience={this.state.editingExperience ? JSON.parse(JSON.stringify(this.state.editingExperience)) : null}
                        reloadData={this.reloadExperiences}
                        onCancel={this.closeAddDataExperienceModal} />
                }

                {this.state.showAddGoalDialog &&
                    <AddGoalModal
                        id="add-goal"
                        companyNodeId={this.state.academyNodeId}
                        modal={this.state.showAddGoalDialog}
                        close={this.closeAddGoalModal}
                    />
                }

                {(this.state.showAlertPopup ?? false) &&
                    <AlertPopupView
                        title={t('Career unavailable')}
                        message={t('You don\'t have any career in this company. You need to add a career to this company before adding data experience.')}
                        doneTitle={t('modal.close')}
                        handleDone={() => { this.setState({ showAlertPopup: false }) }}
                        onClose={() => { this.setState({ showAlertPopup: false }) }}
                    />
                }

                <Row className="step-company">
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        <div className="path-component align-horizontal">
                            <Label className="link" onClick={this.onBack}>{t('Network')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link selectable" onClick={() => { this.onBack() }}>{t('education.academics')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link-selected">{basicInfo} </Label>
                        </div>
                    </CardBody>
                    <CardBody>
                        <div className='align-vertical'>
                            {this.state.activeTabInfo == ACADEMICS_DETAILS_INFORMATION &&
                                <div className='box-side height-px-0'>
                                    <div></div>
                                    <span className="ml-2" style={{ 'transform': 'translate(0px, 20px)' }}
                                        onClick={() => {
                                            this.editNetworkAcademics();
                                        }}>
                                        <Edit className="icon-dual ml-2 cursor-pointer icon-action" id='edit-company' />
                                        <UncontrolledTooltip placement="bottom" target='edit-company'>
                                            {format(t('general.modify0'), t('education.academics'))}
                                        </UncontrolledTooltip>
                                    </span>
                                </div>
                            }
                            {(this.state.activeTabInfo == ACADEMICS_DETAILS_GOAL || this.state.activeTabInfo == ACADEMICS_DETAILS_EXPERIENCE) &&
                                <div className='box-side height-px-0'>
                                    <div></div>
                                    <span className="ml-2" style={{ 'transform': 'translate(0px, 20px)' }}
                                        onClick={() => {
                                            if (this.state.activeTabInfo == ACADEMICS_DETAILS_EXPERIENCE) {
                                                this.addDataExperience()
                                            }
                                            else {
                                                this.addGoal()
                                            }
                                        }}>
                                        <PlusSquare className="icon-dual ml-2 cursor-pointer icon-action" id='add-data-experience' />
                                        <UncontrolledTooltip placement="bottom" target='add-data-experience'>
                                            {format(t('general.add0'), t(this.state.activeTabInfo == ACADEMICS_DETAILS_EXPERIENCE ? 'home.experience' : 'experience.Goal'))}
                                        </UncontrolledTooltip>
                                    </span>
                                </div>
                            }

                            <Nav tabs className="">
                                <NavItem key={ACADEMICS_DETAILS_INFORMATION}>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: this.state.activeTabInfo === ACADEMICS_DETAILS_INFORMATION,
                                        })}
                                        onClick={() => {
                                            this.toggleTabInfo(ACADEMICS_DETAILS_INFORMATION);
                                        }}>
                                        <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                        <span className="d-none d-sm-block">
                                            {' '}
                                            {t('education.academics')}
                                            <SVG src={companyIcon} className="ml-2 mb-1 size-px-16" />
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem key={ACADEMICS_DETAILS_GOAL}>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: this.state.activeTabInfo === ACADEMICS_DETAILS_GOAL,
                                        })}
                                        onClick={() => {
                                            this.toggleTabInfo(ACADEMICS_DETAILS_GOAL);
                                        }}>
                                        <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                        <span className="d-none d-sm-block">
                                            {' '}
                                            {t('experience.Goal')}
                                            <Crosshair className="ml-2 mb-1 size-px-16" size={16} />
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem key={ACADEMICS_DETAILS_MEMBERS}>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: this.state.activeTabInfo === ACADEMICS_DETAILS_MEMBERS,
                                        })}
                                        onClick={() => {
                                            this.toggleTabInfo(ACADEMICS_DETAILS_MEMBERS);
                                        }}>
                                        <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                        <span className="d-none d-sm-block">
                                            {' '}
                                            {t('general.members')}
                                            <Users className="ml-2 mb-1 size-px-16" size={16} />
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem key={ACADEMICS_DETAILS_EXPERIENCE}>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: this.state.activeTabInfo === ACADEMICS_DETAILS_EXPERIENCE,
                                        })}
                                        onClick={() => {
                                            this.toggleTabInfo(ACADEMICS_DETAILS_EXPERIENCE);
                                        }}>
                                        <i className={classnames('d-sm-none', 'd-block', 'mr-1')}></i>
                                        <span className="d-none d-sm-block">
                                            {' '}
                                            {t('home.experience')}
                                            <Star className="ml-2 mb-1 size-px-16" size={16} />
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTabInfo}>
                                <TabPane tabId={ACADEMICS_DETAILS_INFORMATION} key={ACADEMICS_DETAILS_INFORMATION}>
                                    <NetworkAcademicsInformation
                                        academyId={this.state.academyId}
                                        editNetworkAcademics={this.editNetworkAcademics}
                                    />
                                </TabPane>
                                <TabPane tabId={ACADEMICS_DETAILS_GOAL} key={ACADEMICS_DETAILS_GOAL}>
                                    <NetworkAcademicsGoals
                                        academyId={this.state.academyId}
                                    />
                                </TabPane>
                                <TabPane tabId={ACADEMICS_DETAILS_MEMBERS} key={ACADEMICS_DETAILS_MEMBERS}>
                                    <NetworkAcademicsMember
                                        fellows={networkAcademics?.fellows}
                                        academyId={this.state.academyId}
                                    />
                                </TabPane>
                                <TabPane tabId={ACADEMICS_DETAILS_EXPERIENCE} key={ACADEMICS_DETAILS_EXPERIENCE}>
                                    <NetworkAcademicsExperiences
                                        academyId={this.state.academyId}
                                        viewExperienceDetails={this.viewExperienceDetails}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, menuHierarchy, loading, error } = state.Profile;
    const { countries } = state.Auth;
    const { networkPeople, allNetworkAcademics } = state.Network;
    const { companyProfiles } = state.Company;
    const { academicDoc } = state.Academics;

    return { menuHierarchy, subscriptionProfile, countries, networkPeople, allNetworkAcademics, companyProfiles, academicDoc, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getAllNetworkAcademics,
    getCompanyProfiles,
    getNetworkAcademicExperiences,
    getAcademicDoc,
    getGoalsOfAcademic
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademicsDetails));
