// @flow
import {
    POST_PAYMENT,POST_PAYMENT_FAILED,POST_PAYMENT_SUCCESS,
    GET_PAYMENT,GET_PAYMENT_FAILED,GET_PAYMENT_SUCCESS,
    GET_ALL_PAYMENT,GET_ALL_PAYMENT_FAILED,GET_ALL_PAYMENT_SUCCESS,
    POST_STATUS_SUBSCRIPTION, POST_STATUS_SUBSCRIPTION_SUCCESS, POST_STATUS_SUBSCRIPTION_FAILED,
    GET_CHECKOUT_LINK, GET_CHECKOUT_LINK_SUCCESS, GET_CHECKOUT_LINK_FAILED
} from './constants';

export const postPayment = (payment, callbacks) => ({
    type: POST_PAYMENT,
    payload: { payment, callbacks } ,
});

export const postPaymentSuccess = (payment) => ({
    type: POST_PAYMENT_SUCCESS,
    payload: payment,
});

export const postPaymentFailed = (error) => ({
    type: POST_PAYMENT_FAILED,
    payload: error,
});

export const postStatusSubscription = (content, callbacks) => ({
    type: POST_STATUS_SUBSCRIPTION,
    payload: { content, callbacks } ,
});

export const postStatusSubscriptionSuccess = (payment) => ({
    type: POST_STATUS_SUBSCRIPTION_SUCCESS,
    payload: payment,
});

export const postStatusSubscriptionFailed = (error) => ({
    type: POST_STATUS_SUBSCRIPTION_FAILED,
    payload: error,
});

export const getPayment = (payment, callbacks) => ({
    type: GET_PAYMENT,
    payload: { payment, callbacks } ,
});

export const getPaymentSuccess = (payment) => ({
    type: GET_PAYMENT_SUCCESS,
    payload: payment,
});

export const getPaymentFailed = (error) => ({
    type: GET_PAYMENT_FAILED,
    payload: error,
});

export const getAllPayment = (email, callbacks) => ({
    type: GET_ALL_PAYMENT,
    payload: { email, callbacks } ,
});

export const getAllPaymentSuccess = (payment) => ({
    type: GET_ALL_PAYMENT_SUCCESS,
    payload: payment,
});

export const getAllPaymentFailed = (error) => ({
    type: GET_ALL_PAYMENT_FAILED,
    payload: error,
});


export const getCheckoutLink = (subscriptionId, callbacks) => ({
    type: GET_CHECKOUT_LINK,
    payload: { subscriptionId, callbacks } ,
});

export const getCheckoutLinkSuccess = (response) => ({
    type: GET_CHECKOUT_LINK_SUCCESS,
    payload: response,
});

export const getCheckoutLinkFailed = (error) => ({
    type: GET_CHECKOUT_LINK_FAILED,
    payload: error,
});