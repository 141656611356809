// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { countryCodeToString, getLocalizedString, internationalPhoneFormat, isSamePhoneNumber } from '../../../../helpers/utils';
import { getAPICountryInfo, checkExistUsers, createContact, editContact, resetExistUsers, getAllNetworkPersons } from '../../../../redux/actions';
import { getLoggedInUser, getLoggedInUserProfile } from '../../../../helpers/authUtils';
import PhoneInput from 'react-phone-input-2';
import { PlusSquare, Trash, User } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import SimpleCheckbox from '../../Supports/SimpleCheckbox';
import { REGISTRATION_STATUS_INVITED, REGISTRATION_STATUS_IN_PROCESS, REGISTRATION_STATUS_NONE, REGISTRATION_STATUS_REGISTERED } from './NetworkPerson';
import information from '../../../../assets/images/information.png';
import SVG from 'react-inlinesvg';
import companyIcon from '../../../../assets/images/menu/companies.svg';
import { findPhoneNumbersInText } from 'libphonenumber-js'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export const EMAIL_TYPE_PERSONAL = 0;
export const EMAIL_TYPE_COMPANY = 1;

export const ADD_PERSON_IDENTIFICATION = 0;
export const ADD_PERSON_PROVIDED = 1;
export const ADD_PERSON_NOT_PROVIDED = 2;
export const ADD_PERSON_EXIST_NETWORK = 3;
export const ADD_PERSON_YOURSELF = 4;
export const ADD_PERSON_NEW = 5;
export const ADD_PERSON_EXIST_PERSON = 6;
export const ADD_PERSON_CONFIRM = 7;

class ContactModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            selectedCountry: null,
            size: 'xx',
            addPersonStep: ADD_PERSON_IDENTIFICATION,
            firstName: '',
            lastName: '',
            isAddingEmail: false,
            emails: [],
            email: '',
            emailType: null,
            isAddingPhone: false,
            phones: [],
            dial: { countryCode: 'fr', dialCode: '33', format: '+.. . .. .. .. ..', name: 'France' },
            phone: '',
            phoneType: null,
            selectedInternalUserIndex: null,
            showExistPeople: true
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({
                callbackOnSuccess: (response) => {
                    this.filterCountry();
                },
            });
        }
        else {
            this.filterCountry();
        }

        if (this.props.editingContact) {
            this.populateContact(this.props.editingContact);
        }


        this.props.getAllNetworkPersons(0, 0, false, "", {
            callbackOnSuccess: (response) => {
                console.log('on success', response);
                this.setState({
                    allNetworkPersons: response.all_users,
                    allConnectedPersons: response.connected_users
                })
            },
        });    
    }


    populateContact = (contactDetails) => {
        contactDetails = contactDetails || this.props.editContact;

        let t = this.props.t;
        let firstName = contactDetails?.firstName ?? '';
        let lastName = contactDetails?.lastName ?? '';
        let emailTypes = [{ value: EMAIL_TYPE_PERSONAL, label: t('email.personal') }, { value: EMAIL_TYPE_COMPANY, label: t('email.professional') }];
        let emails = [];
        if (contactDetails?.emails?.length > 0) {
            emails = contactDetails?.emails.map((e) => { return { email: e.email, type: emailTypes.find((t) => { return t.value == e.type }) } })
        }
        else if (contactDetails?.email) {
            emails = [{ email: contactDetails?.email, type: { value: EMAIL_TYPE_PERSONAL, label: this.props.t('email.personal') } }];
        }
        let phones = [];
        const countries = this.props.countries?.map(
            (country, i) => {
                return { value: country.locCode, label: getLocalizedString(country), states: country.states ? country.states : null };
            }
        );
        if (contactDetails?.mobiles) {
            contactDetails.mobiles.forEach(element => {
                let founded = findPhoneNumbersInText(element.mobile);
                let dial = null;
                if (founded?.length > 0) {
                    dial = { countryCode: founded[0].number?.country, dialCode: founded[0].number?.countryCallingCode, format: '+.. . .. .. .. ..', name: countryCodeToString(founded[0].number?.country, countries) };
                }
                phones.push({ dial: dial, phone: element.mobile, type: emailTypes.find((t) => { return t.value == element.type }) });
            });
        }
        else if (contactDetails?.mobileNumber) {
            let founded = findPhoneNumbersInText(contactDetails?.mobileNumber);
            let dial = null;
            if (founded?.length > 0) {
                dial = { countryCode: founded[0].number?.country, dialCode: founded[0].number?.countryCallingCode, format: '+.. . .. .. .. ..', name: countryCodeToString(founded[0].number?.country, countries) };
            }
            phones = [{ dial: dial, phone: contactDetails?.mobileNumber }];
        }

        this.filterCountry(contactDetails);

        this.setState({
            firstName: firstName,
            lastName: lastName,
            emails: emails,
            phones: phones,
        });
    }

    filterCountry = (contactDetails) => {
        contactDetails = contactDetails || this.props.editContact;
        if (contactDetails?.country) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    // value: getLocalizedString(element)
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let selectedCountry = countries?.find((country) => { return country.value == contactDetails.country });
            this.setState({ selectedCountry: selectedCountry });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onContactAction = (checkExist) => {
        let params = {};
        params.firstName = this.state.firstName;
        params.lastName = this.state.lastName;
        params.country = this.state.selectedCountry.value;
        params.mobiles = this.state.phones.length > 0 ? this.state.phones.map((element) => { 
            return { 
                mobile: element.phone, 
                type: element.type?.value 
            } 
        }) : null;
        params.emails = this.state.emails.length > 0 ? this.state.emails.map((element) => { return { email: element.email, type: element.type.value } }) : null;

        if (this.state.selectedInternalUserIndex != null && this.props.existUsers?.internalUsers?.length >= this.state.selectedInternalUserIndex - 1) {
            let loggedInUser = getLoggedInUser();
            let internalUserId = this.props.existUsers?.internalUsers[this.state.selectedInternalUserIndex].id;
            let introducedByUserIds = this.props.existUsers?.internalUsers[this.state.selectedInternalUserIndex].introducedByUserIds ?? [];
            const alreadyInNetwork = introducedByUserIds.some((id) => { return id == loggedInUser.id });

            if (loggedInUser.id == internalUserId) {
                this.setState({ addPersonStep: ADD_PERSON_YOURSELF });
                return;
            }
            if (alreadyInNetwork) {
                this.setState({ addPersonStep: ADD_PERSON_EXIST_NETWORK });
                return;
            }
            else {
                params.internalUserId = internalUserId;
            }
        }

        console.log('-----', this.state.selectedInternalUserId);
        if(this.state.selectedInternalUserId)
            checkExist = false;

        if (checkExist ?? false) {
            this.props.checkExistUsers(params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.setState({ selectedInternalUserIndex: null }, () => {
                        this.onNext();
                    })
                },
            });
        }
        else {
            if (this.state.selectedInternalUserIndex != null) {
                this.setState({ addPersonStep: ADD_PERSON_CONFIRM });
            }
            else {
                this.createContact();
            }
        }
    }

    createContact = () => {
        let params = {};
        params.firstName = this.state.firstName;
        params.lastName = this.state.lastName;
        params.country = this.state.selectedCountry.value;
        params.mobiles = this.state.phones.length > 0 ? this.state.phones.map((element) => { 
            return {
                 mobile: element.phone, 
                 type: element.type?.value 
            } 
        }) : null;

        params.emails = this.state.emails.length > 0 ? this.state.emails.map((element) => { return { email: element.email, type: element.type.value } }) : null;
        if (this.state.selectedInternalUserIndex != null) {
            params.internalUserId = this.props.existUsers?.internalUsers[this.state.selectedInternalUserIndex].id;
        } else if(this.state.selectedInternalUserId) {
            params.internalUserId = this.state.selectedInternalUserId;
        }

        this.props.createContact(params, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
                this.setState({ loading: true, error: null });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?==-
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
                const message = 'You have just create new contact';
                this.props.close(message);
            },
        });
    }

    onEditContact = () => {
        let params = {};
        params.firstName = this.state.firstName;
        params.lastName = this.state.lastName;
        params.country = this.state.selectedCountry.value;
        params.mobiles = this.state.phones.length > 0 ? this.state.phones.map((element) => { return { mobile: element.phone, type: element.type.value } }) : null;
        params.emails = this.state.emails.length > 0 ? this.state.emails.map((element) => { return { email: element.email, type: element.type.value } }) : null;

        if (params.mobiles == null && params.emails == null) {
            this.updateContact(params);
        }
        else {
            let user = getLoggedInUserProfile();
            let sameEmail = this.state.emails.length == 0;
            let samePhone = this.state.phones.length == 0;

            if (user?.user?.emails?.length > 0) {
                for (let index = 0; index < user.user.emails.length; index++) {
                    sameEmail = this.state.phones.some((phone) => { return phone.phone == user.user.emails[index] });
                    if (sameEmail) {
                        break;
                    }
                }
            }
            else if (user?.security?.email?.length > 0 && this.state.emails.length > 0) {
                sameEmail = this.state.emails.some((e) => { return e.email == user?.security?.email });
            }

            if (user?.user?.mobiles?.length > 0) {
                for (let index = 0; index < user.user.mobiles.length; index++) {
                    samePhone = this.state.phones.some((phone) => { return isSamePhoneNumber(phone.phone, user.user.mobiles[index]) });
                    if (samePhone) {
                        break;
                    }
                }
            }
            else if (user?.user?.mobileNumber?.length > 0 && this.state.phones.length > 0) {
                samePhone = this.state.phones.some((phone) => { return isSamePhoneNumber(phone.phone, user.user.mobileNumber) });
            }

            if (!sameEmail || !samePhone) {
                this.props.checkExistUsers(params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                        let internalUsers = (this.props.existUsers?.internalUsers ?? []).filter((user) => { return user.id != this.props.editingContact.id });
                        if (internalUsers.length > 0) {
                            this.setState({ addPersonStep: ADD_PERSON_EXIST_PERSON });
                        }
                        else {
                            this.updateContact(params);
                        }
                    },
                });
            }
            else {
                this.setState({ addPersonStep: ADD_PERSON_YOURSELF })
            }
        }
    }

    updateContact = (params) => {
        let id = this.props.editingContact?.id;
        if (id) {
            this.props.editContact(id, params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    // whatever
                    // console.log('callbackOnSuccess');
                    const message = 'Your personal information has been updated';
                    this.props.close(message);
                },
            });
        }
    }

    handleCountryChanged = event => {
        this.setState({ country: event.value });
    };

    handleStateChanged = event => {
        this.setState({ state: event.value });
    };

    organizationOptions = () => {
        const { t } = this.props;
        return [
            { value: 'M', label: t('personalInfo.male') },
            { value: 'F', label: t('personalInfo.female') },
        ]
    }

    addEmail = () => {
        let emails = this.state.emails;

        let existIndex = emails.findIndex((email) => { return email.email == this.state.email });
        if (existIndex >= 0) {
            let isPersonal = this.state.emailType.value == EMAIL_TYPE_PERSONAL;
            if (isPersonal) {
                let existPersonalIndex = emails.findIndex((email) => { return email.type.value == EMAIL_TYPE_PERSONAL });
                if (existPersonalIndex >= 0 && existPersonalIndex != existIndex) {
                    emails[existPersonalIndex] = { email: this.state.email, type: this.state.emailType };
                    emails.splice(existIndex, 1);
                }
                else {
                    emails[existIndex] = { email: this.state.email, type: this.state.emailType };
                }
            }
            else {
                emails[existIndex] = { email: this.state.email, type: this.state.emailType };
            }
        }
        else if (this.state.emailType.value == EMAIL_TYPE_PERSONAL) {
            let index = emails.findIndex((email) => { return email.type.value == EMAIL_TYPE_PERSONAL });
            if (index >= 0) {
                emails[index] = { email: this.state.email, type: this.state.emailType };
            }
            else {
                emails.push({ email: this.state.email, type: this.state.emailType });
            }
        }
        else {
            emails.push({ email: this.state.email, type: this.state.emailType });
        }

        this.setState({ emails: emails, isAddingEmail: false, email: '', emailType: null })
    }

    addPhone = () => {
        let phones = this.state.phones;
        phones.push({ dial: this.state.dial, phone: this.state.phone, type: this.state.phoneType });
        this.setState({ phones: phones, isAddingPhone: false, phone: '', phoneType: null });
    }

    onBack = () => {
        let backStep = ADD_PERSON_IDENTIFICATION;
        switch (this.state.addPersonStep) {
            case ADD_PERSON_EXIST_NETWORK:
                backStep = ADD_PERSON_PROVIDED;
                break;
            default:
                break;
        }
        this.setState({ addPersonStep: backStep, showExistPeople: false }, () => {
            if (this.state.addPersonStep == ADD_PERSON_IDENTIFICATION) {
                this.props.resetExistUsers({});
            }
        })
    }

    onCheckUsers = () => {
        if (this.state.emails.length == 0 && this.state.phones.length == 0) {
            this.onContactAction(true);
        }
        else {
            let user = getLoggedInUserProfile();
            let sameEmail = this.state.emails.length == 0;
            let samePhone = this.state.phones.length == 0;

            if (this.state.emails.length > 0) {
                if (user?.user?.emails?.length > 0) {
                    for (let index = 0; index < user.user.emails.length; index++) {
                        sameEmail = this.state.phones.some((phone) => { return phone.phone == user.user.emails[index] });
                        if (sameEmail) {
                            break;
                        }
                    }
                }
                else if (user?.security?.email?.length > 0 && this.state.emails.length > 0) {
                    sameEmail = this.state.emails.some((e) => { return e.email == user?.security?.email });
                }
            }

            if (this.state.phones.length > 0) {
                if (user?.user?.mobiles?.length > 0) {
                    for (let index = 0; index < user.user.mobiles.length; index++) {
                        samePhone = this.state.phones.some((phone) => { return isSamePhoneNumber(phone.phone, user.user.mobiles[index]) });
                        if (samePhone) {
                            break;
                        }
                    }
                }
                else if (user?.user?.mobileNumber?.length > 0 && this.state.phones.length > 0) {
                    samePhone = this.state.phones.some((phone) => { return isSamePhoneNumber(phone.phone, user.user.mobileNumber) });
                }
            }

            if (!sameEmail || !samePhone) {
                this.onContactAction(true);
            }
            else {
                this.setState({ addPersonStep: ADD_PERSON_YOURSELF })
            }
        }
    }

    onNext = () => {
        let internalUsers = this.props.existUsers?.internalUsers ?? [];
        let sameNameUsers = (this.props.existUsers?.sameNameUsers ?? []).filter((user) => { return user.country == this.state.selectedCountry.value });

        if (internalUsers.length > 0) {
            this.setState({ addPersonStep: ADD_PERSON_PROVIDED });
        }
        else if (sameNameUsers.length > 0) {
            this.setState({ addPersonStep: ADD_PERSON_NOT_PROVIDED });
        }
        else {
            this.setState({ addPersonStep: ADD_PERSON_NEW });
        }
    }

    notProvidedUsersStatus = (users) => {
        if (users.length == 0) {
            return null;
        }

        let registered = 0;
        let signupProcess = 0;
        let invited = 0;
        let simply = 0;

        users.forEach(person => {
            let registrationStatus = this.registrationStatus(person);

            switch (registrationStatus) {
                case REGISTRATION_STATUS_REGISTERED:
                    registered += 1;
                    break;
                case REGISTRATION_STATUS_IN_PROCESS:
                    signupProcess += 1;
                    break;
                case REGISTRATION_STATUS_INVITED:
                    invited += 1;
                    break;
                default:
                    simply += 1;
                    break;
            }
        });

        let status = {};
        if (registered > 0) {
            status.registered = registered;
        }
        if (signupProcess) {
            status.signupProcess = signupProcess;
        }
        if (invited) {
            status.invited = invited;
        }
        if (simply) {
            status.simplyList = simply;
        }

        return status;
    }

    notProvidedUser = (type, value) => {
        let t = this.props.t;

        let color = 'gray';
        let title = '';
        switch (type) {
            case 'registered':
                color = 'green';
                title = format(t("addPerson.notProvided.userDatanexions"), value);
                break;
            case 'signupProcess':
                color = 'orange';
                title = format(t("addPerson.notProvided.userSignupProcess"), value);
                break;
            case 'invited':
                color = 'red';
                title = format(t("addPerson.notProvided.userInvited"), value);
                break;
            case 'simplyList':
                color = 'gray';
                title = format(t("addPerson.notProvided.userSimplyList"), value);
                break;
            default:
                break;
        }

        return (
            <Row className='mb-3'>
                <Col className='font-size-15 text-left mt-1 align-horizontal'>
                    <User className="size-px-20 mr-2 align-self-flex-start" color={color} />
                    <Label className='mt-0 mb-0'>{title}</Label>
                </Col>
            </Row>
        )
    }

    providedUser = (user, index) => {
        let t = this.props.t;

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        let sameContacts = this.sameContacts(user);

        let emails = sameContacts.emails;
        let phones = sameContacts.phones;

        let contact = '';
        if (emails.length > 0 && phones.length > 0) {
            contact = emails + ' | ' + phones;
        }
        else if (emails.length > 0) {
            contact = emails;
        }
        else if (phones.length > 0) {
            contact = phones;
        }

        let introducedByUserDetails = user.introducedByUserDetails ?? [];
        let userInfo = '';
        if (introducedByUserDetails.length > 0) {
            let introducedByUser = introducedByUserDetails[0];
            userInfo = introducedByUser.firstName ?? '';
            if (introducedByUser.lastName?.length > 0) {
                userInfo += (userInfo.length > 0 ? ' ' : '') + introducedByUser.lastName;
            }

            let country = introducedByUser.country?.length == 0 ? '' : countryCodeToString(introducedByUser.country, countries);
            if (country.length > 0) {
                userInfo += (userInfo.length > 0 ? ', ' : '') + country;
            }
        }

        let title = '';
        let registrationStatus = this.registrationStatus(user);
        switch (registrationStatus) {
            case REGISTRATION_STATUS_REGISTERED:
                if (emails.length > 0 && phones.length > 0) {
                    title = format(t("addPerson.provided.userDatanexions"), 1, contact);
                }
                else if (emails.length > 0) {
                    title = format(t("addPerson.provided.userDatanexionsEmail"), 1, contact);
                }
                else if (phones.length > 0) {
                    title = format(t("addPerson.provided.userDatanexionsMobile"), 1, contact);
                }
                break;
            case REGISTRATION_STATUS_IN_PROCESS:
                if (emails.length > 0 && phones.length > 0) {
                    title = format(t("addPerson.provided.userSignupProcess"), 1, contact);
                }
                else if (emails.length > 0) {
                    title = format(t("addPerson.provided.userSignupProcessEmail"), 1, contact);
                }
                else if (phones.length > 0) {
                    title = format(t("addPerson.provided.userSignupProcessMobile"), 1, contact);
                }
                break;
            case REGISTRATION_STATUS_INVITED:
                if (emails.length > 0 && phones.length > 0) {
                    title = format(t("addPerson.provided.userInvited"), 1, userInfo, contact);
                }
                else if (emails.length > 0) {
                    title = format(t("addPerson.provided.userInvitedEmail"), 1, userInfo, contact);
                }
                else if (emails.length > 0) {
                    title = format(t("addPerson.provided.userInvitedMobile"), 1, userInfo, contact);
                }
                break;
            default:
                title = '';
                break;
        }

        let name = user.firstName ?? '';
        if (user.lastName?.length > 0) {
            name += (name.length > 0 ? ' ' : '') + user.lastName;
        }
        if (user.country?.length > 0) {
            let country = countryCodeToString(user.country, countries);
            if (country.length > 0) {
                name += (name.length > 0 ? ', ' : '') + country;
            }
        }
        if (contact.length > 0) {
            name += (name.length > 0 ? ' ' : '') + '(' + contact + ')';
        }

        let color = this.colorByRegistrationStatus(registrationStatus);

        const checked = this.state.selectedInternalUserIndex != null ? (this.state.selectedInternalUserIndex == index) : false;

        return (
            <Row className='mb-3' key={index}>
                <Col className='font-size-15 text-left mt-2 align-horizontal'>
                    <div className='mr-2 align-self-flex-start'>
                        <SimpleCheckbox checked={checked} didChange={(checked) => { this.didSelectUser(index, checked) }} />
                    </div>
                    <div className='align-horizontal'>
                        <User className="size-px-20 mr-2 align-self-flex-start" color={color} />
                        <Label className='mt-0 mb-0'>{name}</Label>
                    </div>
                </Col>
            </Row>
        )
    }

    didSelectUser = (index, checked) => {
        this.setState({ selectedInternalUserIndex: checked ? index : null });
    }

    registrationStatus = (user) => {
        const updated = user.dateUpdate != null;
        const requested = user.username?.length > 0;
        return requested ? (updated ? REGISTRATION_STATUS_REGISTERED : REGISTRATION_STATUS_IN_PROCESS) : (user.introducedByUserIds?.length > 0 ? REGISTRATION_STATUS_INVITED : REGISTRATION_STATUS_NONE);
    }

    colorByRegistrationStatus = (registrationStatus) => {
        let color = 'gray';
        switch (registrationStatus) {
            case REGISTRATION_STATUS_REGISTERED:
                color = 'green';
                break;
            case REGISTRATION_STATUS_IN_PROCESS:
                color = 'orange';
                break;
            case REGISTRATION_STATUS_INVITED:
                color = 'red';
                break;
            default:
                color = 'gray';
                break;
        }
        return color
    }

    messageForExistPeople = (internalUsers) => {
        let t = this.props.t;

        let sameEmail = this.state.emails.length > 0;
        let samePhone = this.state.phones.length > 0;
        if (this.state.emails.length > 0) {
            sameEmail = internalUsers.some((user) => { return this.sameContacts(user).emails.length > 0 });
        }
        if (this.state.phones.length > 0) {
            samePhone = internalUsers.some((user) => { return this.sameContacts(user).phones.length > 0 });
        }

        if (sameEmail && samePhone) {
            return t('addPerson.provided.existContactMessage');
        }
        else if (sameEmail) {
            return t('addPerson.provided.existEmailMessage');
        }
        else if (samePhone) {
            return t('addPerson.provided.existMobileMessage');
        }

        return t('addPerson.provided.existContactMessage');
    }

    messageForNewPerson = () => {
        let t = this.props.t;

        let hasEmail = this.state.emails.length > 0;
        let hasMobile = this.state.phones.length > 0;

        let newContact = this.state.emails.length > 0 ? this.state.emails[0].email : '';
        if (this.state.phones.length > 0) {
            newContact += (newContact?.length > 0 ? ' | ' : '') + this.state.phones[0].phone;
        }

        if (hasEmail && hasMobile) {
            return format(t('addPerson.newPersonMessage'), newContact);
        }
        else if (hasEmail) {
            return format(t('addPerson.newPersonMessage.email'), newContact);
        }
        else if (hasMobile) {
            return format(t('addPerson.newPersonMessage.mobile'), newContact);
        }

        return t('addPerson.newPersonMessage.noContact');
    }

    sameContacts = (user) => {
        let sameEmails = [];
        let samePhones = [];

        if (user.emails?.length > 0) {
            sameEmails = this.state.emails.filter((e) => { return user.emails.some((email) => { return email.email == e.email }) });
        }
        else if (user.email?.length > 0 && this.state.emails.length > 0) {
            sameEmails = this.state.emails.find((email) => { return email.email == user.email }) ? [this.state.emails.find((email) => { return email.email == user.email })] : [];
        }

        if (user.mobiles?.length > 0) {
            samePhones = this.state.phones.filter((phone) => { return user.mobiles.some((number) => { return number == phone }) });
        }
        else if (user.mobileNumber?.length > 0) {
            samePhones = this.state.phones.find((phone) => { return phone.phone == user.mobileNumber }) ? [this.state.phones.find((phone) => { return phone.phone == user.mobileNumber })] : [];
        }

        let emails = '';
        sameEmails.forEach(email => {
            emails += (emails.length > 0 ? ', ' : '') + email.email;
        });
        let phones = '';
        samePhones.forEach(phone => {
            phones += (phones.length > 0 ? ', ' : '') + internationalPhoneFormat(phone.phone);
        });

        return { phones: phones, emails: emails };
    }

    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.

        console.log('search', string, results);
        // if(string.length < 3) {
        //     this.setState({allNetworkPersons: []})
        // } else {
        //     this.props.getAllNetworkPersons(0, 0, false, string, {
        //         callbackOnSuccess: (response) => {
        //             console.log('on success', response);
        //             this.setState({allNetworkPersons: response})
        //         },
        //     });    
        // }
    }

    isConnected = (item) => {
        return this.state.allConnectedPersons.find(cp => cp.id === item.id);
    }

    handleOnHover = (result) => {
        // the item hovered
      }
    
    handleOnSelect = (item) => {
        if(this.isConnected(item)) {
            return false;
        }

        this.populateContact(item);

        this.setState({
           selectedInternalUserId: item.id
        });
    }
    
    handleOnFocus = () => {
    }
    
    formatResult = (item) => {
        const fullname = item.fullname;
        const email = item.emails?.[0].email;
        const mobile = item.mobiles?.[0].mobile;
        const connected = this.isConnected(item);

        return (
          <div style={{fontStyle: connected ? 'italic' : 'normal', color: connected ? '#6c757d' : 'inherit' }}>
            <span style={{ display: 'block', textAlign: 'left' }}>{fullname}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>{email}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>{mobile}</span>
          </div>
        )
      }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        let emailTypes = [{ value: EMAIL_TYPE_PERSONAL, label: t('email.personal') }, { value: EMAIL_TYPE_COMPANY, label: t('email.professional') }];

        const nextEnable = this.props.editingContact ? (this.state.addPersonStep != ADD_PERSON_IDENTIFICATION) : true;
        const isGoNext = this.state.firstName?.length > 0 && this.state.lastName?.length > 0 && this.state.selectedCountry != null;
        const saveEnable = this.props.editingContact ? (this.state.addPersonStep != ADD_PERSON_EXIST_PERSON) :
            (this.state.addPersonStep != ADD_PERSON_IDENTIFICATION &&
                this.state.addPersonStep != ADD_PERSON_EXIST_NETWORK &&
                this.state.addPersonStep != ADD_PERSON_YOURSELF &&
                this.state.addPersonStep != ADD_PERSON_EXIST_PERSON);
        const isCanSave = this.props.editingContact ? (this.state.firstName?.length > 0 && this.state.lastName?.length > 0 && this.state.selectedCountry != null)
            : ((this.state.addPersonStep == ADD_PERSON_NOT_PROVIDED || this.state.addPersonStep == ADD_PERSON_NEW) ? true : (this.state.selectedInternalUserIndex != null));

        let headerTitle = (this.props.editingContact ? t('contact.modifyContact') : t('network.addPerson')) + ' - ';
        let nextButtonTitle = t("sub.Next");
        let saveButtonTitle = t('personalInfo.save');

        let user = this.state.firstName ?? '';
        if (this.state.lastName?.length > 0) {
            user += (user.length > 0 ? ' ' : '') + this.state.lastName;
        }
        if (this.state.selectedCountry?.label?.length > 0) {
            user += (user.length > 0 ? ', ' : '') + this.state.selectedCountry.label;
        }

        switch (this.state.addPersonStep) {
            case ADD_PERSON_IDENTIFICATION:
                headerTitle += t('network.identification');
                nextButtonTitle = t("sub.Next");
                break;
            case ADD_PERSON_PROVIDED:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                saveButtonTitle = t("general.Add");
                break;
            case ADD_PERSON_NOT_PROVIDED:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                saveButtonTitle = t("experience.New");
                break;
            case ADD_PERSON_EXIST_NETWORK:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                saveButtonTitle = t("experience.New");
                break;
            case ADD_PERSON_YOURSELF:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                break;
            case ADD_PERSON_NEW:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                saveButtonTitle = t("updateCV.validate");
                break;
            case ADD_PERSON_EXIST_PERSON:
                headerTitle += user;
                nextButtonTitle = t("codeInput.previous");
                saveButtonTitle = '';
                break;
            case ADD_PERSON_CONFIRM:
                let existUserName = '';
                if (this.state.selectedInternalUserIndex != null) {
                    let selectedInternalUser = this.props.existUsers?.internalUsers[this.state.selectedInternalUserIndex];
                    existUserName = selectedInternalUser?.firstName ?? '';
                    if (selectedInternalUser?.lastName?.length > 0) {
                        existUserName += (existUserName.length > 0 ? ' ' : '') + selectedInternalUser.lastName;
                    }
                    if (selectedInternalUser.country?.length > 0) {
                        let country = countryCodeToString(selectedInternalUser.country, countries);
                        if (country.length > 0) {
                            existUserName += (existUserName.length > 0 ? ', ' : '') + country;
                        }
                    }
                }
                headerTitle += existUserName;
                break;
            default:
                saveButtonTitle = t('personalInfo.save');
                break;
        }

        const sameEmail = this.state.email.length > 0 ? this.state.emails.some((e) => { return e.email == this.state.email }) : false;
        const isReplacingEmail = sameEmail || (this.state.emailType?.value == EMAIL_TYPE_PERSONAL && this.state.emails.some((e) => { return e.type.value == EMAIL_TYPE_PERSONAL }));

        let internalUsers = this.state.selectedCountry ? (this.props.existUsers?.internalUsers ?? []) : [];
        let filteredInternalUsers = internalUsers.filter((user) => {
            let validFirstName = (user.firstName ?? '').toLowerCase() == (this.state.firstName ?? '').toLowerCase();
            let validLastName = (user.lastName ?? '').toLowerCase() == (this.state.lastName ?? '').toLowerCase();
            let validCountry = user.country == this.state.selectedCountry.value;
            let validEmail = this.state.emails.length == 0;
            if (this.state.emails.length > 0) {
                validEmail = (user.email ?? '') == this.state.emails[0].email;
            }
            let validMobile = this.state.phones.length == 0;
            if (this.state.phones.length > 0) {
                validMobile = (user.mobileNumber ?? '') == this.state.phones[0].phone;
            }
            return validFirstName && validLastName && validCountry && (validEmail || validMobile);
        });
        let existInternalUsers = internalUsers;

        // let existInternalUsers = internalUsers.filter((user) => {
        //     let validFirstName = user.firstName == this.state.firstName;
        //     let validLastName = user.lastName == this.state.lastName;
        //     let validCountry = user.country == this.state.selectedCountry.value;
        //     let validEmail = this.state.emails.length == 0;
        //     if (this.state.emails.length > 0) {
        //         validEmail = (user.email ?? '') == this.state.emails[0].email;
        //     }
        //     let validMobile = this.state.phones.length == 0;
        //     if (this.state.phones.length > 0) {
        //         validMobile = (user.mobileNumber ?? '') == this.state.phones[0].phone;
        //     }
        //     return !(validFirstName && validLastName && validCountry && (validEmail || validMobile));
        // });
        if (this.props.editingContact) {
            existInternalUsers = internalUsers.filter((user) => { return user.id != this.props.editingContact.id });
        }
        let messageForExistPeople = this.messageForExistPeople(existInternalUsers);
        let messageForNewPerson = this.messageForNewPerson();

        let existUserName = '';
        if (this.state.selectedInternalUserIndex != null) {
            let selectedInternalUser = this.props.existUsers?.internalUsers[this.state.selectedInternalUserIndex];
            existUserName = selectedInternalUser?.firstName ?? '';
            if (selectedInternalUser?.lastName?.length > 0) {
                existUserName += (existUserName.length > 0 ? ' ' : '') + selectedInternalUser.lastName;
            }
        }

        let sameNameUsers = this.state.selectedCountry ? (this.props.existUsers?.sameNameUsers ?? []).filter((user) => { return user.country == (this.state.selectedCountry?.value ?? '') }) : [];
        let notProvidedUsers = this.notProvidedUsersStatus(sameNameUsers);
          
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.addPersonStep == ADD_PERSON_CONFIRM ?
                                            <AvForm className="authentication-form">
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3 box-side'>
                                                            <Col className='font-size-15 text-left mt-2' >
                                                                <Label>{format(t("addPerson.addExistPersonMessage"), existUserName)}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col md={1} xl={1}></Col>
                                                    <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div cla>
                                                            <Button color="white" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.setState({ addPersonStep: ADD_PERSON_PROVIDED })
                                                            }}><i className='uil'></i>{t("subscriptions.NO")}</Button>
                                                            <Button className='ml-2' color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.createContact();
                                                            }}><i className='uil'></i>{t("subscriptions.YES")}</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                            :
                                            <AvForm className="authentication-form">
                                                {this.state.loading && <Loader />}
                                                {this.state.addPersonStep == ADD_PERSON_IDENTIFICATION &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <AvGroup>
                                                                <Row className='mt-2 mb-3'>
                                                                    <Col md={12} style={{ zIndex: 11}}>
                                                                        <div style={{ width: '100%' }} className='mb-3 autocomplete'>
                                                                            <ReactSearchAutocomplete
                                                                                items={this.state.allNetworkPersons}
                                                                                fuseOptions={{ 
                                                                                    keys: ["firstName", "lastName"],
                                                                                    minMatchCharLength: 3,
                                                                                    threshold: 0.6
                                                                                }}
                                                                                resultStringKeyName="fullname"
                                                                                onSearch={this.handleOnSearch}
                                                                                onHover={this.handleOnHover}
                                                                                onSelect={this.handleOnSelect}
                                                                                onFocus={this.handleOnFocus}
                                                                                autoFocus
                                                                                placeholder='Search existing'
                                                                                formatResult={this.formatResult}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                        <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.firstName")}</Label>
                                                                    </Col>
                                                                    <Col md={4} xl={8}>
                                                                        <InputGroup className='input-container'>
                                                                            <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("personalInfo.enterYourFirstName")}
                                                                                autoComplete="off"
                                                                                value={this.state.firstName ?? ""}
                                                                                onChange={(value, data, event, formattedValue) => {
                                                                                    this.setState({ firstName: data })
                                                                                }} required />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                            </AvGroup>
                                                            <AvGroup>
                                                                <Row className='mb-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15' for="lastName">{t("personalInfo.lastName")}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <InputGroup className='input-container'>
                                                                            <AvInput className='react-select' type="text" name="lastName" id="lastName" placeholder={t("personalInfo.enterYourLastName")}
                                                                                autoComplete="off"
                                                                                value={this.state.lastName ?? ""}
                                                                                onChange={(value, data, event, formattedValue) => {
                                                                                    this.setState({ lastName: data })
                                                                                }} required />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                            </AvGroup>
                                                            {/* <AvGroup> */}
                                                            <Row>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for='country'>{t("personalInfo.country")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        name="country" id="country"
                                                                        placeholder={t('placeholder.Select')}
                                                                        options={countries}
                                                                        value={this.state.selectedCountry ? countries?.filter((c) => { return c.value == this.state.selectedCountry.value }) : null}
                                                                        onChange={(value) => {
                                                                            this.setState({ selectedCountry: value });
                                                                        }}
                                                                        required
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {/* </AvGroup> */}
                                                        </Col>

                                                        <div className='width-percent-100 mt-3'>
                                                            <Col xl={12}>
                                                                <Row>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('profile.e-mails')}</Label>
                                                                        <PlusSquare
                                                                            className="icon-dual icon-size-20 ml-3"
                                                                            id="add-email"
                                                                            onClick={(e) => {
                                                                                e.currentTarget.blur();
                                                                                this.setState({ isAddingEmail: !this.state.isAddingEmail });
                                                                            }}
                                                                        />
                                                                        <UncontrolledTooltip placement="bottom" target="add-email">
                                                                            {t('network.addEmail')}
                                                                        </UncontrolledTooltip>
                                                                    </Col>
                                                                    {/* <Col>
                                                                </Col> */}
                                                                </Row>
                                                            </Col>
                                                            {(this.state.isAddingEmail || this.state.emails.length > 0) &&
                                                                <div className='border-1'>
                                                                    <Col xl={12}>
                                                                        {this.state.emails.length > 0 && this.state.emails.map((email, mailIndex) => {
                                                                            return (
                                                                                <Row key={mailIndex}>
                                                                                    <Col className='align-horizontal'>
                                                                                        <Label className='mt-2'>{email.email + ' | ' + email.type.label}</Label>
                                                                                        <div className='float-right width-px-40 box-center'>
                                                                                            <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-email' + mailIndex}
                                                                                                onClick={() => {
                                                                                                    let emails = this.state.emails;
                                                                                                    emails.splice(mailIndex, 1);
                                                                                                    this.setState({ emails: emails })
                                                                                                }} />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target={'remove-email' + mailIndex}>
                                                                                                {t("techGroup.hover.removeKeyword")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        })
                                                                        }

                                                                        {this.state.isAddingEmail &&
                                                                            <div>
                                                                                {this.state.emails.length > 0 &&
                                                                                    <Row className='mt-1'>
                                                                                        <Col>
                                                                                            <div className='footer-box-text-grey'></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                <AvGroup>
                                                                                    <Row className='mt-2 mb-1'>
                                                                                        <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                            <Label>{t('signUp.email')}</Label>
                                                                                            {/* <Label for="email">{t('signUp.email')} :</Label> */}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <InputGroup className='input-container'>
                                                                                                <AvInput className="react-select" type="email" name="email" id="email" placeholder={t('forgotPassword.enterYourEmail')}
                                                                                                    autoComplete="off"
                                                                                                    value={null}
                                                                                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                                                                                />
                                                                                            </InputGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvGroup>
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label>{t('updateCV.type')} :</Label>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Select className="react-select"
                                                                                            classNamePrefix="react-select"
                                                                                            placeholder={t('placeholder.Select')}
                                                                                            options={emailTypes}
                                                                                            value={this.state.emailType ? emailTypes.filter((type) => { return type.value == this.state.emailType.value }) : null}
                                                                                            onChange={(value) => {
                                                                                                this.setState({ emailType: value });
                                                                                            }}
                                                                                            getOptionLabel={e => (
                                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    {e.value == EMAIL_TYPE_PERSONAL ?
                                                                                                        <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                                                        <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                                                                    }
                                                                                                    <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={12} xl={12} className='box-side'>
                                                                                        <div></div>
                                                                                        <div className='align-horizontal'>
                                                                                            <Button color="white mr-2"
                                                                                                onClick={() => { this.setState({ isAddingEmail: false, email: '', emailType: null }) }}>
                                                                                                <i className='uil'></i>{t("modal.close")}
                                                                                            </Button>
                                                                                            <Button color="primary"
                                                                                                disabled={this.state.email.length == 0 || this.state.emailType == null}
                                                                                                onClick={() => { this.addEmail() }}>
                                                                                                <i className='uil'></i>{t(isReplacingEmail ? "updatePhoto.replace" : "general.Add")}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className='width-percent-100 mt-3'>
                                                            <Col xl={12}>
                                                                <Row>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('general.mobiles')}</Label>
                                                                        <PlusSquare
                                                                            className="icon-dual icon-size-20 ml-3"
                                                                            id="add-mobile"
                                                                            onClick={(e) => {
                                                                                e.currentTarget.blur();
                                                                                this.setState({ isAddingPhone: !this.state.isAddingPhone });
                                                                            }}
                                                                        />
                                                                        <UncontrolledTooltip placement="bottom" target="add-mobile">
                                                                            {t('network.addEmail')}
                                                                        </UncontrolledTooltip>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {(this.state.isAddingPhone || this.state.phones.length > 0) &&
                                                                <div className='border-1'>
                                                                    <Col xl={12}>
                                                                        {this.state.phones.length > 0 && this.state.phones.map((mobile, mobileIndex) => {
                                                                            return (
                                                                                <Row key={mobileIndex}>
                                                                                    <Col className='align-horizontal'>
                                                                                        <Label className='mt-2'>{internationalPhoneFormat(mobile.phone) + ' | ' + mobile.dial?.name}</Label>
                                                                                        <div className='float-right width-px-40 box-center'>
                                                                                            <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-email' + mobileIndex}
                                                                                                onClick={() => {
                                                                                                    let phones = this.state.phones;
                                                                                                    phones.splice(mobileIndex, 1);
                                                                                                    this.setState({ phones: phones })
                                                                                                }} />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target={'remove-email' + mobileIndex}>
                                                                                                {t("hover.removeMobile")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        }, this)}

                                                                        {this.state.isAddingPhone &&
                                                                            <div>
                                                                                {this.state.phones.length > 0 &&
                                                                                    <Row className='mt-1'>
                                                                                        <Col>
                                                                                            <div className='footer-box-text-grey'></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                                <Row className='mt-2 mb-1'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label>{t('reset.mobile')} :</Label>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <PhoneInput className='width-percent-100'
                                                                                            inputStyle={{ width: '100%' }}
                                                                                            containerClass={"phone-input-custom"}
                                                                                            country={(this.state.dial?.countryCode ?? 'fr').toLowerCase()}
                                                                                            value={this.state.phone ?? ''}
                                                                                            onChange={(value, data, event, formattedValue) => {
                                                                                                let phone = '';
                                                                                                if (data) {
                                                                                                    phone = '+' + data.dialCode + value.slice(data.dialCode.length);
                                                                                                }
                                                                                                this.setState({ dial: data, phone: phone })
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label>{t('updateCV.type')} :</Label>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Select className="react-select"
                                                                                            classNamePrefix="react-select"
                                                                                            placeholder={t('placeholder.Select')}
                                                                                            options={emailTypes}
                                                                                            value={this.state.phoneType ? emailTypes.filter((type) => { return type.value == this.state.phoneType.value }) : null}
                                                                                            onChange={(value) => {
                                                                                                this.setState({ phoneType: value });
                                                                                            }}
                                                                                            getOptionLabel={e => (
                                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    {e.value == EMAIL_TYPE_PERSONAL ?
                                                                                                        <User className="size-px-20 mr-2 align-self-flex-start" color='gray' /> :
                                                                                                        <SVG src={companyIcon} className="size-px-20 mr-2" color='gray' />
                                                                                                    }
                                                                                                    <span style={{ marginLeft: 3 }}>{e.label}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={12} xl={12} className='box-side'>
                                                                                        <div></div>
                                                                                        <div className='align-horizontal'>
                                                                                            <Button color="white mr-2"
                                                                                                onClick={() => { this.setState({ isAddingPhone: false, phone: '' }) }}>
                                                                                                <i className='uil'></i>{t("modal.close")}
                                                                                            </Button>
                                                                                            <Button color="primary"
                                                                                                disabled={this.state.phone.length == 0 || this.state.dial == null}
                                                                                                onClick={() => { this.addPhone() }}>
                                                                                                <i className='uil'></i>{t("general.Add")}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Row>
                                                }
                                                {this.state.addPersonStep == ADD_PERSON_NOT_PROVIDED &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3'>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label>{format(t("addPerson.notProvided.fisrtMessage"), sameNameUsers.length, this.state.selectedCountry?.label)}</Label>
                                                                </Col>
                                                            </Row>
                                                            {Object.keys(notProvidedUsers).length > 0 && Object.keys(notProvidedUsers).map((key, index) => {
                                                                return this.notProvidedUser(key, notProvidedUsers[key]);
                                                            })}
                                                            <Row className='mt-2 mb-3'>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label>{t("addPerson.notProvided.secondMessage")}</Label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }

                                                {this.state.addPersonStep == ADD_PERSON_PROVIDED &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3 box-side'>
                                                                <div>
                                                                    <Col className='font-size-15 text-left mt-2' >
                                                                        <Label>{messageForExistPeople}</Label>
                                                                    </Col>
                                                                </div>
                                                            </Row>
                                                            {internalUsers.length > 0 && internalUsers.map((user, index) => {
                                                                return this.providedUser(user, index)
                                                            })
                                                            }
                                                        </Col>
                                                    </Row>
                                                }

                                                {this.state.addPersonStep == ADD_PERSON_EXIST_PERSON &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3 box-side'>
                                                                <div>
                                                                    <Col className='font-size-15 text-left mt-2' >
                                                                        <Label className='text-color-alert'>{messageForExistPeople}</Label>
                                                                        {/* <img src={information} id="intro-help" className="icon-size-20 ml-3 mr-2" onClick={() => {
                                                                        this.setState({ showExistPeople: !this.state.showExistPeople });
                                                                    }} />
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        id='tooltip-1'
                                                                        target='intro-help'>
                                                                        {t("hover.existContactDetails")}
                                                                    </UncontrolledTooltip> */}
                                                                    </Col>
                                                                </div>
                                                            </Row>

                                                            {this.state.showExistPeople && existInternalUsers.length > 0 && existInternalUsers.map((user, index) => {
                                                                let registrationStatus = this.registrationStatus(user);
                                                                let color = this.colorByRegistrationStatus(registrationStatus);
                                                                let userInfo = user.firstName ?? '';
                                                                if (user.lastName?.length > 0) {
                                                                    userInfo += (userInfo.length > 0 ? ' ' : '') + user.lastName;
                                                                }
                                                                let country = user.country?.length == 0 ? '' : countryCodeToString(user.country, countries);
                                                                if (country.length > 0) {
                                                                    userInfo += (userInfo.length > 0 ? ', ' : '') + country;
                                                                }

                                                                let sameContacts = this.sameContacts(user);
                                                                if (sameContacts.phones.length > 0) {
                                                                    userInfo += (userInfo.length > 0 ? ', ' : '') + sameContacts.phones;
                                                                }
                                                                if (sameContacts.emails.length > 0) {
                                                                    userInfo += (userInfo.length > 0 ? ', ' : '') + sameContacts.emails;
                                                                }

                                                                return (
                                                                    <Row className='mb-3'>
                                                                        <Col className='font-size-15 text-left align-horizontal'>
                                                                            <div className='align-horizontal'>
                                                                                <User className="size-px-20 mr-2 align-self-flex-start" color={color} />
                                                                                <Label className='mt-0 mb-0'>{userInfo}</Label>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                            }
                                                        </Col>
                                                    </Row>
                                                }

                                                {this.state.addPersonStep == ADD_PERSON_EXIST_NETWORK &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3'>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label className='text-color-alert'>{t('addPerson.networkExistMessage')}</Label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }

                                                {this.state.addPersonStep == ADD_PERSON_YOURSELF &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3'>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label className='text-color-alert'>{t('You cannot duplicate yourself into a new Person')}</Label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }

                                                {this.state.addPersonStep == ADD_PERSON_NEW &&
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className='mt-2 mb-3'>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label>{messageForNewPerson}</Label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }

                                                <br />

                                                <Row>
                                                    <Col md={1} xl={1}></Col>
                                                    <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                        <div>
                                                            <Button color="white" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.props.close(null);
                                                            }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                            {nextEnable &&
                                                                <Button className='ml-2' color="primary" disabled={!isGoNext} onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    if (this.state.addPersonStep == ADD_PERSON_IDENTIFICATION) {
                                                                        this.onCheckUsers();
                                                                    }
                                                                    else {
                                                                        this.onBack()
                                                                    }

                                                                }}><i className='uil'></i>{nextButtonTitle}</Button>
                                                            }
                                                        </div>
                                                        <div>
                                                            {saveEnable &&
                                                                <Button className='ml-2' disabled={!isCanSave} color="primary" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    if (this.props.editingContact) {
                                                                        this.onEditContact()
                                                                    }
                                                                    else {
                                                                        this.onContactAction()
                                                                    }
                                                                }}><i className='uil'></i>{saveButtonTitle}</Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { network, existUsers } = state.Network;
    return { country, countries, network, existUsers, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    getAllNetworkPersons,
    checkExistUsers,
    createContact,
    editContact,
    resetExistUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ContactModal));