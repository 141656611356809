// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {getCurrentLanguage} from '../../helpers/utils';
import {getLoggedInUser} from '../../helpers/authUtils';
import {BASE_URL} from './../constants';
import {getEventSuccess, getEventFailed, editEventAtendancyFailed, editEventAtendancySuccess,
createEventAttendancyFailed, createEventAttendancySuccess, getEventByTypeFailed, getEventByTypeSuccess,
getEventByIdFailed, getEventByIdSuccess, deleteEventAttendancySuccess, deleteEventAttendancyFailed} from './actions';
import {
    GET_EVENT, EDIT_EVENT_ATTENDANCY, CREATE_EVENT_ATTENDANCY, DELETE_EVENT_ATTENDANCY, GET_EVENT_BY_TYPE_ID, GET_EVENT_BY_ID
} from './constants';

function* getEvent({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/event/", options);
        yield put(getEventSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getEventFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getEventByType({ payload: { idType, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/event/" + idType, options);
        yield put(getEventByTypeSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getEventByTypeFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getEventById({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/event/find-by-id/" + id, options);
        yield put(getEventByIdSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getEventByIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editEventAtendancy({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/event/edit/" + id, options);
        yield put(editEventAtendancySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editEventAtendancyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteEventAttendancy({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/event/" + id, options);
        yield put(deleteEventAttendancySuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(deleteEventAttendancyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createEventAttendancyEntity({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/event/create", options);
        yield put(createEventAttendancySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createEventAttendancyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetEvent() {
    yield takeEvery(GET_EVENT, getEvent);
}

export function* watchGetEventById() {
    yield takeEvery(GET_EVENT_BY_ID, getEventById);
}

export function* watchGetEventByType() {
    yield takeEvery(GET_EVENT_BY_TYPE_ID, getEventByType);
}

export function* watchEditEventAttendancy() {
    yield takeEvery(EDIT_EVENT_ATTENDANCY, editEventAtendancy);
}

export function* watchcreateEventAttendancyEntity() {
    yield takeEvery(CREATE_EVENT_ATTENDANCY, createEventAttendancyEntity);
}

export function* watchDeleteEventAttendancy() {
    yield takeEvery(DELETE_EVENT_ATTENDANCY, deleteEventAttendancy);
}

function* repoEventSaga() {
    yield all([fork(watchGetEvent), fork(watchEditEventAttendancy), fork(watchcreateEventAttendancyEntity)
        , fork(watchDeleteEventAttendancy), fork(watchGetEventByType), fork(watchGetEventById)]);
}

export default repoEventSaga;
