// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { childrenOfObject, countryCodeToString, getLocalizedString, locationString } from '../../../../helpers/utils';
import { getAPICountryInfo, getAllCompanies2, getAllContentsCompany, getCompanySpecialties, checkExistCompany, checkExistNetworkDomain, checkExistCompanyDomains, createNetworkCompany, editNetworkCompany, addExistCompanyToNetwork, getAllNetworkCompanies } from '../../../../redux/actions';
import { PlusSquare, Trash, User } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import information from '../../../../assets/images/information.png';
import SVG from 'react-inlinesvg';
import SimpleCheckbox from '../../Supports/SimpleCheckbox';
import { getLoggedInUser } from '../../../../helpers/authUtils';
import isValidDomain from 'is-valid-domain';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export const EMAIL_TYPE_PERSONAL = 0;
export const EMAIL_TYPE_COMPANY = 1;

export const ADD_COMPANY_IDENTIFICATION = 0;
export const ADD_COMPANY_SPECIALTY = 1;
export const ADD_COMPANY_EXIST_COMPANY = 3;
export const ADD_COMPANY_EXIST_DOMAIN = 4;
export const ADD_COMPANY_NEW = 5;
export const ADD_COMPANY_NETWORKED = 6;
export const ADD_COMPANY_LOCATION = 7;

class AddCompanyModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'xx',
            addCompanyStep: ADD_COMPANY_IDENTIFICATION,
            companyName: '',
            geoType: null,
            hqCountry: null,
            hqState: null,
            hqCity: '',
            isAddingDomain: false,
            domains: [],
            domain: '',
            isDomain: true,
            domainOwner: null,
            isAddingActivity: false,
            selectedActivity: null,
            activities: [],
            selectingSpecialtyIndex: null,
            isAddingSpecialty: false,
            selectedSpecialty: null,
            specialties: [],
            showExistDomains: false,
            didCheckedCompany: false,
            useSameName: false,
            didCheckedDomains: false,
            existDomains: [],
            existCompanies: [],
            selectedCompany: null,
            industryCategories: []
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }

        if (this.props.allNetworkCompanies == null) {
            this.props.getAllNetworkCompanies({});
        }

        console.log(this.props.getAllCompanies);
        this.props.getAllCompanies2(0, 0, false, "", {
            callbackOnSuccess: (response) => {
                console.log('on success', response);
                this.setState({
                    allCompanies: response.all_companies,
                    allConnectedCompanies: response.connected_companies
                })
            },
        });

        this.props.getAllContentsCompany({
            callbackOnSuccess: (response) => {
                let geoTypes = this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
                    (element, i) => {
                        return { value: i, label: getLocalizedString(element.value), en: element.value.en, id: element.id };
                    }
                );
                let geoType = this.props.editingCompany?.geoTypeId ? geoTypes?.find((type) => { return type.id == this.props.editingCompany?.geoTypeId }) : null;
                this.setState({ geoType: geoType });
            }
        });

        this.props.getCompanySpecialties({
            callbackOnSuccess: (response) => {
                let activities = this.state.activities;
                (this.props.editingCompany?.industryGroupIds ?? []).forEach(element => {
                    let allSpecialties = this.props.companySpecialties?.find((s) => { return s.industryGroupId == element?.id })?.specialties ?? [];
                    allSpecialties = allSpecialties.map((s) => {
                        s.label = getLocalizedString(s.name);
                        return s;
                    });

                    let specialties = [];
                    if (element?.specialties?.length > 0 && allSpecialties.length > 0) {
                        specialties = element.specialties.map((s) => { return allSpecialties.find((specialty) => { return specialty.order == s }) });
                    }

                    let index = activities.findIndex((act) => { return act.activity?.id == element.id });
                    if (index >= 0) {
                        activities[index].specialties = specialties;
                    }
                });

                this.setState({ activities: activities });
            }
        });

        this.defaultIndustryCategories();

        if (this.props.editingCompany) {
            this.populateCompany(this.props.editingCompany);
        }
    }

    handleOnSelect = (item) => {
        if(this.isConnected(item)) {
            return false;
        }

        this.populateCompany(item);

        this.setState({
           selectedInternalCompanyId: item.id
        });
    }

    populateCompany = (editingCompany) => {
        let companyName = editingCompany?.name ?? '';
        let geoTypes = this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
            (element, i) => {
                return { value: i, label: getLocalizedString(element.value), en: element.value.en, id: element.id };
            }
        );
        let geoType = editingCompany?.geoTypeId ? geoTypes?.find((type) => { return type.id == editingCompany?.geoTypeId }) : null;

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );
        let hqCountry = editingCompany?.countryHQ ? countries?.find((type) => { return type.value == editingCompany?.countryHQ }) : null;
        let states = (hqCountry?.states ?? []).map((element) => { return { value: element.id, label: getLocalizedString(element.name) } });
        let hqState = editingCompany?.stateHQ ? states?.find((type) => { return type.value == editingCompany?.stateHQ }) : null;
        let hqCity = editingCompany?.cityHQ ?? '';
        let domains = editingCompany?.domains ?? [];

        let allActivities = this.companyIndustriesFromMenuHierarchy().filter((element) => { return !this.state.activities.some((e) => { return e.activity.id == element.id }) });
        let activities = [];
        (editingCompany?.industryGroupIds ?? []).forEach(element => {
            let activity = allActivities.find((e) => { return e.id == element.id });
            if (activity) {
                let allSpecialties = this.props.companySpecialties?.find((s) => { return s.industryGroupId == element?.id })?.specialties ?? [];
                allSpecialties = allSpecialties.map((s) => {
                    s.label = getLocalizedString(s.name);
                    return s;
                });
                let specialties = [];
                if (element?.specialties?.length > 0 && allSpecialties.length > 0) {
                    specialties = element.specialties.map((s) => { return allSpecialties.find((specialty) => { return specialty.order == s }) });
                }
                activities.push({ activity: activity, selected: element?.specialties?.length > 0, specialties: specialties });
            }
        });

        this.setState({
            companyName: companyName,
            geoType: geoType,
            hqCountry: hqCountry,
            hqState: hqState,
            hqCity: hqCity,
            domains: domains,
            activities: activities
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.menuHierarchy != this.props.menuHierarchy && this.state.industryCategories.length == 0) {
            this.defaultIndustryCategories();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    checkExistCompany = () => {
        if (this.props.editingCompany?.name == this.state.companyName) {
            this.setState({ didCheckedCompany: true, useSameName: true }, () => {
                this.setState({ addCompanyStep: ADD_COMPANY_LOCATION });
            })
        }
        else {
            this.props.checkExistCompany({ 'name': this.state.companyName }, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },

                callbackOnSuccess: (response) => {
                    this.setState({ didCheckedCompany: true }, () => {
                        if (response.length > 0) {
                            this.setState({ existCompanies: response, addCompanyStep: ADD_COMPANY_EXIST_COMPANY })
                        }
                        else {
                            this.setState({ addCompanyStep: ADD_COMPANY_LOCATION });
                        }
                    })
                },
            });
        }
    }

    checkExistCompanyDomains = () => {
        let needCheck = false;
        if (this.state.domains.length > 0) {
            if (this.props.editingCompany?.domains?.length > 0) {
                let defaultDomains = this.props.editingCompany?.domains ?? [];
                needCheck = this.state.domains.filter(x => !defaultDomains.includes(x)).length > 0;
            }
            else {
                needCheck = true;
            }
        }

        if (needCheck) {
            this.props.checkExistCompanyDomains(this.state.domains, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },

                callbackOnSuccess: (response) => {
                    this.setState({ didCheckedDomains: true }, () => {
                        let notMine = response;
                        if (this.props.editingCompany?.domains?.length > 0) {
                            notMine = response.filter(x => !this.props.editingCompany?.domains.includes(x.domain))
                        }

                        if (notMine.length > 0) {
                            this.setState({ existDomains: notMine, addCompanyStep: ADD_COMPANY_EXIST_DOMAIN })
                        }
                        else {
                            this.checkExistCompany();
                        }
                    })
                },
            })
        }
        else {
            this.setState({ didCheckedDomains: true }, () => {
                this.checkExistCompany();
            })
        }
    }

    addExistCompanyToNetwork = () => {
        let existCompany = this.state.selectedExistCompanyIndex && this.state.existDomains[this.state.selectedExistCompanyIndex].data?.id;
        if (existCompany || this.state.selectedInternalCompanyId) {
            let allNetworkCompanies = this.props.allNetworkCompanies ?? [];
            if (allNetworkCompanies.some((c) => { return c.id == existCompany })) {
                this.setState({ addCompanyStep: ADD_COMPANY_NETWORKED });
            }
            else {
                this.props.addExistCompanyToNetwork(existCompany || this.state.selectedInternalCompanyId, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.getAllNetworkCompanies({});
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                });
            }
        }
    }

    onCompanyAction = () => {
        let params = {};

        if (this.state.selectedCompany != null) {
            params.companyId = this.state.selectedCompany.id;
        }
        else {
            params.name = this.state.companyName;
            params.useSameName = this.state.useSameName;
            params.geoType = this.state.geoType.id;
            params.activities = this.state.activities.map((act) => { return { id: act.activity.id, specialties: act.specialties.map((s) => { return s.order }) } });
            params.hqCountry = this.state.hqCountry.value;
            params.hqCity = this.state.hqCity;
            if (this.state.hqCountry.states?.length > 0 && this.state.hqState) {
                params.hqState = this.state.hqState.value;
            }
            if (this.state.domains.length > 0) {
                params.domains = this.state.domains;
            }
        }

        if (this.props.editingCompany) {
            if (this.props.editingCompany?.id) {
                this.props.editNetworkCompany(this.props.editingCompany?.id, params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                })
            }
        }
        else {
            this.props.createNetworkCompany(params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnSuccess: (response) => {
                    this.props.getAllNetworkCompanies({});
                    this.props.close(response);
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                }
            })
        }
    }

    onEditContact = () => {

    }

    checkDomain = () => {
        if (this.state.domain == null || this.state.domain?.length == 0) {
            return;
        }
        let domains = this.state.domains;
        if (!domains.some((element) => { return element == this.state.domain })) {
            let isDomain = isValidDomain(this.state.domain);
            if (isDomain) {
                this.props.checkExistNetworkDomain(this.state.domain, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        if (response?.prefix == 'Node::Company::' && this.props.editingCompany != null) {
                            this.setState({ domainOwner: response });
                        }
                        else if (response?.prefix == 'Node::Academy::') {
                            this.setState({ domainOwner: response });
                        }
                        else {
                            this.addDomain();
                        }
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                });
            }
            else {
                this.setState({ isDomain: false });
            }
        }
    }

    domainOwnerToString = () => {
        let response = this.state.domainOwner;
        if (response?.prefix == 'Node::Academy::') {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let domainOwner = response.name ?? '';
            if (response.cities?.length > 0) {
                let location = locationString(response.cities[0].country, response.cities[0].state, response.cities[0].city, countries);
                if (location?.length > 0) {
                    domainOwner += (domainOwner.length > 0 ? ', ' : '') + location;
                }
            }
            return domainOwner;
        }
        else if (response?.prefix == 'Node::Company::') {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );
            let domainOwner = response.name ?? '';
            let location = locationString(response.countryHQ, response.stateHQ, response.cityHQ, countries);
            if (location?.length > 0) {
                domainOwner += (domainOwner.length > 0 ? ', ' : '') + location;
            }

            return domainOwner;
        }

        return '';
    }

    addDomain = () => {
        let domains = this.state.domains;
        if (!domains.some((element) => { return element == this.state.domain })) {
            domains.push(this.state.domain);
        }
        this.setState({ domains: domains, isAddingDomain: false, domain: '', didCheckedDomains: false, existDomains: [], isDomain: true, domainOwner: null })
    }

    addActivity = () => {
        let activities = this.state.activities;
        let selectedActivity = this.selectedIndustryGroup(this.state.industryCategories);
        if (!activities.some((element) => { return element.activity.id == selectedActivity.id })) {
            activities.push({ activity: selectedActivity, selected: false, specialties: [] });
        }
        this.setState({ activities: activities, isAddingActivity: false });
        this.defaultIndustryCategories();
    }

    addSpecialty = (activity, specialty) => {
        let activities = this.state.activities;
        let index = activities.findIndex((act) => { return act.activity.id == activity.activity.id });
        if (index >= 0) {
            let specialtyIndex = activities[index].specialties.findIndex((s) => { return s.order == specialty.order });
            if (specialtyIndex >= 0) {
                activities[index].specialties.splice(specialtyIndex, 1);
            }
            else {
                activities[index].specialties.push(specialty);
            }
        }
        this.setState({ activities: activities, isAddingSpecialty: false, selectedSpecialty: null })
    }

    onBack = () => {
        let didCheckedDomains = this.state.didCheckedDomains;

        this.setState({
            addCompanyStep: this.state.addCompanyStep == ADD_COMPANY_NETWORKED ? (this.state.selectedExistCompanyIndex != null ? ADD_COMPANY_EXIST_DOMAIN : ADD_COMPANY_EXIST_COMPANY) : ADD_COMPANY_IDENTIFICATION,
            didCheckedDomains: didCheckedDomains,
            isAddingActivity: false, isAddingSpecialty: false, selectedActivity: null, selectedSpecialty: null, selectingSpecialtyIndex: null, selectedExistCompanyIndex: null
        });
    }

    onNext = () => {
        if (this.state.addCompanyStep == ADD_COMPANY_IDENTIFICATION) {
            this.checkExistCompanyDomains();
        }
        else {
            this.setState({ addCompanyStep: ADD_COMPANY_SPECIALTY, isAddingActivity: false, isAddingSpecialty: false, selectedActivity: null, selectedSpecialty: null, selectingSpecialtyIndex: null });
        }
    }

    defaultIndustryCategories = () => {
        let firstIndustry = this.props.menuHierarchy?.industryGroup;
        if (firstIndustry == null) {
            this.setState({ industryCategories: [] });
        }
        if (firstIndustry?.select ?? false) {
            firstIndustry.label = firstIndustry.name;
            this.setState({ industryCategories: [{ industries: [firstIndustry], selectedIndustry: null }] });
        }
        else {
            let firstIndustries = firstIndustry?.children?.map((element) => { element.label = element.name; return element });
            this.setState({ industryCategories: [{ industries: firstIndustries, selectedIndustry: null }] });
        }
    }

    selectIndustry = (index, selectedValue) => {
        let industryCategories = this.state.industryCategories;
        industryCategories[index].selectedIndustry = selectedValue;
        industryCategories.length = index + 1;

        if (selectedValue.children?.length > 0) {
            let objects = selectedValue.children.map((element) => { element.label = element.name; return element });
            if (objects?.length > 0) {
                objects = objects.filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
            }
            industryCategories.push({ industries: objects, selectedIndustry: null });
        }

        this.setState({ industryCategories: industryCategories });
    }

    selectedIndustryGroup = (industryCategories) => {
        let selectedIndustry = null;
        if (industryCategories?.length > 0) {
            selectedIndustry = industryCategories[industryCategories.length - 1].selectedIndustry;
        }
        if (selectedIndustry == null && industryCategories?.length > 1) {
            selectedIndustry = industryCategories[industryCategories.length - 2].selectedIndustry;
        }
        return selectedIndustry;
    }

    companyIndustriesFromMenuHierarchy = () => {
        let children = childrenOfObject(this.props.menuHierarchy?.industryGroup).filter((element) => { return element.id != this.props.menuHierarchy?.industryGroup?.id }).map(
            (child, i) => {
                return {
                    id: child.id, value: i, label: child.name, child: child.children
                };
            }
        );

        let industries = children ?? [];
        return industries
    }

    didSelectExistCompany = (index, checked) => {
        this.setState({ selectedExistCompanyIndex: checked ? index : null });
    }

    checkNextHidden = () => {
        let nextHidden = true;
        if (this.state.addCompanyStep == ADD_COMPANY_IDENTIFICATION) {
            nextHidden = false;
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_LOCATION && this.state.activities.length > 0 && this.props.companySpecialties?.length > 0) {
            for (let idx = 0; idx < this.state.activities.length; idx++) {
                let hasSpecialties = this.props.companySpecialties.some((s) => { return (s.industryGroupId == this.state.activities[idx].activity.id) && s.specialties?.length > 0 });
                if (hasSpecialties) {
                    nextHidden = false;
                    break;
                }
            }
        }
        return nextHidden;
    }

    checkSaveable = () => {
        console.log('checkSaveable', this.state.selectedInternalCompanyId)
        if(this.state.selectedInternalCompanyId)
            return true;

        let saveEnable = true;
        if (this.state.addCompanyStep == ADD_COMPANY_IDENTIFICATION) {
            saveEnable = this.state.companyName.length > 0 && this.state.geoType != null && !this.state.isAddingDomain;
            let states = this.state.hqCountry?.states ?? [];
            if (saveEnable == true && states.length > 0) {
                saveEnable = this.state.hqState != null
            }
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_LOCATION) {
            saveEnable = this.state.activities.length > 0 && this.state.hqCountry != null && this.state.hqCity.length > 0 && !this.state.isAddingActivity;
            let states = this.state.hqCountry?.states ?? [];
            if (saveEnable == true && states.length > 0) {
                saveEnable = this.state.hqState != null
            }
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_SPECIALTY) {
            // saveEnable = currentSpecialties.length > 0;
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_EXIST_COMPANY) {
            saveEnable = this.state.selectedCompany != null;
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_EXIST_DOMAIN) {
            saveEnable = this.state.selectedExistCompanyIndex != null;
        }
        return saveEnable;
    }

    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log('search', string);
    }

    handleOnClear = () => {
        console.log('clear');
        this.populateCompany(null);

        this.setState({
            selectedInternalCompanyId: null
        });
    }

    isConnected = (item) => {
        return this.state.allConnectedCompanies.find(cp => cp.id === item.id);
    }

    formatResult = (item) => {
        const name = item.name;
        const connected = this.isConnected(item);

        return (
          <div style={{fontStyle: connected ? 'italic' : 'normal', color: connected ? '#6c757d' : 'inherit' }}>
            <span style={{ display: 'block', textAlign: 'left' }}>{name}</span>
          </div>
        )
    }

    onValidate = () => {
        if ((this.state.selectedExistCompanyIndex != null) || (this.state.selectedInternalCompanyId != null)) {
            this.addExistCompanyToNetwork();
        }
        else if (this.state.addCompanyStep == ADD_COMPANY_LOCATION || this.state.addCompanyStep == ADD_COMPANY_SPECIALTY) {
            this.onCompanyAction();
        }
        else if (this.state.didCheckedCompany && this.state.didCheckedDomains) {
            let loggedInUser = getLoggedInUser();
            if (this.state.selectedCompany?.createdByUserId == loggedInUser?.id || (this.state.selectedCompany?.members ?? []).some((element) => { return element == loggedInUser.id })) {
                this.setState({ addCompanyStep: ADD_COMPANY_NETWORKED });
            }
            else {
                this.setState({ useSameName: true }, () => {
                    this.setState({ addCompanyStep: ADD_COMPANY_LOCATION });
                })
            }
        }
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        let geoTypes = this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
            (element, i) => {
                return { value: i, label: getLocalizedString(element.value), en: element.value.en, id: element.id };
            }
        );

        let states = this.state.hqCountry?.states ?? [];
        states = states.map((element) => { return { value: element.id, label: getLocalizedString(element.name) } });

        let allActivities = this.companyIndustriesFromMenuHierarchy().filter((element) => { return !this.state.activities.some((e) => { return e.activity?.id == element.id }) });
        let selectedActivities = this.state.activities.filter((element) => {
            return this.props.companySpecialties?.find((s) => { return s.industryGroupId == element.activity?.id })?.specialties?.length > 0
        }).map((a) => { a.label = a.activity?.label; return a });

        const nextHidden = this.checkNextHidden();
        const saveHidden = (!this.state.selectedInternalCompanyId) && ((this.state.addCompanyStep == ADD_COMPANY_LOCATION) ? !nextHidden : (this.state.addCompanyStep == ADD_COMPANY_IDENTIFICATION || this.state.addCompanyStep == ADD_COMPANY_NETWORKED || (this.state.addCompanyStep == ADD_COMPANY_EXIST_COMPANY && this.props.editingCompany)));
        const saveEnable = this.checkSaveable();

        let headerTitle = (this.props.editingCompany ? t('network.modifyCompany') : t('network.addCompany')) + ' - ';
        switch (this.state.addCompanyStep) {
            case ADD_COMPANY_IDENTIFICATION:
                headerTitle += t('network.identification');
                break;
                case ADD_COMPANY_LOCATION:
                headerTitle += this.state.companyName;
                break;
            case ADD_COMPANY_EXIST_COMPANY:
                headerTitle += this.state.companyName;
                break;
            case ADD_COMPANY_EXIST_DOMAIN:
                headerTitle += this.state.companyName;
                break;
            case ADD_COMPANY_SPECIALTY:
                headerTitle += this.state.companyName;
                // if (currentActivity?.activity.label?.length > 0) {
                //     headerTitle += ', ' + currentActivity?.activity.label;
                // }
                break;
            default:
                break;
        }

        let domainOwnerName = this.domainOwnerToString();

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}
                                            {this.state.addCompanyStep == ADD_COMPANY_IDENTIFICATION &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2'>
                                                            <Col md={12} style={{ zIndex: 11}}>
                                                                <div style={{ width: '100%' }} className='mb-3 autocomplete'>
                                                                    <ReactSearchAutocomplete
                                                                        items={this.state.allCompanies}
                                                                        fuseOptions={{ 
                                                                            keys: ["name"],
                                                                            minMatchCharLength: 3,
                                                                            threshold: 0.6
                                                                        }}
                                                                        resultStringKeyName="name"
                                                                        onSearch={this.handleOnSearch}
                                                                        onHover={this.handleOnHover}
                                                                        onSelect={this.handleOnSelect}
                                                                        onFocus={this.handleOnFocus}
                                                                        onClear={this.handleOnClear}
                                                                        autoFocus
                                                                        placeholder='Search existing'
                                                                        formatResult={this.formatResult}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("sub.Companyname")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <InputGroup className='input-container'>
                                                                    <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("company.enterName")}
                                                                        autoComplete="off"
                                                                        value={this.state.companyName ?? ""}
                                                                        onChange={(value, data, event, formattedValue) => {
                                                                            this.setState({ companyName: data, existCompanies: [], didCheckedCompany: false, useSameName: false })
                                                                        }} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='country'>{t("career.CompanyGeoType")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="country"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={geoTypes}
                                                                    value={this.state.geoType ? geoTypes?.filter((c) => { return c.value == this.state.geoType.value }) : null}
                                                                    onChange={(value) => {
                                                                        this.setState({ geoType: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <div className='width-percent-100 mt-3'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{t('company.domains')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="add-domain"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingDomain: !this.state.isAddingDomain });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="add-domain">
                                                                        {t('network.addDomain')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingDomain || this.state.domains.length > 0) &&
                                                            <div className='border-1'>
                                                                <Col xl={12}>
                                                                    {this.state.domains.length > 0 && this.state.domains.map((domain, mailIndex) => {
                                                                        return (
                                                                            <Row key={mailIndex}>
                                                                                <Col className='align-horizontal'>
                                                                                    <Label className='mt-2'>{domain}</Label>
                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-domain' + mailIndex}
                                                                                            onClick={() => {
                                                                                                let domains = this.state.domains;
                                                                                                domains.splice(mailIndex, 1);
                                                                                                this.setState({ domains: domains })
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target={'remove-domain' + mailIndex}>
                                                                                            {t("techGroup.hover.removeDomain")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })
                                                                    }

                                                                    {this.state.isAddingDomain &&
                                                                        <div>
                                                                            {this.state.domains.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <AvGroup>
                                                                                <Row className='mt-2 mb-1'>
                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                        <Label>{t('company.domain')} :</Label>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <InputGroup className='input-container'>
                                                                                            <AvInput className="react-select" type="domain" name="domain" id="domain" placeholder={null}
                                                                                                autoComplete="off"
                                                                                                value={this.state.domain}
                                                                                                onChange={(e) => { this.setState({ domain: e.target.value }) }}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </AvGroup>

                                                                            {!this.state.isDomain &&
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col className='path-component font-size-15 text-left'>
                                                                                        <Label className='font-size-15 text-color-alert'>{t('network.invalidDomain')}</Label>
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            {this.state.domainOwner != null &&
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col className='path-component font-size-15 text-left'>
                                                                                        <Label className='font-size-15 text-color-alert'>{format(t(this.state.domainOwner?.prefix == 'Node::Academy::' ? 'network.domainBelongToAcademics' : 'network.domainBelongToCompany'), domainOwnerName)}</Label>
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => {
                                                                                                let state = { domain: '', isDomain: true, domainOwner: null };
                                                                                                if (this.state.domainOwner == null && this.state.isDomain) {
                                                                                                    state.isAddingDomain = false;
                                                                                                }
                                                                                                this.setState(state);
                                                                                            }}>
                                                                                            <i className='uil'></i>{t((this.state.domainOwner == null && this.state.isDomain) ? "modal.close" : 'academic.erase')}
                                                                                        </Button>
                                                                                        {this.state.domainOwner == null && this.state.isDomain &&
                                                                                            <Button color="primary"
                                                                                                disabled={this.state.domain.length == 0}
                                                                                                onClick={() => { this.checkDomain() }}>
                                                                                                <i className='uil'></i>{t("general.Add")}
                                                                                            </Button>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>
                                                </Row>
                                            }

                                            {this.state.addCompanyStep == ADD_COMPANY_LOCATION &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                <Label className='link-selected font-size-15' for='country'>{t("personalInfo.country")}</Label>
                                                            </Col>
                                                            <Col md={8} xl={8}>
                                                                <Select className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="country" id="country"
                                                                    placeholder={t('placeholder.Select')}
                                                                    options={countries}
                                                                    value={this.state.hqCountry ? countries?.filter((c) => { return c.value == this.state.hqCountry.value }) : null}
                                                                    onChange={(value) => {
                                                                        this.setState({ hqCountry: value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        {states.length > 0 &&
                                                            <Row className='mt-3'>
                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15' for='country'>{t("personalInfo.state")}</Label>
                                                                </Col>
                                                                <Col md={8} xl={8}>
                                                                    <Select className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        name="states" id="states"
                                                                        placeholder={t('placeholder.Select')}
                                                                        options={states}
                                                                        value={this.state.hqState ? states?.filter((c) => { return c.value == this.state.hqState.value }) : null}
                                                                        onChange={(value) => {
                                                                            this.setState({ hqState: value });
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }

                                                        <Row className='mt-3'>
                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.city")}</Label>
                                                            </Col>
                                                            <Col md={4} xl={8}>
                                                                <InputGroup className='input-container'>
                                                                    <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("personalInfo.enterYourCity")}
                                                                        autoComplete="off"
                                                                        value={this.state.hqCity ?? ""}
                                                                        onChange={(value, data, event, formattedValue) => {
                                                                            this.setState({ hqCity: data })
                                                                        }} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <div className='width-percent-100 mt-3'>
                                                        <Col xl={12}>
                                                            <Row>
                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                    <Label className='link-selected font-size-15'>{t('general.Activities')}</Label>
                                                                    <PlusSquare
                                                                        className="icon-dual icon-size-20 ml-3"
                                                                        id="add-activity"
                                                                        onClick={(e) => {
                                                                            e.currentTarget.blur();
                                                                            this.setState({ isAddingActivity: !this.state.isAddingActivity });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip placement="bottom" target="add-activity">
                                                                        {t('network.addActivity')}
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {(this.state.isAddingActivity || this.state.activities.length > 0) &&
                                                            <div className='border-1'>
                                                                <Col xl={12}>
                                                                    {this.state.activities.length > 0 && this.state.activities.map((activity, activityIndex) => {
                                                                        return (
                                                                            <Row>
                                                                                <Col className='align-horizontal'>
                                                                                    <Label className='mt-2'>{activity.activity?.label ?? ''}</Label>
                                                                                    <div className='float-right width-px-40 box-center'>
                                                                                        <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-email' + activityIndex}
                                                                                            onClick={() => {
                                                                                                let activities = this.state.activities;
                                                                                                activities.splice(activityIndex, 1);
                                                                                                this.setState({ activities: activities })
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target={'remove-email' + activityIndex}>
                                                                                            {t("hover.removeMobile")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }, this)}

                                                                    {this.state.isAddingActivity &&
                                                                        <div>
                                                                            {this.state.activities.length > 0 &&
                                                                                <Row className='mt-1'>
                                                                                    <Col>
                                                                                        <div className='footer-box-text-grey'></div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }

                                                                            <Row className='mt-2 mb-1'>
                                                                                <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                    <Label>{t('general.Activity')} :</Label>
                                                                                </Col>
                                                                                <Col md={8} xl={8} className='align-vertical'>
                                                                                    {this.state.industryCategories.map((category, categoryIndex) => {
                                                                                        return (
                                                                                            <Select
                                                                                                className={"react-select" + (categoryIndex > 0 ? ' mt-3' : '')}
                                                                                                classNamePrefix="react-select"
                                                                                                placeholder={t(categoryIndex > 0 ? 'general.optional' : 'placeholder.Select')}
                                                                                                value={category.selectedIndustry ?? null}
                                                                                                onChange={(value) => { this.selectIndustry(categoryIndex, value) }}
                                                                                                options={category.industries}>
                                                                                            </Select>
                                                                                        )
                                                                                    })}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={12} xl={12} className='box-side'>
                                                                                    <div></div>
                                                                                    <div className='align-horizontal'>
                                                                                        <Button color="white mr-2"
                                                                                            onClick={() => {
                                                                                                this.setState({ isAddingActivity: false });
                                                                                                this.defaultIndustryCategories();
                                                                                            }}>
                                                                                            <i className='uil'></i>{t("modal.close")}
                                                                                        </Button>
                                                                                        <Button color="primary"
                                                                                            disabled={this.selectedIndustryGroup(this.state.industryCategories) == null}
                                                                                            onClick={() => { this.addActivity() }}>
                                                                                            <i className='uil'></i>{t("general.Add")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        }
                                                    </div>
                                                </Row>
                                            }

                                            {this.state.addCompanyStep == ADD_COMPANY_SPECIALTY &&
                                                <Row>
                                                    <div className='path-component width-percent-100 mt-3'>
                                                        {selectedActivities.length > 0 &&
                                                            <Col xl={12}>
                                                                {selectedActivities.map((act, selectedActivityIndex) => {
                                                                    let allSpecialties = this.props.companySpecialties?.find((s) => { return s.industryGroupId == act.activity?.id })?.specialties ?? [];
                                                                    allSpecialties = allSpecialties.map((s) => {
                                                                        s.label = getLocalizedString(s.name);
                                                                        return s;
                                                                    });
                                                                    let selectedSpecialties = allSpecialties.filter((specialty) => { return act.specialties.some((f) => { return f.order == specialty.order }) });
                                                                    let remainSpecialties = allSpecialties.filter((specialty) => { return !act.specialties.some((f) => { return f.order == specialty.order }) });

                                                                    return (
                                                                        <div className='align-vertical width-percent-100'>
                                                                            <div>
                                                                                <Label className='link-selected font-size-15 mt-2'>{act?.label ?? ''}</Label>
                                                                            </div>

                                                                            {allSpecialties.length > 0 &&
                                                                                <Row className='border-1 mb-2'>
                                                                                    <Col className='align-vertical'>
                                                                                        <div className='width-percent-100'>
                                                                                            <Col xl={12}>
                                                                                                <Row>
                                                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                        <Label className='link-selected font-size-15'>{t('academy.specialties')}</Label>
                                                                                                        {remainSpecialties.length > 0 &&
                                                                                                            <div>
                                                                                                                <PlusSquare
                                                                                                                    className="icon-dual icon-size-20 ml-3"
                                                                                                                    id="select-school"
                                                                                                                    onClick={(e) => {
                                                                                                                        e.currentTarget.blur();
                                                                                                                        this.setState({ isAddingSpecialty: !this.state.isAddingSpecialty, selectingSpecialtyIndex: selectedActivityIndex });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <UncontrolledTooltip placement="bottom" target="select-school">
                                                                                                                    {t('network.selectSpecialty')}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Col>
                                                                                            {((this.state.isAddingSpecialty && this.state.selectingSpecialtyIndex == selectedActivityIndex) || selectedSpecialties.length > 0) &&
                                                                                                <div className='mb-2'>
                                                                                                    <Col xl={12}>
                                                                                                        {selectedSpecialties.map((specialty, selectedSpecialtyIndex) => {
                                                                                                            return (
                                                                                                                <Row>
                                                                                                                    <Col className='align-horizontal ml-3'>
                                                                                                                        <Label className='mt-2'>{specialty?.label ?? ''}</Label>
                                                                                                                        <div className='float-right width-px-40 box-center'>
                                                                                                                            <Trash className="ml-2 size-px-20 cursor-pointer" id={'remove-selected-faculty' + selectedSpecialtyIndex}
                                                                                                                                onClick={() => {
                                                                                                                                    this.addSpecialty(act, specialty);
                                                                                                                                }} />
                                                                                                                            <UncontrolledTooltip
                                                                                                                                placement="top"
                                                                                                                                id='tooltip-1'
                                                                                                                                target={'remove-selected-faculty' + selectedSpecialtyIndex}>
                                                                                                                                {t("hover.removeFaculty")}
                                                                                                                            </UncontrolledTooltip>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )
                                                                                                        }, this)}

                                                                                                        {this.state.isAddingSpecialty && this.state.selectingSpecialtyIndex == selectedActivityIndex &&
                                                                                                            <div>
                                                                                                                {selectedSpecialties.length > 0 &&
                                                                                                                    <Row className='mt-1'>
                                                                                                                        <Col>
                                                                                                                            <div className='footer-box-text-grey'></div>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                }

                                                                                                                <Row className='mt-2 mb-1'>
                                                                                                                    <Col md={4} xl={4} className='font-size-15 text-left mt-2'>
                                                                                                                        <Label>{t('academy.specialty')} :</Label>
                                                                                                                    </Col>
                                                                                                                    <Col>
                                                                                                                        <Select className="react-select"
                                                                                                                            classNamePrefix="react-select"
                                                                                                                            name="states" id="states"
                                                                                                                            placeholder={t('placeholder.Select')}
                                                                                                                            options={remainSpecialties}
                                                                                                                            value={this.state.selectedSpecialty ? remainSpecialties.find((s) => { return s.order == this.state.selectedSpecialty.order }) : null}
                                                                                                                            onChange={(value) => {
                                                                                                                                this.setState({ selectedSpecialty: value });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <Row className='mt-2 mb-2'>
                                                                                                                    <Col md={12} xl={12} className='box-side'>
                                                                                                                        <div></div>
                                                                                                                        <div className='align-horizontal'>
                                                                                                                            <Button color="white mr-2"
                                                                                                                                onClick={() => { this.setState({ isAddingSpecialty: false, selectedActivity: null, selectingSpecialtyIndex: null }) }}>
                                                                                                                                <i className='uil'></i>{t("modal.close")}
                                                                                                                            </Button>
                                                                                                                            <Button color="primary"
                                                                                                                                disabled={this.state.selectedSpecialty == null}
                                                                                                                                onClick={() => { this.addSpecialty(act, this.state.selectedSpecialty) }}>
                                                                                                                                <i className='uil'></i>{t("general.Add")}
                                                                                                                            </Button>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </Col>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }, this)}
                                                            </Col>
                                                        }
                                                    </div>
                                                </Row>
                                            }

                                            {this.state.addCompanyStep == ADD_COMPANY_EXIST_COMPANY &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3'>
                                                            {this.props.editingCompany ?
                                                                <Col className='font-size-15 text-left mt-2 align-vertical' >
                                                                    <Label className='text-color-alert'>{t('addCompany.companyExistMessage1')}</Label>
                                                                    <Label>{t('addCompany.companyExistMessage5')}</Label>
                                                                </Col>
                                                                :
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label className=''>{t('addCompany.companyExistMessage1')}</Label>
                                                                    <Label className=''>{t(this.props.editingCompany ? 'addCompany.companyExistMessage3' : 'addCompany.companyExistMessage4')}</Label>

                                                                    {this.state.existCompanies.map((company) => {
                                                                        return (
                                                                            <Row className='mb-3'>
                                                                                <Col className='font-size-15 text-left mt-2 align-horizontal'>
                                                                                    <div className='mr-3 align-self-flex-start'>
                                                                                        <SimpleCheckbox checked={this.state.selectedCompany?.id == company.id} didChange={(checked) => { this.setState({ selectedCompany: this.state.selectedCompany == company ? null : company }) }} />
                                                                                    </div>
                                                                                    <Label className='mt-0 mb-0'>{company.name ?? ''}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })}
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addCompanyStep == ADD_COMPANY_NETWORKED &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3 box-side'>
                                                            <Col className='font-size-15 text-left mt-2' >
                                                                <Label className='text-color-alert'>{format(t('network.existObject'), t('subscription.company').toLowerCase())}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }

                                            {this.state.addCompanyStep == ADD_COMPANY_EXIST_DOMAIN &&
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row className='mt-2 mb-3 box-side'>
                                                            <div>
                                                                <Col className='font-size-15 text-left mt-2' >
                                                                    <Label>{format(t('network.domainsExist'), t('subscription.company').toLowerCase())}</Label>
                                                                    {/* <img src={information} id="intro-help" className="icon-size-20 ml-3 mr-2" onClick={() => {
                                                                        this.setState({ showExistDomains: !this.state.showExistDomains });
                                                                    }} />
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        id='tooltip-1'
                                                                        target='intro-help'>
                                                                        {t("hover.existContactDetails")}
                                                                    </UncontrolledTooltip> */}
                                                                </Col>
                                                            </div>
                                                        </Row>

                                                        {this.state.existDomains.length > 0 && this.state.existDomains.map((element, index) => {
                                                            let domain = element.domain ?? '';
                                                            let owner = element.data?.name ?? '';
                                                            let location = locationString(element.data?.countryHQ, element.data?.stateHQ, element.data?.cityHQ, countries);
                                                            if (location?.length > 0) {
                                                                owner += (owner.length > 0 ? ', ' : '') + location;
                                                            }

                                                            if (domain.length > 0) {
                                                                owner += (owner.length > 0 ? ' - ' : '') + domain;
                                                            }

                                                            const checked = this.state.selectedExistCompanyIndex != null ? (this.state.selectedExistCompanyIndex == index) : false;
                                                            return (
                                                                <Row className='mb-3'>
                                                                    <Col className='font-size-15 text-left mt-2 align-horizontal'>
                                                                        <div className='mr-2 align-self-flex-start'>
                                                                            <SimpleCheckbox checked={checked} didChange={(checked) => { this.didSelectExistCompany(index, checked) }} />
                                                                        </div>
                                                                        <Label className='mt-0 mb-0'>{owner}</Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                        }
                                                    </Col>
                                                </Row>
                                            }

                                            <br />

                                            <Row>
                                                <Col md={1} xl={1}></Col>
                                                <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                    <div>
                                                        <Button color="white" onClick={(e) => {
                                                            e.currentTarget.blur();
                                                            this.props.close(null);
                                                        }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                        {this.state.addCompanyStep != ADD_COMPANY_IDENTIFICATION &&
                                                            <Button className='ml-2' color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onBack()
                                                            }}><i className='uil'></i>{t(this.state.addCompanyStep == ADD_COMPANY_SPECIALTY ? 'codeInput.previous' : 'terms.back')}</Button>
                                                        }
                                                        {!nextHidden &&
                                                            <Button className='ml-2' color="primary" disabled={!saveEnable} onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onNext();
                                                            }}><i className='uil'></i>{t('sub.Next')}</Button>
                                                        }
                                                    </div>
                                                    <div>
                                                        {!saveHidden &&
                                                            <Button className='ml-2' disabled={!saveEnable} color="primary" onClick={(e) => {
                                                                e.currentTarget.blur();
                                                                this.onValidate();
                                                            }}><i className='uil'></i>{t(this.state.addCompanyStep == ADD_COMPANY_EXIST_DOMAIN ? 'general.Add' : 'updateCV.validate')}</Button>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );
    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { menuHierarchy } = state.Profile;
    const { allNetworkCompanies, network, existUsers } = state.Network;
    const { companies, companySpecialties } = state.Company;
    return { country, countries, menuHierarchy, allNetworkCompanies, network, existUsers, companies, companySpecialties, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    getAllContentsCompany,
    getCompanySpecialties,
    checkExistCompany,
    checkExistNetworkDomain,
    getAllCompanies2,
    checkExistCompanyDomains,
    createNetworkCompany,
    editNetworkCompany,
    addExistCompanyToNetwork,
    
    getAllNetworkCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddCompanyModal));