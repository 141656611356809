import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { addRealMonth, countryCodeToString, dateStringFromTimeInterval, getCurrentLanguage, getLocalizedString, stateCodeToString } from '../../../../../../helpers/utils';
import { isDarkMode } from '../../../../../../helpers/authUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { DatePickerWrapperStyles, DatePickerWrapperDarkStyles } from '../../../../../../helpers/DatePickerFormat';
import { format } from '../../../../../../helpers/stringFormat';
import { SCREEN_GRADUATE_DEGREE_SAVE_F } from './constants';

class StepAddAcademicEntityFaculty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            className: 'custom-modal-style',
        };
    }

    render() {
        const { t, disabledNext, academicsInCountry, currentCountryToAcademicEntity, facultyDoc,
            currentAcademicFaculty, currentAcademicEntity, currentAcademicCity,
            currentAcademicEntityName, currentAcademicCityName, newIntCode,
            countries, currentStateOfCountryToAcademicEntity, facultyName, currentNewIntCodeToAcademicEntity, isEditing } = this.props;

        var dateStart = (this.props.academicMonthStart != null && !isNaN(this.props.academicMonthStart)) ? moment(this.props.academicMonthStart).toDate() : null;
        var dateEnd = (this.props.academicMonthEnd != null && !isNaN(this.props.academicMonthEnd)) ? moment(this.props.academicMonthEnd).toDate() : null;

        var academicsEntity = academicsInCountry?.map((element, i) => {
            return {
                value: element.id,
                label: element.name,
                cities: element.cities ? element.cities : null,
                faculties: element.faculties ? element.faculties : null,
                entity: element,
                nodeId: element.nodeId ?? null
            };
        }) ?? [];

        academicsEntity.push({
            value: "Others",
            label: t("sub.Others"),
            cities: null,
            faculties: null,
            entity: null
        })

        const selectedAcademicsEntity = academicsEntity?.find(op => { return op.value === currentAcademicEntity })
        const citiesDocument = selectedAcademicsEntity?.cities;
        let facultiesDocument = selectedAcademicsEntity?.faculties;

        const typeOfFaculties = facultyDoc?.map((element, i) => {
            return {
                value: element?.order?.toString(),
                label: getLocalizedString(element?.value),
                specialties: element?.specialties,
                faculty: element ?? null
            };
        });

        let cities = citiesDocument?.map((element, i) => {
            return {
                value: element.city,
                label: element.city,
                cityLang: element.cityLang ? element.cityLang : null,
                id: element.id
            };
        }) ?? [];
        cities.push({
            value: 'Other',
            label: t("sub.Others"),
            cityLang: currentNewIntCodeToAcademicEntity,
            id: 1000
        });

        let facultiesByAcademic = null;
        if (currentAcademicCity != null && facultiesDocument?.length > 0) {
            facultiesByAcademic = facultiesDocument?.filter((faculty) => { return (faculty.cities ?? []).some((id) => { return id == currentAcademicCity }) }) ?? [];
        }

        let faculties = facultiesByAcademic?.map((element, i) => {
            var facultyName = (element.facultyDetail?.order != 1000) ? (element?.facultyDetail?.value ? getLocalizedString(element?.facultyDetail?.value) : '') :
                element?.facultyName ?? '';
            return {
                value: element.facultyDetail?.order?.toString(),
                label: facultyName,
                specialties: element?.facultyDetail?.specialties,
                faculty: element?.facultyDetail ?? null
            };
        }) ?? [];
        faculties.push({ value: 'Other', label: t("sub.Others") });

        let selectedFacultyByAcademic = this.props.selectedFacultyByAcademic;
        const isFacultyOfSelectedAcademic = faculties.some((faculty) => { return faculty.value == selectedFacultyByAcademic });
        if (currentAcademicFaculty?.length > 0 && !isFacultyOfSelectedAcademic) {
            selectedFacultyByAcademic = 'Other';
        }

        const isDark = isDarkMode();

        let enableSave = currentAcademicEntity === 'Others' ? (currentAcademicEntityName?.length > 0) : (currentAcademicEntity != null);
        const editing = isEditing ?? false;
        const readOnly = this.props.readOnly ?? false;

        return (
            <div className='align-vertical mt-4' xl={12}>
                <Label className="font-bold mb-3"> {this.props.title ?? t('academic.titleStep1')} :</Label>
                <Label className="font-bold">{format(t('academic.stepFacultySub'), currentCountryToAcademicEntity ? countryCodeToString(currentCountryToAcademicEntity, countries) : '', currentStateOfCountryToAcademicEntity ? (", " + stateCodeToString(currentCountryToAcademicEntity, currentStateOfCountryToAcademicEntity, countries)) : '')}</Label>

                <br />
                {currentAcademicEntity !== "Others" ?
                    <div>
                        <Row >
                            <Col xl={5} className='align-vertical mb-4'>
                                <Label>{t(editing ? 'Academy' : 'academic.selectAcademicEntity')}</Label>
                                {(editing || readOnly) ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicEntity != null && academicsEntity?.length > 0) ? academicsEntity.find(op => {
                                        return op.value === currentAcademicEntity
                                    })?.label : ''}</Label>
                                    :
                                    <Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder={t('placeholder.Select')}
                                        value={(currentAcademicEntity != null && academicsEntity?.length > 0) ? academicsEntity.find(op => {
                                            return op.value === currentAcademicEntity
                                        }) : null}
                                        onChange={this.props.onChangeAcademicEntity}
                                        options={academicsEntity}></Select>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={5} className='align-vertical mr-5 mb-4'>
                                <Label>{t(readOnly ? 'academic.cityOfAcademicEntity' : 'academic.selectCityOfAcademicEntity')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicCity != null && cities?.length > 0) ? cities.find(op => {
                                        return op.id === currentAcademicCity
                                    })?.label ?? '' : ''}</Label>
                                    :
                                    <Select
                                        isDisabled={this.props.readOnly ?? false}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder={t('placeholder.Select')}
                                        value={(currentAcademicCity != null && cities?.length > 0) ? cities.find(op => {
                                            return op.id === currentAcademicCity
                                        }) : null}
                                        onChange={this.props.onChangeAcademicCity}
                                        options={cities}></Select>
                                }
                            </Col>

                            {currentAcademicCity == 1000 &&
                                <Col xl={5} className='align-vertical input-container mb-4'>
                                    <Label>{format(t('academic.enterNameOfCityOfAcademicEntity'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                                    {readOnly ?
                                        <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{currentAcademicCityName ?? ''}</Label>
                                        :
                                        <Input
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            type="text"
                                            name="academicCityName"
                                            id="academicCityName"
                                            autoComplete="off"
                                            // placeholder={t('placeholder.InputCity')}
                                            value={currentAcademicCityName}
                                            onChange={this.props.onChangeAcademicEntityCityName}></Input>
                                    }
                                </Col>
                            }
                        </Row>
                        {currentAcademicCity != 1000 &&
                            <Row>
                                <Col xl={5} className='align-vertical mr-5 mb-4'>
                                    <Label>{t(readOnly ? 'Faculty' : 'academic.selectFaculty')}</Label>
                                    {readOnly ?
                                        <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(selectedFacultyByAcademic != null && faculties?.length > 0) ? faculties.find(op => {
                                            return op.value === selectedFacultyByAcademic
                                        })?.label ?? '' : ''}</Label>
                                        :
                                        <Select
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            placeholder={t('placeholder.Select')}
                                            value={(selectedFacultyByAcademic != null && faculties?.length > 0) ? faculties.find(op => {
                                                return op.value === selectedFacultyByAcademic
                                            }) : null}
                                            onChange={this.props.onSelectFacultyByAcademic}
                                            options={faculties}></Select>
                                    }
                                </Col>
                            </Row>
                        }
                        {selectedFacultyByAcademic == 'Other' &&
                            <Row >
                                <Col xl={5} className='align-vertical mr-5 mb-4'>
                                    <Label>{t('academic.typeOfFaculty')}</Label>
                                    {readOnly ?
                                        <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicFaculty != null && typeOfFaculties?.length > 0) ? typeOfFaculties.find(op => {
                                            return op.value === currentAcademicFaculty
                                        })?.label ?? '' : ''}</Label>
                                        :
                                        <Select
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            placeholder={t('placeholder.Select')}
                                            value={(currentAcademicFaculty != null && typeOfFaculties?.length > 0) ? typeOfFaculties.find(op => {
                                                return op.value === currentAcademicFaculty
                                            }) : null}
                                            onChange={this.props.onChangeAcademicFaculty}
                                            options={typeOfFaculties}></Select>
                                    }
                                </Col>
                                {currentAcademicFaculty == 1000 &&
                                    <Col xl={5} className='align-vertical input-container mb-4'>
                                        <Label>{format(t('academic.enterFacultyName'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                                        {readOnly ?
                                            <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{facultyName ?? ''}</Label>
                                            :
                                            <Input
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                type="text"
                                                name="facultyName"
                                                id="facultyName"
                                                autoComplete="off"
                                                // placeholder={t('placeholder.InputCity')}
                                                value={facultyName}
                                                onChange={this.props.onChangeAcademicEntityFacultyName}></Input>
                                        }
                                    </Col>
                                }
                            </Row>
                        }

                        <Row>
                            <Col>
                                <Label className="font-bold">{t('academic.timePeriodOfAttendance')}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3} className='align-vertical'>
                                <Label>{t('career.monthStart')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{this.props.academicMonthStart ? dateStringFromTimeInterval(this.props.academicMonthStart, 'MM/YYYY') : ''}</Label>
                                    :
                                    <DatePicker
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        placeholderText="(MM/YYYY)"
                                        selected={dateStart}
                                        className="date-picker p-2"
                                        // minDate={dateNow}
                                        onChange={this.props.handleAcademicStartDateInputChange}
                                        locale={getCurrentLanguage()}
                                    />
                                }
                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                            </Col>

                            <Col xl={3} className='align-vertical'>
                                <Label>{t('career.monthEnd')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{this.props.academicMonthEnd ? dateStringFromTimeInterval(this.props.academicMonthEnd, 'MM/YYYY') : ''}</Label>
                                    :
                                    <DatePicker
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        placeholderText="(MM/YYYY)"
                                        className="date-picker p-2"
                                        selected={dateEnd}
                                        minDate={dateStart}
                                        onChange={this.props.handleAcademicEndDateInputChange}
                                        locale={getCurrentLanguage()}
                                    />
                                }
                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row >
                            <Col xl={5} className='align-vertical mr-5 mb-4'>
                                <Label>{t('academic.selectAcademicEntity')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicEntity != null && academicsEntity?.length > 0) ? academicsEntity.find(op => {
                                        return op.value === currentAcademicEntity
                                    })?.label ?? '' : ''}</Label>
                                    :
                                    <Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder={t('placeholder.Select')}
                                        value={(currentAcademicEntity != null && academicsEntity?.length > 0) ? academicsEntity.find(op => {
                                            return op.value === currentAcademicEntity
                                        }) : null}
                                        onChange={this.props.onChangeAcademicEntity}
                                        options={academicsEntity}></Select>
                                }
                            </Col>
                        </Row>
                        <Row >
                            <Col xl={5} className='align-vertical input-container mr-5 mb-4'>
                                <Label>{format(t('academic.enterNameOfAcademicEntity'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{currentAcademicEntityName ?? ''}</Label>
                                    :
                                    <Input
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        type="text"
                                        name="academicName"
                                        id="academicName"
                                        autoComplete="off"
                                        // placeholder={t('placeholder.InputCity')}
                                        value={currentAcademicEntityName}
                                        onChange={this.props.onChangeAcademicEntityName}></Input>
                                }
                            </Col>
                            <Col xl={5} className='align-vertical mb-4'>
                                <Label>{t('academic.typeOfFaculty')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{(currentAcademicFaculty != null && typeOfFaculties?.length > 0) ? typeOfFaculties.find(op => {
                                        return op.value === currentAcademicFaculty
                                    })?.label ?? '' : ''}</Label>
                                    :
                                    <Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder={t('placeholder.Select')}
                                        value={(currentAcademicFaculty != null && typeOfFaculties?.length > 0) ? typeOfFaculties.find(op => {
                                            return op.value === currentAcademicFaculty
                                        }) : null}
                                        onChange={this.props.onChangeAcademicFaculty}
                                        options={typeOfFaculties}></Select>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={5} className='align-vertical input-container mr-5 mb-4'>
                                <Label>{format(t('academic.enterNameOfCityOfAcademicEntity'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{currentAcademicCityName ?? ''}</Label>
                                    :
                                    <Input
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        type="text"
                                        name="academicCityName"
                                        id="academicCityName"
                                        autoComplete="off"
                                        // placeholder={t('placeholder.InputCity')}
                                        value={currentAcademicCityName}
                                        onChange={this.props.onChangeAcademicEntityCityName}></Input>
                                }
                            </Col>
                            {currentAcademicFaculty == 1000 &&
                                <Col xl={5} className='align-vertical input-container mb-4'>
                                    <Label>{format(t('academic.enterFacultyName'), (newIntCode?.name?.length > 0) ? newIntCode?.name[0] : '')}</Label>
                                    {readOnly ?
                                        <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{facultyName ?? ''}</Label>
                                        :
                                        <Input
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            type="text"
                                            name="facultyName"
                                            id="facultyName"
                                            autoComplete="off"
                                            // placeholder={t('placeholder.InputCity')}
                                            value={facultyName}
                                            onChange={this.props.onChangeAcademicEntityFacultyName}></Input>
                                    }
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col>
                                <Label className="font-bold">{t('academic.timePeriodOfAttendance')}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3} className='align-vertical'>
                                <Label>{t('career.monthStart')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{this.props.academicMonthStart ? dateStringFromTimeInterval(this.props.academicMonthStart, 'MM/YYYY') : ''}</Label>
                                    :
                                    <DatePicker
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        placeholderText="(MM/YYYY)"
                                        selected={dateStart}
                                        className="date-picker p-2"
                                        // minDate={dateNow}
                                        onChange={this.props.handleAcademicStartDateInputChange}
                                        locale={getCurrentLanguage()}
                                    />
                                }
                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                            </Col>

                            <Col xl={3} className='align-vertical'>
                                <Label>{t('career.monthEnd')}</Label>
                                {readOnly ?
                                    <Label className='font-size-15 font-weight-bold mt-2 mb-3'>{this.props.academicMonthEnd ? dateStringFromTimeInterval(this.props.academicMonthEnd, 'MM/YYYY') : ''}</Label>
                                    :
                                    <DatePicker
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        placeholderText="(MM/YYYY)"
                                        className="date-picker p-2"
                                        selected={dateEnd}
                                        minDate={dateStart}
                                        onChange={this.props.handleAcademicEndDateInputChange}
                                        locale={getCurrentLanguage()}
                                    />
                                }
                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                            </Col>
                        </Row>
                    </div>
                }
                <br />
                <div className="buttons-wrapper mt-4">
                    <div className='width-px-300 space-between mt-4 align-horizontal'>
                        <div className="align-horizontal">
                            <Button color="primary" onClick={this.props.cancelStep}><i className='uil'></i>{t((this.props.readOnly ?? false) ? "modal.close" : "codeInput.cancel")}</Button>
                            {!(this.props.readOnly ?? false) &&
                                <div className='ml-4'><Button color="primary" onClick={() => this.props.nextDegree(SCREEN_GRADUATE_DEGREE_SAVE_F)} disabled={!enableSave}><i className='uil'></i>{t("personalInfo.save")}</Button></div>
                            }
                        </div>
                        <div className="ml-5 aligned-right align-horizontal">
                            <Button color="primary" className="mr-4" onClick={this.props.backStep}><i className='uil'></i>{t("terms.back")}</Button>
                            <Button color="primary" className="" onClick={this.props.nextStepAddAcademicEntityFaculty} disabled={disabledNext}><i className='uil'></i>{t("sub.Next")}</Button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

export default withTranslation('translations')(StepAddAcademicEntityFaculty);