// @flow
import {
    CHANGE_ACTIVE_MENU_FROM_LOCATION,
    CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS, INIT_MENU,
    INIT_MENU_SUBCRIPTION, INIT_MENU_SUCCESS, INIT_MENU_WITH_MENU_ITEMS,
    CHANGE_ACTIVE_MENU_WITH_MENU_ID, CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS,
    CHANGE_MENU_TYPE, CHANGE_MENU_TYPE_SUCCESS
} from './constants';

export const initMenu = () => ({
    type: INIT_MENU,
    payload: {},
});

export const initMenuSubscription = (menuItems) => ({
    type: INIT_MENU_SUBCRIPTION,
    payload: menuItems,
});

export const initMenuWithMenuItems = (menuItems) => ({
    type: INIT_MENU_WITH_MENU_ITEMS,
    payload: menuItems,
});

export const initMenuSuccess = (menuItems) => ({
    type: INIT_MENU_SUCCESS,
    payload: menuItems,
});

export const changeActiveMenuFromLocation = (currentMenuItems) => ({
    type: CHANGE_ACTIVE_MENU_FROM_LOCATION,
    payload: currentMenuItems,
});

export const changeActiveMenuFromLocationSuccess = (activatedMenuItemIds) => ({
    type: CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
    payload: { activatedMenuItemIds },
});


export const changeActiveMenuWithMenuId = (menuId) => ({
    type: CHANGE_ACTIVE_MENU_WITH_MENU_ID,
    payload: { menuId },
});

export const changeActiveMenuWithMenuIdSuccess = (activatedMenuItemIds) => ({
    type: CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS,
    payload: { activatedMenuItemIds },
});

export const changeMenuType = (type, callbacks) => ({
    type: CHANGE_MENU_TYPE,
    payload: { type, callbacks },
});

export const changeMenuTypeSuccess = (type) => ({
    type: CHANGE_MENU_TYPE_SUCCESS,
    payload: type,
});