// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup,
    InputGroup, Label, Modal, Row, Input, UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { allLanguages, childrenOfObject, countryCodeToString, dateStringFromTimeInterval, facultyWithId, getCurrentLanguage, getLocalizedString, locationString } from '../../../../helpers/utils';
import { getAPICountryInfo, checkExistEvent, createNetworkEvent, editNetworkEvent, getAllNetworkCompanies, getAllContentsCompany, getCompetencyInfo, getEventGroup } from '../../../../redux/actions';
import { PlusSquare, Trash, User } from 'react-feather';
import { format } from '../../../../helpers/stringFormat';
import information from '../../../../assets/images/information.png';
import SVG from 'react-inlinesvg';
import { TRAINING_TYPE_BUSINESS_DOMAIN, TRAINING_TYPE_DEPARTMENTAL, TRAINING_TYPE_METHODOLOGY, TRAINING_TYPE_TECHNOLOGY } from '../../Settings/Background/Trainings/AddNewTrainingModal';
import { DatePickerWrapperDarkStyles, DatePickerWrapperStyles } from '../../../../helpers/DatePickerFormat';
import DatePicker, { registerLocale } from 'react-datepicker';
import { isDarkMode } from '../../../../helpers/authUtils';
import { NETWORK_COMPANY_TYPE_COUNTRY } from './NetworkCompany';

export const ADD_EVENT_IDENTIFICATION = 0;
export const ADD_EVENT_LOCATION = 1;

export const EVENT_TYPE_ONLINE = 248;
export const EVENT_TYPE_ONSITE = 247;

class AddEventModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            size: 'xx',
            addEventStep: ADD_EVENT_IDENTIFICATION,
            company: null,
            eventType: null,
            eventName: '',
            geoType: null,
            country: null,
            state: null,
            city: '',
            isAddingCity: false,
            locations: [],
            isSelectingLocation: false,

            subjects: [],
            isSelectingSubject: false,
            selectedSubjectType: null,
            subjectCategories: [],
            selectedSubject: null,

            startDate: null,
            endDate: null,
            sponsors: [],
            isSelectingSponsor: false,
            selectedSponsor: null,
            exhibitors: [],
            isSelectingExhibitor: false,
            selectedExhibitor: null,

            editingLocationIndex: null
        };
    }


    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');

        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }

        if (this.props.companies.length == 0) {
            this.props.getAllContentsCompany({});
        }

        if (this.props.competencyInfo == null) {
            this.props.getCompetencyInfo({})
        }

        if (this.props.eventGroup == null) {
            this.props.getEventGroup({})
        }

        // if (this.props.allNetworkCompanies == null) {
        //     this.props.getAllNetworkCompanies(NETWORK_COMPANY_TYPE_COUNTRY, 0, false, {
        //         callbackOnBegin: () => {
        //             this.setState({ loading: true, error: null });
        //         },

        //         callbackOnFailure: (error) => {
        //             this.setState({ loading: false, error: error });
        //         },

        //         callbackOnFinish: () => {
        //             this.setState({ loading: false });
        //         }
        //     });
        // }

        this.prefillData();
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    prefillData = () => {
        let event = this.props.editingEvent;
        if (event != null) {
            const countries = this.props.countries?.map(
                (element, i) => {
                    return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
                }
            );

            let state = {};

            let companies = this.companies();
            state.company = event.companyNodeId ? companies?.find((element) => { return element?.nodeId == event.companyNodeId }) : null;

            state.eventName = event.name ?? '';

            let eventTypes = this.eventTypes();
            state.eventType = event.eventGroupId ? eventTypes?.find((element) => { return element.id == event.eventGroupId }) : null;

            let geoTypes = this.geoTypes();
            state.geoType = event.geoTypeId ? geoTypes?.find((element) => { return element.id == event.geoTypeId }) : null;

            state.locations = event.locations ?? [];
            if (event.geoTypeId == 2 && event.locations?.length > 0) {
                state.country = event.locations[0].country ? countries?.find((element) => { return element.value == event.locations[0].country }) : null;

                let states = state.country?.states?.map((element) => { element.label = getLocalizedString(element.name); return element });
                state.state = event.locations[0].state ? states?.find((element) => { return element.id == event.locations[0].state }) : null;
            }

            let types = this.subjectTypes();
            state.subjects = event.subjects?.map((element) => {
                let subjectType = element.type ? types?.find((e) => { return e.id == element.type }) : null;

                let categories = this.subjectCategories(element.type);
                let subjectCategories = [{ objects: categories, selectedObject: null }];
                let subject = null;
                if (element.id) {
                    let lastCategory = this.lastCategory(element.type, element.id);
                    if (lastCategory) {
                        let tree = [lastCategory.category];
                        if (lastCategory.category?.children?.length > 0) {
                            tree.push({ objects: lastCategory.category.children.map((element) => { element.label = element.name; return element }) });
                        }
                        tree = this.categoriesTree([lastCategory.category], lastCategory.ancestor, lastCategory.category.id);

                        if (tree.length > 0) {
                            tree.forEach(element => {
                                subjectCategories[subjectCategories.length - 1].selectedObject = element;
                                if (element.children?.length > 0) {
                                    subjectCategories.push({ objects: element.children.map((element) => { element.label = element.name; return element }) });
                                }
                            });
                        }
                    }

                    let subjects = this.subjects(element.type, subjectCategories);
                    subject = element.id ? subjects.find((element) => { return element.id == element.id || element.value == element.id }) : null;
                }

                let categoryName = '';
                (subjectCategories ?? []).forEach(element => {
                    if (element?.selectedObject?.name?.length > 0) {
                        categoryName += (categoryName.length > 0 ? ', ' : '') + element?.selectedObject?.name;
                    }
                });
                return { type: element.type, id: element.id, typeName: subjectType?.label ?? '', categoryName: categoryName, subjectName: subject?.label ?? '' }
            }) ?? [];

            this.setState(state);
        }
    }

    companies = () => {
        return this.props.allNetworkCompanies?.map((element) => { element.label = element.name ?? ''; return element });
    }

    lastCategory = (typeId, objectId) => {
        let category = null;
        let ancestor = null;
        let categories = this.subjectCategories(typeId);
        for (var index = 0; index < categories.length; index++) {
            switch (typeId) {
                case TRAINING_TYPE_TECHNOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'technologies', objectId);
                    break;
                case TRAINING_TYPE_METHODOLOGY:
                    category = this.parentCategoryOfObject(categories[index], 'methodologies', objectId);
                    break;

                case TRAINING_TYPE_BUSINESS_DOMAIN:
                    category = this.parentCategoryOfObject(categories[index], 'businessdomain', objectId);
                    break;

                case TRAINING_TYPE_DEPARTMENTAL:
                    category = this.parentCategoryOfObject(categories[index], 'departmental', objectId);
                    break;

                default: break;
            }

            if (category != null) {
                category.selectedObject = (category.children ?? []).find((element) => { return element.id == objectId });
                ancestor = categories[index];
                break;
            }
        }

        return category ? { ancestor: ancestor, category: category } : null;
    }

    parentCategoryOfObject = (parent, type, objectId) => {
        if (parent[type]?.length > 0) {
            let index = parent[type].findIndex((element) => { return element.id == objectId });
            if (index >= 0) {
                return parent;
            }
        }

        if (parent.id.toString() == objectId) {
            return parent;
        }

        if (parent.children?.length > 0) {
            for (var index in parent.children) {
                let position = this.parentCategoryOfObject(parent.children[index], type, objectId);
                if (position != null) {
                    return position;
                }
            }
        }
        return null;
    }

    parentOfSelectedCategory = (ancestor, selectedCategoryId) => {
        let parent = null;
        if (ancestor.children?.length > 0) {
            if (ancestor.children.some((element) => { return element.id == selectedCategoryId })) {
                parent = ancestor;
            }

            if (parent == null) {
                for (var lvl in ancestor.children) {
                    let p = this.parentOfSelectedCategory(ancestor.children[lvl], selectedCategoryId);
                    if (p != null) {
                        parent = p;
                        break;
                    }
                }
            }
        }

        if (parent != null) {
            parent.selectedObject = (parent.children ?? []).find((element) => { return element.id == selectedCategoryId });
        }
        return parent;
    }

    categoriesTree = (tree, ancestor, selectedCategoryId) => {
        let parent = this.parentOfSelectedCategory(ancestor, selectedCategoryId);
        if (parent != null) {
            tree.unshift(parent);
            if (ancestor.id != parent.id) {
                return this.categoriesTree(tree, ancestor, parent?.id);
            }
            return tree;
        }
        return tree;
    }


    onCheckExist = () => {
        if (!this.state.didCheckedEvent) {
            this.checkExistEvent();
        }
        else {
            this.setState({ useSameName: true }, () => {
                this.setState({ addEventStep: ADD_EVENT_LOCATION })
            })
        }
    }

    checkExistEvent = () => {
        let { t } = this.props;

        let needCheck = true;
        if (this.props.editingEvent?.name == this.state.eventName) {
            needCheck = false;
            this.setState({ didCheckedEvent: true, useSameName: true }, () => {
                this.onCheckExist();
            })
        }

        if (needCheck) {
            this.props.checkExistEvent({ 'name': this.state.eventName }, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                },

                callbackOnSuccess: (response) => {
                    let state = {};
                    if (response.existing ?? false) {
                        state.error = { message: format(t('network.existObject'), t('global.event').toLowerCase()) };
                    }
                    else {
                        state.addEventStep = ADD_EVENT_LOCATION;
                    }
                    this.setState(state)
                },
            });
        }
    }

    onEventAction = () => {
        let params = {};
        params.companyNodeId = this.state.company?.nodeId;
        params.name = this.state.eventName;
        params.eventGroupId = this.state.eventType?.id;
        params.geoTypeId = this.state.geoType?.id;
        params.subjects = this.state.subjects.map((element) => { return { type: element.type, id: element.id } });
        if (this.state.locations.length > 0) {
            params.locations = this.state.locations;
        }

        let string = JSON.stringify(params);

        if (this.props.editingEvent) {
            if (this.props.editingEvent?.id) {
                this.props.editNetworkEvent(this.props.editingEvent?.id, params, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                        this.setState({ loading: true, error: null });
                    },
                    callbackOnSuccess: (response) => {
                        this.props.close(response);
                    },

                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },

                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    }
                })
            }
        }
        else {
            this.props.createNetworkEvent(params, {
                callbackOnBegin: () => {
                    // Update loading state?
                    // console.log('callbackOnBegin');
                    this.setState({ loading: true, error: null });
                },
                callbackOnSuccess: (response) => {
                    this.props.close(response);
                },

                callbackOnFailure: (error) => {
                    // show error to your users or stay quiet
                    this.setState({ loading: false, error: error });
                },

                callbackOnFinish: () => {
                    // Update loading state?
                    this.setState({ loading: false });
                }
            })
        }
    }

    selectCompany = (value, countries) => {
        let state = {};
        state.company = value;
        if (value != this.state.company) {
            if (this.state.eventType?.id == EVENT_TYPE_ONLINE) {
                state.locations = [{ country: value.company?.countryHQ, state: value.company?.stateHQ, city: value.company?.cityHQ }];
                state.country = null;
                state.state = null;
                state.city = '';
            }
            else if (this.state.geoType?.id == 2) {
                let country = value.company?.countryHQ ? countries?.find((element) => { return element.value == value.company?.countryHQ }) : null;
                if (country != this.state.country) {
                    state.country = country;
                    state.state = null;
                    state.city = '';
                    state.locations = [];
                }
            }
            else {
                state.country = null;
                state.state = null;
                state.city = '';
                state.locations = [];
            }
        }
        this.setState(state);
    }

    eventTypes = () => {
        return this.props.eventGroup?.children?.map((element) => { element.label = element.name; return element });
    }

    selectEventType = (value) => {
        let state = {};
        state.eventType = value;
        if (value != this.state.eventType) {
            if (value.id == EVENT_TYPE_ONLINE) {
                state.locations = this.state.company ? [{ country: this.state.company.countryHQ, state: this.state.company.stateHQ, city: this.state.company.cityHQ }] : [];
            }
            else {
                state.locations = [];
            }
        }
        this.setState(state);
    }

    geoTypes = () => {
        return this.props.companies?.companyGeoType?.listDocDetails?.companyGeoType?.map(
            (element, i) => {
                element.label = element.value ? getLocalizedString(element.value) : '';
                return element;
            }
        );
    }

    selectGeoType = (value, countries) => {
        let state = {};
        state.geoType = value;
        if (value != this.state.geoType) {
            state.locations = [];
            state.country = this.state.geoType == 1 ? null : (this.state.company?.countryHQ ? countries?.find((element) => { return element.value == this.state.company?.countryHQ }) : null);
            state.state = null;
            state.city = '';
        }
        this.setState(state);
    }

    cancelAddingCity = () => {
        this.setState({ isAddingCity: false, city: '' });
    }

    finishAddingCity = () => {
        let locations = this.state.locations;
        locations.push({ country: this.state.country?.value, state: this.state.state?.id, city: this.state.city });
        this.setState({ isAddingCity: false, locations: locations, state: null, city: '' });
    }

    cancelAddingLocation = () => {
        this.setState({ isSelectingLocation: false, country: null, state: null, city: '' });
    }

    finishAddingLocation = () => {
        let locations = this.state.locations;
        locations.push({ country: this.state.country?.value, state: this.state.state?.id, city: this.state.city });
        this.setState({ isSelectingLocation: false, locations: locations, country: null, state: null, city: '' });
    }

    subjectTypes = () => {
        return (this.props.competencyInfo?.type?.competencyType ?? []).map((type) => { type.label = getLocalizedString(type.value); return type });
    }

    subjectCategories = (typeId) => {
        let categories = [];
        if (typeId == TRAINING_TYPE_TECHNOLOGY) {
            // Technology
            let techGroup = this.props.menuHierarchy?.techGroup;
            if (techGroup) {
                techGroup.label = techGroup.name;
                categories = [techGroup];
            }
            // if (this.props.menuHierarchy?.techGroup?.children?.length > 0) {
            //     categories = this.props.menuHierarchy?.techGroup?.children?.map((element) => {
            //         element.label = element.name;
            //         return element
            //     });

            //     categories = (categories ?? []).filter((element) => { return element.technology ?? true });
            // }
        }
        else if (typeId == TRAINING_TYPE_BUSINESS_DOMAIN) {
            // Business Group / Industry
            if (this.props.menuHierarchy?.industryGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.industryGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.businessDomain ?? true });
            }
        }
        else if (typeId == TRAINING_TYPE_DEPARTMENTAL) {
            let departmentGroup = this.props.menuHierarchy?.departmentGroup;
            if (departmentGroup) {
                if (departmentGroup.select ?? false) {
                    departmentGroup.label = departmentGroup.name;
                    categories = [departmentGroup];
                }
                else if (departmentGroup.children?.length > 0) {
                    categories = departmentGroup.children?.map((element) => {
                        element.label = element.name;
                        return element
                    }) ?? [];
                    categories = categories.filter((element) => { return element.departmental ?? true });
                }
            }
        }
        else if (typeId == TRAINING_TYPE_METHODOLOGY) {
            if (this.props.menuHierarchy?.methodologyGroup?.children?.length > 0) {
                categories = this.props.menuHierarchy?.methodologyGroup?.children?.map((element) => {
                    element.label = element.name;
                    return element
                });

                categories = (categories ?? []).filter((element) => { return element.methodology ?? true });
            }
        }

        return (categories ?? []).filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
    }

    subjects = (typeId, subjectCategories) => {
        let selectedCategory = null;
        if (subjectCategories?.length > 0) {
            selectedCategory = subjectCategories[subjectCategories.length - 1].selectedObject;
        }
        if (selectedCategory == null && subjectCategories?.length > 1) {
            selectedCategory = subjectCategories[subjectCategories.length - 2].selectedObject;
        }

        let subjects = [];
        let children = [];
        if (selectedCategory != null) {
            if (typeId == 2) {
                // Technology
                children = selectedCategory?.technologies ?? [];
            }
            else if (typeId == 4) {
                children = selectedCategory?.methodologies ?? [];

            }
        }

        if (children.length > 0) {
            subjects = children.map((element) => {
                element.label = element.name;
                return element;
            });
        }

        return subjects ?? [];
    }

    selectSubjectType = (value) => {
        let params = {};
        params.selectedSubjectType = value;
        if (value.id != this.state.selectedSubjectType?.id) {
            let categories = this.subjectCategories(value?.id);
            params.subjectCategories = [{ objects: categories }];
            params.selectedSubject = null;
        }

        this.setState(params);
    }

    selectSubjectCategory = (categoryIndex, selectedValue) => {
        let subjectCategories = this.state.subjectCategories;
        subjectCategories[categoryIndex].selectedObject = selectedValue;
        subjectCategories.length = categoryIndex + 1;

        if (selectedValue.children?.length > 0) {
            let objects = selectedValue.children.map((element) => { element.label = element.name; return element });
            if (objects?.length > 0) {
                objects = objects.filter((element) => { return (element.select ?? true) ? true : element.children?.length > 0 });
            }
            subjectCategories.push({ objects: objects });
        }
        this.setState({ subjectCategories: subjectCategories });
    }

    selectedSubject = (subjectCategories) => {
        let selectedSubject = null;
        if (subjectCategories?.length > 0) {
            selectedSubject = subjectCategories[subjectCategories.length - 1].selectedObject;
            if (selectedSubject == null && subjectCategories.length > 1) {
                selectedSubject = subjectCategories[subjectCategories.length - 2].selectedObject;
            }
        }
        return selectedSubject;
    }

    cancelAddingSubject = () => {
        this.setState({ isSelectingSubject: false, selectedSubjectType: null, subjectCategories: [], selectedSubject: null });
    }

    finishAddingSubject = () => {
        let subjects = this.state.subjects;
        let selectedSubject = this.selectedSubject(this.state.subjectCategories);
        let typeName = this.state.selectedSubjectType?.label ?? '';
        let categoryName = '';
        this.state.subjectCategories.forEach(element => {
            if (element.selectedObject?.label?.length > 0) {
                categoryName += (categoryName.length > 0 ? ', ' : '') + element.selectedObject?.label;
            }
        });
        let subjectName = this.state.selectedSubject?.label ?? '';
        subjects.push({ type: this.state.selectedSubjectType?.id, id: this.state.selectedSubject?.id ?? selectedSubject?.id, typeName: typeName, categoryName: categoryName, subjectName: subjectName });
        this.setState({ isSelectingSubject: false, subjects: subjects, selectedSubjectType: null, subjectCategories: [], selectedSubject: null });
    }

    cancelAddingSponsor = () => {
        this.setState({ isSelectingSponsor: false, selectedSponsor: null });
    }

    finishAddingSponsor = () => {
        let sponsors = this.state.sponsors;
        if (this.state.selectedSponsor?.company?.nodeId && !sponsors.some((element) => { return element == this.state.selectedSponsor?.company?.nodeId })) {
            sponsors.push(this.state.selectedSponsor.company?.nodeId);
        }
        this.setState({ isSelectingSponsor: false, sponsors: sponsors, selectedSponsor: null });
    }

    cancelAddingExhibitor = () => {
        this.setState({ isSelectingExhibitor: false, selectedExhibitor: null });
    }

    finishAddingExhibitor = () => {
        let exhibitors = this.state.exhibitors;
        if (this.state.selectedExhibitor?.company?.nodeId && !exhibitors.some((element) => { return element == this.state.selectedExhibitor?.company?.nodeId })) {
            exhibitors.push(this.state.selectedExhibitor.company?.nodeId);
        }
        this.setState({ isSelectingExhibitor: false, exhibitors: exhibitors, selectedExhibitor: null });
    }

    beginAddingPeriod = (index) => {
        this.setState({ editingLocationIndex: index, startDate: null, endDate: null, isSelectingSponsor: false, selectedSponsor: null, selectedExhibitor: null, sponsors: [], exhibitors: [] });
    }

    cancelAddingPeriod = () => {
        this.setState({ editingLocationIndex: null, startDate: null, endDate: null, isSelectingSponsor: false, selectedSponsor: null, selectedExhibitor: null, sponsors: [], exhibitors: [] });
    }

    finishAddingPeriod = () => {
        let locations = this.state.locations;
        let periods = locations[this.state.editingLocationIndex].periods ?? [];
        periods.push({ startDate: this.state.startDate?.valueOf(), endDate: this.state.endDate?.valueOf(), sponsors: this.state.sponsors, exhibitors: this.state.exhibitors });
        locations[this.state.editingLocationIndex].periods = periods;
        this.setState({ locations: locations, editingLocationIndex: null, startDate: null, endDate: null, isSelectingSponsor: false, selectedSponsor: null, selectedExhibitor: null, sponsors: [], exhibitors: [] });
    }

    deletePeriod = (locationIndex, periodIndex) => {
        let locations = this.state.locations;
        locations[locationIndex].periods.splice(periodIndex, 1);
        this.setState({ locations: locations });
    }

    checkNextable = () => {
        let nextable = this.state.company != null &&
            this.state.eventName?.length > 0 &&
            this.state.eventType != null &&
            this.state.subjects.length > 0;

        if (nextable && this.state.eventType?.id == EVENT_TYPE_ONSITE) {
            nextable = this.state.geoType != null;
            if (nextable) {
                nextable = this.state.locations.length > 0;
            }
        }

        return nextable;
    }

    checkSaveable = () => {
        if (this.state.addEventStep == ADD_EVENT_IDENTIFICATION) {
            let nextable = this.checkNextable();
            return nextable;
        }
        else {
            return !this.state.locations.some((element) => { return element.periods == null || element.periods.length == 0 });
        }
    }

    onNext = () => {
        if (this.state.didCheckedEvent) {
            this.setState({ addEventStep: ADD_EVENT_LOCATION });
        }
        else {
            this.checkExistEvent();
        }
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <Alert color="danger" key="alert">
                {message != null ? message : t("Cannot connect to server.")}
            </Alert>
        }
        return ""
    }

    render() {
        const { t } = this.props;

        const isDark = isDarkMode();

        const countries = this.props.countries?.map(
            (element, i) => {
                return { value: element.locCode, label: getLocalizedString(element), states: element.states ? element.states : null };
            }
        );

        let states = this.state.country ? countries?.find((element) => { return element.value == this.state.country.value })?.states : null;
        states = states?.map((element) => { element.label = getLocalizedString(element.name); return element });

        let locationAddable = this.state.country != null && this.state.city.length > 0;
        if (locationAddable && states?.length > 0) {
            locationAddable = this.state.state != null;
        }

        let cities = this.state.locations.map((element) => {
            let states = element.country ? countries?.find((e) => { return e.value == element.country })?.states : null;
            states = states?.map((element) => { element.label = getLocalizedString(element.name); return element });

            let stateName = element.state ? states?.find((e) => { return e.id == element.state })?.label : null;
            let location = stateName ?? '';
            if (element.city?.length > 0) {
                location += (location.length > 0 ? ', ' : '') + element.city;
            }
            return location
        });

        let subject = this.selectedSubject(this.state.subjectCategories);
        let subjectAddable = subject != null;
        // if (subjectAddable && (this.state.selectedSubjectType?.id == TRAINING_TYPE_TECHNOLOGY || this.state.selectedSubjectType?.id == TRAINING_TYPE_METHODOLOGY)) {
        //     subjectAddable = this.state.selectedSubject != null;
        // }

        let companies = this.companies();
        let remainingSponsors = companies?.filter((element) => { return !this.state.sponsors.some((e) => { return e == element.company?.nodeId }) });
        let remainingExhibitors = companies?.filter((element) => { return !this.state.exhibitors.some((e) => { return e == element.company?.nodeId }) });

        let eventTypes = this.eventTypes();
        let geoTypes = this.geoTypes();

        let types = this.subjectTypes();
        let subjects = this.subjects(this.state.selectedSubjectType?.id, this.state.subjectCategories);


        const nextable = this.checkNextable();
        const saveEnable = this.checkSaveable();

        let headerTitle = format(t(this.props.editingGoal ? t('general.modify0') : t('general.add0')), t('global.event'));
        headerTitle += ' - ';
        headerTitle += this.state.addEventStep == ADD_EVENT_IDENTIFICATION ? t('network.identification') : this.state.eventName;

        let errorMessage = this.state.error?.response?.data?.message ? this.state.error?.response?.data?.message : this.state.error?.message;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        this.props.close(null);
                    }}
                    className={this.state.className}
                    size={this.state.size}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {this.state.error && <this.Alert error={this.state.error} />}
                                <Card className='shadow-none'>
                                    <CardHeader>
                                        <Label className={'h4'}>{headerTitle}</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="authentication-form">
                                            {this.state.loading && <Loader />}

                                            {errorMessage ?
                                                <Row>
                                                    <Col xl={12}>
                                                        <Row>
                                                            <Col className='path-component font-size-15 text-left'>
                                                                <Label className='font-size-15 text-color-alert'>{errorMessage}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={12} xl={12} className='font-size-15 text-left align-horizontal'>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.close(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}
                                                                </Button>
                                                                <Button color="primary ml-3" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.setState({ error: null });
                                                                }}><i className='uil'></i>{t('terms.back')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                :
                                                <div>
                                                    {this.state.addEventStep == ADD_EVENT_IDENTIFICATION &&
                                                        <Row>
                                                            <Col xl={12}>
                                                                <Row className='mt-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('subscription.company')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            placeholder={t('placeholder.Select')}
                                                                            options={companies}
                                                                            value={this.state.company ?? null}
                                                                            onChange={(value) => { this.selectCompany(value, countries) }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mt-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                        <Label className='link-selected font-size-15' for="firstName">{t("global.event")}</Label>
                                                                    </Col>
                                                                    <Col md={4} xl={8}>
                                                                        <InputGroup className='input-container'>
                                                                            <AvInput className='react-select' type="text" name="firstName" id="firstName" placeholder={t("global.event")}
                                                                                autoComplete="off"
                                                                                value={this.state.eventName ?? ""}
                                                                                onChange={(e) => { this.setState({ eventName: e.target.value, didCheckedEvent: false, useSameName: false }) }} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mt-3'>
                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('event.type')}</Label>
                                                                    </Col>
                                                                    <Col md={8} xl={8}>
                                                                        <Select className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            placeholder={t('placeholder.Select')}
                                                                            options={eventTypes}
                                                                            value={this.state.eventType ?? null}
                                                                            onChange={(value) => { this.selectEventType(value) }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                {this.state.eventType && this.state.eventType.id == EVENT_TYPE_ONSITE &&
                                                                    <div>
                                                                        <Row className='mt-3'>
                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                <Label className='link-selected font-size-15'>{t('event.geoType')}</Label>
                                                                            </Col>
                                                                            <Col md={8} xl={8}>
                                                                                <Select className="react-select"
                                                                                    classNamePrefix="react-select"
                                                                                    placeholder={t('placeholder.Select')}
                                                                                    options={geoTypes}
                                                                                    value={this.state.geoType ?? null}
                                                                                    onChange={(value) => { this.selectGeoType(value, countries) }}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        {this.state.geoType != null &&
                                                                            <div>
                                                                                {this.state.geoType?.id == 2 ?
                                                                                    <div className='widh-percent-100'>
                                                                                        <Row className='mt-3'>
                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                <Label className='link-selected font-size-15'>{t('personalInfo.country')}</Label>
                                                                                            </Col>
                                                                                            <Col md={8} xl={8}>
                                                                                                <Select className="react-select"
                                                                                                    classNamePrefix="react-select"
                                                                                                    placeholder={t('placeholder.Select')}
                                                                                                    options={countries}
                                                                                                    value={this.state.country}
                                                                                                    onChange={(value) => {
                                                                                                        let state = {};
                                                                                                        state.country = value;
                                                                                                        if (value != this.state.country) {
                                                                                                            state.state = null;
                                                                                                            state.city = '';
                                                                                                            state.locations = [];
                                                                                                            state.isAddingCity = false;
                                                                                                        }
                                                                                                        this.setState(state);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Row className='mt-2 mb-0'>
                                                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                <Label className='link-selected font-size-15'>{t('general.Cities')}</Label>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Row className='mb-4 border-1 rounded-3'>
                                                                                            <div className='align-vertical width-percent-100'>
                                                                                                {cities?.length > 0 && cities.map((city, cityIndex) => {
                                                                                                    return <div key={cityIndex}>
                                                                                                        <div className='align-vertical'>
                                                                                                            <div className='align-horizontal ml-3'>
                                                                                                                <Label>{city}</Label>
                                                                                                                <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                                                    var locations = this.state.locations;
                                                                                                                    locations.splice(cityIndex, 1);
                                                                                                                    this.setState({ locations: locations });
                                                                                                                }}>
                                                                                                                    <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-city" />
                                                                                                                    <UncontrolledTooltip
                                                                                                                        placement="top"
                                                                                                                        id='tooltip-1'
                                                                                                                        target='remove-city'>
                                                                                                                        {t("experience.hover.removeCity")}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {(cityIndex == this.state.locations.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                                    </div>
                                                                                                })}

                                                                                                <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                                    {!this.state.isAddingCity &&
                                                                                                        <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                                            <div className='align-horizontal'>
                                                                                                                <PlusSquare className="icon-dual icon-size-20" id="icon-add-city"
                                                                                                                    onClick={() => {
                                                                                                                        if (this.state.isAddingCity) {
                                                                                                                            this.cancelAddingCity()
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            this.setState({ isAddingCity: true })
                                                                                                                        }
                                                                                                                    }} />
                                                                                                                <UncontrolledTooltip
                                                                                                                    placement="top"
                                                                                                                    id='tooltip-1'
                                                                                                                    target='icon-add-city'>
                                                                                                                    {t("experience.hover.addCity")}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </div>

                                                                                                            <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                                                onClick={() => {
                                                                                                                    if (this.state.isAddingCity) {
                                                                                                                        this.cancelAddingCity()
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        this.setState({ isAddingCity: true })
                                                                                                                    }
                                                                                                                }}
                                                                                                            >{t('personalInfo.city')}</Label>
                                                                                                        </div>
                                                                                                    }

                                                                                                    {this.state.isAddingCity &&
                                                                                                        <div className='width-percent-100'>
                                                                                                            {this.state.isAddingCity &&
                                                                                                                <div className='input-container'>
                                                                                                                    <div className='widh-percent-100'>
                                                                                                                        {states?.length > 0 &&
                                                                                                                            <Row className='mt-3'>
                                                                                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                                                    <Label className='link-selected font-size-15'>{t('personalInfo.state')}</Label>
                                                                                                                                </Col>
                                                                                                                                <Col md={8} xl={8}>
                                                                                                                                    <Select className="react-select"
                                                                                                                                        classNamePrefix="react-select"
                                                                                                                                        placeholder={t('placeholder.Select')}
                                                                                                                                        options={states}
                                                                                                                                        value={this.state.state}
                                                                                                                                        onChange={(value) => {
                                                                                                                                            let state = {};
                                                                                                                                            state.state = value;
                                                                                                                                            if (value != this.state.state) {
                                                                                                                                                state.city = '';
                                                                                                                                            }
                                                                                                                                            this.setState(state);
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        }

                                                                                                                        <Row className='mt-3'>
                                                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                                                                                <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.city")}</Label>
                                                                                                                            </Col>
                                                                                                                            <Col md={4} xl={8} className='input-container'>
                                                                                                                                <Input type="text" name="firstName" id="firstName" placeholder={t("personalInfo.city")}
                                                                                                                                    autoComplete="off"
                                                                                                                                    value={this.state.city ?? ""}
                                                                                                                                    onChange={(e) => { this.setState({ city: e.target.value }) }}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row >
                                                                                                                            <Col md={12} xl={12} className='box-side'>
                                                                                                                                <div></div>
                                                                                                                                <ul className="list-inline wizard mt-3">
                                                                                                                                    <li className="previous list-inline-item">
                                                                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingCity() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                                        <Button onClick={() => { this.finishAddingCity() }} color="primary" disabled={this.state.city == null || this.state.city?.length == 0}>{t('general.Add')}</Button>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </Col>
                                                                                            </div>
                                                                                        </Row>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <Row className='mt-2 mb-0'>
                                                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                <Label className='link-selected font-size-15'>{t('company.locations')}</Label>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Row className='mb-4 border-1 rounded-3'>
                                                                                            <div className='align-vertical width-percent-100'>
                                                                                                {this.state.locations?.length > 0 && this.state.locations.map((element, locationIndex) => {
                                                                                                    let location = locationString(element.country, element.state, element.city, countries);
                                                                                                    return <div key={locationIndex}>
                                                                                                        <div className='align-vertical'>
                                                                                                            <div className='align-horizontal ml-3'>
                                                                                                                <Label>{location}</Label>
                                                                                                                <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                                                    var locations = this.state.locations;
                                                                                                                    locations.splice(locationIndex, 1);
                                                                                                                    this.setState({ locations: locations });
                                                                                                                }}>
                                                                                                                    <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-activity" />
                                                                                                                    <UncontrolledTooltip
                                                                                                                        placement="top"
                                                                                                                        id='tooltip-1'
                                                                                                                        target='remove-activity'>
                                                                                                                        {t("experience.hover.removeActivity")}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {(locationIndex == this.state.locations.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                                    </div>
                                                                                                })}

                                                                                                <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                                    {!this.state.isSelectingLocation &&
                                                                                                        <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                                            <div className='align-horizontal'>
                                                                                                                <PlusSquare className="icon-dual icon-size-20" id="icon-add-activity"
                                                                                                                    onClick={() => {
                                                                                                                        if (this.state.isSelectingLocation) {
                                                                                                                            this.cancelAddingLocation()
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            this.setState({ isSelectingLocation: true })
                                                                                                                        }
                                                                                                                    }} />
                                                                                                                <UncontrolledTooltip
                                                                                                                    placement="top"
                                                                                                                    id='tooltip-1'
                                                                                                                    target='icon-add-activity'>
                                                                                                                    {t("experience.hover.addActivity")}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </div>

                                                                                                            <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                                                onClick={() => {
                                                                                                                    if (this.state.isSelectingLocation) {
                                                                                                                        this.cancelAddingLocation()
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        this.setState({ isSelectingLocation: true })
                                                                                                                    }
                                                                                                                }}
                                                                                                            >{t('general.location')}</Label>
                                                                                                        </div>
                                                                                                    }

                                                                                                    {this.state.isSelectingLocation &&
                                                                                                        <div className='width-percent-100'>
                                                                                                            {this.state.isSelectingLocation &&
                                                                                                                <div className='input-container'>
                                                                                                                    <div className='widh-percent-100'>
                                                                                                                        <Row className='mt-3'>
                                                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                                                <Label className='link-selected font-size-15'>{t('personalInfo.country')}</Label>
                                                                                                                            </Col>
                                                                                                                            <Col md={8} xl={8}>
                                                                                                                                <Select className="react-select"
                                                                                                                                    classNamePrefix="react-select"
                                                                                                                                    placeholder={t('placeholder.Select')}
                                                                                                                                    options={countries}
                                                                                                                                    value={this.state.country}
                                                                                                                                    onChange={(value) => {
                                                                                                                                        let state = {};
                                                                                                                                        state.country = value;
                                                                                                                                        if (value != this.state.country) {
                                                                                                                                            state.state = null;
                                                                                                                                            state.city = '';
                                                                                                                                        }
                                                                                                                                        this.setState(state);
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        {states?.length > 0 &&
                                                                                                                            <Row className='mt-3'>
                                                                                                                                <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                                                    <Label className='link-selected font-size-15'>{t('personalInfo.state')}</Label>
                                                                                                                                </Col>
                                                                                                                                <Col md={8} xl={8}>
                                                                                                                                    <Select className="react-select"
                                                                                                                                        classNamePrefix="react-select"
                                                                                                                                        placeholder={t('placeholder.Select')}
                                                                                                                                        options={states}
                                                                                                                                        value={this.state.state}
                                                                                                                                        onChange={(value) => {
                                                                                                                                            let state = {};
                                                                                                                                            state.state = value;
                                                                                                                                            if (value != this.state.state) {
                                                                                                                                                state.city = '';
                                                                                                                                            }
                                                                                                                                            this.setState(state);
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        }

                                                                                                                        <Row className='mt-3'>
                                                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2' >
                                                                                                                                <Label className='link-selected font-size-15' for="firstName">{t("personalInfo.city")}</Label>
                                                                                                                            </Col>
                                                                                                                            <Col md={4} xl={8} className='input-container'>
                                                                                                                                <Input type="text" name="firstName" id="firstName" placeholder={t("personalInfo.city")}
                                                                                                                                    autoComplete="off"
                                                                                                                                    value={this.state.city ?? ""}
                                                                                                                                    onChange={(e) => { this.setState({ city: e.target.value }) }}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>

                                                                                                                        <Row >
                                                                                                                            <Col md={12} xl={12} className='box-side'>
                                                                                                                                <div></div>
                                                                                                                                <ul className="list-inline wizard mt-3">
                                                                                                                                    <li className="previous list-inline-item">
                                                                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingLocation() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                                        <Button onClick={() => { this.finishAddingLocation() }} color="primary" disabled={!locationAddable}>{t('general.Add')}</Button>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </Col>
                                                                                            </div>
                                                                                        </Row>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                <Row className='mt-2 mb-0'>
                                                                    <Col className='path-component font-size-15 text-left mt-2'>
                                                                        <Label className='link-selected font-size-15'>{t('general.subjects')}</Label>
                                                                    </Col>
                                                                </Row>

                                                                <Row className='mb-4 border-1 rounded-3'>
                                                                    <div className='align-vertical width-percent-100'>
                                                                        {this.state.subjects?.length > 0 && this.state.subjects.map((element, subjectIndex) => {
                                                                            let summary = element.typeName ?? '';
                                                                            if (element.categoryName?.length > 0) {
                                                                                summary += (summary.length > 0 ? ', ' : '') + element.categoryName;
                                                                            }
                                                                            if (element.subjectName?.length > 0) {
                                                                                summary += (summary.length > 0 ? ', ' : '') + element.subjectName;
                                                                            }
                                                                            return <div key={subjectIndex}>
                                                                                <div className='align-vertical'>
                                                                                    <div className='align-horizontal ml-3'>
                                                                                        <Label>{summary}</Label>
                                                                                        <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                            var subjects = this.state.subjects;
                                                                                            subjects.splice(subjectIndex, 1);
                                                                                            this.setState({ subjects: subjects });
                                                                                        }}>
                                                                                            <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-activity" />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                id='tooltip-1'
                                                                                                target='remove-activity'>
                                                                                                {t("experience.hover.removeActivity")}
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {(subjectIndex == this.state.subjects.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                            </div>
                                                                        })}

                                                                        <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                            {!this.state.isSelectingSubject &&
                                                                                <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                    <div className='align-horizontal'>
                                                                                        <PlusSquare className="icon-dual icon-size-20" id="icon-add-activity"
                                                                                            onClick={() => {
                                                                                                if (this.state.isSelectingSubject) {
                                                                                                    this.cancelAddingSubject()
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({ isSelectingSubject: true })
                                                                                                }
                                                                                            }} />
                                                                                        <UncontrolledTooltip
                                                                                            placement="top"
                                                                                            id='tooltip-1'
                                                                                            target='icon-add-activity'>
                                                                                            {t("experience.hover.addActivity")}
                                                                                        </UncontrolledTooltip>
                                                                                    </div>

                                                                                    <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            if (this.state.isSelectingSubject) {
                                                                                                this.cancelAddingSubject()
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ isSelectingSubject: true })
                                                                                            }
                                                                                        }}
                                                                                    >{t('general.subject')}</Label>
                                                                                </div>
                                                                            }

                                                                            {this.state.isSelectingSubject &&
                                                                                <div className='width-percent-100'>
                                                                                    {this.state.isSelectingSubject &&
                                                                                        <div className='input-container'>
                                                                                            <div className='widh-percent-100'>
                                                                                                <Row className='mb-3'>
                                                                                                    <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                        <Label className='link-selected font-size-15' for='country'>{t('techGroup.category')}</Label>
                                                                                                    </Col>
                                                                                                    <Col md={8} xl={8}>
                                                                                                        <Select
                                                                                                            className="react-select"
                                                                                                            classNamePrefix="react-select"
                                                                                                            placeholder={t('placeholder.Select')}
                                                                                                            value={this.state.selectedType}
                                                                                                            onChange={
                                                                                                                (value) => { this.selectSubjectType(value) }
                                                                                                            }
                                                                                                            options={types}></Select>
                                                                                                    </Col>
                                                                                                </Row>

                                                                                                {this.state.subjectCategories.length > 0 &&
                                                                                                    <Row className='mb-3'>
                                                                                                        <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                            <Label className='link-selected font-size-15' for='country'>{t('general.subject')}</Label>
                                                                                                        </Col>
                                                                                                        <Col md={8} xl={8} className='align-vertical'>
                                                                                                            {this.state.subjectCategories.map((category, categoryIndex) => {
                                                                                                                return (
                                                                                                                    <Select
                                                                                                                        className={"react-select" + (categoryIndex > 0 ? ' mt-3' : '')}
                                                                                                                        classNamePrefix="react-select"
                                                                                                                        placeholder={t(categoryIndex > 0 ? 'general.optional' : 'placeholder.Select')}
                                                                                                                        value={category.selectedObject ?? null}
                                                                                                                        onChange={(value) => { this.selectSubjectCategory(categoryIndex, value) }}
                                                                                                                        options={category.objects}></Select>
                                                                                                                )
                                                                                                            }, this)}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }

                                                                                                {subjects?.length > 0 &&
                                                                                                    <Row className='mb-3'>
                                                                                                        <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                            <Label className='link-selected font-size-15' for='country'>{t(this.state.selectedSubjectType.id == TRAINING_TYPE_TECHNOLOGY ? 'experience.Technology' : 'competency.methodology')}</Label>
                                                                                                        </Col>
                                                                                                        <Col md={8} xl={8}>
                                                                                                            <Select
                                                                                                                className="react-select"
                                                                                                                classNamePrefix="react-select"
                                                                                                                placeholder={t('general.optional')}
                                                                                                                value={this.state.selectedSubject}
                                                                                                                onChange={(value) => {
                                                                                                                    this.setState({ selectedSubject: value })
                                                                                                                }}
                                                                                                                options={subjects}></Select>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }

                                                                                                <Row >
                                                                                                    <Col md={12} xl={12} className='box-side'>
                                                                                                        <div></div>
                                                                                                        <ul className="list-inline wizard">
                                                                                                            <li className="previous list-inline-item">
                                                                                                                <Button className='mr-2' onClick={() => { this.cancelAddingSubject() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                <Button onClick={() => { this.finishAddingSubject() }} color="primary" disabled={!subjectAddable}>{t('general.Add')}</Button>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </div>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                    }

                                                    {this.state.addEventStep == ADD_EVENT_LOCATION &&
                                                        <Row>
                                                            <Col xl={12}>
                                                                {this.state.locations?.length > 0 && this.state.locations.map((element, locationIndex) => {
                                                                    let location = this.state.eventType?.id == EVENT_TYPE_ONLINE ? this.state.eventType?.name : this.state.geoType?.id == 1 ? locationString(element.country, element.state, element.city, countries) : element.city;
                                                                    let periods = element.periods;
                                                                    return <div key={locationIndex}>
                                                                        <div className='align-vertical'>
                                                                            <Row className='mt-3 mb-0'>
                                                                                <Col className='path-component font-size-15 text-left mt-2'>
                                                                                    <Label className='link-selected font-size-15'>{location}</Label>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className='mb-4 border-1 rounded-3'>
                                                                                <div className='align-vertical width-percent-100'>
                                                                                    {periods?.length > 0 && periods.map((element, periodIndex) => {
                                                                                        let startDate = dateStringFromTimeInterval(element.startDate, "DD/MM/YYYY");
                                                                                        let endDate = dateStringFromTimeInterval(element.endDate, "DD/MM/YYYY");
                                                                                        let summary = startDate + ' - ' + endDate;
                                                                                        let sponsors = '';
                                                                                        (element.sponsors ?? []).forEach(s => {
                                                                                            let sponsor = companies?.find((e) => { return e.nodeId == s })?.company?.name ?? '';
                                                                                            if (sponsor?.length > 0) {
                                                                                                sponsors += (sponsors.length > 0 ? ', ' : '') + sponsor;
                                                                                            }
                                                                                        });
                                                                                        if (sponsors.length > 0) {
                                                                                            sponsors = t('general.sponsors') + ': ' + sponsors;
                                                                                        }

                                                                                        let exhibitors = '';
                                                                                        (element.exhibitors ?? []).forEach(s => {
                                                                                            let exhibitor = companies?.find((e) => { return e.nodeId == s })?.company?.name ?? '';
                                                                                            if (exhibitor?.length > 0) {
                                                                                                exhibitors += (exhibitors.length > 0 ? ', ' : '') + exhibitor;
                                                                                            }
                                                                                        });
                                                                                        if (exhibitors.length > 0) {
                                                                                            exhibitors = t('event.exhibitors') + ': ' + exhibitors;
                                                                                        }

                                                                                        return <div key={periodIndex}>
                                                                                            <div className='align-vertical'>
                                                                                                <div className='align-horizontal ml-3'>
                                                                                                    <Label>{summary}</Label>
                                                                                                    <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                                        this.deletePeriod(locationIndex, periodIndex);
                                                                                                    }}>
                                                                                                        <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-activity" />
                                                                                                        <UncontrolledTooltip
                                                                                                            placement="top"
                                                                                                            id='tooltip-1'
                                                                                                            target='remove-activity'>
                                                                                                            {t("experience.hover.removeActivity")}
                                                                                                        </UncontrolledTooltip>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {sponsors.length > 0 &&
                                                                                                    <Label className='ml-3'>{sponsors}</Label>
                                                                                                }
                                                                                                {exhibitors.length > 0 &&
                                                                                                    <Label className='ml-3'>{exhibitors}</Label>
                                                                                                }
                                                                                            </div>

                                                                                            {(periodIndex == periods.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                        </div>
                                                                                    })}

                                                                                    <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                        {this.state.editingLocationIndex != locationIndex &&
                                                                                            <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                                <div className='align-horizontal'>
                                                                                                    <PlusSquare className="icon-dual icon-size-20" id="icon-add-activity"
                                                                                                        onClick={() => {
                                                                                                            if (this.state.editingLocationIndex == locationIndex) {
                                                                                                                this.cancelAddingPeriod();
                                                                                                            }
                                                                                                            else {
                                                                                                                this.beginAddingPeriod(locationIndex);
                                                                                                            }
                                                                                                        }} />
                                                                                                    <UncontrolledTooltip
                                                                                                        placement="top"
                                                                                                        id='tooltip-1'
                                                                                                        target='icon-add-activity'>
                                                                                                        {t("experience.hover.addActivity")}
                                                                                                    </UncontrolledTooltip>
                                                                                                </div>

                                                                                                <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                                    onClick={() => {
                                                                                                        if (this.state.editingLocationIndex == locationIndex) {
                                                                                                            this.cancelAddingPeriod();
                                                                                                        }
                                                                                                        else {
                                                                                                            this.beginAddingPeriod(locationIndex);
                                                                                                        }
                                                                                                    }}
                                                                                                >{t('Period')}</Label>
                                                                                            </div>
                                                                                        }

                                                                                        {this.state.editingLocationIndex == locationIndex &&
                                                                                            <div className='width-percent-100'>
                                                                                                <div className='input-container'>
                                                                                                    <div className='widh-percent-100'>
                                                                                                        <Row className='mt-2'>
                                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                                <Label className='link-selected font-size-15'>{t('training.dateStart')}</Label>
                                                                                                            </Col>
                                                                                                            <Col md={8} xl={8}>
                                                                                                                <DatePicker
                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                    placeholderText="(DD/MM/YYYY)"
                                                                                                                    selected={this.state.startDate}
                                                                                                                    className="date-picker p-2"
                                                                                                                    // minDate={dateNow}
                                                                                                                    onChange={(value) => { this.setState({ startDate: value }) }}
                                                                                                                    locale={getCurrentLanguage()}
                                                                                                                />
                                                                                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                                                                            </Col>
                                                                                                        </Row>

                                                                                                        <Row className='mt-3'>
                                                                                                            <Col md={4} xl={4} className='path-component font-size-15 text-left mt-2'>
                                                                                                                <Label className='link-selected font-size-15'>{t('training.dateEnd')}</Label>
                                                                                                            </Col>
                                                                                                            <Col md={8} xl={8}>
                                                                                                                <DatePicker
                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                    placeholderText="(DD/MM/YYYY)"
                                                                                                                    selected={this.state.endDate}
                                                                                                                    className="date-picker p-2"
                                                                                                                    // minDate={dateNow}
                                                                                                                    onChange={(value) => { this.setState({ endDate: value }) }}
                                                                                                                    locale={getCurrentLanguage()}
                                                                                                                />
                                                                                                                {isDark ? <DatePickerWrapperDarkStyles /> : <DatePickerWrapperStyles />}
                                                                                                            </Col>
                                                                                                        </Row>

                                                                                                        <Row className='mt-2 mb-0'>
                                                                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                                <Label className='link-selected font-size-15'>{t('general.sponsors')}</Label>
                                                                                                            </Col>
                                                                                                        </Row>

                                                                                                        <Row className='mb-4 ml-0 mr-0 border-1 rounded-3'>
                                                                                                            <div className='align-vertical width-percent-100'>
                                                                                                                {this.state.sponsors?.length > 0 && this.state.sponsors.map((element, sponsorIndex) => {
                                                                                                                    let sponsor = companies?.find((e) => { return e.nodeId == element })?.company?.name ?? '';
                                                                                                                    return <div key={sponsorIndex}>
                                                                                                                        <div className='align-vertical'>
                                                                                                                            <div className='align-horizontal ml-3'>
                                                                                                                                <Label>{sponsor}</Label>
                                                                                                                                <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                                                                    var sponsors = this.state.sponsors;
                                                                                                                                    sponsors.splice(sponsorIndex, 1);
                                                                                                                                    this.setState({ sponsors: sponsors });
                                                                                                                                }}>
                                                                                                                                    <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-activity" />
                                                                                                                                    <UncontrolledTooltip
                                                                                                                                        placement="top"
                                                                                                                                        id='tooltip-1'
                                                                                                                                        target='remove-activity'>
                                                                                                                                        {t("experience.hover.removeActivity")}
                                                                                                                                    </UncontrolledTooltip>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        {(sponsorIndex == this.state.sponsors.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                                                    </div>
                                                                                                                })}

                                                                                                                <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                                                    {!this.state.isSelectingSponsor &&
                                                                                                                        <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                                                            <div className='align-horizontal'>
                                                                                                                                <PlusSquare className="icon-dual icon-size-20" id="icon-add-activity"
                                                                                                                                    onClick={() => {
                                                                                                                                        if (this.state.isSelectingSponsor) {
                                                                                                                                            this.cancelAddingSponsor()
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            this.setState({ isSelectingSponsor: true })
                                                                                                                                        }
                                                                                                                                    }} />
                                                                                                                                <UncontrolledTooltip
                                                                                                                                    placement="top"
                                                                                                                                    id='tooltip-1'
                                                                                                                                    target='icon-add-activity'>
                                                                                                                                    {t("experience.hover.addActivity")}
                                                                                                                                </UncontrolledTooltip>
                                                                                                                            </div>

                                                                                                                            <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                                                                onClick={() => {
                                                                                                                                    if (this.state.isSelectingSponsor) {
                                                                                                                                        this.cancelAddingSponsor()
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        this.setState({ isSelectingSponsor: true })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            >{t('general.sponsor')}</Label>
                                                                                                                        </div>
                                                                                                                    }

                                                                                                                    {this.state.isSelectingSponsor &&
                                                                                                                        <div className='width-percent-100'>
                                                                                                                            {this.state.isSelectingSponsor &&
                                                                                                                                <div className='input-container'>
                                                                                                                                    <div className='widh-percent-100'>
                                                                                                                                        <Row className='mb-3'>
                                                                                                                                            <Col >
                                                                                                                                                <Select
                                                                                                                                                    className="react-select"
                                                                                                                                                    classNamePrefix="react-select"
                                                                                                                                                    placeholder={t('placeholder.Select')}
                                                                                                                                                    value={this.state.selectedSponsor}
                                                                                                                                                    onChange={
                                                                                                                                                        (value) => { this.setState({ selectedSponsor: value }) }
                                                                                                                                                    }
                                                                                                                                                    options={remainingSponsors}></Select>
                                                                                                                                            </Col>
                                                                                                                                        </Row>

                                                                                                                                        <Row >
                                                                                                                                            <Col md={12} xl={12} className='box-side'>
                                                                                                                                                <div></div>
                                                                                                                                                <ul className="list-inline wizard">
                                                                                                                                                    <li className="previous list-inline-item">
                                                                                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingSponsor() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                                                        <Button onClick={() => { this.finishAddingSponsor() }} color="primary" disabled={this.state.selectedSponsor == null}>{t('general.Add')}</Button>
                                                                                                                                                    </li>
                                                                                                                                                </ul>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    </div>


                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </Col>
                                                                                                            </div>
                                                                                                        </Row>

                                                                                                        <Row className='mt-2 mb-0'>
                                                                                                            <Col className='path-component font-size-15 text-left mt-2'>
                                                                                                                <Label className='link-selected font-size-15'>{t('event.exhibitors')}</Label>
                                                                                                            </Col>
                                                                                                        </Row>

                                                                                                        <Row className='mb-4 ml-0 mr-0 border-1 rounded-3'>
                                                                                                            <div className='align-vertical width-percent-100'>
                                                                                                                {this.state.exhibitors?.length > 0 && this.state.exhibitors.map((element, exhibitorIndex) => {
                                                                                                                    let exhibitor = companies?.find((e) => { return e.nodeId == element })?.company?.name ?? '';
                                                                                                                    return <div key={exhibitorIndex}>
                                                                                                                        <div className='align-vertical'>
                                                                                                                            <div className='align-horizontal ml-3'>
                                                                                                                                <Label>{exhibitor}</Label>
                                                                                                                                <div className='width-px-40 box-center ml-4' onClick={() => {
                                                                                                                                    var exhibitors = this.state.exhibitors;
                                                                                                                                    exhibitors.splice(exhibitorIndex, 1);
                                                                                                                                    this.setState({ exhibitors: exhibitors });
                                                                                                                                }}>
                                                                                                                                    <Trash className="icon-dual ml-2 icon-size-20 cursor-pointer" id="remove-exhibitor" />
                                                                                                                                    <UncontrolledTooltip
                                                                                                                                        placement="top"
                                                                                                                                        id='tooltip-1'
                                                                                                                                        target='remove-exhibitor'>
                                                                                                                                        {t("event.removeExhibitor")}
                                                                                                                                    </UncontrolledTooltip>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        {(exhibitorIndex == this.state.exhibitors.length - 1) && <div className='height-px-1 bg-color-separator mt-2 ml-3 mr-3'></div>}
                                                                                                                    </div>
                                                                                                                })}

                                                                                                                <Col className='align-vertical font-size-15 text-left mt-2'>
                                                                                                                    {!this.state.isSelectingExhibitor &&
                                                                                                                        <div className='align-horizontal mb-2 ml-1 cursor-pointer'>
                                                                                                                            <div className='align-horizontal'>
                                                                                                                                <PlusSquare className="icon-dual icon-size-20" id="icon-add-exhibitor"
                                                                                                                                    onClick={() => {
                                                                                                                                        if (this.state.isSelectingExhibitor) {
                                                                                                                                            this.cancelAddingExhibitor()
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            this.setState({ isSelectingExhibitor: true })
                                                                                                                                        }
                                                                                                                                    }} />
                                                                                                                                <UncontrolledTooltip
                                                                                                                                    placement="top"
                                                                                                                                    id='tooltip-1'
                                                                                                                                    target='icon-add-exhibitor'>
                                                                                                                                    {t("event.addExhibitor")}
                                                                                                                                </UncontrolledTooltip>
                                                                                                                            </div>

                                                                                                                            <Label className='ml-2 mt-1 mb-1 link-selected font-size-15 cursor-pointer'
                                                                                                                                onClick={() => {
                                                                                                                                    if (this.state.isSelectingExhibitor) {
                                                                                                                                        this.cancelAddingExhibitor()
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        this.setState({ isSelectingExhibitor: true })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            >{t('event.exhibitor')}</Label>
                                                                                                                        </div>
                                                                                                                    }

                                                                                                                    {this.state.isSelectingExhibitor &&
                                                                                                                        <div className='width-percent-100'>
                                                                                                                            {this.state.isSelectingExhibitor &&
                                                                                                                                <div className='input-container'>
                                                                                                                                    <div className='widh-percent-100'>
                                                                                                                                        <Row className='mb-3'>
                                                                                                                                            <Col >
                                                                                                                                                <Select
                                                                                                                                                    className="react-select"
                                                                                                                                                    classNamePrefix="react-select"
                                                                                                                                                    placeholder={t('placeholder.Select')}
                                                                                                                                                    value={this.state.selectedExhibitor}
                                                                                                                                                    onChange={
                                                                                                                                                        (value) => { this.setState({ selectedExhibitor: value }) }
                                                                                                                                                    }
                                                                                                                                                    options={remainingExhibitors}></Select>
                                                                                                                                            </Col>
                                                                                                                                        </Row>

                                                                                                                                        <Row >
                                                                                                                                            <Col md={12} xl={12} className='box-side'>
                                                                                                                                                <div></div>
                                                                                                                                                <ul className="list-inline wizard">
                                                                                                                                                    <li className="previous list-inline-item">
                                                                                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingExhibitor() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                                                        <Button onClick={() => { this.finishAddingExhibitor() }} color="primary" disabled={this.state.selectedExhibitor == null}>{t('general.Add')}</Button>
                                                                                                                                                    </li>
                                                                                                                                                </ul>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    </div>


                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </Col>
                                                                                                            </div>
                                                                                                        </Row>

                                                                                                        <Row >
                                                                                                            <Col md={12} xl={12} className='box-side'>
                                                                                                                <div></div>
                                                                                                                <ul className="list-inline wizard">
                                                                                                                    <li className="previous list-inline-item">
                                                                                                                        <Button className='mr-2' onClick={() => { this.cancelAddingPeriod() }} color="white">{t("personalInfo.cancel")}</Button>
                                                                                                                        <Button onClick={() => { this.finishAddingPeriod() }} color="primary" disabled={this.state.startDate == null || this.state.endDate == null}>{t('general.Add')}</Button>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </Col>
                                                                                </div>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <br />

                                                    <Row>
                                                        <Col md={1} xl={1}></Col>
                                                        <Col md={10} xl={10} className='font-size-15 text-left align-horizontal box-side'>
                                                            <div cla>
                                                                <Button color="white" onClick={(e) => {
                                                                    e.currentTarget.blur();
                                                                    this.props.close(null);
                                                                }}><i className='uil'></i>{t("personalInfo.cancel")}</Button>
                                                                {this.state.addEventStep != ADD_EVENT_IDENTIFICATION &&
                                                                    <Button className='ml-2' color="primary" onClick={(e) => {
                                                                        e.currentTarget.blur();
                                                                        this.setState({ addEventStep: ADD_EVENT_IDENTIFICATION })
                                                                    }}><i className='uil'></i>{t('terms.back')}</Button>
                                                                }

                                                                {this.state.addEventStep == ADD_EVENT_IDENTIFICATION &&
                                                                    <Button className='ml-2' color="primary" disabled={!nextable} onClick={(e) => {
                                                                        e.currentTarget.blur();
                                                                        this.onNext();
                                                                    }}><i className='uil'></i>{t('sub.Next')}</Button>
                                                                }
                                                            </div>
                                                            <div>
                                                                {this.state.addEventStep == ADD_EVENT_LOCATION &&
                                                                    <Button className='ml-2' disabled={!saveEnable} color="primary" onClick={(e) => {
                                                                        e.currentTarget.blur();
                                                                        this.onEventAction();

                                                                    }}><i className='uil'></i>{t('updateCV.validate')}</Button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment >
        );

    };
}

const mapStateToProps = (state) => {
    const { country, countries, loading, error } = state.Auth;
    const { menuHierarchy } = state.Profile;
    const { allNetworkCompanies, eventGroup } = state.Network;
    const { companies } = state.Company;
    const { competencyInfo } = state.Competency;

    return { country, countries, menuHierarchy, allNetworkCompanies, eventGroup, companies, competencyInfo, loading, error };
};

const mapDispatchToProps = {
    getAPICountryInfo,
    checkExistEvent,
    createNetworkEvent,
    editNetworkEvent,
    getAllNetworkCompanies,
    getAllContentsCompany,
    getCompetencyInfo,
    getEventGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AddEventModal));