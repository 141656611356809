import classnames from 'classnames';
import React, { Component } from 'react';
import {
    ChevronDown
} from 'react-feather';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Scroll from 'react-scroll';
import {
    Card, CardBody, Col, Container, Label, Nav,
    NavItem, NavLink, Row, TabContent, TabPane, UncontrolledAlert
} from 'reactstrap';
import logo_with_text from '../../assets/images/logo-with-text.png';
import logo from '../../assets/images/logo.png';
import firebase from '../../firebase/firebaseIndex';
import TopbarAuth from '../../components/TopbarAuth';
import { AuthenticationStatus, isUserAuthenticated, userAuthenticatedStatus } from '../../helpers/authUtils';
import { getPrivacyPolicy, loginUser, getContentHTML, saveNextPath } from '../../redux/actions';
import CompanyView from './CompanyView';
import ForgetPassword from './ForgetPassword';
import Register from './Register';
import SignIn from './SignIn';
import TermsOfService from './TermsOfService';
import ExternalValidation from './ExternalValidation';
import { path } from 'd3';
var Element = Scroll.Element;



class Home extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            tabviewMode: 1, // 0: sign in; 1: sign up; 2: company
            windowSize: 100,
            titleFontSize: this.scaledFontSize(),
            screenMode: 0, // 0: Home; 1: PrivacyPolicy; 2: Terms
            isResetMode: 0,
            changePasswordSucess: 0,
            callCheckResetMode: 0,
            isValidation: false,
            validationCode: null
        }
    }

    handleResize = e => {
        const windowSize = window.innerWidth;
        const titleFontSize = this.scaledFontSize()
        this.setState(prevState => {
            return {
                windowSize,
                titleFontSize
            };
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getPrivacyPolicy();
        window.addEventListener("resize", this.handleResize);

        window.appVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible"
            }
        );

        if (this.props.location.pathname) {
            if (this.props.location.search.length > 17 && this.props.location.search.startsWith('?validation_code=')) {
                let code = this.props.location.search.substring(17);
                console.log(code);
                this.setState({ validationCode: code, isValidation: true }, () => {
                    var scroll = Scroll.animateScroll;
                    scroll.scrollToBottom()
                });
            }
            else if (this.props.location.search.length > 17 && (this.props.location.search.includes('invitation_email=') || this.props.location.search.includes('invitation_phone='))) {
                // http://dev.datanexions.com/?invitation_email=thanglvan@gmail.com
                this.setState({ invitation: this.props.location.search }, () => {
                    this.toggleTabView(3);
                    var scroll = Scroll.animateScroll;
                    scroll.scrollToBottom()
                });
            }
            else if (this.props.location?.state?.nextPath?.length > 0) {
                this.props.saveNextPath(this.props.location?.state?.nextPath ?? '', {});

                if (this.props.location?.state?.nextPath?.length > 0 && this.props.location?.state?.nextPath != '/') {
                    var scroll = Scroll.animateScroll;
                    scroll.scrollToBottom()
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.handleResize);
    }

    getPrivacyPolicy = () => {
        this.props.getPrivacyPolicy({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                // this.setState({ loading: false, error: error });
                // console.log('callbackOnFailure');
            },
            callbackOnFinish: () => {
                // Update loading state?
                // this.setState({ loading: false });
                // console.log('callbackOnFinish');
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');

            },
        });
    }

    getPrivacyPolicyContentHTML = (api) => {
        this.props.getContentHTML(api, {
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                // this.setState({ loading: false, error: error });
                // console.log('callbackOnFailure');
            },
            callbackOnFinish: () => {
                // Update loading state?
                // this.setState({ loading: false });
                // console.log('callbackOnFinish');
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');

            },
        });
    }

    scaledFontSize = () => {
        const hScale = window.innerWidth * 40 / 800;
        const vScale = window.innerHeight * 50 / 600;
        return hScale < vScale ? hScale : vScale;
    }

    toggleTabView = (tab) => {
        if ((this.state.tabviewMode !== tab) || (this.state.screenMode != 0) || this.state.isValidation) {
            this.setState({
                screenMode: 0,
                isValidation: false,
                tabviewMode: tab
            });
        }
    }
    headerBarFuncSignIn = () => {
        this.toggleTabView(2)
        this.setResetMode(0)
    }
    headerBarFuncSignUp = () => {
        this.toggleTabView(3)
        this.setResetMode(0)

    }
    headerBarFuncCompany = () => {
        this.toggleTabView(1)
        this.setResetMode(0)

    }
    headerBarFuncReset = () => {
        this.toggleTabView(4)
        this.setState({
            callCheckResetMode: this.state.callCheckResetMode + 1,
        });
    }

    setResetMode = (value) => {
        this.setState({
            isResetMode: value
        })
    }

    setChangePasswordSucess = (value) => {
        this.setState({
            changePasswordSucess: value
        })
    }


    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        this.props.loginUser({ username: values.username, password: values.password });
    }


    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const status = userAuthenticatedStatus();
        if (status === AuthenticationStatus.EmailNotVerified) {
            window.scrollTo(0, 0);
            return <Redirect to='/account/confirm' />
        }
        else if (status === AuthenticationStatus.ProfileNotProvided) {
            window.scrollTo(0, 0);
            return <Redirect to='/account/personalinfo' />
        }
        else if (status === AuthenticationStatus.ProfileVerified) {
            window.scrollTo(0, 0);
            if (this.props.nextPath?.length > 0 && this.props.nextPath != '/') {
                let path = this.props.nextPath;
                this.props.saveNextPath('', {});
                return <Redirect to={{ pathname: path }} />;
            }
            return <Redirect to='/my-summary' />
        }
    }

    Alert = (props) => {
        const error = props.error;
        const { t } = this.props;
        if (error != null) {
            const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
            return <UncontrolledAlert color="danger" key="alert">
                {message != null ? message : "Cannot connect to server."}
            </UncontrolledAlert>
        }
        return ""
    }

    onValidated = () => {
        this.setState({ isValidation: false });
        this.props.history.push({
            pathname: '/'
        });
    }

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        const { t } = this.props;

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (document.querySelector('.navbar-custom')) {
                if (value > 40) {
                    document.querySelector('.navbar-custom').classList.add('sticky-background')
                } else {
                    document.querySelector('.navbar-custom').classList.remove('sticky-background')
                }
            }
        });

        return (
            <React.Fragment>
                <div id="recaptcha-container"></div>
                {this.renderRedirectToRoot()}
                {(this._isMounted || !isAuthTokenValid) && <div className="">
                    <div className="">
                        <Row className="justify-content-center">
                            <Col xl={12} >
                                <div className={(this.state.screenMode === 0) ? "main-background" : ""}>
                                    <Element name="headerForm" style={{
                                        height: "20px",
                                    }} >
                                        <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
                                            <Container fluid>
                                                <Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
                                                    <span className="logo-lg">
                                                        <img src={logo} alt="" height="40" />
                                                    </span>
                                                </Link>

                                                <TopbarAuth tabviewMode={this.state.tabviewMode}
                                                    headerBarFuncSignUp={this.headerBarFuncSignUp}
                                                    headerBarFuncSignIn={this.headerBarFuncSignIn}
                                                    headerBarFuncCompany={this.headerBarFuncCompany} />
                                            </Container>
                                        </div>
                                    </Element>

                                    {(this.state.screenMode === 0) &&
                                        <Label style={{
                                            fontSize: this.state.titleFontSize,
                                            fontWeight: 700,
                                            color: "white",
                                            marginTop: this.state.titleFontSize * 1.2
                                        }} size={this.state.titleFontSize.toString()}>{t('signIn.description')}</Label>}

                                    {(this.state.screenMode === 0) &&
                                        <div className="horizontal-red-line" />}

                                    {(this.state.screenMode === 0) &&
                                        <img style={{
                                            width: this.state.titleFontSize * 5,
                                            height: this.state.titleFontSize * 5 * 575 / 500,
                                        }}
                                            src={logo_with_text}
                                        />

                                    }

                                    {(this.state.screenMode === 0) &&
                                        <div>

                                            <Scroll.Link
                                                activeClass="active"
                                                to="introElement"
                                                spy={true}
                                                smooth={true}
                                                duration={250}
                                                style={{ display: "inline-block", margin: "20px" }}
                                            >
                                                <ChevronDown className="align-self-center" size={80} color="#e7392d"></ChevronDown>
                                            </Scroll.Link>

                                        </div>
                                    }

                                </div>

                            </Col>
                        </Row>

                        {(this.state.screenMode === 0) && <div className="mt-4 ml-4 mr-4">
                            <Element name="introElement" style={{
                                height: "1px",
                                display: 'absolute',
                                marginBottom: '72px',
                                marginTop: '-72px'
                            }} />
                            <Row className="justify-content-center intro-block">


                                <Col xl={12} >

                                    <Row >
                                        <Col>
                                            <Label className='title-label'>
                                                {t("login.networkIntro1")}
                                            </Label>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={3} md={3} sm={3} xs={3} className="d-flex">
                                            <Card className="intro-card mb-3">
                                                <CardBody>
                                                    <Label className="content-label">{t("login.networkIntro2")}</Label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={3} md={3} sm={3} xs={3} className="d-flex">
                                            <Card className="intro-card mb-3">
                                                <CardBody>
                                                    <Label className="content-label">{t("login.networkIntro3")}</Label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={3} md={3} sm={3} xs={3} className="d-flex">
                                            <Card className="intro-card mb-3">
                                                <CardBody>
                                                    <Label className="content-label">{t("login.networkIntro4")}</Label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={3} md={3} sm={3} xs={3} className="d-flex">
                                            <Card className="intro-card mb-3">
                                                <CardBody>
                                                    <Label className="content-label">{t("login.networkIntro5")}</Label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={12}>
                                            <Card className="intro-card-bottom mb-3">
                                                <CardBody>
                                                    <Label className="content-label">{t("login.networkIntro6")}</Label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                            <Element name="formSignElement" style={{
                                height: "20px",
                            }} ></Element>
                            {this.state.isValidation && <Row className="align-items-top" >
                                <ExternalValidation validationCode={this.state.validationCode} onValidated={this.onValidated} Alert={this.Alert} />
                            </Row>}
                            {!this.state.isValidation && <Row className="align-items-top" >
                                <Col xl={2} md={3} sm={4} xs={5}>
                                    <Nav tabs>
                                        <Col className="mt-2">
                                            <Row className="align-items-center ml-2">
                                                <Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
                                                    <span className="logo-sm">
                                                        <img src={logo_with_text} alt="" height="70" />
                                                    </span>
                                                </Link>
                                            </Row>
                                            {(this.state.isResetMode === 0)
                                                ?
                                                <div>
                                                    <NavItem key={0}>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({ active: this.state.tabviewMode === 1 })}
                                                            onClick={() => {
                                                                this.toggleTabView(1);
                                                            }}>
                                                            <i
                                                                className={classnames(
                                                                    'd-sm-none',
                                                                    'd-block',
                                                                    'mr-1'
                                                                )}></i>
                                                            <span className="d-none d-sm-block">{t('login.company')}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem key={1}>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({ active: this.state.tabviewMode === 2 })}
                                                            onClick={() => {
                                                                this.toggleTabView(2);
                                                            }}>
                                                            <i
                                                                className={classnames(

                                                                    'd-sm-none',
                                                                    'd-block',
                                                                    'mr-1'
                                                                )}></i>
                                                            <span className="d-none d-sm-block">{t('login.signIn1')}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem key={2}>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({ active: this.state.tabviewMode === 3 })}
                                                            onClick={() => {
                                                                this.toggleTabView(3);
                                                            }}>
                                                            <i
                                                                className={classnames(
                                                                    'd-sm-none',
                                                                    'd-block',
                                                                    'mr-1'
                                                                )}></i>
                                                            <span className="d-none d-sm-block">{t('login.signUp1')}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </div>

                                                :
                                                <NavItem key={3}>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: this.state.tabviewMode === 4 })}
                                                        onClick={() => {
                                                            this.toggleTabView(4);
                                                        }}>
                                                        <i
                                                            className={classnames(
                                                                'd-sm-none',
                                                                'd-block',
                                                                'mr-1'
                                                            )}></i>
                                                        <span className="d-none d-sm-block">{t('login.reset')}</span>
                                                    </NavLink>
                                                </NavItem>

                                            }


                                        </Col>

                                    </Nav>
                                </Col>
                                <Col xl={10} md={9} sm={8} xs={7}>
                                    <TabContent activeTab={this.state.tabviewMode} className="tab-content min-height-100">
                                        <TabPane tabId={2} key={2}>
                                            <SignIn onForgotButton={this.headerBarFuncReset} changePasswordSucess={this.state.changePasswordSucess} />
                                        </TabPane>
                                        <TabPane tabId={3} key={3}>

                                            <Register invitation={this.state.invitation} />

                                        </TabPane>
                                        <TabPane tabId={1} key={1}>

                                            <CompanyView isSelected={this.state.tabviewMode == 3} />

                                        </TabPane>
                                        <TabPane tabId={4} key={4}>

                                            <ForgetPassword onCancelButton={this.headerBarFuncSignIn} setResetMode={this.setResetMode}
                                                setChangePasswordSucess={this.setChangePasswordSucess} checkTabMode={this.state.callCheckResetMode}
                                            ></ForgetPassword>

                                        </TabPane>
                                    </TabContent>
                                </Col>

                            </Row>}
                        </div>
                        }

                        {(this.state.screenMode === 1) && <div>
                            <TermsOfService onBack={() => this.setState({ screenMode: 0 })}
                                privacyPolicy={this.props.privacyPolicy}
                                termsOfService={this.props.termsOfService}
                                isPrivacyPolicyOpen={true}
                                isTermOfServiceOpen={false}
                                stringContentHTML={this.props.stringContentHTML}
                                getPrivacyPolicyContentHTML={this.getPrivacyPolicyContentHTML}
                                activatedKeys={[0]}
                                titleHerder={t("policy.documents") + " > " + t("phoneInput.termOfService") + " > " + t(this.props.termsOfService ? this.props.termsOfService[0].name : '')}
                            />
                        </div>}

                        {(this.state.screenMode === 2) && <div>
                            <TermsOfService onBack={() => this.setState({ screenMode: 0 })}
                                privacyPolicy={this.props.privacyPolicy}
                                termsOfService={this.props.termsOfService}
                                isTermOfServiceOpen={true}
                                isPrivacyPolicyOpen={false}
                                stringContentHTML={this.props.stringContentHTML}
                                activatedKeys={[1]}
                                getPrivacyPolicyContentHTML={this.getPrivacyPolicyContentHTML} />
                        </div>}


                        <Row className="mt-4 ml-2 mr-1 d-flex justify-content-between">
                            <div>
                                <Label for="intro">{t('footer.copyright')}</Label>
                                <Link to="">
                                    <i className="uil uil-facebook ml-1"></i>
                                </Link>
                                <Link to="">
                                    <i className="uil-linkedin ml-1"></i>

                                </Link>
                                <Link to="">
                                    <i className="uil-instagram-alt ml-1" ></i>

                                </Link>
                            </div>
                            {(this.state.screenMode !== 2 && this.state.screenMode !== 1) &&
                                <div className="mr-3">
                                    <Row className="align-items-center">
                                        <Link to={{}} onClick={() => {
                                            this.getPrivacyPolicyContentHTML("privacyPolicy");
                                            this.setState({ screenMode: 1 })
                                        }}>
                                            <p className={(this.state.screenMode === 1) ? "mr-3 p-2 aligned-right bg-danger text-white" : "mr-3 p-2 aligned-right"} >{t("phoneInput.privacyPolicy")}</p>
                                        </Link>
                                        <Link to={{}} onClick={() => {

                                            this.getPrivacyPolicyContentHTML("termsOfService");
                                            this.setState({ screenMode: 2 })
                                        }
                                        }>
                                            <p className={(this.state.screenMode === 2) ? "mr-3 p-2 aligned-right bg-danger text-white" : "mr-3 p-2 aligned-right"} >{t("phoneInput.termOfService")}</p>
                                        </Link>
                                    </Row>
                                </div>
                            }
                        </Row>
                    </div>
                </div>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, loading, nextPath, error } = state.Auth;
    const { privacyPolicy, termsOfService, stringContentHTML } = state.PrivacyPolicy;
    return { user, privacyPolicy, stringContentHTML, termsOfService, nextPath, loading, error };
};

export default connect(mapStateToProps, { loginUser, getPrivacyPolicy, getContentHTML, saveNextPath })((withTranslation('translations')(Home)));
