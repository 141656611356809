// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    Edit,
    Mail,
    Trash,
    User,
    UserPlus
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Label,
    Row,
    UncontrolledTooltip,
} from 'reactstrap';
import { countryCodeToString, CustomAlertPopup, customAlertPopup, dateStringFromTimeInterval, durationBetweenDates, formatDateString, getLocalizedString, internationalPhoneFormat, isAdmin, locationString, toCETString, uniqueArray } from '../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    changeTheme,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getNetworkPeople,
    resetExistUsers,
    deleteContact,
    getDataExperience
} from '../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, getLoggedInUser } from '../../../../helpers/authUtils';
import UserInfo from '../../Settings/Profile/UserInfo';
import * as layoutConstants from '../../../../constants/layout';
import ContactModal from './ContactModal';
import { Requested } from '../../../../components/Icons';
import SVG from 'react-inlinesvg';
import { format } from '../../../../helpers/stringFormat';
import Select from 'react-select';
import InvitationModal from './InvitationModal';
import { MENU_TYPE_HOME } from '../../../../redux/appMenu/constants';
import AlertPopupView, { ALERT_TYPE_DANGER } from '../../Supports/AlertPopupView';
import RemoveNetworkModal from './RemoveNetworkModal';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Loader';

export const REGISTRATION_STATUS_NONE = 0;
export const REGISTRATION_STATUS_IN_PROCESS = 1;
export const REGISTRATION_STATUS_REGISTERED = 2;
export const REGISTRATION_STATUS_INVITED = 3;
export const REGISTRATION_STATUS_REJECTED = 4;

export const NETWORK_PERSON_TYPE_COUNTRY = 0;
export const NETWORK_PERSON_TYPE_DEPARTMENT = 1;

class NetworkPerson extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            displayWidth: 800,
            selectedNode: null,
            showContactModal: 0,
            showInvitationModal: 0,
            idType: null,
            editingCompetency: null,
            editingContact: null,
            deletingContact: null,
            selectedCountry: 'All Countries',
            selectedState: 'All States',
            selectedDepartment: 'All Departments',
            displayingDetails: [],
            showDetails: false,
            groupByCountry: true,
            currentPage: 0,
            keyword: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());
        if (this.props.countries == null || this.props.countries?.length == 0) {
            this.props.getAPICountryInfo({});
        }
        //this.props.getMenuHierarchy();

        this.getPeople();

        if (this.props.subscriptionProfile == null) {
            this.getProfileAndCompanies();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        // this.props.history.push({ pathname: '/network' });
    }

    getProfileAndCompanies = () => {
        this.props.getSubscriptionProfile({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
            },
        });
    };

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }

    onSelectedNode = (node) => {
        //alert('onSelectedNode: ' + node.labels[0]);
        this.setState({ selectedNode: node });
    };

    openContactModal = (editingContact) => {
        this.setState({
            editingContact: editingContact,
            showContactModal: 1,
            personalInfoMessage: null,
        });
    };

    closeContactModal = (message) => {
        this.setState({
            showContactModal: 0,
            editingContact: null
        }, () => {
            this.props.resetExistUsers({});
        });
    };

    closeInvitationModal = () => {
        this.setState({
            showInvitationModal: 0,
            editingContact: null,
        });
    };

    personNetworkByCountry = () => {
        let network = [];

        let people = this.props.networkPeople?.data ?? [];
        people.forEach(person => {
            let countryCode = person.country ?? '';
            let index = network.findIndex((group) => { return group.country == countryCode });
            if (index >= 0) {
                let p = network[index].people ?? [];
                p.push(person);
                network[index].people = p;
            }
            else {
                network.push({ country: countryCode, people: [person] });
            }
        });

        network.forEach(element => {
            if (element.people?.length > 1) {
                element.people.sort((a, b) => {
                    let nameA = a.firstName ?? '';
                    if (a.lastName?.length > 0) {
                        nameA += (nameA.length > 0 ? ' ' : '') + a.lastName;
                    }

                    let nameB = b.firstName ?? '';
                    if (b.lastName?.length > 0) {
                        nameB += (nameB.length > 0 ? ' ' : '') + b.lastName;
                    }

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    personNetworkByDepartment = () => {
        let network = [];

        let people = this.props.networkPeople?.data ?? [];
        if (people.length > 0) {
            console.log('asdasd');
        }

        people.forEach(person => {
            let departments = person.departments ?? [];
            if (departments.length > 0) {
                departments.forEach(department => {
                    let index = network.findIndex((group) => { return group.department == (department ?? '') });
                    if (index >= 0) {
                        if (!network[index].people.some((p) => { return p.id == person.id })) {
                            network[index].people.push(person);
                        }
                    }
                    else {
                        network.push({ department: department ?? '', people: [person] });
                    }
                });
            }
            else {
                let index = network.findIndex((group) => { return group.department == '' });
                if (index >= 0) {
                    if (!network[index].people.some((p) => { return p.id == person.id })) {
                        network[index].people.push(person);
                    }
                }
                else {
                    network.push({ department: '', people: [person] });
                }
            }
        });

        network.forEach(element => {
            if (element.people?.length > 1) {
                element.people.sort((a, b) => {
                    let nameA = a.firstName ?? '';
                    if (a.lastName?.length > 0) {
                        nameA += (nameA.length > 0 ? ' ' : '') + a.lastName;
                    }

                    let nameB = b.firstName ?? '';
                    if (b.lastName?.length > 0) {
                        nameB += (nameB.length > 0 ? ' ' : '') + b.lastName;
                    }

                    return nameA > nameB ? 1 : -1;
                })
            }
        });

        return network;
    }

    experienceSummary = (experience) => {
        let title = '';
        experience.forEach((element, index) => {
            if (index < experience.length - 1 && element.name?.length > 0) {
                title += (title.length > 0 ? ' | ' : '') + element.name;
            }
        });
        return title;
    }

    uniqueExperiences = (experiences) => {
        if (experiences == null) {
            return;
        }
        experiences = experiences.filter((value, index, self) => {
            return index === self.findIndex((element) => {
                return this.experienceSummary(value) == this.experienceSummary(element)
            })
        })

        return experiences;
    }

    userType = (id, registrationStatus, invited) => {
        let color = 'gray';
        let hover = 'personStatus.noInvitation';
        switch (registrationStatus) {
            case REGISTRATION_STATUS_REGISTERED:
                color = 'green';
                hover = 'personStatus.registered'
                break;
            case REGISTRATION_STATUS_IN_PROCESS:
                color = 'orange';
                hover = 'personStatus.underSignup'
                break;
            default:
                color = invited ? 'red' : 'gray';
                hover = invited ? 'personStatus.invited' : 'personStatus.noInvitation'
                break;
        }
        return (
            <div className={'box-center size-px-20'}>
                <User id={id} className="size-px-20" color={color} />
                <UncontrolledTooltip placement="bottom" target={id}>{this.props.t(hover)}</UncontrolledTooltip>
            </div>
        )
    }

    validationStatus = (id, type, value) => {
        let t = this.props.t;
        let color = 'bg-color-orange';
        switch (type) {
            case 'in_submited':
                color = 'bg-color-orange';
                break;
            case 'in_validated':
                color = 'bg-color-green';
                break;
            case 'in_rejected':
                color = 'bg-color-red';
                break;
            case 'out_submited':
                color = 'bg-color-orange';
                break;
            case 'out_validated':
                color = 'bg-color-green';
                break;
            case 'out_rejected':
                color = 'bg-color-red';
                break;
            default:
                break;
        }

        return (
            <div id={type + id} className={'box-center size-px-15 rounded-8 ml-2' + ' ' + color}>
                <Label className='font-size-10 text-color-white mt-0 mb-0'>{value}</Label>
                <UncontrolledTooltip placement="bottom" target={type + id}>{type}</UncontrolledTooltip>
            </div>
        )
    }

    validationStatusModule = (id, validationStatus) => {
        return (
            <div className='align-horizontal center-vertical'>
                {(validationStatus.in_submited ?? 0) > 0 &&
                    this.validationStatus(id, 'in_submited', validationStatus.in_submited)
                }
                {(validationStatus.in_validated ?? 0) > 0 &&
                    this.validationStatus(id, 'in_validated', validationStatus.in_validated)
                }
                {(validationStatus.in_rejected ?? 0) > 0 &&
                    this.validationStatus(id, 'in_rejected', validationStatus.in_rejected)
                }
                <div>
                    <SVG src={Requested} className="icon-chevron ml-2 mb-1" />
                </div>
                {(validationStatus.out_submited ?? 0) > 0 &&
                    this.validationStatus(id, 'out_submited', validationStatus.out_submited)
                }
                {(validationStatus.out_validated ?? 0) > 0 &&
                    this.validationStatus(id, 'out_validated', validationStatus.out_validated)
                }
                {(validationStatus.out_rejected ?? 0) > 0 &&
                    this.validationStatus(id, 'out_rejected', validationStatus.out_rejected)
                }
            </div>
        )
    }

    invitationModule = (id, person) => {
        let t = this.props.t;
        let color = '';
        let hover = 'network.invite';
        let invitations = person.invitations?.requests ?? [];
        if (invitations.length > 1) {
            invitations = invitations.sort((a, b) => {
                var d1 = Date.parse(a.date);
                var d2 = Date.parse(b.date);
                return d1 < d2 ? 1 : -1;
            })
        }

        let inviteable = person.emails?.length > 0 || person.email?.length > 0 || person.mobiles?.length > 0 || person.mobileNumber?.length > 0;
        let invitationDate = invitations?.length > 0 ? invitations[0].date : null;

        hover = inviteable ? 'invitation.invite' : 'invitation.missingInfo';
        if (invitationDate) {
            invitationDate = (moment(invitationDate)?.format('MMM. DD, YYYY hh:mm A') ?? '');
            let now = dateStringFromTimeInterval((new Date()).valueOf(), "DD/MM/YYYY");
            let weeks = durationBetweenDates(invitationDate, "MMM. DD, YYYY hh:mm A", now, "DD/MM/YYYY", ['weeks']);
            if (weeks < 2) {
                color = 'bg-color-green';
                hover = 'invitation.invitedRecently';
            }
            else if (weeks >= 2 && weeks < 4) {
                color = 'bg-color-orange';
                hover = 'invitation.invitedLast2weeks';
            }
            else {
                color = 'bg-color-red';
                hover = 'invitation.invitedLast4weeks';
            }
        }

        return (
            <div className='box-center mt-1'>
                <Mail
                    className={"icon-dual ml-2 size-px-20" + (inviteable ? ' cursor-pointer' : '')}
                    color={color.length > 0 ? 'gray' : (inviteable ? 'green' : 'red')}
                    id={id}
                    onClick={(e) => {
                        e.currentTarget.blur();
                        if (inviteable) {
                            this.setState({
                                editingContact: person,
                                showInvitationModal: 1
                            });
                        }
                    }
                    }
                />
                {color.length > 0 &&
                    <div className={'size-px-10 rounded-5' + ' ' + color} style={{ 'transform': 'translate(-8px, 5px)' }}></div>
                }
                <UncontrolledTooltip placement="bottom" target={id}>
                    {t(hover)}
                </UncontrolledTooltip>
            </div>
        )
    }

    showDetails = (categoryIndex, personIndex) => {
        let displayingDetails = this.state.displayingDetails;
        if (displayingDetails.some((element) => { return element.category == categoryIndex })) {
            displayingDetails.forEach((element, index) => {
                if (element.category == categoryIndex) {
                    let people = element.people ?? [];
                    let index = people.findIndex((person) => { return person == personIndex });
                    if (index >= 0) {
                        element.people.splice(index, 1);
                    }
                    else {
                        people.push(personIndex);
                        element.people = people;
                    }
                }
            });
        }
        else {
            displayingDetails.push({ category: categoryIndex, people: [personIndex] });
        }
        this.setState({ displayingDetails: displayingDetails });
    }

    combineExperiences = (experiences) => {
        let combined = [];
        experiences.forEach(exp => {
            let index = combined.findIndex((element) => { return element[0].id == exp[0].id && element[1].id == exp[1].id && element[2].id == exp[2].id })
            if (index >= 0) {
                if (exp[3].position?.length > 0 || exp[3].roles?.length > 0) {
                    combined[index] = exp;
                }
            }
            else {
                combined.push(exp);
            }
        });

        return combined;
    }

    adminString = (person, countries) => {
        let admins = '';
        (person?.admins ?? []).forEach(admin => {
            let name = admin.firstName ?? '';
            if (admin.lastName) {
                name += (name.length > 0 ? ' ' : '') + admin.lastName;
            }

            let location = locationString(person.country, admin.state, admin.city, countries);

            let adminInfo = name;
            if (location) {
                adminInfo += (adminInfo.length > 0 ? ', ' : '') + location;
            }
            if (adminInfo.length > 0) {
                admins += (admins.length > 0 ? ' | ' : '') + adminInfo;
            }
        });

        if (admins.length > 0) {
            admins = this.props.t('company.admin') + ' : ' + admins;
        }

        return admins;
    }

    createdByString = (person, countries) => {
        let t = this.props.t;

        let createdUser = null;
        if (person?.createdByUserId) {
            createdUser = (person?.introducedByUserDetails ?? []).find((u) => { return u.id == person?.createdByUserId });
        }

        let createdBy = createdUser?.firstName ?? '';
        if (createdUser?.lastName?.length > 0) {
            createdBy += (createdBy.length > 0 ? ' ' : '') + createdUser?.lastName;
        }
        let userCountry = createdUser?.country ? countryCodeToString(createdUser?.country, countries) : '';
        if (createdBy.length > 0 && userCountry?.length > 0) {
            createdBy += (createdBy.length > 0 ? ', ' : '') + userCountry;
        }

        if (createdBy.length == 0) {
            createdBy = t('company.admin');
        }

        let createdDate = person.dateCreated ? formatDateString(person.dateCreated, 'MMM. DD, YYYY') : '';

        return createdBy.length > 0 ? (createdDate?.length > 0 ? format(t('general.createdByOn'), createdBy, createdDate) : format(t('general.createdBy'), createdBy)) : '';
    }

    viewNetworkPersonDetails = (person) => {
        if (this.props.history) {
            this.props.history.push({
                pathname: '/network-person-details/' + person.id,
                state: {
                    networkPerson: person
                }
            });
        }
    }

    networkAtPage = (page) => {
        let network = this.state.groupByCountry ? this.personNetworkByCountry() : this.personNetworkByDepartment();
        let remaining = 10;


        return network;
    }

    getPeople = () => {
        if (this.props.networkPersonKeyword != this.state.keyword || this.props.networkPeople == null) {
            this.props.getNetworkPeople(this.state.groupByCountry ? NETWORK_PERSON_TYPE_COUNTRY : NETWORK_PERSON_TYPE_DEPARTMENT, 0, false, this.state.keyword, {
                callbackOnBegin: () => {
                    if(this._isMounted)
                        this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ error: error });
                },
                callbackOnFinish: () => {
                    if(this._isMounted)
                        this.setState({ loading: false });
                },
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({ currentPage: page }, () => {
            this.props.getNetworkPeople(this.state.groupByCountry ? NETWORK_PERSON_TYPE_COUNTRY : NETWORK_PERSON_TYPE_DEPARTMENT, page, false, this.state.keyword, {
                callbackOnBegin: () => {
                    this.setState({ loading: true, error: null });
                },
                callbackOnFailure: (error) => {
                    this.setState({ error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
            });
        });
    }

    render() {
        const { t } = this.props;
        const countries = this.props.countries?.map((element, i) => {
            return {
                value: element.locCode,
                label: getLocalizedString(element),
                states: element.states ? element.states : null,
            };
        });

        let groupByCountry = this.state.groupByCountry;

        let network = groupByCountry ? this.personNetworkByCountry() : this.personNetworkByDepartment();
        
        /*
        if (this.state.keyword?.length > 0 && this.state.keyword?.length < 2) {
            network = [];
        }
        else*/ /*if (this.state.keyword?.length >= 2) {
            network = network.map((group) => {
                let states = group.country?.length == 0 ? null : countries?.find((country) => { return country.value == group.country })?.states;

                let people = group.people?.filter((person) => {
                    let name = person.firstName ?? '';
                    if (person.lastName) {
                        name += (name.length > 0 ? ' ' : '') + person.lastName;
                    }

                    const isValidName = name.toLowerCase().includes(this.state.keyword.toLowerCase());

                    let isValidMail = false;
                    if (person?.emails?.length > 0) {
                        for (let i = 0; i < person.emails.length; i++) {
                            let email = person.emails[i] ?? '';
                            if (email?.length > 0) {
                                isValidMail = email?.toLowerCase().includes(this.state.keyword.toLowerCase());
                                if (isValidPosition) {
                                    break;
                                }
                            }
                        }
                    }
                    else if (person.email?.length > 0) {
                        isValidMail = person.email.toLowerCase().includes(this.state.keyword.toLowerCase());
                    }

                    let isValidPhone = false;
                    if (person?.mobiles?.length > 0) {
                        for (let i = 0; i < person.mobiles.length; i++) {
                            let mobile = person.mobiles[i] ?? '';
                            if (mobile?.length > 0) {
                                isValidPhone = mobile?.toLowerCase().includes(this.state.keyword.toLowerCase());
                                if (isValidPosition) {
                                    break;
                                }
                            }
                        }
                    }
                    else if (person.mobileNumber?.length > 0) {
                        isValidPhone = person.mobileNumber.toLowerCase().includes(this.state.keyword.toLowerCase());
                    }

                    let country = person.country?.length == 0 ? '' : countryCodeToString(person.country, countries);
                    const isValidCountry = country?.length == 0 ? true : (country ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());
                    const isValidCity = person.city?.length == 0 ? true : (person.city ?? '').toLowerCase().includes(this.state.keyword.toLowerCase());

                    let isValidDepartment = false;
                    if (person?.departments?.length > 0) {
                        for (let i = 0; i < person.departments.length; i++) {
                            let department = person.departments[i] ?? '';
                            if (department?.length > 0) {
                                isValidDepartment = department?.toLowerCase().includes(this.state.keyword.toLowerCase());
                                if (isValidDepartment) {
                                    break;
                                }
                            }
                        }
                    }

                    let isValidPosition = false;
                    if (person?.positions?.length > 0) {
                        for (let i = 0; i < person.positions.length; i++) {
                            let position = person.positions[i] ?? '';
                            if (position?.length > 0) {
                                isValidPosition = position?.toLowerCase().includes(this.state.keyword.toLowerCase());
                                if (isValidPosition) {
                                    break;
                                }
                            }
                        }
                    }

                    let isValidExperience = false;
                    if (person?.experiences?.length > 0) {
                        for (let i = 0; i < person.experiences.length; i++) {
                            let exp = this.experienceSummary(person.experiences[i]);
                            if (exp?.length > 0) {
                                isValidExperience = exp?.toLowerCase().includes(this.state.keyword.toLowerCase());
                                if (isValidExperience) {
                                    break;
                                }
                            }
                        }
                    }

                    let isValidAdmin = false;
                    let adminString = this.adminString(person, countries);
                    if (adminString.length > 0) {
                        isValidAdmin = adminString?.toLowerCase().includes(this.state.keyword.toLowerCase());
                    }

                    let isValidCreatedBy = false;
                    let createdByString = this.createdByString(person, countries);
                    if (createdByString?.length > 0) {
                        isValidCreatedBy = createdByString.toLowerCase().includes(this.state.keyword.toLowerCase());
                    }

                    if (states == null) {
                        return isValidName || isValidMail || isValidCountry || isValidCity || isValidPhone || isValidDepartment || isValidPosition || isValidExperience || isValidCreatedBy || isValidAdmin;
                    }

                    let state = states?.find((state) => { return state.id == person.state ?? '' });
                    let stateName = state?.name ? getLocalizedString(state?.name) : '';
                    let isValidState = stateName.toLowerCase().includes(this.state.keyword.toLowerCase());
                    return isValidName || isValidMail || isValidCountry || isValidCity || isValidPhone || isValidState || isValidDepartment || isValidPosition || isValidExperience || isValidCreatedBy || isValidAdmin;
                });
                group.people = people;
                return group;
            }, this);
        }*/

        let filteredNetwork = [];
        if (groupByCountry) {
            filteredNetwork = this.state.selectedCountry == 'All Countries' ? network : network.filter((group) => { return group.country == this.state.selectedCountry });
        }
        else {
            filteredNetwork = this.state.selectedDepartment == 'All Departments' ? network : network.filter((group) => { return group.department == this.state.selectedDepartment });
        }

        console.log('network', network);
        
        let availableCountries = [];
        let networkCountries = [];
        let availableDepartments = [];
        let networkDepartments = [];
        if (groupByCountry) {
            availableCountries = [
                {
                    value: 'All Countries',
                    label: t('country.allCountries'),
                    states: null,
                }
            ];

            networkCountries = network.map((group) => { return group.country });
            networkCountries = networkCountries?.map((countryCode) => {
                if (countryCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return countries.find((c) => { return c.value == countryCode });
            });
            networkCountries.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkCountries.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableCountries = availableCountries.concat(networkCountries);
        }
        else {
            availableDepartments = [
                {
                    value: 'All Departments',
                    label: t('department.allDepartments'),
                }
            ];

            networkDepartments = network.map((group) => { return group.department });
            networkDepartments = networkDepartments?.map((department) => {
                if (department.length == 0) {
                    return {
                        value: '',
                        label: t('sub.Others'),
                    }
                }

                return {
                    value: department,
                    label: department
                };
            }, this);
            networkDepartments.sort(function (a, b) { return b.label.localeCompare(a.label) });
            networkDepartments.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });
            availableDepartments = availableDepartments.concat(networkDepartments);
        }

        let selectedCountry = (this.state.selectedCountry && availableCountries.length > 0) ? availableCountries.find((element) => { return element.value == this.state.selectedCountry }) : null;
        let states = selectedCountry?.states?.map((state, i) => {
            return {
                value: state.id,
                label: getLocalizedString(state.name),
            };
        }) ?? [];

        let availableStates = [];
        if (filteredNetwork?.length == 1 && states.length > 0) {
            let people = filteredNetwork[0].people ?? [];
            let statePs = [];
            people.forEach(person => {
                let state = person.state ?? '';
                const exist = statePs.some((stateCode) => { return stateCode == state });
                if (!exist) {
                    statePs.push(state);
                }
            });
            statePs = statePs.map((stateCode) => {
                if (stateCode.length == 0) {
                    return {
                        value: 'Other',
                        label: t('sub.Others'),
                        states: null,
                    }
                }
                return states.find((c) => { return c.value == stateCode });
            });
            statePs.sort(function (a, b) { return b.label.localeCompare(a.label) });
            statePs.sort(function (a, b) { return a.label == t('sub.Others') ? 1 : -1 });

            availableStates = [
                {
                    value: 'All States',
                    label: t('country.allStates'),
                }
            ];
            availableStates = availableStates.concat(statePs);
        }

        filteredNetwork = filteredNetwork.map(group => {
            if (this.state.selectedState && this.state.selectedState != 'All States') {
                let people = group.people;
                people = people?.filter((person) => { return (person.state ?? '') == (this.state.selectedState == 'Other' ? '' : this.state.selectedState) })
                group.people = people;
            }
            return group
        }, this);

        filteredNetwork = filteredNetwork.filter((group) => { return group.people?.length > 0 });
        filteredNetwork.sort(function (a, b) {
            if (groupByCountry) {
                let countryA = a.country?.length == 0 ? '' : countryCodeToString(a.country, countries)
                let countryB = b.country?.length == 0 ? '' : countryCodeToString(b.country, countries)
                return a.country.length == 0 ? 1 : countryB.localeCompare(countryA)
            }
            else {
                let departmentA = a.department ?? '';
                let departmentB = b.department ?? '';
                return departmentA == 'Others' ? 1 : departmentB.localeCompare(departmentA)
            }
        });
        filteredNetwork.sort(function (a, b) {
            if (groupByCountry) {
                return a.country.length == 0 ? 1 : -1
            }
            else {
                return a.department.length == 0 ? 1 : -1
            }
        });

        return (
            <React.Fragment>
                {(this.state.showAlertPopup ?? false) &&
                    <RemoveNetworkModal
                        modal={this.state.showAlertPopup}
                        networkPerson={this.state.deletingContact}
                        onCancel={() => { this.setState({ showAlertPopup: false, deletingContact: null }) }}
                        onSubmit={() => {
                            if (this.state.deletingContact?.id) {
                                this.props.deleteContact(this.state.deletingContact.id, {
                                    callbackOnBegin: () => {
                                        // Update loading state?
                                        // console.log('callbackOnBegin');
                                        this.setState({ loading: true });
                                    },
                                    callbackOnFailure: (error) => {
                                        // show error to your users or stay quiet
                                        this.setState({ loading: false, error: error });
                                    },
                                    callbackOnFinish: () => {
                                        // Update loading state?
                                        this.setState({ loading: false });
                                    },
                                    callbackOnSuccess: (response) => {
                                        this.setState({ showAlertPopup: false, deletingContact: null })
                                    },
                                });
                            }
                        }}
                    />
                }

                {this.state.showContactModal == 1 &&
                    <ContactModal
                        id="add-contact"
                        title={t('profile.updatePersonalInformation')}
                        editingContact={this.state.editingContact ? JSON.parse(JSON.stringify(this.state.editingContact)) : null}
                        modal={this.state.showContactModal == 1}
                        close={this.closeContactModal}
                    />
                }

                {this.state.showInvitationModal == 1 &&
                    <InvitationModal
                        id="network-invitation"
                        userId={this.state.editingContact?.id}
                        contact={this.state.editingContact}
                        modal={this.state.showInvitationModal == 1}
                        close={this.closeInvitationModal}
                    />
                }

                <Row className="step-company">
                    <Col md={8}>
                        <UserInfo
                            countries={countries}
                            waitingForEmail={t('sub.waitingForEmail')}
                            missingPersonal={t('sub.declareCompanyStatus')}
                            missingPersonalIndependant={t('userInfo.declareCompanyStatusIndependant')}
                            showValidation={false}
                            NoActiveCompany={t('sub.noActiveCompany')}
                            isCompany={false}
                            profile={this.props.subscriptionProfile}
                        />
                    </Col>
                </Row>

                <Card className="application-settings">
                    <CardBody>
                        {this.state.loading && <Loader />}

                        <div className="path-component align-horizontal">
                            <Label className="link" onClick={this.onBack}>{t('Network')} </Label>
                            <Label className="link-separator ml-2 mr-2">/</Label>
                            <Label className="link-selected">{t('general.person')} </Label>
                            <UserPlus
                                className="icon-dual ml-3 icon-size-20"
                                id="add-contact"
                                onClick={(e) => {
                                    e.currentTarget.blur();
                                    this.openContactModal()
                                }}
                            />

                            <UncontrolledTooltip placement="bottom" target="add-contact">
                                {t('network.addPerson')}
                            </UncontrolledTooltip>
                        </div>
                    </CardBody>
                    <CardBody className="text-box">
                        <Row className='ml-4'>
                            <Col xl={3}>
                                {groupByCountry ?
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableCountries}
                                        placeholder={t('placeholder.Select')}
                                        value={(this.state.selectedCountry && availableCountries.length > 0) ? availableCountries.find((element) => { return element.value == this.state.selectedCountry }) : null}
                                        onChange={(value) => { this.setState({ selectedCountry: value.value == 'Other' ? '' : value.value, selectedState: 'All States' }) }} />
                                    :
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableDepartments}
                                        placeholder={t('placeholder.Select')}
                                        value={(this.state.selectedDepartment && availableDepartments.length > 0) ? availableDepartments.find((element) => { return element.value == this.state.selectedDepartment }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedDepartment != value.value) {
                                                this.setState({ selectedDepartment: value.value, selectedState: 'All States' })
                                            }
                                        }} />
                                }
                            </Col>
                            {states.length > 0 &&
                                <Col xl={3}>
                                    <Select
                                        className='react-select mb-3'
                                        classNamePrefix="react-select"
                                        name="career" id="career"
                                        options={availableStates}
                                        placeholder={t('placeholder.Select')}
                                        value={this.state.selectedState ? availableStates.find((element) => { return element.value == this.state.selectedState }) : null}
                                        onChange={(value) => {
                                            if (this.state.selectedState != value.value) {
                                                this.setState({ selectedState: value.value })
                                            }
                                        }} />
                                </Col>
                            }

                            <Col xl={3} className='box-side'>
                                <div className="width-percent-100 float-sm-right mt-3 mt-sm-0 mb-3">
                                    <div className="width-percent-100 task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control search-input rounded-5"
                                                    placeholder="Search..."
                                                    value={this.state.keyword ?? ''}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({ keyword: e.target.value ?? '' }, () => {
                                                            if (!(this.props.networkPersonKeyword.length < 3 && this.state.keyword.length < 3)) {
                                                                this.getPeople();
                                                            }
                                                        })
                                                    }}
                                                />
                                                <span className="uil uil-search icon-search"></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>


                            <Col xl={3} className='box-side'>
                                <div className='align-horizontal mb-2'>
                                    <div>
                                        <label className="cursor-pointer mr-2 mb-3" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            {t('company.byCountry')}
                                        </label>
                                    </div>
                                    <div className='hard-custom-switch'>
                                        <div className="custom-control custom-switch mb-3 cursor-pointer" onClick={() => {
                                            this.setState({ groupByCountry: !this.state.groupByCountry }, () => {
                                                this.handlePageClick(0);
                                            })
                                        }}>
                                            <input
                                                type="radio"
                                                className="custom-control-input cursor-pointer"
                                                checked={!this.state.groupByCountry}
                                                onChange={() => { }}
                                            />
                                            <label className="custom-control-label cursor-pointer">
                                                {t('person.byDepartment')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col className='box-side'>
                                {groupByCountry && states.length > 0 &&
                                    <div></div>
                                }
                                <div className={"custom-control custom-switch mb-3 cursor-pointer" + (groupByCountry ? ' mr-2' : '')} onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}>
                                    <input
                                        type="radio"
                                        className="custom-control-input cursor-pointer"
                                        checked={this.state.showDetails}
                                        onChange={() => { }}
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                                        {t('general.details')}
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <br />

                        {filteredNetwork.length > 0 && filteredNetwork.map((item, index) => {
                            let country = item.country?.length == 0 ? t('sub.Others') : (countryCodeToString(item.country, countries) ?? '');
                            let department = item.department?.length == 0 ? t('sub.Others') : item.department;

                            return (
                                <div key={'network' + index} >
                                    <div className='mb-2'>
                                        <Row className="path-component ml-4">
                                            <Col>
                                                <Label className='link-selected'>{groupByCountry ? country : department}</Label>
                                            </Col>
                                        </Row>
                                        {item.people.length > 0 && item.people.map((person, pidx) => {

                                            let experiences = person.experiences?.experiences ?? [];
                                            if (experiences.length > 0) {
                                                experiences = this.combineExperiences(experiences);
                                            }
                                            if (experiences.length > 0) {
                                                experiences = this.uniqueExperiences(experiences);
                                            }

                                            let name = person.firstName ?? '';
                                            if (person.lastName) {
                                                name += (name.length > 0 ? ' ' : '') + person.lastName;
                                            }

                                            let info = name;

                                            let personCountry = person.country?.length == 0 ? '' : (countryCodeToString(person.country, countries) ?? '');

                                            let position = '';
                                            if (person.positions?.length > 0) {
                                                let positions = person.positions.filter((value, index, self) => { return index == self.findIndex((d) => { return d == value }) })
                                                positions.forEach(element => {
                                                    position += (position.length > 0 ? ', ' : '') + element;
                                                });
                                            }

                                            let department = '';
                                            if (person.departments?.length > 0) {
                                                let departments = uniqueArray(person.departments, 'nodeId');
                                                departments.forEach(element => {
                                                    if (element.name?.length > 0) {
                                                        department += (department.length > 0 ? ', ' : '') + element.name;
                                                    }
                                                });
                                            }

                                            let perDetails = groupByCountry ? (position + (position.length > 0 ? ' | ' : '') + department) : personCountry;
                                            if (perDetails.length > 0) {
                                                info += (info.length > 0 ? ' | ' : '') + perDetails;
                                            }

                                            let mobiles = '';
                                            if (person.mobiles?.length > 0) {
                                                person.mobiles.forEach(mobile => {
                                                    mobiles += (mobiles.length > 0 ? ', ' : '') + internationalPhoneFormat(mobile);
                                                });
                                            }
                                            else if (person.mobileNumber?.length > 0) {
                                                mobiles = internationalPhoneFormat(person.mobileNumber);
                                            }

                                            let emails = '';
                                            if (person.emails) {
                                                person.emails.forEach(email => {
                                                    emails += (emails.length > 0 ? ', ' : '') + email.email;
                                                });
                                            }
                                            else if (person.email?.length > 0) {
                                                emails = person.email;
                                            }

                                            let contact = mobiles;
                                            if (emails.length > 0) {
                                                contact += (contact.length > 0 ? ', ' : '') + emails;
                                            }

                                            if (contact.length > 0) {
                                                info += (info.length > 0 ? ' | ' : '') + contact;
                                            }

                                            if (person.invitations?.length > 0) {
                                                let invite = person.invitations[0];
                                                let inviteString = format(t('Invited on {0} by {1}'), invite.invitationDate, invite.user);
                                                info += (info.length > 0 ? ' | ' : '') + inviteString;
                                            }

                                            const updated = person.dateUpdate != null;
                                            const requested = person.username?.length > 0;
                                            const registrationStatus = requested ? (updated ? REGISTRATION_STATUS_REGISTERED : REGISTRATION_STATUS_IN_PROCESS) : REGISTRATION_STATUS_NONE;

                                            let validationStatus = { in_submited: person.submittedValidationIn, in_validated: person.validatedValidationIn, in_rejected: person.rejectedValidationIn, out_submited: person.submittedValidationOut, out_validated: person.validatedValidationOut, out_rejected: person.rejectedValidationOut };
                                            let keys = Object.keys(validationStatus);
                                            let hasValidation = false;
                                            keys.forEach(key => {
                                                let value = validationStatus[key] ?? 0;
                                                if (value > 0) {
                                                    hasValidation = true;
                                                    return;
                                                }
                                            });

                                            let admins = this.adminString(person, countries);

                                            let createdUser = null;
                                            if (person?.createdByUserId) {
                                                createdUser = (person?.introducedByUserDetails ?? []).find((u) => { return u.id == person?.createdByUserId });
                                            }

                                            let createdByString = this.createdByString(person, countries);

                                            const hasDetails = experiences?.length > 0 || admins.length > 0 || createdByString.length > 0;

                                            // const isDisplayingDetails = this.state.displayingDetails.some((element) => {
                                            //     const sameCategory = element.category == index;
                                            //     const samePerson = (element.people ?? []).some((person) => { return person == pidx });
                                            //     return sameCategory && samePerson;
                                            // });

                                            return (
                                                <div className='ml-2' key={index + "_" + pidx}>
                                                    <div className="path-component align-horizontal ml-4">
                                                        {this.userType('userType' + index + pidx, registrationStatus, person.invitations?.requests?.length > 0 || (person.hasOtherInvitations ?? false))}
                                                        <Label className='ml-2 link-horver font-size-15' onClick={() => { this.viewNetworkPersonDetails(person) }}>{info}</Label>
                                                        <div className='ml-3 align-horizontal'>
                                                            {hasValidation &&
                                                                this.validationStatusModule('' + index + pidx, validationStatus)
                                                            }
                                                            {registrationStatus == REGISTRATION_STATUS_NONE &&
                                                                <div className='align-horizontal'>
                                                                    {hasValidation &&
                                                                        <div className='ml-3 mr-3'>|</div>
                                                                    }
                                                                    <div className='align-horizontal'>
                                                                        {(person?.createdByUserId == getLoggedInUser()?.id || isAdmin()) &&
                                                                            <div>
                                                                                <Edit className="icon-size-20 cursor-pointer" color='gray' id={'edit' + index + pidx} onClick={(e) => {
                                                                                    e.currentTarget.blur();
                                                                                    this.setState({ editingContact: person, showContactModal: true })
                                                                                }} />
                                                                                <UncontrolledTooltip placement="bottom" target={'edit' + index + pidx}>
                                                                                    {t('network.editPersonInfo')}
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        }
                                                                        <div className='float-right size-px-20 box-center ml-2'>
                                                                            <Trash className="icon-dual size-px-20 cursor-pointer" id={'delete' + index + pidx}
                                                                                onClick={() => {
                                                                                    this.setState({ deletingContact: person, showAlertPopup: true })
                                                                                }} />
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                id='tooltip-1'
                                                                                target={'delete' + index + pidx}>
                                                                                {t("experience.hover.removePerson")}
                                                                            </UncontrolledTooltip>
                                                                        </div>
                                                                        {this.invitationModule("invite" + index + pidx, person)}


                                                                    </div>
                                                                </div>
                                                            }
                                                            {/* <div>
                                                                <img src={information} id={'contact-details' + index + pidx} className="size-px-18 ml-2 mr-2" onClick={() => {
                                                                    this.showDetails(index, pidx);
                                                                }} />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    id='tooltip-1'
                                                                    target={'contact-details' + index + pidx}>
                                                                    {t("hover.existContactDetails")}
                                                                </UncontrolledTooltip>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {this.props.didLoadPeopleDetails && this.state.showDetails && hasDetails &&
                                                        <div className='bg-color-details align-vertical ml-4 mb-3'>
                                                            <div className='align-vertical mt-1 mb-1'>
                                                                {experiences?.length > 0 && experiences.map((experience) => {
                                                                    let exp = this.experienceSummary(experience);
                                                                    return (
                                                                        <Row className='ml-3 mb-1 mt-1'>
                                                                            <Col>
                                                                                <Label className='mb-0 mr-4'>{exp}</Label>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }, this)}

                                                                {admins.length > 0 &&
                                                                    <Row className='ml-3 mb-1 mt-1'>
                                                                        <Col className='align-horizontal'>
                                                                            <Label className='mb-0 mt-1'>{admins}</Label>
                                                                        </Col>
                                                                    </Row>
                                                                }

                                                                {createdByString.length > 0 &&
                                                                    <Row className='ml-3 mb-1'>
                                                                        <Col>
                                                                            <Label className='mb-0 mt-1 font-size-13 text-color-system-notice'>{createdByString}</Label>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {(index < filteredNetwork.length - 1) &&
                                        <Row className="path-component ml-3">
                                            <Col>
                                                <div className='separator-line mb-3'></div>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            )
                        })}

                        {this.props.networkPeople?.total_pages > 1 &&
                            <div className='float-right mt-2'>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.props.networkPeople?.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    forcePage={this.state.currentPage}
                                    onPageChange={(e) => { this.handlePageClick(e.selected) }}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { subscriptionProfile, menuHierarchy, loading, error } = state.Profile;
    const { countries } = state.Auth;
    const { networkPersonKeyword, networkPeople, didLoadPeopleDetails } = state.Network;
    return { menuHierarchy, subscriptionProfile, countries, networkPersonKeyword, networkPeople, didLoadPeopleDetails, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getAPICountryInfo,
    getSubscriptionProfile,
    initMenuWithMenuItems,
    changeSidebarType,
    initMenuSubscription,
    getMenuHierarchy,
    getNetworkPeople,
    resetExistUsers,
    deleteContact
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkPerson));
