// @flow

import {combineReducers} from 'redux';
import AppMenu from './appMenu/reducers';
import Auth from './auth/reducers';
import Company from './company/reducers';
import Layout from './layout/reducers';
import Profile from './profile/reducers';
import RepoCyclesteps from './repocyclesteps/reducers';
import RepoProducts from './repoproducts/reducers';
import RepoProfiles from './repoprofiles/reducers';
import RepoSites from './reposites/reducers';
import Repositories from './repositories/reducers';
import PrivacyPolicy from './privacyPolicy/reducers';
import Payment from './payment/reducers';
import Academics from './academics/reducers';
import Competency from './competency/reducers';
import Nexion from './nexion/reducers';
import Training from './training/reducers';
import Validation from './validation/reducers';
import Event from './event/reducers';
import SearchReducer from './search/reducers';
import Network from './network/reducers';
export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Profile,
    RepoProfiles,
    RepoProducts,
    Repositories,
    RepoSites,
    RepoCyclesteps,
    Company,
    PrivacyPolicy,
    Payment,
    Academics,
    Competency,
    Nexion,
    Training,
    Validation,
    Event,
    SearchReducer,
    Network
});
