// @flow
import {all} from 'redux-saga/effects';
import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import companiesSaga from './company/saga';
import layoutSaga from './layout/saga';
import profileSaga from './profile/saga';
import repocyclestepsSaga from './repocyclesteps/saga';
import repoproductsSaga from './repoproducts/saga';
import repoprofilesSaga from './repoprofiles/saga';
import repositesSaga from './reposites/saga';
import repositoriesSaga from './repositories/saga';
import repoprivacyPolicySaga from './privacyPolicy/saga';
import paymentSaga from './payment/saga';
import repoAcademicsSaga from './academics/saga';
import repoCompetenciesSaga from './competency/saga';
import repoNexionsSaga from './nexion/saga';
import repoTrainingSaga from './training/saga';
import repoValidationSaga from './validation/saga';
import eventSaga from './event/saga';
import searchSaga from './search/saga';
import networkSaga from './network/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), appMenuSaga(), profileSaga(), repoprofilesSaga(), 
        repositoriesSaga(), repoproductsSaga(), repositesSaga(), repocyclestepsSaga(), companiesSaga(), 
        repoprivacyPolicySaga(), paymentSaga(), repoAcademicsSaga(), repoCompetenciesSaga(), repoNexionsSaga(),
        repoTrainingSaga(), repoValidationSaga(), eventSaga(), searchSaga(), networkSaga()]);
}
