// @flow
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
    Alert,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import { getLocalizedString } from '../../../../../../helpers/utils';
import { getRepoProducts, postPayment } from '../../../../../../redux/actions';
import { CheckCircle } from 'react-feather';
import { format } from '../../../../../../helpers/stringFormat';

class CurrentSubscription extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            size: 'lg',
            loading: false,
            activeTabInfo: 1,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.setState({
            selectedOption: this.props.purchasesOption,
            selectedDetail: this.props.purchasesDetail,
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    Alert = (props) => {
        const { t } = this.props;
        const error = props.error;
        if (error != null) {
            const message =
                error.response && error.response.data && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            return (
                <Alert color="danger" key="alert">
                    {message != null ? message : t('Cannot connect to server.')}
                </Alert>
            );
        }
        return '';
    };

    toggleTabInfo(tab) {
        if (this.state.activeTabInfo !== tab) {
            this.setState({
                activeTabInfo: tab,
            });
        }
    }
    render() {
        const { t, productDocs } = this.props;

        return (
            <React.Fragment>
                <div>
                    {productDocs &&
                        productDocs?.detail?.features?.length > 0 && (
                            <div xl={12}>
                                <Row xl={12}>
                                    <Col xl={12}>
                                        <Label>
                                            {
                                                format(t('sub.editionDes'), productDocs?.detail?.name != null ? getLocalizedString(productDocs.detail.name) : '')
                                            }
                                        </Label>
                                    </Col>
                                </Row>

                                {productDocs?.detail?.standardFeatures?.length > 0 && productDocs?.detail?.standardFeatures?.map(
                                    (element, i) => {
                                        return (
                                            <Row xl={12} className='ml-4' key={i}>
                                                <div className="mt-0 mb-1">
                                                    <CheckCircle
                                                        color="green"
                                                        size={18}
                                                    />
                                                </div>
                                                <div className="mt-0 mb-1  ml-2">
                                                    <Label>
                                                        {getLocalizedString(
                                                            element.name
                                                        )}
                                                    </Label>
                                                </div>
                                            </Row>
                                        );
                                    }, this)}

                                {productDocs?.detail?.features ? productDocs?.detail?.features.map(
                                    (element, i) => {
                                        return (
                                            <Row xl={12} className='ml-4' key={i}>
                                                <div className="mt-0 mb-1">
                                                    <CheckCircle
                                                        color="green"
                                                        size={18}
                                                    />
                                                </div>
                                                <div className="mt-0 mb-1  ml-2">
                                                    <Label>
                                                        {getLocalizedString(
                                                            element.name
                                                        )}
                                                    </Label>
                                                </div>
                                            </Row>
                                        );
                                    }, this) :
                                    <div >
                                        <Label>{t('payment.youonlyhavefree')}</Label>
                                    </div>}
                            </div>
                        )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { repoproducts, currencies, loading } = state.RepoProducts;
    const { payment, error } = state.Payment;
    return { repoproducts, currencies, payment, loading, error };
};

const mapDispatchToProps = {
    getRepoProducts,
    postPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CurrentSubscription));
