// @flow
import {
    GET_PRIVACYPOLICY,GET_PRIVACYPOLICY_FAILED,GET_PRIVACYPOLICY_SUCCESS,
    GET_TERMOFSERVICE,GET_TERMOFSERVICE_FAILED,GET_TERMOFSERVICE_SUCCESS,
    GET_CONTENT_HTML, GET_CONTENT_HTML_FAILED, GET_CONTENT_HTML_SUCCESS
} from './constants';

const INIT_STATE = {
    privacyPolicy: {},
    termsOfService:{},
    stringContentHTML:'',
    loading: false,
    error: null
};

const RepoProducts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRIVACYPOLICY:
            return { ...state, loading: true };
        case GET_PRIVACYPOLICY_SUCCESS:
            return { ...state, privacyPolicy: action.payload, loading: false, error: null };
        case GET_PRIVACYPOLICY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_TERMOFSERVICE:
            return { ...state, loading: true };
        case GET_TERMOFSERVICE_SUCCESS:
            return { ...state, termsOfService: action.payload, loading: false, error: null };
        case GET_TERMOFSERVICE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_CONTENT_HTML:
            return { ...state, loading: true };
        case GET_CONTENT_HTML_SUCCESS:
            return { ...state, stringContentHTML: action.payload, loading: false, error: null };
        case GET_CONTENT_HTML_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RepoProducts;
