/* AUTH */
export const GET_PRIVACYPOLICY = 'GET_PRIVACYPOLICY';
export const GET_PRIVACYPOLICY_SUCCESS = 'GET_PRIVACYPOLICY_SUCCESS';
export const GET_PRIVACYPOLICY_FAILED = 'GET_PRIVACYPOLICY_FAILED';

export const GET_TERMOFSERVICE = "GET_TERMOFSERVICE";
export const GET_TERMOFSERVICE_FAILED="GET_TERMOFSERVICE_FAILED";
export const GET_TERMOFSERVICE_SUCCESS="GET_TERMOFSERVICE_SUCCESS";
export const GET_CONTENT_HTML= "GET_CONTENT_HTML"; 
export const GET_CONTENT_HTML_FAILED= "GET_CONTENT_HTML_FAILED"; 
export const GET_CONTENT_HTML_SUCCESS= "GET_CONTENT_HTML_SUCCESS";
