import classNames from 'classnames';
import MetisMenu from 'metismenujs';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { changeActiveMenuFromLocation, initMenu, selectSearchCategory } from '../redux/actions';
import SVG from 'react-inlinesvg';
import { AuthenticationStatus, isDarkMode, userAuthenticatedStatus } from '../helpers/authUtils';

const MenuItemWithChildren = ({ item, linkClassNames, subMenuClassNames, activatedMenuItemIds, push }) => {
    const Icon = item.icon || null;
    const LocalIcon = item.localIcon || null;
    return (
        <li className={classNames('side-nav-item', { 'mm-active': activatedMenuItemIds.indexOf(item.id) >= 0 })}>
            <Link
                to={{ pathname: item.path }}
                className={classNames('side-sub-nav-link', linkClassNames)}
                onClick={(e) => {
                    // DO NOT preventDefault
                    //e.preventDefault();
                    setTimeout(function () {
                        if (item.id) {
                            push(item.path);
                        }
                    }, 800);

                }}
                aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}>
                {item.icon &&
                    <Icon className="icon-dual icon-medium mr-2" />
                }
                {item.localIcon &&
                    <SVG src={LocalIcon} className="icon-dual icon-xs mr-2" />
                }
                {item.badge && (
                    <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>
                )}
                <span> {item.name} </span>
                <span className="menu-arrow"></span>
            </Link>

            <ul
                className={classNames(subMenuClassNames, 'mm-collapse', {
                    'mm-collapsed mm-show': activatedMenuItemIds.indexOf(item.id) >= 0,
                })}
                aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}>
                {item.children.map((child, i) => {
                    return (
                        <React.Fragment key={i}>
                            {child.children ? (
                                <MenuItemWithChildren
                                    item={child}
                                    linkClassNames=""
                                    activatedMenuItemIds={activatedMenuItemIds}
                                    subMenuClassNames="side-nav-third-level"
                                    push={push}
                                />
                            ) : (
                                <MenuItem
                                    item={child}
                                    className={classNames({ active: activatedMenuItemIds.indexOf(child.id) >= 0 })}
                                    linkClassName=""
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </li>
    );
};

const MenuItem = ({ item, className, linkClassName }) => {
    return (
        <li className={classNames('side-nav-item', className)}>
            <MenuItemLink item={item} className={className} />
        </li>
    );
};

const MenuItemLink = ({ item, className }) => {
    const Icon = item.icon || null;
    const LocalIcon = item.localIcon || null;

    let path = { pathname: item.path };
    if (item.entity) {
        path.state = { entity: item.entity };
    }

    return (
        <NavLink isActive={(match, location) => {
                return className === 'active'
            }}
            to={path} className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)} >
            {item.icon && <Icon />}
            {item.localIcon &&
                <SVG src={LocalIcon} className="icon-dual icon-xs mr-2" />
            }
            {item.badge && <span className={`font-size-12 badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
            <span> {item.name} </span>
        </NavLink>
    );
};


/**
 * Renders the application menu
 */

class AppMenu extends Component {
    menuRef = null;

    static defaultProps = {
        mode: 'vertical',
    };

    componentDidMount = () => {
        if (!this.props.menu.menuItems) this.props.initMenu();
        else this.initMenu();

        this.props.history.listen((location, action) => {
            // hide menus in mobile
            document.body.classList.remove('sidebar-enable');
            //this.props.changeActiveMenuFromLocation();
        });
    };

    componentDidUpdate = prevProps => {
        if (
            !prevProps.menu.menuItems ||
            (prevProps.menu.menuItems /*&& prevProps.menu.menuItems !== this.props.menu.menuItems*/)
        ) {
            if (this.isDifferentMenuItem(prevProps.menu.menuItems[0], this.props.menu.menuItems[0])) {
                this.initMenu();
            }
        }
    };

    componentWillUnmount() {
        this.menuRef.dispose()
    }

    isDifferentMenuItem = (item1, item2) => {
        if (item1 == null || item2 == null) {
            return true;
        }
        if ((item1.id != item2.id) || (item1.name != item2.name)
            || (item1.icon != item2.icon) || (item1.localIcon != item2.localIcon)
            || (item1.badge != item2.badge)) {
            return true;
        }

        let children1 = item1.children ?? [];
        let children2 = item2.children ?? [];

        if (children1.length != children2.length) {
            return true;
        }

        for (let i = 0; i < children1.length; i++) {
            let childDiff = this.isDifferentMenuItem(children1[i], children2[i]);
            if (childDiff) {
                return true;
            }
        }

        return false;
    }

    initMenu() {
        if (this.props.mode === 'horizontal') {
            //const menuRef = this.menuRef = new MetisMenu('#menu-bar')

            if (!this.menuRef) {
                this.menuRef = new MetisMenu('#menu-bar');
            } else {
                this.menuRef.dispose();
                this.menuRef = new MetisMenu('#menu-bar');
                this.menuRef.update();
            }
            const menuRef = this.menuRef;
            this.menuRef.on('shown.metisMenu', function (event) {
                window.addEventListener('click', function menuClick(e) {
                    if (!event.target.contains(e.target)) {
                        menuRef.hide(event.detail.shownElement);
                        window.removeEventListener('click', menuClick);
                    }
                });
            });
        } else {
            if (!this.menuRef) {
                this.menuRef = new MetisMenu('#menu-bar');
            } else {
                this.menuRef.dispose();
                this.menuRef = new MetisMenu('#menu-bar');
                this.menuRef.update();
            }
        }
    }

    localizationMenuName = (item) => {
        var children = [];
        item.children.forEach(element => {
            let newElement = Object.assign({}, element);
            newElement.name = this.props.t(element.name);
            if (newElement.children) {
                newElement = this.localizationMenuName(newElement);
            }
            children.push(newElement);
        });
        item.children = children;

        return item;
    }

    render() {
        const isHorizontal = this.props.mode === 'horizontal';
        const activatedKeys = this.props.menu && this.props.menu.activatedMenuItemIds ? this.props.menu.activatedMenuItemIds : [];
        const { t } = this.props;
        const isDark = isDarkMode();
        return (
            <React.Fragment>
                {this.props.menu && this.props.menu.menuItems && (
                    <ul className="metismenu" id="menu-bar">
                        {this.props.menu.menuItems.map((itemOriginal, i) => {
                            let item = Object.assign({}, itemOriginal);
                            const isSeparator = itemOriginal.name == 'separator';

                            if (!isSeparator) {
                                item.name = t(itemOriginal.name);
                                if (item.children) {
                                    item = this.localizationMenuName(item);
                                }
                            }

                            return (
                                <React.Fragment key={i}>
                                    {item.header && !isHorizontal && (
                                        <li className="menu-title" key={i + '-el'}>
                                            {item.header}
                                        </li>
                                    )}

                                    {isSeparator &&
                                        <div className='height-px-1 bg-color-separator'></div>
                                        // <hr />
                                    }

                                    {!isSeparator &&
                                        <React.Fragment>
                                            {item.children ? (
                                                <MenuItemWithChildren
                                                    item={item}
                                                    subMenuClassNames="nav-second-level"
                                                    activatedMenuItemIds={activatedKeys}
                                                    linkClassNames="side-nav-link"
                                                    push={this.props.history.push}
                                                />
                                            ) : (
                                                <MenuItem
                                                    item={item}
                                                    className={classNames({ 'mm-active': activatedKeys.indexOf(item.id) >= 0 })}
                                                    linkClassName="side-nav-link"
                                                />
                                            )}
                                        </React.Fragment>
                                    }
                                    {/* {item.children ? (
                                        <MenuItemWithChildren
                                            item={item}
                                            subMenuClassNames="nav-second-level"
                                            activatedMenuItemIds={activatedKeys}
                                            linkClassNames="side-nav-link"
                                            push={this.props.history.push}
                                        />
                                    ) : (
                                        <MenuItem
                                            item={item}
                                            className={classNames({ 'mm-active': activatedKeys.indexOf(item.id) >= 0 })}
                                            linkClassName="side-nav-link"
                                        />
                                    )} */}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        menu: state.AppMenu,
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        { initMenu, changeActiveMenuFromLocation, selectSearchCategory }
    )(withTranslation('translations')(AppMenu))
);
