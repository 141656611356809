// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    GET_COMPETENCY, GET_COMPETENCY_FAILED, GET_COMPETENCY_SUCCESS,
    GET_COMPETENCY_INFO, GET_COMPETENCY_INFO_FAILED, GET_COMPETENCY_INFO_SUCCESS,
    CREATE_COMPETENCY, CREATE_COMPETENCY_FAILED, CREATE_COMPETENCY_SUCCESS,
    EDIT_COMPETENCY, EDIT_COMPETENCY_SUCCESS, EDIT_COMPETENCY_FAILED,
    GET_COMPETENCY_HIERARCHY, GET_COMPETENCY_HIERARCHY_SUCCESS, GET_COMPETENCY_HIERARCHY_FAILED,
    GET_COMPETENCY_TYPE, GET_COMPETENCY_TYPE_SUCCESS, GET_COMPETENCY_TYPE_FAILED,
    DELETE_COMPETENCY_LEVEL, DELETE_COMPETENCY_LEVEL_SUCCESS, DELETE_COMPETENCY_LEVEL_FAILED,
    EDIT_COMPETENCY_LEVEL, EDIT_COMPETENCY_LEVEL_SUCCESS, EDIT_COMPETENCY_LEVEL_FAILED,
    CREATE_COMPETENCY_LEVEL, CREATE_COMPETENCY_LEVEL_SUCCESS, CREATE_COMPETENCY_LEVEL_FAILED,
    GET_COMPETENCY_BY_ID, GET_COMPETENCY_BY_ID_SUCCESS, GET_COMPETENCY_BY_ID_FAILED,
    GET_COMPETENCY_CONTEXT, GET_COMPETENCY_CONTEXT_SUCCESS, GET_COMPETENCY_CONTEXT_FAILED

} from './constants';

const INIT_STATE = {
    competencies: [],
    competenciesOfType: [],
    competency: null,
    competencyInfo: null,
    competencyHierarchy: null,
    competencyContext: null,
    loading: false,
    error: null
};

const Competency = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPETENCY:
            return { ...state, loading: true };
        case GET_COMPETENCY_SUCCESS:
            return { ...state, competencies: action.payload, loading: false, error: null };
        case GET_COMPETENCY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPETENCY_BY_ID:
            return { ...state, loading: true };
        case GET_COMPETENCY_BY_ID_SUCCESS:
            return { ...state, competency: action.payload, loading: false, error: null };
        case GET_COMPETENCY_BY_ID_FAILED:
            return { ...state, competency: null, error: action.payload, loading: false };

        case GET_COMPETENCY_TYPE:
            return { ...state, loading: true };
        case GET_COMPETENCY_TYPE_SUCCESS:
            return { ...state, competenciesOfType: action.payload, loading: false, error: null };
        case GET_COMPETENCY_TYPE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPETENCY_INFO:
            return { ...state, loading: true };
        case GET_COMPETENCY_INFO_SUCCESS:
            return { ...state, competencyInfo: action.payload, loading: false, error: null };
        case GET_COMPETENCY_INFO_FAILED:
            return { ...state, competencyInfo: null, error: action.payload, loading: false };

        case CREATE_COMPETENCY:
            return { ...state, loading: true };
        case CREATE_COMPETENCY_SUCCESS:
            {
                let current = state.competencies;
                let editedCompetency = action.payload;
                if (current && editedCompetency?.id) {
                    let index = current.findIndex((element) => { return element.id == editedCompetency.id });
                    if (index >= 0) {
                        current[index] = editedCompetency;
                    }
                }
                return { ...state, competencies: current, loading: false, error: null };
            }
        case CREATE_COMPETENCY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_COMPETENCY:
            return { ...state, loading: true };
        case EDIT_COMPETENCY_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_COMPETENCY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_COMPETENCY_LEVEL:
            return { ...state, loading: true };
        case CREATE_COMPETENCY_LEVEL_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_COMPETENCY_LEVEL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_COMPETENCY_LEVEL:
            return { ...state, loading: true };
        case EDIT_COMPETENCY_LEVEL_SUCCESS:
            return { ...state, loading: false, error: null };
        case EDIT_COMPETENCY_LEVEL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPETENCY_HIERARCHY:
            return { ...state, competencyHierarchy: null, loading: true };
        case GET_COMPETENCY_HIERARCHY_SUCCESS:
            return { ...state, competencyHierarchy: action.payload, loading: false, error: null };
        case GET_COMPETENCY_HIERARCHY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_COMPETENCY_LEVEL:
            return { ...state, loading: true };
        case DELETE_COMPETENCY_LEVEL_SUCCESS:
            let modifiedCompetencies = state.competencies;
            return { ...state, competencies: modifiedCompetencies, loading: false, error: null };
        case DELETE_COMPETENCY_LEVEL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_COMPETENCY_CONTEXT:
            return { ...state, loading: true };
        case GET_COMPETENCY_CONTEXT_SUCCESS:
            return { ...state, competencyContext: action.payload?.competencyContext, loading: false, error: null };
        case GET_COMPETENCY_CONTEXT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;


        default:
            return { ...state };
    }
};

export default Competency;
