// @flow
import {
    GET_COMPETENCY,GET_COMPETENCY_FAILED,GET_COMPETENCY_SUCCESS,
    GET_COMPETENCY_INFO,GET_COMPETENCY_INFO_FAILED,GET_COMPETENCY_INFO_SUCCESS,
    CREATE_COMPETENCY, CREATE_COMPETENCY_FAILED, CREATE_COMPETENCY_SUCCESS,
    GET_COMPETENCY_HIERARCHY, GET_COMPETENCY_HIERARCHY_SUCCESS, GET_COMPETENCY_HIERARCHY_FAILED,
    GET_COMPETENCY_TYPE, GET_COMPETENCY_TYPE_SUCCESS, GET_COMPETENCY_TYPE_FAILED,
    DELETE_COMPETENCY_LEVEL, DELETE_COMPETENCY_LEVEL_SUCCESS, DELETE_COMPETENCY_LEVEL_FAILED,
    EDIT_COMPETENCY, EDIT_COMPETENCY_SUCCESS, EDIT_COMPETENCY_FAILED,
    EDIT_COMPETENCY_LEVEL, EDIT_COMPETENCY_LEVEL_SUCCESS, EDIT_COMPETENCY_LEVEL_FAILED,
    CREATE_COMPETENCY_LEVEL, CREATE_COMPETENCY_LEVEL_SUCCESS, CREATE_COMPETENCY_LEVEL_FAILED,
    GET_COMPETENCY_BY_ID, GET_COMPETENCY_BY_ID_SUCCESS, GET_COMPETENCY_BY_ID_FAILED,
    GET_COMPETENCY_CONTEXT, GET_COMPETENCY_CONTEXT_SUCCESS, GET_COMPETENCY_CONTEXT_FAILED
} from './constants';

export const getCompetencies = (callbacks) => ({
    type: GET_COMPETENCY,
    payload: { callbacks },
});

export const getCompetenciesSuccess = (data) => ({
    type: GET_COMPETENCY_SUCCESS,
    payload: data,
});

export const getCompetenciesFailed = (error) => ({
    type: GET_COMPETENCY_FAILED,
    payload: error,
});

export const getCompetencyById = (competencyId, callbacks) => ({
    type: GET_COMPETENCY_BY_ID,
    payload: { competencyId, callbacks },
})

export const getCompetencyByIdSuccess = (data) => ({
    type: GET_COMPETENCY_BY_ID_SUCCESS,
    payload: data,
})

export const getCompetencyByIdFailed = (error) => ({
    type: GET_COMPETENCY_BY_ID_FAILED,
    payload: error,
})

export const getCompetenciesOfType = ( type, callbacks ) => ({
    type: GET_COMPETENCY_TYPE,
    payload: { type, callbacks },
});

export const getCompetenciesOfTypeSuccess = (data) => ({
    type: GET_COMPETENCY_TYPE_SUCCESS,
    payload: data,
});

export const getCompetenciesOfTypeFailed = (error) => ({
    type: GET_COMPETENCY_TYPE_FAILED,
    payload: error,
});

export const getCompetencyInfo = (callbacks) => ({
    type: GET_COMPETENCY_INFO,
    payload: { callbacks },
});

export const getCompetencyInfoSuccess = (data) => ({
    type: GET_COMPETENCY_INFO_SUCCESS,
    payload: data,
});

export const getCompetencyInfoFailed = (error) => ({
    type: GET_COMPETENCY_INFO_FAILED,
    payload: error,
});

export const createCompetency = (competency, callbacks) => ({
    type: CREATE_COMPETENCY,
    payload: { competency, callbacks },
});

export const createCompetencySuccess = (data) => ({
    type: CREATE_COMPETENCY_SUCCESS,
    payload: data,
});

export const createCompetencyFailed = (error) => ({
    type: CREATE_COMPETENCY_FAILED,
    payload: error,
});

export const createCompetencyLevel = (competency, callbacks) => ({
    type: CREATE_COMPETENCY_LEVEL,
    payload: { competency, callbacks },
});

export const createCompetencyLevelSuccess = (data) => ({
    type: CREATE_COMPETENCY_LEVEL_SUCCESS,
    payload: data,
});

export const createCompetencyLevelFailed = (error) => ({
    type: CREATE_COMPETENCY_LEVEL_FAILED,
    payload: error,
});

export const editCompetency = (id, params, callbacks) => ({
    type: EDIT_COMPETENCY,
    payload: { id, params, callbacks },
});

export const editCompetencySuccess = (response) => ({
    type: EDIT_COMPETENCY_SUCCESS,
    payload: response,
});

export const editCompetencyFailed = (error) => ({
    type: EDIT_COMPETENCY_FAILED,
    payload: error,
});

export const editCompetencyLevel = (competency, callbacks) => ({
    type: EDIT_COMPETENCY_LEVEL,
    payload: { competency, callbacks },
});

export const editCompetencyLevelSuccess = (data) => ({
    type: EDIT_COMPETENCY_LEVEL_SUCCESS,
    payload: data,
});

export const editCompetencyLevelFailed = (error) => ({
    type: EDIT_COMPETENCY_LEVEL_FAILED,
    payload: error,
});

export const getCompetencyHierarchy = (id, competencyId, callbacks) => ({
    type: GET_COMPETENCY_HIERARCHY,
    payload: { id, competencyId, callbacks },
});

export const getCompetencyHierarchySuccess = (data) => ({
    type: GET_COMPETENCY_HIERARCHY_SUCCESS,
    payload: data,
});

export const getCompetencyHierarchyFailed = (error) => ({
    type: GET_COMPETENCY_HIERARCHY_FAILED,
    payload: error,
});


export const deleteCompetencyLevel = (id, callbacks) => ({
    type: DELETE_COMPETENCY_LEVEL,
    payload: { id, callbacks },
});

export const deleteCompetencyLevelSuccess = (data) => ({
    type: DELETE_COMPETENCY_LEVEL_SUCCESS,
    payload: data,
});

export const deleteCompetencyLevelFailed = (error) => ({
    type: DELETE_COMPETENCY_LEVEL_FAILED,
    payload: error,
});

export const getCompetencyContext = (callbacks) => ({
    type: GET_COMPETENCY_CONTEXT,
    payload: { callbacks },
});

export const getCompetencyContextSuccess = (data) => ({
    type: GET_COMPETENCY_CONTEXT_SUCCESS,
    payload: data,
});

export const getCompetencyContextFailed = (error) => ({
    type: GET_COMPETENCY_CONTEXT_FAILED,
    payload: error,
});