// @flow
import classnames from 'classnames';
import React, { Component } from 'react';
import {
    CheckCircle,
    Edit,
    Share2, Trash, XCircle,
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import { childrenOfObject, colorForValidationStatuses, customAlertPopup, getLocalizedString, objectsFromGroup, uniqueArray, uniqueSimpleArray } from '../../../../../helpers/utils';
import {
    changeMenuType,
    setTopBarTitle,
    changeLayout,
    changeTheme,
    getNetworkAcademicExperiences,
    getAllNetworkCompanies,
    getRelationAction,
    editExperience
} from '../../../../../redux/actions';
// Include its styles in you build process as well
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { getTheme, getSidebarWidth, getNetworkCompany, getLoggedInUserProfile, getLoggedInUser } from '../../../../../helpers/authUtils';
import * as layoutConstants from '../../../../../constants/layout';
import SVG from 'react-inlinesvg';
import { MENU_TYPE_HOME } from '../../../../../redux/appMenu/constants';
import { format } from '../../../../../helpers/stringFormat';
import { ValidationCount } from '../../../Settings/Profile/Academics/PersonalAcademics';
import { VALIDATION_STATUS_REJECTED, VALIDATION_STATUS_SUBMITTED, VALIDATION_STATUS_VALIDATED } from '../../../../../redux/validation/constants';
import moment from 'moment';
import ObjectAdjustmentModal from '../../../Supports/ObjectAdjustmentModal';
import AddExperienceModal, { EXPERIENCE_TYPE_ACADEMIC } from '../../../Settings/Background/Experience/AddExperienceModal';
//import AddExperienceModal from './AddExperienceModal';

class NetworkAcademicsExperiences extends Component {
    _isMounted = false;
    _numPages = 0;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            activeTabInfo: 1,
            companyNodeId: -1,
            experiences: null,
            editingExperience: null,
            showAddExperienceModal: false,
            showDetails: false,
            showObjectAdjustmentModal: false,
            objectType: null,
            object: null,
            allObjects: [],
            selectedObjects: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
        this.props.setTopBarTitle('topBar.home');
        this.props.changeMenuType(MENU_TYPE_HOME, {});
        //this.props.changeLayout(getLayout());

        if (document.body) {
            if (getSidebarWidth()) {
                if (document.body.classList.contains('sidebar-enable'))
                    document.body.classList.remove('sidebar-enable');
                this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
            } else {
                if (document.body.classList.contains('left-side-menu-condensed'))
                    document.body.classList.remove('left-side-menu-condensed');
                document.body.classList.add('sidebar-enable');
            }
        }

        this.props.changeTheme(getTheme());

        // if (this.props.allNetworkCompanies == null) {
        //     this.props.getAllNetworkCompanies({});
        // }

        if (this.props.relationAction == null) {
            this.props.getRelationAction({});
        }

        this.getExperiences();
    }

    componentDidUpdate = prevProps => {
        if (prevProps.academyId !== this.props.academyId || prevProps.allNetworkAcademics !== this.props.allNetworkAcademics) {
            this.getExperiences();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onBack = () => {
        this.props.history.push({ pathname: '/network-person' });
    }

    getExperiences = () => {
        if (this.props.academyId != null && this.props.allNetworkAcademics != null) {
            let nodeId = -1;
            let academics = this.props.allNetworkAcademics ?? [];
            for (let i = 0; i < academics.length; i++) {
                let c = academics[i];
                if (c.id == this.props.academyId) {
                    nodeId = c.nodeId;
                    break;
                }
            }

            this.setState({ companyNodeId: nodeId }, () => {
                this.props.getNetworkAcademicExperiences(nodeId, {
                    callbackOnBegin: () => {
                        // Update loading state?
                        // console.log('callbackOnBegin');
                    },
                    callbackOnFailure: (error) => {
                        // show error to your users or stay quiet
                        this.setState({ loading: false, error: error });
                    },
                    callbackOnFinish: () => {
                        // Update loading state?
                        this.setState({ loading: false });
                    },
                    callbackOnSuccess: (response) => {
                    },
                });
            })
        }
    };

    closeAddExperienceModal = () => {
        this.setState({
            showAddExperienceModal: false,
            editingExperience: null
        })
    }

    objectsFromGroup = (group, key) => {
        if (group == null) {
            return [];
        }

        let object = [];
        if (group[key]?.length > 0) {
            object = group[key];
        }

        if (group.children?.length > 0) {
            for (let i = 0; i < group.children.length; i++) {
                let grandChildren = this.objectsFromGroup(group.children[i], key);
                object = object.concat(grandChildren);
            }
        }
        return object;
    }

    goalsFromActionGroup = (group) => {
        if (group == null) {
            return [];
        }

        let goals = [];
        if (group.actions?.length > 0) {
            for (let i = 0; i < group.actions.length; i++) {
                goals = goals.concat(group.actions[i].goals ?? [])
            }
        }

        if (group.children?.length > 0) {
            for (let j = 0; j < group.children.length; j++) {
                goals = goals.concat(this.goalsFromActionGroup(group.children[j]))
            }
        }

        return goals;
    }

    filteredGoals = (selectedEType, companyId) => {
        if (companyId == null || selectedEType == null) {
            return [];
        }

        let actionGroup = selectedEType;
        let goals = this.goalsFromActionGroup(actionGroup);
        goals = goals.filter((element) => { return element.goal?.id == companyId })
        return goals.map((element) => { return { value: element.id, label: element.name, entity: element } });
    }

    experienceSummary = (selectedEType, experience) => {
        const { t, menuHierarchy } = this.props;

        let title = '';

        let goals = this.filteredGoals(selectedEType, this.state.companyNodeId);
        let selectedGoal = null;
        if (experience.goalNodeId) {
            selectedGoal = goals.find((element) => { return element.value == experience.goalNodeId });
            if (selectedGoal?.label?.length > 0) {
                title = selectedGoal?.label;
            }
        }
        else if (experience?.submitGoalName?.length > 0) {
            title += experience?.submitGoalName;
        }

        let integrationName = experience.submitIntegrationName ?? '';
        if (integrationName.length > 0) {
            title += (title.length > 0 ? ' | ' : '') + integrationName;
        }

        let allDepartments = childrenOfObject(menuHierarchy?.departmentGroup)?.map((element) => { element.label = element.name; return element });
        let departments = '';
        (experience?.departments ?? []).forEach(element => {
            let department = allDepartments.find((d) => { return d.id == element.departmentGroupId });
            if (department?.label?.length > 0) {
                departments += (departments.length > 0 ? ', ' : '') + department?.label;
            }
        });
        if (departments.length > 0) {
            departments = format(t('experience.deployTo0'), departments);
            title += (title.length > 0 ? ' | ' : '') + departments;
        }

        return title
    }

    experienceCategories = (experiences) => {
        if (experiences.length == 0 || this.props.menuHierarchy?.allActionGroup == null) {
            return [];
        }

        let experienceTypes = this.props.menuHierarchy?.allActionGroup?.children ?? [];
        let categories = experienceTypes.map((type) => { return { 'type': type, experiences: [] } });
        categories.push({ 'type': { id: -1, 'name': 'Other' }, experiences: [] });

        for (let i = 0; i < experiences.length; i++) {
            let index = categories.findIndex((element) => { return element.type.id == experiences[i].type })
            if (index >= 0) {
                categories[index].experiences.push(experiences[i]);
            }
            else {
                categories[categories.length - 1].experiences.push(experiences[i]);
            }
        }

        categories = categories.filter((element) => { return element.experiences.length > 0 })
        categories = categories.map((element) => { return { type: element.type, experiences: element.experiences.sort((a, b) => moment(a.dateCreation, 'MM/YYYY').valueOf() > moment(b.dateCreation, 'MM/YYYY').valueOf() ? 1 : -1) } })
        return categories;
    }

    generalModule = () => {
        return (
            <Row>
                <Col>
                    <Label className='ml-4'>{'No information'}</Label>
                </Col>
            </Row>
        )
    }

    experienceModule = (experiences) => {
        if (experiences == null || experiences.length == 0) {
            return this.generalModule();
        }

        let { t, menuHierarchy } = this.props;

        let categories = this.experienceCategories(experiences ?? []);

        return (
            <div>
                <div className='box-side'>
                    <div></div>
                    <div className="custom-control custom-switch cursor-pointer" onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}>
                        <input
                            type="radio"
                            className="custom-control-input cursor-pointer"
                            checked={this.state.showDetails}
                            onChange={() => {}}
                        />
                        <label className="custom-control-label cursor-pointer" htmlFor="left-sidebar-width-scrollable">
                            {t('general.details')}
                        </label>
                    </div>
                </div>

                {categories.map((category, index) => {
                    return (
                        <div key={index} className={index > 0 ? 'mt-3' : ''}>
                            <Row className="path-component">
                                <Label className="link selectable font-weight-bold company-name ml-4 mt-0" onClick={() => { this.selectCategory(category) }}>
                                    {category.type?.name ?? ''}
                                </Label>
                            </Row>

                            <Table className="mb-0" borderless>
                                <tbody>
                                    {category.experiences.map((experience, idx) => {
                                        let summary = this.experienceSummary(category.type, experience)
                                        let validations = experience?.validationsStatus ?? [];
                                        let submitted = validations.filter((element) => { return element.status == 'SUBMITTED' });
                                        let validated = validations.filter((element) => { return element.status == 'VALIDATED' });
                                        let rejected = validations.filter((element) => { return element.status == 'REJECTED' });
                                        let color = colorForValidationStatuses(validations, validated, rejected);

                                        let contributors = [];
                                        (experience.contributions ?? []).forEach(element => {
                                            contributors.push(element);
                                        });

                                        let allTechnologies = this.allTechnologies();
                                        let technologies = [];
                                        (experience.technologies ?? []).forEach(t => {
                                            let technology = allTechnologies.find((element) => { return element.id == t.techId });
                                            if (technology?.label?.length > 0) {
                                                technologies.push([technology?.label, t.description ?? '']);
                                            }
                                        });

                                        let allSources = this.allSources();
                                        let sources = [];
                                        (experience.sources ?? []).forEach(s => {
                                            let source = allSources.find((element) => { return element.id == s.sourceGroupId });
                                            if (source?.label?.length > 0) {
                                                sources.push([source?.label, s.description ?? '']);
                                            }
                                        });

                                        let allResults = this.allResults();
                                        let results = [];
                                        (experience.results ?? []).forEach(r => {
                                            let result = allResults.find((element) => { return element.id == r.buildGroupId });
                                            if (result?.label?.length > 0) {
                                                results.push([result?.label, r.description ?? '']);
                                            }
                                        });

                                        let networkPeople = this.networkPeople(experience);
                                        let rights = this.props.relationAction?.relationAction?.rights?.map((element) => { return { value: element.id, label: getLocalizedString(element.name), entity: element } }) ?? [];
                                        let allContributions = this.allContributions();

                                        let contributions = contributors.map((element, index) => {
                                            let person = networkPeople?.find((e) => { return e.value == element.contributorId });
                                            let userRight = rights?.find((e) => { return e.value == element.userRight });

                                            let mergedContributions = this.mergeContributions(element.contributions ?? []);
                                            let roleStrings = [];
                                            mergedContributions.forEach((c, i) => {
                                                let contr = allContributions.find((conObj) => { return conObj.id == c.id });
                                                if (contr != null) {
                                                    let contributions_roles = contr?.roles?.map((role) => { return { value: role.id, label: getLocalizedString(role.name), entity: role } }) ?? [];
                                                    (c.roles ?? []).forEach((r) => {
                                                        let role = contributions_roles.find((rObj) => { return rObj.value == r.id });
                                                        let contributionsString = '';
                                                        if (role != null) {
                                                            contributionsString += (contributionsString.length == 0 ? '' : ', ') + role.label;
                                                        }

                                                        if (r.list?.length > 0) {
                                                            let optionalList = [];
                                                            if (c.id == 'CONTRIBUTOR') {
                                                                optionalList = role.value == 1 ? allTechnologies : allResults;
                                                            }
                                                            else {
                                                                optionalList = role.value == 1 ? allTechnologies : networkPeople;
                                                            }

                                                            let listString = '';
                                                            r.list.forEach((item) => {
                                                                let object = optionalList.find((obj) => {
                                                                    return (obj.id ?? obj.value) == item.id
                                                                });

                                                                if (object?.label?.length > 0) {
                                                                    listString += (listString.length == 0 ? '' : ', ') + object.label;
                                                                }
                                                            });

                                                            if (listString.length > 0) {
                                                                contributionsString += ' (' + listString + ')';
                                                            }
                                                        }

                                                        if (contributionsString.length > 0) {
                                                            roleStrings.push(contributionsString);
                                                        }
                                                    });
                                                }
                                            });

                                            return [person?.label ?? '', userRight?.label ?? ''];
                                        });

                                        let details = {};
                                        details[t('general.contributors')] = contributions;
                                        details[t('techGroup.technologies')] = technologies;
                                        details[t('experience.Sources')] = sources;
                                        details[t('general.results')] = results;

                                        const hasDetails = contributors.length > 0 || technologies.length > 0 || sources.length > 0 || results.length > 0;

                                        return (
                                            <tr key={summary}>
                                                <td >
                                                    <div className='align-horizontal path-component'>
                                                        <CheckCircle color={color} size={18} />
                                                        <Label className="link-horver ml-2 mb-0 mt-0" onClick={() => {
                                                            this.props.viewExperienceDetails(experience);
                                                        }}>{summary}</Label>

                                                        {validations.length > 0 &&
                                                            <div className="ml-2 align-horizontal box-center align-items-flex-start">
                                                                <Label className="mr-2 mb-0 mt-0">|</Label>

                                                                {validated.length > 0 &&
                                                                    <ValidationCount t={this.props.t} id={"validation-validated" + index + idx} className='mr-2' status={VALIDATION_STATUS_VALIDATED} count={validated.length} />
                                                                }

                                                                {submitted.length > 0 &&
                                                                    <ValidationCount t={this.props.t} id={"validation-submited" + index + idx} className='mr-2' status={VALIDATION_STATUS_SUBMITTED} count={submitted.length} />
                                                                }

                                                                {rejected.length > 0 &&
                                                                    <ValidationCount t={this.props.t} id={"validation-rejected" + index + idx} className='mr-2' status={VALIDATION_STATUS_REJECTED} count={rejected.length} />
                                                                }

                                                                {/* <div className='align-horizontal'>
                                                                {(validations.length > 0 || validated.length > 0 || rejected.length > 0) &&
                                                                    <Label className="mr-1">|</Label>
                                                                }
                                                                <div className='box-center align-horizontal' id={"request-validation" + index + idx}>
                                                                    <SVG src={ValidationRequest} className="ml-1 size-px-20 cursor-pointer" onClick={(e) => {
                                                                        this.setState({ targetCategory: type, targetElement: baseTrainingsInfo, showRequestValidationDialog: true });
                                                                    }} />
                                                                    <UncontrolledTooltip placement="bottom" target={"request-validation" + index + idx}>
                                                                        {t('validation.requestValidation')}
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div>

                                                            <span className="ml-2" onClick={() => {
                                                                this.setState({
                                                                    statusAction: ACTION_EDIT_TRAINING,
                                                                    isAddingNewTraining: true,
                                                                    competencyType: type,
                                                                    competencyInfo: baseTrainingsInfo,
                                                                }, () => {
                                                                });
                                                            }}>
                                                                <FeatherIcon.Edit className="icon-dual ml-2 cursor-pointer icon-action" id={"edit-competency-level" + index2} />
                                                                <UncontrolledTooltip placement="bottom" target={"edit-competency-level" + index2}>
                                                                    {t('me.hover.editCompetencyLevel')}
                                                                </UncontrolledTooltip>
                                                            </span>

                                                            <span className="ml-2" onClick={() => {
                                                                customAlertPopup({
                                                                    title: format(t('alert.deleteTitle'), t('me.trainings')),
                                                                    message: format(t('alert.deleteMessage'), t('me.trainings')),
                                                                    cancelTitle: t('No'),
                                                                    doneTitle: t('Yes'),
                                                                    handleCancel: () => { },
                                                                    handleDone: () => {
                                                                        this.deleteCompetencyLevel(type, baseTrainingsInfo.id)
                                                                    }
                                                                });
                                                            }}>
                                                                <Trash className="icon-dual ml-2 cursor-pointer icon-action" id={"delete-competency-level" + index2} />
                                                                <UncontrolledTooltip placement="bottom" target={"delete-competency-level" + index2}>
                                                                    {t('me.hover.deleteCompetencyLevelLevel')}
                                                                </UncontrolledTooltip>
                                                            </span> */}
                                                            </div>
                                                        }
                                                        {/* <div className="ml-4 align-horizontal box-center">
                                                {validations.length > 0 &&
                                                    <div className='box-center align-horizontal'>
                                                        <Label>{validations.length ?? 0}</Label>
                                                        <SVG src={Requested} className="ml-1 size-px-20" id="requested" />
                                                    </div>
                                                }
                                                {validated.length > 0 &&
                                                    <div className="align-horizontal box-center">
                                                        {(validations.length > 0) &&
                                                            <Label className="ml-2 mr-2">|</Label>
                                                        }
                                                        <div className='box-center align-horizontal'>
                                                            <Label>{validated.length ?? 0}</Label>
                                                            <CheckCircle className="ml-1" color="green" size={16} id="accepted" />
                                                        </div>
                                                    </div>
                                                }
                                                {rejected.length > 0 &&
                                                    <div className="align-horizontal box-center">
                                                        {(validations.length > 0 || validated.length > 0) &&
                                                            <Label className="ml-2 mr-2">|</Label>
                                                        }
                                                        <div className='box-center align-horizontal'>
                                                            <Label>{rejected.length ?? 0}</Label>
                                                            <XCircle className="ml-1" color="red" size={16} id="rejected" />
                                                        </div>
                                                    </div>
                                                }
                                                {(validations.length > 0 || validated.length > 0 || rejected.length > 0) &&
                                                    <Label className="ml-2 mr-2">|</Label>
                                                }
                                                <div className='box-center align-horizontal'>
                                                    <SVG src={ValidationRequest} className="ml-1 size-px-20 cursor-pointer" id="request" onClick={(e) => {
                                                        this.setState({ targetCategory: category, targetElement: experience, showRequestValidationDialog: true });
                                                    }} />
                                                </div>

                                                <span className="ml-3" onClick={() => {
                                                    this.setState({ editingExperience: experience, openAddDataExperienceDialog: true })
                                                }}>
                                                    <Edit className="icon-size-20 cursor-pointer" id={hoverEdit} />
                                                    <UncontrolledTooltip placement="bottom" target={hoverEdit}>
                                                        {t('experience.hover.editExperience')}
                                                    </UncontrolledTooltip>
                                                </span>
                                                <span className='float-right size-px-20 box-center ml-2'>
                                                    <Trash className="icon-dual size-px-20 cursor-pointer" id={hoverRemove}
                                                        onClick={() => {
                                                            this.removeExperience(experience)
                                                        }} />
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        id='tooltip-1'
                                                        target={hoverRemove}>
                                                        {t("experience.hover.removeExperience")}
                                                    </UncontrolledTooltip>
                                                </span>
                                                        </div> */}
                                                    </div>
                                                    {this.state.showDetails && hasDetails &&
                                                        <div className='align-vertical border-1 rounded-5 mt-2'>
                                                            {this.detailsTable(experience, details, index, idx)}
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    );
                })}
            </div>
        )
    }

    detailsTable = (experience, summary, categoryId, experienceId) => {
        let t = this.props.t;
        return (
            <div>
                <Table className="mb-0 ml-2 mr-2 mt-2 mb-2" responsive="sm">
                    <tbody>
                        {Object.keys(summary).length > 0 && Object.keys(summary).map((key, index) => {
                            return (
                                <tr key={key}>
                                    <th style={{ 'width': '30%' }} scope="row">
                                        <div className='align-horizontal'>
                                            {key}
                                            <div onClick={() => {
                                                this.adjustObject(experience, key);
                                            }}>
                                                <Edit className="icon-size-20 cursor-pointer ml-3" color='gray' id={'edit' + key + categoryId + experienceId} style={{ 'transform': 'translate(0px, -3px)' }} />
                                                <UncontrolledTooltip placement="bottom" target={'edit' + key + categoryId + experienceId}>
                                                    {format(t('general.modify0'), key.toLowerCase())}
                                                </UncontrolledTooltip>
                                            </div>
                                        </div></th>

                                    {(typeof summary[key] === 'string') ?
                                        <td style={{ 'width': '60%' }}>{summary[key]}</td>
                                        :
                                        <td style={{ 'width': '60%' }}>{
                                            <div className='align-vertical width-percent-100'>
                                                {summary[key].length == 0 &&
                                                    <Label>{t('message.noInformation')}</Label>
                                                }

                                                {summary[key].length > 0 && summary[key].map((element, index) => {
                                                    return (
                                                        <Row className='width-percent-100' md={12} xl={12} key={index}>
                                                            {(typeof element === 'string') ?
                                                                <Col className={index > 0 ? 'mt-2' : ''}>
                                                                    <span>{element}</span>
                                                                </Col>
                                                                :
                                                                <div className={'align-horizontal width-percent-100' + (index > 0 ? ' mt-2' : '')} md={12} xl={12}>
                                                                    <Col md={6} xl={6}>
                                                                        <span>{element[0]}</span>
                                                                    </Col>
                                                                    <Col md={6} xl={6}>
                                                                        <span>{element[1]}</span>
                                                                    </Col>
                                                                </div>
                                                            }
                                                        </Row>
                                                    )
                                                })}
                                            </div>
                                        }</td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    networkPeople = (experience) => {
        let people = [];
        if (this.props.allNetworkPeople) {
            people = this.props.allNetworkPeople?.map((element) => {
                let name = element.firstName ?? '';
                if (element.lastName?.length > 0) {
                    name += (name.length > 0 ? ' ' : '') + element.lastName;
                }
                return { value: element.nodeId, label: name, entity: element };
            }) ?? [];

            people = people.sort((a, b) => {
                return b.label > a.label ? -1 : 1;
            })
        }
        if (experience?.contributions?.length > 0 && this.props.subscriptionProfile?.people != null) {
            experience.contributions.forEach((contribution) => {
                if (!people.some((p) => { return p.value == contribution.contributorId }) && this.props.subscriptionProfile.people[contribution.contributorId] != null) {
                    people.unshift({ value: contribution.contributorId, label: this.props.subscriptionProfile.people[contribution.contributorId], entity: { id: '' } });
                }
                let saleContribution = contribution.contributions?.find((c) => { return c.id == 'SALES' });
                let competencySale = saleContribution?.roles?.find((r) => { return r.id == 2 });
                if (competencySale?.list?.length > 0) {
                    competencySale.list.forEach((item) => {
                        if (!people.some((p) => { return p.value == item.id }) && this.props.subscriptionProfile.people[item.id] != null) {
                            people.unshift({ value: item.id, label: this.props.subscriptionProfile.people[item.id], entity: { id: '' } });
                        }
                    });
                }
            });
        }

        let myIndex = people.findIndex((element) => { return element.value == getLoggedInUserProfile().user.nodeId });
        if (people.length > 1 && myIndex >= 0) {
            let me = people[myIndex];
            me.label = '- ' + this.props.t('profile.me') + ' -';
            people.splice(myIndex, 1);
            people.unshift(me);
            people = people.sort((a, b) => {
                return b.id == getLoggedInUser().id ? -1 : 1;
            })
        }

        return people;
    }

    mergeContributions = (contributions) => {
        let mergedContributions = [];

        let contributionIds = contributions.map((c) => { return c.id });
        let uniqueContributionIds = uniqueSimpleArray(contributionIds);
        uniqueContributionIds.forEach((id) => {
            let contributionsById = contributions.filter((c) => { return c.id == id });
            let contribution = { id: id, roles: [] };
            contributionsById.forEach((c) => {
                if (c.roles?.length > 0) {
                    c.roles.forEach((role) => {
                        let roleIndex = contribution.roles.findIndex((r) => { return r.id == role.id });
                        if (roleIndex == -1) {
                            contribution.roles.push(role);
                        }
                        else {
                            contribution.roles[roleIndex].list = [...contribution.roles[roleIndex].list, ...role.list];
                            contribution.roles[roleIndex].list = uniqueArray(contribution.roles[roleIndex].list, 'id');
                        }
                    });
                }
            });

            mergedContributions.push(contribution);
        });

        return mergedContributions;
    }

    allContributions = () => {
        return this.props.relationAction?.relationAction?.relations?.map((element) => { element.label = element.name ? getLocalizedString(element.name) : ''; return element }) ?? [];
    }

    allTechnologies = () => {
        return objectsFromGroup(this.props.menuHierarchy?.techGroup, 'technologies').map((element) => { element.label = element.name; return element });
    }

    allSources = () => {
        return childrenOfObject(this.props.menuHierarchy?.sourceGroup)?.map((element) => { element.label = element.name; return element });
    }

    allResults = () => {
        return childrenOfObject(this.props.menuHierarchy?.buildGroup)?.map((element) => { element.label = element.name; return element });
    }

    adjustObject = (experience, key) => {
        let { t } = this.props;

        let object = null;
        let selectedObjects = [];
        let allObjects = [];
        let description = true;
        switch (key) {
            case t('general.contributors'):
                this.setState({ editingExperience: experience, showAddExperienceModal: true });
                return;
            case t('techGroup.technologies'):
                object = t('experience.Technology');
                allObjects = this.allTechnologies();
                selectedObjects = (experience.technologies ?? []).map((element) => { return { id: element.techId, description: element.description } });
                break;
            case t('experience.Sources'):
                object = t('experience.Source');
                allObjects = this.allSources();
                selectedObjects = (experience.sources ?? []).map((element) => { return { id: element.sourceGroupId, description: element.description } });
                break;
            case t('general.results'):
                object = t('experience.Result');
                allObjects = this.allResults();
                selectedObjects = (experience.results ?? []).map((element) => { return { id: element.buildGroupId, description: element.description } });
                break;
            default: break;
        }

        let state = {};
        state.editingExperience = experience;
        state.objectType = key;
        state.object = object;
        state.allObjects = allObjects;
        state.selectedObjects = selectedObjects;
        state.description = description;
        state.showObjectAdjustmentModal = true;
        this.setState(state);
    }

    closeObjectAdjustmentModal = () => {
        let state = {};
        state.editingExperience = null;
        state.objectType = null;
        state.object = null;
        state.allObjects = [];
        state.selectedObjects = [];
        state.showObjectAdjustmentModal = false;
        this.setState(state);
    }

    finishObjectAdjustment = (selectedObjects) => {
        let { t } = this.props;

        let params = {};
        params.type = this.state.editingExperience.type;
        params.companyNodeId = this.state.editingExperience.companyNodeId;
        params.actionNodeId = this.state.editingExperience.actionNodeId;
        if (this.state.editingExperience.positionNodeId) {
            params.positionNodeId = this.state.editingExperience.positionNodeId;
        }
        if (this.state.editingExperience.thirdPartyCompanyNodeId) {
            params.thirdPartyCompanyNodeId = this.state.editingExperience.thirdPartyCompanyNodeId;
        }

        params.goalNodeId = this.state.selectedGoal?.value;

        if (this.state.editingExperience.submitIntegrationName?.length > 0) {
            params.submitIntegrationName = this.state.editingExperience.submitIntegrationName;
        }

        if (this.state.editingExperience.submitIntegrationDescription?.length > 0) {
            params.submitIntegrationDescription = this.state.editingExperience.submitIntegrationDescription;
        }

        if (this.state.editingExperience.sources?.length > 0) {
            params.sources = this.state.editingExperience.sources;
        }

        if (this.state.editingExperience.technologies?.length > 0) {
            params.technologies = this.state.editingExperience.technologies;
        }

        if (this.state.editingExperience.results?.length > 0) {
            params.results = this.state.editingExperience.results;
        }

        if (this.state.editingExperience.departments?.length > 0) {
            params.departments = this.state.editingExperience.departments;
        }

        if (this.state.editingExperience.contributions?.length > 0) {
            params.contributions = this.state.editingExperience.contributions;
        }

        switch (this.state.objectType) {
            case t('general.contributors'):
                params.contributions = selectedObjects.map((element, index) => { return element.id });
                break;
            case t('techGroup.technologies'):
                params.technologies = selectedObjects.map((element, index) => { return { order: index, techId: element.id, description: element.description } });
                break;
            case t('experience.Sources'):
                params.sources = selectedObjects.map((element, index) => { return { order: index, sourceGroupId: element.id, description: element.description } });
                break;
            case t('general.results'):
                params.results = selectedObjects.map((element, index) => { return { order: index, buildGroupId: element.id, description: element.description } });
                break;
            default: break;
        }

        if (this.state.editingExperience.id) {
            this.props.editExperience(this.state.editingExperience.id, params, {
                callbackOnBegin: () => {
                    this.setState({ loading: true });
                },
                callbackOnFailure: (error) => {
                    this.setState({ loading: false, error: error });
                },
                callbackOnFinish: () => {
                    this.setState({ loading: false });
                },
                callbackOnSuccess: (response) => {
                    this.closeObjectAdjustmentModal();
                },
            })
        }
    }

    render() {
        const { t, type, countries } = this.props;

        let module = this.props.academicExperiences ? this.experienceModule : this.generalModule;

        return (
            <React.Fragment>
                {this.state.showObjectAdjustmentModal &&
                    <ObjectAdjustmentModal
                        objectType={this.state.objectType}
                        object={this.state.object}
                        allObjects={this.state.allObjects}
                        selectedObjects={this.state.selectedObjects}
                        modal={this.state.showObjectAdjustmentModal}
                        close={this.closeObjectAdjustmentModal}
                        adjustObject={this.finishObjectAdjustment}
                        description={this.state.description}
                    />
                }

                {this.state.showAddExperienceModal &&
                    <AddExperienceModal
                        modal={this.state.showAddExperienceModal}
                        menuHierarchy={this.props.menuHierarchy}
                        countries={countries}
                        dataType={EXPERIENCE_TYPE_ACADEMIC}
                        careers={this.props.subscriptionProfile?.careers ?? []}
                        defaultCompanyId={this.props.academyId}
                        companyNodeId={this.state.companyNodeId}
                        editingExperience={this.state.editingExperience ? JSON.parse(JSON.stringify(this.state.editingExperience)) : null}
                        isEditingContributors={true}
                        onCancel={this.closeAddExperienceModal} />
                }

                {module(this.props.academicExperiences)}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { countries } = state.Auth;
    const { menuHierarchy, relationAction } = state.Profile;
    const { allNetworkAcademics, allNetworkPeople, academicExperiences, loading, error } = state.Network;

    return { countries, menuHierarchy, relationAction, allNetworkAcademics, allNetworkPeople, academicExperiences, loading, error };
};

const mapDispatchToProps = {
    changeMenuType,
    setTopBarTitle,
    changeTheme,
    changeLayout,
    getNetworkAcademicExperiences,
    getAllNetworkCompanies,
    getRelationAction,
    editExperience
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(NetworkAcademicsExperiences));
