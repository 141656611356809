/* AUTH */

export const VALIDATION_TYPE_IN = 1;
export const VALIDATION_TYPE_OUT = 0;

export const VALIDATION_DOCUMENT_EDUCATION = 0;
export const VALIDATION_DOCUMENT_TRAINING = 1;
export const VALIDATION_DOCUMENT_CAREER = 2;
export const VALIDATION_DOCUMENT_EVENTS = 3;
export const VALIDATION_DOCUMENT_EXPERIENCE = 4;
export const VALIDATION_DOCUMENT_COMPETENCY = 5;

export const VALIDATION_STATUS_SUBMITTED = 0;
export const VALIDATION_STATUS_VALIDATED = 1;
export const VALIDATION_STATUS_REJECTED = 2;

export const SEARCH_VERIFIER = 'SEARCH_VERIFIER';
export const SEARCH_VERIFIER_SUCCESS = 'SEARCH_VERIFIER_SUCCESS';
export const SEARCH_VERIFIER_FAILED = 'SEARCH_VERIFIER_FAILED';

export const GET_EXTERNAL_VALIDATION = 'GET_EXTERNAL_VALIDATION';
export const GET_EXTERNAL_VALIDATION_SUCCESS = 'GET_EXTERNAL_VALIDATION_SUCCESS';
export const GET_EXTERNAL_VALIDATION_FAILED = 'GET_EXTERNAL_VALIDATION_FAILED';
export const UPDATE_EXTERNAL_VALIDATION = 'UPDATE_EXTERNAL_VALIDATION';
export const UPDATE_EXTERNAL_VALIDATION_SUCCESS = 'UPDATE_EXTERNAL_VALIDATION_SUCCESS';
export const UPDATE_EXTERNAL_VALIDATION_FAILED = 'UPDATE_EXTERNAL_VALIDATION_FAILED';

export const UPDATE_VALIDATION = 'UPDATE_VALIDATION';
export const UPDATE_VALIDATION_SUCCESS = 'UPDATE_VALIDATION_SUCCESS';
export const UPDATE_VALIDATION_FAILED = 'UPDATE_VALIDATION_FAILED';

export const CREATE_VALIDATION = 'CREATE_VALIDATION';
export const CREATE_VALIDATION_SUCCESS = 'CREATE_VALIDATION_SUCCESS';
export const CREATE_VALIDATION_FAILED = 'CREATE_VALIDATION_FAILED';

export const EDIT_VALIDATION = 'EDIT_VALIDATION';
export const EDIT_VALIDATION_SUCCESS = 'EDIT_VALIDATION_SUCCESS';
export const EDIT_VALIDATION_FAILED = 'EDIT_VALIDATION_FAILED';

export const DELETE_VALIDATION = 'DELETE_VALIDATION';
export const DELETE_VALIDATION_SUCCESS = 'DELETE_VALIDATION_SUCCESS';
export const DELETE_VALIDATION_FAILED = 'DELETE_VALIDATION_FAILED';

export const GET_VALIDATIONS = 'GET_VALIDATIONS';
export const GET_VALIDATIONS_SUCCESS = 'GET_VALIDATIONS_SUCCESS';
export const GET_VALIDATIONS_FAILED = 'GET_VALIDATIONS_FAILED';

export const GET_VALIDATIONS_SUPERSET = 'GET_VALIDATIONS_SUPERSET';
export const GET_VALIDATIONS_SUPERSET_SUCCESS = 'GET_VALIDATIONS_SUPERSET_SUCCESS';
export const GET_VALIDATIONS_SUPERSET_FAILED = 'GET_VALIDATIONS_SUPERSET_FAILED';

export const GET_VALIDATION_GRAPH_DATA = 'GET_VALIDATION_GRAPH_DATA';
export const GET_VALIDATION_GRAPH_DATA_SUCCESS = 'GET_VALIDATION_GRAPH_DATA_SUCCESS';
export const GET_VALIDATION_GRAPH_DATA_FAILED = 'GET_VALIDATION_GRAPH_DATA_FAILED';
