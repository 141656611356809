// @flow
import {
    GET_REPOCYCLESTEPS,GET_REPOCYCLESTEPS_FAILED,GET_REPOCYCLESTEPS_SUCCESS
} from './constants';


const INIT_STATE = {
    repocyclesteps: [],
    loading: false,
    error: null
};

const RepoCyclesteps = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPOCYCLESTEPS:
            return { ...state, loading: true };
        case GET_REPOCYCLESTEPS_SUCCESS:
            return { ...state, repocyclesteps: action.payload.products, loading: false, error: null };
        case GET_REPOCYCLESTEPS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RepoCyclesteps;
