import { lang } from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Cookies } from 'react-cookie';
import { Button, Container, Label, Modal } from 'reactstrap';
import { getLoggedInUser, isDarkMode } from './authUtils';
import { format } from '../helpers/stringFormat';
import { findPhoneNumbersInText, parsePhoneNumber } from 'libphonenumber-js'
import { VALIDATION_STATUS_REJECTED, VALIDATION_STATUS_SUBMITTED, VALIDATION_STATUS_VALIDATED } from '../redux/validation/constants';
import { Star } from 'react-feather';
import { PRODUCT_STATUS_EXPIRED, PRODUCT_STATUS_LIVE, PRODUCT_STATUS_PAYMENT_FAILED, PRODUCT_STATUS_PAYMENT_STOPPED } from '../redux/profile/constants';
import { SUBSCRIPTION_TYPE_ACADEMIC, SUBSCRIPTION_TYPE_COMPANY, SUBSCRIPTION_TYPE_INDIVIDUAL } from '../pages/apps/Payment/PaymentPurchasesView';

var CountryLanguage = require('country-language');

const moment = require('moment');
require('moment/min/locales.min');

const toCETString = (date) => {
    let language = getCurrentLanguage();
    if (language == 'zh') {
        language = 'zh-CN';
    }
    moment.locale(language);
    let mm = moment(date).add(2, 'hours');

    let value = mm.format('LLL') + ' CET';//mm.format("yyyy MMM, DD") + ' at ' + mm.format("HH:mm") + ' CET';
    return value;
};

const dateInputDate = (date) => {
    moment.locale(getCurrentLanguage());
    let mm = moment(date);
    let value = mm.format('YYYY-MM');
    return value;
}

const dateOnlyDisplayString = (date) => {
    let language = getCurrentLanguage();
    if (language == 'zh') {
        language = 'zh-CN';
    }
    moment.locale(language);
    let mm = moment(date);
    let value = mm.format('LLL'); //mm.format("yyyy MMM, DD");
    return value;
};

const yearOnlyDisplayString = (date) => {
    let language = getCurrentLanguage();
    if (language == 'zh') {
        language = 'zh-CN';
    }
    moment.locale(language);

    let mm = moment(date);
    if (mm.isValid() == false) {
        mm = moment(date, "MM/YYYY");
    }
    var value = mm.format('YYYY');//('ll'); //mm.format("yyyy MMM, DD");
    if (mm.isValid() == false) {
        value = "";
    }

    return value;
};

const monthOnlyDisplayString = (date) => {
    let language = getCurrentLanguage();
    if (language == 'zh') {
        language = 'zh-CN';
    }
    moment.locale(language);

    let mm = moment(date, "MM/YYYY");
    var value = mm.format('MMM, YYYY');//('ll'); //mm.format("yyyy MMM, DD");
    if (mm.isValid() == false) {
        value = "";
    }
    return value;
};

const dateStringFromTimeInterval = (timeInterval, format) => {
    let date = moment(timeInterval);
    return date.format(format) ?? '';
}

const formatDateString = (dateString, format) => {
    let language = getCurrentLanguage();
    if (language == 'zh') {
        language = 'zh-CN';
    }
    moment.locale(language);

    let date = moment(dateString);
    var value = '';
    if (date.isValid() == false) {
        value = "";
    }
    else {
        value = date.format(format) ?? '';
    }
    return value;
}

const dateOnlyString = (date) => {
    moment.locale(getCurrentLanguage());
    let mm = moment(date);
    let value = mm.format('YYYY-MM-DD');
    return value;
};

const addRealMonth = (d, period, daysOffset) => {
    if(daysOffset === undefined)
        daysOffset = 0;

    var currentDate = moment(d);
    var futureMonth = moment(currentDate).add(period, 'M').add(daysOffset, 'days');
    var futureMonthEnd = moment(futureMonth).endOf('month');

    if (currentDate.date() !== futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
        futureMonth = futureMonth.add(1, 'd');
    }
    let value = futureMonth.format('LL');
    return value;
};

const addRealMonth2 = (d, period, daysOffset) => {
    if(daysOffset === undefined)
        daysOffset = 0;

    var currentDate = moment(d);
    var futureMonth = moment(currentDate).add(period, 'M').add(daysOffset, 'days');
    var futureMonthEnd = moment(futureMonth).endOf('month');

    if (currentDate.date() !== futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
        futureMonth = futureMonth.add(1, 'd');
    }
    let value = futureMonth.format('YYYY, MMMM Do');
    return value;
};

const dateOnlyString2 = (date) => {
    moment.locale(getCurrentLanguage());
    let mm = moment(date);
    let value = mm.format('LL');
    return value;
};

const dateForUpdate = (date) => {
    moment.locale(getCurrentLanguage());
    let mm = moment(date);
    let value = mm.format('YYYY-MM-DD HH:mm:ss');
    return value;
};

const timeFromDateString = (date) => {
    let mm = moment(date);
    return mm.valueOf();
};

const durationBetweenDates = (dateStart, formatStart, dateEnd, formatEnd, timeIntervals) => {
    let starts = moment(dateStart, formatStart);
    if (starts == null || isNaN(starts)) {
        return '';
    }

    let ends = moment();
    if (dateEnd != null) {
        let end = moment(dateEnd, formatEnd);
        if (!isNaN(end)) {
            ends = end;
        }
    }
    var b = starts,
        a = ends,
        intervals = timeIntervals ?? ['years', 'months'],
        out = [];

    for (var i = 0; i < intervals.length; i++) {
        var diff = a.diff(b, intervals[i]);
        b.add(diff, intervals[i]);
        out.push(diff);
    }

    return out;
}

const monthDiff = (timeStamp1, timeStamp2) => {
    let d1 = new Date(timeStamp1);
    let d2 = new Date(timeStamp2);
    let day1 = d1.getDate();
    let day2 = d2.getDate();
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    if (day2 <= day1) {
        months -= 1;
    }
    return months <= 0 ? 0 : months;

}

const durationTimeStringFromDuration = (duration, t) => {
    if (duration?.length == 0) {
        return '';
    }
    let diffYear = duration[0];
    let diffMonth = duration[1];
    if (diffYear != 0 || diffMonth != 0) {
        if (diffYear == 0) {
            return format(t("duration.monthOnly"), diffMonth);
        }
        else if (diffMonth == 0) {
            return format(t("duration.yearOnly"), diffYear);
        }
        else {
            return format(t("duration.yearMonth"), diffYear, diffMonth);
        }
    }
    return '';
}

const durationTimeString = (dateStart, formatStart, dateEnd, formatEnd, t) => {
    let starts = moment(dateStart, formatStart);
    if (starts == null || isNaN(starts)) {
        return '';
    }

    let ends = moment();
    if (dateEnd != null) {
        let end = moment(dateEnd, formatEnd);
        if (!isNaN(end)) {
            ends = end;
        }
    }
    var b = starts,
        a = ends,
        intervals = ['years', 'months'],
        out = [];

    for (var i = 0; i < intervals.length; i++) {
        var diff = a.diff(b, intervals[i]);
        b.add(diff, intervals[i]);
        out.push(diff);
    }

    let diffYear = out[0];
    let diffMonth = out[1];
    if (diffYear != 0 || diffMonth != 0) {
        if (diffYear == 0) {
            return format(t("duration.monthOnly"), diffMonth);
        }
        else if (diffMonth == 0) {
            return format(t("duration.yearOnly"), diffYear);
        }
        else {
            return format(t("duration.yearMonth"), diffYear, diffMonth);
        }
    }
    return '';
};

const allLanguages = () => {
    var allLanguageCodes = CountryLanguage.getLanguages();

    const languages = allLanguageCodes?.map((element, i) => {
        return {
            code: element.iso639_1,
            value: element.iso639_2en,
            label: element.name?.length > 0 ? element.name[0] : '',
            language: element
        };
    });

    return languages ?? []
};

const setAvailableLanguages = (languages) => {
    let cookies = new Cookies();
    if (languages) cookies.set('languages', JSON.stringify(languages), { path: '/' });
    else cookies.remove('languages', { path: '/' });
};

const getAvailableLanguages = () => {
    const cookies = new Cookies();
    const languages = cookies.get('languages');
    return languages ? languages : [];
};

const getCurrentLanguage = () => {
    const cookies = new Cookies();
    const language = cookies.get('language');
    return language ? language : 'en';
};

const setCurrentLanguage = (language) => {
    let cookies = new Cookies();
    if (language) cookies.set('language', JSON.stringify(language), { path: '/' });
    else cookies.remove('language', { path: '/' });
};

const getLocalizedString = (obj) => {
    const defaultValue = obj?.en ?? '---';
    if (obj) {
        const lang = getCurrentLanguage();
        if (lang == 'en' && obj.en) {
            return obj.en;
        } else if (lang == 'fr' && obj.fr) {
            return obj.fr;
        } else if (lang == 'de' && obj.de) {
            return obj.de;
        } else if (lang == 'es' && obj.es) {
            return obj.es;
        } else if (lang == 'it' && obj.it) {
            return obj.it;
        } else if (lang == 'zh' && obj.zh) {
            return obj.zh;
        }
    }
    // console.log('getCurrentLanguage: ' + lang);
    return defaultValue;
};

const countryCodeToString = (countryCode, countries) => {
    const c = countries?.filter((e) => e.value === countryCode);

    var country = (c != null && c.length > 0) ? c[0].label : countryCode;

    return country;
};

const locationString = (countryCode, stateCode, city, countries) => {
    let location = '';
    const c = countryCode?.length > 0 ? countries?.find((e) => e.value === countryCode || e.locCode === countryCode) : null;
    let states = null;
    if (c) {
        states = c.states;
        location = c?.label ?? (c?.locCode ? getLocalizedString(c) : c.value)
    }

    if (states?.length > 0 && stateCode?.length > 0) {
        let state = states.find((s) => { return s.id == stateCode });
        if (state?.name && getLocalizedString(state.name)) {
            location += (location.length > 0 ? ' - ' : '') + getLocalizedString(state.name);
        }
    }

    if (city) {
        location += (location.length > 0 ? ', ' : '') + city;
    }

    return location;
};

const stateCodeToString = (countryCode, stateCode, countries) => {
    const countriesList = countries?.map((element, i) => {
        return {
            value: element.locCode,
            label: getLocalizedString(element),
            states: element.states ? element.states : null,
        };
    });
    const c = countriesList?.filter((e) => e.value === countryCode);

    var stateName = '';
    if (c != null && typeof c[0]?.states !== 'undefined' && c[0].states !== null) {

        const state = c[0].states?.filter((e) => e.id === stateCode);

        if (state?.length > 0) {
            // country += ' / ';
            const s = getLocalizedString(state[0].name);
            stateName += s.length > 0 ? s : stateCode;
        }
    }
    return stateName;
};

const isLeftbarHidden = () => {
    return (
        window.location.pathname.includes('/search') ||
        window.location.pathname.includes('/public_profile') ||
        window.location.pathname.includes('/public_company') ||
        window.location.pathname.includes('/public_academy') ||
        window.location.pathname.includes('/public_competency')
    );
};

const customAlertPopup = ({ title, message, cancelTitle, doneTitle, handleCancel, handleDone }) => {
    return confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div id="react-confirm-alert">
                    <div className="react-confirm-alert-overlay">
                        <div className="react-confirm-alert">
                            <div class={"react-confirm-alert-body bg-color-alert"}>
                                <h1>{title ?? ''}</h1>
                                <Label className='display-linebreak'>{message ?? ''}</Label>
                                <div className="react-confirm-alert-button-group">
                                    {(cancelTitle?.length > 0 || handleCancel != null) &&
                                        <Button color="primary" onClick={() => {
                                            if (handleCancel != null) {
                                                handleCancel();
                                            }
                                            onClose();
                                        }}>{cancelTitle ?? ''}</Button>}
                                    {(doneTitle?.length > 0 || handleDone != null) &&
                                        <Button color="primary" onClick={() => {
                                            if (handleDone != null) {
                                                handleDone();
                                            }
                                            onClose();
                                        }}>{doneTitle ?? ''}</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}

const colorForValidationStatuses = (validations, validated, rejected) => {
    let color = 'gray';
    if (validated.length > 0) {
        color = 'green';
    }
    else if (rejected.length > 0) {
        color = 'red';
    }
    else if (validations.length > 0) {
        color = 'orange';
    }

    return color;
}

const textColorForValidationStatus = (status) => {
    switch (status) {
        case VALIDATION_STATUS_SUBMITTED:
            return 'text-color-orange';
        case VALIDATION_STATUS_VALIDATED:
            return 'text-color-green';
        case VALIDATION_STATUS_REJECTED:
            return 'text-color-red';
        default:
            return 'text-color-default'
    }
}

const objectsFromGroup = (group, key) => {
    if (group == null) {
        return [];
    }

    let object = [];
    if (group[key]?.length > 0) {
        object = group[key];
    }

    if (group.children?.length > 0) {
        for (let i = 0; i < group.children.length; i++) {
            let grandChildren = objectsFromGroup(group.children[i], key);
            object = object.concat(grandChildren);
        }
    }
    return object;
}

const childrenOfObject = (object) => {
    if (object == null) {
        return [];
    }

    let child = Object.assign({}, object);
    child.children = null;
    let children = [];
    children.push(child);

    let grandChildren = object.children ?? [];
    if (grandChildren.length > 0) {
        for (let i = 0; i < grandChildren.length; i++) {
            let grand = childrenOfObject(grandChildren[i])
            children = children.concat(grand)
        }
    }
    return children
}

const mobileElements = (mobile) => {
    if (mobile == null) {
        return null;
    }
    let founded = findPhoneNumbersInText(mobile);
    if (founded?.length > 0) {
        return { countryCode: founded[0].number?.country, dialCode: founded[0].number?.countryCallingCode, mobile: mobile };
    }
    return null;
}

const internationalPhoneFormat = (mobile) => {
    if (mobile == null) {
        return '';
    }

    let phone = typeof mobile === 'string' ? mobile : mobile?.mobile;

    if (phone.length > 0 && phone.substring(0, 1) != '+') {
        phone = '+' + phone;
    }

    let phoneNumber = parsePhoneNumber(phone);
    phoneNumber = phoneNumber?.formatInternational();
    return phoneNumber ?? phone;
}

const isSamePhoneNumber = (number1, number2) => {
    if (number1 == null || number2 == null) {
        return false;
    }

    if (number1?.length > 0 && number1.substring(0, 1) != '+') {
        number1 = '+' + number1;
    }

    if (number2?.length > 0 && number2.substring(0, 1) != '+') {
        number2 = '+' + number2;
    }
    return number1 == number2;
}

const uniqueArray = (array, key) => {
    array = array.filter((value, index, self) => {
        return index === self.findIndex((t) => {
            return key != null ? (t[key] === value[key]) : (t === value)
        })
    })

    return array;
}

const uniqueSimpleArray = (array) => {
    const uniqueArray = [];
    array.map(item => {
        if (uniqueArray.indexOf(item) === -1) {
            uniqueArray.push(item)
        }
    });

    return uniqueArray;
}

const facultyWithId = (id, academyFaculties) => {
    let facultyDoc = academyFaculties?.map((f) => { return { id: f.order, label: f.value ? getLocalizedString(f.value) : '', entity: f } }) ?? [];
    let faculty = null;
    if (id != null && facultyDoc.length > 0) {
        faculty = facultyDoc.find((f) => { return f.id == id });
    }
    return faculty;
}

const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
}

const isAdmin = (s) => {
    return getLoggedInUser()?.roles?.includes('ROLE_MODERATOR') || getLoggedInUser()?.roles?.includes('ROLE_ADMIN');
}

const combineObjects = (object1, object2) => {
    let keys1 = Object.keys(object1);
    let keys2 = Object.keys(object2);
    keys2.forEach(element => {
        if (!keys1.some((k) => { return k == element })) {
            object1[element] = object2[element];
        }
    });
    return object1;
}

const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}

const isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomColor = () => {
    let h = randomNumberInRange(0, 360);
    let s = 100//randomNumberInRange(30, 100);
    let l = randomNumberInRange(30, 80);
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

const isIndividualFree = (securityProducts, payments) => {
    if (securityProducts?.length < 2 || payments == null || payments?.length == 0) {
        return true;
    }
    
    let products = (securityProducts ?? []).filter((p) => { return p.id.includes('Free') || (p.id.includes('individual') && p.subscriptionType == SUBSCRIPTION_TYPE_INDIVIDUAL) });
    products = products.filter((p) => { return p.id.includes("Free") || (p.status != PRODUCT_STATUS_EXPIRED && p.status != PRODUCT_STATUS_PAYMENT_STOPPED) });

    let currentProduct = products.length > 0
        ? products[products.length - 1]
        : null;

    let productId = currentProduct?.id ?? '';

    let subscriptionFailed = currentProduct?.status == PRODUCT_STATUS_PAYMENT_FAILED;
    if (subscriptionFailed && payments?.length > 0) {
        if (payments?.length > 0) {
            payments = payments.filter((p) => { return p.paymentRequestParams?.productId == productId });
            if (payments.length > 0) {
                payments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
                subscriptionFailed = payments[0].payments?.length > 1;

                if (!subscriptionFailed) {
                    let activatingProducts = products.filter((p) => { return p.status == PRODUCT_STATUS_LIVE });
                    if (activatingProducts.length > 0) {
                        currentProduct = activatingProducts[activatingProducts.length - 1];
                    }
                    else {
                        currentProduct = products[0];
                    }
                    productId = currentProduct?.id ?? '';
                }
            }
        }
    }

    let unPaids = [];
    if ((productId.includes('Free') || subscriptionFailed) && payments?.length > 0) {
        let unPaidPayments = payments.filter((p) => {
            let payments = p.payments ?? [];
            if (payments.length <= 1) {
                return false;
            }
            else {
                let paymentPlan = p.paymentRequestParams?.options?.paymentPlan;
                let paids = payments.filter((payment) => { return payment.status == 'paid' });
                paids.sort(function (a, b) { return b.dateSubmit > a.dateSubmit ? -1 : 1 });
                let firstPaid = paids[0].paidAt ?? paids[0].createdAt;
                let subscriptionedMonths = monthDiff(firstPaid * 1000, Date.now());
                return subscriptionedMonths < paymentPlan;
            }
        });
        if (unPaidPayments.length > 0) {
            unPaidPayments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
            unPaids = (unPaidPayments[0].payments ?? []).filter((p) => { return p.status == 'failed' });
            unPaids.sort(function (a, b) { return b.createdAt < a.createdAt ? -1 : 1 });
            currentProduct = products.find((p) => { return p.id == unPaidPayments[0].paymentRequestParams?.productId });
            productId = currentProduct?.id ?? '';
            subscriptionFailed = true;
        }
    }

    return productId.includes('Free') || subscriptionFailed;
}

export {
    toCETString,
    dateOnlyString,
    dateForUpdate,
    timeFromDateString,
    dateOnlyDisplayString,
    dateOnlyString2,
    durationBetweenDates,
    monthDiff,
    durationTimeStringFromDuration,
    durationTimeString,
    allLanguages,
    setAvailableLanguages,
    getAvailableLanguages,
    setCurrentLanguage,
    getCurrentLanguage,
    getLocalizedString,
    addRealMonth2,
    addRealMonth,
    dateInputDate,
    monthOnlyDisplayString,
    formatDateString,
    countryCodeToString,
    locationString,
    stateCodeToString,
    isLeftbarHidden,
    customAlertPopup,
    yearOnlyDisplayString,
    dateStringFromTimeInterval,
    colorForValidationStatuses,
    textColorForValidationStatus,
    objectsFromGroup,
    childrenOfObject,
    mobileElements,
    internationalPhoneFormat,
    isSamePhoneNumber,
    uniqueArray,
    uniqueSimpleArray,
    facultyWithId,
    capitalize,
    isAdmin,
    combineObjects,
    isValidEmail,
    isValidURL,
    randomNumberInRange,
    randomColor,
    isIndividualFree
};
