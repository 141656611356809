import React, { Component } from 'react';
import { connect } from 'react-redux';
//import './assets/scss/theme.scss';
import { getCurrentLanguage } from './helpers/utils';
import Routes from './routes/Routes';
import { i18n, loadFromLocalizationFromDatabase } from './translations/i18n';
import * as layoutConstants from './constants/layout';

const LightTheme = React.lazy(() => import('./ThemeLight'));
const DarkTheme = React.lazy(() => import('./ThemeDark'));

/**
 * Main app component
 */
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDark: false,
        };
    }
    componentDidMount() {
        loadFromLocalizationFromDatabase();
        const language = getCurrentLanguage();
        i18n.changeLanguage(language);
        this.setState({
            isDark: false,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.theme != prevProps.theme) {
            window.location.reload(false);
        }
    }

    //create a parent component that will load the components conditionally using React.Suspense
    ThemeSelector = ({ children }) => {
        //const CHOSEN_THEME = localStorage.getItem('TYPE_OF_THEME') || TYPE_OF_THEME.DEFAULT;
        let isDark = this.state.isDark;
        if (this.props.theme == layoutConstants.THEME_DARK) {
            isDark = true;
        } else if (this.props.theme == layoutConstants.THEME_DEVICE) {
            isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
                // isDark = e.matches? true: false;
                window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
                if (isDark != (e.matches? true: false)) {
                  isDark = e.matches? true: false;
                  this.setState(
                      {
                          isDark: isDark,
                      },
                      function () {
                          window.location.reload(false);
                      }
                  );
              }
            });
        }

        return (
            <>
                <React.Suspense fallback={<></>}>{isDark ? <DarkTheme /> : <LightTheme />}</React.Suspense>
                {children}
            </>
        );
    };

    render() {
        return (
            <this.ThemeSelector>
                <Routes></Routes>;
            </this.ThemeSelector>
        );
    }
}

const mapStateToProps = (state) => {
    const { theme } = state.Layout;

    return { theme };
};

export default connect(mapStateToProps)(App);
