/* AUTH */
export const CHECK_EXIST_NETWORK_DOMAIN = "CHECK_EXIST_NETWORK_DOMAIN";
export const CHECK_EXIST_NETWORK_DOMAIN_SUCCESS = "CHECK_EXIST_NETWORK_DOMAIN_SUCCESS";
export const CHECK_EXIST_NETWORK_DOMAIN_FAILED = "CHECK_EXIST_NETWORK_DOMAIN_FAILED";

//Network Person
export const CREATE_CONTACT = "CREATE_CONTACT";
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILED = 'CREATE_CONTACT_FAILED';

export const EDIT_CONTACT = "EDIT_CONTACT";
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILED = 'EDIT_CONTACT_FAILED';

export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED';

export const GET_NETWORK = "GET_NETWORK";
export const GET_NETWORK_SUCCESS = 'GET_NETWORK_SUCCESS';
export const GET_NETWORK_FAILED = 'GET_NETWORK_FAILED';

export const CREATE_INVITE = "CREATE_INVITE";
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';
export const CREATE_INVITE_FAILED = 'CREATE_INVITE_FAILED';

export const GET_ALL_NETWORK_PEOPLE = "GET_ALL_NETWORK_PEOPLE";
export const GET_ALL_NETWORK_PEOPLE_SUCCESS = "GET_ALL_NETWORK_PEOPLE_SUCCESS";
export const GET_ALL_NETWORK_PEOPLE_FAILED = "GET_ALL_NETWORK_PEOPLE_FAILED";

export const GET_NETWORK_PEOPLE = "GET_NETWORK_PEOPLE";
export const GET_NETWORK_PEOPLE_SUCCESS = "GET_NETWORK_PEOPLE_SUCCESS";
export const GET_NETWORK_PEOPLE_FAILED = "GET_NETWORK_PEOPLE_FAILED";

export const GET_ALL_NETWORK_PERSONS = "GET_ALL_NETWORK_PERSONS";
export const GET_ALL_NETWORK_PERSONS_SUCCESS = "GET_ALL_NETWORK_PERSONS_SUCCESS";
export const GET_ALL_NETWORK_PERSONS_FAILED = "GET_ALL_NETWORK_PERSONS_FAILED";

export const GET_ALL_ACADEMICS = "GET_ALL_ACADEMICS";
export const GET_ALL_ACADEMICS_SUCCESS = "GET_ALL_ACADEMICS_SUCCESS";
export const GET_ALL_ACADEMICS_FAILED = "GET_ALL_ACADEMICS_FAILED";

export const GET_ALL_COMPANIES2 = "GET_ALL_COMPANIES2";
export const GET_ALL_COMPANIES2_SUCCESS = "GET_ALL_COMPANIES2_SUCCESS";
export const GET_ALL_COMPANIES2_FAILED = "GET_ALL_COMPANIES2_FAILED";

export const GET_NETWORK_COMPANIES = "GET_NETWORK_COMPANIES";
export const GET_NETWORK_COMPANIES_SUCCESS = "GET_NETWORK_COMPANIES_SUCCESS";
export const GET_NETWORK_COMPANIES_FAILED = "GET_NETWORK_COMPANIES_FAILED";

export const GET_ALL_NETWORK_COMPANIES = "GET_ALL_NETWORK_COMPANIES";
export const GET_ALL_NETWORK_COMPANIES_SUCCESS = "GET_ALL_NETWORK_COMPANIES_SUCCESS";
export const GET_ALL_NETWORK_COMPANIES_FAILED = "GET_ALL_NETWORK_COMPANIES_FAILED";

export const CHECK_EXIST_USERS = "CHECK_EXIST_USERS";
export const CHECK_EXIST_USERS_SUCCESS = "CHECK_EXIST_USERS_SUCCESS";
export const CHECK_EXIST_USERS_FAILED = "CHECK_EXIST_USERS_FAILED";

export const RESET_EXIST_USERS = "RESET_EXIST_USERS";
export const RESET_EXIST_USERS_SUCCESS = "RESET_EXIST_USERS_SUCCESS";
export const RESET_EXIST_USERS_FAILED = "RESET_EXIST_USERS_FAILED";

export const GET_NETWORK_PERSON_INVITATIONS = "GET_NETWORK_PERSON_INVITATIONS";
export const GET_NETWORK_PERSON_INVITATIONS_SUCCESS = "GET_NETWORK_PERSON_INVITATIONS_SUCCESS";
export const GET_NETWORK_PERSON_INVITATIONS_FAILED = "GET_NETWORK_PERSON_INVITATIONS_FAILED";

export const GET_NETWORK_PERSON_VALIDATIONS = "GET_NETWORK_PERSON_VALIDATIONS";
export const GET_NETWORK_PERSON_VALIDATIONS_SUCCESS = "GET_NETWORK_PERSON_VALIDATIONS_SUCCESS";
export const GET_NETWORK_PERSON_VALIDATIONS_FAILED = "GET_NETWORK_PERSON_VALIDATIONS_FAILED";

export const GET_NETWORK_PERSON_EXPERIENCES = "GET_NETWORK_PERSON_EXPERIENCES";
export const GET_NETWORK_PERSON_EXPERIENCES_SUCCESS = "GET_NETWORK_PERSON_EXPERIENCES_SUCCESS";
export const GET_NETWORK_PERSON_EXPERIENCES_FAILED = "GET_NETWORK_PERSON_EXPERIENCES_FAILED";

export const GET_NETWORK_PERSON_NETWORK = "GET_NETWORK_PERSON_NETWORK";
export const GET_NETWORK_PERSON_NETWORK_SUCCESS = "GET_NETWORK_PERSON_NETWORK_SUCCESS";
export const GET_NETWORK_PERSON_NETWORK_FAILED = "GET_NETWORK_PERSON_NETWORK_FAILED";


//Network Company
export const CHECK_EXIST_COMPANY = "CHECK_EXIST_COMPANY";
export const CHECK_EXIST_COMPANY_SUCCESS = "CHECK_EXIST_COMPANY_SUCCESS";
export const CHECK_EXIST_COMPANY_FAILED = "CHECK_EXIST_COMPANY_FAILED";

export const CHECK_EXIST_COMPANY_DOMAINS = "CHECK_EXIST_COMPANY_DOMAINS";
export const CHECK_EXIST_COMPANY_DOMAINS_SUCCESS = "CHECK_EXIST_COMPANY_DOMAINS_SUCCESS";
export const CHECK_EXIST_COMPANY_DOMAINS_FAILED = "CHECK_EXIST_COMPANY_DOMAINS_FAILED";

export const CREATE_NETWORK_COMPANY = "CREATE_NETWORK_COMPANY";
export const CREATE_NETWORK_COMPANY_SUCCESS = "CREATE_NETWORK_COMPANY_SUCCESS";
export const CREATE_NETWORK_COMPANY_FAILED = "CREATE_NETWORK_COMPANY_FAILED";

export const EDIT_NETWORK_COMPANY = "EDIT_NETWORK_COMPANY";
export const EDIT_NETWORK_COMPANY_SUCCESS = "EDIT_NETWORK_COMPANY_SUCCESS";
export const EDIT_NETWORK_COMPANY_FAILED = "EDIT_NETWORK_COMPANY_FAILED";

export const DELETE_NETWORK_COMPANY = "DELETE_NETWORK_COMPANY";
export const DELETE_NETWORK_COMPANY_SUCCESS = "DELETE_NETWORK_COMPANY_SUCCESS";
export const DELETE_NETWORK_COMPANY_FAILED = "DELETE_NETWORK_COMPANY_FAILED";

export const CREATE_NETWORK_COMPANY_GOAL = "CREATE_NETWORK_COMPANY_GOAL";
export const CREATE_NETWORK_COMPANY_GOAL_SUCCESS = "CREATE_NETWORK_COMPANY_GOAL_SUCCESS";
export const CREATE_NETWORK_COMPANY_GOAL_FAILED = "CREATE_NETWORK_COMPANY_GOAL_FAILED";

export const EDIT_NETWORK_COMPANY_GOAL = "EDIT_NETWORK_COMPANY_GOAL";
export const EDIT_NETWORK_COMPANY_GOAL_SUCCESS = "EDIT_NETWORK_COMPANY_GOAL_SUCCESS";
export const EDIT_NETWORK_COMPANY_GOAL_FAILED = "EDIT_NETWORK_COMPANY_GOAL_FAILED";

export const DELETE_NETWORK_COMPANY_GOAL = "DELETE_NETWORK_COMPANY_GOAL";
export const DELETE_NETWORK_COMPANY_GOAL_SUCCESS = "DELETE_NETWORK_COMPANY_GOAL_SUCCESS";
export const DELETE_NETWORK_COMPANY_GOAL_FAILED = "DELETE_NETWORK_COMPANY_GOAL_FAILED";

export const GET_NETWORK_COMPANY_EXPERIENCES = "GET_NETWORK_COMPANY_EXPERIENCES";
export const GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS = "GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS";
export const GET_NETWORK_COMPANY_EXPERIENCES_FAILED = "GET_NETWORK_COMPANY_EXPERIENCES_FAILED";

//Network Academics
export const CHECK_EXIST_ACADEMY = "CHECK_EXIST_ACADEMY";
export const CHECK_EXIST_ACADEMY_SUCCESS = "CHECK_EXIST_ACADEMY_SUCCESS";
export const CHECK_EXIST_ACADEMY_FAILED = "CHECK_EXIST_ACADEMY_FAILED";

export const CHECK_EXIST_ACADEMY_DOMAINS = "CHECK_EXIST_ACADEMY_DOMAINS";
export const CHECK_EXIST_ACADEMY_DOMAINS_SUCCESS = "CHECK_EXIST_ACADEMY_DOMAINS_SUCCESS";
export const CHECK_EXIST_ACADEMY_DOMAINS_FAILED = "CHECK_EXIST_ACADEMY_DOMAINS_FAILED";

export const CREATE_NETWORK_ACADEMY = "CREATE_NETWORK_ACADEMY";
export const CREATE_NETWORK_ACADEMY_SUCCESS = "CREATE_NETWORK_ACADEMY_SUCCESS";
export const CREATE_NETWORK_ACADEMY_FAILED = "CREATE_NETWORK_ACADEMY_FAILED";

export const EDIT_NETWORK_ACADEMY = "EDIT_NETWORK_ACADEMY";
export const EDIT_NETWORK_ACADEMY_SUCCESS = "EDIT_NETWORK_ACADEMY_SUCCESS";
export const EDIT_NETWORK_ACADEMY_FAILED = "EDIT_NETWORK_ACADEMY_FAILED";

export const DELETE_NETWORK_ACADEMY = "DELETE_NETWORK_ACADEMY";
export const DELETE_NETWORK_ACADEMY_SUCCESS = "DELETE_NETWORK_ACADEMY_SUCCESS";
export const DELETE_NETWORK_ACADEMY_FAILED = "DELETE_NETWORK_ACADEMY_FAILED";

export const GET_ALL_NETWORK_ACADEMICS = "GET_ALL_NETWORK_ACADEMICS";
export const GET_ALL_NETWORK_ACADEMICS_SUCCESS = "GET_ALL_NETWORK_ACADEMICS_SUCCESS";
export const GET_ALL_NETWORK_ACADEMICS_FAILED = "GET_ALL_NETWORK_ACADEMICS_FAILED";

export const GET_NETWORK_ACADEMICS = "GET_NETWORK_ACADEMICS";
export const GET_NETWORK_ACADEMICS_SUCCESS = "GET_NETWORK_ACADEMICS_SUCCESS";
export const GET_NETWORK_ACADEMICS_FAILED = "GET_NETWORK_ACADEMICS_FAILED";

export const GET_NETWORK_ACADEMIC_EXPERIENCES = "GET_NETWORK_ACADEMIC_EXPERIENCES";
export const GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS = "GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS";
export const GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED = "GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED";

export const CREATE_EDUCATION = "CREATE_EDUCATION";
export const CREATE_EDUCATION_SUCCESS = "CREATE_EDUCATION_SUCCESS";
export const CREATE_EDUCATION_FAILED = "CREATE_EDUCATION_FAILED";

export const EDIT_EDUCATION = "EDIT_EDUCATION";
export const EDIT_EDUCATION_SUCCESS = "EDIT_EDUCATION_SUCCESS";
export const EDIT_EDUCATION_FAILED = "EDIT_EDUCATION_FAILED";

export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_FAILED = "DELETE_EDUCATION_FAILED";

export const GET_PEOPLE_BY_DOMAIN = "GET_PEOPLE_BY_DOMAIN";
export const GET_PEOPLE_BY_DOMAIN_SUCCESS = "GET_PEOPLE_BY_DOMAIN_SUCCESS";
export const GET_PEOPLE_BY_DOMAIN_FAILED = "GET_PEOPLE_BY_DOMAIN_FAILED";

export const CREATE_NETWORK_EVENT = "CREATE_NETWORK_EVENT";
export const CREATE_NETWORK_EVENT_SUCCESS = "CREATE_NETWORK_EVENT_SUCCESS";
export const CREATE_NETWORK_EVENT_FAILED = "CREATE_NETWORK_EVENT_FAILED";

export const EDIT_NETWORK_EVENT = "EDIT_NETWORK_EVENT";
export const EDIT_NETWORK_EVENT_SUCCESS = "EDIT_NETWORK_EVENT_SUCCESS";
export const EDIT_NETWORK_EVENT_FAILED = "EDIT_NETWORK_EVENT_FAILED";

export const DELETE_NETWORK_EVENT = "DELETE_NETWORK_EVENT";
export const DELETE_NETWORK_EVENT_SUCCESS = "DELETE_NETWORK_EVENT_SUCCESS";
export const DELETE_NETWORK_EVENT_FAILED = "DELETE_NETWORK_EVENT_FAILED";

export const GET_ALL_NETWORK_EVENTS = "GET_ALL_NETWORK_EVENTS";
export const GET_ALL_NETWORK_EVENTS_SUCCESS = "GET_ALL_NETWORK_EVENTS_SUCCESS";
export const GET_ALL_NETWORK_EVENTS_FAILED = "GET_ALL_NETWORK_EVENTS_FAILED";

export const GET_NETWORK_EVENTS = "GET_NETWORK_EVENTS";
export const GET_NETWORK_EVENTS_SUCCESS = "GET_NETWORK_EVENTS_SUCCESS";
export const GET_NETWORK_EVENTS_FAILED = "GET_NETWORK_EVENTS_FAILED";

export const CHECK_EXIST_EVENT = "CHECK_EXIST_EVENT";
export const CHECK_EXIST_EVENT_SUCCESS = "CHECK_EXIST_EVENT_SUCCESS";
export const CHECK_EXIST_EVENT_FAILED = "CHECK_EXIST_EVENT_FAILED";

export const GET_EVENT_GROUP = "GET_EVENT_GROUP";
export const GET_EVENT_GROUP_SUCCESS = "GET_EVENT_GROUP_SUCCESS";
export const GET_EVENT_GROUP_FAILED = "GET_EVENT_GROUP_FAILED";

export const GET_SIGNUP_REQUESTS = "GET_SIGNUP_REQUESTS";
export const GET_SIGNUP_REQUESTS_SUCCESS = "GET_SIGNUP_REQUESTS_SUCCESS";
export const GET_SIGNUP_REQUESTS_FAILED = "GET_SIGNUP_REQUESTS_FAILED";

export const DECLINE_SIGNUP_REQUEST = "DECLINE_SIGNUP_REQUEST";
export const DECLINE_SIGNUP_REQUEST_SUCCESS = "DECLINE_SIGNUP_REQUEST_SUCCESS";
export const DECLINE_SIGNUP_REQUEST_FAILED = "DECLINE_SIGNUP_REQUEST_FAILED";

export const ADD_EXIST_COMPANY_TO_NETWORK = "ADD_EXIST_COMPANY_TO_NETWORK";
export const ADD_EXIST_COMPANY_TO_NETWORK_SUCCESS = "ADD_EXIST_COMPANY_TO_NETWORK_SUCCESS";
export const ADD_EXIST_COMPANY_TO_NETWORK_FAILED = "ADD_EXIST_COMPANY_TO_NETWORK_FAILED";

export const ADD_EXIST_ACADEMIC_TO_NETWORK = "ADD_EXIST_ACADEMIC_TO_NETWORK";
export const ADD_EXIST_ACADEMIC_TO_NETWORK_SUCCESS = "ADD_EXIST_ACADEMIC_TO_NETWORK_SUCCESS";
export const ADD_EXIST_ACADEMIC_TO_NETWORK_FAILED = "ADD_EXIST_ACADEMIC_TO_NETWORK_FAILED";