// @flow
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import zh from 'date-fns/locale/zh-CN';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerWrapperStyles, DatePickerWrapperDarkStyles } from '../../../helpers/DatePickerFormat';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormGroup,
    InputGroup,
    Label,
    Media,
    Modal,
    Row,
    Spinner,
} from 'reactstrap';
import Loader from '../../../components/Loader';
import { format } from '../../../helpers/stringFormat';
import { getCurrentLanguage, getLocalizedString, addRealMonth2, timeFromDateString, monthDiff } from '../../../helpers/utils';
import { getRepoProducts, getCheckoutLink, payFailedPayment } from '../../../redux/actions';
import * as FeatherIcon from 'react-feather';
import ProductView from '../../auth/ProductView';
import CurrentSubscription from '../Settings/Profile/Subscriptions/individual/CurrentSubscription';
import { PRODUCT_STATUS_EXPIRED, PRODUCT_STATUS_LIVE, PRODUCT_STATUS_PAYMENT_FAILED, PRODUCT_STATUS_PAYMENT_STOPPED } from '../../../redux/profile/constants';

export const SUBSCRIPTION_TYPE_INDIVIDUAL = 0;
export const SUBSCRIPTION_TYPE_COMPANY = 1;
export const SUBSCRIPTION_TYPE_ACADEMIC = 2;

class PaymentPurchasesView extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            size: 'lg',
            loading: false,
            disabledNext: true,
            error: null,
            selectedOption: null,
            selectedDetail: null,
            selectTypePayIndex: 0,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('authentication-bg');
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }

    onClickNext = () => {
        let detail = this.state.selectedDetail;
        let option = this.state.selectedOption;
        this.props.nextPurchasesView(detail, option);
    };

    onClickPrevious = () => {
        this.props.previousPurchasesView();
    };

    checkOut = (subscriptionId) => {
        this.props.getCheckoutLink(subscriptionId, {
            callbackOnBegin: () => {
                this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                this.props.checkoutPayment(response)
            },
        });
    }

    productId = (currentProduct) => {
        let productId = currentProduct?.id ?? '';
        if (productId.includes('individualFree') && (this.props.subscriptionType == SUBSCRIPTION_TYPE_COMPANY || this.props.subscriptionType == SUBSCRIPTION_TYPE_ACADEMIC)) {
            productId = 'Product::companyFree';
        }

        return productId;
    }

    handlePay = (currentPayment) => {
        if (currentPayment?.id?.length > 0) {
            let failedPayments = (currentPayment.payments ?? []).filter((p) => { return p.status != 'paid' });
            if (failedPayments.length > 0) {
                failedPayments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
                let latestPayment = failedPayments[0];
                if (latestPayment.status == 'expired') {
                    this.checkOut(currentPayment?.id);
                }
                else {
                    this.props.payFailedPayment(currentPayment.id, 0, {
                        callbackOnSuccess: (checkOutLink) => {
                            window.location.replace(checkOutLink);
                        }
                    });
                }
            }
        }
    }

    render() {
        const { t } = this.props;

        let selectTypePay = this.props.currencies?.length > this.state.selectTypePayIndex ? this.props.currencies[this.state.selectTypePayIndex] : null;
        const currencyName = selectTypePay ? getLocalizedString(selectTypePay.name) : '';

        let productType = 'individual';
        let type = SUBSCRIPTION_TYPE_INDIVIDUAL;
        switch (this.props.subscriptionType) {
            case SUBSCRIPTION_TYPE_INDIVIDUAL:
                productType = 'individual';
                type = SUBSCRIPTION_TYPE_INDIVIDUAL;
                break;
            case SUBSCRIPTION_TYPE_COMPANY:
                productType = 'company';
                type = SUBSCRIPTION_TYPE_COMPANY;
                break;
            case SUBSCRIPTION_TYPE_ACADEMIC:
                productType = 'company';
                type = SUBSCRIPTION_TYPE_ACADEMIC;
                break;
            default:
                productType = 'individual';
                type = SUBSCRIPTION_TYPE_INDIVIDUAL;
                break;
        }

        let products = (this.props.security?.products ?? []).filter((p) => { return p.id.includes('Free') || (p.id.includes(productType) && p.subscriptionType == type) });
        products = products.filter((p) => { return p.id.includes("Free") || (p.status != PRODUCT_STATUS_EXPIRED && p.status != PRODUCT_STATUS_PAYMENT_STOPPED) });

        let currentProduct = products.length > 0
            ? products[products.length - 1]
            : null;

        let productId = this.productId(currentProduct);

        let subscriptionFailed = currentProduct?.status == PRODUCT_STATUS_PAYMENT_FAILED;
        if (subscriptionFailed && this.props.payments?.length > 0) {
            if (this.props.payments?.length > 0) {
                let payments = this.props.payments.filter((p) => { return p.paymentRequestParams?.productId == productId });
                if (payments.length > 0) {
                    payments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
                    subscriptionFailed = payments[0].payments?.length > 1;

                    if (!subscriptionFailed) {
                        let activatingProducts = products.filter((p) => { return p.status == PRODUCT_STATUS_LIVE });
                        if (activatingProducts.length > 0) {
                            currentProduct = activatingProducts[activatingProducts.length - 1];
                        }
                        else {
                            currentProduct = products[0];
                        }
                        productId = this.productId(currentProduct);
                    }
                }
            }
        }

        let unPaids = [];
        if ((productId.includes('Free') || subscriptionFailed) && this.props.payments?.length > 0) {
            let unPaidPayments = this.props.payments.filter((p) => {
                let payments = p.payments ?? [];
                if (payments.length <= 1) {
                    return false;
                }
                else {
                    let paymentPlan = p.paymentRequestParams?.options?.paymentPlan;
                    let paids = payments.filter((payment) => { return payment.status == 'paid' });
                    paids.sort(function (a, b) { return b.dateSubmit > a.dateSubmit ? -1 : 1 });
                    let firstPaid = paids[0].paidAt ?? paids[0].createdAt;
                    let subscriptionedMonths = monthDiff(firstPaid * 1000, Date.now());
                    return subscriptionedMonths < paymentPlan;
                }
            });
            if (unPaidPayments.length > 0) {
                unPaidPayments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
                unPaids = (unPaidPayments[0].payments ?? []).filter((p) => { return p.status == 'failed' });
                unPaids.sort(function (a, b) { return b.createdAt < a.createdAt ? -1 : 1 });
                currentProduct = products.find((p) => { return p.id == unPaidPayments[0].paymentRequestParams?.productId });
                productId = this.productId(currentProduct);
                subscriptionFailed = true;
            }
        }

        let currentPayment = null;
        let requestPayment = null;
        if (!productId.includes('Free') && this.props.payments?.length > 0) {
            let payments = this.props.payments.filter((p) => { return (p.paymentRequestParams?.productId == productId) && ((p.payments ?? []).filter((payment) => { return payment.status == 'paid' }).length > 0) });
            if (payments.length > 0) {
                payments.sort(function (a, b) { return b.dateSubmit < a.dateSubmit ? -1 : 1 });
                currentPayment = payments[0];
                requestPayment = currentPayment.paymentRequestParams;
            }
        }

        let subscriptionIndex = (this.props.productDocs ?? []).findIndex((product) => { return product.id == productId });
        let currentSubscription = subscriptionIndex >= 0 ? this.props.productDocs[subscriptionIndex] : null;
        if (subscriptionIndex > 0) {
            let standardFeatures = [];
            for (var i = 0; i < subscriptionIndex; i++) {
                standardFeatures = standardFeatures.concat(this.props.productDocs[i].detail?.features ?? []);
            }
            if (this.props.productDocs[subscriptionIndex].detail != null) {
                this.props.productDocs[subscriptionIndex].detail.standardFeatures = standardFeatures;
            }
        }

        console.log('CurrentPayment', currentPayment);

        let dueDate = ''
        if (this.props.payments?.length > 0) {
            if (this.props.payments[0].subscription?.nextPaymentDate != null && this.props.payments[0].subscription?.nextPaymentDate > 0) {
                dueDate = addRealMonth2(this.props.payments[0].subscription?.nextPaymentDate, 0, -1);
            }
            else {
                dueDate = addRealMonth2(this.props.payments[0].subscription?.startDate ?? (currentPayment?.dateSubmit * 1000), requestPayment?.options?.subscriptionPeriod ?? 1, -1);
            }
        }

        const isDowngrade = productId === 'Product::companyPremium' || productId === 'Product::academyPremium' || productId === 'Product::individualPremium';

        let engagement = format(
            t('payment.title'),
            requestPayment?.options?.paymentPlan ?? 1,
            requestPayment?.options?.subscriptionPeriod ?? 1,
            requestPayment?.options?.monthPrice
                ? requestPayment.options.monthPrice[currencyName]
                : '',
            selectTypePay?.symbol
        );
        let remaining = format(t('payment.content'), requestPayment?.options?.subscriptionPeriod ?? 1, dueDate);

        let actionDescription = t(isDowngrade ? 'downgrade.message' : 'sub.bottomStepFinish');
        if (subscriptionFailed) {
            let unPaidMonths = 0;
            if (unPaids.length > 0) {
                let failedTime = unPaids[0].createdAt ?? 0;
                if (failedTime > 0) {
                    unPaidMonths = monthDiff(failedTime * 1000, Date.now());
                }
            }
            actionDescription = format(t('payment.forcedDowngradeMessage'), unPaidMonths + 1);
        }


        return (
            <React.Fragment>
                <Row>
                    <div md={12}>
                        {this.state.error && <this.Alert error={this.state.error} />}
                        {this.state.loading == true && <Loader />}

                        <CurrentSubscription
                            repoProducts={products}
                            productDocs={currentSubscription}
                        />
                        <br />
                        {!subscriptionFailed && !productId.includes('Free') &&
                            <div className='align-vertical'>
                                <Label className='mt-4'>{engagement}</Label>
                                <Label>{remaining}</Label>
                            </div>
                        }

                        <Label className='mt-4 mb-4'>{actionDescription}</Label>

                        {subscriptionFailed ?
                            <div className="center-horizon">
                                <Row className="center-horizon.self">
                                    <Button color="primary" onClick={(e) => {
                                        e.currentTarget.blur();
                                        this.handlePay(currentPayment);
                                    }}>
                                        <i className="uil"></i>
                                        {t('payment.pay')}
                                    </Button>
                                </Row>
                            </div>
                            :
                            <div className="center-horizon">
                                <Row className="center-horizon.self">
                                    {(currentProduct != null && productId !== 'Product::companyFree' && productId !== 'Product::individualFree' && (requestPayment?.options?.paymentPlan ?? 0) > 1) && (
                                        <Button className="mr-3 custom-btn-red" onClick={this.props.stopPurchases}>
                                            <i className="uil"></i>
                                            {t('sub.stop')}
                                        </Button>
                                    )}
                                    {isDowngrade && (
                                        <Button
                                            color="primary"
                                            className="mr-3"
                                            disabled={true}
                                            onClick={this.props.downgradePurchases}>
                                            <i className="uil"></i>
                                            {t('sub.downgrade')}
                                        </Button>
                                    )}
                                    {(productId !== 'Product::companyPremium' && productId !== 'Product::academyPremium' && productId !== 'Product::individualPremium') && (
                                        <Button color="primary" onClick={this.props.upgradePurchases}>
                                            <i className="uil"></i>
                                            {t('sub.upgrade')}
                                        </Button>
                                    )}
                                </Row>
                            </div>
                        }
                    </div>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currencies, loading, error } = state.RepoProducts;

    return { currencies, loading, error };
};

const mapDispatchToProps = {
    getRepoProducts,
    getCheckoutLink,
    payFailedPayment
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(PaymentPurchasesView));
