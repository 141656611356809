// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from '../constants';
import {
    createContactSuccess, createContactFailed,
    editContactSuccess, editContactFailed,
    deleteContactSuccess, deleteContactFailed,
    getNetworkSuccess, getNetworkFailed,
    createInviteSuccess, createInviteFailed,
    getNetworkPeopleSuccess, getNetworkPeopleFailed,
    getNetworkCompaniesSuccess, getNetworkCompaniesFailed,
    checkExistUsersSuccess, checkExistUsersFailed,
    resetExistUsersSuccess,
    getNetworkPersonInvitationsSuccess, getNetworkPersonInvitationsFailed,
    getNetworkPersonValidationsSuccess, getNetworkPersonValidationsFailed,
    getNetworkPersonExperiencesSuccess, getNetworkPersonExperiencesFailed,
    getNetworkPersonnetworkSuccess, getNetworkPersonnetworkFailed,

    checkExistCompanySuccess, checkExistCompanyFailed,
    checkExistCompanyDomainsSuccess, checkExistCompanyDomainsFailed,
    createNetworkCompanySuccess, createNetworkCompanyFailed,
    editNetworkCompanySuccess, editNetworkCompanyFailed,
    deleteNetworkCompanySuccess, deleteNetworkCompanyFailed,
    createNetworkCompanyGoalSuccess, createNetworkCompanyGoalFailed,
    editNetworkCompanyGoalSuccess, editNetworkCompanyGoalFailed,
    deleteNetworkCompanyGoalSuccess, deleteNetworkCompanyGoalFailed,
    getNetworkCompanyExperiencesSuccess, getNetworkCompanyExperiencesFailed,

    getAllAcademicsFailed, getAllAcademicsSuccess,
    getAllCompanies2Failed, getAllCompanies2Success,
    getAllNetworkPersonsFailed, getAllNetworkPersonsSuccess,
    getNetworkAcademicExperiencesSuccess, getNetworkAcademicExperiencesFailed,
    checkExistAcademySuccess, checkExistAcademyFailed,
    checkExistAcademyDomainsSuccess, checkExistAcademyDomainsFailed,
    createNetworkAcademySuccess, createNetworkAcademyFailed,
    editNetworkAcademySuccess, editNetworkAcademyFailed,
    deleteNetworkAcademySuccess, deleteNetworkAcademyFailed,
    getNetworkAcademicsSuccess, getNetworkAcademicsFailed,
    createNetworkEventSuccess, createNetworkEventFailed,
    editNetworkEventSuccess, editNetworkEventFailed,
    deleteNetworkEventSuccess, deleteNetworkEventFailed,
    getNetworkEventsSuccess, getNetworkEventsFailed, getNetworkEvents,
    checkExistEventSuccess, checkExistEventFailed,
    createEducationSuccess, createEducationFailed,
    editEducationSuccess, editEducationFailed,
    deleteEducationSuccess, deleteEducationFailed,

    getPeopleByDomainSuccess, getPeopleByDomainFailed, getNetworkPeople,
    getNetworkAcademics, getNetworkCompanies,
    getEventGroupSuccess, getEventGroupFailed,

    getSignupRequestsSuccess, getSignupRequestsFailed, 
    getAllNetworkCompaniesSuccess, getAllNetworkCompaniesFailed, 
    getAllNetworkPeopleSuccess, 
    getAllNetworkAcademicsSuccess, getAllNetworkAcademicsFailed, 
    getAllNetworkEventsSuccess, getAllNetworkEventsFailed, 
    declineSignupRequestSuccess, declineSignupRequestFailed, 
    checkExistNetworkDomainSuccess, checkExistNetworkDomainFailed,

    addExistCompanyToNetworkSuccess, addExistCompanyToNetworkFailed,
    addExistAcademicToNetworkSuccess, addExistAcademicToNetworkFailed


} from './actions';
import {
    CREATE_CONTACT,
    EDIT_CONTACT,
    DELETE_CONTACT,
    GET_NETWORK,
    CREATE_INVITE,
    GET_NETWORK_PEOPLE,
    GET_NETWORK_COMPANIES,
    CHECK_EXIST_USERS,
    RESET_EXIST_USERS,
    GET_NETWORK_PERSON_INVITATIONS,
    GET_NETWORK_PERSON_VALIDATIONS,
    GET_NETWORK_PERSON_EXPERIENCES,
    GET_NETWORK_PERSON_NETWORK,
    CHECK_EXIST_COMPANY, CHECK_EXIST_COMPANY_DOMAINS,
    CREATE_NETWORK_COMPANY, EDIT_NETWORK_COMPANY, DELETE_NETWORK_COMPANY,
    CREATE_NETWORK_COMPANY_GOAL, EDIT_NETWORK_COMPANY_GOAL, DELETE_NETWORK_COMPANY_GOAL,
    GET_NETWORK_COMPANY_EXPERIENCES,

    CHECK_EXIST_ACADEMY, CHECK_EXIST_ACADEMY_DOMAINS,
    CREATE_NETWORK_ACADEMY, EDIT_NETWORK_ACADEMY, DELETE_NETWORK_ACADEMY, GET_NETWORK_ACADEMICS,
    CREATE_EDUCATION, EDIT_EDUCATION, DELETE_EDUCATION,
    CREATE_NETWORK_EVENT, EDIT_NETWORK_EVENT, DELETE_NETWORK_EVENT, GET_NETWORK_EVENTS, CHECK_EXIST_EVENT,
    GET_PEOPLE_BY_DOMAIN,
    GET_EVENT_GROUP,

    GET_SIGNUP_REQUESTS,
    GET_ALL_NETWORK_COMPANIES,
    GET_ALL_NETWORK_PEOPLE,
    GET_ALL_ACADEMICS,
    GET_ALL_COMPANIES2,
    GET_ALL_NETWORK_ACADEMICS,
    GET_ALL_NETWORK_EVENTS,
    DECLINE_SIGNUP_REQUEST,
    CHECK_EXIST_NETWORK_DOMAIN,
    GET_NETWORK_ACADEMIC_EXPERIENCES,

    ADD_EXIST_COMPANY_TO_NETWORK, 
    ADD_EXIST_ACADEMIC_TO_NETWORK,
    GET_ALL_NETWORK_PERSONS

} from './constants';
import { GET_ACADEMICS } from '../academics/constants';


function* checkExistNetworkDomainInternal({ payload: { domain, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/is-domain-in-used/" + domain, options);
        yield put(checkExistNetworkDomainSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistNetworkDomainFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createContactInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/add-contact", options);
        yield put(createContactSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createContactFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editContactInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let url = BASE_URL + "api/network/edit-contact/" + id + '/' + getLoggedInUser().id;
        let string = JSON.stringify(params);

        const response = yield call(fetchJSON, BASE_URL + "api/network/edit-contact/" + id + '/' + getLoggedInUser().id, options);
        yield put(editContactSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editContactFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteContactInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/delete-contact/" + id, options);
        yield put(deleteContactSuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteContactFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createInviteInternal({ payload: { params, contactId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/invite/" + contactId, options);
        yield put(createInviteSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createInviteFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/", options);
        yield put(getNetworkSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllNetworkPeopleInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/all-connected-people/", options);
        yield put(getAllNetworkPeopleSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllNetworkCompaniesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllNetworkPersonsInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/network/all-network-persons/?keyword=" + keyword ?? '', options);
        yield put(getAllNetworkPersonsSuccess(type, page, loadDetails, keyword, response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllNetworkPersonsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getNetworkPeopleInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/network/connected-people/" + type + "/" + page + "/" + loadDetails + '?keyword=' + keyword, options);
        yield put(getNetworkPeopleSuccess(type, page, loadDetails, keyword, response));
        if (!loadDetails) {
            yield put(getNetworkPeople(type, page, true, keyword, callbacks));
        }

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkPeopleFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllNetworkCompaniesInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/all-connected-companies/", options);
        yield put(getAllNetworkCompaniesSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllNetworkCompaniesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkCompaniesInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let details = keyword.length > 0 ? true : loadDetails;

        const response = yield call(fetchJSON, BASE_URL + "api/network/connected-companies/" + type + "/" + page + "/" + details + '?keyword=' + keyword, options);
        yield put(getNetworkCompaniesSuccess(type, page, loadDetails, keyword, response));

        if (!details) {
            yield put(getNetworkCompanies(type, page, true, keyword, callbacks));
        }

        console.log('************************* connected-companies');

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkCompaniesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistUsersInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/check-contact", options);
        yield put(checkExistUsersSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistUsersFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* resetExistUsersInternal({ payload: { callbacks } }) {
    const {
        callbackOnSuccess
    } = callbacks;

    yield put(resetExistUsersSuccess());
    if (callbackOnSuccess) {
        yield call(callbackOnSuccess);
    }
}

function* getNetworkPersonInvitationsInternal({ payload: { id, withMe, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        let path = BASE_URL + "api/network/invitation-status/" + id + (withMe ? "/true" : "/false");
        const response = yield call(fetchJSON, path, options);
        yield put(getNetworkPersonInvitationsSuccess(response, withMe));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkPersonInvitationsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkPersonValidationsInternal({ payload: { id, withMe, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/validations/validations/" + id + (withMe ? "/true" : "/false"), options);
        yield put(getNetworkPersonValidationsSuccess(response, withMe));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkPersonValidationsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkPersonNetworkInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/network-people/" + id, options);
        yield put(getNetworkPersonnetworkSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkPersonnetworkFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkPersonExperiencesInternal({ payload: { id, withMe, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/data-experiences/" + id + (withMe ? "/true" : "/false"), options);
        yield put(getNetworkPersonExperiencesSuccess(response, withMe));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkPersonExperiencesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistCompanyInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/is-existing-same-company-name", options);
        yield put(checkExistCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistCompanyDomainsInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/in-used-domains", options);
        yield put(checkExistCompanyDomainsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistCompanyDomainsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNetworkCompanyInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/create-network-company", options);
        yield put(createNetworkCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createNetworkCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNetworkCompanyInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/edit-network-company/" + id + '/' + getLoggedInUser().id, options);
        yield put(editNetworkCompanySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editNetworkCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNetworkCompanyInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/delete-network-company/" + id, options);
        yield put(deleteNetworkCompanySuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteNetworkCompanyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNetworkCompanyGoalInternal({ payload: { companyNodeId, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        // const response = yield call(fetchJSON, BASE_URL + "companies/" + companyNodeId + '/create-goal', options);
        const response = yield call(fetchJSON, BASE_URL + "api/academics/" + companyNodeId + '/create-goal', options);
        yield put(createNetworkCompanyGoalSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createNetworkCompanyGoalFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNetworkCompanyGoalInternal({ payload: { goalId, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "companies/edit-goal/" + goalId, options);
        yield put(editNetworkCompanyGoalSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editNetworkCompanyGoalFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNetworkCompanyGoalInternal({ payload: { goalId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "companies/delete-goal/" + goalId, options);
        yield put(deleteNetworkCompanyGoalSuccess(goalId));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteNetworkCompanyGoalFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkCompanyExperiencesInternal({ payload: { companyId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/experiences/get-experiences-by-user-and-company/" + companyId, options);
        yield put(getNetworkCompanyExperiencesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkCompanyExperiencesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkAcademicExperiencesInternal({ payload: { academicId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/experiences/get-experiences-by-user-and-academy/" + academicId, options);
        yield put(getNetworkAcademicExperiencesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkAcademicExperiencesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistAcademyInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/is-existing-same-academy-name", options);
        yield put(checkExistAcademySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistAcademyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistAcademyDomainsInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/in-used-academy-domains", options);
        yield put(checkExistAcademyDomainsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistAcademyDomainsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNetworkAcademyInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/create-network-academy", options);
        yield put(createNetworkAcademySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createNetworkAcademyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNetworkAcademyInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/edit-network-academy/" + id + '/' + getLoggedInUser().id, options);
        yield put(editNetworkAcademySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editNetworkAcademyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNetworkAcademyInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/delete-network-academy/" + id, options);
        yield put(deleteNetworkAcademySuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteNetworkAcademyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllNetworkAcademicsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/all-connected-academics/", options);
        yield put(getAllNetworkAcademicsSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllNetworkAcademicsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkAcademicsInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/connected-academics/" + type + "/" + page + "/" + loadDetails + '?keyword=' + keyword, options);
        yield put(getNetworkAcademicsSuccess(type, page, loadDetails, keyword, response));

        if (!loadDetails) {
            yield put(getNetworkAcademics(type, page, true, keyword, callbacks));
        }

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkAcademicsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllAcademicsInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/network/all-academics/?keyword=" + keyword ?? '', options);
        yield put(getAllAcademicsSuccess(type, page, loadDetails, keyword, response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllAcademicsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllCompanies2Internal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/network/all-companies/?keyword=" + keyword ?? '', options);
        yield put(getAllCompanies2Success(type, page, loadDetails, keyword, response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllCompanies2Failed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createEducationInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/create-education", options);
        yield put(createEducationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createEducationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editEducationInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/edit-education", options);
        yield put(editEducationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editEducationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteEducationInternal({ payload: { academyId, nodeId, placeId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/delete-education/" + academyId + '/' + nodeId + '/' + placeId, options);
        yield put(deleteEducationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteEducationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPeopleByDomainInternal({ payload: { domain, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/people-by-domain" + domain, options);
        yield put(getPeopleByDomainSuccess({ domain: domain, people: response }));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, { domain: domain, people: response });
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getPeopleByDomainFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

// Network events
function* createNetworkEventInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/create-network-event", options);
        yield put(createNetworkEventSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createNetworkEventFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNetworkEventInternal({ payload: { id, params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'PATCH',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/edit-network-event/" + id, options);
        yield put(editNetworkEventSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(editNetworkEventFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNetworkEventInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/delete-network-event/" + id, options);
        yield put(deleteNetworkEventSuccess(id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteNetworkEventFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAllNetworkEventsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/all-connected-events/", options);
        yield put(getAllNetworkEventsSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAllNetworkEventsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNetworkEventsInternal({ payload: { type, page, loadDetails, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/connected-events/" + type + "/" + page + "/" + loadDetails + '?keyword=' + keyword, options);
        yield put(getNetworkEventsSuccess(type, page, loadDetails, keyword, response));

        if (!loadDetails) {
            yield put(getNetworkEvents(type, page, true, keyword, callbacks));
        }

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getNetworkEventsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* checkExistEventInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/is-existing-same-event-name", options);
        yield put(checkExistEventSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(checkExistEventFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getEventGroupInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/event-groups", options);
        yield put(getEventGroupSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getEventGroupFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getSignupRequestsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/signup-requests", options);
        yield put(getSignupRequestsSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getSignupRequestsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* declineSignupRequestInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/decline-request/" + id, options);
        yield put(declineSignupRequestSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(declineSignupRequestFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* addExistCompanyToNetworkInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/add-existing-company-to-network/" + id, options);
        yield put(addExistCompanyToNetworkSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(addExistCompanyToNetworkFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* addExistAcademicToNetworkInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/network/add-existing-academy-to-network/" + id, options);
        yield put(addExistAcademicToNetworkSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(addExistAcademicToNetworkFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchCheckExistNetworkDomain() {
    yield takeEvery(CHECK_EXIST_NETWORK_DOMAIN, checkExistNetworkDomainInternal);
}

export function* watchCreateContact() {
    yield takeEvery(CREATE_CONTACT, createContactInternal);
}

export function* watchEditContact() {
    yield takeEvery(EDIT_CONTACT, editContactInternal);
}

export function* watchDeleteContact() {
    yield takeEvery(DELETE_CONTACT, deleteContactInternal);
}

export function* watchGetNetwork() {
    yield takeEvery(GET_NETWORK, getNetworkInternal);
}

export function* watchGetAllNetworkPersons() {
    yield takeEvery(GET_ALL_NETWORK_PERSONS, getAllNetworkPersonsInternal);
}

export function* watchGetAllAcademics() {
    yield takeEvery(GET_ALL_ACADEMICS, getAllAcademicsInternal);
}

export function* watchGetAllCompanies2() {
    yield takeEvery(GET_ALL_COMPANIES2, getAllCompanies2Internal);
}

export function* watchGetAllNetworkPeople() {
    yield takeEvery(GET_ALL_NETWORK_PEOPLE, getAllNetworkPeopleInternal);
}

export function* watchGetNetworkPeople() {
    yield takeEvery(GET_NETWORK_PEOPLE, getNetworkPeopleInternal);
}

export function* watchGetAllNetworkCompanies() {
    yield takeEvery(GET_ALL_NETWORK_COMPANIES, getAllNetworkCompaniesInternal);
}

export function* watchGetNetworkCompanies() {
    yield takeEvery(GET_NETWORK_COMPANIES, getNetworkCompaniesInternal);
}

export function* watchCreateInvite() {
    yield takeEvery(CREATE_INVITE, createInviteInternal);
}

export function* watchCheckExistUsers() {
    yield takeEvery(CHECK_EXIST_USERS, checkExistUsersInternal);
}

export function* watchResetExistUsers() {
    yield takeEvery(RESET_EXIST_USERS, resetExistUsersInternal);
}

export function* watchGetNetworkPersonInvitations() {
    yield takeEvery(GET_NETWORK_PERSON_INVITATIONS, getNetworkPersonInvitationsInternal);
}

export function* watchGetNetworkPersonValidations() {
    yield takeEvery(GET_NETWORK_PERSON_VALIDATIONS, getNetworkPersonValidationsInternal);
}

export function* watchGetNetworkPersonNetwork() {
    yield takeEvery(GET_NETWORK_PERSON_NETWORK, getNetworkPersonNetworkInternal);
}

export function* watchGetNetworkPersonExperiences() {
    yield takeEvery(GET_NETWORK_PERSON_EXPERIENCES, getNetworkPersonExperiencesInternal);
}

export function* watchGetAllNetworkAcademics() {
    yield takeEvery(GET_ALL_NETWORK_ACADEMICS, getAllNetworkAcademicsInternal);
}

export function* watchGetNetworkAcademics() {
    yield takeEvery(GET_NETWORK_ACADEMICS, getNetworkAcademicsInternal);
}

export function* watchCheckExistCompany() {
    yield takeEvery(CHECK_EXIST_COMPANY, checkExistCompanyInternal);
}

export function* watchcheckExistCompanyDomains() {
    yield takeEvery(CHECK_EXIST_COMPANY_DOMAINS, checkExistCompanyDomainsInternal);
}

export function* watchCreateNetworkCompany() {
    yield takeEvery(CREATE_NETWORK_COMPANY, createNetworkCompanyInternal);
}

export function* watchEditNetworkCompany() {
    yield takeEvery(EDIT_NETWORK_COMPANY, editNetworkCompanyInternal);
}

export function* watchDeleteNetworkCompany() {
    yield takeEvery(DELETE_NETWORK_COMPANY, deleteNetworkCompanyInternal);
}

export function* watchCreateNetworkCompanyGoal() {
    yield takeEvery(CREATE_NETWORK_COMPANY_GOAL, createNetworkCompanyGoalInternal);
}

export function* watchEditNetworkCompanyGoal() {
    yield takeEvery(EDIT_NETWORK_COMPANY_GOAL, editNetworkCompanyGoalInternal);
}

export function* watchDeleteNetworkCompanyGoal() {
    yield takeEvery(DELETE_NETWORK_COMPANY_GOAL, deleteNetworkCompanyGoalInternal);
}

export function* watchGetNetworkCompanyExperiences() {
    yield takeEvery(GET_NETWORK_COMPANY_EXPERIENCES, getNetworkCompanyExperiencesInternal);
}

export function* watchGetNetworkAcademicExperiences() {
    yield takeEvery(GET_NETWORK_ACADEMIC_EXPERIENCES, getNetworkAcademicExperiencesInternal);
}

export function* watchCheckExistAcademy() {
    yield takeEvery(CHECK_EXIST_ACADEMY, checkExistAcademyInternal);
}

export function* watchcheckExistAcademyDomains() {
    yield takeEvery(CHECK_EXIST_ACADEMY_DOMAINS, checkExistAcademyDomainsInternal);
}

export function* watchCreateNetworkAcademy() {
    yield takeEvery(CREATE_NETWORK_ACADEMY, createNetworkAcademyInternal);
}

export function* watchEditNetworkAcademy() {
    yield takeEvery(EDIT_NETWORK_ACADEMY, editNetworkAcademyInternal);
}

export function* watchDeleteNetworkAcademy() {
    yield takeEvery(DELETE_NETWORK_ACADEMY, deleteNetworkAcademyInternal);
}

export function* watchCreateEducation() {
    yield takeEvery(CREATE_EDUCATION, createEducationInternal);
}
export function* watchEditEducation() {
    yield takeEvery(EDIT_EDUCATION, editEducationInternal);
}
export function* watchDeleteEducation() {
    yield takeEvery(DELETE_EDUCATION, deleteEducationInternal);
}

export function* watchGetPeopleByDomain() {
    yield takeEvery(GET_PEOPLE_BY_DOMAIN, getPeopleByDomainInternal);
}

// Network events
export function* watchGetAllNetworkEvents() {
    yield takeEvery(GET_ALL_NETWORK_EVENTS, getAllNetworkEventsInternal);
}

export function* watchGetNetworkEvents() {
    yield takeEvery(GET_NETWORK_EVENTS, getNetworkEventsInternal);
}

export function* watchCreateNetworkEvent() {
    yield takeEvery(CREATE_NETWORK_EVENT, createNetworkEventInternal);
}

export function* watchEditNetworkEvent() {
    yield takeEvery(EDIT_NETWORK_EVENT, editNetworkEventInternal);
}

export function* watchDeleteNetworkEvent() {
    yield takeEvery(DELETE_NETWORK_EVENT, deleteNetworkEventInternal);
}

export function* watchCheckExistEvent() {
    yield takeEvery(CHECK_EXIST_EVENT, checkExistEventInternal);
}

export function* watchGetEventGroup() {
    yield takeEvery(GET_EVENT_GROUP, getEventGroupInternal);
}

export function* watchGetSignupRequests() {
    yield takeEvery(GET_SIGNUP_REQUESTS, getSignupRequestsInternal);
}

export function* watchDeclineSignupRequest() {
    yield takeEvery(DECLINE_SIGNUP_REQUEST, declineSignupRequestInternal);
}

export function* watchAddExistCompanyToNetwork() {
    yield takeEvery(ADD_EXIST_COMPANY_TO_NETWORK, addExistCompanyToNetworkInternal);
}

export function* watchAddExistAcademicToNetwork() {
    yield takeEvery(ADD_EXIST_ACADEMIC_TO_NETWORK, addExistAcademicToNetworkInternal);
}

function* networkSaga() {
    yield all([
        fork(watchCheckExistNetworkDomain),
        fork(watchCreateContact), fork(watchEditContact), fork(watchDeleteContact),
        fork(watchCreateInvite), fork(watchGetNetwork), fork(watchGetAllNetworkPeople), fork(watchGetAllNetworkPersons),
        fork(watchGetAllAcademics),fork(watchGetAllCompanies2),fork(watchGetNetworkPeople), fork(watchGetAllNetworkCompanies), 
        fork(watchGetNetworkCompanies), fork(watchCheckExistUsers), fork(watchResetExistUsers),
        fork(watchGetNetworkPersonInvitations), fork(watchGetNetworkPersonValidations), fork(watchGetNetworkPersonExperiences), fork(watchGetNetworkPersonNetwork),
        fork(watchCheckExistCompany), fork(watchcheckExistCompanyDomains), fork(watchCreateNetworkCompany), fork(watchEditNetworkCompany), fork(watchDeleteNetworkCompany),
        fork(watchCreateNetworkCompanyGoal), fork(watchEditNetworkCompanyGoal), fork(watchDeleteNetworkCompanyGoal),
        fork(watchGetNetworkCompanyExperiences), fork(watchGetNetworkAcademicExperiences),
        fork(watchCheckExistAcademy), fork(watchcheckExistAcademyDomains), fork(watchCreateNetworkAcademy), fork(watchEditNetworkAcademy), fork(watchDeleteNetworkAcademy), fork(watchGetNetworkAcademics), fork(watchGetAllNetworkAcademics),
        fork(watchCreateEducation), fork(watchEditEducation), fork(watchDeleteEducation),
        fork(watchGetPeopleByDomain),
        fork(watchCreateNetworkEvent), fork(watchEditNetworkEvent), fork(watchDeleteNetworkEvent), fork(watchGetNetworkEvents), fork(watchGetAllNetworkEvents),
        fork(watchCheckExistEvent), fork(watchGetEventGroup), fork(watchGetSignupRequests), fork(watchDeclineSignupRequest),
        fork(watchAddExistCompanyToNetwork), fork(watchAddExistAcademicToNetwork)
    ]);
}

export default networkSaga;
