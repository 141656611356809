// @flow
import {
    GET_PRIVACYPOLICY,GET_PRIVACYPOLICY_FAILED,GET_PRIVACYPOLICY_SUCCESS,
    GET_TERMOFSERVICE,GET_TERMOFSERVICE_FAILED,GET_TERMOFSERVICE_SUCCESS,
    GET_CONTENT_HTML, GET_CONTENT_HTML_FAILED, GET_CONTENT_HTML_SUCCESS
} from './constants';

export const getPrivacyPolicy = (callbacks) => ({
    type: GET_PRIVACYPOLICY,
    payload: { callbacks },
});

export const getPrivacyPolicySuccess = (data) => ({
    type: GET_PRIVACYPOLICY_SUCCESS,
    payload: data,
});

export const getPrivacyPolicyFailed = (error) => ({
    type: GET_PRIVACYPOLICY_FAILED,
    payload: error,
});

export const getTermOfService = (callbacks) => ({
    type: GET_TERMOFSERVICE,
    payload: { callbacks },
});

export const getTermOfServiceSuccess = (data) => ({
    type: GET_TERMOFSERVICE_SUCCESS,
    payload: data,
});

export const getTermOfServiceFailed = (error) => ({
    type: GET_TERMOFSERVICE_FAILED,
    payload: error,
});

export const getContentHTML = (api, callbacks) => ({
    type: GET_CONTENT_HTML,
    payload: { api, callbacks },
});

export const getContentHTMLSuccess = (data) => ({
    type: GET_CONTENT_HTML_SUCCESS,
    payload: data,
});

export const getContentHTMLFailed = (error) => ({
    type: GET_CONTENT_HTML_FAILED,
    payload: error,
});
