import React, { Component } from 'react';
import { FileText } from 'react-feather';
import { withTranslation } from "react-i18next";
import { Label, Table, UncontrolledTooltip } from 'reactstrap';
import { isValidURL } from '../../../helpers/utils';

export const SUMMARY_ELEMENT_DOCUMENT_URL = 'summary_document_url'
export const SUMMARY_ELEMENT_URL = 'summary_url'

class SummaryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const summary = this.props.summary ?? {};
        const list = this.props.list ?? [];
        return <Table className="mb-0" striped>
            {this.props.summary &&
                <tbody>
                    {Object.keys(summary).length > 0 && Object.keys(summary).map((key, index) => {
                        const keyArray = !(typeof summary[key] === 'string');
                        return (
                            <tr key={key}>
                                <th style={{ 'width': '35%' }} scope="row">{key}</th>
                                {keyArray ?
                                    <td style={{ 'width': '65%' }}>{
                                        <div className='align-vertical'>
                                            {summary[key].map((element, index) => {
                                                const isURL = isValidURL(element);
                                                return (
                                                    <div key={index}>
                                                        {isURL &&
                                                            <div>
                                                                <FileText
                                                                    id="view-invoice"
                                                                    className="icon-dual center-horizon.self link-horver"
                                                                    onClick={() => {
                                                                        window.open(element);
                                                                    }}
                                                                />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    id='tooltip-1'
                                                                    target='view-invoice'>
                                                                    {this.props.t("experience.hover.invoice")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        }
                                                        {!isURL &&
                                                            <span className={index > 0 ? 'mt-2' : ''}>{element}</span>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }</td>
                                    :
                                    <td style={{ 'width': '65%' }}>{summary[key]}</td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            }
            {this.props.list &&
                <tbody>
                    {list.map((element, index) => {
                        return (
                            <tr key={element}>
                                <th scope="row">{element}</th>
                            </tr>
                        );
                    })}
                </tbody>
            }
        </Table>
    }
}

export default withTranslation('translations')(SummaryTable);
