// @flow
import {
    GET_NEXION,GET_NEXION_FAILED,GET_NEXION_SUCCESS,
    GET_NEXION_INFO,GET_NEXION_INFO_FAILED,GET_NEXION_INFO_SUCCESS,
    CREATE_NEXION_REQUEST, CREATE_NEXION_REQUEST_FAILED, CREATE_NEXION_REQUEST_SUCCESS,
    EDIT_NEXION_REQUEST, EDIT_NEXION_REQUEST_SUCCESS, EDIT_NEXION_REQUEST_FAILED,
    DELETE_NEXION_REQUEST, DELETE_NEXION_REQUEST_SUCCESS, DELETE_NEXION_REQUEST_FAILED,
    CREATE_NEXION_OFFER, CREATE_NEXION_OFFER_FAILED, CREATE_NEXION_OFFER_SUCCESS,
    EDIT_NEXION_OFFER, EDIT_NEXION_OFFER_SUCCESS, EDIT_NEXION_OFFER_FAILED,
    DELETE_NEXION_OFFER, DELETE_NEXION_OFFER_SUCCESS, DELETE_NEXION_OFFER_FAILED,
    GET_NEXION_HIERARCHY, GET_NEXION_HIERARCHY_SUCCESS, GET_NEXION_HIERARCHY_FAILED,
    GET_NEXION_TYPE, GET_NEXION_TYPE_SUCCESS, GET_NEXION_TYPE_FAILED,
    GET_NEXION_OFFER_KEYWORDS, GET_NEXION_OFFER_KEYWORDS_SUCCESS, GET_NEXION_OFFER_KEYWORDS_FAILED,
    CREATE_NEXION_OFFER_KEYWORDS, CREATE_NEXION_OFFER_KEYWORDS_SUCCESS, CREATE_NEXION_OFFER_KEYWORDS_FAILED,
} from './constants';

export const getNexions = (callbacks) => ({
    type: GET_NEXION,
    payload: { callbacks },
});

export const getNexionsSuccess = (data) => ({
    type: GET_NEXION_SUCCESS,
    payload: data,
});

export const getNexionsFailed = (error) => ({
    type: GET_NEXION_FAILED,
    payload: error,
});

export const getNexionsOfType = ( type, callbacks ) => ({
    type: GET_NEXION_TYPE,
    payload: { type, callbacks },
});

export const getNexionsOfTypeSuccess = (data) => ({
    type: GET_NEXION_TYPE_SUCCESS,
    payload: data,
});

export const getNexionsOfTypeFailed = (error) => ({
    type: GET_NEXION_TYPE_FAILED,
    payload: error,
});

export const getNexionInfo = (callbacks) => ({
    type: GET_NEXION_INFO,
    payload: { callbacks },
});

export const getNexionInfoSuccess = (data) => ({
    type: GET_NEXION_INFO_SUCCESS,
    payload: data,
});

export const getNexionInfoFailed = (error) => ({
    type: GET_NEXION_INFO_FAILED,
    payload: error,
});

export const createNexionRequest = (nexion, callbacks) => ({
    type: CREATE_NEXION_REQUEST,
    payload: { nexion, callbacks },
});

export const createNexionRequestSuccess = (data) => ({
    type: CREATE_NEXION_REQUEST_SUCCESS,
    payload: data,
});

export const createNexionRequestFailed = (error) => ({
    type: CREATE_NEXION_REQUEST_FAILED,
    payload: error,
});

export const editNexionRequest = (nexion, callbacks) => ({
    type: EDIT_NEXION_REQUEST,
    payload: { nexion, callbacks },
});

export const editNexionRequestSuccess = (data) => ({
    type: EDIT_NEXION_REQUEST_SUCCESS,
    payload: data,
});

export const editNexionRequestFailed = (error) => ({
    type: EDIT_NEXION_REQUEST_FAILED,
    payload: error,
});

export const deleteNexionRequest = (id, callbacks) => ({
    type: DELETE_NEXION_REQUEST,
    payload: { id, callbacks },
});

export const deleteNexionRequestSuccess = (data) => ({
    type: DELETE_NEXION_REQUEST_SUCCESS,
    payload: data,
});

export const deleteNexionRequestFailed = (error) => ({
    type: DELETE_NEXION_REQUEST_FAILED,
    payload: error,
});

export const createNexionOffer = (nexion, callbacks) => ({
    type: CREATE_NEXION_OFFER,
    payload: { nexion, callbacks },
});

export const createNexionOfferSuccess = (data) => ({
    type: CREATE_NEXION_OFFER_SUCCESS,
    payload: data,
});

export const createNexionOfferFailed = (error) => ({
    type: CREATE_NEXION_OFFER_FAILED,
    payload: error,
});

export const editNexionOffer = (nexion, callbacks) => ({
    type: EDIT_NEXION_OFFER,
    payload: { nexion, callbacks },
});

export const editNexionOfferSuccess = (data) => ({
    type: EDIT_NEXION_OFFER_SUCCESS,
    payload: data,
});

export const editNexionOfferFailed = (error) => ({
    type: EDIT_NEXION_OFFER_FAILED,
    payload: error,
});

export const deleteNexionOffer = (id, callbacks) => ({
    type: DELETE_NEXION_OFFER,
    payload: { id, callbacks },
});

export const deleteNexionOfferSuccess = (data) => ({
    type: DELETE_NEXION_OFFER_SUCCESS,
    payload: data,
});

export const deleteNexionOfferFailed = (error) => ({
    type: DELETE_NEXION_OFFER_FAILED,
    payload: error,
});

export const getNexionHierarchy = (id, callbacks) => ({
    type: GET_NEXION_HIERARCHY,
    payload: { id, callbacks },
});

export const getNexionHierarchySuccess = (data) => ({
    type: GET_NEXION_HIERARCHY_SUCCESS,
    payload: data,
});

export const getNexionHierarchyFailed = (error) => ({
    type: GET_NEXION_HIERARCHY_FAILED,
    payload: error,
});

export const getNexionOfferKeywords = (callbacks) => ({
    type: GET_NEXION_OFFER_KEYWORDS,
    payload: { callbacks },
});

export const getNexionOfferKeywordsSuccess = (data) => ({
    type: GET_NEXION_OFFER_KEYWORDS_SUCCESS,
    payload: data,
});

export const getNexionOfferKeywordsFailed = (error) => ({
    type: GET_NEXION_OFFER_KEYWORDS_FAILED,
    payload: error,
});

export const createNexionOfferKeywords = (data, callbacks) => ({
    type: CREATE_NEXION_OFFER_KEYWORDS,
    payload: { data, callbacks },
});

export const createNexionOfferKeywordsSuccess = (data) => ({
    type: CREATE_NEXION_OFFER_KEYWORDS_SUCCESS,
    payload: data,
});

export const createNexionOfferKeywordsFailed = (error) => ({
    type: CREATE_NEXION_OFFER_KEYWORDS_FAILED,
    payload: error,
});
