// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from './../constants';
import {
    addCommentFailed, addCommentSuccess, deleteCommentFailed, deleteCommentSuccess, getCommentsFailed, getCommentsSuccess, getRepositoriesFailed, getRepositoriesSuccess
} from './actions';
import {
    ADD_COMMENT, DELETE_COMMENT, GET_COMMENTS, GET_REPOSITORIES
} from './constants';

/**
 * Get repositories
 */
function* getRepositoriesInternal({ payload: { callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    const user = getLoggedInUser();
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            callbackOnBegin();
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/technology/data-technology', options);
        yield put(getRepositoriesSuccess(response));
        if (callbackOnSuccess) {
            callbackOnSuccess(response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getRepositoriesFailed(message));
        if (callbackOnFailure) {
            callbackOnFailure(error);
        }
    } finally {
        if (callbackOnFinish) {
            callbackOnFinish();
        }
    }
}


/**
 * Add comment
 */
function* addCommentInternal({ payload: { comment, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    const user = getLoggedInUser();
    const options = {
        method: 'POST',
        body: comment,
        headers: { authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            callbackOnBegin();
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/technology/add-comment', options);
        yield put(addCommentSuccess(response));
        if (callbackOnSuccess) {
            callbackOnSuccess(response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addCommentFailed(message));
        if (callbackOnFailure) {
            callbackOnFailure(error);
        }
    } finally {
        if (callbackOnFinish) {
            callbackOnFinish();
        }
    }
}


/**
 * Get comments
 */
function* getCommentsInternal({ payload: { query, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const { targetType, targetName } = query;

    const user = getLoggedInUser();
    const options = {
        body: { targetType, targetName },
        method: 'POST',
        headers: { authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            callbackOnBegin();
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/technology/comments' /*format('api/technology/comments?targetType={0}&targetName={1}', targetType, targetName)*/, options);
        yield put(getCommentsSuccess(response));
        if (callbackOnSuccess) {
            callbackOnSuccess(response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getCommentsFailed(message));
        if (callbackOnFailure) {
            callbackOnFailure(error);
        }
    } finally {
        if (callbackOnFinish) {
            callbackOnFinish();
        }
    }
}

/**
 * Delete comment
 */
function* deleteCommentInternal({ payload: { id, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const user = getLoggedInUser();
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            callbackOnBegin();
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/technology/delete-comment/' + id, options);
        yield put(deleteCommentSuccess(response));
        if (callbackOnSuccess) {
            callbackOnSuccess(response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteCommentFailed(message));
        if (callbackOnFailure) {
            callbackOnFailure(error);
        }
    } finally {
        if (callbackOnFinish) {
            callbackOnFinish();
        }
    }
}

export function* watchGetRepositories() {
    yield takeEvery(GET_REPOSITORIES, getRepositoriesInternal);
}

export function* watchAddComment() {
    yield takeEvery(ADD_COMMENT, addCommentInternal);
}

export function* watchGetComments() {
    yield takeEvery(GET_COMMENTS, getCommentsInternal);
}

export function* watchDeleteComments() {
    yield takeEvery(DELETE_COMMENT, deleteCommentInternal);
}

function* repositoriesSaga() {
    yield all([fork(watchGetRepositories), fork(watchAddComment), fork(watchGetComments),
    fork(watchDeleteComments)]);
}

export default repositoriesSaga;
