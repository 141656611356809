import React,{Component} from 'react';
import * as FeatherIcon from 'react-feather';
import {ChevronLeft} from 'react-feather';
import {withTranslation} from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import {connect} from 'react-redux';
import {Card,Col,Label,Row} from 'reactstrap';
import AppMenu from '../../components/AppMenu';
import {
    initMenuAndItemParams
} from '../../redux/actions';
import {getCurrentLanguage} from '../../helpers/utils';
import StaticMenu from '../../components/StaticMenu';




class TermsOfService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuItems: [],
            isPrivacyPolicyOpen:this.props.isPrivacyPolicyOpen?true:false,
            isTermOfServiceOpen:this.props.isTermOfServiceOpen?true:false
        };
    }

    componentDidMount() {
        this.setState({
            isPrivacyPolicyOpen:this.props.isPrivacyPolicyOpen?true:false,
            isTermOfServiceOpen:this.props.isTermOfServiceOpen?true:false,
            titleHerder:this.props.titleHerder?this.props.titleHerder:'',
            currentLanguage: getCurrentLanguage()
        });

        this.loadMenuItems();
    }

    componentDidUpdate = prevProps => {
        if ((prevProps.privacyPolicy !== this.props.privacyPolicy) || (prevProps.termsOfService !== this.props.termsOfService))
        {
            this.loadMenuItems();
        }
    };
    

    loadMenuItems = () => {
        const menuItems = [];

        if (this.props.privacyPolicy && this.props.privacyPolicy.length > 0) {
            let menu = {
                name: 'phoneInput.privacyPolicy',
                id: 0,
                children: [],
                onClick: this.onSubMenu
            }
            menuItems.push(menu);

            this.props.privacyPolicy.forEach((element, index) => {
                const child = { name: element.name, id: element.id, parentId: 0 };
                child.onClick = this.onSubMenu;
                menu.children.push(child);
            });
        }

        if (this.props.termsOfService && this.props.termsOfService.length > 0) {
            let menu = {
                name: 'phoneInput.termOfService',
                id: 1,
                children: [],
                onClick: this.onSubMenu
            }
            menuItems.push(menu);

            this.props.termsOfService.forEach((element, index) => {
                const child = { name: element.name, id: element.id, parentId: 1 };
                child.onClick = this.onSubMenu;
                menu.children.push(child);
            });
        }

        this.setState({menuItems});
    }

    onSubMenu = (id) => {
        // console.log('onSubMenu: ' + id);
        var element = null;

        if (id === 0) {
            this.props.getPrivacyPolicyContentHTML("privacyPolicy");
            var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.privacyPolicy");
            this.setState({
                titleHerder:titleHerder,
                menuId:id,
                isPrivacyPolicyOpen: true,
                isTermOfServiceOpen: false
            })
        }
        else if (id === 1) {
            this.props.getPrivacyPolicyContentHTML("termsOfService");
                var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.termOfService");
        
                this.setState({
                    titleHerder:titleHerder,
                    menuId:id,
                    isPrivacyPolicyOpen: false,
                    isTermOfServiceOpen: true
                })
        }
        else if (this.props.privacyPolicy) {
             element = this.props.privacyPolicy.find((op) => {
                return op.id === id;
            });
            if ((element !== null && typeof element !== 'undefined') ) {
                this.props.getPrivacyPolicyContentHTML("privacyPolicy/"+ id);
                var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.privacyPolicy") + " > " + this.props.t(element.name);
        
                this.setState({
                    titleHerder:titleHerder,
                    menuId:element.id,
                    isPrivacyPolicyOpen: true,
                    isTermOfServiceOpen: false
                })
            }
        }
        if ((element === null || typeof element === 'undefined')  && this.props.termsOfService) {
            element = this.props.termsOfService.find((op) => {
                return op.id === id;
            });
            if (element != null) {
                this.props.getPrivacyPolicyContentHTML("termsOfService/"+ id);
                var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.termOfService") + " > " + this.props.t(element.name);
        
                this.setState({
                    titleHerder:titleHerder,
                    menuId:element.id,
                    isPrivacyPolicyOpen: false,
                    isTermOfServiceOpen: true
                })
            }
        }
    };

    setTextTile=()=>{
        var titleHerder = ""
        if (this.state.menuId === 0) {
            this.props.getPrivacyPolicyContentHTML("privacyPolicy");
            var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.privacyPolicy");           
        }
        else if (this.state.menuId === 1) {
            this.props.getPrivacyPolicyContentHTML("termsOfService");
            var titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.termOfService");
        }
       else if(this.state.isTermOfServiceOpen){
            this.props.getPrivacyPolicyContentHTML(this.state.menuId?("termsOfService/"+this.state.menuId):"termsOfService");
            titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.termOfService") + " > " + this.props.t(this.props.termsOfService[0].name);
        } else if(this.state.isPrivacyPolicyOpen){
            this.props.getPrivacyPolicyContentHTML(this.state.menuId?("privacyPolicy/"+this.state.menuId):"privacyPolicy");
            titleHerder = this.props.t("policy.documents") + " > " + this.props.t("phoneInput.privacyPolicy") + " > " + this.props.t(this.props.privacyPolicy[0].name);
        }
        
            this.setState({
                titleHerder:titleHerder,
                currentLanguage: getCurrentLanguage()
            });
    }

    togglePrivacyPolicy=()=>{
        var isPrivacyPolicyOpen = this.state.isPrivacyPolicyOpen;
        this.setState({
            isPrivacyPolicyOpen: !isPrivacyPolicyOpen
        })
    }

    toggleTermOfService=()=>{
        var isTermOfServiceOpen = this.state.isTermOfServiceOpen;
        
        this.setState({
            isTermOfServiceOpen: !isTermOfServiceOpen
        })
    }

    htmlContent = () => {
        var stringhtml = this.props.stringContentHTML;//"<!doctype html><html><head><title>Datanexions Privacy Policy</title><meta name=\"description\" content=\"Privacy Policy\"><meta name=\"keywords\" content=\"documentation\"></head><body>Datanexions Privacy Policy</body></html>"
    return {
        __html: stringhtml
    }
}

    render() {
        const { t } = this.props;
        
        if (this.state.currentLanguage !== getCurrentLanguage()) {
            this.setTextTile();
        }
        return (
            <React.Fragment>

            <Card className="mt-5">
                <div className='account-pages my-5'>
                    <Row >
                    
                        <Col >
                                <Row >
                                    <div className="tab-left-privacy-policy-title" onClick={this.props.onBack}>
                                            <ChevronLeft className="icon-dual ml-2 mb-1" size={22} />
                                            <Label className="title-left-small-label" color="dark" for="backbutton">{t("terms.back")}</Label>
                                    </div>
                                    <div className="tab-right-privacy-policy-title">
                                        <Label className="title-left-small-label float-left">
                                        {this.state.titleHerder? this.state.titleHerder: t("policy.documents")}
                                        </Label>
                                    </div>
                                </Row>
                           

                        </Col>
                    </Row>

                    <Row>
                        
                            <div className="tab-left-privacy-policy-content-border">
                            
                            <div id="sidebar-menu">
                                <StaticMenu menu={{menuItems: this.state.menuItems}}
                                    activatedKeys={this.props.activatedKeys}
                                />
                            </div>
                            </div>
                        
                        
                            <div className="tab-right-privacy-policy-content-border">
                                <div dangerouslySetInnerHTML={this.htmlContent()} />
                            </div>
                        
                    </Row>
                </div>
                </Card>
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    const { profile, cvs, photos, loading, error } = state.Profile;
    const { countries } = state.Auth;
    return { countries, profile, cvs, photos, loading, error };
};

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps) (withTranslation('translations')(TermsOfService));
