
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
import styled, { css, createGlobalStyle } from 'styled-components';

const DatePickerWrapperStyles = createGlobalStyle`
     .react-datepicker {
         background-color: ${'#ffffff'};
         color: ${'#404040'};
         border: ${'1px'} solid ${'#e2e7f1'};
     }
     .react-datepicker__header {
         background-color: #e2e7f1;
         color: ${'#000'};
         border-bottom: 1px solid #aeaeae;
     }
     .react-datepicker__day:hover,
     .react-datepicker__month-text:hover,
     .react-datepicker__quarter-text:hover,
     .react-datepicker__year-text:hover {
         border-radius: 0.3rem;
         background-color: #e2e7f1;
     }
 `;

const DatePickerWrapperDarkStyles = createGlobalStyle`
    .react-datepicker {
        background-color: ${'#323742'};
        color: ${'#bcc1cc'};
        border: 1px solid ${'#3b414e'};
    }
    .react-datepicker__header {
        background-color: #3b414e;
        color: ${'#ffffff'};
        border-bottom: 1px solid #aeaeae;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 0.3rem;
        background-color: #3b414e;
    }

    .react-datepicker__month {
        margin: 0.4rem;
        text-align: center;
        color: ${'#bcc1cc'};
      }


.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
  color: ${'#bcc1cc'};
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: ${'#bcc1cc'};
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: ${'#bcc1cc'};
  font-weight: bold;
  font-size: 0.944rem;
}

`;

export { DatePickerWrapperStyles, DatePickerWrapperDarkStyles };

