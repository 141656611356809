// @flow
import { data } from 'jquery';
import { LOGOUT_USER } from '../auth/constants';
import {
    SEARCH_VERIFIER, SEARCH_VERIFIER_SUCCESS, SEARCH_VERIFIER_FAILED,
    GET_EXTERNAL_VALIDATION, GET_EXTERNAL_VALIDATION_SUCCESS, GET_EXTERNAL_VALIDATION_FAILED,
    UPDATE_EXTERNAL_VALIDATION, UPDATE_EXTERNAL_VALIDATION_SUCCESS, UPDATE_EXTERNAL_VALIDATION_FAILED,
    UPDATE_VALIDATION, UPDATE_VALIDATION_SUCCESS, UPDATE_VALIDATION_FAILED,
    CREATE_VALIDATION, CREATE_VALIDATION_SUCCESS, CREATE_VALIDATION_FAILED,
    EDIT_VALIDATION, EDIT_VALIDATION_SUCCESS, EDIT_VALIDATION_FAILED,
    DELETE_VALIDATION, DELETE_VALIDATION_SUCCESS, DELETE_VALIDATION_FAILED,
    GET_VALIDATIONS, GET_VALIDATIONS_SUCCESS, GET_VALIDATIONS_FAILED,
    GET_VALIDATIONS_SUPERSET_SUCCESS, GET_VALIDATIONS_SUPERSET_FAILED, VALIDATION_TYPE_IN,
    GET_VALIDATION_GRAPH_DATA, GET_VALIDATION_GRAPH_DATA_SUCCESS, GET_VALIDATION_GRAPH_DATA_FAILED

} from './constants';

const INIT_STATE = {
    loading: false,
    externalValidation: null,
    chart: null,
    validationsIn: null,
    validationsOut: null,
    validationGraphData: null,
    error: null
};

const Validation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_VERIFIER:
            return { ...state, loading: true };
        case SEARCH_VERIFIER_SUCCESS:
            return { ...state, loading: false, error: null };
        case SEARCH_VERIFIER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EXTERNAL_VALIDATION:
            return { ...state, loading: true };
        case GET_EXTERNAL_VALIDATION_SUCCESS:
            return { ...state, externalValidation: action.payload.data, loading: false, error: null };
        case GET_EXTERNAL_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_EXTERNAL_VALIDATION:
            return { ...state, loading: true };
        case UPDATE_EXTERNAL_VALIDATION_SUCCESS:
            return { ...state, loading: false, error: null };
        case UPDATE_EXTERNAL_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_VALIDATION:
            return { ...state, loading: true };
        case UPDATE_VALIDATION_SUCCESS:
            return { ...state, loading: false, error: null };
        case UPDATE_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };


        case CREATE_VALIDATION:
            return { ...state, loading: true };
        case CREATE_VALIDATION_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_VALIDATION:
            return { ...state, loading: true };
        case EDIT_VALIDATION_SUCCESS:
            {
                let validationsOut = state.validationsOut;
                if (validationsOut) {
                    let index = validationsOut.findIndex((element) => { return element.id == action.payload.data.id })
                    if (index >= 0) {
                        validationsOut[index] = action.payload.data;
                    }
                }
                return { ...state, validationsOut: validationsOut, loading: false, error: null };
            }
        case EDIT_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_VALIDATION:
            return { ...state, loading: true };
        case DELETE_VALIDATION_SUCCESS:
            {
                let validationsOut = state.validationsOut;
                if (validationsOut) {
                    let index = validationsOut.findIndex((element) => { return element.id == action.payload.data })
                    if (index >= 0) {
                        validationsOut.splice(index, 1);
                    }
                }
                return { ...state, validationsOut: validationsOut, loading: false, error: null };
            }
        case DELETE_VALIDATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_VALIDATIONS:
            return { ...state, loading: true };
        case GET_VALIDATIONS_SUCCESS:
            if (action.payload.type == VALIDATION_TYPE_IN) {
                return { ...state, validationsIn: action.payload.data, loading: false, error: null };
            }
            else {
                return { ...state, validationsOut: action.payload.data, loading: false, error: null };
            }
        case GET_VALIDATIONS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_VALIDATIONS_SUPERSET_SUCCESS:
            return { ...state, chart: action.payload.data, loading: false, error: null };
        case GET_VALIDATIONS_SUPERSET_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        case GET_VALIDATION_GRAPH_DATA:
            return { ...state, loading: true };
        case GET_VALIDATION_GRAPH_DATA_SUCCESS:
            return { ...state, validationGraphData: action.payload.response, loading: false, error: null };
        case GET_VALIDATION_GRAPH_DATA_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Validation;
