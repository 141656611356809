// @flow
import { getLoggedInUser } from '../../helpers/authUtils';
import {
    CHECK_PHONENUMBERINUSED, CHECK_PHONENUMBERINUSED_FAILED, CHECK_PHONENUMBERINUSED_SUCCESS, FORGET_PASSWORD,
    FORGET_PASSWORD_FAILED, FORGET_PASSWORD_SUCCESS, GET_API_COUNTRY_INFO, GET_API_COUNTRY_INFO_FAILED,
    GET_API_COUNTRY_INFO_SUCCESS, GET_COUNTRY_INFO, GET_COUNTRY_INFO_FAILED, GET_COUNTRY_INFO_SUCCESS, LOGIN_USER, LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESS, LOGOUT_USER, NEW_PASSWORD, NEW_PASSWORD_FAILED, NEW_PASSWORD_SUCCESS, REGISTER_USER, REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
    REQUEST_SIGNUP, REQUEST_SIGNUP_SUCCESS, REQUEST_SIGNUP_FAILED,
    SAVE_NEXT_PATH, SAVE_NEXT_PATH_SUCCESS, SAVE_NEXT_PATH_FAILED
} from './constants';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    error: null,
    country: null,
    countries: [],
    passwordResetRequested: '',
    passwordReset: false,
    nextPath: ''
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SAVE_NEXT_PATH:
            return { ...state, loading: true };
        case SAVE_NEXT_PATH_SUCCESS:
            return { ...state, nextPath: action.payload, loading: false, error: null };
        case SAVE_NEXT_PATH_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetRequested: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_COUNTRY_INFO:
            return { ...state, loading: true };
        case GET_COUNTRY_INFO_SUCCESS:
            return { ...state, country: action.payload, loading: false, error: null };
        case GET_COUNTRY_INFO_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_API_COUNTRY_INFO:
            return { ...state, loading: true };
        case GET_API_COUNTRY_INFO_SUCCESS:
            return { ...state, countries: action.payload.countries, loading: false, error: null };
        case GET_API_COUNTRY_INFO_FAILED:
            return { ...state, error: action.payload, loading: false };

        case NEW_PASSWORD:
            return { ...state, loading: true };
        case NEW_PASSWORD_SUCCESS:
            return { ...state, passwordReset: true, loading: false, error: null };
        case NEW_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CHECK_PHONENUMBERINUSED:
            return { ...state, loading: true };
        case CHECK_PHONENUMBERINUSED_SUCCESS:
            return { ...state, loading: false, error: null };
        case CHECK_PHONENUMBERINUSED_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REQUEST_SIGNUP:
            return { ...state, loading: true };
        case REQUEST_SIGNUP_SUCCESS:
            return { ...state, loading: false, error: null };
        case REQUEST_SIGNUP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return { ...state };
    }
};

export default Auth;
