// @flow
import {
    GET_EVENT,GET_EVENT_FAILED,GET_EVENT_SUCCESS,
    EDIT_EVENT_ATTENDANCY,EDIT_EVENT_ATTENDANCY_FAILED,EDIT_EVENT_ATTENDANCY_SUCCESS,
    CREATE_EVENT_ATTENDANCY, CREATE_EVENT_ATTENDANCY_FAILED, CREATE_EVENT_ATTENDANCY_SUCCESS,
    DELETE_EVENT_ATTENDANCY, DELETE_EVENT_ATTENDANCY_FAILED, DELETE_EVENT_ATTENDANCY_SUCCESS,
    GET_EVENT_BY_TYPE_ID, GET_EVENT_BY_TYPE_ID_FAILED, GET_EVENT_BY_TYPE_ID_SUCCESS,
    GET_EVENT_BY_ID, GET_EVENT_BY_ID_FAILED, GET_EVENT_BY_ID_SUCCESS
} from './constants';

export const getEvent = (callbacks) => ({
    type: GET_EVENT,
    payload: { callbacks },
});

export const getEventSuccess = (data) => ({
    type: GET_EVENT_SUCCESS,
    payload: data,
});

export const getEventFailed = (error) => ({
    type: GET_EVENT_FAILED,
    payload: error,
});

export const getEventById = (id, callbacks) => ({
    type: GET_EVENT_BY_ID,
    payload: { id, callbacks },
});

export const getEventByIdSuccess = (data) => ({
    type: GET_EVENT_BY_ID_SUCCESS,
    payload: data,
});

export const getEventByIdFailed = (error) => ({
    type: GET_EVENT_BY_ID_FAILED,
    payload: error,
});

export const getEventByType = (idType, callbacks) => ({
    type: GET_EVENT_BY_TYPE_ID,
    payload: { idType, callbacks },
});

export const getEventByTypeSuccess = (data) => ({
    type: GET_EVENT_BY_TYPE_ID_SUCCESS,
    payload: data,
});

export const getEventByTypeFailed = (error) => ({
    type: GET_EVENT_BY_TYPE_ID_FAILED,
    payload: error,
});

export const editEventAtendancy = ( id, params, callbacks) => ({
    type: EDIT_EVENT_ATTENDANCY,
    payload: { id, params, callbacks },
});

export const editEventAtendancySuccess = (data) => ({
    type: EDIT_EVENT_ATTENDANCY_SUCCESS,
    payload: data,
});

export const editEventAtendancyFailed = (error) => ({
    type: EDIT_EVENT_ATTENDANCY_FAILED,
    payload: error,
});

export const deleteEventAttendancy = ( id, callbacks) => ({
    type: DELETE_EVENT_ATTENDANCY,
    payload: { id, callbacks },
});

export const deleteEventAttendancySuccess = (data) => ({
    type: DELETE_EVENT_ATTENDANCY_SUCCESS,
    payload: data,
});

export const deleteEventAttendancyFailed = (error) => ({
    type: DELETE_EVENT_ATTENDANCY_FAILED,
    payload: error,
});

export const createEventAttendancy = (data, callbacks) => ({
    type: CREATE_EVENT_ATTENDANCY,
    payload: { data, callbacks },
});

export const createEventAttendancySuccess = (data) => ({
    type: CREATE_EVENT_ATTENDANCY_SUCCESS,
    payload: data,
});

export const createEventAttendancyFailed = (error) => ({
    type: CREATE_EVENT_ATTENDANCY_FAILED,
    payload: error,
});

