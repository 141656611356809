// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {getLoggedInUser} from '../../helpers/authUtils';
import {BASE_URL} from './../constants';
import {getNexionsSuccess, getNexionsFailed, getNexionInfoFailed, getNexionInfoSuccess,
createNexionRequestFailed, createNexionRequestSuccess, 
editNexionRequestSuccess, editNexionRequestFailed,
deleteNexionRequestSuccess, deleteNexionRequestFailed,
createNexionOfferSuccess, createNexionOfferFailed,
editNexionOfferSuccess, editNexionOfferFailed,
deleteNexionOfferSuccess, deleteNexionOfferFailed,
getNexionsOfTypeFailed, getNexionsOfTypeSuccess,
getNexionHierarchySuccess, getNexionHierarchyFailed,
getNexionOfferKeywordsSuccess, getNexionOfferKeywordsFailed,
createNexionOfferKeywordsSuccess, createNexionOfferKeywordsFailed
} from './actions';
import {
    GET_NEXION, GET_NEXION_INFO, CREATE_NEXION_REQUEST, EDIT_NEXION_REQUEST, DELETE_NEXION_REQUEST,
    GET_NEXION_HIERARCHY, 
    GET_NEXION_TYPE, CREATE_NEXION_OFFER, EDIT_NEXION_OFFER, DELETE_NEXION_OFFER,
    GET_NEXION_OFFER_KEYWORDS, CREATE_NEXION_OFFER_KEYWORDS,
} from './constants';

function* getNexionsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/", options);
        yield put(getNexionsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getNexionsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNexionsOfTypeInternal({ payload: { type, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/nexions/" + type, options);
        yield put(getNexionsOfTypeSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getNexionsOfTypeFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNexionInfoInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/nexion-info", options);
        yield put(getNexionInfoSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getNexionInfoFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNexionRequestInternal({ payload: { nexion, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const json = JSON.stringify(nexion);
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: json
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/create-nexion-request", options);
        yield put(createNexionRequestSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        console.log('code = ' + error.status)
        console.log('message = ' + message)

        yield put(createNexionRequestFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNexionRequestInternal({ payload: { nexion, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const json = JSON.stringify(nexion);
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: json
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/edit-nexion-request", options);
        yield put(editNexionRequestSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        console.log('code = ' + error.status)
        console.log('message = ' + message)

        yield put(editNexionRequestFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNexionRequestInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/delete-nexion-request/" + id, options);
        yield put(deleteNexionRequestSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        console.log('code = ' + error.status)
        console.log('message = ' + message)

        yield put(deleteNexionRequestFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNexionOfferInternal({ payload: { nexion, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: nexion
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/create-nexion-offer", options);
        yield put(createNexionOfferSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createNexionOfferFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* editNexionOfferInternal({ payload: { nexion, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: nexion
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/edit-nexion-offer", options);
        yield put(editNexionOfferSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(editNexionOfferFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteNexionOfferInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/delete-nexion-offer/" + id, options);
        yield put(deleteNexionOfferSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(deleteNexionOfferFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getNexionHierarchyInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/nexion-context-hierarchy/" + id, options);
        yield put(getNexionHierarchySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getNexionHierarchyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getNexionOfferKeywordsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/get-offer-keywords", options);
        yield put(getNexionOfferKeywordsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(getNexionOfferKeywordsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createNexionOfferKeywordsInternal({ payload: { data, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    
    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token,'Content-Type': 'application/json' },
        body: data
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        
        const response = yield call(fetchJSON, BASE_URL + "api/nexions/create-nexion-offer-keywords", options);
        yield put(createNexionOfferKeywordsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
       
        yield put(createNexionOfferKeywordsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchCreateNexionOfferKeywords() {
    yield takeEvery(CREATE_NEXION_OFFER_KEYWORDS, createNexionOfferKeywordsInternal);
}

export function* watchGetNexionOfferKeywords() {
    yield takeEvery(GET_NEXION_OFFER_KEYWORDS, getNexionOfferKeywordsInternal);
}

export function* watchGetNexions() {
    yield takeEvery(GET_NEXION, getNexionsInternal);
}

export function* watchGetNexionsOfType() {
    yield takeEvery(GET_NEXION_TYPE, getNexionsOfTypeInternal);
}

export function* watchGetNexionInfo() {
    yield takeEvery(GET_NEXION_INFO, getNexionInfoInternal);
}

export function* watchCreateNexionRequest() {
    yield takeEvery(CREATE_NEXION_REQUEST, createNexionRequestInternal);
}

export function* watchEditNexionRequest() {
    yield takeEvery(EDIT_NEXION_REQUEST, editNexionRequestInternal);
}

export function* watchDeleteNexionRequest() {
    yield takeEvery(DELETE_NEXION_REQUEST, deleteNexionRequestInternal);
}

export function* watchCreateNexionOffer() {
    yield takeEvery(CREATE_NEXION_OFFER, createNexionOfferInternal);
}

export function* watchEditNexionOffer() {
    yield takeEvery(EDIT_NEXION_OFFER, editNexionOfferInternal);
}

export function* watchDeleteNexionOffer() {
    yield takeEvery(DELETE_NEXION_OFFER, deleteNexionOfferInternal);
}

export function* watchGetNexionHierarchy() {
    yield takeEvery(GET_NEXION_HIERARCHY, getNexionHierarchyInternal);
}

function* repoNexionsSaga() {
    yield all([fork(watchGetNexions), fork(watchGetNexionInfo), fork(watchCreateNexionRequest), fork(watchEditNexionRequest),fork(watchDeleteNexionRequest),
    fork(watchGetNexionHierarchy), fork(watchGetNexionsOfType), fork(watchCreateNexionOffer), fork(watchEditNexionOffer),fork(watchDeleteNexionOffer),
    fork(watchGetNexionOfferKeywords), fork(watchCreateNexionOfferKeywords)]);
}

export default repoNexionsSaga;
