// @flow
import * as layoutConstants from '../../constants/layout';
import { getTheme, getLayout } from '../../helpers/authUtils';
import { LOGOUT_USER } from '../auth/constants';
import {
    CHANGE_LAYOUT,
    CHANGE_THEME,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE, HIDE_RIGHT_SIDEBAR,
    SET_TOPBAR_TITLE, SHOW_RIGHT_SIDEBAR, TOGGLE_RIGHT_SIDEBAR
} from './constants';

const INIT_STATE = {
    layoutType: getLayout(),
    theme: getTheme(),
    layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
    leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
    showRightSidebar: false,
    topbarTitle: 'topBar.home'
};

const Layout = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload,
            };
        case CHANGE_THEME:
            return {
                ...state,
                theme: action.payload,
            };
        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload,
            };
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: action.payload,
            };
        case CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: action.payload,
            };
        case TOGGLE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: !state.showRightSidebar,
            };
        case SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: true,
            };
        case HIDE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: false,
            };
        case SET_TOPBAR_TITLE:
            return {
                ...state,
                topbarTitle: action.payload,
            };

        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return state;
    }
};

export default Layout;
