// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { getCurrentLanguage } from '../../helpers/utils';
import { getLoggedInUser } from '../../helpers/authUtils';
import { BASE_URL } from './../constants';
import {
    getAcademicsSuccess, getAcademicsFailed,
    getAcademicByIdSuccess, getAcademicByIdFailed,
    getAcademicDocFailed, getAcademicDocSuccess,
    createAcademicFailed, createAcademicSuccess, 
    getAcademicsInCountryFailed, getAcademicsInCountrySuccess,
    deleteAcademicFailed, deleteAcademicSuccess,
    getPublicAcademySuccess, getPublicAcademyFailed,
    getEmailsAcademicsSuccess, getEmailsAcademicsFailed,
    getGoalsOfAcademicSuccess, getGoalsOfAcademicFailed,
    getAcademicFellowsSuccess, getAcademicFellowsFailed,
    setAcademicsAdminSuccess, setAcademicsAdminFailed
} from './actions';
import {
    GET_ACADEMICS, 
    GET_ACADEMIC_BY_ID, 
    GET_ACADEMIC_DOC, 
    CREATE_ACADEMIC, 
    GET_ACADEMICS_IN_COUNTRY, 
    DELETE_ACADEMIC, 
    GET_PUBLIC_ACADEMY, 
    GET_EMAILS_ACADEMICS, 
    GET_ACADEMIC_GOALS,
    GET_ACADEMIC_FELLOWS,
    SET_ACADEMICS_ADMIN
} from './constants';

function* getAcademics({ payload: { userId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/academics/" + userId, options);
        yield put(getAcademicsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAcademicsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAcademicByIdInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/academics/get-academic/" + id, options);
        yield put(getAcademicByIdSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAcademicByIdFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAcademicsInCountry({ payload: { countryCode, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/academics/academic-in-country/" + countryCode, options);
        yield put(getAcademicsInCountrySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAcademicsInCountryFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAcademicDoc({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/academics/academicDoc", options);
        yield put(getAcademicDocSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getAcademicDocFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* createAcademicEntity({ payload: { academic, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'POST',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
        body: academic
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/academics/create-academic", options);
        yield put(createAcademicSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(createAcademicFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* deleteAcademicEntity({ payload: { academicId, placeId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'DELETE',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' },
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }

        const response = yield call(fetchJSON, BASE_URL + "api/academics/delete-academic/" + academicId + '/' + placeId, options);
        yield put(deleteAcademicSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(deleteAcademicFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getPublicAcademyInternal({ payload: { id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/academics/academy-by-id/" + id, options);
        yield put(getPublicAcademySuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getPublicAcademyFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* getEmailsAcademicsInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + "api/academics/list-emails-academics", options);
        yield put(getEmailsAcademicsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
            // this.getTermOfService(callbacks);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

        yield put(getEmailsAcademicsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getGoalsOfAcademicInternal({ payload: { academicId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/academics/' + academicId + '/goals' , options);

        yield put(getGoalsOfAcademicSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getGoalsOfAcademicFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getAcademicFellowsInternal({ payload: { academicId, callbacks } }) {

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    const token = getLoggedInUser().accessToken
    const options = {
        method: 'GET',
        headers: { authorization: "Bearer " + token, 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/academics/get-academy-fellow/' + academicId, options);

        yield put(getAcademicFellowsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAcademicFellowsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* setAcademicsAdminInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const token = getLoggedInUser().accessToken

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: params
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/academics/set-admin', options);

        yield put(setAcademicsAdminSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setAcademicsAdminFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetAcademics() {
    yield takeEvery(GET_ACADEMICS, getAcademics);
}

export function* watchGetAcademicById() {
    yield takeEvery(GET_ACADEMIC_BY_ID, getAcademicByIdInternal);
}

export function* watchGetAcademicsInCountry() {
    yield takeEvery(GET_ACADEMICS_IN_COUNTRY, getAcademicsInCountry);
}

export function* watchGetAcademicDoc() {
    yield takeEvery(GET_ACADEMIC_DOC, getAcademicDoc);
}

export function* watchCreateAcademicEntity() {
    yield takeEvery(CREATE_ACADEMIC, createAcademicEntity);
}

export function* watchDeleteAcademicEntity() {
    yield takeEvery(DELETE_ACADEMIC, deleteAcademicEntity);
}

export function* watchGetPublicAcademy() {
    yield takeEvery(GET_PUBLIC_ACADEMY, getPublicAcademyInternal);
}

export function* watchGetEmailsAcademics() {
    yield takeEvery(GET_EMAILS_ACADEMICS, getEmailsAcademicsInternal);
}

export function* watchGetGoalsOfAcademic() {
    yield takeEvery(GET_ACADEMIC_GOALS, getGoalsOfAcademicInternal);
}

export function* watchGetAcademicFellows() {
    yield takeEvery(GET_ACADEMIC_FELLOWS, getAcademicFellowsInternal);
}

export function* watchSetAcademicsAdmin() {
    yield takeEvery(SET_ACADEMICS_ADMIN, setAcademicsAdminInternal);
}

function* repoAcademicsSaga() {
    yield all([fork(watchGetAcademics), fork(watchGetAcademicById), fork(watchGetAcademicDoc), fork(watchCreateAcademicEntity),
    fork(watchGetAcademicsInCountry), fork(watchDeleteAcademicEntity), fork(watchGetPublicAcademy), fork(watchGetEmailsAcademics),
    fork(watchGetGoalsOfAcademic), fork(watchGetAcademicFellows), fork(watchSetAcademicsAdmin)]);
}

export default repoAcademicsSaga;
