// @flow
import {
    GET_REPOSITES,GET_REPOSITES_FAILED,GET_REPOSITES_SUCCESS
} from './constants';

const INIT_STATE = {
    reposites: [],
    loading: false,
    error: null
};

const RepoSites = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPOSITES:
            return { ...state, loading: true };
        case GET_REPOSITES_SUCCESS:
            return { ...state, reposites: action.payload.profiles, loading: false, error: null };
        case GET_REPOSITES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RepoSites;
