import firebase from 'firebase';
import 'firebase/app';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyCJNO4WHKeVO0R6--CXRqGVR8C3F_t0qoM",
    authDomain: "datanexions-poc.firebaseapp.com",
    projectId: "datanexions-poc",
    storageBucket: "datanexions-poc.appspot.com",
    messagingSenderId: "760422566778",
    appId: "1:760422566778:web:d55fe6dd76036dba53a4c3",
    measurementId: "G-NKJNN9PKKM"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

export default firebase;