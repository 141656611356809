// @flow
import {
    SEARCH_VERIFIER, SEARCH_VERIFIER_SUCCESS, SEARCH_VERIFIER_FAILED,
    GET_EXTERNAL_VALIDATION, GET_EXTERNAL_VALIDATION_SUCCESS, GET_EXTERNAL_VALIDATION_FAILED,
    UPDATE_EXTERNAL_VALIDATION, UPDATE_EXTERNAL_VALIDATION_SUCCESS, UPDATE_EXTERNAL_VALIDATION_FAILED,
    UPDATE_VALIDATION, UPDATE_VALIDATION_SUCCESS, UPDATE_VALIDATION_FAILED,
    CREATE_VALIDATION, CREATE_VALIDATION_SUCCESS, CREATE_VALIDATION_FAILED,
    EDIT_VALIDATION, EDIT_VALIDATION_SUCCESS, EDIT_VALIDATION_FAILED,
    DELETE_VALIDATION, DELETE_VALIDATION_SUCCESS, DELETE_VALIDATION_FAILED,
    GET_VALIDATIONS, GET_VALIDATIONS_SUPERSET, GET_VALIDATIONS_SUCCESS, GET_VALIDATIONS_FAILED, GET_VALIDATIONS_SUPERSET_SUCCESS, GET_VALIDATIONS_SUPERSET_FAILED,
    GET_VALIDATION_GRAPH_DATA, GET_VALIDATION_GRAPH_DATA_SUCCESS, GET_VALIDATION_GRAPH_DATA_FAILED
} from './constants';

export const searchVerifier = (keyword, page, callbacks) => ({
    type: SEARCH_VERIFIER,
    payload: { keyword, page, callbacks },
});

export const searchVerifierSuccess = (keyword, data) => ({
    type: SEARCH_VERIFIER_SUCCESS,
    payload: { keyword, data },
});

export const searchVerifierFailed = (error) => ({
    type: SEARCH_VERIFIER_FAILED,
    payload: error,
});

export const getExternalValidation = (code, callbacks) => ({
    type: GET_EXTERNAL_VALIDATION,
    payload: { code, callbacks },
});

export const getExternalValidationSuccess = (data) => ({
    type: GET_EXTERNAL_VALIDATION_SUCCESS,
    payload: { data },
});

export const getExternalValidationFailed = (error) => ({
    type: GET_EXTERNAL_VALIDATION_FAILED,
    payload: error,
});

export const updateExternalValidation = (code, status, comment, callbacks) => ({
    type: UPDATE_EXTERNAL_VALIDATION,
    payload: { code, status, comment, callbacks },
});

export const updateExternalValidationSuccess = (data) => ({
    type: UPDATE_EXTERNAL_VALIDATION_SUCCESS,
    payload: { data },
});

export const updateExternalValidationFailed = (error) => ({
    type: UPDATE_EXTERNAL_VALIDATION_FAILED,
    payload: error,
});

export const createValidation = (params, callbacks) => ({
    type: CREATE_VALIDATION,
    payload: { params, callbacks },
});

export const createValidationSuccess = (data) => ({
    type: CREATE_VALIDATION_SUCCESS,
    payload: { data },
});

export const createValidationFailed = (error) => ({
    type: CREATE_VALIDATION_FAILED,
    payload: error,
});


export const editValidation = (id, params, callbacks) => ({
    type: EDIT_VALIDATION,
    payload: { id, params, callbacks },
});

export const editValidationSuccess = (data) => ({
    type: EDIT_VALIDATION_SUCCESS,
    payload: { data },
});

export const editValidationFailed = (error) => ({
    type: EDIT_VALIDATION_FAILED,
    payload: error,
});


export const deleteValidation = (id, comment, callbacks) => ({
    type: DELETE_VALIDATION,
    payload: { id, comment, callbacks },
});

export const deleteValidationSuccess = (data) => ({
    type: DELETE_VALIDATION_SUCCESS,
    payload: { data },
});

export const deleteValidationFailed = (error) => ({
    type: DELETE_VALIDATION_FAILED,
    payload: error,
});


export const getValidations = (type, callbacks) => ({
    type: GET_VALIDATIONS,
    payload: { type, callbacks },
});

export const getValidationsSuccess = (type, data) => ({
    type: GET_VALIDATIONS_SUCCESS,
    payload: { type, data },
});

export const getValidationsFailed = (error) => ({
    type: GET_VALIDATIONS_FAILED,
    payload: error,
});

export const getValidationsSuperset = (type, mode, filter, callbacks) => ({
    type: GET_VALIDATIONS_SUPERSET,
    payload: { type, mode, filter, callbacks },
});

export const getValidationsSupersetSuccess = (type, data) => ({
    type: GET_VALIDATIONS_SUPERSET_SUCCESS,
    payload: { type, data },
});

export const getValidationsSupersetFailed = (error) => ({
    type: GET_VALIDATIONS_SUPERSET_FAILED,
    payload: error,
});

export const updateValidation = (id, status, comment, callbacks) => ({
    type: UPDATE_VALIDATION,
    payload: { id, status, comment, callbacks },
});

export const updateValidationSuccess = (data) => ({
    type: UPDATE_VALIDATION_SUCCESS,
    payload: { data },
});

export const updateValidationFailed = (error) => ({
    type: UPDATE_VALIDATION_FAILED,
    payload: error,
});


export const getValidationGraphData = (callbacks) => ({
    type: GET_VALIDATION_GRAPH_DATA,
    payload: { callbacks },
});

export const getValidationGraphDataSuccess = (response) => ({
    type: GET_VALIDATION_GRAPH_DATA_SUCCESS,
    payload: { response },
});

export const getValidationGraphDataFailed = (error) => ({
    type: GET_VALIDATION_GRAPH_DATA_FAILED,
    payload: error,
});
