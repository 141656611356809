import classnames from 'classnames';
import React, { Component } from 'react';
import "react-bubble-ui/dist/index.css";
import { withTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Card, CardBody, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import logo from '../../assets/images/logo.png';
import { getRepoProducts, getRepoProfiles } from '../../redux/actions';
import { getLocalizedString } from './../../helpers/utils';
import BenefitView from './BenefitView';
import HowDatanexions from './HowDatanexions';
import ProductView from './ProductView';
import { isDarkMode } from '../../helpers/authUtils';
import { IS_STAGING, S3_BASE_URL } from '../../redux/constants';

class NetworkTabView extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTabNetwork: 1,
            isTab1Open: false,
            isTab2Open: false,
            isTab3Open: false,
            isTab4Open: false,
            activedTabPricing: 1,
            selectedType: 0,
            detailType: 0,
            selectTypePay: 0,
            playing: true,
            muted: true,
            videoError: null
        };
        this.playerRef = React.createRef();
    }

    componentDidMount() {
        this.props.getRepoProfiles({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
            },
        });

        // Get repository::products
        this.props.getRepoProducts({
            callbackOnBegin: () => {
                // Update loading state?
                // console.log('callbackOnBegin');
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
                this.setState({ loading: false, error: error });
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                // whatever
                // console.log('callbackOnSuccess');
            },
        });
    }

    componentDidUpdate(prevProps) {
        if ((this.props.isSelected != prevProps.isSelected) && !this.props.isSelected) {
            if (this.playerRef && this.playerRef.current) {
                this.playerRef.current.src = null;
            }
        }
    }

    toggle = (tab) => {
        if (this.state.activeTabNetwork !== tab) {
            this.setState({
                activeTabNetwork: tab,
            });
        }
    };

    toggleTab1 = () => {
        this.setState({ isTab1Open: !this.state.isTab1Open });
    }
    toggleTab2 = () => {
        this.setState({ isTab2Open: !this.state.isTab2Open });
    }
    toggleTab3 = () => {
        this.setState({ isTab3Open: !this.state.isTab3Open });
    }
    toggleTab4 = () => {
        this.setState({ isTab4Open: !this.state.isTab4Open });
    }
    togglePricing = (tab) => {
        if (this.state.activedTabPricing !== tab) {
            this.setState({
                activedTabPricing: tab
            });
        }
    };

    render() {

        const { t, error } = this.props;
        // 
        var rolesProfile = [];
        if (this.props.repoprofiles) {
            this.props.repoprofiles.map(role => {
                rolesProfile.push(role)
            })
        }
        const roleOptions = rolesProfile?.map(role => {
            return { value: role?.name, label: getLocalizedString(role?.profileCategoryInfo?.name) }
        })

        const detailProfile = [];
        const roleProfile = rolesProfile[this.state.selectedType]
        if (roleProfile) {
            roleProfile.profileDocs.forEach(detail => {
                detailProfile.push(detail)
            })
        }
        const detailOptions = detailProfile.map(detail => {
            return { value: detail.id, label: getLocalizedString(detail.detail?.name) }
        })

        const s3_base_url = S3_BASE_URL;
        const description = (roleProfile)
            && roleProfile.profileDocs[this.state.detailType].detail?.description ? getLocalizedString(roleProfile.profileDocs[this.state.detailType].detail?.description) : '';
        var image = (roleProfile)
            && roleProfile.profileDocs[this.state.detailType].detail?.image ? s3_base_url + getLocalizedString(roleProfile.profileDocs[this.state.detailType].detail?.image) : logo;

        var video = (roleProfile)
            && roleProfile.profileDocs[this.state.detailType].detail?.video ? s3_base_url + getLocalizedString(roleProfile.profileDocs[this.state.detailType].detail?.video) : '';
        // console.log('video: ' + video);
        if (isDarkMode() && roleProfile?.profileDocs?.length > this.state.detailType) {
            if (roleProfile?.profileDocs[this.state.detailType]?.detail?.imageB != null &&
                roleProfile?.profileDocs[this.state.detailType]?.detail?.imageB != '') {
                image = s3_base_url + getLocalizedString(roleProfile.profileDocs[this.state.detailType].detail.imageB);
            }
            if (roleProfile?.profileDocs[this.state.detailType]?.detail?.videoB != null &&
                roleProfile?.profileDocs[this.state.detailType]?.detail?.videoB != '') {
                video = s3_base_url + getLocalizedString(roleProfile.profileDocs[this.state.detailType].detail.videoB);
            }
        }

        const benefits = (roleProfile)
            && roleProfile.profileDocs[this.state.detailType].detail?.benefits ? roleProfile.profileDocs[this.state.detailType].detail?.benefits : [];
        const videoError = this.state.videoError ? this.state.videoError : (roleProfile
            && roleProfile.profileDocs[this.state.detailType].detail?.video ? null : 'error.noVideoAvailable');
        const products = this.props.repoproducts ? this.props.repoproducts : [];
        const currencies = this.props.currencies ? this.props.currencies : [];
        var typePay = [];
        if (currencies) {
            typePay = currencies.map(c => {
                const localizedName = getLocalizedString(c.name);
                return { value: localizedName, label: localizedName }
            });
        }

        const playing = this.state.playing && this.props.isSelected;
        const isProduction = !IS_STAGING;
        return (

            <React.Fragment>
                <Col xl={12}>

                    <Nav className="nav">

                        <NavItem key={1}>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTabNetwork === 1 })}
                                onClick={() => {
                                    this.toggle(1);
                                }} >
                                <Label className={(this.state.activeTabNetwork === 1) ? 'title-dark-label' : 'title-gray-label'}>
                                    {t('company.whyDatanexions')}
                                </Label>
                            </NavLink>
                        </NavItem>
                        {!isProduction &&
                            <NavItem key={2}>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: this.state.activeTabNetwork === 2 })}
                                    onClick={() => {
                                        this.toggle(2);
                                    }} >
                                    <Label className={(this.state.activeTabNetwork === 2) ? 'title-dark-label' : 'title-gray-label'}>
                                        {t('company.howDatanexions')}
                                    </Label>
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem key={3}>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTabNetwork === 3 })}
                                onClick={() => {
                                    this.toggle(3);
                                }}>

                                <Label className={(this.state.activeTabNetwork === 3) ? 'title-dark-label' : 'title-gray-label'}>
                                    {t('company.pricing')}
                                </Label>
                            </NavLink>
                        </NavItem>

                    </Nav>
                </Col>


                <TabContent activeTab={this.state.activeTabNetwork}>

                    <TabPane tabId={1} key={1}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>

                                        <Row >

                                            <Label className='ml-2 mt-2'>
                                                {t("network.iam")}
                                            </Label>

                                            <Col xl={3} >
                                                <Select
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    placeholder={t('placeholder.Select')}
                                                    options={roleOptions}
                                                    value={roleOptions[this.state.selectedType]}
                                                    onChange={(value) => {
                                                        const idx = roleOptions.indexOf(value);
                                                        this.setState({ selectedType: idx, detailType: 0, videoError: null });

                                                    }}
                                                ></Select>
                                            </Col>
                                            <Label className='ml-2 mt-2'>

                                            </Label>

                                            <Col xl={3} >
                                                <Select
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    placeholder={t('placeholder.Select')}
                                                    options={detailOptions}
                                                    value={detailOptions[this.state.detailType]}
                                                    onChange={(value) => {
                                                        const idx = detailOptions.indexOf(value);
                                                        this.setState({ detailType: idx, videoError: null });
                                                    }}
                                                ></Select>
                                            </Col>
                                        </Row>

                                        {(this.state.selectedType < 1000) &&
                                            <div>
                                                <Card className="intro-card-bottom mb-3 mt-1">
                                                    <CardBody>
                                                        <Label className="title-left-small-label">{description}</Label>
                                                    </CardBody>
                                                </Card>
                                                <Row >
                                                    <Col xl={3}>
                                                        <div className="photo-no-border">
                                                            <img
                                                                className="img-responsive fit-image"
                                                                src={image}
                                                                alt={image}
                                                                onError={(e) => {
                                                                    e.preventDefault();
                                                                    e.target.src = logo
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xl={9}>
                                                        <div className="video-border">
                                                            {videoError ? <Label className="title-dark-small-label">{t(videoError)}</Label> :
                                                                <ReactPlayer
                                                                    className='react-player'
                                                                    ref={this.playerRef}
                                                                    url={video}
                                                                    playing={playing}
                                                                    muted={this.state.muted}
                                                                    controls={true}
                                                                    controlsList="nodownload"
                                                                    config={{
                                                                        file: {
                                                                            attributes: {
                                                                                controlsList: 'nodownload',
                                                                                onContextMenu: e => e.preventDefault()
                                                                            }
                                                                        }
                                                                    }}
                                                                    loop={true}
                                                                    onClick={(e) => {
                                                                        this.setState({ playing: !this.playerRef.current.playing });
                                                                    }}
                                                                    onError={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({ videoError: 'error.videoNotFound' });
                                                                        // console.log('video error');
                                                                    }

                                                                    }
                                                                    width='100%'
                                                                    height='100%'
                                                                />
                                                            }

                                                        </div>
                                                    </Col>

                                                </Row>

                                                {/* Benefits */}
                                                {!isProduction &&
                                                    <div>
                                                        {benefits.map(benefit => (
                                                            <BenefitView benefit={benefit} />
                                                        )
                                                        )}
                                                    </div>
                                                }
                                            </div>}
                                    </CardBody>

                                </Card>


                            </Col>
                        </Row>
                    </TabPane>

                    {/* <TabPane tabId={2} key={2}>
                        <HowDatanexions />
                    </TabPane> */}

                    <TabPane tabId={3} key={3}>
                        <Row>
                            <Col sm="12">
                                <Col xl={12}>
                                    <Row>
                                        <Col md={2} >
                                            <Select
                                                className="react-select mt-1 mr-3"
                                                classNamePrefix="react-select"
                                                placeholder={t('placeholder.Select')}
                                                options={typePay}
                                                value={typePay[this.state.selectTypePay]}
                                                onChange={(value) => {
                                                    const idx = typePay.indexOf(value);
                                                    this.setState({ selectTypePay: idx });
                                                }}
                                            ></Select>

                                        </Col>

                                        <Nav tabs>
                                            {products.map((product, index) => (
                                                <NavItem key={index + 1}>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: this.state.activedTabPricing === index + 1 })}
                                                        onClick={() => {
                                                            this.togglePricing(index + 1);
                                                        }} >

                                                        <span className="d-none d-sm-block">{product.productCategoryInfo && product.productCategoryInfo.name ? getLocalizedString(product.productCategoryInfo.name) : ''}</span>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                            )}

                                        </Nav>
                                    </Row>


                                </Col>
                                <TabContent activeTab={this.state.activedTabPricing}>

                                    {products.map((product, index) => (
                                        <ProductView key={index} product={product} selectTypePay={currencies && currencies[this.state.selectTypePay]} index={index + 1} />
                                    ))}

                                </TabContent>
                            </Col>
                        </Row>
                    </TabPane>

                </TabContent>

            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    const { repoprofiles, loading, error } = state.RepoProfiles;
    const { repoproducts, currencies } = state.RepoProducts;
    return { repoprofiles, repoproducts, currencies, loading, error };
};

export default connect(mapStateToProps, { getRepoProfiles, getRepoProducts })((withTranslation('translations')(NetworkTabView)));
