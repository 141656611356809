// @flow
import {all,call,fork,put,takeEvery} from 'redux-saga/effects';
import {fetchJSON} from '../../helpers/api';
import {BASE_URL} from './../constants';
import {
    getRepoSitesFailed,getRepoSitesSuccess
} from './actions';
import {
    GET_REPOSITES
} from './constants';

function* getRepoSitesInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + 'api/localize/repo-sites', options);
        yield put(getRepoSitesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getRepoSitesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }

    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetRepoSites() {
    yield takeEvery(GET_REPOSITES, getRepoSitesInternal);
}

function* repositesSaga() {
    yield all([fork(watchGetRepoSites)]);
}

export default repositesSaga;
