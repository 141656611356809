// @flow
import { data } from 'jquery';
import {
    CREATE_CONTACT, CREATE_CONTACT_SUCCESS, CREATE_CONTACT_FAILED,
    EDIT_CONTACT, EDIT_CONTACT_SUCCESS, EDIT_CONTACT_FAILED,
    DELETE_CONTACT, DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAILED,
    GET_NETWORK, GET_NETWORK_SUCCESS, GET_NETWORK_FAILED,
    CREATE_INVITE, CREATE_INVITE_SUCCESS, CREATE_INVITE_FAILED,
    GET_ALL_NETWORK_PEOPLE, GET_ALL_NETWORK_PEOPLE_SUCCESS, GET_ALL_NETWORK_PEOPLE_FAILED,
    GET_NETWORK_PEOPLE, GET_NETWORK_PEOPLE_SUCCESS, GET_NETWORK_PEOPLE_FAILED,
    GET_ALL_NETWORK_COMPANIES, GET_ALL_NETWORK_COMPANIES_SUCCESS, GET_ALL_NETWORK_COMPANIES_FAILED,
    GET_NETWORK_COMPANIES, GET_NETWORK_COMPANIES_SUCCESS, GET_NETWORK_COMPANIES_FAILED,
    CHECK_EXIST_USERS, CHECK_EXIST_USERS_SUCCESS, CHECK_EXIST_USERS_FAILED,
    RESET_EXIST_USERS, RESET_EXIST_USERS_SUCCESS, RESET_EXIST_USERS_FAILED,
    GET_ALL_ACADEMICS, GET_ALL_ACADEMICS_SUCCESS, GET_ALL_ACADEMICS_FAILED,
    GET_ALL_COMPANIES2, GET_ALL_COMPANIES2_FAILED, GET_ALL_COMPANIES2_SUCCESS,
    GET_ALL_NETWORK_PERSONS, GET_ALL_NETWORK_PERSONS_SUCCESS, GET_ALL_NETWORK_PERSONS_FAILED,
    GET_NETWORK_PERSON_INVITATIONS, GET_NETWORK_PERSON_INVITATIONS_SUCCESS, GET_NETWORK_PERSON_INVITATIONS_FAILED,
    GET_NETWORK_PERSON_VALIDATIONS, GET_NETWORK_PERSON_VALIDATIONS_SUCCESS, GET_NETWORK_PERSON_VALIDATIONS_FAILED,
    GET_NETWORK_PERSON_EXPERIENCES, GET_NETWORK_PERSON_EXPERIENCES_SUCCESS, GET_NETWORK_PERSON_EXPERIENCES_FAILED,
    GET_NETWORK_PERSON_NETWORK, GET_NETWORK_PERSON_NETWORK_SUCCESS, GET_NETWORK_PERSON_NETWORK_FAILED,

    CREATE_NETWORK_COMPANY, CREATE_NETWORK_COMPANY_SUCCESS, CREATE_NETWORK_COMPANY_FAILED,
    EDIT_NETWORK_COMPANY, EDIT_NETWORK_COMPANY_SUCCESS, EDIT_NETWORK_COMPANY_FAILED,
    DELETE_NETWORK_COMPANY, DELETE_NETWORK_COMPANY_SUCCESS, DELETE_NETWORK_COMPANY_FAILED,
    CREATE_NETWORK_COMPANY_GOAL, CREATE_NETWORK_COMPANY_GOAL_SUCCESS, CREATE_NETWORK_COMPANY_GOAL_FAILED,
    EDIT_NETWORK_COMPANY_GOAL, EDIT_NETWORK_COMPANY_GOAL_SUCCESS, EDIT_NETWORK_COMPANY_GOAL_FAILED,
    DELETE_NETWORK_COMPANY_GOAL, DELETE_NETWORK_COMPANY_GOAL_SUCCESS, DELETE_NETWORK_COMPANY_GOAL_FAILED,

    GET_NETWORK_ACADEMIC_EXPERIENCES, GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS, GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED,
    CREATE_NETWORK_ACADEMY, CREATE_NETWORK_ACADEMY_SUCCESS, CREATE_NETWORK_ACADEMY_FAILED,
    EDIT_NETWORK_ACADEMY, EDIT_NETWORK_ACADEMY_SUCCESS, EDIT_NETWORK_ACADEMY_FAILED,
    DELETE_NETWORK_ACADEMY, DELETE_NETWORK_ACADEMY_SUCCESS, DELETE_NETWORK_ACADEMY_FAILED,
    GET_NETWORK_ACADEMICS, GET_NETWORK_ACADEMICS_SUCCESS, GET_NETWORK_ACADEMICS_FAILED,
    GET_ALL_NETWORK_ACADEMICS, GET_ALL_NETWORK_ACADEMICS_SUCCESS, GET_ALL_NETWORK_ACADEMICS_FAILED,

    CREATE_NETWORK_EVENT, CREATE_NETWORK_EVENT_SUCCESS, CREATE_NETWORK_EVENT_FAILED,
    EDIT_NETWORK_EVENT, EDIT_NETWORK_EVENT_SUCCESS, EDIT_NETWORK_EVENT_FAILED,
    DELETE_NETWORK_EVENT, DELETE_NETWORK_EVENT_SUCCESS, DELETE_NETWORK_EVENT_FAILED,
    GET_ALL_NETWORK_EVENTS, GET_ALL_NETWORK_EVENTS_SUCCESS, GET_ALL_NETWORK_EVENTS_FAILED,
    GET_NETWORK_EVENTS, GET_NETWORK_EVENTS_SUCCESS, GET_NETWORK_EVENTS_FAILED,
    GET_EVENT_GROUP, GET_EVENT_GROUP_SUCCESS, GET_EVENT_GROUP_FAILED,

    GET_NETWORK_COMPANY_EXPERIENCES, GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS, GET_NETWORK_COMPANY_EXPERIENCES_FAILED,

    GET_PEOPLE_BY_DOMAIN, GET_PEOPLE_BY_DOMAIN_SUCCESS, GET_PEOPLE_BY_DOMAIN_FAILED,

    GET_SIGNUP_REQUESTS, GET_SIGNUP_REQUESTS_SUCCESS, GET_SIGNUP_REQUESTS_FAILED,
    DECLINE_SIGNUP_REQUEST, DECLINE_SIGNUP_REQUEST_SUCCESS, DECLINE_SIGNUP_REQUEST_FAILED,


} from './constants';

import {
    EDIT_EXPERIENCE, EDIT_EXPERIENCE_SUCCESS, EDIT_EXPERIENCE_FAILED,
} from '../profile/constants';

import {
    LOGOUT_USER,
} from '../auth/constants';
import { NETWORK_COMPANY_TYPE_COUNTRY } from '../../pages/apps/Home/Network/NetworkCompany';
import { NETWORK_PERSON_TYPE_COUNTRY } from '../../pages/apps/Home/Network/NetworkPerson';
import { NETWORK_ACADEMIC_TYPE_COUNTRY } from '../../pages/apps/Home/Network/NetworkAcademics';
import { NETWORK_EVENT_TYPE_COUNTRY } from '../../pages/apps/Home/Network/NetworkEvents';

const INIT_STATE = {
    network: null,

    allNetworkPeople: null,
    networkPersonType: NETWORK_PERSON_TYPE_COUNTRY,
    networkPersonPage: 0,
    networkPersonKeyword: '',
    networkPeople: null,
    didLoadPeopleDetails: false,
    networkPersonInvitations: null,
    networkPersonInvitationsWithMe: null,
    networkPersonValidations: null,
    networkPersonValidationsWithMe: null,
    networkPersonExperiences: null,
    networkPersonExperiencesWithMe: null,
    networkPersonNetwork: null,

    allNetworkCompanies: null,
    networkCompanyType: NETWORK_COMPANY_TYPE_COUNTRY,
    networkCompanyPage: 0,
    networkCompanyKeyword: '',
    networkCompanies: null,
    didLoadCompaniesDetails: false,

    allNetworkAcademics: null,
    networkAcademicType: NETWORK_ACADEMIC_TYPE_COUNTRY,
    networkAcademicPage: 0,
    networkAcademicKeyword: '',
    networkAcademics: null,
    didLoadAcademicsDetails: false,

    allNetworkEvents: null,
    networkEventType: NETWORK_EVENT_TYPE_COUNTRY,
    networkEventPage: 0,
    networkEventKeyword: '',
    networkEvents: null,
    didLoadEventsDetails: false,

    existUsers: null,
    loading: false,
    error: null,
    experiences: [],
    academicExperiences: [],
    peopleByDomain: null,

    eventGroup: null,

    signupRequests: null
};

const Network = (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_CONTACT:
            return { ...state, loading: true };
        case CREATE_CONTACT_SUCCESS:
            {
                let allNetworkCompanies = state.allNetworkCompanies;
                if (allNetworkCompanies) {
                    allNetworkCompanies.push(action.payload);
                }
                let currentNetworkPeople = state.networkPeople;
                if (currentNetworkPeople?.data) {
                    currentNetworkPeople.data.push(action.payload);
                }
                return { ...state, allNetworkCompanies: allNetworkCompanies, networkPeople: currentNetworkPeople, loading: false, error: null };
            }
        case CREATE_CONTACT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_CONTACT:
            return { ...state, loading: true };
        case EDIT_CONTACT_SUCCESS:
            {
                let editedContact = action.payload;
                let allNetworkPeople = state.allNetworkPeople;
                if (allNetworkPeople && editedContact) {
                    let index = allNetworkPeople.findIndex((person) => { return person.id == editedContact.id });
                    if (index >= 0) {
                        allNetworkPeople[index] = editedContact;
                    }
                }
                let currentNetworkPeople = state.networkPeople;
                if (currentNetworkPeople?.data && editedContact) {
                    let index = currentNetworkPeople.data.findIndex((person) => { return person.id == editedContact.id });
                    if (index >= 0) {
                        currentNetworkPeople.data[index] = editedContact;
                    }
                }
                return { ...state, allNetworkPeople: allNetworkPeople, networkPeople: currentNetworkPeople, loading: false, error: null };
            }
        case EDIT_CONTACT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_INVITE:
            return { ...state, loading: true };
        case CREATE_INVITE_SUCCESS:
            {
                let editedContact = action.payload.network;
                let allNetworkPeople = state.allNetworkPeople;
                if (allNetworkPeople && editedContact) {
                    let index = allNetworkPeople.findIndex((person) => { return person.id == editedContact.id });
                    if (index >= 0) {
                        allNetworkPeople[index] = editedContact;
                    }
                }
                let currentNetworkPeople = state.networkPeople;
                if (currentNetworkPeople?.data && editedContact) {
                    let index = currentNetworkPeople.data.findIndex((person) => { return person.id == editedContact.id });
                    if (index >= 0) {
                        currentNetworkPeople.data[index] = editedContact;
                    }
                }
                return { ...state, allNetworkPeople: allNetworkPeople, networkPeople: currentNetworkPeople, loading: false, error: null };
            }
        case CREATE_INVITE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_CONTACT:
            return { ...state, loading: true };
        case DELETE_CONTACT_SUCCESS:
            {
                let allNetworkPeople = state.allNetworkPeople;
                if (allNetworkPeople) {
                    let index = allNetworkPeople.findIndex((person) => { return person.id == action.payload });
                    if (index >= 0) {
                        allNetworkPeople.splice(index, 1);
                    }
                }
                let currentNetworkPeople = state.networkPeople;
                if (currentNetworkPeople?.data) {
                    let index = currentNetworkPeople.data.findIndex((person) => { return person.id == action.payload });
                    if (index >= 0) {
                        currentNetworkPeople.data.splice(index, 1);
                    }
                }
                return { ...state, allNetworkPeople: allNetworkPeople, networkPeople: currentNetworkPeople, loading: false, error: null };
            }
        case DELETE_CONTACT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK:
            return { ...state, loading: true };
        case GET_NETWORK_SUCCESS:
            return { ...state, network: action.payload, loading: false, error: null };
        case GET_NETWORK_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_NETWORK_PEOPLE:
            return { ...state, loading: true };
        case GET_ALL_NETWORK_PEOPLE_SUCCESS:
            return { ...state, allNetworkPeople: action.payload, loading: false, error: null };
        case GET_ALL_NETWORK_PEOPLE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_ACADEMICS:
            return { ...state, loading: true };
        case GET_ALL_ACADEMICS_SUCCESS:
            return { ...state, allAcademics: action.payload, loading: false, error: null };
        case GET_ALL_ACADEMICS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_COMPANIES2:
            return { ...state, loading: true };
        case GET_ALL_COMPANIES2_SUCCESS:
            return { ...state, allCompanies: action.payload, loading: false, error: null };
        case GET_ALL_COMPANIES2_FAILED:
            return { ...state, error: action.payload, loading: false };
    
        case GET_ALL_NETWORK_PERSONS:
            return { ...state, loading: true };
        case GET_ALL_NETWORK_PERSONS_SUCCESS:
            return { ...state, allNetworkPersons: action.payload, loading: false, error: null };
        case GET_ALL_NETWORK_PERSONS_FAILED:
            return { ...state, error: action.payload, loading: false };
    
        case GET_NETWORK_PEOPLE:
            if (state.networkPersonType != action.payload.type || state.networkPersonKeyword != action.payload.keyword) {
                return { ...state, networkPersonKeyword: action.payload.keyword, networkPersonType: action.payload.type, networkPersonPage: action.payload.page, networkPeople: null, didLoadPeopleDetails: false, loading: true };
            }
            else {
                return { ...state, didLoadPeopleDetails: false, networkPersonPage: action.payload.page, loading: true };
            }
        case GET_NETWORK_PEOPLE_SUCCESS:
            if (state.networkPersonType == action.payload.type && state.networkPersonKeyword == action.payload.keyword && action.payload.page == state.networkPersonPage) {
                return { ...state, didLoadPeopleDetails: action.payload.loadDetails ?? false, networkPeople: action.payload.network, loading: false, error: null };
            }
        case GET_NETWORK_PEOPLE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_NETWORK_COMPANIES:
            return { ...state, loading: true };
        case GET_ALL_NETWORK_COMPANIES_SUCCESS:
            return { ...state, allNetworkCompanies: action.payload, loading: false, error: null };
        case GET_ALL_NETWORK_COMPANIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_COMPANIES:
            if (state.networkCompanyType != action.payload.type || state.networkCompanyKeyword != action.payload.keyword) {
                return { ...state, networkCompanyType: action.payload.type, networkCompanyKeyword: action.payload.keyword, networkCompanyPage: action.payload.page, networkCompanies: null, didLoadCompaniesDetails: false, loading: true };
            }
            else {
                return { ...state, didLoadCompaniesDetails: false, networkCompanyPage: action.payload.page, loading: true };
            }
        case GET_NETWORK_COMPANIES_SUCCESS:
            if (state.networkCompanyType == action.payload.type && state.networkCompanyKeyword == action.payload.keyword && action.payload.page == state.networkCompanyPage) {
                return { ...state, didLoadCompaniesDetails: action.payload.loadDetails ?? false, networkCompanies: action.payload.network, loading: false, error: null };
            }
        case GET_NETWORK_COMPANIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_PERSON_INVITATIONS:
            return { ...state, loading: true };
        case GET_NETWORK_PERSON_INVITATIONS_SUCCESS:
            if (action.payload.withMe ?? false) {
                return { ...state, networkPersonInvitationsWithMe: action.payload.response, loading: false, error: null };
            }
            else {
                return { ...state, networkPersonInvitations: action.payload.response, loading: false, error: null };
            }
        case GET_NETWORK_PERSON_INVITATIONS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_PERSON_VALIDATIONS:
            return { ...state, loading: true };
        case GET_NETWORK_PERSON_VALIDATIONS_SUCCESS:
            if (action.payload.withMe ?? false) {
                return { ...state, networkPersonValidationsWithMe: action.payload.response, loading: false, error: null };
            }
            else {
                return { ...state, networkPersonValidations: action.payload.response, loading: false, error: null };
            }
        case GET_NETWORK_PERSON_VALIDATIONS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_PERSON_EXPERIENCES:
            return { ...state, loading: true };
        case GET_NETWORK_PERSON_EXPERIENCES_SUCCESS:
            if (action.payload.withMe ?? false) {
                return { ...state, networkPersonExperiencesWithMe: action.payload.response, loading: false, error: null };
            }
            else {
                return { ...state, networkPersonExperiences: action.payload.response, loading: false, error: null };
            }
        case GET_NETWORK_PERSON_EXPERIENCES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_PERSON_NETWORK:
            return { ...state, loading: true };
        case GET_NETWORK_PERSON_NETWORK_SUCCESS:
            return { ...state, networkPersonNetwork: action.payload.response, loading: false, error: null };
        case GET_NETWORK_PERSON_NETWORK_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHECK_EXIST_USERS:
            return { ...state, loading: true };
        case CHECK_EXIST_USERS_SUCCESS:
            return { ...state, existUsers: action.payload.response, loading: false, error: null };
        case CHECK_EXIST_USERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case RESET_EXIST_USERS:
            return { ...state, loading: true };
        case RESET_EXIST_USERS_SUCCESS:
            return { ...state, existUsers: null, loading: false, error: null };
        case RESET_EXIST_USERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_NETWORK_COMPANY:
            return { ...state, loading: true };
        case CREATE_NETWORK_COMPANY_SUCCESS:
            {
                let allNetworkCompanies = state.allNetworkCompanies;
                if (allNetworkCompanies != null) {
                    allNetworkCompanies.push(action.payload);
                }
                let networkCompanies = state.networkCompanies;
                if (networkCompanies && networkCompanies.data) {
                    networkCompanies.data.push(action.payload);
                }
                return { ...state, allNetworkCompanies: allNetworkCompanies, networkCompanies: networkCompanies, loading: false, error: null };
            }
        case CREATE_NETWORK_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NETWORK_COMPANY:
            return { ...state, loading: true };
        case EDIT_NETWORK_COMPANY_SUCCESS:
            {
                let allNetworkCompanies = state.allNetworkCompanies;
                if (allNetworkCompanies != null) {
                    let index = allNetworkCompanies.findIndex((element) => { return element.id == action.payload.id });
                    if (index >= 0) {
                        allNetworkCompanies[index] = action.payload;
                    }
                }
                let networkCompanies = state.networkCompanies;
                if (networkCompanies?.data != null) {
                    let index = networkCompanies?.data.findIndex((element) => { return element.company?.id == action.payload.id });
                    if (index >= 0) {
                        networkCompanies.data[index].company = action.payload;
                    }
                }
                return { ...state, allNetworkCompanies: allNetworkCompanies, networkCompanies: networkCompanies, loading: false, error: null };
            }
        case EDIT_NETWORK_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NETWORK_COMPANY:
            return { ...state, loading: true };
        case DELETE_NETWORK_COMPANY_SUCCESS:
            {
                let allNetworkCompanies = state.allNetworkCompanies;
                if (allNetworkCompanies) {
                    let index = allNetworkCompanies.findIndex((company) => { return company?.id == action.payload });
                    if (index >= 0) {
                        allNetworkCompanies.splice(index, 1);
                    }
                }
                let currentCompanies = state.networkCompanies;
                if (currentCompanies?.data) {
                    let index = currentCompanies.data.findIndex((company) => { return company.company?.id == action.payload });
                    if (index >= 0) {
                        currentCompanies.data.splice(index, 1);
                    }
                }
                return { ...state, allNetworkCompanies: allNetworkCompanies, networkCompanies: currentCompanies, loading: false, error: null };
            }
        case DELETE_NETWORK_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };


        case CREATE_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case CREATE_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                return { ...state, loading: false, error: null };
            }
        case CREATE_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };


        case EDIT_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case EDIT_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                return { ...state, loading: false, error: null };
            }
        case EDIT_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };


        case DELETE_NETWORK_COMPANY_GOAL:
            return { ...state, loading: true };
        case DELETE_NETWORK_COMPANY_GOAL_SUCCESS:
            {
                return { ...state, loading: false, error: null };
            }
        case DELETE_NETWORK_COMPANY_GOAL_FAILED:
            return { ...state, error: action.payload, loading: false };


        case CREATE_NETWORK_ACADEMY:
            return { ...state, loading: true };
        case CREATE_NETWORK_ACADEMY_SUCCESS:
            {
                let allNetworkAcademics = state.allNetworkAcademics;
                if (allNetworkAcademics) {
                    allNetworkAcademics.push(action.payload);
                }
                let currentAcademics = state.networkAcademics;
                if (currentAcademics) {
                    if (!currentAcademics.data) {
                        currentAcademics.data = [];
                    }
                    currentAcademics.data.push(action.payload);
                }
                return { ...state, allNetworkAcademics: allNetworkAcademics, networkAcademics: currentAcademics, loading: false, error: null };
            }
        case CREATE_NETWORK_ACADEMY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NETWORK_ACADEMY:
            return { ...state, loading: true };
        case EDIT_NETWORK_ACADEMY_SUCCESS:
            {
                let allNetworkAcademics = state.allNetworkAcademics;
                if (allNetworkAcademics) {
                    let index = allNetworkAcademics.findIndex((academy) => { return academy.id == action.payload.academy?.id });
                    if (index >= 0) {
                        allNetworkAcademics[index] = action.payload.academy;
                    }
                }
                let currentAcademics = state.networkAcademics;
                if (currentAcademics?.data) {
                    let index = currentAcademics.data.findIndex((academy) => { return academy.academy?.id == action.payload.academy?.id });
                    if (index >= 0) {
                        currentAcademics.data[index] = action.payload;
                    }
                }
                return { ...state, networkAcademics: currentAcademics, loading: false, error: null };
            }
        case EDIT_NETWORK_ACADEMY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NETWORK_ACADEMY:
            return { ...state, loading: true };
        case DELETE_NETWORK_ACADEMY_SUCCESS:
            {
                let allNetworkAcademics = state.allNetworkAcademics;
                if (allNetworkAcademics) {
                    let index = allNetworkAcademics.findIndex((academy) => { return academy.id == action.payload });
                    if (index >= 0) {
                        allNetworkAcademics.splice(index, 1);
                    }
                }
                let currentAcademics = state.networkAcademics;
                if (currentAcademics?.data) {
                    let index = currentAcademics.data.findIndex((academy) => { return academy.academy?.id == action.payload });
                    if (index >= 0) {
                        currentAcademics.data.splice(index, 1);
                    }
                }
                return { ...state, allNetworkAcademics: allNetworkAcademics, networkAcademics: currentAcademics, loading: false, error: null };
            }
        case DELETE_NETWORK_ACADEMY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_NETWORK_ACADEMICS:
            return { ...state, loading: true };
        case GET_ALL_NETWORK_ACADEMICS_SUCCESS:
            let allNetworkAcademics = action.payload.filter((element) => { return element != null });
            return { ...state, allNetworkAcademics: allNetworkAcademics, loading: false, error: null };
        case GET_ALL_NETWORK_ACADEMICS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_ACADEMICS:
            if (state.networkAcademicKeyword != action.payload.keyword || state.networkAcademicType != action.payload.type) {
                return { ...state, networkAcademicKeyword: action.payload.keyword, networkAcademicType: action.payload.type, networkAcademicPage: action.payload.page, networkAcademics: null, didLoadAcademicsDetails: false, keyword: action.payload.keyword, loading: true };
            }
            else {
                return { ...state, didLoadAcademicsDetails: false, networkAcademicPage: action.payload.page, loading: true };
            }
        case GET_NETWORK_ACADEMICS_SUCCESS:
            if (action.payload.keyword == state.networkAcademicKeyword && action.payload.type == state.networkAcademicType && action.payload.page == state.networkAcademicPage) {
                return { ...state, didLoadAcademicsDetails: action.payload.loadDetails ?? false, networkAcademics: action.payload.network, loading: false, error: null };
            }
        case GET_NETWORK_ACADEMICS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_NETWORK_EVENT:
            return { ...state, loading: true };
        case CREATE_NETWORK_EVENT_SUCCESS:
            {
                let allNetworkEvents = state.allNetworkEvents;
                if (allNetworkEvents) {
                    allNetworkEvents.push(action.payload);
                }
                let currentEvents = state.networkEvents;
                if (currentEvents.data) {
                    currentEvents.data.push(action.payload);
                }
                return { ...state, allNetworkEvents: allNetworkEvents, networkEvents: currentEvents, loading: false, error: null };
            }
        case CREATE_NETWORK_EVENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_NETWORK_EVENT:
            return { ...state, loading: true };
        case EDIT_NETWORK_EVENT_SUCCESS:
            {
                let allNetworkEvents = state.allNetworkEvents;
                if (allNetworkEvents) {
                    let index = allNetworkEvents.findIndex((event) => { return event.id == action.payload?.id });
                    if (index >= 0) {
                        allNetworkEvents[index] = action.payload;
                    }
                }
                let currentEvents = state.networkEvents;
                if (currentEvents?.data) {
                    let index = currentEvents.data.findIndex((event) => { return event.id == action.payload?.id });
                    if (index >= 0) {
                        currentEvents.data[index] = action.payload;
                    }
                }
                return { ...state, allNetworkEvents: allNetworkEvents, networkEvents: currentEvents, loading: false, error: null };
            }
        case EDIT_NETWORK_EVENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_NETWORK_EVENT:
            return { ...state, loading: true };
        case DELETE_NETWORK_EVENT_SUCCESS:
            {
                let allNetworkEvents = state.allNetworkEvents;
                if (allNetworkEvents) {
                    let index = allNetworkEvents.findIndex((event) => { return event.id == action.payload });
                    if (index >= 0) {
                        allNetworkEvents.splice(index, 1);
                    }
                }
                let currentEvents = state.networkEvents;
                if (currentEvents?.data) {
                    let index = currentEvents.data.findIndex((event) => { return event.id == action.payload });
                    if (index >= 0) {
                        currentEvents.data.splice(index, 1);
                    }
                }
                return { ...state, allNetworkEvents: allNetworkEvents, networkEvents: currentEvents, loading: false, error: null };
            }
        case DELETE_NETWORK_EVENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_ALL_NETWORK_EVENTS:
            return { ...state, loading: true };
        case GET_ALL_NETWORK_EVENTS_SUCCESS:
            return { ...state, allNetworkEvents: action.payload, loading: false, error: null };
        case GET_ALL_NETWORK_EVENTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_EVENTS:
            if (state.networkEventType != action.payload.type || state.networkEventKeyword != action.payload.keyword) {
                return { ...state, networkEventType: action.payload.type, networkEventKeyword: action.payload.keyword, networkEventPage: action.payload.page, networkEvents: null, didLoadEventsDetails: false, loading: true };
            }
            else {
                return { ...state, didLoadEventsDetails: false, networkEventPage: action.payload.page, loading: true };
            }
        case GET_NETWORK_EVENTS_SUCCESS:
            if (state.networkEventType == action.payload.type && state.networkEventKeyword == action.payload.keyword && action.payload.page == state.networkEventPage) {
                return { ...state, didLoadEventsDetails: action.payload.loadDetails ?? false, networkEvents: action.payload.network, loading: false, error: null };
            }
        case GET_NETWORK_EVENTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGOUT_USER:
            return INIT_STATE;

        case GET_NETWORK_COMPANY_EXPERIENCES:
            return { ...state, loading: true };
        case GET_NETWORK_COMPANY_EXPERIENCES_SUCCESS:
            return { ...state, experiences: action.payload, loading: false, error: null };
        case GET_NETWORK_COMPANY_EXPERIENCES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NETWORK_ACADEMIC_EXPERIENCES:
            return { ...state, loading: true };
        case GET_NETWORK_ACADEMIC_EXPERIENCES_SUCCESS:
            return { ...state, academicExperiences: action.payload, loading: false, error: null };
        case GET_NETWORK_ACADEMIC_EXPERIENCES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_EXPERIENCE:
            return { ...state, loading: true };
        case EDIT_EXPERIENCE_SUCCESS:
            {
                let current = state.experiences;
                if (current) {
                    let index = current.findIndex((element) => { return element.id == action.payload.id });
                    if (index >= 0) {
                        current[index] = action.payload;
                    }
                }
                return { ...state, experiences: current, loading: false, error: null };
            }
        case EDIT_EXPERIENCE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PEOPLE_BY_DOMAIN:
            return { ...state, loading: true };
        case GET_PEOPLE_BY_DOMAIN_SUCCESS:
            return { ...state, peopleByDomain: action.payload, loading: false, error: null };
        case GET_PEOPLE_BY_DOMAIN_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EVENT_GROUP:
            return { ...state, loading: true };
        case GET_EVENT_GROUP_SUCCESS:
            return { ...state, eventGroup: action.payload, loading: false, error: null };
        case GET_EVENT_GROUP_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_SIGNUP_REQUESTS:
            return { ...state, loading: true };
        case GET_SIGNUP_REQUESTS_SUCCESS:
            return { ...state, signupRequests: action.payload, loading: false, error: null };
        case GET_SIGNUP_REQUESTS_FAILED:
            return { ...state, signupRequests: null, error: action.payload, loading: false };

        case DECLINE_SIGNUP_REQUEST:
            return { ...state, loading: true };
        case DECLINE_SIGNUP_REQUEST_SUCCESS:
            {
                let signupRequests = state.signupRequests;
                if (signupRequests) {
                    let index = signupRequests.findIndex((element) => { return element.request?.id == action.payload.id });
                    if (index >= 0) {
                        signupRequests[index].request = action.payload;
                    }
                }
                return { ...state, signupRequests: signupRequests, loading: false, error: null };
            }
        case DECLINE_SIGNUP_REQUEST_FAILED:
            return { ...state, signupRequests: null, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Network;
