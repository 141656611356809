// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authProtectedRoutes as routes, authSubscriptions as repoRoutesSubscription, repositoriesProtectedRoutes as repoRoutes } from '../../routes';
import { changeActiveMenuFromLocationSuccess, initMenuSuccess, changeActiveMenuWithMenuIdSuccess, changeMenuTypeSuccess } from './actions';
import {
    CHANGE_ACTIVE_MENU_FROM_LOCATION, INIT_MENU, INIT_MENU_SUBCRIPTION, INIT_MENU_WITH_MENU_ITEMS,
    CHANGE_ACTIVE_MENU_WITH_MENU_ID,
    CHANGE_MENU_TYPE
} from './constants';
import assignIds from './utils';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = (menuItems, idPaths) => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems = matchingItems.concat(idPaths);
            matchingItems.push(menuItem.id);
        }
        else if (typeof menuItem.children !== 'undefined') {
            let currentPaths = [];
            currentPaths = currentPaths.concat(idPaths);
            currentPaths.push(menuItem.id);
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children, currentPaths)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems() {
    try {
        const menuItems = assignIds(routes);
        yield put(initMenuSuccess(menuItems));
    } catch (error) { }
}

/**
 * Initilizes the menu with items
 */
function* initMenuSubscriptions({ payload: menuItems }) {
    try {
        var replaceItems = assignIds(repoRoutesSubscription);
        if (menuItems && menuItems[0].children.length === 0) {
            replaceItems = replaceItems.filter(function (element) {
                return (element.id !== 4)
            });
        }
        if (replaceItems && replaceItems.length > 1) {
            replaceItems[1] = menuItems[0];
            if (replaceItems.length > 2 && menuItems.length > 1) {
                replaceItems[2] = menuItems[1];
            }
            if (replaceItems.length > 3 && menuItems.length > 2) {
                replaceItems[3] = menuItems[2];
            }
        }
        else {
            replaceItems.push(menuItems[0]);
            if (menuItems.length > 1) {
                replaceItems.push(menuItems[1]);
            }
            if (menuItems.length > 2) {
                replaceItems.push(menuItems[2]);
            }
        }
        yield put(initMenuSuccess(replaceItems));
    } catch (error) { }
}

function* initMenuWithItems({ payload: menuItems }) {
    try {
        yield put(initMenuSuccess(menuItems));
    } catch (error) { }
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation({ payload: currentMenuItems }) {
    try {
        const menuItems = currentMenuItems; //assignIds(routes);
        const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems, []);
        console.log(activatedMenuItemIds);
        yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
    } catch (error) { }
}

/**
 * change the active menu with menuId
 */
function* changeActiveMenuWithMenuId({ payload: menuId }) {
    try {
        const activatedMenuItemIds = menuId?.menuId;
        yield put(changeActiveMenuWithMenuIdSuccess(activatedMenuItemIds));
    } catch (error) { }
}

/**
 * change type of left menu
 */
function* changeMenuTypeInternal({ payload: { type, callbacks } }) {
    const {
        callbackOnSuccess
    } = callbacks;

    try {
        yield put(changeMenuTypeSuccess(type));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, type);
        }
    } catch (error) { }
}


/**
 * Watchers
 */
export function* watchMenuInit() {
    yield takeEvery(INIT_MENU, initMenuAndItems);
}

export function* watchMenuSubscription() {
    yield takeEvery(INIT_MENU_SUBCRIPTION, initMenuSubscriptions);
}

export function* watchMenuInitWithItems() {
    yield takeEvery(INIT_MENU_WITH_MENU_ITEMS, initMenuWithItems);
}

export function* watchMenuChange() {
    yield takeEvery(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

export function* watchMenuChangeWithId() {
    yield takeEvery(CHANGE_ACTIVE_MENU_WITH_MENU_ID, changeActiveMenuWithMenuId);
}

export function* watchChangeMenuType() {
    yield takeEvery(CHANGE_MENU_TYPE, changeMenuTypeInternal);
}


function* appMenuSaga() {
    yield all([fork(watchMenuInit), fork(watchMenuChange), fork(watchMenuInitWithItems), fork(watchMenuSubscription),
    fork(watchMenuChangeWithId), fork(watchChangeMenuType)]);
}

export default appMenuSaga;
