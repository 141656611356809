import React,{Component} from 'react';
import {Col,Collapse,Label,Row} from 'reactstrap';
import down_chevron from '../../assets/images/down-chevron.svg';
import logo from '../../assets/images/logo.png';
import right_chevron from '../../assets/images/right-chevron.svg';
import {getLocalizedString} from './../../helpers/utils';
import SVG from 'react-inlinesvg';
import { S3_BASE_URL } from '../../redux/constants';

class ToggleTabImageInform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTab1Open: false,
        };
    }

    componentDidMount() {

    }

    toggleTab1 = () => {
        this.setState({ isTab1Open: !this.state.isTab1Open });
    }

    render() {
        const s3_base_url = S3_BASE_URL;
        return (
            <React.Fragment>
                <Row className="mt-1">
                    <Col>
                        {this.state.isTab1Open ? <SVG src={down_chevron} className="icon-chevron mr-2" onClick={this.toggleTab1} /> :
                            <SVG src={right_chevron} className="icon-chevron mr-2" onClick={this.toggleTab1} />}
                        <Label onClick={this.toggleTab1}>{this.props.el && this.props.el.name ? getLocalizedString(this.props.el.name) : ''}</Label>
                    </Col>
                </Row>

                <Collapse isOpen={this.state.isTab1Open}>
                    <Row>
                        <Col xl={3}>
                            <div className="photo-border">
                                <img
                                    className="img-responsive fit-image"
                                    src={this.props.el ? s3_base_url + this.props.el.image: ''}
                                    alt={this.props.el && this.props.el.image }
                                    onError={(e) => {
                                        e.preventDefault();
                                        e.target.src=logo
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={9}>
                            <div className="benefit-description">
                                <Label className="title-dark-small-label">{this.props.el && this.props.el.description ? getLocalizedString(this.props.el.description) : ''}</Label>
                            </div>
                        </Col>
                    </Row>
                </Collapse>
            </React.Fragment>
        );
    }
}

export default ToggleTabImageInform;
