// @flow
import { LOGOUT_USER } from '../auth/constants';
import {
    CHANGE_ACTIVE_MENU_FROM_LOCATION,
    CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS, INIT_MENU,
    INIT_MENU_SUBCRIPTION, INIT_MENU_SUCCESS, INIT_MENU_WITH_MENU_ITEMS,
    CHANGE_ACTIVE_MENU_WITH_MENU_ID, CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS,
    CHANGE_MENU_TYPE, CHANGE_MENU_TYPE_SUCCESS,
    MENU_TYPE_HOME, MENU_TYPE_NONE
} from './constants';

const INIT_STATE = {
    menuType: MENU_TYPE_NONE,
    menuItems: [],
    loading: false,
    error: null
};

const AppMenu = (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_MENU:
            return { ...state, loading: true };
        case INIT_MENU_SUBCRIPTION:
            return { ...state, loading: true };
        case INIT_MENU_WITH_MENU_ITEMS:
            return { ...state, loading: true };
        case INIT_MENU_SUCCESS:
            // if (state.menuItems.length == 0 || state.menuItems[0].name != action.payload[0].name){
            return { ...state, menuItems: action.payload, activatedMenuItemIds: [], loading: false };
        // }
        // else {
        //     return { ...state, activatedMenuItemIds: [], loading: false };
        // }

        case CHANGE_ACTIVE_MENU_FROM_LOCATION:
            return { ...state };
        case CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
            return { ...state, ...action.payload };

        case CHANGE_ACTIVE_MENU_WITH_MENU_ID:
            return { ...state };
        case CHANGE_ACTIVE_MENU_WITH_MENU_ID_SUCCESS:
            /*
            let activatedMenuItemIds = state.activatedMenuItemIds;
            if (activatedMenuItemIds != null){
                action.payload.activatedMenuItemIds = action.payload.activatedMenuItemIds.concat(activatedMenuItemIds);
            }*/
            console.log('active Menu Items: ' + action.payload.activatedMenuItemIds);
            return { ...state, ...action.payload };

        case CHANGE_MENU_TYPE:
            return { ...state };
        case CHANGE_MENU_TYPE_SUCCESS:
            return state.menuType != action.payload ? { ...state, menuType: action.payload } : { ...state };
        case LOGOUT_USER:
            return INIT_STATE;

        default:
            return state;
    }
};

export default AppMenu;
