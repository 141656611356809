// File: i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { BASE_URL } from "../redux/constants";
import { getCurrentLanguage, setAvailableLanguages } from "../helpers/utils";

//import TRANSLATIONS_EN from "./en/translation.json";
//import TRANSLATIONS_FR from "./fr/translation.json";

const axios = require('axios').default;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    /*
    resources: {
      en: {
        translations: TRANSLATIONS_EN
      },
      fr: {
        translations: TRANSLATIONS_FR
      }
    }*/
  });

i18n.init({
  interpolation: {
    format: function (value, format, lng) {
      if (value instanceof Date) return moment(value).format(format);
      if (typeof value === "number")
        return new Intl.NumberFormat().format(value);
      return value;
    }
  }
});



const loadFromLocalizationFromDatabase = () => {
  const url = BASE_URL + 'api/localize/all';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
};
  const instance = axios.create({
    baseURL: url,
    timeout: 10000,
    headers: options.headers
  });
  instance.get().then((response) => {
    // console.log(response)
    return response.data;
  })
    .then(translations => {
      //return json;
      // i18n.addResourceBundle('en', 'translations', {
      //   "Welcome back!": 'SAMMMMMMMMPLLLLLLLLLLLEEEE'
      // });

      var languages = [];
      translations.forEach(translation => {
        i18n.addResourceBundle(translation.id, 'translations', translation.translations);
        languages.push({id: translation.id, name: translation.name});
      });
       
      setAvailableLanguages(languages);

      const language = getCurrentLanguage();
      i18n.changeLanguage(language);
    })
    .catch(error => {
      // console.log(error);
    });

  
}

export { i18n, loadFromLocalizationFromDatabase };
