// @flow
import jwtDecode from 'jwt-decode';
import * as layoutConstants from '../constants/layout';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const decoded = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }

};

const AuthenticationStatus = {
    UnAuthorize: 0,
    LoggedIn: 1,
    PhoneNotVerified: 2,
    EmailNotVerified: 3,
    ProfileNotProvided: 4,
    NotSubscribe: 5,
    ProfileVerified: 6,
};

const userAuthenticatedStatus = () => {
    const user = getLoggedInUser();
    if (!user) {
        return AuthenticationStatus.UnAuthorize;
    }

    try {
        const decoded = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return AuthenticationStatus.UnAuthorize;
        } else {
            const profile = getLoggedInUserProfile();
            
            if (profile && profile.security ){
                if (profile.security.isValidated){
                    if (profile.user.dateUpdate) {
                        let products = profile.security?.products ?? [];
                        if (products.length == 0 || products.find((product) => { return product.id == "Product::individualFree"}) == null) {
                            return AuthenticationStatus.NotSubscribe;
                        }
                        else {
                            return AuthenticationStatus.ProfileVerified;
                        }
                    }
                    else {
                        return AuthenticationStatus.ProfileNotProvided;
                    }
                }
                else if (profile.user.mobileNumber == null) {
                    return AuthenticationStatus.PhoneNotVerified;
                }
                else {
                    return AuthenticationStatus.EmailNotVerified;
                }
            }

            return AuthenticationStatus.LoggedIn;
        }
    } catch (error) {
        return AuthenticationStatus.NotLogin;
    }

};

const userAuthenticatedStatusCompany = (email) => {
    const user = getLoggedInUser();
    if (!user) {
        return AuthenticationStatus.UnAuthorize;
    }

    try {
        const decoded = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return AuthenticationStatus.UnAuthorize;
        } else {
            const profile = getLoggedInUserProfile();
            var authorizationStatus = AuthenticationStatus.LoggedIn;
            
            if (profile && profile.security && profile.security.companies.length > 0){
                profile.security.companies.forEach(security => {
                    if (security.email === email) {
                        if (security.isValidated){
                            if (profile.user.dateUpdate) {
                                return authorizationStatus = AuthenticationStatus.ProfileVerified;
                            }
                            else {
                                return authorizationStatus = AuthenticationStatus.ProfileNotProvided;
                            }
                        }
                        else {
                            return authorizationStatus = AuthenticationStatus.EmailNotVerified;
                        }
                        
                    }
                    
                });   
            }
            return authorizationStatus;
        }
    } catch (error) {
        return AuthenticationStatus.NotLogin;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    var user = localStorage.getItem('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

/**
 * Returns the admin user
 */
 const getAdminUser = () => {
    var user = localStorage.getItem('admin-user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};


/**
 * Returns the logged in user profile
 */
 const getLoggedInUserProfile = () => {
     var profile = localStorage.getItem('profile');
     
     return profile ? (typeof profile == 'object' ? profile : JSON.parse(profile)) : null;
};

/**
 * Returns the logged in user profile
 */
 const getLoggedInUserPayment = () => {
    var payment = localStorage.getItem('payment');
    
    return payment ? (typeof payment == 'object' ? payment : JSON.parse(payment)) : null;
};

/**
 * Sets the session
 * @param {*} user payment
 */
 const setLoggedInUserPayment = payment => {

    if (payment){
        localStorage.setItem('payment', JSON.stringify(payment));
    }
    else {
        localStorage.removeItem('payment');
    }
};

/**
 * Sets the session
 * @param {*} user
 */
 const setLoggedInUser = user => {

    if (user){
        localStorage.setItem('user', JSON.stringify(user));
    }
    else {
        localStorage.removeItem('user');
        localStorage.removeItem('admin-user');
    }
};

/**
 * Sets Impersonate User
 * @param {*} user
 */
 const setImpersonateUser = user => {
    if (user){
        var savedAdminUser = getAdminUser();
        if (savedAdminUser == null){
            var currentUser = getLoggedInUser();
            var profile = getLoggedInUserProfile();
            currentUser.firstName = profile?.user?.firstName;
            currentUser.lastName = profile?.user?.lastName;
            localStorage.setItem('admin-user', JSON.stringify(currentUser));
        }
        localStorage.setItem('user', JSON.stringify(user));
    }
    else {
        localStorage.removeItem('admin-user');
    }
};

/**
 * Sets the login user profile to cookie
 * @param {*} profile
 */
 const setLoggedInUserProfile = profile => {
     if (profile){
        localStorage.setItem('profile', JSON.stringify(profile));
     }
     else {
        localStorage.removeItem('profile');
     }
};


/**
 * Sets the theme
 * @param {*} theme
 */
 const setTheme = theme => {
    if (theme){
        localStorage.setItem('theme', JSON.stringify(theme));
    }
    else {
        localStorage.removeItem('theme');
    }
};

const getTheme = () => {
    var theme = localStorage.getItem('theme');
    return theme ? (typeof theme == 'object' ? theme : JSON.parse(theme)) : layoutConstants.THEME_DEFAULT;
};

const isDarkMode = ()=>{
    var isDark = false;
    var theme = getTheme();
    if (theme == layoutConstants.THEME_DARK) {
        isDark = true;
    } else if (theme == layoutConstants.THEME_DEVICE) {
        isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    return isDark;
}


/**
 * Sets the layout
 * @param {*} layout
 */
 const setLayout = layout => {
    if (layout){
        localStorage.setItem('layout', JSON.stringify(layout));
    }
    else {
        localStorage.removeItem('layout');
    }
};

const getLayout = () => {
    var layout = localStorage.getItem('layout');
    return layout ? (typeof layout == 'object' ? layout : JSON.parse(layout)) : layoutConstants.LAYOUT_VERTICAL;
};

/**
 * Sets the left bar with
 * @param {*} layout
 */
 const setSidebarWidth = (theme) => {
    localStorage.setItem('left-side-bar-width', theme);
};

const getSidebarWidth = () => {
    var layout = localStorage.getItem('left-side-bar-width');
    return layout == 'true' ?? true;
}


/**
 * Sets the selected network person profile to cookie
 * @param {*} person
 */
 const setNetworkPerson = person => {
    if (person){
        localStorage.setItem('networkPerson', JSON.stringify(person));
    }
    else {
        localStorage.removeItem('networkPerson');
    }
};

/**
 * Get the network person profile from cookie
 * @param {*} profile
 */
 const getNetworkPerson = () => {
    var person = localStorage.getItem('networkPerson');
    return person ? (typeof person == 'object' ? person : JSON.parse(person)) : null;
}


/**
 * Sets the selected network company to cookie
 * @param {*} company
 */
 const setNetworkCompany = company => {
    if (company){
        localStorage.setItem('networkCompany', JSON.stringify(company));
    }
    else {
        localStorage.removeItem('networkCompany');
    }
};

/**
 * Get the network company from cookie
 * @param {*} company
 */
 const getNetworkCompany = () => {
    var company = localStorage.getItem('networkCompany');
    return company ? (typeof company == 'object' ? company : JSON.parse(company)) : null;
}

/**
 * Sets the selected network academics to cookie
 * @param {*} academics
 */
const setNetworkAcademics = academics => {
    if (academics){
        localStorage.setItem('networkAcademics', JSON.stringify(academics));
    }
    else {
        localStorage.removeItem('networkAcademics');
    }
};

/**
 * Get the network academics from cookie
 * @param {*} academics
 */
 const getNetworkAcademics = () => {
    var academics = localStorage.getItem('networkAcademics');
    return academics ? (typeof academics == 'object' ? academics : JSON.parse(academics)) : null;
}


export {
    isUserAuthenticated,userAuthenticatedStatus,getLoggedInUser,getLoggedInUserProfile,
    setLoggedInUser,setLoggedInUserProfile,AuthenticationStatus,userAuthenticatedStatusCompany,
    setLoggedInUserPayment, getLoggedInUserPayment,
    setTheme, getTheme, setSidebarWidth,  getSidebarWidth,
    setLayout, getLayout, isDarkMode,
    setNetworkPerson, getNetworkPerson,
    setNetworkCompany, getNetworkCompany,
    setNetworkAcademics, getNetworkAcademics,
    setImpersonateUser, getAdminUser
};

