import { CheckCircle, Circle, Bell, PieChart } from 'react-feather';
import {
    AcademicsIcon, MeIcon, DepartmentsIcon,
    TechnologiesIcon, IndustriesIcon, IssuesIcon, ConnexionsIcon,
    IssuesProjectIcon, IssuesCorporateIcon, IssuesTechnologyIcon, IssuesHumanIcon,
    IssuesDepartmentalIcon, ConnexionsCoachingIcon, ConnexionsChangeIcon, ConnexionsRecruitmentIcon,
    ConnexionsEventingIcon, ConnexionsTrainingIcon, ConnexionsEducationIcon, ConnexionsIntegrationIcon,
    ConnexionsStrategyIcon, ConnexionsTechnologyIcon, SettingsApplicationIcon, SettingsSubscriptionsIcon,
    SettingsProfileIcon, SettingsBackgroundIcon, Validations, NetworkIcon, RepositoriesAllDepartmentsIcon, RepositoriesAllIndustriesIcon, RepositoriesAllIssuesIcon, RepositoriesEducationIcon, RepositoriesDataTechnologyIcon
} from '../../../../components/Icons';
import { getLoggedInUserProfile, getSidebarWidth } from '../../../../helpers/authUtils';
import { getLocalizedString } from '../../../../helpers/utils';
import { SEARCH_CATEGORY_EDUCATION, SEARCH_CATEGORY_ALL, SEARCH_CATEGORY_CAREER, SEARCH_CATEGORY_COMPETENCY, SEARCH_CATEGORY_TECHNOLOGY, SEARCH_CATEGORY_USER, SEARCH_PERSONAL_NETWORK, SEARCH_CATEGORY_ACADEMY, SEARCH_CATEGORY_COMPANY } from '.././../../../redux/search/constants';
import * as layoutConstants from '../../../../constants/layout';

const PROFILE_MENU_ID = 10000;
const PROFILE_MY_SUMMARY_MENU_ID = PROFILE_MENU_ID * 10 + 1;

const FACULTY_MENU_ID = 20000;
const INDUSTRY_MENU_ID = 30000;
const DEPARTMENT_MENU_ID = 40000;
const TECHNOLOGY_MENU_ID = 50000;
const ISSUES_MENU_ID = 60000;
const NETWORK_MENU_ID = 70000;
export const BACKGROUND_MENU_ID = 80000;
const PROFILE_ACADEMICS_MENU_ID = BACKGROUND_MENU_ID * 10 + 2;
const PROFILE_COMPANIES_MENU_ID = BACKGROUND_MENU_ID * 10 + 3;
const PROFILE_EVENTS_MENU_ID = BACKGROUND_MENU_ID * 10 + 4;
const PROFILE_COMPETENCIES_MENU_ID = BACKGROUND_MENU_ID * 10 + 5;
const PROFILE_VALIDATIONS_MENU_ID = BACKGROUND_MENU_ID * 10 + 6;
const PROFILE_TRAINING_MENU_ID = BACKGROUND_MENU_ID * 10 + 7;
const PROFILE_EXPERIENCE_MENU_ID = BACKGROUND_MENU_ID * 10 + 8;

export const ALERTS_MENU_ID = 90000;

const initHomeMenuItems = (p, menuHierarchy, alerts, competencyInfo) => {
    const profile = getLoggedInUserProfile();
    let menuItems = [];
    let packName = 'individualFree';
    if (profile?.security?.products?.length > 0) {
        packName = profile?.security?.products[0].id ?? "individualFree";
        if (packName.startsWith("Product::")) {
            packName = packName.substring(9);
        }
    }

    let home = profile?.user?.menus?.home;
    if (home != null) {
        if (home.me[packName] ?? false) {
            menuItems.push({
                name: 'profile.me',
                localIcon: MeIcon,
                id: PROFILE_MENU_ID,
                path: '/me',
                children: []
            });

            let children = home?.me?.children;
            if (children != null) {
                if (children.my_summary[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'me.mysummary',
                        localIcon: MeIcon,
                        id: PROFILE_MY_SUMMARY_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                        path: '/my-summary'
                    });
                }

                if (children.education[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'academic.academics',
                        localIcon: AcademicsIcon,
                        path: '/academics',
                        id: FACULTY_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                    });
                }

                if (children.industries[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'profile.industries',
                        localIcon: IndustriesIcon,
                        path: '/industries',
                        id: INDUSTRY_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                    });
                }
                if (children.departments[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'profile.departments',
                        localIcon: DepartmentsIcon,
                        path: '/departments',
                        id: DEPARTMENT_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                    });
                }
                if (children.technologies[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'techGroup.technologies',
                        localIcon: TechnologiesIcon,
                        path: '/technologies',
                        id: TECHNOLOGY_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                    });
                }
                if (children.issues[packName] ?? false) {
                    menuItems[0].children.push({
                        name: 'topBar.issues',
                        localIcon: IssuesIcon,
                        path: 'my-issues',
                        id: ISSUES_MENU_ID,
                        parentId: PROFILE_MENU_ID,
                    });
                }

                if (children.validations[packName] ?? false) {
                    let childValidations = {
                        name: 'me.validations',
                        id: PROFILE_VALIDATIONS_MENU_ID,
                        localIcon: Validations,
                        parentId: PROFILE_MENU_ID,
                        path: '/validations'
                    };
                    childValidations.children = [
                        { name: 'me.validations.in', id: PROFILE_VALIDATIONS_MENU_ID * 10 + 1, parentId: PROFILE_VALIDATIONS_MENU_ID, path: '/validations-in' },
                        { name: 'me.validations.out', id: PROFILE_VALIDATIONS_MENU_ID * 10 + 2, parentId: PROFILE_VALIDATIONS_MENU_ID, path: '/validations-out' }
                    ];
                    menuItems[0].children.push(childValidations);
                }
            }
        }

        if (home.network[packName] ?? false) {
            let item = {
                name: 'home.network',
                localIcon: NetworkIcon,
                id: NETWORK_MENU_ID,
                path: '/network',
                children: []
            };

            let children = home?.network?.children;
            if (children != null) {
                if (children.person[packName] ?? false) {
                    item.children.push({ name: 'general.person', localIcon: MeIcon, id: NETWORK_MENU_ID * 10 + 1, parentId: NETWORK_MENU_ID, path: '/network-person' });
                }
                if (children.company[packName] ?? false) {
                    item.children.push({ name: 'subscription.company', localIcon: IndustriesIcon, id: NETWORK_MENU_ID * 10 + 2, parentId: NETWORK_MENU_ID, path: '/network-company' });
                }
                if (children.academics[packName] ?? false) {
                    item.children.push({ name: 'education.academics', localIcon: AcademicsIcon, id: NETWORK_MENU_ID * 10 + 3, parentId: NETWORK_MENU_ID, path: '/network-academics' });
                }
                if (children.events[packName] ?? false) {
                    item.children.push({ name: 'me.events', localIcon: ConnexionsEventingIcon, id: NETWORK_MENU_ID * 10 + 4, parentId: NETWORK_MENU_ID, path: '/network-events' });
                }
            }

            menuItems.push(item);
        }

        if (home.background[packName] ?? false) {
            let item = {
                name: 'profile.background',
                id: BACKGROUND_MENU_ID,
                localIcon: SettingsBackgroundIcon,
                path: '/background',
                children: []
            };

            let children = home?.background?.children;
            if (children != null) {
                if (children.educations[packName] ?? false) {
                    let childAcademics = { name: 'academic.academics', id: PROFILE_ACADEMICS_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/education' }
                    // if (profile?.academics?.academics?.length > 0) {
                    //     childAcademics.children = [];
                    //     profile.academics.academics.forEach((academy, index) => {
                    //         if (academy?.academyPlaces?.length > 0 && academy?.academyDetail != null) {
                    //             const child = { name: academy?.academyDetail?.name, id: academy?.academyDetail.id, parentId: PROFILE_ACADEMICS_MENU_ID };
                    //             let academicId = academy?.id
                    //             child.path = "/education-info/" + academicId;//+ '/' + academy?.facultyId + '/' + academy?.graduateDegreeId;
                    //             childAcademics.children.push(child);
                    //         }
                    //     });
                    // }
                    item.children.push(childAcademics);
                }
                if (children.trainings[packName] ?? false) {
                    let childTrainings = { name: 'me.trainings', id: PROFILE_TRAINING_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/trainings' };
                    // if (profile?.trainings?.length > 0) {
                    //     childTrainings.children = [];
                    //     const competenciesType = profile?.competencyType?.competencyType ?? [];
                    //     profile.trainings.forEach((training, index) => {
                    //         if (training != null) {
                    //             var test = '';
                    //             var competencyName = '';

                    //             for (let index = 0; index < competenciesType.length; index++) {
                    //                 const element = competenciesType[index];
                    //                 if (training.trainings?.length > 0 && element.id == training.trainings[0].idType) {
                    //                     competencyName = element.value ? getLocalizedString(element.value) : '';
                    //                 }
                    //             }
                    //             const child = { name: competencyName ?? '', id: training.id, parentId: PROFILE_TRAINING_MENU_ID };
                    //             // let academicId = academy?.academyDetail.id?.substring(15) ?? '0'
                    //             child.path = "/training-info/" + (training.id ?? '0') + '/' + (training.trainings[0].idType ?? '0');
                    //             childTrainings.children.push(child);
                    //         }
                    //     });
                    // }
                    item.children.push(childTrainings);
                }
                if (children.careers[packName] ?? false) {
                    let childCompanies = { name: 'company.companies', id: PROFILE_COMPANIES_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/companies' };
                    // if (profile?.careers?.length > 0) {
                    //     childCompanies.children = [];
                    //     profile.careers.forEach((element, index) => {

                    //         let company = element.company;
                    //         let companyName = company?.name ? (company.name + (company.companyIndex ? (" (" + company.companyIndex + ')') : "")) : ''
                    //         const child = { name: companyName, id: company?.id, parentId: PROFILE_COMPANIES_MENU_ID };
                    //         let companyId = element?.career?.companyId?.substring(15) ?? '0';
                    //         let path = "/company-info/" + element?.career?.id;
                    //         child.path = path;
                    //         childCompanies.children.push(child);
                    //     });
                    // }
                    item.children.push(childCompanies);
                }
                if (children.events[packName] ?? false) {
                    let childEvents = { name: 'me.events', id: PROFILE_EVENTS_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/events' };
                    // if (profile?.events?.length > 0) {
                    //     let subjectTypes = (competencyInfo?.type?.competencyType ?? []).map((type) => { type.label = getLocalizedString(type.value); return type });
                    //     childEvents.children = [];
                    //     profile.events.forEach((event) => {
                    //         (event.networkEvent?.subjects ?? []).forEach(subject => {
                    //             let type = subject.type;
                    //             let index = childEvents.children.findIndex((child) => { return child.id == type });
                    //             if (index < 0) {
                    //                 let subjectName = subjectTypes.find((s) => { return s.id == type })?.label;
                    //                 let child = { name: subjectName ?? '', id: type, parentId: PROFILE_EVENTS_MENU_ID };
                    //                 child.path = "/event-category/" + type;
                    //                 childEvents.children.push(child);
                    //             }
                    //         });
                    //     });
                    // }
                    item.children.push(childEvents);
                }
                if (children.data_experiences[packName] ?? false) {
                    let childExperience = { name: 'home.experience', id: PROFILE_EXPERIENCE_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/experience' };
                    // if (profile?.experiences?.length > 0) {
                    //     const experiences = profile?.experiences;
                    //     let experienceTypes = menuHierarchy?.allActionGroup?.children ?? [];
                    //     let categories = experienceTypes.map((type) => { return { 'type': type, experiences: [] } });
                    //     categories.push({ 'type': { id: -1, 'name': 'Other' }, experiences: [] });

                    //     for (let i = 0; i < experiences.length; i++) {
                    //         let index = categories.findIndex((element) => { return element.type.id == experiences[i].type })
                    //         if (index >= 0) {
                    //             categories[index].experiences.push(experiences[i]);
                    //         }
                    //         else {
                    //             categories[categories.length - 1].experiences.push(experiences[i]);
                    //         }
                    //     }
                    //     categories = categories.filter((element) => { return element.experiences.length > 0 })

                    //     childExperience.children = [];
                    //     categories.forEach((category, index) => {
                    //         const child = { name: category.type?.name ?? '', id: category.type?.id, parentId: PROFILE_EXPERIENCE_MENU_ID, entity: category };
                    //         child.path = "/experience-info/" + (category.type.id ?? '0');// + '/' + (training.trainings[0].idType ?? '0');
                    //         childExperience.children.push(child);
                    //     });
                    // }
                    item.children.push(childExperience);
                }
                if (children.competencies[packName] ?? false) {
                    let childCompetencies = { name: 'me.competencies', id: PROFILE_COMPETENCIES_MENU_ID, parentId: BACKGROUND_MENU_ID, path: '/competencies' };
                    // if (profile?.competencies?.length > 0) {
                    //     childCompetencies.children = [];
                    //     const competenciesType = profile?.competencyType?.competencyType ?? [];
                    //     profile.competencies.forEach((competency, index) => {
                    //         var competencyName = '';
                    //         var idType = null;
                    //         if (competency != null) {
                    //             if (competency.idType != null) {
                    //                 idType = competency.idType;
                    //             }

                    //             for (let index = 0; index < competenciesType.length; index++) {
                    //                 const element = competenciesType[index];
                    //                 if (element.id == idType) {
                    //                     competencyName = element.value ? getLocalizedString(element.value) : '';
                    //                 }
                    //             }
                    //             const child = { name: competencyName, id: idType, parentId: PROFILE_COMPETENCIES_MENU_ID };
                    //             let competencyId = competency?.id ?? '0';
                    //             child.path = "/competencies/" + idType ?? "1";

                    //             if (!childCompetencies.children.some((comp) => { return comp.id == child.id })) {
                    //                 childCompetencies.children.push(child);
                    //             }

                    //         }
                    //     });
                    // }
                    item.children.push(childCompetencies);
                }
            }

            menuItems.push(item);
        }

        if (home.alerts[packName] ?? false) {
            let item = {
                name: 'general.alert',
                id: ALERTS_MENU_ID,
                icon: Bell,
                path: '/alerts',
                children: []
            };

            let alertCategories = categoriesFromAlerts(alerts?.alerts ?? []);

            alertCategories.forEach((category, index) => {
                item.children.push({
                    name: category.name ?? '',
                    id: ALERTS_MENU_ID + index,
                    parentId: ALERTS_MENU_ID, path: '/alerts/' + category.name
                })
            });

            menuItems.push(item);
        }

        // if (home.dashboard[packName] ?? false) {
        menuItems.unshift({
            name: 'home.dashboard',
            localIcon: MeIcon,
            id: PROFILE_MENU_ID,
            path: '/dashboard',
        });
        // }

    }

    return menuItems;
};

const isMenuIdExist = (menuItem, checkedItems) => {
    if (checkedItems.indexOf(menuItem.id) >= 0) {
        return true;
    }
    checkedItems.push(menuItem.id);
    for (let i = 0; i < menuItem.children?.length; i++) {
        if (isMenuIdExist(menuItem.children[i], checkedItems)) {
            return true;
        }
    }
}

const initIssuesMenuItems = (menuHierarchy) => {
    const profile = getLoggedInUserProfile();
    let menuItems = [];
    let packName = 'individualFree';
    if (profile?.security?.products?.length > 0) {
        packName = profile?.security?.products[0].id ?? "individualFree";
        if (packName.startsWith("Product::")) {
            packName = packName.substring(9);
        }
    }

    let children = [];
    buildMenuWithData(children, menuHierarchy.issueGroup?.children, '/issue/', 1, 4);

    let issues = profile?.user?.menus?.issues;

    if (!(enableOption(issues?.project, packName))) {
        let index = children.find((item) => { return item.id == 448 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(issues?.corporate, packName))) {
        let index = children.find((item) => { return item.id == 358 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(issues?.technology, packName))) {
        let index = children.find((item) => { return item.id == 332 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(issues?.human, packName))) {
        let index = children.find((item) => { return item.id == 398 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(issues?.departmental, packName))) {
        let index = children.find((item) => { return item.id == 144 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }

    if (children.length > 0) {
        menuItems = [{
            name: 'topBar.issues',
            localIcon: IssuesIcon,
            path: '/issues',
            id: 1,
            children: children
        }
        ];
        menuItems = menuItems.concat(children);

        for (let i = 1; i < menuItems.length; i++) {
            let item = menuItems[i];
            if (item.id == 448) {
                item.localIcon = IssuesProjectIcon
            }
            else if (item.id == 358) {
                item.localIcon = IssuesCorporateIcon
            }
            else if (item.id == 332) {
                item.localIcon = IssuesTechnologyIcon
            }
            else if (item.id == 398) {
                item.localIcon = IssuesHumanIcon
            }
            else if (item.id == 144) {
                item.localIcon = IssuesDepartmentalIcon
            }
        }
    }

    return menuItems;
};

const enableOption = (option, packName) => {
    if (option == null) {
        return false
    }
    return option[packName] ?? false
}

const initConnexionsMenuItems = (menuHierarchy) => {
    const profile = getLoggedInUserProfile();
    let menuItems = [];
    let packName = 'individualFree';
    if (profile?.security?.products?.length > 0) {
        packName = profile?.security?.products[0].id ?? "individualFree";
        if (packName.startsWith("Product::")) {
            packName = packName.substring(9);
        }
    }

    let children = [];
    buildMenuWithData(children, menuHierarchy?.nexionsGroup?.children, '/connexion/', 4);

    let connecxions = profile?.user?.menus?.connecxions;
    if (!(enableOption(connecxions?.coaching, packName))) {
        let index = children.find((item) => { return item.id == 308 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.change, packName))) {
        let index = children.find((item) => { return item.id == 309 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.recruitment, packName))) {
        let index = children.find((item) => { return item.id == 373 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.eventing, packName))) {
        let index = children.find((item) => { return item.id == 294 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.training, packName))) {
        let index = children.find((item) => { return item.id == 439 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.education, packName))) {
        let index = children.find((item) => { return item.id == 344 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.integration, packName))) {
        let index = children.find((item) => { return item.id == 379 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.strategy, packName))) {
        let index = children.find((item) => { return item.id == 397 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }
    if (!(enableOption(connecxions?.technology, packName))) {
        let index = children.find((item) => { return item.id == 447 });
        if (index >= 0) {
            children.splice(index, 1);
        }
    }

    if (children.length > 0) {
        menuItems = [{
            name: 'topBar.connexions',
            localIcon: ConnexionsIcon,
            path: '/connexions',
            id: 1,
            children: children
        }
        ];
        menuItems = menuItems.concat(children);

        for (let i = 1; i < menuItems.length; i++) {
            let item = menuItems[i];
            if (item.id == 308) {
                item.localIcon = ConnexionsCoachingIcon
            }
            else if (item.id == 309) {
                item.localIcon = ConnexionsChangeIcon
            }
            else if (item.id == 373) {
                item.localIcon = ConnexionsRecruitmentIcon
            }
            else if (item.id == 294) {
                item.localIcon = ConnexionsEventingIcon
            }
            else if (item.id == 439) {
                item.localIcon = ConnexionsTrainingIcon
            }
            else if (item.id == 344) {
                item.localIcon = ConnexionsEducationIcon
            }
            else if (item.id == 379) {
                item.localIcon = ConnexionsIntegrationIcon
            }
            else if (item.id == 397) {
                item.localIcon = ConnexionsStrategyIcon
            }
            else if (item.id == 447) {
                item.localIcon = ConnexionsTechnologyIcon
            }
        }
    }

    return menuItems;
};

const initSettingsMenuItems = (menuHierarchy) => {
    const profile = getLoggedInUserProfile();
    let menuItems = [];
    let packName = 'individualFree';
    if (profile?.security?.products?.length > 0) {
        packName = profile?.security?.products[0].id ?? "individualFree";
        if (packName.startsWith("Product::")) {
            packName = packName.substring(9);
        }
    }

    let settings = profile?.user?.menus?.settings;
    if (settings != null) {
        if (settings.profile[packName] ?? false) {
            menuItems.push({
                name: 'profile.profile',
                id: 4,
                localIcon: SettingsProfileIcon,
                children: []
            });

            let children = settings?.profile?.children;
            if (children != null) {
                if (children.personal_information[packName] ?? false) {
                    menuItems[0].children.push({ name: 'profile.personalInformation', id: 41, path: '/profile' });
                }
                if (children.link_in[packName] ?? false) {
                    menuItems[0].children.push({ name: 'profile.linkedIn', id: 42, path: '/linked-in' });
                }
                if (children.photo[packName] ?? false) {
                    menuItems[0].children.push({ name: 'profile.photo', id: 43, path: '/photo' });
                }
                if (children.cv[packName] ?? false) {
                    menuItems[0].children.push({ name: 'profile.cv', id: 44, path: '/cv' });
                }
            }
        }
        if (settings.subscriptions[packName] ?? false) {
            let item = {
                name: 'profile.subscriptions',
                id: 5,
                localIcon: SettingsSubscriptionsIcon,
                children: []
            };

            let children = settings?.subscriptions?.children;
            if (children != null) {
                if (children.subsription_as_individual[packName] ?? false) {
                    item.children.push({ name: 'sub.SubscriptionasanIndividual', id: 51, path: '/individual-subscription' });
                }
                if (children.member_of_company[packName] ?? false) {
                    item.children.push({ name: 'sub.Subscriptionasacompanyfellow', id: 52, path: '/company-subscription' });
                }
                if (children.member_of_academy[packName] ?? false) {
                    item.children.push({ name: 'general.memberOfAcademy', id: 53, path: '/academy-subscription' });
                }
            }

            menuItems.push(item);
        }

        if (settings.application[packName] ?? false) {
            let item = {
                name: 'menu.Application',
                id: 6,
                localIcon: SettingsApplicationIcon,
                children: [],
            };

            let children = settings?.application?.children;
            if (children != null) {
                if (children.interface[packName] ?? false) {
                    item.children.push({ name: 'Interface', id: 0, parentId: 6, path: '/application/interface' });
                }
                if (children.api[packName] ?? false) {
                    item.children.push({ name: 'API', id: 1, parentId: 6, path: '/application/api' });
                }
            }

            menuItems.push(item);
        }
    }

    return menuItems;
}

const initSearchMenuItems = (categories, personalNetwork) => {
    const menuItems = [];
    menuItems.push({
        name: 'category.allCategories',
        id: SEARCH_CATEGORY_ALL,
        path: '/search/',
        icon: categories?.length == 0 ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'separator',
        id: 100001,
    });

    menuItems.push({
        name: 'signIn.user',
        id: SEARCH_CATEGORY_USER,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'techGroup.technologies',
        id: SEARCH_CATEGORY_TECHNOLOGY,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'academic.academics',
        id: SEARCH_CATEGORY_EDUCATION,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'company.companies',
        id: SEARCH_CATEGORY_CAREER,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'me.competencies',
        id: SEARCH_CATEGORY_COMPETENCY,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'subscription.company',
        id: SEARCH_CATEGORY_COMPANY,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'education.academics',
        id: SEARCH_CATEGORY_ACADEMY,
        icon: (categories?.find((element) => { return element == SEARCH_CATEGORY_USER }) != null) ? CheckCircle : Circle,
    });

    menuItems.push({
        name: 'separator',
        id: 100001,
    });

    menuItems.push({
        name: 'search.myPersonalNetwork',
        id: SEARCH_PERSONAL_NETWORK,
    });

    return menuItems;
};

const initAlertsMenuItems = (existedAlerts) => {
    const menuItems = [];

    menuItems.push({
        name: 'category.allCategories',
        id: 7,
        localIcon: DepartmentsIcon,
        children: null,
        path: '/alerts/',
    });

    menuItems.push({
        name: 'separator',
        id: 8,
    });

    existedAlerts.forEach((element, index) => {
        menuItems.push({
            name: element.name,
            id: 9 + index,
            localIcon: DepartmentsIcon,
            children: null,
            path: '/alerts/' + element.name,
        });
    });

    return menuItems;
};

const initRepositoriesMenuItems = (menuHierarchy, repositories) => {
    if (document.body) {
        if (getSidebarWidth()) {
            if (document.body.classList.contains("sidebar-enable"))
                document.body.classList.remove("sidebar-enable");
            this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
        } else {
            if (document.body.classList.contains("left-side-menu-condensed"))
                document.body.classList.remove("left-side-menu-condensed");
            document.body.classList.add("sidebar-enable");
        }
    }

    const profile = getLoggedInUserProfile();
    let menuItems = [];
    let packName = 'individualFree';
    if (profile?.security?.products?.length > 0) {
        packName = profile?.security?.products[0].id ?? "individualFree";
        if (packName.startsWith("Product::")) {
            packName = packName.substring(9);
        }
    }

    let repoProfile = profile?.user?.menus?.repositories;
    if (repoProfile.all_faculties[packName] ?? false) {
        if ((menuHierarchy != null) && (menuHierarchy?.facultyGroup?.children?.length > 0)) {
            let menu = {
                name: menuHierarchy.facultyGroup.name,
                id: menuHierarchy?.facultyGroup?.id,
                localIcon: RepositoriesEducationIcon,
                path: "/repositories/academics/" + menuHierarchy?.facultyGroup?.id,
                children: []
            }
            menuItems.push(menu);
            buildMenuWithData(menu.children, menuHierarchy.facultyGroup.children, '/repositories/academics/', menu.id, 5);
        }
    }

    if (repoProfile.data_technologies[packName] ?? false) {
        if (repositories != null) {
            let dataTechnologyMenu = {
                name: 'Data Technology',
                id: repositories?.id,
                localIcon: RepositoriesDataTechnologyIcon,
                children: [],
                path: "/repositories/" + repositories?.id,
                children: []
            }
            menuItems.push(dataTechnologyMenu);
            buildMenuWithData(dataTechnologyMenu.children, repositories.children, '/repositories/', dataTechnologyMenu.id, 5);
        }
    }

    if (repoProfile.all_departments[packName] ?? false) {
        if ((menuHierarchy != null) && (menuHierarchy?.departmentGroup?.children?.length > 0)) {
            let industriesMenu = {
                name: menuHierarchy.departmentGroup.name,
                id: menuHierarchy?.departmentGroup?.id,
                localIcon: RepositoriesAllDepartmentsIcon,
                path: "/repositories/departments/" + menuHierarchy?.departmentGroup?.id,
                children: []
            }
            menuItems.push(industriesMenu);
            buildMenuWithData(industriesMenu.children, menuHierarchy.departmentGroup.children, '/repositories/departments/', industriesMenu.id, 5);
        }
    }

    if (repoProfile.all_industries[packName] ?? false) {
        if ((menuHierarchy != null) && (menuHierarchy?.industryGroup?.children?.length > 0)) {
            let industriesMenu = {
                name: menuHierarchy.industryGroup.name,
                id: menuHierarchy?.industryGroup?.id,
                localIcon: RepositoriesAllIndustriesIcon,
                path: "/repositories/industries/" + menuHierarchy?.industryGroup?.id,
                children: []
            }
            menuItems.push(industriesMenu);
            buildMenuWithData(industriesMenu.children, menuHierarchy.industryGroup.children, '/repositories/industries/', industriesMenu.id, 5);
        }
    }

    if (repoProfile.all_issues[packName] ?? false) {
        if ((menuHierarchy != null) && (menuHierarchy?.issueGroup?.children?.length > 0)) {
            let industriesMenu = {
                name: menuHierarchy.issueGroup.name,
                id: menuHierarchy?.issueGroup?.id,
                localIcon: RepositoriesAllIssuesIcon,
                path: "/repositories/issues/" + menuHierarchy?.issueGroup?.id,
                children: []
            }
            menuItems.push(industriesMenu);
            buildMenuWithData(industriesMenu.children, menuHierarchy.issueGroup.children, '/repositories/issues/', industriesMenu.id, 5);
        }
    }

    if (repoProfile.all_methodologies[packName] ?? false) {
        if ((menuHierarchy != null) && (menuHierarchy?.methodologyGroup?.children?.length > 0)) {
            let industriesMenu = {
                name: menuHierarchy.methodologyGroup.name,
                id: menuHierarchy?.methodologyGroup?.id,
                localIcon: RepositoriesAllIssuesIcon,
                path: "/repositories/methodologies/" + menuHierarchy?.methodologyGroup?.id,
                children: []
            }
            menuItems.push(industriesMenu);
            buildMenuWithData(industriesMenu.children, menuHierarchy.methodologyGroup.children, '/repositories/methodologies/', industriesMenu.id, 6);
        }
    }

    return menuItems;
}

const buildMenuWithData = (target, children, path, parentId, maxLevel) => {
    if (children == null) {
        return;
    }
    for (let index = 0; index < children.length; index++) {
        let department = children[index];
        const child = { name: department.name, id: department.id, parentId: parentId, path: path + department.id };

        // if ((department.children != null) && (department.children.length > 0)) {
        //     child.children = [];
        //     if (maxLevel > 0) {
        //         buildMenuWithData(child.children, department.children, path, parentId, maxLevel - 1);
        //     }
        // }
        target.push(child);
    }
}

const findItemWithId = (items, id, itemType) => {
    if (items.id == id) {
        return items;
    }

    if (itemType?.length > 0 && items[itemType]?.length > 0) {
        for (let i = 0; i < items[itemType].length; i++) {
            if (items[itemType][i].id == id) {
                return items;
            }
        }
    }

    if (items.children != null) {
        for (let i = 0; i < items.children.length; i++) {
            let child = findItemWithId(items.children[i], id, itemType);
            if (child != null) {
                return child;
            }
        }
    }

    return null;
}

const findItemWithDataId = (items, id) => {
    if (items.data?.id == id) {
        return items;
    }

    if (items.children != null) {
        for (let i = 0; i < items.children.length; i++) {
            let child = findItemWithDataId(items.children[i], id);
            if (child != null) {
                return child;
            }
        }
    }

    return null;
}

const categoriesFromAlerts = (alerts) => {
    let categories = [];
    let others = [];
    alerts.forEach(alert => {
        if (alert.category?.length > 0) {
            let index = categories.findIndex((category) => { return category.name == alert.category })
            if (index >= 0) {
                categories[index].alerts.push(alert);
            }
            else {
                categories.push({ name: alert.category, alerts: [alert] })
            }
        }
        else {
            others.push(alert);
        }
    });
    if (others.length > 0) {
        categories.push({ name: 'Others', alerts: others })
    }

    return categories;
}

export {
    initHomeMenuItems,
    initIssuesMenuItems, initConnexionsMenuItems, initSettingsMenuItems, initSearchMenuItems, initAlertsMenuItems, initRepositoriesMenuItems,
    findItemWithId, findItemWithDataId, buildMenuWithData
};
