import { BASE_URL } from '../redux/constants';

const axios = require('axios').default;
const timeout = 100000;

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    
    if (options.method == 'POST') {
        let baseURL = url;
        let path = "/";

        if (url.startsWith(BASE_URL)){
            baseURL = BASE_URL;
            path = url.substring(BASE_URL.length);
        }
        const instance = axios.create({
            baseURL: baseURL,
            timeout: timeout,
            headers: options.headers
        });
        return instance.post(path, options.body).then((response) => {
            // console.log(response)
            return response.data;
        })
            .then(json => {
                return json;
            })
            .catch(error => {
                throw error;
            });
    }
    else if (options.method == 'GET') {
        const instance = axios.create({
            baseURL: url,
            timeout: timeout,
            headers: options.headers
        });
        return instance.get().then((response) => {
            // console.log(response)
            return response.data;
        })
            .then(json => {
                return json;
            })
            .catch(error => {
                throw error;
            });
    }
    else if (options.method == 'PATCH') {
        const instance = axios.create({
            baseURL: url,
            timeout: timeout,
            headers: options.headers
        });
        return instance.patch("/", options.body).then((response) => {
            // console.log(response)
            return response.data;
        })
            .then(json => {
                return json;
            })
            .catch(error => {
                throw error;
            });
    }
    else if (options.method == 'DELETE') {
        const instance = axios.create({
            baseURL: url,
            timeout: timeout,
            headers: options.headers
        });
        return instance.delete("/", options.body).then((response) => {
            // console.log(response)
            return response.data;
        })
            .then(json => {
                return json;
            })
            .catch(error => {
                throw error;
            });
    }
};

export {fetchJSON};

